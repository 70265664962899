import React from "react";
import Table from "antd/lib/table";
import AppEmpty from "../../shared/appEmpty/appEmpty";
import './antdTableWrapper.scss';

const AntdTableWrapper = ({
  loading,
  rowKey,
  columns,
  dataSource,
  scroll,
  onRow,
  onChange,
  pagination,
  rowSelection,
  expandable
}) => {
  // custom empty table message
  const locale = {
    emptyText: <AppEmpty />,
  };

  return (
    <Table
      loading={loading}
      locale={locale}
      rowKey={rowKey}
      columns={columns}
      dataSource={dataSource}
      showSorterTooltip={false}
      scroll={scroll ? scroll : { x: 1000 }}
      size="small"
      onRow={onRow}
      onChange={onChange}
      pagination={pagination}
      className="AntdTableWrapper"
      rowSelection={rowSelection || null}
      expandable={expandable}
    />
  );
};

export default AntdTableWrapper;
