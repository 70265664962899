import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_STATES = "GET_ALL_STATES";
export const GET_LATEST_STATE = "GET_LATEST_STATE";
export const GET_STATE_POLICY = "GET_STATE_POLICY";
export const UPDATE_STATE_POLICY = "UPDATE_STATE_POLICY";

const getAllStates = (environmentId, page = 0) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const statesReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/environment/${environmentId}?page=${page}`,
      "GET"
    );

    const states = await statesReq.json();
    dispatch({
      type: GET_ALL_STATES,
      payload: { states },
    });

    return states;
  });
};

const getLatestState = (environmentId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const statesReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/environment/${environmentId}/latest`,
      "GET"
    );

    const latest = await statesReq.json();
    dispatch({
      type: GET_LATEST_STATE,
      payload: { latest },
    });

    return latest;
  });
};

const upload = (environmentId, tfState) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/environment/${environmentId}/upload`,
      "POST",
      {
        tfState,
      }
    );

    return req;
  });
};

const getStatePolicy = (environmentId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/policy/${environmentId}`,
      "GET"
    );

    const res = { environmentId };
    if (req.ok) {
      res.ok = true;
      res.result = await req.json();
    } else {
      res.ok = false;
      res.result = null;
      res.status = req.status;
    }

    dispatch({
      type: GET_STATE_POLICY,
      payload: res,
    });
    return res;
  });
};

const updateStatePolicy = (environmentId, selected, policy) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/policy/${environmentId}`,
      "PUT",
      { selected, policy }
    );
    const json = await req.json();

    dispatch({
      type: UPDATE_STATE_POLICY,
      payload: json,
    });

    return { ok: req.ok, json };
  });
};

const getLatestByStack = (stackId) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/stack/${stackId}/latest`,
      "GET"
    );

    const data = await response.json();
    return data;
  });
};

const getStateById = (stateId) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/states/${stateId}`,
      "GET",
      undefined,
      undefined,
      undefined,
      false
    );
    const data = await response.json();
    return data;
  });
};

export {
  getAllStates,
  upload,
  getLatestState,
  getStatePolicy,
  updateStatePolicy,
  getLatestByStack,
  getStateById,
};
