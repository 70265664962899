import React, { useEffect } from "react";
import _ from "lodash";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";

const AwsFinalInstructions = ({ wizardState, setSubmitDisabled }) => {
  useEffect(() => {
    setSubmitDisabled(false);
  });

  const renderCode = (isOneLine) => {
    if (!_.isEmpty(wizardState)) {
      var hcl = wizardState?.code;
      if (isOneLine) {
        hcl = `cat ~/.aws/credentials && terraform init && terraform --version`;
      }
      return <DarkCopyBox text={hcl} />;
    }
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  return (
    <div className="AwsFinalInstructions col">
      <div className="AwsFinalInstructions__intro col">
        <div>
          <span className="dark-blue bold">Procedure</span>
          <ol className="dark-blue AwsFinalInstructions__intro-orderedlist">
            <li key="1">
              <span className="dark-blue">
                Copy the provided code to a Terraform file inside a new directory.
              </span>
            </li>
            <li key="2">
              <span className="dark-blue">
                In the same directory that contains the file, run:{" "}
                <span className="purple-flag">terraform init</span>
              </span>
            </li>
            <li key="3">
              <span className="dark-blue">
                Run the command: <span className="purple-flag">terraform apply</span>
              </span>
            </li>
            <li key="4">
              <span className="dark-blue">
                Select <span className="bold">Done</span>.
              </span>
            </li>
          </ol>
        </div>
        <div className="AwsFinalInstructions__cmds-item col">
          {renderCode()}
        </div>
      </div>
    </div>
  );
};

export default AwsFinalInstructions;
