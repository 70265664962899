import React from "react";
import _ from "lodash";
import IconBtn from "../iconBtn/iconBtn";

const ViewSelectedBox = ({ onClick, selectedArr, active }) => {
  if (_.isEmpty(selectedArr)) {
    return <div />;
  }

  return (
    <div className="row" style={{ gap: '7px' }}>
      
      <IconBtn
        onClick={onClick}
        type={active ? "eye-slash" : "eye"}
        colorClass="filter"
        tooltipText={active ? "Return to regular mode" : "View selected assets"}
      />
      {`${selectedArr?.length} selected`}
    </div>
  );
};

export default ViewSelectedBox;
