import React, { useMemo } from "react";
import ReactFlow, {
  Controls,
  Background,
} from "react-flow-renderer";
import DiagramResourceBlock from "./diagramResourceBlock/diagramResourceBlock";

import { sendEvent } from "../../../../utils/amplitude";
import { CiEvents } from "../../../../utils/amplitudeEvents";
import "./postPlanChart.scss";

const getProviderFromCiType = (ciType = '') => ciType.split("_")[0];

const PostPlanChart = ({ data = {}, handleOpenConfig }) => {
  const { height,  width, nodes = [], edges = [], actionLegend = {}, severityLegend = {} } = data;


  const actionArr = Object.keys(actionLegend) || [];
  const severityArr = Object.keys(severityLegend) || [];
  const severityLegendExists = severityArr.some((key) => severityLegend[key]?.visible)

  const onResourceClick = (data) => {
    sendEvent(CiEvents.ciRunResourceClick, { from_graph: true })
    handleOpenConfig(data);
  }
  
  const nodeTypes = useMemo(() => ({
    ASSET: (node = {}) => (
        <DiagramResourceBlock
          resource={{
            data: {
              provider: getProviderFromCiType(node.data?.type) || "",
              assetType: node.data?.type,
              ...node.data,
            }
          }}
          onClick={() => onResourceClick(node.data)}
        />
  )}));

  return (
    <>
      <div
        style={{
          minHeight: "100%",
          minWidth: "100%",
          height: height || "100%",
          width: width || "100%",
        }}>
        <ReactFlow
          defaultNodes={nodes}
          defaultEdges={edges}
          nodeTypes={nodeTypes}
          className="PostPlanChart-container"
          attributionPosition="bottom-right"
          minZoom={0.1}
          defaultZoom={0.5}
          defaultPosition={[(height / 2),( width/2 )]}
          fitView
          fitViewOptions={{ includeHiddenNodes: true, maxZoom: .8 }}
        >
          <Controls showInteractive={false} />
          <Background />
            <div className="PostPlanChart__legend row g8">
              <span className="PostPlanChart__legend-title">Action</span>
              {actionArr.map((key) => actionLegend[key]?.visible && (
                <div className="PostPlanChart__legend-item row g8" key={key}>
                  <div className={`PostPlanChart__legend-item-color ${key}`} />
                  <span className="PostPlanChart__legend-item-text">{actionLegend[key]?.label}</span>
                </div>
              ))}
            </div>
          {severityLegendExists &&
            <div className="PostPlanChart__legend sever row g8">
              <span className="PostPlanChart__legend-title">Severity</span>
              {severityArr.map((key) => severityLegend[key]?.visible && (
                <div className="PostPlanChart__legend-item row g8" key={key}>
                  <div className={`PostPlanChart__legend-item-color ${key}`} />
                  <span className="PostPlanChart__legend-item-text">{severityLegend[key]?.label}</span>
                </div>
              ))}
            </div> }
        </ReactFlow>
      </div>

    </>
  );
};

export default PostPlanChart;
