import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Input, Checkbox } from "antd";
import _ from "lodash";
import { getOnPremiseIp } from "../../../redux/actions/integrationsActions";
import { nicknameRegex, validate } from "../../../utils/validations";
import { renderIps } from "../../../utils/helpers";


const GitlabConfiguration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const publicIps = useSelector((state) => state.integrationsReducer.publicIps);
  const [onpremOpen, setOnpremOpen] = useState(false);
  const [loadingIps, setLoadingIps] = useState(false);

  useEffect(() => {
    handleFetchOnpremIPS();
  }, []);

  const handleFetchOnpremIPS = async () => {
    setLoadingIps(true);
    await dispatch(getOnPremiseIp());
    setLoadingIps(false);
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, token } = formValues;

    if (_.isEmpty(name) || _.isEmpty(token)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="GitlabConfiguration col">
      <div className="GitlabConfiguration__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue GitlabConfiguration__intro-orderedlist">
          <li key="1">
            Log in to your GitLab account to create a personal access token.
          </li>
          <li key="2">
            Select <span className="bold">User Settings</span>
            {` > `}
            <span className="bold">Access Tokens</span>.
          </li>
          <li key="3">
            Enter a <span className="bold">Token name</span> and{" "}
            <span className="bold">Expiration date</span>.
          </li>
          <li key="4">
            Select both of the following scopes:
            <span className="code-flag" style={{ margin: "0 5px" }}>
              api
            </span>
            <span className="code-flag" style={{ margin: "0 5px 0 0" }}>read_user</span>
            <span className="code-flag">read_repository</span>
          </li>
          <li key="5">
            Select <span className="bold">Create personal access token</span>.
            Copy the token.
          </li>
          <li key="6">
            In Firefly, enter the <span className="bold">Nickname</span>, and
            paste the personal access token.
          </li>
          <li key="7">
            If you are using GitLab on-premises, enter the GitLab domain name.
          </li>
          <li key="8">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="gitlab-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "GitLab Integration",
          token: wizardState.token || "",
          domain: wizardState.domain || "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Access Token"
          name="token"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          rules={[
            {
              required: true,
              message: "Gitlab Token is required",
            },
          ]}
        >
          <Input.Password placeholder="" />
        </Form.Item>

        <div className="GitlabConfiguration__onprem">
          <Checkbox
            checked={onpremOpen}
            onChange={() => {
              setOnpremOpen(!onpremOpen);
            }}
          >
            <span className="form-label">Are you using GitLab on-premise?</span>
          </Checkbox>

          {onpremOpen && (
            <Form.Item
              label="GitLab custom domain"
              name="domain"
              style={{
                marginBottom: "1.5rem",
                flexDirection: "column",
                marginTop: "20px",
              }}
              help={
                <span
                  className="small-dark-blue"
                  style={{
                    opacity: 0.7,
                    fontSize: "12px",
                    transform: "translateY(10px)",
                  }}
                >
                  {loadingIps ? "loading.." : renderIps(publicIps)}
                </span>
              }
            >
              <Input placeholder="" />
            </Form.Item>
          )}
        </div>
      </Form>
    </div>
  );
};

export default GitlabConfiguration;
