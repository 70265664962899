import React from "react";
import { useSelector } from "react-redux";
import AppBtn from "../../../shared/appBtn/appBtn";
import { S3_ASSETS } from '../../../consts/config';
import "./emptyNotifications.scss";
import { useTranslation } from "react-i18next";

const EmptyNotifications = ({ handleAddNotification }) => {
  const { t } = useTranslation('notifications')
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  return (
    <div className="EmptyNotifications col center">
      <img src={`${S3_ASSETS}/illustrations/notifiction_illustrator.png`} alt="Empty Notifications" />
      <div className="EmptyNotifications__text col center">
        <span className="title">
          {t('empty-notification.title')}
        </span>
        <div className="EmptyNotifications__text-action center col">
          <span className="lighterText">
            {t('empty-notification.sub-title')}
          </span>
          <AppBtn
            text={t('empty-notification.add-alert-btn')}
            onClick={handleAddNotification}
            disabled={isViewer}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyNotifications;
