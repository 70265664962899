import React from "react";
import isEmpty from 'lodash/isEmpty'

import "./gradientTextarea.scss";

const GradientTextarea = ({ name, value, onChange, placeholder, rows, upperContent, insideButton, onKeyDown }) => {
  const withButtonInside = !isEmpty(insideButton);

  return (
    <div className="GradientTextarea">
      <div className="GradientTextarea__container col">
        {upperContent}
        <div className={withButtonInside ? `GradientTextarea__container-textarea`: ''}>
          <div className={withButtonInside ? `GradientTextarea__container-textarea-withButton row` : 'row'}>
              <textarea
                name={name}
                value={value}
                onChange={onChange}
                placeholder={placeholder}
                rows={rows}
                onKeyDown={onKeyDown ? onKeyDown : null}
              />
            {withButtonInside ? insideButton : null}
        </div>
        </div>
      </div>
    </div>
  );
};

export default GradientTextarea;
