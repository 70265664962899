import React from "react";
import Loading from "../../../shared/loading/loading";

import "./iacStacksContent.scss";
import IacInsightCards from "../iacInfoCards/iacInfoCards";

const IacStacksContent = ({
  selectedIacType,
  selectedCategory,
  iacStacksFilters,
  modulesFilters,
  iacStacksTable,
  terraformStacksTable,
  modulesTable,
  providersTable,
  backendsTable,
  repositoryTable,
  loading,
  iacStacksCategoriesComponent,
  terraformInsights,
  cloudformationInsights,
  collapsed
}) => {
  const showFiltersCategories = ["appliedStacks", "installedArtifacts", "installedCharts", "modules"];
  const showFilters = showFiltersCategories.includes(selectedCategory);

  const contentByIacType = {
    terraform: terraformStacksTable,
    helm: iacStacksTable,
    k8s: iacStacksTable,
    kustomize: iacStacksTable,
    cloudformation: iacStacksTable,
    pulumi: iacStacksTable,
  };

  const contentByCategory = {
    modules: modulesTable,
    providers: providersTable,
    backends: backendsTable,
    repositories: repositoryTable
  };

  const renderFilters = () => {
    if (selectedCategory === "modules") {
      return modulesFilters;
    }
    return iacStacksFilters;
  }

  const renderContent = () => {
    if (selectedCategory === "modules") {
      return contentByCategory[selectedCategory];
    }
    return contentByIacType[selectedIacType]
  }
  
  return (
    <div
      className={`IacStacksContent ${showFilters ? "noRadius" : ""} ${collapsed? 'expanded':''}`}
    >
      {loading ? (
        <Loading />
      ) : (
       <div className="IacStacksContent__wrapper" >
        {selectedIacType == 'terraform' && (
          <IacInsightCards  insights={terraformInsights}  />
        )}
        {selectedIacType == 'cloudformation' && (
          <IacInsightCards  insights={cloudformationInsights} type="cloudformation" />
        )}
        {iacStacksCategoriesComponent}
         <div
          className={`IacStacksContent__grid ${selectedIacType} ${
            showFilters ? "filtersGrid" : ""
          }`}
        >
          {showFilters && renderFilters()}
          {showFilters
            ? renderContent()  
            : contentByCategory[selectedCategory]}
        </div>
       </div>
      )}
    </div>
  );
};

export default IacStacksContent;
