import React from "react";
import "./linelLoader.scss";

const LinelLoader = (fullWidth) => {
  return (
    <div className={`LinelLoader ${fullWidth ? "fullWidth" : ""}`}></div>
  );
};

export default LinelLoader;
