import React, { useState, useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import ActionsSummary from "../actionsSummary/actionsSummary";
import { getWorkflowResources } from "../../../../redux/actions/ciWorkflowsActions";
import { useDispatch } from "react-redux";
import ApplyResources from "./applyResources";
import Outputs from "./outputs";
import Diagnostic from "./diagnostic/diagnostic";

import "./postApply.scss";

const PostApply = ({ applyId = '', parsedDataSummary = {}, handleOpenConfig }) => {
    const [applyData, setApplyData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const { notNoOpResourcesCount = 0, failedResourcesCount = 0 } = parsedDataSummary;
    const applyCount = notNoOpResourcesCount + failedResourcesCount;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchApplyData();
    }, [pageNumber, pageSize]);
    
    const fetchApplyData = async () => {
        setLoading(true);
        const newOffset = (pageNumber - 1) * pageSize;
        const response = await dispatch(getWorkflowResources(applyId, { offset: newOffset > applyCount ? 0 : newOffset ,size: pageSize }));
        if (response) setApplyData(response.resources);
        setLoading(false);
    }

    const { outputs =  {}, diagnostics: diagnosticsArr } = parsedDataSummary;
    const outputsArr = Object.keys(outputs).map((outputKey) => ({ id: uuidv4(), name: outputKey, value: outputs[outputKey] })) || [];
    const outputsLength = outputsArr.length;
    
    return (
        <div className="PostApply col g10">
            <ActionsSummary data={parsedDataSummary} />
             <div className="col g10">
                <span className="bold">Resources</span>
                <ApplyResources data={applyData} loading={loading} pageSize={pageSize} setPageSize={setPageSize} setPageNumber={setPageNumber} applyCount={applyCount} handleOpenConfig={handleOpenConfig}/>
             </div>
            {outputsLength ? 
                <div className="col g10">
                    <div className="row g5">
                        <span className="bold">Outputs</span>
                        <span className="sub-title">{outputsLength} total</span>
                    </div>
                    <Outputs data={outputsArr} />
                </div> : null
            }
            {diagnosticsArr?.length ? 
            <div className="col g10">
                <span className="bold">Global diagnostics</span>
                <div className="col g10">
                    {diagnosticsArr.map((diagnostic) => {
                     const key = uuidv4();
                     return <Diagnostic key={key} item ={{ key, ...diagnostic }}/>})
                    }
                </div>
            </div>
            : null }
        </div>);
};

export default PostApply;