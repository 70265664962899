/* eslint-disable */
import _ from "lodash";
import moment from "moment";
import { buckets, clouds, iacProviders, integrationIcons } from "./icons";
import { providers } from "./providers";
import { CODIFY_TYPES, IAC_STACKS_MAP, IAC_TYPES } from "../consts/general";

export const dateTimeFormat = "MMM DD YYYY, HH:mm";
export const dateTimeSecondsFormat = "MMM DD YYYY, HH:mm:ss";
export const dateFormat = "MMM DD YYYY";

// -------- format numbers when decimal with option to set decimal places ---------
export const formatIfDecimal = (number, decimalPlaces) => {
  const decimalCount = decimalPlaces ? decimalPlaces : 1;
  return number % 1 !== 0 ? number.toFixed(decimalCount) : number;
};

// ----- format numbers to have comma when over 1K - supports decimal numbers also -------
export const numberWithCommas = (x) => {
  let y = parseFloat(x).toFixed(2).split(".");
  let output =
    y[0].replace(/\B(?=(\d{3})+(?!\d))/g, ",") + (+y[1] ? "." + y[1] : "");
  return output;
};

export const formatNoUnderscore = (str) => {
  return str.replace(/_/g, " ");
};

export const formatNohyphen = (str) => {
  return str.replace(/-/g, " ");
};

export const formatNohyphenNoUnderscore = (str) => {
  if (!_.isEmpty(str)) {
    return str.replaceAll(/-|_/g, " ");
  }
  return;
};

const RESERVED_TYPES_KEYS_MAP = {
  gcp: "GCP",
  aws: "AWS",
  db: "DB",
  iam: "IAM",
  ip: "IP",
  pubsub: "Pub/Sub",
  acl: "ACL",
  ssl: "SSL",
  url: "URL",
  http: "HTTP",
  https: "HTTPS",
  ipv4: "IPv4",
  Cidr: "CIDR",
  dhcp: "DHCP",
  api: "API",
  dns: "DNS",
  sql: "SQL",
  vpc: "VPC",
  s3: "S3",
}
// format a string with AWS text and underscore and uppercase if second word is short
// => start: "aws_ccc_ddd" ==> result "AWS CCC ddd"
export const formatAwsStringWithUnderscore = (str, noProvider) => {
  if (_.isEmpty(str)) {
    return "-";
  }

  const stringArray = str.split("_");
  let first = stringArray[0];
  switch (first) {
    case "kubernetes":
      first = "K8s";
      break;
    case "mongodbatlas":
      first = "MongoDB Atlas";
      break;
    case "azurerm":
      first = "Azure";
      break;
    default:
      first = providerNameFormatter(stringArray[0]);
      break;
  }
  
  if (stringArray.length === 1) {
    return _.capitalize(stringArray[0]);
  }
  const secondWord = stringArray[1];
  let second = RESERVED_TYPES_KEYS_MAP[secondWord] || (secondWord.length > 3 ? _.capitalize(secondWord) : secondWord.toUpperCase());
  stringArray.splice(0, 2); // remove first two.
  const restOfItems = stringArray.map((item) => RESERVED_TYPES_KEYS_MAP[item] || _.capitalize(item));
  if (first.toLowerCase() === "aws" && second.toLowerCase() === "instance") {
    second = "EC2 " + second;
  }
  if (noProvider) {
    return [second, ...restOfItems].join(" ");
  }
  return [first, second, ...restOfItems].join(" ");
};

export const timeDifferenceInWords = (prefix, start, end) => {
  const hoursDiff = end.diff(start, "hours");
  const minutesDiff = end.diff(start, "minutes");
  const secondsDiff = end.diff(start, "seconds");
  const daysDiff = end.diff(start, "days");
  const monthsDiff = end.diff(start, "months");
  const yearsDiff = end.diff(start, "years");

  if (yearsDiff > 0) {
    return prefix + " " + yearsDiff + " " + "years ago";
  }
  if (monthsDiff > 0) {
    return prefix + " " + monthsDiff + " " + "months ago";
  }
  if (daysDiff > 0) {
    return prefix + " " + daysDiff + " " + "days ago";
  }
  if (hoursDiff > 0) {
    return prefix + " " + hoursDiff + " " + "hours ago";
  }
  if (minutesDiff > 0) {
    return prefix + " " + minutesDiff + " " + "minutes ago";
  }
  if (secondsDiff > 0) {
    return prefix + " " + secondsDiff + " " + "seconds ago";
  }
};

// this function doesn't uses lodash cuz lodash killing underscoe and hyphen
export const capitalizeFirst = (str) => {
  if (!_.isEmpty(str)) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  }
  return;
};
export const formatIacStatusTitle = (str) => {
  let output = "";
  switch (str) {
    case "managed":
      output = "Codified";
      break;
    case "unmanaged":
      output = "Unmanaged";
      break;
    case "modified":
      output = "Drifts";
      break;
    case "ghost":
      output = "Ghost";
      break;
    case "delete":
      output = "Deleted";
      break;
    default:
      output = capitalizeFirst(str);
      break;
  }
  return output;
};

export const formatIacStatus = (str) => {
  let output = "";
  switch (str) {
    case "managed":
      output = "Codified";
      break;
    case "unmanaged":
      output = "Unmanaged";
      break;
    case "modified":
      output = "Drifted";
      break;
    case "ghost":
      output = "Ghost";
      break;
    case "insight":
      output = "Insight";
      break;
    case "notSupported":
      output = "Not Supported";
      break;
    case "unknown":
      output = "Unknown Status (Missing Integration)";
      break;

    default:
      output = capitalizeFirst(str);
      break;
  }
  return output;
};

export const iacStatusIconByType = (type) => {
  let output = "";
  switch (type) {
    case "managed":
    case "Codified":
      output = "code";
      break;
    case "unmanaged":
    case "Unmanaged":
      output = "exclamation-circle";
      break;
    case "modified":
    case "Drifted":
      output = "code-branch";
      break;
    case "ghost":
    case "Ghost":
      output = "ghost";
      break;
    default:
      break;
  }
  return output;
};

export const formatIacStatusToState = (str) => {
  let output = "";
  switch (str) {
    case "Codified":
      output = "managed";
      break;
    case "Unmanaged":
      output = "unmanaged";
      break;
    case "Drifted":
      output = "modified";
      break;
    case "Ghost":
      output = "ghost";
      break;
    case "Insight":
      output = "insight";
      break;

    default:
      break;
  }
  return output;
};

export const renderOprhanStatus = (str) => {
  let output = "";
  switch (str) {
    case "stateFileDeleted":
      output = "Unavailable";
      break;
    case "outOfSync":
      output = "Out of Sync";

      break;
    case "partialSync":
      output = "Partial Sync";
      break;

    case "notSupported":
      output = "Not Supported";
      break;

    case "synced":
      output = "In Sync";
      break;

    case "loading":
      output = "Scanning";
      break;

    case "stateFileEmpty":
      output = "Empty State";
      break;

    default:
      output = capitalizeFirst(str);
      break;
  }
  return output;
};

export const formatUnixDate = (date) => {
  if (date) {
    let output = _.ceil(date);

    if (output.toString()?.length < 11) {
      output = output * 1000;
    }
    return (output = moment(output).format(dateFormat));
  }
  return "-";
};

export const formatUTCDateTime = (date) => {
  if (date) {
    return moment(date).format(dateTimeFormat);
  }
  return "-";
}

export const numberToPastUnixDate = (daysNum) => {
  const SEVEN_DAYS_AGO = moment().subtract(7, "d").valueOf();
  const THIRTY_DAYS_AGO = moment().subtract(30, "d").valueOf();
  let output;

  switch (daysNum) {
    case "7":
      output = SEVEN_DAYS_AGO;
      break;
    case "30":
      output = THIRTY_DAYS_AGO;
      break;

    default:
      break;
  }

  return output;
};

export const providerNameFormatter = (provider) => {
  let output = "";
  switch (provider) {
    case "aws":
      output = "AWS";
      break;
    case "k8s":
      output = "Kubernetes";
      break;
    case "gcp":
    case "google":
      output = "Google Cloud";
      break;
    case "github":
      output = "GitHub";
      break;
    case "azurerm":
      output = "Azure";
      break;
    default:
      output = _.upperFirst(provider);
      break;
  }

  return output;
};

export const formatStatusText = (str) => {
  if (str) {
    const noUnderScore = formatNoUnderscore(str);
    return _.capitalize(_.lowerCase(noUnderScore));
  }
  return str;
};

export const renderEllipsis = (str, letterCount) => {
  if (!_.isEmpty(str) && str?.length > letterCount) {
    const newTest = str.substring(0, letterCount) + "...";
    return newTest;
  }
  return str;
};

export const renderProviderTypeText = (str) => {
  let output = "";
  switch (str) {
    case "cloud":
      output = "Cloud Infrastructure";
      break;
    case "aws":
      output = "AWS Account";
      break;
    case "azurerm":
      output = "Azure Subscriptions";
      break;
    case "k8s":
      output = "K8s Cluster";
      break;
    case "gcp":
      output = "Google Cloud Project";
      break;
    case "saas":
      output = "SaaS Application";
      break;
    case "cdn":
      output = "CDN/Edge";
      break;
    case "apm":
      output = "APM/Monitoring";
      break;
    case "cicd":
      output = "CI/CD";
      break;
    case "iam":
      output = "IAM";
      break;
    case "newrelic":
      output = "New Relic";
      break;
    default:
      output = _.upperFirst(str);
      break;
  }
  return output;
};

export const formatGlobalScopeTitle = (scope, returnAssetTypes) => {
  let title = "";
  let providerName = "";
  if (scope?.endsWith("objects")) {
    let provider = scope?.replace("objects", "");
    providerName = providerNameFormatter(provider);
    if (returnAssetTypes) {
      title = `${providerName} All Asset Types`;
    } else {
      title = `All ${providerName} Assets`;
    }
  } else {
    title = formatAwsStringWithUnderscore(scope);
  }
  return title;
};

export const getInsightType = (types) => {
  const type = _.find(types, (item) => !_.isEmpty(item));
  let provider = "";
  _.forEach(_.keys(providers), (providerKey) => {
    if (type?.startsWith(providers[providerKey]?.insightPrefix)) {
      provider = providerKey;
    }
  });
  return provider;
};

export const getAllProvidersArray = (integrationsObj) => {
  let allProviders = _.map(_.keys(integrationsObj), (providerKey) => {
    return _.map(integrationsObj[providerKey] || [], (integ) => {
      return {
        ...integ,
        provider: providerKey,
      };
    });
  });
  allProviders = _.flatten(allProviders);
  return allProviders;
};

export const getTimeFromDateString = (timeString) => {
  const localeTime = new Date(timeString).toLocaleTimeString();
  const unpackedTime = localeTime?.split(" ");
  const timeArr = unpackedTime[0]?.split(":");
  return `${timeArr[0]}:${timeArr[1]} ${unpackedTime[1]}`;
};

export const renderCrawlerTitleByType = (type) => {
  let text = type;
  if (type === "tfc") {
    text = "terraform";
  }
  return capitalizeFirst(text);
};

export const renderCrawlerIconByType = (type, isDark) => {
  switch (type) {
    case "s3":
      return buckets["aws_bucket"];
    case "tfc":
      return iacProviders("terraform", isDark);
    case "pulumi":
      return iacProviders("pulumi", isDark);
    case "cloudformation":
      return iacProviders("cloudFormation", isDark);
    case "k8s":
      return clouds("k8s", isDark);
    case "azure":
    case "azure storage account":  
      return iacProviders("azureBackend", isDark);
    case "argocd-local":
      return iacProviders("argo", isDark);
    default:
      return integrationIcons[type];
  }
};

export const formatJSONstring = (str) => {
  try {
    if (!str) {
      return '';
    }
    const parsedAsset = JSON.parse(str);
    const stringifiedAsset = JSON.stringify(parsedAsset, null, 2);
    return stringifiedAsset;
  } catch (e) {
    return ''
  }
}

export const formatDeletedFilter = (str) => {
  let output = "";
  switch (str) {
    case "last_day":
      output = "Last 24 hours";
      break;
    case "last_week":
      output = "Last 7 days";

      break;
    case "last_month":
      output = "Last 30 days";
      break;

    case "last_quarter":
      output = "Last 90 days";
      break;

    default:
      output = str;
      break;
  }
  return output;
}

export const getArnUserName = (arn = "") => {
  const splitted = arn.split('/')
  if (splitted.length > 1) {
    const userName = splitted[splitted.length - 1]
    return `.../${userName}`
  }
  return arn
}

export const providerAssetImagesFormatter = (str) => {
  let output = "";
  switch (str) {
    case "k8s":
      output = "kubernetes";
      break;
    case "gcp":
      output = "google";
      break;
    default:
      output = str;
      break;
  }
  return output;
}
export const formatCodifyTabName = (key) => {
  switch (key) {
    case CODIFY_TYPES.cloudformation:
      return 'CloudFormation';
    case CODIFY_TYPES.deploymentManager:
      return 'Deployment Manager';
    case CODIFY_TYPES.configConnector:
      return 'Config Connector';
    case CODIFY_TYPES.googleCloudSdk:
      return 'Cloud SDK';
    case CODIFY_TYPES.cdk8s:
      return 'CDK8S';
    case CODIFY_TYPES.cdk:
      return 'CDK';
    case CODIFY_TYPES.tfcdk:
      return 'TFCDK';
    case CODIFY_TYPES.arm:
        return 'ARM';
    default:
      return capitalizeFirst(key);
  }
}
export const formatTitleByIacType = (key) => {
  switch (key) {
    case IAC_TYPES.k8s: return IAC_STACKS_MAP.k8s;
    case "eksctl": return "EKS Controller"
    case "externalDns": return "ExternalDNS"
    case "gke": return "GKE Controller"
    case "goog-dm": return "Deployment Manager"
    case "composer": return "Cloud Composer"
    case "airflow": return "Apache Airflow"
    default: return capitalizeFirst(key);
  }
}
export const groupByCloud = (strings, selectedItems= []) => {
  const grouped = {};
  let sortedItems = _.sortBy(strings, st => !selectedItems?.includes(st));
  for (const string of sortedItems) {
    const firstWord = string.split('_')[0];

    if (!grouped[firstWord]) {
      grouped[firstWord] = [];
    }

    grouped[firstWord].push(string);
  }

  return grouped;
}
