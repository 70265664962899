import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { Form, Input, Checkbox } from "antd";
import { charNumHyfenOnly } from "../../../utils/validations";
import { getAllKeyPairs } from "../../../redux/actions/keyPairsActions";
import { renderRandomCronJob } from "../../../utils/helpers";
import MarkAsProduction from "../../../shared/markAsProduction/markAsProduction";

const K8sOverview = ({
  setSubmitDisabled,
  wizardState,
  setWizardState,
  isProd,
  handelSetIsProd,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const [loadingAccessKeys, setLoadingAccessKeys] = useState(false);

  const keyPairs = useSelector((state) => state.keyPairsReducer.keyPairs);

  useEffect(() => {
    setSubmitDisabled(false);
    setWizardState({
      ...wizardState,
      ...{
        clusterId: "default",
        schedule: renderRandomCronJob(),
        namespace: "firefly",
      },
    });
    fetchAccessKeys();
  }, []);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { clusterId } = formValues;

    if (_.isEmpty(clusterId)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const fetchAccessKeys = async () => {
    if (_.isEmpty(keyPairs)) {
      setLoadingAccessKeys(true);
      await dispatch(getAllKeyPairs());
      setLoadingAccessKeys(false);
    }
  };

  return (
    <div className="K8sOverview col">
      <div className="K8sOverview__section col">
        <span className="dark-blue bold">Before you begin</span>
        <div className="dark-blue K8sOverview__section-list">
          <span className="dark-blue">
            Verify you are using{" "}
            <a href="https://kubernetes.io/" target="_blank" rel="noreferrer" className="med-bold underline">
              Kubernetes v1.15
            </a>{" "}
            or later and Helm v.3.50 or later.
          </span>
        </div>
        <span className="dark-blue">
          To integrate your Kubernetes clusters, enter your{" "}
          <span className="med-bold purple-text">Cluster ID</span>, and select{" "}
          <span className="med-bold purple-text">Next</span>.
        </span>
      </div>
      <div className="K8sOverview__section col form-wizard-wrapper">
        <Form
          {...layout}
          name="k8s-wizard-form"
          form={form}
          initialValues={{
            clusterId: wizardState.clusterId || "default",
            namespace: wizardState.namespace || "firefly",
          }}
          className="AppWizard__content-body-form"
          onValuesChange={(
            changedValues,
            allValues // this is how you can get the form values
          ) => {
            allValues.name = allValues.clusterId || "default";
            setWizardState({
              ...wizardState,
              ...allValues,
            });
            checkValidation(allValues);
          }}
        >
          <Form.Item
            label="Cluster ID"
            name="clusterId"
            tooltip="Unique Alias for the cluster"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            rules={[
              {
                required: true,
                message: "Cluster Id is required",
              },
              {
                pattern: charNumHyfenOnly.regex,
                message: charNumHyfenOnly.msg,
              },
            ]}
          >
            <Input placeholder="Please fill cluster Id" />
          </Form.Item>
        </Form>
        <Checkbox checked={isProd} onChange={handelSetIsProd}>
          <MarkAsProduction />
        </Checkbox>
        <Checkbox
            checked={wizardState?.withArgo}
            onChange={() => setWizardState({...wizardState, withArgo: !wizardState.withArgo})}>
           <Form.Item
            label="Integrate Argo CD"
            tooltip="To display the Kubernetes object status from Argo CD in the Inventory, select the checkbox. (Currently supported by AWS only)"/>
        </Checkbox>
      </div>
    </div>
  );
};

export default K8sOverview;
