import React, { useState } from 'react';
import { v4 as uuidv4 } from 'uuid';
import keys from 'lodash/keys';
import mapValues from 'lodash/mapValues';
import zipObject from 'lodash/zipObject';
import every from 'lodash/every';
import SearchBox from '../../../searchBox/searchBox';
import { Button, Checkbox } from 'antd';
import AppBtn from '../../../appBtn/appBtn';
import './columnVisibilityPanel.scss'
const CustomColumnVisibilityPanel = ({ tableColumns, columnVisibilityModel, defaultColumnVisibilityModel, onColumnVisibilityChange, menuProps }) => {
    const [searchTerm, setSearchTerm] = useState("")
    const [selectedColumns, setSelectedColumns] = useState(columnVisibilityModel);
    const [errorMessage, setErrorMessage] = useState()
    defaultColumnVisibilityModel = defaultColumnVisibilityModel ?? zipObject(tableColumns?.map(column => column.field), Array(tableColumns?.length).fill(true));

    const handleChange = (checkedColumns) => {
        if(errorMessage) setErrorMessage();
        setSelectedColumns({
            ...selectedColumns,
            ...zipObject(keys(selectedColumns), keys(defaultColumnVisibilityModel).map(key => checkedColumns.includes(key))),
        })
    }

    const options = tableColumns.map(column => ({ label: column.headerName, value: column.field }))?.filter(column => ![""," "].includes(column.label));
    const defaultValues = keys(columnVisibilityModel)?.filter(column => columnVisibilityModel[column]);
    return (
        <div className="ColumnVisibilityPanel">
            <div className="ColumnVisibilityPanel__header">
                <div>Table Columns</div>
            </div>
            <div className="ColumnVisibilityPanel__content">
                <SearchBox placeholder="Search Column" value={(v) => setSearchTerm(v)} resetSearch={setSearchTerm} />
                <div className="ColumnVisibilityPanel__content__actions">
                    <Button type="link" onClick={() => onColumnVisibilityChange(mapValues(defaultColumnVisibilityModel, () => true))}>Select all</Button>
                    <div></div>
                    <Button type="link" onClick={() => onColumnVisibilityChange(defaultColumnVisibilityModel)}>Reset</Button>
                </div>

                <Checkbox.Group
                    className='ColumnVisibilityPanel__content__checkbox-group'
                    // options={options}
                    onChange={handleChange}
                    defaultValue={defaultValues}
                >
                    {options.map(option => <Checkbox key={uuidv4()} style={{ display: _handleCheckboxDisplay(option) }} value={option.value}>{option.label}</Checkbox>)}
                </Checkbox.Group>
                {errorMessage && <span className='ColumnVisibilityPanel__content__error'>{errorMessage}</span>}
                <AppBtn text="Apply" onClick={(e) => {
                    if(every(selectedColumns, value => value === false)){
                        return setErrorMessage("At least 1 is required");
                    }
                    onColumnVisibilityChange(selectedColumns);
                    menuProps.hideMenu(e)
                }} />

            </div>

        </div>
    );

    function _handleCheckboxDisplay(option) {
        if (!searchTerm)
            return 'flex';
        return option.label.toLocaleLowerCase().includes(searchTerm.toLocaleLowerCase()) ? 'flex' : 'none';
    }
}

export default CustomColumnVisibilityPanel;