import React from "react";
import Modal from "antd/lib/modal";
import "./freeTierModal.scss";
import AppBtn from "../../../shared/appBtn/appBtn";
import _ from "lodash";
import arrow from "../../../Images/general_icons/green_outline_check.svg";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { S3_ASSETS } from "../../../consts/config";

const FreeTierModal = ({ visible, handleClose, handleGoPremium }) => {
  const list = [
    "1 cloud account",
    "1 K8s cluster",
    "Up to 1,000 assets",
    "Sync once a day",
    "Slack integration",
    "Git integration",
    "Community support",
  ];

  const freeTierImg = `${S3_ASSETS}/account-tier-illustrations/free_tier_circle.svg`;

  const renderList = (arr) => {
    return _.map(arr || [], (item) => (
      <span className="FreeTierModal__list-item row">
        <img src={arrow} alt="arrow" />
        {item}
      </span>
    ));
  };

  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="FreeTierModal"
      closable={false}
      centered
      width={700}
    >
      <div className="FreeTierModal__header center row">
        <button className="FreeTierModal__header-close" onClick={handleClose}>
          <FontAwesomeIcon icon={faTimes} />
        </button>
        <img src={freeTierImg} alt="tier" />
        <div className="col" style={{ gap: "10px" }}>
          <span className="FreeTierModal__header-title">Free Tier</span>
          <span className="FreeTierModal__header-sub">
            Firefly’s free tier helps teams and individuals with a smaller-scale
            infrastructure gain the value of Firefly for free.
          </span>
        </div>
      </div>
      <div className="col" style={{ gap: "14px" }}>
        <div className="FreeTierModal__list row center">
          {renderList(list.slice(0, 3))}
        </div>
        <div className="FreeTierModal__list row center">
          {renderList(list.slice(3, 7))}
        </div>
      </div>
      <div className="FreeTierModal__actions center row">
        <AppBtn text="Not for me" onClick={handleClose} whiteOutlined />
        <AppBtn text="Let's Go" onClick={handleGoPremium} />
      </div>
    </Modal>
  );
};

export default FreeTierModal;
