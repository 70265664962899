import React from "react";
import { S3_ASSETS } from '../../../consts/config';
import "./emptyDrifts.scss";
import { useTranslation } from "react-i18next";

const EmptyDrifts = () => {
  const { t } = useTranslation('event-explorer')
  return (
    <div className="EmptyDrifts col center">
      <img src={`${S3_ASSETS}/illustrations/DevOps_men_women.svg`} alt="empty stack group" />
      <div className="EmptyDrifts__text col center">
        <span className="title">
          {t('empty-drifts.title')}
        </span>
        <span className="title">{t('empty-drifts.sub-title')}</span>
      </div>
    </div>
  );
};

export default EmptyDrifts;
