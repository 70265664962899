import React from "react";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import isEmpty from "lodash/isEmpty";

const AssetDataSourceCell = ({ row }) => {


  return (
    <div className="AssetDataSourceCell row g10">
      <div className="row g10">
        {!isEmpty(row?.provider) && (
          <ProviderIcon provider={row?.provider} customStyle="inventory" />
        )}
        <span>
          {row?.integrationName}
        </span>
      </div>
    </div>
  );
};

export default React.memo(AssetDataSourceCell);
