import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { toggleAppTheme } from "../../../redux/actions/userPreferencesActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./themeToggle.scss";

const ThemeToggle = () => {
  const dispatch = useDispatch();
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const handleToggleAppTheme = () => {
    dispatch(toggleAppTheme(themeDark));
  };

  return (
    <button
      className={`ThemeToggle center ${themeDark ? "drak-active" : ""}`}
      onClick={handleToggleAppTheme}
    >
      <FontAwesomeIcon icon={themeDark ? "sun" : "moon"} />
    </button>
  );
};

export default ThemeToggle;
