import React, { useState, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import AppWizard from "../../../../shared/appWizard/appWizard";
import ProviderSelection from "../../../stackCreation/providerSelection";
import {
  getExternalId as getAccountExternalId,
} from "../../../../redux/actions/integrationsActions";
import { clouds } from "../../../../utils/icons";
import Loading from '../../../../shared/loading/loading';
import "./awsIntegrationConfig.scss";

const AwsIntegrationConfig = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingInit, setLoadingInit] = useState(true);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    getInitData();
  }, []);

  const getInitData = async () => {
    await Promise.all([
      dispatch(getAccountExternalId()),
    ]);
    setLoadingInit(false);
  };

  const configWizard = [
    {
      step_title: "Integration Method",
      step_description: "Select how you want to integrate your AWS account",
      content_title: "Select Integration Method",
      content: (
         loadingInit ? <Loading /> : <ProviderSelection hideHelm onClickMethod comingSoonPC awsDesciption/>
      ),
      valid: true,
    },
  ];

  return (
    <div className="AwsIntegrationConfig">
      <AppWizard
        iconSrc={clouds("aws", themeDark)}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default AwsIntegrationConfig;
