import React, { useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import AntdTableWrapper from '../../../shared/antdTableWrapper/antdTableWrapper';
import Avatar from 'antd/lib/avatar';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCheck,
  faQuestionCircle,
  faEye,
  faShieldAlt,
  faUserTag,
} from "@fortawesome/free-solid-svg-icons";

import { getUniqeKeys } from "../../../utils/helpers";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { dateTimeSecondsFormat } from "../../../utils/formatting";
import { useTranslation } from "react-i18next";

const UsersTable = ({
  data,
  loading,
  handleConfirmDelete,
  handleSelectRow,
  handleAssignRole,
}) => {
  const { t } = useTranslation('users')
  const { user } = useAuth0();
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const [tableCurrentTotal, setTableCurrentTotal] = useState(0);

  const usersTableColumns = [
    {
      title: t('user-management.table.headers.name'),
      dataIndex: "name",
      sorter: (a, b) => a.name.localeCompare(b.name),
      render: (text, record) => {
        return (
          <div style={{ wordWrap: "break-word", display: 'flex', gap: '10px' }}>
            <Avatar src={record?.picture} size="small">
              {_.capitalize(_.isEmpty(text) ? 'U' : text[0])}
            </Avatar>
            {text}
          </div>
        )
      },
    },
    {
      title: t('user-management.table.headers.role'),
      dataIndex: "role",
      filters: getUniqeKeys(data || [], "role"),
      onFilter: (value, record) => record.role.includes(value),
      render: (role, record) => renderRole(role),
    },
    {
      title: t('user-management.table.headers.email'),
      dataIndex: "email",
      sorter: (a, b) => a.email.localeCompare(b.email),
    },
    {
      title: t('user-management.table.headers.created-at'),
      dataIndex: "created_at",
      render: (created_at) =>
        created_at ? moment(created_at).format(dateTimeSecondsFormat) : "-",
      sorter: (a, b) => {
        if (moment(a.created_at).isBefore(moment(b.created_at))) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: t('user-management.table.headers.last-login'),
      dataIndex: "last_login",
      render: (last_login) =>
        last_login ? moment(last_login).format(dateTimeSecondsFormat) : "-",
      sorter: (a, b) => {
        if (moment(a.last_login).isBefore(moment(b.last_login))) {
          return -1;
        }
        return 1;
      },
    },
    {
      title: t('user-management.table.headers.emailVerification'),
      dataIndex: "email_verified",
      width: "200px",
      filters: getUniqeKeys(
        [{ email_verified: "Verified" }, { email_verified: "Not verified" }],
        "email_verified"
      ),
      onFilter: (value, record) =>
        (value == "Verified") == record.email_verified,
      render: (status) => renderStatus(status),
      className: "status-column",
    },
    {
      title: t('user-management.table.headers.actions'),
      width: "100px",
      dataIndex: "user_id",
      render: (user_id, row) => renderActionBtns(user_id, row),
    },
  ];

  // format status column values
  const renderStatus = (status) => {
    if (status) {
      return (
        <span>
          <FontAwesomeIcon icon={faCheck} className="status-icon green" />
          {t('user-management.table.body.verified')}
        </span>
      );
    }
    return (
      <span>
        <FontAwesomeIcon icon={faQuestionCircle} className="status-icon red" />
        {t('user-management.table.body.not-verified')}
      </span>
    );
  };

  // show delete button for every row
  const renderActionBtns = (user_id) => {
    if (user.sub === user_id) {
      return;
    }
    return (
      <div className="action-user-cell row">
        <IconBtn
          onClick={handleAssignRole}
          customIcon={<FontAwesomeIcon icon={faUserTag} />}
          colorClass="purple"
          disabled={isViewer}
        />
        <span className="action-user-cell__delete">
          <IconBtn
            onClick={handleConfirmDelete}
            type="delete"
            colorClass="red"
            disabled={isViewer}
          />
        </span>
      </div>
    );
  };

  // render role with icon
  const renderRole = (role) => {
    return (
      <span>
        <FontAwesomeIcon
          icon={role === "Viewer" ? faEye : faShieldAlt}
          className={`role-icon ${role === "Viewer" ? "green" : "purple"}`}
        />
        {t(`user-management.table.body.${role}`)}
      </span>
    );
  };

  const onTableChange = (pagination, filters, sorter, extra) => {
    setTableCurrentTotal(extra.currentDataSource.length);
  };

  return (
    <AntdTableWrapper
      loading={loading}
      rowKey="user_id"
      columns={usersTableColumns}
      dataSource={!_.isEmpty(data) ? data : []}
      onRow={(record) => ({
        onClick: () => {
          handleSelectRow(record);
        },
      })}
      onChange={onTableChange}
      pagination={{
        defaultPageSize: 8,
        defaultCurrent: 1,
        showSizeChanger: false,
        total: !_.isEmpty(data) ? tableCurrentTotal : 0,
        showTotal: (total, range) => t('user-management.table.footer.items-count', { rangeFrom: range[0], rangeTo: range[1], itemsCount: total })
      }}
    />
  );
};

export default UsersTable;
