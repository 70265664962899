import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
export const CREATE_STACK = "CREATE_STACK";
export const GET_CFT_STACKS = "GET_CFT_STACKS";
export const GET_STACKS_STATUS = "GET_STACKS_STATUS";

const getCftStacksByEnvAndSearch = (environmentId, searchVal) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const envId = environmentId === "all" ? "" : environmentId;

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/cft/byEnv?name=${searchVal}&environmentId=${envId}`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: GET_STACKS_STATUS,
      payload: { data },
    });

    return data;
  });
};

const getStacksByIntegration = (integration, region) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/cft/${integration}/region/${region}`,
      "GET"
    );

    const data = await req.json();
    return { req, data };
  });
};

const getStacksByAccount = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/cft/${integration}/account`,
      "GET"
    );

    const data = await req.json();
    return { req, data };
  });
};

const deleteCftStack = (environmentId, stackId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/${environmentId}/stack/cft/deleteById/${stackId}`,
      "DELETE"
    );
    return req;
  });
};
const exportCftStacks = (environmentId, searchVal) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();
    const envId = environmentId === "all" ? "" : environmentId;

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/cft/export?name=${searchVal}&environmentId=${envId}`,
      "GET"
    );
    const file = await response.blob();
    return {req: response, file};
  });
};

export {
  getStacksByIntegration,
  getCftStacksByEnvAndSearch,
  deleteCftStack,
  exportCftStacks,
  getStacksByAccount,
};
