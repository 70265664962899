import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";

const CloudFlareForm = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, token } = formValues;

    if (_.isEmpty(name) || _.isEmpty(token)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="CloudFlareForm col">
      <div className="CloudFlareForm__intro col">
        <span className="form-label">
          How to integrate Cloudflare with Firefly?
        </span>
        <ol className="CloudFlareForm__intro-orderedlist">
          <li key="1">
            Click{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://developers.cloudflare.com/fundamentals/api/get-started/create-token/"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>{" "}
            for explanations how to create an <span className="bold">API Token</span>.
          </li>
          <li key="2">
          Choose the <span className="bold">Read All Resources</span> API Token Template.
          </li>
          <li key="3">
            Paste the <span className="bold">API Token</span> in the input box
            below.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="cloudflare-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Cloudflare Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="API Token"
          name="token"
          rules={[
            {
              required: true,
              message: "API Token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default CloudFlareForm;
