import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { iacProviders } from "../../utils/icons";

const IacIcon = ({ iacType, customStyle, dark }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const getCustomStyle = () => {
    switch (customStyle) {

      case "table":
        return {
          width: "auto",
          height: "15px"
        };
      default:
        return {};
    }
  };
  return (
    <img
      alt="cloud"
      src={iacProviders(dark ? `${iacType}Dark` : iacType, themeDark)}
      style={getCustomStyle()}
    />
  );
};

export default IacIcon;
