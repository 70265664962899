import React, {
  useState,
  useMemo,
  useEffect,
} from "react";
import _ from "lodash";
import { Checkbox } from "antd";
import { Empty } from "antd";
import SearchBox from "../../../shared/searchBox/searchBox";
import { v4 as uuidv4 } from "uuid";

import FilterContent from "./filterContent";

import "./inventorySingelFilter.scss";

const InventorySingelFilter = ({
  allTypes = [],
  selectedTypes,
  title,
  reset,
  singleSelection,
  active,
  noAnimation,
  checkedList,
  hideText
}) => {
  const CheckboxGroup = Checkbox.Group;
  const [searchVal, setSearchVal] = useState("");
  const [clickedCheckbox, setClickedCheckbox] = useState(null);
  const [isPinnedClicked, setIsPinnedClicked] = useState(false);

  const isPinnedItem = allTypes[0]?.isPinnedItem;

  useEffect(() => {
    if (_.isEmpty(allTypes) || reset) {
      setClickedCheckbox(null);
    }
  }, [allTypes, reset]);

  const filteredTypes = useMemo(() => {
    if (!_.isEmpty(allTypes)) {
      let filteredTypes = isPinnedItem ? _.slice(allTypes, 1) : allTypes;
      if (searchVal) {
        filteredTypes = _.filter(
          filteredTypes,
          (item) =>
            (item.formattedName || "")
              .toLowerCase()
              .includes(searchVal.toLowerCase()) || checkedList?.includes(item?.name)
        );
      }
      return filteredTypes;
    }
  }, [searchVal, selectedTypes, allTypes, reset]);

  const renderTypes = () => {
    if (!_.isEmpty(allTypes)) {
      return _.map(filteredTypes, (item, idx) => {
        return (
          <FilterContent
            key={uuidv4()}
            name={item?.name}
            icon={item?.icon}
            count={item?.count}
            isProdFlag={item?.isProdFlag}
            element={item?.element}
            formattedName={item?.formattedName}
            checkedList={checkedList}
            setClickedCheckbox={(val) => setClickedCheckbox(val)}
            active
            noAnimation={noAnimation}
            provider={item?.provider}
            hideText={hideText}
          />
        );
      });
    }
  };

  const onChange = (list) => {
    if (singleSelection && list?.length > 1) {
      const singleArr = _.filter(list, (item) => item !== clickedCheckbox);
      selectedTypes(singleArr);
    } else {
      selectedTypes(list);
    }
  };

  const handleClearAll = () => {
    selectedTypes([]);
    setClickedCheckbox(null);
    setSearchVal("");
    setIsPinnedClicked(false);
  };

  const handlePinnedClick = () => {
    const toggle = !isPinnedClicked;
    setIsPinnedClicked(toggle);
    const subItems = allTypes[0].subItems || [];
    const updatedArray = toggle ? [...checkedList, ...subItems] : _.filter(checkedList, (item) => !subItems.find(subItem => item === subItem));
    selectedTypes(updatedArray);
  };

  if (_.isEmpty(allTypes)) {
    return (
      <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      </div>
    );
  }

  return (
    <div className="InventorySingelFilter">
      <div className="InventorySingelFilter__title row between">
        <SearchBox
          placeholder={title}
          value={(val) => setSearchVal(val)}
          resetSearch={(val) => setSearchVal(val)}
          grayMode
          noBorder
          hideReset
        />
        {!_.isEmpty(checkedList) && (
          <button
            onClick={handleClearAll}
            className="InventorySingelFilter__title-actions-clear"
          >
            Clear
          </button>
        )}
      </div>

      <div className="InventorySingelFilter__types col">
        {_.isEmpty(filteredTypes) && !_.isEmpty(searchVal) ? (
          <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Results"
            />
          </div>
        ) : (
          <div className="col g5">
           {isPinnedItem && 
            <div className={`InventorySingelFilter__types-item pinned ${isPinnedClicked ? "active" : ""} row g5`} onClick={handlePinnedClick}>
              <span className="InventorySingelFilter__types-item-pinnedText">{allTypes[0].formattedName}</span>
              <div className="InventorySingelFilter__types-item-pinnedText">{allTypes[0].count}</div>
            </div>}
            <CheckboxGroup value={checkedList} onChange={onChange}>
              {renderTypes()}
            </CheckboxGroup>
          </div>
        )}
      </div>
    </div>
  );
};

export default InventorySingelFilter;
