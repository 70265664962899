import React, { useCallback } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faUpload } from "@fortawesome/free-solid-svg-icons";

import { nicknameRegex, validate } from "../../../utils/validations";

const GcpCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const onDrop = useCallback((acceptedFiles) => {
    handleSetJsonFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
  });

  const handleSetJsonFile = (file) => {
    if (!_.isEmpty(file)) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = (e) => {
        let serviceAccountKey = JSON.parse(JSON.stringify(e.target.result, null, 2));

        form.setFieldsValue({
          serviceAccountKey
        });
        setWizardState({...wizardState, ...form.getFieldsValue(), serviceAccountKey});
        return checkValidation({...form.getFieldsValue(), serviceAccountKey})
      };
    }
  }

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, projectId, serviceAccountKey } = formValues;

    if (
      _.isEmpty(name) ||
      _.isEmpty(projectId) ||
      _.isEmpty(serviceAccountKey)
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="GcpCreation col">
      <div className="GcpCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue GcpCreation__intro-orderedlist">
          <li key="1">
            Go to your{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://console.cloud.google.com/iam-admin/serviceaccounts"
              style={{ textDecoration: "underline" }}
            >
              Google Cloud service account
            </a>
            .
          </li>
          <li key="2">
            Add the following roles to the account you want to integrate:{" "}
            <span className="code-flag" style={{ marginRight: '5px' }} >storage.objectViewer</span>
             conditional to tfstate suffix
          </li>
          <li key="3">Select the kebab menu.</li>
          <li key="4">
            Select <span className="code-flag">Manage keys</span>
            {` > `}
            <span className="code-flag">ADD KEY</span>
            {` > `}
            <span className="code-flag">Create new key</span>.
          </li>
          <li key="5">
            Select <span className="code-flag">JSON</span>
            {` > `}
            <span className="code-flag">CREATE</span>. Selecting{" "}
            <span className="code-flag">CREATE</span> downloads a service account key
            file.
          </li>
          <li key="6">
            In Firefly, enter a <span className="code-flag">Nickname</span> and{" "}
            <span className="code-flag">Project ID</span>.
          </li>
          <li key="7">
            Paste or upload the account key file into the{" "}
            <span className="code-flag">Service Account Key</span> box.
          </li>
          <li key="8">
            Select <span className="code-flag">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="gcp-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Google Cloud Storage",
          projectId: wizardState.projectId || "",
          serviceAccountKey: wizardState.serviceAccountKey || "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>
        <Form.Item
          label="Project ID"
          name="projectId"
          rules={[
            {
              required: true,
              message: "Project ID is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter project ID" />
        </Form.Item>
        <Form.Item
          label="Service Account Key"
          name="serviceAccountKey"
          rules={[
            {
              required: true,
              message: "Service account key is required",
            },
          ]}
          style={{ marginBottom: "1rem", flexDirection: "column" }}
        >
          <Input.TextArea
            placeholder="Enter service account key (JSON format)"
            rows={4}
            allowClear
          />
        </Form.Item>

        <div {...getRootProps()} className="drop-zone-box center">
          <input {...getInputProps()} />
          <div className="row" style={{ gap: "8px" }}>
            <span>Or upload / drag a JSON file</span>
            <FontAwesomeIcon icon={faUpload} />
          </div>
        </div>
      </Form>
    </div>
  );
};

export default GcpCreation;
