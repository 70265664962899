import _ from "lodash";

import {
  GET_ENVIRONMENTS,
  CREATE_ENVIRONMENT,
  ENVIRONMENT_DATA,
  SET_ENV_GLOBAL_DATA,
} from "../actions/environmentActions";

const initialState = {
  environments: [],
  environmentData: {},
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ENVIRONMENTS:
      let tempEnv = [];
      if (!_.isEmpty(payload.data)) {
        tempEnv = payload.data.map((item) => ({
          ...item,
          type: "iac",
        }));
      }
      return { ...state, environments: tempEnv };

    case CREATE_ENVIRONMENT:
      return { ...state };

    case ENVIRONMENT_DATA:
      return { ...state, environmentData: payload.data };

    case SET_ENV_GLOBAL_DATA:
      return { ...state, environmentData: payload };

    default:
      return state;
  }
}
