import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import OpsgenieCreation from "./opsgenieCreation";

import {
  createOpsgenieIntegration,
  testOpsgenieIntegration,
} from "../../../redux/actions/integrationsActions";
import { integrationIcons } from "../../../utils/icons";

import "./opsgenieIntegration.scss";

const getTypeFromPathName = (history) => {
  const lastPath = history?.location?.pathname?.split("/")[2];
  return lastPath ? lastPath.split("-")[0] : "";
}

const OpsgenieIntegration = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const defaultTags = ["firefly"]
  const [wizardState, setWizardState] = useState({
    apiKey: "",
    tags: defaultTags
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  
  const type = history?.location?.state?.type || getTypeFromPathName(history);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "API key creation",
      content_title: `${_.capitalize(type)} Integration`,
      content: (
        <OpsgenieCreation
          type={type}
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          defaultTags={defaultTags}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
            const opsgenieAPIKeyOk = await dispatch(
              testOpsgenieIntegration(wizardState.apiKey)
            );
            if (opsgenieAPIKeyOk) {
              const created = await dispatch(
                createOpsgenieIntegration(wizardState.name, wizardState.apiKey, wizardState.tags)
              );
              setLoadingSubmit(false);
              if (!created) {
                throw new Error("Invalid api key");
              }
            } else {
              setLoadingSubmit(false);
              throw new Error("Invalid api key");
            }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="WebhookIntegraion">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={integrationIcons[type]}
      />
    </div>
  );
};

export default OpsgenieIntegration;
