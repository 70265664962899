import React, { useState, useEffect } from "react";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import {
  getRepositoriesWithModules,
  getModulesByRepo,
  moduleCall,
  clearModuleData,
  setPullRequestParameters,
} from "../../../../redux/actions/iacImportActions";
import { setTerraformCmds } from "../../../../redux/actions/inventoryv3Actions";
import { v4 as uuidv4 } from "uuid";
import Select from "antd/lib/select";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import find from "lodash/find";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import { clouds } from "../../../../utils/icons";
import AppToggle from "../../../../shared/appToggle/appToggle";

import { Divider, Radio } from "antd";
import ModulesResources from "./modulesResources";

import "./modulesTab.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const NON_TOGGLE_VCS_SUPOORT = ['github', 'bitbucket'];
const MAX_STRING_LENGTH_TO_RENDER = 50000;


const ModulesTab = ({ description, prefix, selectedResources, isImportBlocksClicked, importBlocksComment, handleOverweightData, OverweightDataDisplay }) => {
  const dispatch = useDispatch();
  const [loadingInit, setLoadinInit] = useState(true);
  const [loadingModule, setLoadingModule] = useState(false);
  const [loadingCreateModule, setLoadingCreateModule] = useState(false);
  const [dependenciesToggle, setDependenciesToggle] = useState(true);
  const [seperateToggle, setSeperateToggle] = useState(true);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [showModuleProtocolRadio, setShowModuleProtocolRadio] = useState(false)
  const [moduleProtocol, setModuleProtocol] = useState('ssh')

  const moduleRepos = useSelector(
    (state) => state.iacImportReducer.moduleRepos
  );
  const moduleData = useSelector((state) => state.iacImportReducer.moduleData);
  const modules = useSelector((state) => state.iacImportReducer.modules);
  const pullReqParams = useSelector(
    (state) => state.iacImportReducer.pullReqParams
  );
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  useEffect(() => {
    getInitData();
    return () => {
      dispatch(clearModuleData());
    };
  }, []);

  const getResourceTypes = (isSameTypeResourcesCheck = false) => {
    let resourcesTypes = [];
    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];
      resourcesTypes.push(resource?.assetType);
    }
    const uniqueResourcesTypes = _.uniq(resourcesTypes);
    if (isSameTypeResourcesCheck) {
      return uniqueResourcesTypes.length === 1
    }
    return uniqueResourcesTypes
  }

  const allowSeperateModules = selectedResources?.length > 1 && getResourceTypes(true)

  const getInitData = async () => {
    setLoadinInit(true);
    let resourcesTypes = getResourceTypes();
    await Promise.all([
      dispatch(getRepositoriesWithModules(resourcesTypes)),
    ]);
    setLoadinInit(false);
  };

  const getModules = async (repoName) => {
    if (repoName) {
      setLoadingModule(true);
      setSelectedModule(null);
      let resourcesTypes = getResourceTypes();
      await dispatch(getModulesByRepo(repoName, resourcesTypes));
      setLoadingModule(false);
    }
  };

  const handleCreateModule = async (md5, useDependencies, protocol, useSeperate) => {
    setLoadingCreateModule(true);

    let assetType = "";
    let assetId = "";
    let provider = "";
    let providerId = "";
    let resources = [];

    for (let i = 0; i < selectedResources?.length; i++) {
      const resource = selectedResources[i];

      if (i === 0) {
        assetType = resource.assetType;
        assetId = resource.assetId;
        provider = resource.provider;
        providerId = resource.integrationId;
      } else {
        resources.push({
          terraformAssetType: resource.assetType,
          providerId: resource.integrationId,
          assetId: resource.assetId,
        });
      }
    }

    const payload = {
      moduleMD5: md5,
      sourceType: find(modules || [] , mod => mod?.moduleFullPathMD5 === md5)?.moduleSourceType,
      assetType,
      assetId,
      providerId,
      provider,
      resources,
      codificationMode: useDependencies ? "CALL_MODULE" : "CALL_MODULE_WITHOUT_DEPENDENCIES",
      moduleProtocol: protocol || moduleProtocol,
      separateModuleCall: allowSeperateModules && useSeperate,
    };

    let req = await dispatch(moduleCall(payload));
    await dispatch(setTerraformCmds(req?.TerraformCMDs));
    setLoadingCreateModule(false);
  };

  const renderRepos = () => {
    if (!isEmpty(moduleRepos)) {
      return map(moduleRepos || [], ({ repoFullName = "", vcsType= "", isPublic = false } = {}) => {
        return (
          <Select.Option value={[repoFullName, vcsType]} key={repoFullName}>
            <span className={`ModulesTab__repo row between`}>
              <div className="row g15">
                <img src={clouds(vcsType, themeDark)} alt="integration" />
                {repoFullName}
              </div>
              {isPublic && <span className="purple-flag ModulesTab__repo-public">Public</span>}
            </span>
          </Select.Option>
        );
      });
    }
  };

  const renderModules = () => {
    if (!isEmpty(modules) && selectedRepo) {
      return map(modules || [], (mod) => {
        return (
          <Select.Option value={mod?.moduleFullPathMD5} key={uuidv4()}>
            {mod?.moduleName}
          </Select.Option>
        );
      });
    }
  };
  const needToRenderCode = !isEmpty(moduleData) && !isEmpty(moduleData?.HCL);
  const needToRenderNoData = !loadingCreateModule && isEmpty(moduleData?.HCL) && !isEmpty(selectedRepo) && !isEmpty(selectedModule);
  const noHeaderBottomBorder = needToRenderCode && !isEmpty(selectedModule) && !isEmpty(selectedRepo) && !loadingCreateModule;

  const renderCode = () => {
    if (loadingCreateModule) {
      return (
        <div className="empty-code center">
          <Loading />
        </div>
      );
    }

    if (isEmpty(selectedRepo) || isEmpty(selectedModule)) {
      return null;
    }

    if (needToRenderNoData) {
      return (
        <div className="empty-code center">
          <AppEmpty text="No data" customStyle="code" />
        </div>
      );
    }

    if (needToRenderCode) {
      let code = `${prefix}${isImportBlocksClicked && !isEmpty(moduleData?.TerraformImportBlocks)  ? `${importBlocksComment}${moduleData?.TerraformImportBlocks?.join("\n")}`: ''} \n${moduleData?.HCL}`
      if (code.length > MAX_STRING_LENGTH_TO_RENDER) {
        handleOverweightData(true);
        return (
          <div className="empty-code center">
            {OverweightDataDisplay}
          </div>
        );
      }
      return (
        <div className="ModulesTab__code-container col">
          <SyntaxHighlighter
              style={atomDark}
              showLineNumbers={true}
              language="hcl"
              lineProps={{
                style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
              }}
            >
              {code}
          </SyntaxHighlighter>
          <ModulesResources moduleData={moduleData} />
        </div>
      );
    }
  };

  const handleOnChangeModule = (module, useDependencies, protocol, useSeperate) => {
    setSelectedModule(module);
    dispatch(
      setPullRequestParameters({
        ...pullReqParams,
        moduleMD5: module,
        sourceType: find(modules || [] , mod => mod?.moduleFullPathMD5 === module)?.moduleSourceType,
        repo: selectedRepo,
        moduleProtocol: protocol || moduleProtocol,
      })
    );
    handleCreateModule(module, useDependencies, protocol, useSeperate);
  };
  
  const onChangeModuleProtocol = (e) => {
    const newMoudleProtocol = e.target.value;
    if (selectedModule) {
      handleOnChangeModule(selectedModule, dependenciesToggle, newMoudleProtocol)
    }
    setModuleProtocol(newMoudleProtocol)
  };

  if (loadingInit) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="ModulesTab col">
      <div className={`ModulesTab__header ${noHeaderBottomBorder ? 'noBottom' : ''} col`}>
        <span className="ModulesTab__header-description row g15">
          <div className="ModulesTab__header-description-mark">
                <FontAwesomeIcon icon="info-circle" className="ModulesTab__header-description-mark-dot"/>
                <div className="ModulesTab__header-description-mark-line"/>
          </div>
          {description}
          </span>
        <div className="ModulesTab__header-select">
          <Select
            placeholder={loadingInit ? "loading..." : "Choose Repo"}
            showSearch
            filterOption={(input, option) => {
              const [repoName] = option?.value || []
              return repoName?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            }
            style={{ width: "100%" }}
            disabled={loadingInit || loadingModule}
            loading={loadingInit}
            className="ModulesTab__header-select-drop"
            onChange={(item) => {
              const [repoName, vcsType] = item || []
              getModules(repoName);
              setSelectedRepo(repoName)
              if (!NON_TOGGLE_VCS_SUPOORT.includes(vcsType)) {
                return setShowModuleProtocolRadio(true)
              }
              setShowModuleProtocolRadio(false)
            }}
          >
            {renderRepos()}
          </Select>

          <Select
            placeholder={loadingModule ? "loading..." : "Choose Module"}
            showSearch
            filterOption={(input, option) =>  option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            style={{ width: "100%" }}
            disabled={loadingModule}
            loading={loadingModule}
            value={selectedModule}
            className="ModulesTab__header-select-drop"
            onSelect={(module) => handleOnChangeModule(module, dependenciesToggle, moduleProtocol, seperateToggle)}
          >
            {!loadingModule && renderModules()}
          </Select>
        </div>
        <div className="ModulesTab__header-checkbox row">
          <div className="ModulesTab__header-checkbox-item">
            <AppToggle
              text="Codify with dependencies"
              checked={dependenciesToggle}
              toggleChecked={() => {
                let useDependencies = !dependenciesToggle
                setDependenciesToggle(!dependenciesToggle);
                if (selectedModule !== null) {
                  handleOnChangeModule(selectedModule, useDependencies, moduleProtocol, seperateToggle)
                }
              }}
            >
            </AppToggle>
          </div>
        {allowSeperateModules && <div className="ModulesTab__header-checkbox-item">
          <AppToggle
              text="Seperate module call for each resource"
              checked={seperateToggle}
              toggleChecked={() => {
                let useSeperate = !seperateToggle
                setSeperateToggle(!seperateToggle);
                if (selectedModule !== null) {
                  handleOnChangeModule(selectedModule, dependenciesToggle, moduleProtocol, useSeperate)
                }
              }}
            >
            </AppToggle>
          </div>
        }
          <div className="row">
            <Divider type="vertical" />
            <div className="row ModulesTab__header-radio">
              <span className={`ModulesTab__header-radio-text ${!showModuleProtocolRadio ? 'disabledText':  ''}`}>Git Connection Protocol</span>
              <Radio.Group onChange={onChangeModuleProtocol} value={moduleProtocol} disabled={!showModuleProtocolRadio}>
              <Radio value="ssh" key="ssh">ssh</Radio>
              <Radio value="https" key="https">https</Radio>
            </Radio.Group>
            </div>
          </div>
        </div>
        
      </div>

      <div className="ModulesTab__code">{renderCode()}</div>
  
    </div>
  );
};

export default ModulesTab;
