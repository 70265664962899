import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { sendEvent } from "../../utils/amplitude";
import { integrationEvents } from "../../utils/amplitudeEvents";

export const CREATE_AZURE_INTEGRATION = "CREATE_AZURE_INTEGRATION";
export const EDIT_AZURE_INTEGRATION = "EDIT_AZURE_INTEGRATION";
export const DELETE_AZURE_INTEGRATION = "DELETE_AZURE_INTEGRATION";

const createAzureIntegration = (payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/azure`,
      "POST",
      payload
    );
    const { method, isProd, isEventDriven, isIacAutoDiscoverDisabled } = payload;
    
    sendEvent(integrationEvents.azureIntegrationCreation, {
      method,
      isProd,
      isEventDriven,
      isIacAutoDiscoverDisabled,
    });

    dispatch({
      type: CREATE_AZURE_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteAzureIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/azure/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_AZURE_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const updateAzureIntegration = payload => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/azure/${payload?._id}`,
      "put",
      payload
    );
    const { method, isProd, isEventDriven, isIacAutoDiscoverDisabled } = payload;
    
    sendEvent(integrationEvents.azureIntegrationEdit, {
      method,
      isProd,
      isEventDriven,
      isIacAutoDiscoverDisabled,
    });

    dispatch({
      type: EDIT_AZURE_INTEGRATION,
      payload: {},
    });
    return res;
  });
}

export { createAzureIntegration, deleteAzureIntegration, updateAzureIntegration };
