import React from 'react';
import _ from "lodash";
import './provisionLangs.scss';

const ProvisionLangs = ({ options ,selected, onClick }) => {

    if(_.isEmpty(options)) {
        return null;
    };
    
    return (
        <div className="ProvisionLangs row g10">
            {_.map(options, (item) => (
            <div 
                onClick={() => onClick(item)}
                className={`ProvisionLangs__item center ${selected === item?.name ? 'active' : ''}`} 
                key={item?.name}
            >
                {item?.name}
            </div>
            ))}
        </div>
    )
}

export default ProvisionLangs;
