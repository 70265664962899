import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { clouds } from "../../../utils/icons";
import {  getInstructionsCode } from "../../../redux/actions/keyPairsActions";
import { createK8sIntegration } from "../../../redux/actions/k8sIntegrationActions";

import AppWizard from "../../../shared/appWizard/appWizard";
import K8sOverview from "./k8sOverview";
import K8sFinalInstructions from "./k8sFinalInstructions";
import "./k8sIntegration.scss";
import ArgoCdOverivew from "./argoCdOverview";
import { iacProviders } from "../../../utils/icons";

const K8sIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [isProd, setIsProd] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    setPreviousDisabled(false);
  }, []);

  const handleK8SInstallation = async() => {
    setLoadingSubmit(true);
    setPreviousDisabled(true);
    const data = await dispatch(getInstructionsCode("k8s", { clusterId: wizardState?.clusterId, schedule: wizardState?.schedule, namespace: wizardState?.namespace }));
    setWizardState({ ...wizardState, code: data?.code });
    if (_.isEmpty(data?.code)) {
      setLoadingSubmit(false);
      throw new Error("Failed to get instructions code. Please try again");
    }
    let argoData = {};
    const { argoDomain, argoToken, awsBucket, awsBucketRegion } = wizardState || {};
    if (wizardState?.withArgo) {
      argoData = {
        argoDomain,
        argoToken,
        awsBucket,
        awsBucketRegion,
      };
    }
    const created = await dispatch(
      createK8sIntegration(
        wizardState.clusterId,
        data?.accessKey,
        wizardState.name,
        isProd,
        argoData
      )
    );
    if (!created) {
      setLoadingSubmit(false);
      throw new Error(
        "Failed to save Kubernetes integration. Please try again"
      );
    }
    setLoadingSubmit(false);

  }

  const configWizard = [
    {
      step_title: "Overview",
      step_description: "Information and requirements",
      content_title: "Kubernetes integration",
      content: (
        <K8sOverview
          setSubmitDisabled={setSubmitDisabled}
          wizardState={wizardState}
          setWizardState={setWizardState}
          isProd={isProd}
          handelSetIsProd={() => setIsProd(!isProd)}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
       if (!wizardState?.withArgo) await handleK8SInstallation();
      },
      skipNextStep: !wizardState?.withArgo
    },
    {
      step_title: "Argo CD",
      step_description: "Information and requirements (optional)",
      content_title: "Argo CD",
      content: (
        <ArgoCdOverivew
          setSubmitDisabled={setSubmitDisabled}
          wizardState={wizardState}
          setWizardState={setWizardState}
        />
      ),
      valid: true,
      customStepIcon: iacProviders("argo", themeDark),
      beforeNextStep: async () => {
        await handleK8SInstallation();
      }
    },
    {
      step_title: "Completion",
      step_description: "Installation instructions",
      content_title: "Installation instructions",
      content: (
        <K8sFinalInstructions
          wizardState={{...wizardState}}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="K8SIntegration">
      <AppWizard
        iconSrc={clouds("k8s", themeDark)}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        prevDisabled={previousDisabled}
      />
    </div>
  );
};

export default K8sIntegration;
