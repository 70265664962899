import React from "react";
import { Form, Input, Checkbox } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";

const PulumiCreation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
  crawlerCreate,
  setCrawlerCreate,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, token } = formValues;

    if (_.isEmpty(token)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="PulumiCreation col">
      <div className="PulumiCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue PulumiCreation__intro-orderedlist">
          <li key="1">
            Go{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://app.pulumi.com/"
              style={{ textDecoration: "underline" }}
            >
              here
            </a>
            , to create a Pulumi access token.
          </li>
          <li key="2">
            Select <span className="bold">Settings</span>
            {` > `}
            <span className="bold">Access Tokens</span>
            {` > `}
            <span className="bold">Create token</span>.
          </li>
          <li key="3">Copy the token.</li>
          <li key="4">
            In Firefly, enter a <span className="bold">Nickname</span>, and
            paste the <span className="bold">Pulumi access token</span>.
          </li>
          <li key="5">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="pulumi-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Pulumi Cloud Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please fill integration name" />
        </Form.Item>
        <Form.Item
          label="Pulumi access token"
          name="token"
          tooltip="Your access token begins with pul-."
          rules={[
            {
              required: true,
              message: "Pulumi access token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="" />
        </Form.Item>
        <div className="fetchable-selection">
          <Checkbox checked={crawlerCreate} onChange={setCrawlerCreate}>
            <span className="form-label">
              Auto fetch all stacks from this integration?
            </span>
          </Checkbox>
        </div>
      </Form>
    </div>
  );
};

export default PulumiCreation;
