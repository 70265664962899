import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import PagerDutyForm from "./pagerDutyForm";
import { integrationIcons } from "../../../utils/icons";

import { createPagerdutyIntegration } from "../../../redux/actions/integrationsActions";

import "./pagerDutyIntegration.scss";

const PagerDutyIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "PagerDuty Integration setup",
      content_title: "PagerDuty Integration",
      content: (
        <PagerDutyForm
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const req = await dispatch(
          createPagerdutyIntegration(
            wizardState?.name,
            wizardState?.apiKey,
            wizardState?.serviceType,
          )
        );
        setLoadingSubmit(false);
        if (!req?.ok) {
          throw new Error(
            `Failed to create PagerDuty integration.`
          );
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="PagerDutyIntegration">
      <AppWizard
        iconSrc={integrationIcons["pagerduty"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default PagerDutyIntegration;
