import React from "react";
import { useTranslation } from "react-i18next";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Checkbox, Divider, Input, Tooltip, Select } from "antd";
import TextArea from "antd/lib/input/TextArea";
import './tfVar.scss';
import useWindowSize from "../../../hooks/useWindowSize";

const TfVar = ({
    tfVar = {},
    onChangeValue
}) => {
    const { t } = useTranslation("compose", { keyPrefix: "moduleCall.tooltips" });
    const { width: screenWidth } = useWindowSize(); 
    const isSmallScreenWidth = screenWidth < 1250;

    const {
        name = '',
        description= '',
        required = false,
        validation = '',
        type = '',
        default: defaultValue = '',
        sensitive = false,
        key,
        options = []
    } = tfVar;

    const renderValueInputByType = () => {
        const isOptionsArr = options?.length > 0;
        if (isOptionsArr) {
            const mappedOptions = options.map((option) => ({ label: option, value: option }));
            return <Select options={mappedOptions} onSelect={(option) => onChangeValue(option, key)}/>
        }
        if (type === 'bool') {
            return <Checkbox className="TfVar__input" checked={defaultValue} onChange={(e) => onChangeValue(e.target.checked, key)}/>
        }
        if (type === 'number' || type === 'string') {
            return <Input className="TfVar__input" value={defaultValue} onChange={(e) => onChangeValue(e.target.value, key)}/>
        }
        //otherwise lists/array/objects
        return <TextArea className="TfVar__textArea" value={defaultValue} onChange={(e) => onChangeValue(e.target.value, key)}/>
    }

    return (
        <div className={`TfVar ${isSmallScreenWidth ? 'smallScreen': ''}`} key={key}>
            <div className="row g10">
                <div className={`TfVar__icon ${required ? 'active' : ''} row`}>
                    <Tooltip title={`${t(required ? 'required': 'noRequired')}`}>
                        <FontAwesomeIcon width={25} icon="check-circle" />
                    </Tooltip>
                </div>
                <div className={`TfVar__icon ${sensitive ? 'active' : ''} row`}>
                    <Tooltip title={`${t(sensitive ? 'sensitive': 'noSensitive')}`}>
                        <FontAwesomeIcon width={25} icon="asterisk"/>
                    </Tooltip>
                </div>
                <div className={`TfVar__icon ${validation ? 'active' : ''} row`}>
                    <Tooltip title={!validation ? t('noValidation') : <div className="col">{t('validationBlock')}: <span>{validation}</span></div>}>
                        <FontAwesomeIcon width={25} icon="fingerprint"/>
                    </Tooltip>
                </div>
                <div className={`TfVar__icon ${description ? 'active' : ''} row`}>
                    <Tooltip title={!description ? t('noDescription') : <div className="col">{t('variableDescription')}: <span>{description}</span></div>}>
                        <FontAwesomeIcon width={25} icon="question-circle"/>
                    </Tooltip>
                </div>
            </div>
           <Divider className="TfVar__divider" type="vertical"/>
           <Tooltip title={`${t('variableType')} - ${type}`}>
                <span className="TfVar__type">{type}</span>
           </Tooltip>
           <Tooltip title={`${t('variableName')} - ${name}`}>
            <span className="TfVar__name">{name}</span>
           </Tooltip>
           <Tooltip title={t('variableValue')}>
               {renderValueInputByType()}
            </Tooltip>
        </div>)
}

export default TfVar;