import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setFilters } from "../../../redux/actions/inventoryActions";
import { setSearchVal } from "../../../redux/actions/globalAppActions";
import Radio from "antd/lib/radio";
import Spin from "antd/lib/spin";
import Empty from "antd/lib/empty";
import SingleView from "./singleView";

const ViewsList = ({ handleDelete, loading, handleSave, handleDefault }) => {
  const dispatch = useDispatch();
  const [activeView, setActiveView] = useState([]);
  const views = useSelector((state) => state.screenViewReducer.views);
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  useEffect(() => {
    handleSetActiveIfScreenFilterMatch()
  },[screenFilters, views])

  const handleSetActiveIfScreenFilterMatch = () => {
    if (!isEmpty(views)) {
      const match = views?.find((view) =>
        isEqual(screenFilters, view?.filters)
      );
      if (match) {
        return setActiveView([match?.id]);
      } else {
        return setActiveView([]);
      }
    }
  }

  const renderList = () => {
    if (!isEmpty(views)) {
      return map(views, (item) => {
        if (!item?.isTemporary) {
          return (
            <SingleView
              key={item?.id}
              name={item?.name}
              isDefault={item?.isDefault}
              setSelected={() => {
                dispatch(setSearchVal(item?.filters?.freeSearchText));
                dispatch(setFilters(item?.filters));
                setActiveView([item?.id])
                return;
              }}
              active={activeView?.includes(item?.id)}
              id={item?.id}
              isCustom={item?.isCustom}
              handleDelete={handleDelete}
              handleDefault={handleDefault}
            />
          );
        }
      });
    }
  };

  const onChange = (e) => {
    setActiveView([e.target.value]);
  };

  const renderViewsWrapper = () => {
    if (isEmpty(views) && !loading) {
      return (
        <div className="ViewsList__empty center col">
          <Empty
            image={Empty.PRESENTED_IMAGE_SIMPLE}
            description="You have not saved any views"
          />
        </div>
      );
    }
    return (
      <div className="ViewsList__items">
        <Radio.Group
          value={!isEmpty(activeView) ? activeView[0] : []}
          onChange={onChange}
        >
          {renderList()}
        </Radio.Group>
      </div>
    );
  };

  return (
    <div className="ViewsList">
      {loading ? (
        <div className="ViewsList__loading center">
          <Spin />
        </div>
      ) : (
        renderViewsWrapper()
      )}
      <div className="ViewsList__footer row">
        <span className="row ViewsList__footer-save" onClick={handleSave}>
          <FontAwesomeIcon
            icon="save"
            className="ViewsList__footer-save-icon"
          />
          <span className="ViewsList__footer-save-name">Save Current View</span>
        </span>
      </div>
    </div>
  );
};

export default ViewsList;
