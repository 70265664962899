import React, { useState } from 'react';
import CodeDiff from '../../../../../shared/codeDiff/codeDiff';
import ActionBtn from '../../../../../shared/actionBtn/actionBtn';
import Configuration from '../../../../inventory/assetDataModal/assetConfig/configuration';
import { sendEvent } from '../../../../../utils/amplitude';
import { CiEvents } from '../../../../../utils/amplitudeEvents';
import { useTranslation } from 'react-i18next';

import './planDrift.scss';

const PlanDrift = ({ diffs = [] }) => {
    const [jsonView, setJsonView] = useState(false);
    const jsonDiffs = JSON.stringify(diffs);
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "postPlan.planDrift"});

    return (
        <div className="PlanDrift col g10">
             <span className="title">{t("title")}</span>
             { jsonView ? <Configuration item={jsonDiffs} />
             : <div className="PlanDrift__table col">
                <div className="PlanDrift__table-header">
                    <span className="PlanDrift__table-header-title">{t("property")}</span>
                    <span className="PlanDrift__table-header-title">{t("currentConfiguration")}</span>
                    <span className="PlanDrift__table-header-title">{t("desiredConfiguration")}</span>
                </div>
                <div className="PlanDrift__table-body">
                    {diffs.map((item, index) => {
                        const { path = '', before = '', after = '' } = item;
                        const beforeString = JSON.stringify(before);
                        const afterString = JSON.stringify(after);
                        return (
                            <div className="PlanDrift__table-row" key={index}>
                                <span className="PlanDrift__table-row-title">{path}</span>
                                <CodeDiff oldValue={beforeString} newValue={afterString} />
                            </div>
                        )
                    })}
                </div>
             </div>}
             <div className="PlanDrift__actions row">
                    <ActionBtn text="" action="json" icon="json" onClick={() => setJsonView(!jsonView)} purple/>
                    <ActionBtn text="" icon="copy" action="copy" stringToAction={jsonDiffs} purple onClickDifferentActions={() => sendEvent(CiEvents.ciRunResourceCopyClick, {})}/>
                    <ActionBtn
                        purple
                        text=""
                        action="download"
                        fileType="json"
                        fileName="resource-plan-drift"
                        icon="donwload"
                        stringToAction={jsonDiffs}
                    />               
             </div>
        </div>
    );
};

export default PlanDrift;