import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_INSIGHTS_AGGREGATIONS = "GET_INSIGHTS_AGGREGATIONS";
export const GET_INSIGHTS_ASSETS = "GET_INSIGHTS_ASSETS";
export const GET_DASHBOARD_INSIGHTS = "GET_DASHBOARD_INSIGHTS";
export const SET_INSIGHTS_FILTERS = "SET_INSIGHTS_FILTERS";
export const GET_LATEST_INSIGHTS = "GET_LATEST_INSIGHTS";

const getInsightsAggregations = (showExclusions, accounts, showProduction, prodAccounts) => {
  return action(async (dispatch) => {
    let baseQuery = {
      queryPage: 1,
      queryAdditionalFilters: [],
    };

    baseQuery["aggregations"] = {
      classifications: {
        terms: {
          field: "classifications.keyword",
          size: 10000,
        },
        aggs: {
          accounts: {
            terms: {
              field: "providerId.keyword",
              size: 10000,
            },
          },
        },
      },
    };

      // create the filter accounts of the query
  if (!_.isEmpty(accounts)) {
    let accountsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(accounts, (filter) => {
      accountsQuery.bool.should.push({
        term: {
          "providerId.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(accountsQuery);
  }

  if (showProduction && !_.isEmpty(prodAccounts)) {
    let prodAccountsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(prodAccounts, (account) => {
      prodAccountsQuery.bool.should.push({
        term: {
          "integrationId.keyword": {
            value: account?.id,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(prodAccountsQuery);
  }

    baseQuery.isExcludedReturns = showExclusions;

    baseQuery.size = 0;

    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/`,
      "POST",
      baseQuery
    );
    const data = await req.json();

    dispatch({
      type: GET_INSIGHTS_AGGREGATIONS,
      payload: data,
    });
  });
};

const getInsightsAssets = (showExclusions, insights, filterPage, accounts, issue, showProduction, prodAccounts, sorting, tablePageSize) => {
  let baseQuery = {
    queryPage: filterPage - 1,
    queryAdditionalFilters: [],
    queryAdditionalFilterOut: [],
    classificationId: "",
    size: tablePageSize
  };

  // create the filter insight of the query
  if (!_.isEmpty(insights)) {
    let classificationsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(insights, (filter) => {
      classificationsQuery.bool.should.push({
        term: {
          "classifications.keyword": {
            value: filter,
          },
        },
      });
      baseQuery.classificationId = filter;
    });
    baseQuery.queryAdditionalFilters.push(classificationsQuery);
  }

  // create the filter accounts of the query
  if (!_.isEmpty(accounts)) {
    let accountsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(accounts, (filter) => {
      accountsQuery.bool.should.push({
        term: {
          "providerId.keyword": {
            value: filter,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(accountsQuery);
  }

  if (showProduction && !_.isEmpty(prodAccounts)) {
    let prodAccountsQuery = {
      bool: {
        should: [],
        minimum_should_match: 1,
      },
    };
    _.forEach(prodAccounts, (account) => {
      prodAccountsQuery.bool.should.push({
        term: {
          "integrationId.keyword": {
            value: account?.id,
          },
        },
      });
    });
    baseQuery.queryAdditionalFilters.push(prodAccountsQuery);
  }

  // set the sorting by date parameter
  baseQuery.isDateSortedDescending = true;
  baseQuery.isExcludedReturns = showExclusions;
  baseQuery.sorting = sorting;

  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventoryV2/classification`,
      "POST",
      baseQuery
    );
    const data = await req.json();
    if (!issue) {
      dispatch({
        type: GET_INSIGHTS_ASSETS,
        payload: data,
      });
    }
    return data;
  });
};

const getDashboardInsights = (onlyProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/classifications/dashboard`,
      "POST",
      { onlyProd }
    );

    const data = await response.json();

    dispatch({
      type: GET_DASHBOARD_INSIGHTS,
      payload: { data },
    });

    return data;
  });
};

const getLatestInsights = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/classifications/latest`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: GET_LATEST_INSIGHTS,
      payload: { data },
    });

    return data;
  });
};

const setInsightsFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_INSIGHTS_FILTERS,
      payload: filters,
    });
  });
};

export { getInsightsAggregations, getInsightsAssets, getDashboardInsights, getLatestInsights, setInsightsFilters };
