import React from "react";
import { Button } from "antd";
import EvaluateIcon from "../../../Images/general_icons/evaluate_Icon.svg";

import "./regoCodeBlock.scss";
import CodeBlock from "../../../shared/codeBlock/codeBlock";

const RegoCodeBlock = ({
  code,
  onChange,
  readOnly,
  handleTestRule,
  loadingTest,
}) => {
  return (
    <div className="RegoCodeBlock">
      <CodeBlock 
      height="100%"
      editable={!readOnly}
      code={code}
      onChange={onChange}
      lang="js"
      />
      <Button
        type="primary"
        loading={loadingTest}
        onClick={handleTestRule}
        icon={
          <img
            src={EvaluateIcon}
            alt=""
            style={{ marginRight: "8px", transform: "translateY(-1px)" }}
          />
        }
        style={{
          position: "absolute",
          bottom: "20px",
          right: "20px",
          height: "30px",
          paddingTop: "3px",
        }}
      >
        Evaluate
      </Button>
    </div>
  );
};

export default RegoCodeBlock;
