import React from "react";
import { useHistory } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../utils/formatting";
import AppBtn from "../../../shared/appBtn/appBtn";
import integrationsIcon from "../../../Images/general_icons/connected-integrations.svg";
import "./integrationsHeader.scss";

const IntegrationsHeader = ({ count }) => {
  const history = useHistory();
  const { t } = useTranslation("integrations");
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const handleNavigateCatalog = () => {
    return history.push("/integrations/new-integration");
  };

  return (
    <div className="IntegrationsHeader row between">
      <div className="row g10">
        <img src={integrationsIcon} alt="icon" />
        <span className="title bold">{t("header.title", { count: numberWithCommas(count) })}</span>
      </div>
      <AppBtn
        text={t("header.add-btn")}
        onClick={handleNavigateCatalog}
        icon={<FontAwesomeIcon icon="plus" />}
        disabled={isViewer}
      />
    </div>
  );
};

export default IntegrationsHeader;
