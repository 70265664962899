import React, { useState, useEffect, useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import moment from "moment";
import SmallCalendar from "../../../../../shared/smallCalendar/smallCalendar";
import './historyCalendarPopover.scss'

const HistoryCalendarPopover = ({ selectedRevision, setSelectedRevision }) => {
  const revisions = useSelector((state) => state.historyReducer.revisionsWithoutCurrent);
  const [revisionHoursbyDate, setRevisionHoursByDate] = useState([]);
  const [selectedDate, setSelectedDate] = useState(
    selectedRevision?.time || null
  );

  useEffect(() => {
    groupDatesByDay();
  }, [revisions]);

  const groupDatesByDay = () => {
    if (!_.isEmpty(revisions)) {
      // add another key to represnt the day without hours
      // by setting all timestamps to the startof the day

      const arrWithDayKey = _.map(revisions || [], (i) => {
        let newTime = moment(i?.time).startOf("day").toDate();
        return { ...i, day: newTime };
      });

      const groupByDay = _.groupBy(arrWithDayKey || [], "day");

      const output = _.map(groupByDay || {}, (val, key) => {
        return { day: key, items: val };
      });
      setRevisionHoursByDate(output);
    }
  };

  const renderActiveDates = () => {
    if (revisions) {
      return _.map(revisions || [], (r) => r?.time);
    }
    return [];
  };

  const handleSelectDate = (value) => {
    setSelectedDate(moment(new Date(value)).toDate());
  };

  const renderMatchingHours = useMemo(() => {
    if (!_.isEmpty(revisions) && !_.isEmpty(revisionHoursbyDate)) {
      const matchingDayRevisions = _.find(revisionHoursbyDate || [], (item) => {
        let newTime = moment(new Date(selectedDate)).startOf("day").valueOf();
        return moment(new Date(item?.day)).startOf("day").valueOf() === newTime;
      });

      return _.map(matchingDayRevisions?.items || [], (x) => (
        <div
          key={x?.runId}
          className={`HistoryCalendarPopover__hours-list-item ${
            selectedRevision?.runId === x?.runId ? "active" : ""
          }`}
          onClick={() => setSelectedRevision(x)}
        >
          {moment(x?.time).format("HH:mm")}
        </div>
      ));
    }
  }, [revisionHoursbyDate, revisions, selectedDate, selectedRevision]);

  if (_.isEmpty(revisions)) {
    return null;
  }

  return (
    <div className="HistoryCalendarPopover col">
      <SmallCalendar
        value={selectedDate}
        onChange={handleSelectDate}
        maxDate={revisions?.[revisions?.length - 1]?.time}
        minDate={revisions?.[0]?.time}
        activeDates={renderActiveDates()}
      />
      <div className="HistoryCalendarPopover__hours col center">
        <span className="bold center">Avilable Versions</span>
        <div className="HistoryCalendarPopover__hours-list row">{renderMatchingHours}</div>
        </div>
    </div>
  );
};

export default HistoryCalendarPopover;
