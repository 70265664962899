import React, {
  useState,
  useMemo,
  useRef,
  useLayoutEffect,
} from "react";
import _ from "lodash";
import { Radio } from "antd";
import { Empty } from "antd";
import SearchBox from "../../../shared/searchBox/searchBox";
import { v4 as uuidv4 } from "uuid";

import FilterContent from "./filterContent";

import "./inventorySingelFilter.scss";

const InventorySingelRadioFilter = ({
  allTypes,
  selectedTypes,
  title,
  reset,
  active,
  noAnimation,
  checkedList
}) => {
  const [searchVal, setSearchVal] = useState("");

  // dynamic dimentions for scrolling
  const parentRef = useRef(null);
  const [parentWidth, setParentWidth] = useState(0);

  useLayoutEffect(() => {
    if (parentRef.current) {
      setParentWidth(parentRef.current.offsetWidth * 0.75);
    }
  }, []);

  const filteredTypes = useMemo(() => {
    if (!_.isEmpty(allTypes)) {
      let filteredTypes = allTypes;
      if (searchVal) {
        filteredTypes = _.filter(
          filteredTypes,
          (item) =>
            (item.formattedName || "")
              .toLowerCase()
              .includes(searchVal.toLowerCase()) ||
            (item.formattedName || "")
              .toLowerCase()
              .includes(searchVal.toLowerCase())
        );
      }
      return filteredTypes;
    }
  }, [searchVal, selectedTypes, allTypes, reset]);

  const renderTypes = () => {
    if (!_.isEmpty(allTypes)) {
      return _.map(filteredTypes, (item, idx) => {
        return (
          <FilterContent
            key={uuidv4()}
            name={item?.name}
            icon={item?.icon}
            count={item?.count}
            formattedName={item?.formattedName}
            checkedList={checkedList}
            parentWidth={parentWidth}
            setClickedCheckbox={(val) => {return false}}
            active
            noAnimation={noAnimation}
            radioMode
          />
        );
      });
    }
  };

  const onChange = (e) => {
    selectedTypes([e.target.value])
  };

  const handleClearAll = () => {
    selectedTypes([]);
  };

  if (_.isEmpty(allTypes)) {
    return (
      <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      </div>
    );
  }

  return (
    <div className="InventorySingelFilter" ref={parentRef}>
      <div className="InventorySingelFilter__title row between">
        <SearchBox
          placeholder={title}
          value={(val) => setSearchVal(val)}
          resetSearch={(val) => setSearchVal(val)}
          grayMode
          noBorder
        />
        {!_.isEmpty(checkedList) && (
          <button
            onClick={handleClearAll}
            className="InventorySingelFilter__title-actions-clear"
          >
            Clear
          </button>
        )}
      </div>

      <div className="InventorySingelFilter__types col">
        {_.isEmpty(filteredTypes) && !_.isEmpty(searchVal) ? (
          <div className="center NewAssetsChart" style={{ padding: "30px 0" }}>
            <Empty
              image={Empty.PRESENTED_IMAGE_SIMPLE}
              description="No Results"
            />
          </div>
        ) : (
          <Radio.Group value={!_.isEmpty(checkedList) ? checkedList[0] : []} onChange={onChange}>
            {renderTypes()}
          </Radio.Group>
        )}
      </div>
    </div>
  );
};

export default InventorySingelRadioFilter;
