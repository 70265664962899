import React, { useState } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  iacStatusIconByType,
  numberWithCommas,
  formatIacStatusToState,
} from "../../../utils/formatting";
import { setFilters } from "../../../redux/actions/inventoryActions";

import "./state.scss";

const State = ({
  name,
  percentage,
  total,
  datesData,
  onlyProd,
  prodAccounts,
}) => {
  const [hover, setHover] = useState(false);
  const history = useHistory();
  const dispatch = useDispatch();

  const toggleHover = () => {
    setHover(!hover);
  };

  const getSevenDaysCount = () => {
    let obj = {
      Drifted: "modified",
      Codified: "managed",
      Ghost: "ghost",
      Unmanaged: "unmanaged",
    };

    if (!_.isEmpty(datesData)) {
      let stateDaysPastWeek =
        _.find(datesData || [], (item) => item?.key === obj?.[name])
          ?.doc_count || 0;
      return numberWithCommas(stateDaysPastWeek);
    }
    return 0;
  };

  const counterClick = () => {
    const dataType = formatIacStatusToState(name);
    dispatch(
      setFilters({
        state: [dataType],
        years: [],
        types: [],
        severity: [],
        regions: [],
        classifications: [],
        exclusions: [],
        iacTypes: [],
        stacks: [],
        arns: [],
        providerTypes: {
          providerType: [],
          providerSubType: [],
          provider: [],
          providerId: onlyProd ? prodAccounts : [],
        },
      })
    );
    history.push({
      pathname: `/inventory`,
      search: `?state=${dataType}`,
    });
  };

  return (
    <div
      className={`State ${name} center col`}
      onMouseEnter={toggleHover}
      onMouseLeave={toggleHover}
      onClick={counterClick}
    >
      <div
        className={`State ${name} ${hover && total > 0 ? "hovered" : ""} row`}
      >
        <div className="State__container row">
          <div className={`State__container-icon ${name}`}>
            <FontAwesomeIcon icon={iacStatusIconByType(name)} />
          </div>
          <div className="State__container-data col">
            <span className={`State__container-data-title row bold ${name}`}>
              <span>{`${percentage}%`}</span>
              <span>{name}</span>
            </span>
            <span className="State__container-data-text">
              <span className="State__container-data-text">{`Total ${numberWithCommas(
                total
              )} / +${getSevenDaysCount()} Past week`}</span>
            </span>
          </div>
          <div className={`State__arrow ${name}`} style={{visibility: hover? 'visible' : 'hidden'}}>
              <FontAwesomeIcon icon="angle-right" />
            </div>
        </div>
      </div>
    </div>
  );
};

export default State;
