import { Tooltip } from "antd";
import moment from "moment";
import React, { useCallback, useEffect, useRef } from "react";
import { useSelector } from "react-redux";
import get from "lodash/get";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";
import { dateTimeSecondsFormat, getArnUserName } from "../../../../utils/formatting";
import { handleRowClick, openRowByIndex } from "../../../../utils/table";
import EventReqRes from "./eventReqRes";
import { CLOUD_PROVIDERS } from "../../../../consts/general";

const EventsTable = ({
  loading,
  pageSize = 10,
  setPageNumber,
  setPageSize,
  setSorter,
  assetData,
}) => {
  const apiRef = useRef();

  const events = useSelector((state) => state.assetEventsReducer.events);
  const count = useSelector((state) => state.assetEventsReducer.count);

  const isAWSProvider = assetData?.provider === CLOUD_PROVIDERS.aws;
  const isAzureProvider = assetData?.provider === CLOUD_PROVIDERS.azurerm;

  useEffect(() => {
    if (events.length && apiRef.current) {
      openRowByIndex(apiRef, 0)
    }
  }, [apiRef.current])

  const getDetailPanelContent = isAzureProvider ? null : useCallback((data) => <EventReqRes eventData={data?.row} assetData={assetData} isAWSProvider={isAWSProvider} />, []);
  const getDetailPanelHeight = useCallback(() => "auto", []);

  const fields = {
    time: isAWSProvider ? "@timestamp" : "createdAt",
    eventName: isAWSProvider ? "detail.eventName" : "eventName",
    userInfo: isAWSProvider ? "detail.userIdentity.userName" : "ownerId",
    userType: isAWSProvider ? "detail.userIdentity.type" : "ownerType",
  }

  const columns = [
    {
      headerName: "Time",
      field: fields.time,
      flex: 2,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => moment(get(row, fields.time, "")).format(dateTimeSecondsFormat)
    },
    {
      headerName: "Event Name",
      field: fields.eventName,
      flex: 1,
      disableColumnMenu: true,
      valueGetter: ({ row }) => get(row, fields.eventName, "") || ""
    },
    {
      headerName: "Identity Info",
      field: fields.userInfo,
      flex: 2,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => {
        const { row, api } = params;
        apiRef.current = api;
        const output = row?.ownerId ||
                       row?.detail?.userIdentity?.userName ||
                       row?.detail?.userIdentity?.arn || "";
        const outputLength = output.length;
        const shownText = outputLength > 30 ? getArnUserName(output) : output;
        return (
          <div className="EventsUserInfo">
            <Tooltip title={outputLength > 30 ? output : ""}>
              {shownText}
            </Tooltip>
          </div>
        );
      },
    },
    {
      headerName: "Identity Type",
      field: fields.userType,
      flex: 1,
      filterable: false,
      disableColumnMenu: true,
      valueGetter: ({ row }) => get(row, fields.userType, "") || "",
    },
  ];

  return (
    <div>
      <TableWrapper
        autoHeight={!!events?.length}
        rowKey="uniqueId"
        tableData={events || []}
        columns={columns}
        disableSelectionOnClick
        loading={loading}
        pageSize={pageSize}
        rowCount={count}
        handlePageChange={setPageNumber}
        getDetailPanelContent={getDetailPanelContent}
        getDetailPanelHeight={getDetailPanelHeight}
        onPageSizeChange={setPageSize}
        onRowClick={(row) => handleRowClick(apiRef, row.id)}
        handleSortChange={setSorter}
        serverSide
      />
    </div>
  );
};

export default EventsTable;
