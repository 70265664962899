import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { S3_ASSETS } from "../../consts/config";
import skeleton_light from "./img_skeleton_light.png";
import skeleton_dark from "./img_skeleton_dark.png";
import { Spin } from "antd";
import { LoadingOutlined } from '@ant-design/icons';

const DEFAULT_URL = `${S3_ASSETS}/small_asset_types/aws/aws_cloudtrail.png`

const AssetTypeImage = ({ assetType, provider, customStyle, loaderSize }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const skeleton = themeDark ? skeleton_dark : skeleton_light;
  const [src, setSrc] = useState(null);
  const [loading, setLoading] = useState(false);

  const loadingIcon = <LoadingOutlined style={{ fontSize: loaderSize || 24 }} spin />;

  useEffect(() => {
    let isMounted = true;
    setLoading(true);

    async function fetchImage() {

      let providerFolder = provider === 'azure' ? 'azurerm' : provider;
      if (!provider) {
        let newProvider = assetType?.split("_");
        providerFolder = newProvider?.[0] || "";
      }
  
      let url = `${S3_ASSETS}/small_asset_types/${providerFolder}/${assetType}.png`;
      let default_provider_url = `${S3_ASSETS}/small_asset_types/${providerFolder}/${providerFolder}_default.png`;
  
      const img = new Image();
      img.src = url;
      img.onload = () => {
        if (isMounted) {
        setSrc(url);
        setLoading(false);
        }
      };
      img.onerror = () => {
        if (isMounted) {
        setSrc(default_provider_url);
        setLoading(false);
        }
      };
    }
    fetchImage()

    return () => {
      isMounted = false;
    };

  }, []);

  if (loading) return <Spin indicator={loadingIcon}/>;
  return <img src={src ? src : skeleton} loading="lazy" alt="type" style={customStyle} onError={() => setSrc(DEFAULT_URL)}/>;
};

export default AssetTypeImage;
