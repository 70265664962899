import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

const GithubSetup = ({ wizardState, setWizardState }) => {
  const [form] = Form.useForm();
  const layout = {};
  const GITHUB_APP_LINK = "https://github.com/apps/firefly-app";

  return (
    <div className="GithubSetup col">
      <div className="GithubSetup__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue GithubSetup__intro-orderedlist">
          <li key="1">
            To install the Firefly GitHub application, go to{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href={GITHUB_APP_LINK}
              style={{ textDecoration: "underline" }}
            >
              GitHub
            </a>{" "}
            and select <span className="bold">Install</span>.
          </li>
          <li key="2">
            Select your Terraform repositories or{" "}
            <span className="bold">All repositories</span>, and select{" "}
            <span className="bold">Install & Authorize</span>.
          </li>
          <li key="3">
            Enter your <span className="bold">Password</span>, and select{" "}
            <span className="bold">Confirm password</span>.
          </li>
          <li key="4">
            Select <span className="bold">Continue with GitHub</span>.
          </li>
          <li key="5">
            Select <span className="bold">Authorize Infralight</span>.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="github-app-form"
        form={form}
        initialValues={{
          nickname: wizardState?.nickname,
        }}
        className="GithubSetup__form"
        onValuesChange={(allValues) => {
          setWizardState({ ...wizardState, ...allValues });
        }}
      >
        <Form.Item
          label="Nickname"
          name="nickname"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please fill integration name" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default GithubSetup;
