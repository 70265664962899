import _ from "lodash";
import { insightsFilterTemplate } from "../../consts/insights";
import {
  GET_INSIGHTS_AGGS,
  GET_INSIGHTS_BY_ACCOUNT,
  SET_INSIGHTS_FILTERS,
  SET_INSIGHTS_TABLE_FILTERS,
  TOGGLE_FILTERS,
  TOGGLE_INSIGHT_ENBALE,
  INSIGHT_SUGGESTIONS_ASSETS
} from "../actions/insightsV2Actions";

const initialState = {
  hits: [],
  total: 0,
  filtersOpen: true,
  filters: insightsFilterTemplate,
  tableFilters: {
    page: 1,
    page_size: 50,
    sorting: [],
  },
  aggs: {},
  suggestionsAssets: []
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INSIGHTS_AGGS:
      return {
        ...state,
        aggs: payload,
      };

    case GET_INSIGHTS_BY_ACCOUNT:
      return {
        ...state,
        hits: payload?.hits || [],
        total: payload?.total || 0,
      };

    case SET_INSIGHTS_FILTERS:
      return {
        ...state,
        filters: payload,
      };

    case SET_INSIGHTS_TABLE_FILTERS:
      return {
        ...state,
        tableFilters: payload,
      };

    case TOGGLE_FILTERS:
      return {
        ...state,
        filtersOpen: !state.filtersOpen,
      };

    case TOGGLE_INSIGHT_ENBALE:
      const index = _.findIndex(state.hits, { _id: payload?.id });

      // If the object is found, update its enable value
      if (index !== -1) {
        const newHits = _.cloneDeep(state.hits);
        newHits[index].isEnabled = payload?.isEnabled;

        // show a scan animation in the UI when an insight is turned on again.
        if(payload?.isEnabled) {
          newHits[index].isScanning = true;
        }
        return { ...state, hits: newHits };
      }

      // If the object is not found, return the original array
      return state;

      case INSIGHT_SUGGESTIONS_ASSETS:
        return {
          ...state,
          suggestionsAssets: payload,
        };

    default:
      return state;
  }
};
