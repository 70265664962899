import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import Lottie from "react-lottie";
import animationData from "./easy_animation_logo_icon.json";
import { S3_ASSETS } from '../../../consts/config';

import "./appLogo.scss";

const AppLogo = ({ sidebar }) => {
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className={`AppLogo row ${sidebar ? 'sidebar' : ''}`}>
      <Link to="/" className="AppLogo__link row center">
        <Lottie
          options={defaultOptions}
          height={35}
          width={35}
          isStopped={false}
          isPaused={false}
        />
        {!sidebarClosed && (
          <img src={`${S3_ASSETS}/logo/logo_text_${themeDark ? 'dark' : 'light'}.svg`} alt="firefly" className="AppLogo__text" />
          )}
      </Link>
    </div>
  );
};

export default AppLogo;
