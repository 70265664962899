import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { Button, Form, Input } from "antd";
import { searchRepoPaths } from "../../../../redux/actions/vcsActions";
import AppModal from "../../../../shared/appModal/appModal";
import Loading from "../../../../shared/loading/loading";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./browseRepositoryModal.scss";

const BrowseRepositoryModal = ({
  visible,
  handleClose,
  repo,
  vcsId,
  type,
  selectedPath,
}) => {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [paths, setPaths] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    setPaths([]);
    form.resetFields();
  }, [visible]);

  const handleSearch = async (values) => {
    setLoading(true);
    const req = await dispatch(
      searchRepoPaths(type, vcsId, repo, values?.searchString)
    );
    setPaths(req);
    return setLoading(false);
  };

  const renderPathlist = () => {
    if (
      _.isEmpty(paths) &&
      !loading &&
      !_.isEmpty(form.getFieldValue("searchString"))
    ) {
      return <div className="center" style={{ height: '200px' }}>No Results</div>;
    }
    return _.map(paths || [], (item) => {
      return (
        <div
          key={item}
          className={`BrowseRepositoryModal__item row`}
          onClick={() => selectedPath(item)}
        >
          <FontAwesomeIcon icon={["far", "file"]} />
          {item}
        </div>
      );
    });
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      bodyClassName="BrowseRepositoryModal col"
      title={`Search "${repo}"`}
      hideSubmitBtn
      zIndex={1002}
    >
      <div className="BrowseRepositoryModal__content">
        <div className="BrowseRepositoryModal__content-head">
          <Form
            form={form}
            name="repo_search"
            layout="inline"
            onFinish={handleSearch}
            style={{ display: "grid", gridTemplateColumns: "80% 20%" }}
          >
            <Form.Item
              name="searchString"
              rules={[
                {
                  required: true,
                  message: "Please type a path or file name",
                  whitespace: true,
                  validateTrigger: "onSubmit",
                },
              ]}
            >
              <Input
                placeholder="Type path or file"
                style={{ width: "100%", backgroundColor: "transparent" }}
              />
            </Form.Item>
            <Form.Item style={{ marginRight: 0 }}>
              <Button type="primary" htmlType="submit">
                Search
              </Button>
            </Form.Item>
          </Form>
        </div>
        <div className="BrowseRepositoryModal__content-list">
          {loading ? (
            <div className="center" style={{ height: "200px" }}>
              <Loading />
            </div>
          ) : (
            renderPathlist()
          )}
        </div>
      </div>
    </AppModal>
  );
};

export default BrowseRepositoryModal;
