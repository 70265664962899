import action from "../middleware";

import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_ALL_CRAWLERS = "GET_ALL_CRAWLERS";
export const GET_CRAWLERS = "GET_CRAWLERS";
export const CLEAR_CRAWLERS = "CLEAR_CRAWLERS";
export const DELETE_CRAWLER = "DELETE_CRAWLER";
export const CREATE_CRAWLER = "CREATE_CRAWLER";

const getCrawlers = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/crawlers`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: GET_ALL_CRAWLERS,
      payload: { data },
    });
  });
};

const clearCrawlers = () => {
  return action(async (dispatch) => {
    dispatch({
      type: CLEAR_CRAWLERS,
      payload: {},
    });
  });
};

const deleteCrawlerById = (crawlerId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deleteReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/crawlers/${crawlerId}`,
      "DELETE"
    );
    return deleteReq;
  });
};

const createCrawler = (
  integrationId,
  bucket,
  selected,
  prefix,
  tags
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name: 'unassigned',
      integrationId,
      bucket,
      selected,
      prefix,
      owner: 'unassigned',
      tags
    };
    
    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/crawlers/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_CRAWLER,
      payload: {},
    });

    const json = await res.json();
    return { crawler: json };
  });
};

export {
  getCrawlers,
  clearCrawlers,
  deleteCrawlerById,
  createCrawler
};
