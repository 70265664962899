import React from "react";
import Button from "antd/lib/button";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./integrationCard.scss";

const IntegrationCard = ({
  icon,
  title,
  onClick,
  connected,
  coming_soon,
  auto,
}) => {
  const renderFlag = () => {
    if (coming_soon) {
      return <span className="IntegrationCard__flag soon">Coming soon</span>;
    }

    if (connected) {
      return (
        <span className="IntegrationCard__flag connected row">
          <FontAwesomeIcon
            icon="check"
            className="IntegrationCard__action-check"
          />
          Connected
        </span>
      );
    }

    if (auto) {
      return (
        <span className="IntegrationCard__flag connected row">
          <FontAwesomeIcon
            icon="check"
            className="IntegrationCard__action-check"
          />
          Auto discovered
        </span>
      );
    }
    return;
  };

  return (
    <div
      className={`IntegrationCard row between ${
        auto || connected ? "connected" : ""
      } ${coming_soon ? "soon" : ""}`}
    >
      <div className="IntegrationCard__type row">
        <div className="IntegrationCard__type-wrapperIcon center">
          <img
            className="IntegrationCard__type-wrapperIcon-icon"
            src={icon}
            alt={icon}
          />
        </div>
        <span className="IntegrationCard__type-title bold">{title}</span>
      </div>
      <div className="IntegrationCard__action">
        {!coming_soon && !auto && !connected && (
          <Button
            onClick={onClick}
            type="default"
            className="IntegrationCard__action-btn"
          >
            Connect
            <FontAwesomeIcon
              icon="plug"
              className="IntegrationCard__action-btn-icon"
            />
          </Button>
        )}
        {renderFlag()}
      </div>
    </div>
  );
};

export default IntegrationCard;
