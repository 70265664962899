import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { sendEvent } from "../../utils/amplitude";
import { generalEvents } from '../../utils/amplitudeEvents';

export const CREATE_JIRA_INTEGRATION = "CREATE_JIRA_INTEGRATION";
export const GET_JIRA_INTEGRATIONS = "GET_JIRA_INTEGRATIONS";
export const EDIT_JIRA_INTEGRATION = "EDIT_JIRA_INTEGRATION";
export const DELETE_JIRA_INTEGRATION = "DELETE_JIRA_INTEGRATION";

const createJiraIntegration = (name, domain, mail, token) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira/`,
      "POST",
      {
        name,
        domain,
        mail,
        token,
      }
    );

    dispatch({
      type: CREATE_JIRA_INTEGRATION,
      payload: {},
    });

    const result = await req.json();

    return result;
  });
};

const getJiraIntegrations = (showError) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira`,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );

    const result = await integrationsReq.json();

    dispatch({
      type: GET_JIRA_INTEGRATIONS,
      payload: { result },
    });

    return integrationsReq.ok;
  });
};

const editJiraIntegration = (integration, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira/${integration}`,
      "PUT",
      { name }
    );

    dispatch({
      type: EDIT_JIRA_INTEGRATION,
      payload: {},
    });

    return req.ok;
  });
};

const deleteJiraIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_JIRA_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

const getJiraDomainMetadata = (integration, startName = "") => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira/${integration}/metadata/`,
      "POST",
      { startName }
    );

    const result = await req.json();

    return { req, data: result };
  });
};

const createJiraTicket = (integration, jiraPost) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/jira/${integration}/ticket/`,
      "POST",
    jiraPost
    );
    sendEvent(generalEvents.jiraIssueClicked, { action: 'user created jira issue' })

    const data = await req.json();

    return { req, data };
  });
};

export {
  createJiraIntegration,
  getJiraIntegrations,
  editJiraIntegration,
  deleteJiraIntegration,
  getJiraDomainMetadata,
  createJiraTicket,
};
