import React, { useEffect, useMemo, useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useDispatch, useSelector } from "react-redux";
import { getAssetEventsGraphData } from "../../../../redux/actions/assetEventsActions";
import { Select } from "antd";
import { Column } from "@ant-design/charts";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { getAbbreviateNumber } from "../../../../utils/helpers";

const EventsGraph = ({ assetData , eventTimesFilterOptions = []}) => {
  const [graphLoading, setGraphLoading] = useState(false);
  const [byTimeSelected, setByTimeSelected] = useState(eventTimesFilterOptions[0]?.key);

  const dispatch = useDispatch();
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const graphData = useSelector((state) => state.assetEventsReducer.graphData);

  useEffect(() => {
    fetchGraphData(byTimeSelected);
  }, []);

  const config = {
    autoFit: true,
    data: graphData,
    xField: "time",
    yField: "value",
    seriesField: "name",
    isStack: true,
    columnStyle: {
      radius: [4, 4, 0, 0],
    },
    xAxis: {
      top: true,
    },
    yAxis: {
        visible: true,
        label: {
          autoHide: false,
          formatter: (num) => num % 1 === 0 ? num : ""
        },
        grid: {
          visible: false
        }
    },
    legend: {
      flipPage: false,
      position: 'top-left',
      maxRow: 2,
    },
    animation: {
        appear: {
            animation: 'fade-in',
            duration: 1000,
        }
    },
    interactions: [
      {
        type: 'active-region',
        enable: false,
      },
    ],
    theme: {
        padding: 10,
        maxColumnWidth: 5,
        colors10: [
            "#5724B1",
            "#8559FE",
            "#5246B9",
            "#4E64C0",
            "#498BC8",
            "#33FDD8"
        ],
    },
    tooltip: {
      customContent: (idx, values) => {
        const total = values.reduce((acc, elem) => acc + parseInt(elem.value), 0);
        return (
          <div className="EventGraphContainer__tooltip col">
            <span className="bold">
                Top 3 Events
            </span>
            <div className="EventGraphContainer__tooltip-list col">
                {values.map(({ name, value, color } = {}) => (
                <div className="EventGraphContainer__tooltip-item row" key={uuidv4()}>
                    <div className="EventGraphContainer__tooltip-item-mark">
                        <div className="EventGraphContainer__tooltip-item-mark-dot" style={{ backgroundColor: color }}/>
                        <div className="EventGraphContainer__tooltip-item-mark-line" style={{ backgroundColor: color }}/>
                    </div>
                    <span className="text">{name}</span>
                    <span className="lighterText">{`${(parseInt(value)/total).toFixed(2) * 100}%`}</span>
                </div>
                ))}
            </div>
          </div>
        );
      },
      domStyles: {
        "g2-tooltip": {
          backgroundColor: themeDark ? "#212133" : "white",
          color: themeDark ? "rgba(255, 255, 255, 0.801)" : "#515151",
          boxShadow: `${themeDark? 'rgb(174, 174, 174)': '#ccc'} 0px 0px 4px`
        },
      },
    },
  };

  const fetchGraphData = async (byTime) => {
    const {
      integrationId,
      region,
      arn,
      resourceId,
      name: resourceName,
      assetType,
      service,
      provider,
    } = assetData || {};

    const payload = {
      integrationId,
      region,
      arn,
      resourceId,
      resourceName,
      assetType,
      service,
      provider,
    };
    setGraphLoading(true);
    await dispatch(getAssetEventsGraphData(payload, byTime));
    setGraphLoading(false);
  };

  const onEventTimeSelect = (byTime) => {
    setByTimeSelected(byTime);
    return fetchGraphData(byTime);
  };

const graph = useMemo(() => (<Column {...config} />), [graphData])

return (
    <div className="EventGraphContainer col">
        <Select
            className="EventTableSelect__graph"
            placeholder="Select a time filter"
            showArrow
            suffixIcon={<FontAwesomeIcon icon={["far", "clock"]} />}
            value={byTimeSelected}
            onSelect={onEventTimeSelect}
            disabled={graphLoading}
            loading={graphLoading}
        >
        {eventTimesFilterOptions.map((time) => {
          const { key, value: count, title } = time || {};
          return (
            <Select.Option key={key} value={key}>
              {`${title} (${getAbbreviateNumber((count))})`}
            </Select.Option>
          );
        })}
      </Select>
      <div className="EventGraphContainer__column">
          {graph}
        </div>
    </div>
  );
};

export default EventsGraph;
