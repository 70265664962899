import React, { useState } from "react";
import _ from "lodash";
import Typed from "react-typed";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCopy, faCheck, faCode } from "@fortawesome/free-solid-svg-icons";

import "./cliSnippet.scss";

const CliSnippet = ({ title, content, deleteMode }) => {
  content = content.replace(/;/g, '').replace(/&/g, '').replace(/</g, '').replace(/>/g, '').replace(/"/g, '').replace(/'/g, '').replace(/\//g, '').replace(/=/g, '')

  const [isCopied, setIsCopied] = useState(false);

  const onCopyClicked = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className={`CliSnippet col ${deleteMode && "deleteMode"}`}>
      <div className="CliSnippet__wrapper">
        <div className="CliSnippet__header row between">
          <div className="CliSnippet__header-title row">
            <FontAwesomeIcon icon={faCode} />
            {!_.isEmpty(title) ? title : "FLYCLI"}
          </div>
          <CopyToClipboard text={content} onCopy={onCopyClicked}>
            {isCopied ? (
              <FontAwesomeIcon icon={faCheck} />
            ) : (
              <FontAwesomeIcon icon={faCopy} style={{ cursor: "pointer" }} />
            )}
          </CopyToClipboard>
        </div>
        <div className="CliSnippet__content">
          <Typed strings={[content]} typeSpeed={40} key={content} />
        </div>
      </div>
    </div>
  );
};

export default CliSnippet;
