import React, { useState, useEffect } from "react";
import _ from "lodash";
import Drawer from "antd/lib/drawer";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";

import ActionBtn from "../../../../shared/actionBtn/actionBtn";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import AssetsList from "../assetsList/assetsList";

import "./policyDrawer.scss";
import Base64 from "../../../../utils/base64";
import { FIREFLY_REGO_PREFIX } from "../../../../utils/regoHelper";
import { useTranslation } from "react-i18next";

const PolicyDrawer = ({ visible, closeDrawer, policy }) => {
  const { t } = useTranslation('excluded-assets')
  const [code, setCode] = useState(null);
  const [isFeachingCode, setIsFeachingCode] = useState(false);

  useEffect(() => {
    if (visible) {
      fetchCode();
    }
  }, [visible]);

  const fetchCode = () => {
    setIsFeachingCode(true);
    setCode(
      `${FIREFLY_REGO_PREFIX}\n${_.isEmpty(policy?.rego) ? "" : Base64.decode(policy.rego)
      }`
    );
    setIsFeachingCode(false);
  };

  const renderCode = () => {
    if (!_.isEmpty(code)) {
      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {code}
        </SyntaxHighlighter>
      );
    }
    return (
      <div className="full-page center">
        <AppEmpty text={t('policy-drawer.no-data')} customStyle="code" />
      </div>
    );
  };

  const renderTypes = () => {
    if (!_.isEmpty(policy?.type)) {
      return (
        <div className="row" style={{ gap: "10px" }}>
          {_.map(policy?.type || [], (inner) => {
            return (
              <span className="purple-flag" key={inner}>
                {formatAwsStringWithUnderscore(inner)}
              </span>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return (
    <Drawer
      title={null}
      placement="right"
      closable={false}
      onClose={closeDrawer}
      visible={visible}
      key="right"
      className="PolicyDrawer"
      width="900px"
      maskStyle={{ backgroundColor: "rgba(0,0,0,.65)" }}
    >
      <div className="PolicyDrawer__wrapper row">
        <div className="PolicyDrawer__wrapper-assets">
          <AssetsList selectedPolicy={policy} visible={visible} />
        </div>
        <div className="PolicyDrawer__wrapper-code">
          <div className="PolicyDrawer__wrapper-code-title col">
            <span className="title">{!_.isEmpty(policy) && policy?.name}</span>
            {renderTypes()}
          </div>

          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeDrawer}
            className="PolicyDrawer__wrapper__close"
          />

          <div className="PolicyDrawer__wrapper-code-body">
            {isFeachingCode ? <Loading /> : renderCode()}
          </div>

          <div className="PolicyDrawer__wrapper-code-footer row">
            <ActionBtn
              text={t('policy-drawer.export-btn')}
              disabled={_.isEmpty(code)}
              action="download"
              fileType="rego"
              fileName="Policy"
              icon="donwload"
              stringToAction={code}
              refresh={visible}
            />

            <ActionBtn
              text={t('policy-drawer.copy-btn')}
              icon="copy"
              disabled={_.isEmpty(code)}
              action="copy"
              stringToAction={code}
              refresh={visible}
            />
          </div>
        </div>
      </div>
    </Drawer>
  );
};

export default PolicyDrawer;
