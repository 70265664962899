import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

import AppToggle from "../../../../shared/appToggle/appToggle";
import IconBtn from "../../../../shared/iconBtn/iconBtn";
import ConfirmationModal from "../../../../shared/confirmationModal/confirmationModal";
import LinelLoader from "../../../../shared/linelLoader/linelLoader";

import {
  getAllNotifications,
  updateNotification,
  deleteNotification,
} from "../../../../redux/actions/notificationsActions";
import { integrationIcons } from "../../../../utils/icons";

import "./singleNotification.scss";
import { dateTimeFormat, getAllProvidersArray } from "../../../../utils/formatting";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";
import { useTranslation } from "react-i18next";
import { capitalize, cloneDeep, groupBy, omit, some } from "lodash";
import moment from "moment";
import { Badge, Button, Divider, Popover } from "antd";
import ListIcon from "../../../../Images/general_icons/ListAdd.svg";
import TagsIcon from "../../../../Images/general_icons/TagsIcon.svg";
import TimeIcon from "../../../../Images/general_icons/Time_icon.svg";
import UserIcon from "../../../../Images/general_icons/user_icon.svg";
import OwnerIcon from "../../../../Images/general_icons/user_icon_primary.svg";
import LocationIcon from "../../../../Images/general_icons/Location_icon.svg";
import { PROVIDERS_TITLE_MAP } from "../../../../consts/general";

const SingleNotification = ({
  data,
  notificationTypesIndex,
  loadingNotificationsInteg,
  loadingProviders,
  setEditNotificationData,
  setEditModalOpen
}) => {
  const { t } = useTranslation('notifications')
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [notification, setNotification] = useState({});
  const [seeAll, setSeeAll] = useState(false)
  const [visible, setVisible] = useState(false)
  useEffect(() => {
    setNotification(data);
  }, [data]);

  // ------- redux state ---------
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const slackIntegrations = useSelector(
    (state) => state.integrationsReducer.slack
  );
  const teamsIntegrations = useSelector(
    (state) => state.integrationsReducer.teams
  );
  const webhookIntegrations = useSelector(
    (state) => state.integrationsReducer.webhook
  );
  const opsgenieIntegrations = useSelector(
    (state) => state.integrationsReducer.opsgenie
  );
  const torqIntegrations = useSelector(
    (state) => state.integrationsReducer.torq
  );
  const pagerdutyIntegrations = useSelector(
    (state) => state.integrationsReducer.pagerduty
  );
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const slackChannels = useSelector(
    (state) => state.integrationsReducer.channels
  );

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );
  //  -----------------------------

  const toggleNotification = async () => {
    setLoading(true);
    await dispatch(
      updateNotification(notification?._id, {
        isEnabled: !notification?.isEnabled,
      })
    );
    await dispatch(getAllNotifications());
    setLoading(false);
  };

  const handleDeleteAlert = async () => {
    setDeleteInProgress(true);
    await dispatch(deleteNotification(notification?._id));
    await dispatch(getAllNotifications());
    setDeleteInProgress(false);
    setConfirmDeleteOpen(false);
  };

  const renderNameByType = () => {
    const type = notificationTypesIndex.find(
      (item) => item?.value === notification?.eventType
    );
    const output = type?.displayName || "-" ;
    return (
      <div>
        {notification?.displayName ?? output}
        <span className="SingleNotification__header-title_divider"></span>
        {type?.icon && <span className={`SingleNotification__header-title_icon ${type?.value}`}>
          {typeof type?.icon == "string" ? <FontAwesomeIcon icon={type?.icon} className={`${type?.value}`} /> : type.icon}
        </span>}
      </div>
    )
  };
  const getNotificationType = () =>{
    const type = notificationTypesIndex.find(
      (item) => item?.value === notification?.eventType
    );
    return type?.displayName;
  }

  const handleOpenEditModal = () => {
   if(isViewer) return;
   setEditNotificationData(data);
   setEditModalOpen(true);
  }
  const handleToggleSeeAll =() => {
    setSeeAll(!seeAll);
  }

  const getIntegrationsPopoverContent = (name, integrations=[]) => {
    return(
      <>
        <h4> 
          <ProviderIcon provider={name} customStyle="notification" />
          {name}
        </h4>
        <ul>
          {integrations.slice(0,seeAll ? 999 : 5).map(integration => (
            <li key={uuidv4()}>
              <ProviderIcon provider={name} customStyle="notification"/> 
              {integration?.name}
            </li>
          ))}
        </ul>
        {integrations.length > 6 && <Button type="link" onClick={handleToggleSeeAll}>{seeAll ? "See Less" :"See All"}</Button>}
      </>
    )
  }
  const renderScopeFlag = () => {
    const allProviders = getAllProvidersArray(providerIntegrations);
   
    if (!isEmpty(notification?.scope?.providers)) {
      const matchedProviders = notification?.scope?.providers?.map(providerId => allProviders.find(p => p.id === providerId));
      const groupedProviders = groupBy(matchedProviders,'provider');
      let providersToRender = [];

      for (const providerKey in groupedProviders) {
        providersToRender.push((
            <Badge count={groupedProviders[providerKey].length} overflowCount={9} size="small" style={{fontSize:10,backgroundColor:"#9872FE"}} key={uuidv4()}>
                 <Popover visible={visible} onVisibleChange={v => setVisible(v)} content={getIntegrationsPopoverContent(providerKey,groupedProviders[providerKey])} placement="bottomLeft" overlayClassName="SingleNotification__header-integration-popover">
                  <span
                    onClick={handleOpenEditModal}
                    className="SingleNotification__header-content-integrations-single row"
                    key={providerKey}>
                    <ProviderIcon provider={providerKey} customStyle="notification" />
                    {PROVIDERS_TITLE_MAP[providerKey] || capitalize(providerKey)}
                    {groupedProviders[providerKey].some(p => p?.isProd) && <FontAwesomeIcon icon={faFlag} className="SingleNotification__header-content-integrations-single-flag" />}
                  </span>
                 </Popover>
          </Badge>
        ))
      
        
      }
      return (
        <div className="SingleNotification__header-content-integrations row">
              {providersToRender}
        </div>
      )
 
    }
    if (!isEmpty(notification?.scope?.tags)) {
      return (
        <div className="SingleNotification__header-content-integrations row">
          {map(notification?.scope?.tags, (tag) => (
            <span
              className="SingleNotification__header-content-integrations-single row"
              key={uuidv4()}
            >
              <FontAwesomeIcon
                icon="tag"
                className="SingleNotification__header-content-integrations-single-cloud"
              />
              {tag}
            </span>
          ))}
        </div>
      );
    }
    if (!isEmpty(notification?.scope?.insights)) {
      return (
        <div className="SingleNotification__header-content-integrations row">
          {map(notification?.scope?.insights || [], (ins) => {
            const match = classifications?.find((item) => item.id === ins);
            if (!isEmpty(match)) {
              return (
                <span
                  onClick={handleOpenEditModal}
                  className="SingleNotification__header-content-integrations-single row"
                  key={match.id}
                >
                  <FontAwesomeIcon
                    icon="cloud"
                    className="SingleNotification__header-content-integrations-single-cloud"
                  />
                  {match.name}
                </span>
              );
            }
          })}
        </div>
      );
    }
    return;
  };

  const renderIntegrationFlag = () => {
    const allIntegs = [
      ...map(slackIntegrations, (item) => {
        return { ...item, icon: "slack" };
      }),
      ...map(teamsIntegrations, (item) => {
        return { ...item, icon: "teams" };
      }),
      ...map(webhookIntegrations, (item) => {
        return { ...item, icon: "webhook" };
      }),
      ...map(opsgenieIntegrations, (item) => {
        return { ...item, icon: "opsgenie" };
      }),
      ...map(torqIntegrations, (item) => {
        return { ...item, icon: "torq" };
      }),
      ...map(pagerdutyIntegrations, (item) => {
        return { ...item, icon: "pagerduty" };
      }),
      {
        id:"emailAddresses",
        service:"email",
        icon:"email",
        name:"Email Address"
      }
    ];

    return (
      <div className="SingleNotification__header-content-integrations row" onClick={handleOpenEditModal}>
        {map(notification?.notificationIntegrations || [], (integ) => {
          const match = allIntegs.find((item) => item.id === integ?.id);
          if (!isEmpty(match)) {
            if (match?.service !== "slackApp") {
              return (
                <span
                  className="SingleNotification__header-content-integrations-single row"
                  key={match.id}
                >
                  <img
                    src={integrationIcons[match.icon]}
                    alt="integration icon"
                  />
                  {match.name}
                </span>
              );
            } else {
              return map(integ?.channels || [], (channel) => {
                if (!isEmpty(slackChannels)) {
                  const matchChannel = slackChannels.find(
                    (item) => item?.id === channel
                  );
                  if (matchChannel) {
                    return (
                      <span
                        className="SingleNotification__header-content-integrations-single row"
                        key={matchChannel?.id}
                      >
                        <img
                          src={integrationIcons["slack"]}
                          alt="integration icon"
                        />
                        {matchChannel?.name}
                      </span>
                    );
                  }
                  return;
                }
              });
            }
          }
        })}
      </div>
    );
  };
  const renderScopeType = (scopeType) => {
    if(isEmpty(notification)) return;
    const scopes = {
      assetTypes:{
        displayName:"Types",
        icon:ListIcon
      },
      tags:{
        displayName:"Tags",
        icon:TagsIcon
      },
      owners:{
        displayName:"Owners",
        icon:OwnerIcon
      },
      locations:{
        displayName:"Locations",
        icon:LocationIcon
      },
    }
    if (!isEmpty(notification?.scope[scopeType])) {
      return <>
      <div onClick={handleOpenEditModal}>
      <Badge count={notification?.scope[scopeType]?.length} size="small" style={{fontSize:10,backgroundColor:"#9872FE"}}>
        <div className="AppTag minimal SingleNotification__header-content-tag">
          <img src={scopes[scopeType].icon} alt="icon" width={12} />
          <span>{capitalize(scopes[scopeType].displayName)}</span>
        </div>
      </Badge>
      </div>
      </>
    }
  }
  
  const renderMinimizedFlag = () => {
    if (notification?.minimizedAlert) {
      return <div className="purple-flag">{t('single-notification.minimized')}</div>
    }
  }
  const hasCriteria = some(omit(cloneDeep(notification?.scope), 'providers','insights'), value => value?.length > 0)
  return (
    <div
      className={`SingleNotification basic-card ${!notification.isEnabled ? "notificationOff" : ""
        }`}
    >
      <div className="SingleNotification__header col">
        <span className="SingleNotification__header-title">
          <div>
            {renderNameByType()}
            <div className="SingleNotification__header-title-user-information">
            <div>
              <img src={TimeIcon} alt="clock icon" width={12} />
              <span>{moment(notification?.createdAt).format(dateTimeFormat)}</span>
            </div>
            {notification?.name && (
              <>
              <span className="SingleNotification__header-title_divider"></span>
              <div>
                <img src={UserIcon} alt="user icon" width={12} />
                <span>{notification.name}</span>
              </div>
              </>
            )}
          </div>
          </div>
          <div className="SingleNotification__actions row">
            <div className="SingleNotification__actions-action delete-btn">
              <AppToggle
                checked={notification?.isEnabled}
                toggleChecked={toggleNotification}
                loading={loading}
                disabled={isViewer}
              />
            </div>
            <IconBtn
              type="delete"
              onClick={() => setConfirmDeleteOpen(true)}
              disabled={isViewer}
              colorClass="red"
            />
            <IconBtn
              type="edit"
              onClick={() => {
                setEditNotificationData(data);
                setEditModalOpen(true);
              }}
              disabled={isViewer}
              colorClass="purple"
            />
            </div>
        </span>
      
        <Divider  style={{margin:0}}/>
        {loadingNotificationsInteg || loadingProviders ? (
          <LinelLoader />
        ) : (
          <div className="SingleNotification__header-content row">
            {renderScopeFlag()}
            {hasCriteria && (
              <>
                <span className="SingleNotification__header-divider"></span>
                <span className="SingleNotification__header-label">Criteria:</span>
              </>
            )}
            {renderScopeType("assetTypes")}
            {renderScopeType('tags')}
            {renderScopeType('owners')}
            {renderScopeType('locations')}
            <span className="SingleNotification__header-divider"></span>
            <span className="SingleNotification__header-label">Destination:</span>
            {renderIntegrationFlag()}
            {renderMinimizedFlag()}

          </div>
        )}
      </div>

      

      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={() => setConfirmDeleteOpen(false)}
        title={t('delete-modal.title', { type: getNotificationType() })}
        description={t('delete-modal.description',{type:getNotificationType()})}
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteAlert}
      />
    </div>
  );
};

export default SingleNotification;
