import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_TFC_INTEGRATIONS = "GET_TFC_INTEGRATIONS";
export const CREATE_TFC_INTEGRATION = "CREATE_TFC_INTEGRATION";
export const DELETE_TFC_INTEGRATION = "DELETE_TFC_INTEGRATION";
export const GET_ALL_TFC_ORGANIZATIONS = "GET_ALL_TFC_ORGANIZATIONS";
export const GET_ALL_TFC_ORGANIZATION_WORKSPACES = "GET_ALL_TFC_ORGANIZATION_WORKSPACES";
export const EDIT_TFC_INTEGRATION = "EDIT_TFC_INTEGRATION";

const getTfcIntegrations = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const integrationsReq = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc`,
            "GET"
        );

        const integrations = await integrationsReq.json();

        dispatch({
            type: GET_TFC_INTEGRATIONS,
            payload: { integrations },
        });

        return integrationsReq.ok;
    });
};

const createTfcIntegration = (name, apiToken, customDomain) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        let newCustomDomain = undefined;
        if(customDomain) {
            newCustomDomain = customDomain?.includes("/api/v2") ? customDomain : `${customDomain}/api/v2`
        } 

        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc/`,
            "POST",
            {
                name,
                apiToken,
                customDomain: newCustomDomain
            }
        );

        dispatch({
            type: CREATE_TFC_INTEGRATION,
            payload: {},
        });

        const data = await res.json();

        return { req: res, integration: data?.integration };
    });
};

const deleteTfcIntegration = (id) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc/${id}`,
            "DELETE"
        );

        dispatch({
            type: DELETE_TFC_INTEGRATION,
            payload: {},
        });

        return {};
    });
};

const getAllTfcOrganizations = (integration) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc/${integration}/organizations`,
            "GET"
        );

        const organizations = await req.json();

        dispatch({
            type: GET_ALL_TFC_ORGANIZATIONS,
            payload: { },
        });

        return { ok: req.ok, organizations };
    });
};

const getAllTfcOrganizationsWorkspaces = (integration, organization) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const res = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc/${integration}/organization/${organization}/filteredworkspaces`,
            "GET"
        );

        const data = await res.json();

        dispatch({
            type: GET_ALL_TFC_ORGANIZATION_WORKSPACES,
            payload: { data },
        });
        
        return { res, workspaces: data };
    });
};

const editTfcIntegration = (integration, name) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/tfc/${integration}`,
            "PUT",
            {name}
        );

        dispatch({
            type: EDIT_TFC_INTEGRATION,
            payload: {},
        });

        return req.ok;
    });
};

export {
    getTfcIntegrations,
    createTfcIntegration,
    deleteTfcIntegration,
    getAllTfcOrganizations,
    getAllTfcOrganizationsWorkspaces,
    editTfcIntegration
};
