export const handleRowClick = (apiRef, rowId) => {
    const expandedRows = apiRef.current?.getExpandedDetailPanels() || [];
    // close row content
    if (expandedRows?.includes(rowId)) {
      const newExpandedRows = expandedRows.filter((row) => row !== rowId)
      apiRef?.current?.setExpandedDetailPanels(newExpandedRows);
    } else {
      //open row content
      apiRef?.current?.setExpandedDetailPanels([...expandedRows, rowId]);
    }
}
export const openRowByIndex = (apiRef, index = 0) => {
  const expandedRows =  apiRef.current?.getExpandedDetailPanels() || [];
  const rowId = apiRef.current?.getRowIdFromRowIndex(index);
  apiRef.current?.setExpandedDetailPanels([...expandedRows, rowId]);
}

export const openRowsByIds = (apiRef, ids = []) => {
  const expandedRows =  apiRef.current?.getExpandedDetailPanels() || [];
  apiRef.current?.setExpandedDetailPanels([...expandedRows, ...ids]);
};