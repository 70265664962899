import React from "react";

const PagerDutyProcedures = ({ type, style }) => {
  const renderDifferentData = () => {
    if (type === "admin") {
      return (
        <div className="PagerDutyForm__intro col">
          <span className="form-label">
            With this option, you can generate notifications for all services
            available in your PagerDuty account.
          </span>
          <ol className="PagerDutyForm__intro-orderedlist">
            <li key="1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.pagerduty.com/docs/api-access-keys#generate-a-general-access-rest-api-key"
              >
                Generate a General Access REST API Key.
              </a>
            </li>
            <li key="2">
              Copy and paste the <span className="bold">API Key</span> into the <span className="bold">Key</span> box.
            </li>
          </ol>
        </div>
      );
    } else {
      return (
        <div className="PagerDutyForm__intro col">
          <span className="form-label">
            This option enables you to generate notifications for a particular
            PagerDuty service.
          </span>
          <ol className="PagerDutyForm__intro-orderedlist">
            <li key="1">
              <a
                target="_blank"
                rel="noreferrer"
                href="https://support.pagerduty.com/docs/services-and-integrations#generate-a-new-integration-key"
              >
                Generate an Integration Key.
              </a>
            </li>
            <li key="2">
              Copy and paste the <span className="bold">Integration Key </span> 
              into the <span className="bold">Key</span> box.
            </li>
          </ol>
        </div>
      );
    }
  };

  return (
    <div style={style} className="PagerDutyProcedures">
      {renderDifferentData()}
    </div>
  );
};

export default PagerDutyProcedures;
