export const inventoryEvents = {
    search: "Inventory Search",
    codifyDrawerOpen: "Inventory Codify",
    driftDrawerOpen: "Inventory Drift",
    driftDrawerFooterBtnClick: "Drift Drawer",
    export: "Inventory Export Table Click",
    createIssue: "Inventory Create Issue Click",
    deleteAsset: "Inventory Delete Asset Click",
    generateIac: "Inventory Generate IaC Asset Click",
    jumpToCode: "Inventory Jump to Code Asset Click",
    jumpToConsole: "Inventory Jump to Console Asset Click",
    driftButton: "Inventory drift details Asset Click",
    removeAssetButton: "Remove Asset Code Click",
    infoDrawerClick: "Asset Data Drawer Click",
    commentActions: 'Comments Action',
    infoDrawerTabClick: 'Info Drawer Tab Click',
    tfImportCmdDrawerOpen: 'Terraform Import Drawer Open',
    pullRequestDrawerOpen: 'Pull Request Drawer Click',
    createPullRequest: 'Pull Request Created',
    inventoryScreenMode: 'Inventory screen mode changed',
    inventoryTypeBlockClicked: 'Inventory Type Block Click',
    nestedChildrenClick: 'Inventory View relationship Click'
}

export const generalEvents = {
    pageClick: 'Page Click',
    drakMode: 'Dark Mode',
    intercomOpen: 'Intercom Click',
    sidebar: 'Sidebar Toggled',
    jiraIssueClicked: "Create Jira Issue Click",
    organizationChange: "Organiztion Changed",
    tablePageSizeSelection: "Table Page Size Selection",
    eventViewerEventExpansion: "Event Viewer Event Expansion",
}

export const composeEvents = {
    generateModuleCallSubmit: "Generate module call submit",
    generateModuleCallTab: "generate module call tab",
    generateModuleCallPR: "generate module call pr",
    provisionRequest: "Provision Request",
}

export const onboardingEvents = {
    integrationClick: 'Onboarding Integration Click',
    onboardingDone: 'Onboarding Done',
    onboardingStepProgress: 'Onboarding user moved to step'
}

export const iacEvents = {
    iacTypeSelected: "Iac Type Selected",
    filterSelected: "Iac Explorer Filter Selected",
    search: "Iac Explorer Search",
    categorySelected: "Iac Explorer Category Selected",
    export: "IaC Stacks Export Table Click",
}

export const integrationEvents = {
    azureIntegrationCreation: "Azure integration created",
    azureIntegrationEdit: "Azure integration edited",
    sendTestNotification: "Send Test Notification",
}

export const CiEvents = {
    workflowCollapseClick: "Workflow Collapse Click",
    prRowClick: "PR Row Click",
    ciRunTabClick: "CI Run Tab Click",
    ciRunResourceClick: "CI Run Resource Click",
    ciRunResourceCostTabClick: "CI Run Resource Cost Tab Click",
    ciRunResourcePolicyTabClick: "CI Run Resource Policy Tab Click",
    ciRunResourcePlanTabClick: "CI Run Resource Plan Tab Click",
    ciRunResourceCopyClick: "CI Run Resource Copy Click",
}