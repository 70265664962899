import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_IAC_POLICIES = "GET_IAC_POLICIES";
export const DELETE_IAC_POLICY = "DELETE_IAC_POLICY";
export const UPDATE_IAC_POLICY = "UPDATE_IAC_POLICY";
export const CREATE_IAC_POLICY = "CREATE_IAC_POLICY";

const getIacPolicies = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/private`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_IAC_POLICIES,
      payload: { data },
    });
  });
};

const deleteIacPolicy = (policyId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/private/${policyId}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_IAC_POLICY,
      payload: {},
    });
  });
};

const updateIacPolicy = (policyId, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/private/${policyId}`,
      "PUT",
      payload
    );
    return req;
  });
};

const createIacPolicy = (name, description, code) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      description,
      code,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/private`,
      "POST",
      payload
    );

    dispatch({
      type: CREATE_IAC_POLICY,
      payload: {},
    });

    return req.ok;
  });
};

export { getIacPolicies, deleteIacPolicy, updateIacPolicy, createIacPolicy };
