import React from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { v4 as uuidv4 } from "uuid";
import AppTag from "../../../shared/appTag/appTag";

import "./backendsTable.scss";

const ExclusionsTags = ({
  rowData,
  keysByTypes,
  handleRemoveExclusion,
  loadingTag,
}) => {
  return (
    <div className="BackendInfoModal__exclusions-list-tags">
      {map(rowData?.rules || [], (rule) => {
        if (!isEmpty(rule[rowData?.type])) {
          const key = keysByTypes[rowData?.type]
            ? keysByTypes[rowData?.type][1]
            : "";
          return (
            <AppTag
              key={uuidv4()}
              onClick={() => handleRemoveExclusion(rule[rowData?.type][key])}
              text={rule[rowData?.type][key]}
              icon={<FontAwesomeIcon icon="times" />}
              loading={loadingTag === rule[rowData?.type][key]}
            />
          );
        }
      })}
    </div>
  );
};

export default ExclusionsTags;
