import {
  GET_ALL_PROFILES,
  GET_CURRENT_PROFILE,
  CREATE_USER,
  DELETE_USER,
  GET_ALL_ROLES,
  SET_IS_VIEWER,
  GET_USER_COMPANY_LOGO,
  GET_USER_REDACTOR_PREFERENCE
} from "../actions/profilesActions";

const initialState = {
  profiles: [],
  current: null,
  roles: [],
  isViewer: false,
  logoUrl: null,
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_PROFILES:
      return { ...state, profiles: payload.profiles };

    case GET_CURRENT_PROFILE:
      return { ...state, current: payload.current };

    case CREATE_USER:
      return { ...state };

    case DELETE_USER:
      const userId = payload;
      const updatedUsersList = userId ? (state.profiles).filter((user = {}) => user.user_id !== userId) : state.profiles;
      return { ...state, profiles: updatedUsersList || []} ;

    case GET_ALL_ROLES:
      return { ...state, roles: payload.allRoles };

    case SET_IS_VIEWER:
      return { ...state, isViewer: payload.isViewer };

    case GET_USER_COMPANY_LOGO:
      return { ...state, logoUrl: payload };

    case GET_USER_REDACTOR_PREFERENCE:
      return { ...state, redactorPreference: payload };

    default:
      return state;
  }
}
