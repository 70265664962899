import React, { useState, useCallback, useEffect } from "react";
import { useHistory } from "react-router-dom";
import isEmpty from 'lodash/isEmpty';
import { Checkbox, Form, Input, Select } from "antd";
import Loading from "../../../../shared/loading/loading";
import { useDispatch, useSelector } from "react-redux";
import { getAllProvidersArray } from "../../../../utils/formatting";
import { VCS_TYPES } from "../../../../consts/general";
import { getAllVcs } from "../../../../redux/actions/globalAppActions";
import { clouds } from "../../../../utils/icons";
import SearchInput from "../../../inventory/pullRequestForm/searchInput";
import { getReposByVcsSelection, getVcsBranches } from "../../../../redux/actions/vcsActions";

import './workflowsSteps.scss';
import { nameRegex, validate } from "../../../../utils/validations";

const GeneralWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled, isEditMode = false }) => {
    const [form] = Form.useForm();
    const history = useHistory();

    const [loadingGithub, setLoadingGithub] = useState(false);
    const [loadingRepos, setLoadingRepos] = useState(false);
    const [loadingBranches, setLoadingBranches] = useState(false);
    const [loadingSearchRepos, setLoadingSearchRepos] = useState(false);
    const [selectedVcsType, setSelectedVcsType] = useState("");
    const [publicRepo, setPublicRepo] = useState(false);
    const [availableBranches, setAvailableBranches] = useState([]);

    const vcsIntegs = useSelector((state) => state.globalAppReducer.vcs);
    const availableRepos = useSelector((state) => state.vcsReducer.reposByVcs);
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    
    const isCodeCommitSelected = selectedVcsType === VCS_TYPES.codecommit;
    const showPrivatePublicCheckbox = selectedVcsType && !isCodeCommitSelected && !loadingGithub;

    const dispatch = useDispatch();
    const allVcsApps = useCallback(getAllProvidersArray(vcsIntegs), [vcsIntegs]);

    useEffect(()=> {
        fetchVcs();
        if (isEditMode) {
          const { repository, branch } = wizardState;
          getRepoOptions(repository);
          onChangeRepo(repository, branch)
        } 
    }, [])

    useEffect(() => {
        getRepoOptions()
    }, [publicRepo])

    useEffect(() => {
      checkValidation();
    }, [wizardState]);

    const fetchVcs = async () => {
        setLoadingGithub(true);
        await dispatch(getAllVcs());
        setLoadingGithub(false);
    };

    const checkValidation = () => {
        const { workspaceName, vcs, repository, branch, ciTool } = wizardState;
        const formValid = workspaceName && validate(nameRegex.regex, workspaceName) && vcs && repository && branch && ciTool;
        setSubmitDisabled(!formValid);
    }

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onChangeVcs = (vcsId) => {
        form.resetFields(["repository", "branch"]);
        getRepoOptions();
        onValuesChange({ vcs: vcsId, repository: "", branch: ""});
    }

    const renderVcsOptions = () => {
        return (allVcsApps || []).map((app) => (
          <Select.Option value={app?.id} key={app?.id}>
            <span className="PullRequestForm__content-body-vcs row">
              <img src={clouds(app.type, themeDark)} alt="integration" />
              {app.name}
            </span>
          </Select.Option>
        ));
      };
      
    const getVcsTypeById = (vcsId) => {
        const matchVcs = allVcsApps.find((vcsApp = {}) => vcsApp.id?.includes(vcsId)) || {}; 
        const type = matchVcs.name || "github";
        return type;
    };
    
    const getRepoOptions = async(repoName = "") => {
        const vcsId = form.getFieldValue("vcs");
        if (vcsId) {
          setLoadingSearchRepos(true);
          const type = getVcsTypeById(vcsId);
          await dispatch(getReposByVcsSelection(vcsId, type, repoName, publicRepo))
          setSelectedVcsType(type);
        }
        return setLoadingSearchRepos(false);
    }

    const onChangeRepo = async (repo, branchFromWizard) => {
        setLoadingBranches(true);
        form.resetFields(["branch"]);
        const vcsId = form.getFieldValue("vcs");
        const type = getVcsTypeById(vcsId);
        const req = await dispatch(getVcsBranches(type, vcsId, repo));

        const repoDefaultBranch = (availableRepos || []).find((item) => item?.fullName === repo)?.defaultBranch;
        const newBranched = (req || []).map((item) => ({ ...item, default: item?.name === repoDefaultBranch }));
        if (branchFromWizard) {
          form.setFieldsValue({ branch: branchFromWizard });
          onValuesChange({ branch: branchFromWizard });
          setAvailableBranches(newBranched);
          return setLoadingBranches(false);
        }
        // set the defualt branch in the form as selected
        const defaultBranch = newBranched.find((item) => item?.default);
        if (defaultBranch) {
          form.setFieldsValue({ branch: defaultBranch?.name });
        }
        onValuesChange({ repository: repo, branch: defaultBranch?.name });
        setAvailableBranches(newBranched);
        return setLoadingBranches(false);
    };
    
    const renderSelectByVcsSelection = () => {
          return (
              <SearchInput
                options={availableRepos}
                setFormValid={setSubmitDisabled}
                getOptions={(repoName) => getRepoOptions(repoName)}
                loading={loadingRepos || loadingSearchRepos}
                disabled={!wizardState.vcs}
                initialValue={wizardState.repository || ""}
                setRepositoryValue={(val) => {
                  form.setFieldsValue({ repository: val });
                  onChangeRepo(val);
                }}
              />
          );
    };

    const renderBranchOptions = () => {
        return (availableBranches || []).map((branch) => (
          <Select.Option value={branch?.name} key={branch?.name}>
            {`${branch?.name} ${branch?.default ? " (Default Branch)" : ""}`}
          </Select.Option>
        ));
    };

    return (
        <div className="WorkflowsSteps col">
           <span className="bold">General configuration</span>
           <div className="col g10">
           <Form
                name="general-form"
                form={form}
                initialValues={{
                  workspaceName: wizardState.workspaceName,
                  vcs: wizardState.vcs,
                  repository: wizardState.repository,
                  branch: wizardState.branch,
                  ciTool: wizardState.ciTool || 'github-action',
                  workingDirectory: wizardState.workingDirectory,
                }}
          >
          {loadingGithub && (
            <div className="PullRequestForm__content-body tab-page center">
              <Loading />
            </div>
          )}
          {!loadingGithub && (
            <div className="col">
               <Form.Item
                    label="Workspace Name"
                    name="workspaceName"
                    style={{ flexDirection: "column" }} 
                    rules={[
                      {
                        required: true,
                        pattern: nameRegex.regex,
                        message: nameRegex.msg,
                      },
                    ]}
                >
                  <Input placeholder="Enter Workspace name" value={wizardState.workspaceName || ""} onChange={(e) => onValuesChange({ workspaceName: e.target.value })}/>
              </Form.Item>
              <Form.Item
                label="VCS Integration"
                name="vcs"
                help={
                  !loadingGithub && isEmpty(allVcsApps) ? (
                    <div className="no-vcs-integs col">
                      <a onClick={() => history.push({
                        pathname: "/integrations/new-integration",
                        state: { scrollTo: "vcs" },
                      })}>
                        + Add Version Control Integration
                      </a>
                    </div>
                  ) : null
                }
                rules={[
                  {
                    required: true,
                    message: "Integration is required",
                  },
                ]}
                style={{ flexDirection: "column" }}
              >
                <Select
                  placeholder="Select VCS Integration"
                  showSearch
                  filterOption={(input, option) =>
                    option?.children?.props?.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                  disabled={loadingRepos || loadingSearchRepos}
                  style={{ width: "100%" }}
                  onChange={onChangeVcs}
                >
                  {renderVcsOptions()}
                </Select>
              </Form.Item>

                {!loadingGithub ? (
                    <Form.Item
                      name="repository"
                      label="Code Repository"
                      rules={[
                        {
                          required: true,
                          message: "Repository selection is a must",
                        },
                      ]}
                      style={{
                        flexDirection: "column",
                        marginBottom: showPrivatePublicCheckbox ? "10px": "24px",
                      }}
                    >
                      {renderSelectByVcsSelection()}
                    </Form.Item>
                  ) : null
                }

              {showPrivatePublicCheckbox && (
                <div className="col" style={{ marginBottom: "20px" }}>
                  <Checkbox
                    checked={publicRepo}
                    onChange={() => setPublicRepo(!publicRepo)}
                    disabled={loadingRepos}
                  >
                    <span className="form-label">
                      Show only private repositories
                    </span>
                  </Checkbox>
                </div>
              )}

              {
                !loadingGithub && (
                    <Form.Item
                      label="Target Branch"
                      name="branch"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Select
                        placeholder="Select Target Branch"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        disabled={!wizardState.repository || loadingBranches}
                        style={{ width: "100%" }}
                        loading={loadingBranches || loadingRepos}
                        onChange={(val) => onValuesChange({ branch: val })}
                      >
                        {renderBranchOptions()}
                      </Select>
                    </Form.Item>
                )}
              {!loadingGithub && (
                    <Form.Item
                      label="CI Tool"
                      name="ciTool"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Select
                        placeholder="Select CI Tool"
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        style={{ width: "100%" }}
                        loading={loadingBranches}
                        onChange={(val) => onValuesChange({ ciTool: val })}
                      >
                        <Select.Option value="github-action">GitHub Actions</Select.Option>
                      </Select>
                    </Form.Item>
                )}
              {!loadingGithub && (
                    <Form.Item
                      label="Working Directory (optional)"
                      name="workingDirectory"
                      style={{
                        flexDirection: "column",
                      }}
                    >
                      <Input placeholder="Enter Working Directory" value={wizardState.workingDirectory || ""} onChange={(e) => onValuesChange({ workingDirectory: e.target.value })}/>
                    </Form.Item>
                )}
            </div>
           )}
         </Form>
        </div>
       </div>
    );
};

export default GeneralWorkflowConfiguration;