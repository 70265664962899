import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_ENVIRONMENTS = "GET_ENVIRONMENTS";
export const CREATE_ENVIRONMENT = "CREATE_ENVIRONMENT";
export const DELETE_ENVIRONMENT = "DELETE_ENVIRONMENT";
export const ENVIRONMENT_DATA = "ENVIRONMENT_DATA";
export const SET_ENV_GLOBAL_DATA = "SET_ENV_GLOBAL_DATA";

const getEnvironments = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(`${BACKEND_URL}/environments`, "GET");

    const data = await response.json();

    dispatch({
      type: GET_ENVIRONMENTS,
      payload: { data },
    });
  });
};

const createEnvironment = (
  name,
  owner,
  type,
  labels
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      owner,
      type,
      labels
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_ENVIRONMENT,
      payload: {},
    });

    const json = await res.json();
    return { environment: json, req: res };
  });
};

const deleteEnvironment = (environmentId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedEnvironmentReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/${environmentId}`,
      "DELETE"
    );
    const data = await deletedEnvironmentReq.json();
    return { response: data };
  });
};

const getEnvironmentById = (environmentId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/getById/${environmentId}`,
      "GET",
    );

    const data = await response.json();

    dispatch({
      type: ENVIRONMENT_DATA,
      payload: { data },
    });

    return data;

  });
}

const setEnvironmentGlobalData = (envData) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_ENV_GLOBAL_DATA,
      payload: envData,
    });
  });
};

export { getEnvironments, createEnvironment, deleteEnvironment, getEnvironmentById, setEnvironmentGlobalData };
