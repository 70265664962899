import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { Spin } from 'antd';
import AppBtn from '../../shared/appBtn/appBtn';
import {ReactComponent as PrimaryIcon} from '../../Images/general_icons/PrimaryIcon.svg'
import {ReactComponent as TerraformIcon} from '../../Images/general_icons/TFlogo.svg'
import moment from 'moment';
import { dateTimeFormat } from '../../utils/formatting';
import { appToast } from '../../shared/appToast/appToast';
import { useDispatch } from 'react-redux';
import { runFullScan, scanGcpAssociatedProjects, scanIntegrationPrimaryProject } from '../../redux/actions/integrationsActions';
import { isEmpty } from 'lodash';

import './gcpDiscoveryCard.scss';

const GcpDiscoveryCard = ({ type, integration, syncDates, terraformCount, loading, totalAssets }) => {

    const DISPLAY_DATA = {
        primary:{
            title:`Google Cloud ${integration?.isPrimary ? 'Primary':''} Project`,
            description:"Discovered assets (including exclusions and child assets):",
            icon:<PrimaryIcon />
        },
        buckets:{
            title:"GCS Buckets for Terraform Stacks",
            description:"Terraform stacks discovered in GCS buckets:",
            icon:<TerraformIcon />
        },
        sub:{
            title:"Google Cloud Sub-Projects",
            description:"Discovered sub-projects:",
            icon:<FontAwesomeIcon icon="layer-group" />
        }
    }

    const dispatch = useDispatch();
    const [syncLoading, setSyncLoading] = useState(false)
    const renderCount = () => {
        switch(type){
            case "primary":
                return totalAssets;
            case "buckets":
                return terraformCount ?? 0;
            case "sub":
                return integration?.projectsStatus?.filter(project => !project.isDeleted)?.length - 1 || 0;
            default:
                return "";
        }
    }
    const getLastScanDate = () => {
        
        switch(type){
            case "primary":
                return syncDates?.integrationLastSync ? moment(syncDates?.integrationLastSync).format(dateTimeFormat) : "-";
            case "buckets":
                return syncDates?.gcsTerraformLastSync ? moment(syncDates?.gcsTerraformLastSync).format(dateTimeFormat) : "-";
            case "sub":
                return syncDates?.projectsDiscoveryLastSync ? moment(syncDates?.projectsDiscoveryLastSync).format(dateTimeFormat) : "-";
            default:
                return "N/A";
        }
    }


    const handleSync = async () => {
        const localLastFetched = localStorage.getItem(`gcpScan${type}`);
        if (!isEmpty(localLastFetched)) {
            const thirtyMinutesFromFetch = moment.unix(parseInt(localLastFetched)).add(30, "minutes");
            const difference = moment().diff(thirtyMinutesFromFetch);
            if (difference < 0) {
                appToast("info", "", "Last scan occurred less than 30 minutes ago, please try again later");
                return;
            }
        }
        setSyncLoading(true);
        let scanResult = true;
        
        switch(type){
            case "primary":
                scanResult = await dispatch(scanIntegrationPrimaryProject(integration.id, integration.accountId, 'google'));
                break;
            case "buckets":
                scanResult = await dispatch(runFullScan(integration.id, "gcs"));
                break;
            case "sub":
                scanResult = await dispatch(scanGcpAssociatedProjects(integration.id, integration.accountId));
                break;
        }
        if (!scanResult) {
            return setSyncLoading(false);
          } else {
            localStorage.setItem(`gcpScan${type}`, moment().unix());
            appToast("success", "", "Started a new scan");
          }
          setSyncLoading(false);
    }
    return (
        <div className="GcpDiscoveryCard basic-card">
            <div className="GcpDiscoveryCard__title">
               {DISPLAY_DATA[type].title}
                <span>{DISPLAY_DATA[type].icon}</span>
            </div>
            <div className="GcpDiscoveryCard__content">
                <p>{DISPLAY_DATA[type].description}</p>
                <div className="GcpDiscoveryCard__content-count">{loading ? <Spin /> : renderCount()}</div>
                <div className="GcpDiscoveryCard__content-footer">
                    <span className='GcpDiscoveryCard__content-footer-date'>
                        Last Scan: {getLastScanDate()}
                    </span>
                    <AppBtn
                        loading={syncLoading}
                        disabled={syncLoading}
                        onClick={handleSync}
                        text="Scan now"
                        icon={
                            <FontAwesomeIcon
                                icon="sync-alt"
                                color='white'
                            />
                        }
                    />
                </div>
            </div>
        </div>
    );
}

export default GcpDiscoveryCard;