import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import AssetInfoCard from "../assetInfoCard";
import { clouds } from "../../../../../utils/icons";
import "./vcsCode.scss";
import { PROVIDERS } from "../../../../../consts/general";

const VcsCode = ({ asset }) => {
  const vcsIntegrations = useSelector((state) => state.globalAppReducer.vcs);
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const renderIntegMatched = () => {
    if (!_.isEmpty(vcsIntegrations) && !_.isEmpty(asset?.vcsId)) {
      const integsArr = _.flatten(
        _.map(vcsIntegrations || {}, (val) => {
          return val;
        })
      );
      const match = _.find(
        integsArr || [],
        (item) => item?.id === asset?.vcsId
      );
      return _.isEmpty(match) ? null : match;
    }
    return null;
  };

  const renderIntegBlock = () => {
    const integ = renderIntegMatched();

    if (!_.isEmpty(integ)) {
      return (
        <div className="row VcsCode-integ">
          <img src={clouds(integ?.type, themeDark)} alt={integ?.type} />
          <span>{integ?.name}</span>
        </div>
      );
    }
    return null;
  };
  const generateRepoLink = () => {
    const {vcsCodeLink = '', vcsProvider = '', vcsRepo = '' } = asset
    if (!vcsCodeLink) return `https://${vcsProvider}.com/${vcsRepo}`
    let splitted = ''
    if (vcsProvider === PROVIDERS.codecommit) {
      const repoName = _.split(vcsRepo, '/')[1]
      splitted = _.split(vcsCodeLink, repoName)[0]
      return `${splitted}${repoName}/browse`
    }
    splitted = _.split(vcsCodeLink, vcsRepo)[0]
    return `${splitted}${vcsRepo}`
  }

  return (
    <AssetInfoCard
      contentArr={[
        {
          label: "Integration",
          content: renderIntegBlock(),
          display: !_.isEmpty(renderIntegMatched()),
        },
        {
          label: "Repository",
          content: (
            <a
              href={generateRepoLink()}
              target="_blank"
              rel="noopener noreferrer"
              className="VcsCode-link underline"
            >
              {asset?.vcsRepo?.includes("/")
                ? asset?.vcsRepo?.split("/")?.[1]
                : asset?.vcsRepo}
            </a>
          ),
          display: asset?.vcsRepo && asset?.vcsProvider,
        },
        {
          label: "Path",
          content: (
            <a
              href={asset?.vcsCodeLink}
              target="_blank"
              rel="noopener noreferrer"
              className="VcsCode-link underline"
            >
              {asset?.vcsCodeLink?.includes("/") &&
              asset?.vcsCodeLink?.includes("#L")
                ? asset?.vcsCodeLink
                    ?.split("/")
                    ?.slice(7)
                    ?.join("/")
                    ?.split("#L")?.[0]
                : asset?.vcsCodeLink}
            </a>
          ),
          display: asset?.vcsCodeLink,
        },
      ]}
    />
  );
};

export default VcsCode;
