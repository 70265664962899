import React from "react";
import ProviderIcon from "../../shared/providerIcon/providerIcon";

import "./providerFlag.scss";

const ProviderFlag = ({ provider, providerId }) => {
  return (
    <div className={`ProviderFlag row ${provider}`}>
      <ProviderIcon provider={provider} customStyle="drift" />
      {providerId}
    </div>
  );
};

export default ProviderFlag;
