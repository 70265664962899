import React, { useState, useCallback, useEffect } from "react";
import { AutoComplete } from "antd";
import { debounce, isEmpty, map } from "lodash";
import { v4 as uuidv4 } from "uuid";

const SearchInput = ({ options, setFormValid, getOptions, loading, setRepositoryValue, initialValue , disabled = false}) => {
  const [value, setValue] = useState("");
  
  useEffect(() => {
    if (initialValue) {
      setValue(initialValue)
    }
  }, [])

  const handleSearch = (newValue) => {
    setValue(newValue);
    useDebounce(newValue || "");
  };

  const useDebounce = useCallback(
    debounce((val) => {
      getOptions(val);
    }, 500),
    []
  );

  const handleSelect = (newValue) => {
    setValue(newValue);
    setRepositoryValue(newValue);
    setFormValid(true);
  };

  const renderOptions = () => {
    return map(options, (repo = {}) => (
      <AutoComplete.Option value={repo.fullName} key={uuidv4()}>
        {repo.fullName}
      </AutoComplete.Option>
    ));
  };

  return (
    <AutoComplete
      showSearch
      showArrow
      allowClear={!isEmpty(value)}
      value={value}
      placeholder="Please choose/search repository"
      style={{ width: "100%" }}
      onSearch={handleSearch}
      disabled={disabled}
      onSelect={handleSelect}
      onClear={() => handleSelect("")}
      notFoundContent="No Data"
      filterOption={false}
      loading={loading}
    >
      {renderOptions()}
    </AutoComplete>
  );
};

export default SearchInput;
