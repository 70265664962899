import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toggleSidebar } from "../../../redux/actions/userPreferencesActions";
import "./sidebarToggle.scss";

const SidebarToggle = () => {
  const dispatch = useDispatch();
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  const handleCollapseSider = () => {
    dispatch(toggleSidebar(sidebarClosed));
  }

  return (
      <button className="SidebarToggle" onClick={handleCollapseSider}>
        <FontAwesomeIcon icon={sidebarClosed ? 'arrow-right' : 'arrow-left'} />
      </button>
  );
};

export default SidebarToggle;
