import React from "react";
import _ from "lodash";
import moment from "moment";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";
import CodeDiff from "../../../../../shared/codeDiff/codeDiff";
import { dateTimeFormat } from "../../../../../utils/formatting";

const RevisionCompare = ({ asset_compare, selectedRevision }) => {
  const handleSetComapreBlock = (obj) => {
    if (_.isObject(obj)) {
      return JSON.stringify(obj, null, 2);
    }
    if (_.isEmpty(obj)) {
      return "";
    }
    return "";
  };

  return (
    <div className="RevisionCompare row">
      {_.isEmpty(asset_compare) ? (
        <div className="RevisionCompare__empty center">
          <AppEmpty customStyle="code" text="No Data" />
        </div>
      ) : (
        <div className="RevisionCompare__diff col">
          <div className="RevisionCompare__diff-title row">
            <div className="RevisionCompare__diff-title-block center">{` ${selectedRevision?.timeEpoch
                ? moment(selectedRevision?.timeEpoch).format(dateTimeFormat)
                : ""
              }`}</div>
            <div className="RevisionCompare__diff-title-block center">
              Current Configuration
            </div>
          </div>
          <CodeDiff
            lang="text"
            oldValue={handleSetComapreBlock(asset_compare?.revision1)}
            newValue={handleSetComapreBlock(asset_compare?.revision2)}
          />
        </div>
      )}
    </div>
  );
};

export default RevisionCompare;
