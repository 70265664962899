import React from "react";
import Popover from "antd/lib/popover";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import DropMenu from '../../../shared/dropMenu/dropMenu';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './inventoryTableActions.scss';

const InventoryTableActions = ({
  disableExport,
  onExport,
  disabledIssue,
  onCreateIssue,
}) => {
  const links = [
    {
      text: "Export table",
      icon: <FontAwesomeIcon icon="download" />,
      onClick: () => onExport(),
      disabled: disableExport,
    },
    {
      text: "Create Jira issue",
      icon: <FontAwesomeIcon icon={["fab", "jira"]} />,
      onClick: () => onCreateIssue(),
      disabled: disabledIssue,
    },
  ];

  return (
    <Popover
      content={<DropMenu links={links} />}
      title={null}
      placement="bottomRight"
      destroyTooltipOnHide
      overlayClassName="InventoryTableActions"
    >
      <div className="InventoryTableActions__toggle">
        <IconBtn
          type="menu-h"
          colorClass="h-menu"
        />
      </div>
    </Popover>
  );
};

export default InventoryTableActions;
