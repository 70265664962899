import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { handleSetStep } from "../../redux/actions/onbordingActions";
import { useAuth0 } from "@auth0/auth0-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { renderUserName } from "../../utils/helpers";
import WelcomeStep from "../../components/onBoarding/welcomeStep/welcomeStep";
import ProviderStep from "../../components/onBoarding/providerStep/providerStep";
import IacStep from "../../components/onBoarding/iacStep/iacStep";
import GitStep from "../../components/onBoarding/gitStep/gitStep";
import NotificationStep from "../../components/onBoarding/notificationStep/notificationStep";
// import CalendlyStep from "../../components/onBoarding/calendlyStep/calendlyStep";
import Button from "antd/lib/button";
import Space from "antd/lib/space";
import isEmpty from "lodash/isEmpty";
import filter from "lodash/filter";
import ConfirmationModal from "../../shared/confirmationModal/confirmationModal";

import { sendEvent } from "../../utils/amplitude";
import { onboardingEvents } from "../../utils/amplitudeEvents";
import { ONBORDING_STATUS_NAMES } from '../../consts/onbording';
import "./onBoarding.scss";

const OnBoarding = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const onbordingStatus = useSelector((state) => state.onbordingReducer.status);
  const integrations = useSelector(
    (state) => state.onbordingReducer.integrations
  );
  const [comingSoonAlertOpen, setComingSoonAlertOpen] = useState(false);
  const [comingSoonIntegSelected, setComingSoonIntegSelected] = useState(null);

  const handleOpenComingSoonAlert = (integrationName) => {
    setComingSoonIntegSelected(integrationName);
    setComingSoonAlertOpen(true);
    return;
  };

  const steps = [
    {
      title: `Hi ${renderUserName(user)}, Welcome to Firefly`,
      second_sub: "Get started in 3 minutes.",
      content: <WelcomeStep />,
    },
    {
      title: `Connect your Data Sources`,
      sub: "To get started with Firefly, connect at least one data source.",
      second_sub: "Don’t worry, it takes just a minute of your time.",
      content: <ProviderStep openComingSoonAlert={handleOpenComingSoonAlert} />,
    },
    {
      title: `Infrastructure-as-Code tools (optional)`,
      sub: "",
      content: <IacStep />,
    },
    {
      title: `Connect your version control system (optional)`,
      sub: "Connect your Git to experience Firefly's GitOps capabilities and automated remediation.",
      content: <GitStep />,
    },
    {
      title: `Connect your notification system (optional)`,
      sub: `Firefly's alert system discovers drifts, unmanaged resources and policy violations in real-time`,
      content: <NotificationStep />,
    },
    // {
    //   title: `Firefly team can show you even more`,
    //   sub: "",
    //   content: <CalendlyStep />,
    // },
  ];

  // when to disabled to next button ==> only once when no cloud providers
  const handleDisabledNextBtn = () => {
    const { aws, k8s, datadog, akamai, github, okta, gcs, newrelic, cloudflare, ns1, pagerduty, azurerm } =
      integrations;
    return (
      onbordingStatus === 2 &&
      isEmpty(aws) &&
      isEmpty(k8s) &&
      isEmpty(datadog) &&
      isEmpty(akamai) &&
      isEmpty(github) &&
      isEmpty(okta) &&
      isEmpty(filter(gcs, (item) => item?.source === "GCP")) &&
      isEmpty(newrelic) && 
      isEmpty(cloudflare) && 
      isEmpty(ns1) &&
      isEmpty(azurerm)
      // isEmpty(pagerduty)
    );
  };

  // show skip or continue depend on integration status and current step
  const handleContinueOrLater = () => {
    const { gcs, git, notifications, pulumi, terraform } = integrations;

    if (
      onbordingStatus === 1 ||
      onbordingStatus === 2 ||
      onbordingStatus === 3
    ) {
      return "Continue";
    }

    if (
      isEmpty(gcs) &&
      isEmpty(pulumi) &&
      isEmpty(terraform) &&
      onbordingStatus === 3
    ) {
      return "Skip";
    }
    if (isEmpty(git) && onbordingStatus === 4) {
      return "Skip";
    }
    if (isEmpty(notifications) && onbordingStatus === 5) {
      return "Skip";
    }
    return "Continue";
  };

  const handleOnboardingNext = () => {
    if(onbordingStatus === steps.length) {
      sendEvent(onboardingEvents.onboardingDone);
    }
    const stepName = ONBORDING_STATUS_NAMES[onbordingStatus];
    sendEvent(`${onboardingEvents.onboardingStepProgress} ${stepName}`)
    dispatch(handleSetStep(onbordingStatus + 1))
  }

  return (
    <div className="OnBoarding center">
      <div className="no-padding-card OnBoarding__wizard col">
        <div className="OnBoarding__wizard-body col">
          <div className="OnBoarding__wizard-body-header center col">
            {steps[onbordingStatus - 1]?.image && (
              <img src={`${steps[onbordingStatus - 1]?.image}`} />
            )}
            <span className="OnBoarding__wizard-body-header-title bold purple-text">
              {steps[onbordingStatus - 1]?.title}
            </span>
            <span className="OnBoarding__wizard-body-header-sub">
              {steps[onbordingStatus - 1]?.sub}
            </span>
            {!isEmpty(steps[onbordingStatus - 1]?.second_sub) && (
              <span className="OnBoarding__wizard-body-header-sub">
                {steps[onbordingStatus - 1]?.second_sub}
              </span>
            )}
          </div>
          {steps[onbordingStatus - 1]?.content}
        </div>
        <div className="OnBoarding__wizard-footer row between">
          <Button
            onClick={() => dispatch(handleSetStep(onbordingStatus - 1))}
            disabled={onbordingStatus === 1}
            type="text"
          >
            <Space>
              <FontAwesomeIcon icon="long-arrow-alt-left" />
              Back
            </Space>
          </Button>
          <Button
            type="primary"
            onClick={handleOnboardingNext}
            disabled={handleDisabledNextBtn()}
          >
            <Space>
              {onbordingStatus === steps.length
                ? "Take me in"
                : handleContinueOrLater()}
              <FontAwesomeIcon icon="long-arrow-alt-right" />
            </Space>
          </Button>
        </div>
      </div>
      <ConfirmationModal
        visible={comingSoonAlertOpen}
        handleClose={() => setComingSoonAlertOpen(false)}
        title={`Our support for ${comingSoonIntegSelected} integration is coming soon.`}
        description={
          <span className="col" style={{ marginBottom: "20px" }}>
            <span>Don’t worry, We'll let you know once it’s ready.</span>
            <span>
              In the meantime, feel free to explore the providers we currently support.
            </span>
          </span>
        }
        hidePrimaryButton
        cancelBtnText="Close"
      />
    </div>
  );
};

export default OnBoarding;
