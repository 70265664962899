import React from "react";
import StepsSidebar from "../stepsSidebar/stepsSidebar";
import "./onBoardingLayout.scss";

const OnBoardingLayout = ({ children }) => {
  return (
    <div className="OnBoardingLayout">
      <StepsSidebar />
      <div className="OnBoardingLayout__content">{children}</div>
    </div>
  );
};

export default OnBoardingLayout;
