import React from "react";
import isEmpty from "lodash/isEmpty";
import Modal from "antd/lib/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./newAppModal.scss";

const NewAppModal = ({
  visible,
  handleClose,
  children,
  title,
  subtitle,
  centered,
  bodyClassName,
  width,
  iconSrc,
  destroyOnClose,
  footer,
  maskStyle,
  headerSuffix,
  customFooterClassName = "",
}) => {
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="NewAppModal"
      closable={false}
      centered={centered ? true : false}
      width={width}
      destroyOnClose={destroyOnClose}
      maskStyle={{
        backgroundColor: maskStyle ? maskStyle : "rgba(0, 0, 0, 0.45)",
      }}
    >
      {!isEmpty(title) && (
        <div className="NewAppModal__header row">
          <button className="NewAppModal__header-close" onClick={handleClose}>
            <FontAwesomeIcon icon={faTimes} />
          </button>

          {iconSrc && (
            <img
              src={iconSrc}
              alt="icon"
              className="NewAppModal__header-icon row"
            />
          )}

          <div className="NewAppModal__header-text col">
            <span className="NewAppModal__header-text-title bold">{title}</span>
            {subtitle && (
              <span className="NewAppModal__header-text-sub sub-title">
                {subtitle}
              </span>
            )}
          </div>
          {headerSuffix && <div style={{ marginLeft: "auto" }}>
            {headerSuffix}
          </div>}
        </div>
      )}
      <div className={`NewAppModal__body ${bodyClassName}`}>{children}</div>

      {footer ? <div className={`NewAppModal__footer ${customFooterClassName}`}>{footer}</div> : null}
    </Modal>
  );
};

export default NewAppModal;
