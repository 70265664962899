import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { Collapse } from 'antd';
import { getWorkflowsAggs, handleSetCiFilters } from '../../redux/actions/ciWorkflowsActions';
import InventorySingelFilter from '../../components/inventory/inventoryFilters/inventorySingelFilter';
import { ReactComponent as Chevron } from '../../Images/general_icons/chevron.svg';
import { ReactComponent as RepoIcon } from '../../Images/ci/filters/repo.svg';
import { ReactComponent as CiToolIcon } from '../../Images/ci/filters/ci-tool.svg';
import { ReactComponent as StatusIcon } from '../../Images/ci/filters/status.svg';

import './ciFilters.scss';

const { Panel } = Collapse;
const filterIcons = {
    repo: RepoIcon,
    ciTool: CiToolIcon,
    status: StatusIcon,
}

const CiFilters = ({ searchInput }) => {
    const [activePanel, setActivePanel] = useState("0");

    const { t } = useTranslation("ci-pipeline", { keyPrefix: "filters" });
    const ciAggs = useSelector((state) => state.ciWorkflowsReducer.ciAggs) || {};
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters) || {};
    const { repos: reposAggs = [], ciTool: ciToolAggs = []  } = ciAggs;
    const { repos: reposFilters = [], ciTool: ciToolFilters = [] } = ciFilters;

    const dispatch = useDispatch();

    useEffect(() => {
        fetchCiAggs();
    }, [searchInput]);
    
    const fetchCiAggs = async () => dispatch(getWorkflowsAggs(searchInput));
    const handleSetFilters = async (newFilters) => dispatch(handleSetCiFilters(newFilters));

    const panelHeader = (title, activeArr, idx, iconKey) => {
        const IconComponent = filterIcons[iconKey];
        const isActive = !isEmpty(activeArr) || activePanel == idx;
        return (
          <div className="CiFilters__item row">
            <IconComponent className={`CiFilters__item-icon ${isActive ? 'active' : ''}`}/>
            <span
              className={`CiFilters__item-title row ${isActive ? 'active' : '' }`}
            >
              <span>{title}</span>
              {!isEmpty(activeArr) && (
                <span className="purple-text">{`(${activeArr?.length})`}</span>
              )}
            </span>
          </div>
        );
    };

    const handleCutsomArrow = (e) => <Chevron style={{ transform: e.isActive ? "translateY(2px)" : "rotate(-90deg) translateY(2px)", transition: "all .2s ease", width: "10px"}} />;

    return (
        <div className="CiFilters col g10">
            <span className="CiFilters__title bold">Filters</span>
            <div className="CiFilters__container col">
                <Collapse
                    expandIconPosition="right"
                    expandIcon={handleCutsomArrow}
                    activeKey={activePanel}
                    onChange={(panel) => setActivePanel(panel)}
                    accordion
                >
                    <Panel header={panelHeader(t('repository'), reposFilters, "1", "repo")} key="1">
                    <InventorySingelFilter
                        allTypes={reposAggs}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, repos: selected })}
                        checkedList={reposFilters}
                        title={t('search')}
                        active={activePanel === "1"}
                        noAnimation
                    />
                    </Panel>
                    <Panel header={panelHeader(t('ciTool'), ciToolFilters, "2", "ciTool")} key="2">
                    <InventorySingelFilter
                        allTypes={ciToolAggs}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, ciTool: selected })}
                        checkedList={ciToolFilters}
                        title={t('search')}
                        active={activePanel === "2"}
                        noAnimation
                    />
                    </Panel>
                    {/* <Panel header={panelHeader(t('owner'),  ciFilters.owner, "3")} key="3">
                    <InventorySingelFilter
                        allTypes={ciAggs.owner}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, owner: selected })}
                        checkedList={ciFilters.owner}
                        title={t('search')}
                        active={activePanel === "3"}
                    />
                    </Panel> */}
                    {/* <Panel header={panelHeader(t('status'), statusFilters, "4", "status")} key="4">
                    <InventorySingelFilter
                        allTypes={handleArrangeStatus(statusAggs)}
                        selectedTypes={(selected) => handleSetFilters({ ...ciFilters, status: selected })}
                        checkedList={statusFilters}
                        title={t('search')}
                        active={activePanel === "4"}
                    />
                    </Panel> */}
                </Collapse>
            </div>
        </div>
    );
};

export default CiFilters;