import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import CardBox from "../../shared/cardBox/cardBox";
import { clouds, iacProviders } from "../../utils/icons";

const ProviderSelection = ({
  selectedProvider,
  handleSelectProvider,
  setSubmitDisabled,
  hideHelm,
  hideCft,
  hidePulumi,
  showGcp,
  onClickMethod,
  comingSoonTF,
  comingSoonPC,
  gcpIntegration,
  awsDesciption,
}) => {
  const history = useHistory();

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  return (
    <div className="ProviderSelection col">
      {awsDesciption && (
        <div className="dark-blue col" style={{ marginBottom: '40px', gap: '4px' }}>
          <span>
            Firefly’s AWS integration requires creating an <span className="code-flag">IAM Role</span> in your
            AWS account.
          </span>
          <span>
            The IAM role allows Firefly to perform read-only (Get, List, and
            Describe) access for the metadata of AWS services.
          </span>
          <span>
            You can use either CloudFormation or Terraform metadata.
          </span>
        </div>
      )}
      <div className="ProviderSelection__list row">
        {showGcp && (
          <CardBox
            title="Service Account Key"
            active={selectedProvider === "gcp"}
            onClick={() => {
              if (!onClickMethod) {
                handleSelectProvider("gcp");
                setSubmitDisabled(false);
              } else {
                history.push("/integrations/gcp-provider-integration/gcp");
              }
            }}
            img={clouds("gcp", themeDark)}
          />
        )}
         {!hideCft && (
          <CardBox
            title="CloudFormation"
            active={selectedProvider === "cloudFormation"}
            onClick={() => {
              if (!onClickMethod) {
                handleSelectProvider("cloudFormation");
                setSubmitDisabled(false);
              } else {
                history.push("/integrations/aws-integration/cloud-formation");
              }
            }}
            img={iacProviders("cloudFormation")}
          />
        )}
        <CardBox
          title="Terraform"
          active={selectedProvider === "terraform"}
          onClick={() => {
            if (!onClickMethod) {
              handleSelectProvider("terraform");
              setSubmitDisabled(false);
            } else {
              history.push(
                `/integrations/${
                  gcpIntegration
                    ? "gcp-provider-integration"
                    : "aws-integration"
                }/terraform`
              );
            }
          }}
          img={iacProviders("terraform")}
          comingSoon={comingSoonTF}
        />
        {/* {!hidePulumi && (
          <CardBox
            title="Pulumi"
            active={selectedProvider === "pulumi"}
            onClick={() => {
              if (!onClickMethod) {
                handleSelectProvider("pulumi");
                setSubmitDisabled(false);
              } else {
                history.push("/integrations/aws-integration/pulumi");
              }
            }}
            img={iacProviders("pulumi")}
            comingSoon={comingSoonPC}
          />
        )} */}
        {!hideHelm && (
          <CardBox title="Helm" comingSoon img={iacProviders("helm", themeDark)} />
        )}
      </div>
    </div>
  );
};

export default ProviderSelection;
