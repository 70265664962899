import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
export const GET_HELM_CHARTS = "GET_HELM_CHARTS";
export const GET_HELM_BY_RELEASE_NAME = "GET_HELM_BY_RELEASE_NAME";

const getAll = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/helm/`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: GET_HELM_CHARTS,
      payload: { data },
    });
  });
};

const getManifestByReleaseName = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/helm/manifest/`,
      "POST",
      { id }
    );

    const data = await response.json();
    return data;
  });
};
export { getAll, getManifestByReleaseName };
