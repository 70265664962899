import React from "react";
import toLower from "lodash/toLower";
import Tooltip from "antd/lib/tooltip";
import { insights } from '../../../utils/icons';
import "./insightCategoryLabel.scss";

const InsightCategoryLabel = ({ category, withText, noIcon, colorMode, disable }) => {
  const renderIcon = () => {
    const string = toLower(category);
    switch (string) {
      case "reliability":
        return <img src={insights['reliability']} alt="reliability" />;

      case "optimization":
        return <img src={insights['optimization']} alt="optimization" />;

      case "misconfiguration":
        return <img src={insights['misconfiguration']} alt="misconfiguration" />;

      default:
        return null;
    }
  };

  return (
    <div className="InsightCategoryLabel">
      <Tooltip
        placement="top"
        title={!withText ? category : null}
      >
        <div className="InsightCategoryLabel__labelWrapper row" style={{ fontSize: colorMode ? '11px' : '14px' }}>
          {!noIcon && <span className={`${toLower(category)} ${disable ? 'disable' : ''}`}>{renderIcon()}</span>}
          {withText ? <span className={`${colorMode ? `bold ${toLower(category)}` : 'text'} ${disable ? 'disable' : ''}`}>{category}</span> : null}
        </div>
      </Tooltip>
    </div>
  );
};

export default InsightCategoryLabel;