import { SET_STATUS, GET_ONBORDING_STATUS, GET_ACCOUNT_INTEGS, GET_SCAN_STATUS } from "../actions/onbordingActions";
import { getStatusIdxByName, ONBORDING_STATUS_NAMES } from "../../consts/onbording";

const initialState = {
  status: ONBORDING_STATUS_NAMES?.length, // set the wizard to done, case app server fails
  integrations: {},
  account: {},
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_STATUS:
      return { ...state, status: payload };

    case GET_ONBORDING_STATUS:
      const statusNumber = getStatusIdxByName(payload?.onbording_status);
      return { ...state, status: statusNumber, account: payload };

    case GET_ACCOUNT_INTEGS:
      return { ...state, integrations: payload };

    case GET_SCAN_STATUS:
      return { ...state, account: payload };

    default:
      return state;
  }
};
