import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from "../../consts/config";

export const TERRAFORM_INIT = "TERRAFORM_INIT";
export const TERRAFORM_PLAN = "TERRAFORM_PLAN";
export const TERRAFORM_APPLY = "TERRAFORM_APPLY";
export const UPDATE_STEP = "UPDATE_STEP";

const terraformInit = (environmentId, description) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();

        const payload = { description };
        const runReq = await requestWrapper.sendRequest(`${BACKEND_URL}/runs/${environmentId}/init`, "POST", payload);

        let run = null;
        let hasError = null;
        let isInitalized = false;

        if(!runReq.ok){
            hasError = "An error occurred while trying to init terraform";
        }else{
            run = await runReq.json();
            isInitalized = run.isInitalized;
        }

        dispatch({
            type: TERRAFORM_INIT,
            payload: { run, hasError, isInitalized }
        });
    });
}

const terraformPlan = () => {
    return action(async (dispatch, getState) => {

        const state = getState();
        const runId = state.terraformReducer.run.id;

        const requestWrapper = new RequestWrapper();

        const runReq = await requestWrapper.sendRequest(`${BACKEND_URL}/runs/${runId}/plan`, "POST");
        const planReq = await requestWrapper.sendRequest(`${BACKEND_URL}/runs/${runId}/plan`, "GET");

        let run = null;
        let isPlan = false;
        let hasError = null;
        let plan = null;
        
        if(!runReq.ok){
            hasError = "An error occurred while trying to plan terraform";
        }else{
            run = await runReq.json();
            isPlan = run.isPlan;
        }

        if(!planReq.ok){
            hasError = "Could not get plan document";
        }else{
            plan = await planReq.json();
        }

        dispatch({
            type: TERRAFORM_PLAN,
            payload: { run, plan, hasError, isPlan }
        });
    });
}

const terraformApply = () => {
    return action(async (dispatch, getState) => {

        const state = getState();
        const runId = state.terraformReducer.run.id;

        const requestWrapper = new RequestWrapper();

        const runReq = await requestWrapper.sendRequest(`${BACKEND_URL}/runs/${runId}/apply`, "POST");
        const applyReq = await requestWrapper.sendRequest(`${BACKEND_URL}/runs/${runId}/apply`, "GET");

        let run = null;
        let isApply = false;
        let hasError = null;
        let apply = null;
        
        if(!runReq.ok){
            hasError = "An error occurred while trying to apply terraform";
        }else{
            run = await runReq.json();
            isApply = run.isApply;
        }

        if(!applyReq.ok){
            hasError = "Could not get apply document";
        }else{
            apply = await applyReq.json();
        }
        
        dispatch({
            type: TERRAFORM_APPLY,
            payload: { run, apply, hasError, isApply }
        });
    });
}

const updateStep = (step) => {
    return action(async dispatch => {

        dispatch({
            type: UPDATE_STEP,
            payload: { step }
        });
    });
}

export {
    terraformInit,
    terraformPlan,
    terraformApply,
    updateStep

}
