import { BACKEND_URL } from "../consts/config";
import { appToast } from "../shared/appToast/appToast";
import RequestWrapper from "../utils/requestWrapper";

let resourcesAbortController;

const fetchIntegrationDiscoveryStatus = async ({ integrationType, integrationId }) => {
    try {
        const requestWrapper = new RequestWrapper();
        const integrationsReq = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/discovery/${integrationType}/${integrationId}`,
            "GET"
        );
        let data;
        if (integrationsReq?.ok) {
           data = await integrationsReq?.json();
        }
        return data;
    } catch (error) {
        appToast(
            "error",
            "Error occurred",
            error?.message
        )
    }
}

const fetchIntegrationDiscoveryResources = async ({ integrationType, integrationId, search, pageSize, pageNumber, sorting }) => {
    try {
        const requestWrapper = new RequestWrapper();

        if (resourcesAbortController) {
            resourcesAbortController.abort();
        }
        resourcesAbortController = new AbortController();
        const integrationsReq = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/discovery/${integrationType}/${integrationId}/resources`,
            "POST", {
            search,
            pageSize,
            pageNumber,
            sorting
        },
            undefined,
            undefined,
            undefined,
            undefined,
            resourcesAbortController.signal
        );
        let data;
        if (integrationsReq?.ok) {
           data = await integrationsReq?.json();
        }
        return data;
    } catch (error) {
        if (error.name !== 'AbortError') {
            appToast(
                "error",
                "Error occurred",
                error?.message
            )
        }
    }
}

export {
    fetchIntegrationDiscoveryStatus,
    fetchIntegrationDiscoveryResources
}