import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import AppSwitch from "../../../../shared/appSwitch/appSwitch";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import RelationshipChart from "./relationshipChart";
import RelationshipTable from "./relationshipTable";
import "./assetRelationship.scss";

const AssetRelationship = ({ asset }) => {
  const { t } = useTranslation("inventory", { keyPrefix: "asset-popup" });
  const [mode, setMode] = useState("chart");

  return (
    <div className="AssetRelationship col">
      <div className="AssetRelationship__header row between">
        <span className="AssetRelationship__header-title bold">
          {t(`relationships.title.${mode}`)}
        </span>
        <AppSwitch
          items={[
            {
              text: <div className="row g5"><FontAwesomeIcon icon="project-diagram" />{t("relationships.toggle.chart")}</div>,
              value: "chart",
            },
            {
              text: <div className="row g5"><FontAwesomeIcon icon="list" />{t("relationships.toggle.table")}</div>,
              value: "table",
            },
          ]}
          active={mode}
          onChange={setMode}
        />
      </div>
      <div className="AssetRelationship__body">
        {mode === "table" ? <RelationshipTable asset={asset} /> : <RelationshipChart  asset={asset} />}
      </div>
    </div>
  );
};

export default AssetRelationship;
