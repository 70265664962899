import React from "react";
import { TreeSelect } from "antd";
import _ from "lodash";
import { providerNameFormatter } from "../../../utils/formatting";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";

const ProviderSelect = ({ selected, onChange, loading, providers, readOnly,maxTagCount,showCheckedStrategy = "SHOW_PARENT",getPopupContainer }) => {

  const formattedTreeData = () => {
    if (loading || _.isEmpty(providers)) {
      return [];
    }

    const transformedClouds = [];
    for (const cloudName in providers) {
      const cloudData = providers[cloudName]; 
      if (cloudData.length > 0) {
        const cloud = {
          title: (
            <span className="row g10">
              <div style={{ width: '26px' }} className="center"><ProviderIcon provider={cloudName} customStyle="inventory" /></div>
              {providerNameFormatter(cloudName)}
            </span>
          ),
          value: `${cloudName}_all`,
          key: `${cloudName}_all`,
          children: [],
        };
        for (const data of cloudData) {
          const child = {
            title: (
                <span className="row g10">
                  <div style={{ width: '26px' }} className="center"><ProviderIcon provider={cloudName} customStyle="inventory" /></div>
                  {data?.name}
                </span>
              ),
            value: data?.id,
            key: data?.id,
          };
          cloud.children.push(child);
        }
        transformedClouds.push(cloud);
      }
    }
    return transformedClouds;
  };

  return (
    <TreeSelect
      getPopupContainer={getPopupContainer}
      maxTagCount={maxTagCount}
      treeData={formattedTreeData()}
      value={loading? [] : selected}
      onChange={onChange}
      treeCheckable
      showCheckedStrategy={showCheckedStrategy}
      placeholder="Select data sources"
      showArrow
      style={{
        width: "100%",
      }}
      multiple
      filterTreeNode={(search, item) => {
        if (!_.isString(item?.title)) {
          return (
            item?.title?.props?.children[1]
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
          );
        }
        return item?.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      }}
      loading={loading}
      disabled={readOnly}
    />
  );
};

export default ProviderSelect;
