export const ERR_RESOURCE_TF_STACK_NOT_FOUND = 1 // 404
export const ERR_RESOURCE_NOT_MANAGED_BY_TF = 2  // 400
export const ERR_TF_VERSION_NOT_SUPPORTED = 3  // 400
export const ERR_VCS_INTEGRATION_NOT_FOUND = 4 // 404
export const ERR_GITHUB_AUTHENTICATED_OWNER_NOT_FOUND = 5 // 400
export const ERR_RESOURCE_NOT_FOUND = 6 // 404
export const ERR_GITHUB_SEARCH_TIMEOUT = 7 // 408
export const ERR_DRIFT_PROPERTIES_NOT_FOUND_IN_IAC = 8 // 404
export const ERR_FOUND_SOME_DRIFTS = 9
export const ERR_VCS_REPO_NOT_FOUND = 10 // 404
export const ERR_UPDATED_SOME_FILES = 11
export const ERR_FILES_NOT_FOUND_IN_REPO = 12 // 404
export const ERR_INTERNAL_SERVER_ERROR = 13 // 500
export const ERR_VCS_API_ERROR = 14
export const ERR_GITHUB_SEARCH_NOT_FOUND = 15 // 404
export const ERR_ES_SEARCH_RESULT_EMPTY = 16 // 404
export const ERR_RESOURCE_CFT_STACK_NOT_FOUND = 17 // 404
export const ERR_RESOURCE_IS_NOT_DRIFTED = 18 // 404
export const ERR_NO_CHANGE = 19
export const ERR_FOUND_SOME_DRIFTS_NO_CHANGE = 20