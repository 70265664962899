import React, { useEffect, useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import AppBtn from "../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faKey } from "@fortawesome/free-solid-svg-icons";
import {
  getAllKeyPairs,
  deleteKeyPair,
  createKeyPair,
  updateKeyPair,
} from "../../../redux/actions/keyPairsActions";

import "./accessKeys.scss";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import KeyPairsTable from "./keyPairsTable";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import CreateAccessKey from "./createAccessKey";
import EditKeyPair from "./editKeyPair";
import { Trans, useTranslation } from "react-i18next";

const AccessKeys = () => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const { t } = useTranslation('users')
  const keyPairs = useSelector((state) => state.keyPairsReducer.keyPairs);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const [loading, setLoading] = useState(false);
  const [confirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [confirmEditOpen, setConfirmEditOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [editInProgress, setEditInProgress] = useState(false);
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [keyPair, setKeyPair] = useState(null);
  const [selectedRowsKeys, setSelectedRowsKeys] = useState([]);
  const [accessKeyToEdit, setAccessKeyToEdit] = useState(null);

  useEffect(() => {
    getKeyPairs();
  }, []);

  const getKeyPairs = async () => {
    setLoading(true);
    await dispatch(getAllKeyPairs());
    setLoading(false);
  };

  const handleConfirmDelete = () => {
    return setConfirmDeleteOpen(true);
  };

  const handleOpenEditModal = () => {
    return setConfirmEditOpen(true);
  };

  const handleCloseConfiramtion = () => {
    setConfirmDeleteOpen(false);
    setDeleteInProgress(false);
  };

  const handleCloseEditModal = () => {
    setAccessKeyToEdit(null);
    setSelectedRowsKeys(null);
    setEditInProgress(false);
    setConfirmEditOpen(false);
  };

  const handleDeleteKeyPair = async () => {
    setDeleteInProgress(true);
    await Promise.all(
      _.map(
        selectedRowsKeys,
        async (accessKey) => await dispatch(deleteKeyPair(accessKey))
      )
    );
    setDeleteInProgress(false);
    setConfirmDeleteOpen(false);
    setSelectedRowsKeys([]);
    await getKeyPairs();
  };

  const handleEditKeyPair = async (values) => {
    const { description } = values;
    setEditInProgress(true);
    await dispatch(updateKeyPair(accessKeyToEdit?.access_key, description));
    setEditInProgress(false);
    setConfirmEditOpen(false);
    await getKeyPairs();
  };

  const handleCreateKeyPair = async () => {
    setLoading(true);
    const keyPair = await dispatch(createKeyPair(user?.name));
    if (_.isEmpty(keyPair)) {
      return setLoading(false);
    }
    setKeyPair(keyPair);
    setLoading(false);
    setCreateModalOpen(true);
    await getKeyPairs();
  };

  const handleCloseCreateKeyPair = async () => {
    setCreateModalOpen(false);
    setKeyPair(null);
  };

  return (
    <div className="AccessKeys col">
      <div className="AccessKeys__header col">
        <span className="title">{t('access-keys.title')}</span>
        <div className="col">
          <span className="sub-title">
            {t('access-keys.sub-title1')}
          </span>
          <span className="sub-title">
            <Trans i18nKey="users:access-keys.sub-title2">
              Access keys allow programmatically accessing the Firefly API, and is required by on-premises Firefly components such as the <span className="purple-text">k8s-collector</span> and <span className="purple-text">Firefly Rest API</span>.
            </Trans>
          </span>
        </div>
      </div>
      <div className="basic-card AccessKeys__body col">
        <div className="AccessKeys__body-header row between text">
          <span>{t('access-keys.keys', { keysCount: _.isEmpty(keyPairs) ? 0 : keyPairs.length })} </span>
          <div className="AccessKeys__body-btns row">
            <AppBtn
              text={t('access-keys.create-key-btn')}
              onClick={handleCreateKeyPair}
              icon={<FontAwesomeIcon icon={faKey} />}
              disabled={isViewer}
            />
            <AppBtn
              icon={<FontAwesomeIcon icon="trash-alt" />}
              disabled={isViewer || selectedRowsKeys?.length < 1}
              onClick={handleConfirmDelete}
              tooltipText={t('access-keys.delete-key-tooltip')}
            />
          </div>
        </div>
        <KeyPairsTable
          loading={loading}
          data={keyPairs}
          setSelectedRowsKeys={setSelectedRowsKeys}
          handleOpenEditModal={handleOpenEditModal}
          setAccessKeyToEdit={(data) => {
            setAccessKeyToEdit(data);
            setConfirmEditOpen(true);
          }
          }
        />
      </div>
      <ConfirmationModal
        visible={confirmDeleteOpen}
        handleClose={handleCloseConfiramtion}
        title={
          !_.isEmpty(selectedRowsKeys)
            ? t('access-keys.delete-modal.title', { count: selectedRowsKeys.length ?? 0 })
            : null
        }
        description={t('access-keys.delete-modal.description')}
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteKeyPair}
      />
      <EditKeyPair
        visible={confirmEditOpen}
        handleClose={handleCloseEditModal}
        loadingSubmit={editInProgress}
        handleOnSubmit={handleEditKeyPair}
        accessKeyToEdit={accessKeyToEdit}
        handleEditKeyPair={handleEditKeyPair}
      />
      <CreateAccessKey
        visible={createModalOpen}
        handleClose={handleCloseCreateKeyPair}
        keyPair={keyPair}
      />
    </div>
  );
};

export default AccessKeys;
