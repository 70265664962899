export default {
  "us-east-1": { name: "US East 1 (N. Virginia)", state: "United States of America" },
  "us-east-2": { name: "US East 2 (Ohio)", state: "United States of America" },
  "us-west-1": { name: "US West 1 (N. California)", state: "United States of America" },
  "us-west-2": { name: "US West 2 (Oregon)", state: "United States of America" },
  "af-south-1": { name: "Africa (Cape Town)", state: "South Africa" },
  "ap-east-1": { name: "Asia Pacific (Hong Kong)", state: "China" },
  "ap-south-1": { name: "Asia Pacific (Mumbai)", state: "India" },
  "ap-southeast-1": { name: "Asia Pacific 1 (Singapore)", state: "Malaysia" },
  "ap-southeast-2": { name: "Asia Pacific 2 (Sydney)", state: "Australia" },
  "ap-northeast-1": { name: "Asia Pacific 1 (Tokyo)", state: "Japan" },
  "ap-northeast-2": { name: "Asia Pacific 2 (Seoul)", state: "South Korea" },
  "ap-northeast-3": { name: "Asia Pacific 3 (Osaka-Local)", state: "Japan" },
  "ca-central-1": { name: "Canada (Central)", state: "Canada" },
  "cn-north-1": { name: "China (Beijing)", state: "China" },
  "cn-northwest-1": { name: "China (Ningxia)", state: "China" },
  "eu-central-1": { name: "Europe (Frankfurt)", state: "Germany" },
  "eu-west-1": { name: "Europe 1 (Ireland)", state: "Ireland" },
  "eu-west-2": { name: "Europe 2 (London)", state: "United Kingdom" },
  "eu-west-3": { name: "Europe 3 (Paris)", state: "France" },
  "eu-south-1": { name: "Europe (Milan)", state: "Italy" },
  "eu-north-1": { name: "Europe (Stockholm)", state: "Sweden" },
  "me-south-1": { name: "Middle East (Bahrain)", state: "United Arab Emirates" },
  "sa-east-1": { name: "South America (São Paulo)", state: "Brazil" },
};
