import React from "react";
import Calendar from "react-calendar";
import _ from "lodash";
import moment from "moment";
import "react-calendar/dist/Calendar.css";
import "./smallCalendar.scss";
// documentaion for this calendar
// https://github.com/wojtekmaj/react-calendar

const SmallCalendar = ({
  value,
  onChange,
  maxDate,
  minDate,
  activeDates,
  showNeighboringMonth,
}) => {

  return (
    <Calendar
      onChange={onChange} // (value, event) =>
      value={moment(value).toDate()}
      className="SmallCalendar"
      calendarType="ISO 8601"
      maxDate={moment(maxDate).toDate()}
      minDate={moment(minDate).toDate()}
      tileDisabled={({ activeStartDate, date, view }) => {
        if (!_.isEmpty(activeDates)) {
          if (view === "month") {
            const formattedActiveDates = _.map(activeDates || [], (item) =>
              moment(item).format("MM/DD/YYYY")
            );
            return !formattedActiveDates.includes(
              moment(date).format("MM/DD/YYYY")
            );
          }
          // if(view === 'year') {}
          // if(view === 'decade') {}
          // if(view === 'century') {}
        }
      }}
      showNeighboringMonth={showNeighboringMonth}
    />
  );
};

export default SmallCalendar;
