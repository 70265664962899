import React from "react";
import { Avatar, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import moment from "moment";
import _ from "lodash";
import {
  renderInitalFromName,
  initalColorsByFirstLetter,
} from "../../utils/helpers";
import "./appComment.scss";
import { dateTimeSecondsFormat } from "../../utils/formatting";

const AppComment = ({
  handleEdit,
  handleDelete,
  isUserAuthor,
  created,
  updated,
  name,
  comment,
}) => {
  const isNotEdited = moment(updated).isSame(created, "seconds");

  const authorActions = (
    <div className="AppComment__actions row">
      <Tooltip title="Edit">
        <span
          key="comment-edit"
          onClick={handleEdit}
          className="row AppComment__actions-item"
        >
          <FontAwesomeIcon icon="pen" />
        </span>
      </Tooltip>
      <Tooltip title="Delete">
        <span
          key="comment-delete"
          onClick={handleDelete}
          className="row AppComment__actions-item"
        >
          <FontAwesomeIcon icon="trash" />
        </span>
      </Tooltip>
    </div>
  );
  //  ** OPTIONAL FAETURE ADD LIKE TO COMMENT **
  // const actions = (
  //   <div className="AppComment__actions row">
  //     <Tooltip title="Like">
  //       <span
  //         onClick={handleClickLike}
  //         key="comment-like"
  //         className="row AppComment__actions-item like"
  //       >
  //         <span className="comment-action">{likes ? `${likes}` : null}</span>
  //         <FontAwesomeIcon
  //           icon={likes ? "heart" : ["far", "heart"]}
  //           className={`AppComment__actions-item like ${likes ? "liked" : ""}`}
  //         />
  //       </span>
  //     </Tooltip>
  //   </div>
  // );

  // const likeLoader = (
  //   <div className="AppComment__actions row">
  //     <FontAwesomeIcon icon="spinner" spin />
  //   </div>
  // );

  return (
    <div className="AppComment row between">
      <div className="AppComment__content">
        <Avatar
          alt={name}
          className="AppComment__content-avatar"
          style={{ backgroundImage: initalColorsByFirstLetter(name) }}
        >
          {renderInitalFromName(name)}
        </Avatar>
        <div className="AppComment__content-text">
          <div className="AppComment__content-text-header row between">
            <div className="AppComment__content-text-header-text">
              <div className="AppComment__content-text-name bold">{_.capitalize(name)}</div>
              <div className="AppComment__content-text-date">
                <Tooltip title={moment(created).format(dateTimeSecondsFormat)}>
                  <span>{moment(created).fromNow()}</span>
                </Tooltip>
              </div>
            </div>
            {isUserAuthor && authorActions}
          </div>
          <div className="AppComment__content-text-comment">
            {`${comment}`}
            {!isNotEdited && (
              <span className="AppComment__content-text-comment-edited">
                (edited)
              </span>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AppComment;
