import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Checkbox, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getGcpIntegrations,
  getAllGcsBuckets,
  getAllGcsObjects,
} from "../../../redux/actions/gcpIntegrationActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import FormLabels from "../../../shared/formLabels/formLabels";

const GcsBucket = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  setTags,
  allTags,
  currentSelectedTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const gcpIntegrations = useSelector(
    (state) => state.gcpIntegrationReducer.integrations
  );

  const [buckets, setBuckets] = useState([]);
  const [stateFiles, setStateFiles] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedBucket, setSelectedBucket] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingBuckets, setLoadinBuckets] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [autoDiscover, setAutoDiscover] = useState(true);

  // fetch GCP Integrations when starting
  useEffect(() => {
    fetchIntegrations();
  }, []);

  // fetch all GCS Buckets on Integration change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration)) {
      fetchGcsBuckets();
    }
  }, [selectedIntegration]);

  // fetch all S3 Objects on Integration or Bucket change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration) && !_.isEmpty(selectedBucket)) {
      fetchGcsObjects();
    }
  }, [selectedIntegration, selectedBucket]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getGcpIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchGcsBuckets = async () => {
    setLoadinBuckets(true);
    const res = await dispatch(getAllGcsBuckets(selectedIntegration));
    if (_.isEmpty(res.buckets)) {
      return setLoadinBuckets(false);
    }
    setBuckets(res.buckets);
    setLoadinBuckets(false);
  };

  const fetchGcsObjects = async () => {
    setLoadingStates(true);
    const res = await dispatch(
      getAllGcsObjects(selectedIntegration, selectedBucket)
    );
    if (_.isEmpty(res.objects)) {
      return setLoadingStates(false);
    }
    const tfStateFiles = _.filter(res.objects, (obj) =>
      obj.endsWith(".tfstate")
    );
    setStateFiles(tfStateFiles);
    setLoadingStates(false);
  };

  const renderGcpIntegrationsOptions = () => {
    if (!_.isEmpty(gcpIntegrations)) {
      return _.map(gcpIntegrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderGcsBucketsOptions = () => {
    if (!_.isEmpty(buckets)) {
      return _.map(buckets || [], (bucket) => {
        return (
          <Select.Option value={bucket} key={bucket}>
            {bucket}
          </Select.Option>
        );
      });
    }
  };

  const renderGcsFolderOptions = () => {
    if (!_.isEmpty(stateFiles)) {
      const folderArr = _.uniq(
        _.map(stateFiles, (item) => {
          const folder = item?.split("/");
          return `${folder[0]}/`;
        })
      );

      const output = _.map(folderArr || [], (folder) => {
        return (
          <Select.Option value={folder} key={folder}>
            {folder}
          </Select.Option>
        );
      });

      output.unshift(
        <Select.Option value="Entire Bucket" key="Entire Bucket">
          Entire Bucket
        </Select.Option>
      );
      return output;
    }
  };

  const renderGcsTerraformStatesOptions = () => {
    if (!_.isEmpty(stateFiles)) {
      return _.map(stateFiles || [], (state) => {
        return (
          <Select.Option value={state} key={state}>
            {state}
          </Select.Option>
        );
      });
    }
  };

  const setIsFormValidFlag = (formValues) => {
    handleSetIsFormValid(
      !_.isEmpty(formValues.gcpIntegration) &&
        !_.isEmpty(formValues.gcsBucket) &&
        ((!_.isEmpty(formValues?.terraformFolder) &&
          formValues.discovery === "auto") ||
          (!_.isEmpty(formValues?.terraformStates) &&
            formValues.discovery === "manual"))
    );
  };

  return (
    <Form
      {...layout}
      name="gcs-settings-form"
      form={form}
      initialValues={{
        discovery: "auto",
      }}
      className="StateConfiguration"
      onValuesChange={(changedValues, allValues) => {
        // if the change was the value of selected all, set the selected states before moving forward
        if (!_.isUndefined(changedValues.selectAll)) {
          let stateList = changedValues.selectAll ? stateFiles : [];
          allValues.terraformStates = stateList;
          form.setFieldsValue({ terraformStates: stateList });
        }

        if (changedValues.discovery === "manual") {
          form.setFieldsValue({ terraformStates: [] });
        }
        if (changedValues.discovery === "auto") {
          form.setFieldsValue({ terraformFolder: [] });
        }

        changeSelectedStateSettings(allValues);
        setIsFormValidFlag(allValues);
      }}
    >
      <Form.Item
        label="Google Cloud Integration"
        name="gcpIntegration"
        help={
          !loadingIntegrations && _.isEmpty(gcpIntegrations) ? (
            <Link to="/integrations/gcs-integration">
              + Add Google Cloud Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Google Cloud Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select Google Cloud Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderGcpIntegrationsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="Gcs Bucket"
        name="gcsBucket"
        rules={[
          {
            required: true,
            message: "selecting a Gcs Bucket is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select Gcs Bucket"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(bucket) => setSelectedBucket(bucket)}
          loading={loadingBuckets}
        >
          {renderGcsBucketsOptions()}
        </Select>
      </Form.Item>

      <Form.Item
        label="Terraform states"
        name="discovery"
        rules={[
          {
            required: true,
            message: "selecting a terraform discovery is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select terraform discovery"
          onSelect={(discovery) => setAutoDiscover(discovery === "auto")}
        >
          <Select.Option value="auto" key="auto">
            <FontAwesomeIcon
              icon={faSatelliteDish}
              style={{ marginRight: "5px" }}
            />{" "}
            Auto-Discover state files
          </Select.Option>
          <Select.Option value="manual" key="manual">
            Select specific state files
          </Select.Option>
        </Select>
      </Form.Item>

      {autoDiscover ? (
        <Form.Item
          label="Scope Selection"
          name="terraformFolder"
          rules={[
            {
              required: true,
              message: "selecting a folder is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Select Scope"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingStates}
          >
            {renderGcsFolderOptions()}
          </Select>
        </Form.Item>
      ) : (
        <div>
          <Form.Item
            label="Terraform states (.tfstate file)"
            name="terraformStates"
            rules={[
              {
                required: true,
                message: "selecting a Terraform state is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Select
              placeholder="Please select Terraform states (.tfstate file)"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="tags"
              onSelect={(state) => {
                let tmpSelectedStates = selectedStates;
                tmpSelectedStates.push(state);
                return setSelectedStates(tmpSelectedStates);
              }}
              onDeselect={(state) => {
                let tmpSelectedStates = selectedStates;
                _.remove(tmpSelectedStates, (item) => item === state);
                return setSelectedStates(tmpSelectedStates);
              }}
              loading={loadingStates}
            >
              {renderGcsTerraformStatesOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="selectAll" valuePropName="checked">
            <Checkbox checked={false} disabled={_.isEmpty(stateFiles)}>
              <span className="selectAll__checbox">Select All</span>
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};

export default GcsBucket;
