import React from "react";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";
import {
  formatAwsStringWithUnderscore,
  providerAssetImagesFormatter,
} from "../../../utils/formatting";

const AssetTypeCell = ({ row, showAssetTypeIcon }) => {
  return (
    <div className="AssetTypeCell row g10">
      {showAssetTypeIcon && (
        <AssetTypeImage
          assetType={row?.assetType}
          provider={providerAssetImagesFormatter(row?.provider)}
          customStyle={{ height: "20px"}}
          loaderSize={18}
        />
      )}
      <span>{formatAwsStringWithUnderscore(row?.assetType, true)}</span>
    </div>
  );
};

export default AssetTypeCell;
