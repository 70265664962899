import { TreeSelect } from "antd";
import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { getAssetEvents } from "../../../../redux/actions/assetEventsActions";
import HeaderSearchBox from "../../../../shared/headerSearchBox/headerSearchBox";
import EventsTable from "./eventsTable";
import EventsGraph from "./eventsGraph";

import "./eventsViewer.scss";

const { TreeNode } = TreeSelect;

const EventsViewer = ({ asset = {} }) => {
  const [initialLoading, setInitialLoading] = useState(false);
  const [querySearch, setQuerySearch] = useState("");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sorter, setSorter] = useState([]);
  const [namesFilter, setNamesFilter] = useState([]);
  const [firstCall, setFirstCall] = useState(true)

  const dispatch = useDispatch();
  const eventNamesFilterOptions = useSelector(
    (state) => state.assetEventsReducer.eventNamesFilterOptions
  );
  const eventTimesFilterOptions = useSelector(
    (state) => state.assetEventsReducer.eventTimesFilterOptions
  );

  useEffect(() => {
      handleFetchEvents(true);
      setFirstCall(false)
  }, [asset]);

  useEffect(() => {
    if (!firstCall)
    handleFetchEvents();
  }, [querySearch, pageNumber, pageSize, sorter, namesFilter]);

  const renderNames = () => {
    const mappedArr = eventNamesFilterOptions.map((eventName) => {
      const title = `${eventName.key} (${eventName.doc_count})`;
      return (
        <TreeNode key={eventName.key} value={eventName.key} title={title} />
      );
    });
    return mappedArr;
  };
  const handleSelectChange = (newFilters) => setNamesFilter(newFilters);

  const handleFetchEvents = async (shouldFetchFilterOptions = false) => {
    setInitialLoading(true);
    await dispatch(
      getAssetEvents(
        asset.integrationId,
        asset.region,
        asset.arn,
        asset.resourceId,
        asset.name,
        asset.assetType,
        asset.service,
        pageNumber,
        pageSize,
        querySearch,
        sorter,
        namesFilter,
        shouldFetchFilterOptions,
        asset.provider
      )
    );
    setInitialLoading(false);
  };
  
  const renderGraph = eventTimesFilterOptions?.length && !firstCall
  
  return (
    <div className="EventViewerContainer col">
      { renderGraph ? <EventsGraph assetData={asset} eventTimesFilterOptions={eventTimesFilterOptions} /> : null}
      <div className="EventTableContainer col">
        <div className="EventTableContainer__tools row">
          <TreeSelect
            className="EventTableSelect"
            placeholder="Event Name"
            showSearch
            treeCheckable
            showArrow
            onChange={handleSelectChange}
            allowClear
            loading={initialLoading}
          >
            {renderNames()}
          </TreeSelect>
          <HeaderSearchBox
            placeholder="Search..."
            value={(val) => setQuerySearch(val)}
            currentValue={querySearch}
            resetSearch={(val) => setQuerySearch(val)}
          />
        </div>
        <EventsTable
          assetData={asset}
          loading={initialLoading}
          pageSize={pageSize}
          setPageNumber={setPageNumber}
          setPageSize={setPageSize}
          setSorter={setSorter}
        />
      </div>
    </div>
 
  );
};

export default EventsViewer;
