import React from "react";
import { useSelector } from 'react-redux';
import _ from "lodash";
import { Tooltip } from "antd";
import { getIconMutedColor } from '../../utils/helpers';
import "./historyLabelIndicator.scss";

const HistoryLabelIndicator = ({ revisions, openHistoryTab, disable }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  return (
    <Tooltip
      placement="top"
      title={revisions && !disable ? `${revisions} history revisions` : 'Asset history'}
      style={{ cursor: "pointer" }}
      overlayInnerStyle={{
        fontSize: "13px",
      }}
    >
      <button
        className="HistoryLabelIndicator center"
        onClick={
          !revisions || disable
            ? () => {
                return false;
              }
            : openHistoryTab
        }
        style={{
          cursor: !revisions ? "default" : "pointer",
        }}
      >
        <svg
          width="12"
          height="12"
          viewBox="0 0 12 12"
          fill="none"
        >
          <g clipPath="url(#clip0_6994_6512)">
            <path
              d="M1.25 8.25C1.1837 8.25 1.12011 8.22366 1.07322 8.17678C1.02634 8.12989 1 8.0663 1 8V3.75C1 3.6837 1.02634 3.62011 1.07322 3.57322C1.12011 3.52634 1.1837 3.5 1.25 3.5H8C8.0663 3.5 8.12989 3.52634 8.17678 3.57322C8.22366 3.62011 8.25 3.6837 8.25 3.75V4.785C8.5817 4.73835 8.9183 4.73835 9.25 4.785V1.5C9.25 1.36739 9.19732 1.24021 9.10355 1.14645C9.00979 1.05268 8.88261 1 8.75 1H7.5C7.46685 1 7.43505 0.98683 7.41161 0.963388C7.38817 0.939946 7.375 0.908152 7.375 0.875V0.375C7.375 0.275544 7.33549 0.180161 7.26516 0.109835C7.19484 0.0395088 7.09946 0 7 0C6.90054 0 6.80516 0.0395088 6.73484 0.109835C6.66451 0.180161 6.625 0.275544 6.625 0.375V2.25C6.625 2.34946 6.58549 2.44484 6.51516 2.51517C6.44484 2.58549 6.34946 2.625 6.25 2.625C6.15054 2.625 6.05516 2.58549 5.98484 2.51517C5.91451 2.44484 5.875 2.34946 5.875 2.25V1.25C5.875 1.1837 5.84866 1.12011 5.80178 1.07322C5.75489 1.02634 5.6913 1 5.625 1H3.5C3.46685 1 3.43505 0.98683 3.41161 0.963388C3.38817 0.939946 3.375 0.908152 3.375 0.875V0.375C3.375 0.275544 3.33549 0.180161 3.26517 0.109835C3.19484 0.0395088 3.09946 0 3 0C2.90054 0 2.80516 0.0395088 2.73483 0.109835C2.66451 0.180161 2.625 0.275544 2.625 0.375V2.25C2.625 2.34946 2.58549 2.44484 2.51517 2.51517C2.44484 2.58549 2.34946 2.625 2.25 2.625C2.15054 2.625 2.05516 2.58549 1.98483 2.51517C1.91451 2.44484 1.875 2.34946 1.875 2.25V1.25C1.875 1.1837 1.84866 1.12011 1.80178 1.07322C1.75489 1.02634 1.6913 1 1.625 1H0.5C0.367392 1 0.240215 1.05268 0.146447 1.14645C0.0526784 1.24021 0 1.36739 0 1.5L0 8.25C0 8.51522 0.105357 8.76957 0.292893 8.95711C0.48043 9.14464 0.734784 9.25 1 9.25H4.785C4.73835 8.9183 4.73835 8.5817 4.785 8.25H1.25Z"
              fill={!revisions || disable ? getIconMutedColor(themeDark): "#F1B54C"}
            />
            <path
              d="M8.75 5.5C8.10721 5.5 7.47886 5.69061 6.9444 6.04772C6.40994 6.40484 5.99338 6.91242 5.74739 7.50628C5.50141 8.10014 5.43705 8.75361 5.56245 9.38404C5.68785 10.0145 5.99738 10.5936 6.4519 11.0481C6.90642 11.5026 7.48552 11.8122 8.11596 11.9376C8.7464 12.063 9.39986 11.9986 9.99372 11.7526C10.5876 11.5066 11.0952 11.0901 11.4523 10.5556C11.8094 10.0211 12 9.39279 12 8.75C11.9987 7.88845 11.6558 7.06257 11.0466 6.45336C10.4374 5.84416 9.61155 5.50132 8.75 5.5ZM8.75 11C8.30499 11 7.86998 10.868 7.49997 10.6208C7.12996 10.3736 6.84157 10.0222 6.67127 9.61104C6.50098 9.1999 6.45642 8.7475 6.54323 8.31105C6.63005 7.87459 6.84434 7.47368 7.15901 7.15901C7.47368 6.84434 7.87459 6.63005 8.31105 6.54323C8.74751 6.45642 9.19991 6.50097 9.61104 6.67127C10.0222 6.84157 10.3736 7.12996 10.6208 7.49997C10.868 7.86998 11 8.30499 11 8.75C10.9987 9.34633 10.7612 9.91786 10.3395 10.3395C9.91786 10.7612 9.34633 10.9987 8.75 11Z"
              fill={!revisions || disable ? getIconMutedColor(themeDark): "#F1B54C"}
            />
            <path
              d="M9.75 8.375H9.125V7.5C9.125 7.40054 9.08549 7.30516 9.01517 7.23484C8.94484 7.16451 8.84946 7.125 8.75 7.125C8.65054 7.125 8.55516 7.16451 8.48483 7.23484C8.41451 7.30516 8.375 7.40054 8.375 7.5V8.75C8.37629 8.84905 8.41622 8.94369 8.48627 9.01373C8.55631 9.08378 8.65095 9.12371 8.75 9.125H9.75C9.84946 9.125 9.94484 9.08549 10.0152 9.01517C10.0855 8.94484 10.125 8.84946 10.125 8.75C10.125 8.65054 10.0855 8.55516 10.0152 8.48483C9.94484 8.41451 9.84946 8.375 9.75 8.375Z"
              fill={!revisions || disable ? getIconMutedColor(themeDark): "#F1B54C"}
            />
          </g>
          <defs>
            <clipPath id="clip0_6994_6512">
              <rect width="12" height="12" fill="white" />
            </clipPath>
          </defs>
        </svg>
      </button>
    </Tooltip>
  );
};

export default HistoryLabelIndicator;
