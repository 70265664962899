import React, { useState, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useTranslation } from "react-i18next";
import { handleToggleProvisioning } from "../../redux/actions/globalAppActions";
import { setPullRequestParameters } from "../../redux/actions/iacImportActions";
import ActionBtn from "../../shared/actionBtn/actionBtn";
import AppDrawer from "../../shared/appDrawer/appDrawer";
import AppBtn from "../../shared/appBtn/appBtn";
import ComposeHeader from "../../components/composing/composeHeader/composeHeader";
import ProvisionContent from "../../components/composing/provisionContent/provisionContent";
import ModuleCall from "../../components/composing/moduleCall/moduleCall";
import PullRequestForm from "../../components/inventory/pullRequestForm/pullRequestForm";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";

import "./compose.scss";

const COMPOSE_TABS_KEYS = {
  generateAI: "generateAI",
  moduleCall: "moduleCall",
}
const COMPOSE_DRAWER_WIDTH = '90%'

const Compose = () => {
  const { t } = useTranslation(["compose", "common"]);
  const provionOpen = useSelector((state) => state.globalAppReducer.provionOpen);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const dispatch = useDispatch();
  //provision
  const [provisionData, setProvisionData] = useState([]);
  const [selectedFile, setSelectedFile] = useState(null);

  const [generetedModuleCall, setGeneratedModuleCall] = useState(null);
  
  const [activeTab, setActiveTab] = useState(COMPOSE_TABS_KEYS.moduleCall);
  const [pullRequestOpen, setPullRequestOpen] = useState(false);

  const mapData = {
    [COMPOSE_TABS_KEYS.generateAI]: selectedFile?.content,
    [COMPOSE_TABS_KEYS.moduleCall]: generetedModuleCall?.content,
  };
  
  const isModuleCallTabActive = activeTab === COMPOSE_TABS_KEYS.moduleCall;

  const handleGetFileType = useCallback(() => {
    if (isModuleCallTabActive) {
      return "tf";
    }
    if (!_.isEmpty(selectedFile?.filePath)) {
      let output = selectedFile?.filePath?.split(".");
      return output[output?.length - 1];
    }
  }, [selectedFile, generetedModuleCall]);


  const handleGetFileName = useCallback(() => {
    if (isModuleCallTabActive) {
      return "main";
    }
    if (!_.isEmpty(selectedFile?.filePath)) {
      let outputFile = selectedFile?.filePath?.split("/");
      let outputName = outputFile[outputFile?.length - 1];
      let nameNoDot = outputName?.split(".");
      return nameNoDot[0];
    }
  }, [selectedFile, generetedModuleCall]);

  return (
    <div className={`${provionOpen ? 'Compose': ''}`}>
        <AppDrawer
          headerClassName="Compose"
          bodyClassName="Compose"
          visible={provionOpen}
          closeDrawer={() => dispatch(handleToggleProvisioning())}
          width={COMPOSE_DRAWER_WIDTH}
          title={<ComposeHeader
                    title={t('compose:title')}
                    onChangeTab={setActiveTab}
                    activeTab={activeTab}
                    tabsKeys={COMPOSE_TABS_KEYS}/>}
          topBorder
          hideClose
          destroyOnClose
          footer={[
            <ActionBtn
              text={t('common:copy')}
              icon="copy"
              disabled={isModuleCallTabActive ? _.isEmpty(generetedModuleCall?.content) : _.isEmpty(selectedFile?.content)}
              action="copy"
              stringToAction={mapData[activeTab]}
              key="copy"
            />,
            <ActionBtn
              text={t('common:export')}
              disabled={isModuleCallTabActive ? _.isEmpty(generetedModuleCall?.content) : _.isEmpty(selectedFile?.filePath)}
              action="download"
              fileType={handleGetFileType()}
              fileName={handleGetFileName()}
              icon="donwload"
              stringToAction={mapData[activeTab]}
              key="download"
            />,
            <AppBtn
              text="Pull request"
              onClick={() => {
                dispatch(setPullRequestParameters({
                  preparedContent: isModuleCallTabActive ? [{ content: generetedModuleCall?.content }] : provisionData,
                  selectedModule: isModuleCallTabActive ? generetedModuleCall?.selectedModule : null,
                  codifyType: activeTab
                }))
                setPullRequestOpen(true);
              }}
              key="pull-request"
              icon={<FontAwesomeIcon icon={faGitAlt} />}
              disabled={isViewer || (isModuleCallTabActive ? _.isEmpty(generetedModuleCall?.content) : _.isEmpty(provisionData))}
            />,
          ]}
        >
        {activeTab === COMPOSE_TABS_KEYS.generateAI && <ProvisionContent provisionData={provisionData} setProvisionData={setProvisionData} setSelectedFile={setSelectedFile} selectedFile={selectedFile} />}
        {isModuleCallTabActive && <ModuleCall setGeneratedModuleCall={setGeneratedModuleCall} generetedModuleCall={generetedModuleCall?.content} />}
        </AppDrawer>
        <PullRequestForm
          close={() => setPullRequestOpen(false)}
          visible={pullRequestOpen}
          selectedResources={[]}
        />
    </div>
  );
};

export default Compose;
