import React from "react";
import CustomPropertiesTags from "../../../shared/customPropertiesTags/customPropertiesTags";
import FreeTierMask from "../freeTierMask/freeTierMask";
import _ from 'lodash';

const AssetPropertiesCell = ({ value, row, onClickTag, isFreeTier }) => {
  return (
    <div className="AssetPropertiesCell">
      {isFreeTier ? (
        <FreeTierMask>{value}</FreeTierMask>
      ) : (
        <div className="row" style={{ gap: "10px" }}>
          {!_.isEmpty(row?.resourceStatus) && (
            <div className="purple-flag center" style={{ textTransform: 'lowercase' }}>
              {row?.resourceStatus}
            </div>
          )}
          <CustomPropertiesTags
            data={row?.customColumns}
            onClick={onClickTag}
          />
        </div>
      )}
    </div>
  );
};

export default AssetPropertiesCell;
