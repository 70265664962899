import React from 'react';
import moment from 'moment';
import AppEmpty from '../../../../../shared/appEmpty/appEmpty';

const RevisionIdentical = ({ selectedRevision }) => {
    return (<div className="RevisionIdentical">
        <div className="RevisionIdentical__diff">
            <div className="RevisionIdentical__diff-title row">
                <div className="RevisionIdentical__diff-title-block center">
                    {` ${selectedRevision?.timeEpoch
                        ? moment(selectedRevision?.timeEpoch).format("MM.DD.YYYY hh:mm")
                        : ""
                        }`}
                </div>
                <div className="RevisionIdentical__diff-title-block center">
                    Current Configuration
                </div>
            </div>

        </div>
        <div className="RevisionIdentical__diff-state identical">
            <div>
                <div className="RevisionIdentical__diff-state-identical">
                    <AppEmpty customStyle="history" />
                </div>
                <div className="RevisionIdentical__diff-state-title">The IaC status and configuration are identical to the current ones.</div>
            </div>
        </div>

    </div>);
}

export default RevisionIdentical;