import React from "react";
import CommentLabelIndicator from "../../../shared/commentLabelIndicator/commentLabelIndicator";
import ExclusionsLabelIndicator from "../../../shared/exclusionsLabelIndicator/exclusionsLabelIndicator";
import GitopsLabelIndicator from "../../../shared/gitopsLabelIndicator/gitopsLabelIndicator";
import HistoryLabelIndicator from "../../../shared/historyLabelIndicator/historyLabelIndicator";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import InsightsLabelIndicator from "../../../shared/insightsLabelIndicator/insightsLabelIndicator";
import RelationshipLabelIndicator from "../../../shared/relationshipLabelIndicator/relationshipLabelIndicator";
import VcsLabelIndicator from "../../../shared/vcsLabelIndicator/vcsLabelIndicator";
import FreeTierMask from "../freeTierMask/freeTierMask";
import './inventoryTableCells.scss';

const AssetFlagsCell = ({
  value,
  row,
  isFreeTier,
  classifications,
  handleOpenInsights,
  handleOpenGit,
  handleOpenHistoryTab,
  handleOpenCommentsTab,
  excludedAssets,
  handleOpenConnectionTab,
  showRelationFlag
}) => {
  if (isFreeTier) {
    return <FreeTierMask>{value}</FreeTierMask>;
  }

  return (
    <div
      className="AssetFlagsCell row"
      style={{ gap: "10px", paddingRight: "10px" }}
    >
      <div className="row AssetFlagsCell__indications">
        <InsightsLabelIndicator
          insights={row?.classifications}
          allInsights={classifications}
          onClick={handleOpenInsights}
        />
        <VcsLabelIndicator
          resource={row}
          onClick={handleOpenGit}
        />
        <HistoryLabelIndicator
          revisions={row?.revisions}
          openHistoryTab={handleOpenHistoryTab}
          disable={row?.state === "deleted"}
        />
        <CommentLabelIndicator
          commentsCount={row?.commentCount}
          openCommentsTab={handleOpenCommentsTab}
        />
        <GitopsLabelIndicator 
        gitopsDetected={row?.isArgo}
        />
        {showRelationFlag && <RelationshipLabelIndicator
          relationshipDetected={row?.hasOwnerTargets}
          handleOpenConnectionTab={handleOpenConnectionTab}
        />}
      </div>
      <ExclusionsLabelIndicator
        exclusions={row?.exclusions}
        allExclusions={excludedAssets}
      />
      <IconBtn type="menu" colorClass="v-menu" />
    </div>
  );
};

export default React.memo(AssetFlagsCell);
