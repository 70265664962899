import React from "react";
import { Form, Input } from "antd";
import {
  accountNumberRegex,
  nicknameRegex,
  validate,
} from "../../../utils/validations";

const CodeCommitConfiguration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();

  const checkValidation = (formValues) => {
    let formValid = true;
    const { accountNumber, name: integrationName } = formValues;

    if (!accountNumber || !validate(accountNumberRegex.regex, accountNumber)) {
      formValid = false;
    }
    if (!integrationName || !validate(nicknameRegex.regex, integrationName)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="CodeCommitConfiguration col">
      <div className="CodeCommitConfiguration__intro col">
        <div className="dark-blue col">
          <span>
            Firefly’s AWS CodeCommit integration requires creating an{" "}
            <span className="code-flag">IAM Role</span> in your AWS account.
          </span>
          <span>
            The IAM role allows Firefly to perform read-only (Get, List, and
            Describe) access for the metadata of AWS services.
          </span>
          <span>Use the AWS CloudFormation template for deployment.</span>
        </div>
        <span className="dark-blue bold">Before you begin</span>
        <ul className="dark-blue CodeCommitConfiguration__intro-list">
          <li key="1">
            <span className="dark-blue">
              Log in to your desired AWS account with permission to create
              CloudFormation and IAM AWS resources
            </span>
          </li>
          <li key="2">
            <span className="dark-blue">
              Copy your AWS account ID (located at the top right corner of the
              AWS console) to the clipboard
            </span>
          </li>
          <li key="3">
            <span className="dark-blue">
              Paste the AWS account ID in the box
            </span>
          </li>
        </ul>
      </div>
      <div className="CodeCommitConfiguration__formWrapper">
        <Form
          name="codecommit-wizard-form"
          form={form}
          initialValues={{
            name: wizardState.name || "Code Commit Integration",
            accountnumber: wizardState.accountnumber || "",
          }}
          className="AppWizard__content-body-form"
          onValuesChange={(
            changedValues,
            allValues // this is how you can get the form values
          ) => {
            setWizardState(Object.assign(wizardState, allValues));
            checkValidation(allValues);
          }}
        >
          <Form.Item
            label="Nickname"
            name="name"
            rules={[
              {
                required: true,
                message: "Nickname is required",
              },
              {
                pattern: nicknameRegex.regex,
                message: nicknameRegex.msg,
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input placeholder="" />
          </Form.Item>
          <Form.Item
            label="AWS Account ID"
            name="accountNumber"
            rules={[
              {
                required: true,
                message: "AWS Account ID is required",
              },
              {
                message: accountNumberRegex.msg,
                max: 12,
              },
            ]}
            style={{ flexDirection: "column", width: "100%" }}
          >
            <Input placeholder="" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default CodeCommitConfiguration;
