import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import { BACKEND_URL } from '../../consts/config';

export const SEND_FEEDBACK = "SEND_FEEDBACK";

const sendFeedback = (userFeedback, feedback, feedbackType, authorized = true) => {
    return action(async dispatch => {
        const requestWrapper = new RequestWrapper();

        const url = `${BACKEND_URL}/feedback`;
        const req = await requestWrapper.sendRequest(url, "POST", { userFeedback, feedback, feedbackType }, authorized);
        dispatch({
            type: SEND_FEEDBACK,
            payload: {}
        });
        return req;
    });
}

export {
    sendFeedback
}
