import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_SCREEN_VIEWS = "GET_SCREEN_VIEWS";
export const GET_VIEW_BY_ID = "GET_VIEW_BY_ID";
export const CREATE_VIEW = "CREATE_VIEW";
export const DELETE_VIEW = "DELETE_VIEW";
export const TOGGLE_VIEW_MODAL = "TOGGLE_VIEW_MODAL";
export const TOGGLE_DEFAULT = "TOGGLE_DEFAULT";
export const SET_ACTIVE_VIEW = "SET_ACTIVE_VIEW";

const getUserScreenViews = (screen, user_id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/screen-views/${screen}/${user_id}`,
      "GET",
      {},
      undefined,
      undefined,
      false
    );

    const data = await req.json();

    dispatch({
      type: GET_SCREEN_VIEWS,
      payload: { data },
    });
    return data;
  });
};

const getViewById = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/screen-views/view/${id}`,
      "GET",
      {},
      undefined,
      undefined,
      false
    );

    const data = await req.json();

    dispatch({
      type: GET_VIEW_BY_ID,
      payload: { data },
    });

    return data;
  });
};

const createView = (payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/screen-views`,
      "POST",
      payload
    );

    dispatch({
      type: CREATE_VIEW,
      payload: {},
    });

    const result = await req.json();

    return result;
  });
};

const deleteView = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/screen-views/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_VIEW,
      payload: {},
    });
  });
};

const toggleDefault = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/screen-views/default/${id}`,
      "POST"
    );

    dispatch({
      type: TOGGLE_DEFAULT,
      payload: {},
    });
  });
};

const toggleViewModal = (isEditMode) => {
  return action(async (dispatch) => {
    dispatch({
      type: TOGGLE_VIEW_MODAL,
      payload: isEditMode,
    });
  });
};

const setActiveScreenView = (view) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_ACTIVE_VIEW,
      payload: view,
    });
  });
};

export {
  getUserScreenViews,
  getViewById,
  createView,
  deleteView,
  toggleDefault,
  toggleViewModal,
  setActiveScreenView,
};
