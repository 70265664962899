import React, { useEffect } from "react";
import { Collapse, Form, Input } from "antd";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './workflowsSteps.scss';
import EditableTagGroup from "../../../../shared/editableTagGroup/editableTagGroup";

const { Panel } = Collapse;
const AdvancedWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled }) => {
    const [form] = Form.useForm();
    
    useEffect(() => {
        setSubmitDisabled(false);
    }, []);

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onElementChange = (newVal = {}, isProvidersCredentials) => {
        const { awsProfiles = [], providersCredentials = [] } = wizardState;
        const arrToMap = isProvidersCredentials ? providersCredentials : awsProfiles;
        const updatedArray = arrToMap.map((elem) => { return elem.key === newVal.key ? newVal : elem });
        const updatedKey = isProvidersCredentials ? 'providersCredentials' : 'awsProfiles';
        onValuesChange({ [updatedKey]: updatedArray });
    }

    const handleDeleteElement = (key, isProvidersCredentials) => {
        const { awsProfiles = [], providersCredentials = [] } = wizardState;
        const arrayToFilter = isProvidersCredentials ? providersCredentials : awsProfiles;
        const updatedArray = arrayToFilter.filter((elem) => elem.key !== key);
        const updatedKey = isProvidersCredentials ? 'providersCredentials' : 'awsProfiles';
        onValuesChange({ [updatedKey]: updatedArray });
    }

    const onAddNewElements = (isMultiple, isProvidersCredentials) => {
        const newElements = [];
        newElements.push({ name: '', value: '', key: uuidv4() });
        if (isMultiple) {
            newElements.push({ name: '', value: '',  key: uuidv4()}, { name: '', value: '',  key: uuidv4()});
        }
        const { awsProfiles = [], providersCredentials = [] } = wizardState;
        const arrayKey = isProvidersCredentials ? 'providersCredentials' : 'awsProfiles';
        const oldArrayValue = isProvidersCredentials ? providersCredentials : awsProfiles;
        onValuesChange({ [arrayKey]: [...oldArrayValue, ...newElements] });
    };
    
    const handleChangeRules = (newRulesArray = []) => onValuesChange({ runnerLabels: newRulesArray });

    const awsProfileValidation = (awsProfile = {}) => {
        const { name, secretKey, accessKey } = awsProfile;
        const isValid = name ? secretKey && accessKey : true;
        return setSubmitDisabled(!isValid);
    }

    return (
        <div className="WorkflowsSteps col">
           <span className="bold">Advanced configuration</span>
           <span className="form-label">Choose a workspace</span>
           <div className="col g10">
           <Form
                name="advance-config-form"
                form={form}
                initialValues={{}}
          >
               <Form.Item
                name="runnerLabels"
                label="Self hosted runner labels (optional)"
                style={{ flexDirection: "column" }}
              >
               <EditableTagGroup onChange={handleChangeRules} defaultValues={wizardState.runnerLabels || []} rowDisplay/>
              </Form.Item>
                <Form.Item
                    label="AWS Profile (optional)"
                    name="awsProfiles"
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="col g10">
                        {(wizardState.awsProfiles || []).map((awsProfile = {}) => {
                            return (
                                <div className="row g10" key={awsProfile.key}>
                                    <Collapse
                                        className="WorkflowsSteps__collapse"
                                        expandIconPosition="right"
                                        defaultActiveKey="1">
                                        <Panel header={`Profile - ${awsProfile.name}`} key="1">
                                            <div className="WorkflowsSteps__collapse-content">
                                                <div className="col g5">
                                                    <span>Profile name</span>
                                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter profile name" value={awsProfile.name} onChange={(e) => {
                                                        const newAwsProfile = { ...awsProfile, name: e.target.value }; 
                                                        awsProfileValidation(newAwsProfile)
                                                        onElementChange(newAwsProfile)
                                                    }}/>
                                                </div>
                                                <div className="col g5">
                                                        <span>Access key (VCS)</span>
                                                    <Form.Item name="accessKey" {...(awsProfile.name && !awsProfile.accessKey) && {help: "Must to enter access key", validateStatus: "error"}} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter access key (VCS)" value={awsProfile.accessKey} onChange={(e) => {
                                                            const newAwsProfile = { ...awsProfile, accessKey: e.target.value };
                                                            awsProfileValidation(newAwsProfile)
                                                            onElementChange(newAwsProfile)
                                                        }}/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col g5">
                                                    <span>Secret key (VCS)</span>
                                                    <Form.Item name="secretKey" {...(awsProfile.name && !awsProfile.secretKey) && {help: "Must to enter secret key", validateStatus: "error"}} style={{marginBottom: '0px'}}>
                                                        <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter secret key (VCS)" value={awsProfile.secretKey} onChange={(e) => {
                                                            const newAwsProfile = { ...awsProfile, secretKey: e.target.value };
                                                            awsProfileValidation(newAwsProfile)
                                                            onElementChange(newAwsProfile)
                                                        }}/>
                                                    </Form.Item>
                                                </div>
                                                <div className="col g5">
                                                    <span>Region</span>
                                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter region" value={awsProfile.region} onChange={(e) => onElementChange({ ...awsProfile, region: e.target.value })}/>
                                                </div>
                                            </div>
                                        </Panel>
                                     </Collapse>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn profile" onClick={() => handleDeleteElement(awsProfile.key)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewElements()}>Add new profile</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label="Providers Credentials (optional)"
                    name="providersCredentials"
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Name</span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Secret value (VCS)</span>
                    </div>
                    <div className="col g10">
                        {(wizardState.providersCredentials || []).map((providerCredential = {}) => {
                            return (
                                <div className="row g10" key={providerCredential.key}>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret name" value={providerCredential.name} onChange={(e) => onElementChange({ ...providerCredential, name: e.target.value  }, true)}/>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret value (VCS)" value={providerCredential.value} onChange={(e) => onElementChange({ ...providerCredential, value: e.target.value }, true)}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteElement(providerCredential.key, true)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewElements(false, true)}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewElements(true, true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
         </Form>
        </div>
       </div>
    );
};

export default AdvancedWorkflowConfiguration;