import React from "react";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faQuestionCircle,
} from "@fortawesome/free-solid-svg-icons";
import { numberWithCommas, iacStatusIconByType } from "../../utils/formatting";

import "./codificationFilterBtnGroup.scss";

const CodificationFilterBtnGroup = ({
  text,
  active,
  onClick,
  type,
  sum,
  description,
}) => {
  return (
    <button
      className={`CodificationFilterBtnGroup row between ${type} ${
        active ? "active" : null
      } ${sum > 0 ? null : "disable"}`}
      onClick={onClick}
    >
      <div className="row">
        <span className="CodificationFilterBtnGroup__wrapperIcon">
          <FontAwesomeIcon icon={iacStatusIconByType(type)} />
        </span>
        {text}
        <span className="CodificationFilterBtnGroup__sum">
          {sum > 0 ? `(${numberWithCommas(sum)})` : ""}
        </span>
      </div>
      <Tooltip
        placement="top"
        title={description}
      >
        <FontAwesomeIcon
          icon={faQuestionCircle}
          className="CodificationFilterBtnGroup__info"
        />
      </Tooltip>
    </button>
  );
};

export default CodificationFilterBtnGroup;
