import React, { useEffect, useState } from 'react';
import { clouds } from "../../utils/icons";
import { useDispatch, useSelector } from 'react-redux';
import { useLocation,useHistory } from "react-router-dom";
import { isEmpty } from 'lodash';
import moment from 'moment';
import { dateTimeFormat } from '../../utils/formatting';
import { getIntegrationLastSync, getIntegrationResourceCount } from '../../redux/actions/integrationsActions';
import AzureDiscoveryCard from '../../components/azureDiscoveryCard/azureDiscoveryCard';
import './azureDiscovery.scss'


const AzureDiscoveryPage = () => {
    let cardTypes =['primary','buckets'];
    const location = useLocation();
    const history = useHistory();
    const dispatch = useDispatch();
    const [integration, setIntegration] = useState(location.state || {});
    const [loading, setLoading] = useState(true);
    const [bucketsCount, setBucketsCount] = useState(null)
    const [syncDates, setSyncDates] = useState({})
    const [totalAssets, setTotalAssets] = useState(0)

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

    if (!location.state || !integration) {
        history.push('/integrations'); 
        return null; 
    }

    useEffect(() => {
        getGcpDiscoveryStatus()
        getTotalResource()
    }, [integration])

    const getTotalResource = async () => {
        if(integration && integration.id){
            const req = await dispatch(getIntegrationResourceCount('azure', integration.id));
            if(!isEmpty(req)) {
                setTotalAssets(req?.count)
            }
        }
    }

    const getGcpDiscoveryStatus = async () => {
        setLoading(true)
        let syncData = await dispatch(getIntegrationLastSync(integration.id, 'azure'));
        setSyncDates(syncData?.data)
        setBucketsCount(syncData?.data?.azureTerraformLastSync?.totalCount);
        setLoading(false)
    }

    const renderLastScanDate = () => {
        if (isEmpty(integration.createdAt)) {
          return "N/A";
        }
        return moment(integration.createdAt).format(dateTimeFormat);
      };
    
    return (
        <div className="azureDiscovery col">
            <div className="azureDiscovery__account row">
                <img
                    alt="cloud"
                    src={clouds('azurerm', themeDark)}
                    style={{
                        height: "50%",
                        transform: "translateY(2px)",
                    }}
                />
                <div className="azureDiscovery__account-title col">
                    <span>{integration?.name}</span>
                    <div className="azureDiscovery__account-title-date row">
                        <span>
                            Creation Date: 
                        </span>
                        <span>
                           {integration?.createdAt ? renderLastScanDate() : "-"}
                        </span>
                    </div>
                </div>
            </div>
            <div className="azureDiscovery__cards">
                {cardTypes.map((type) => (
                    <AzureDiscoveryCard
                        key={type}
                        type={type}
                        integration={integration}
                        syncDates={syncDates}
                        terraformCount={bucketsCount}
                        loading={loading}
                        totalAssets={totalAssets}
                    />
                ))}
            </div>
        </div>
    );
}

export default AzureDiscoveryPage;