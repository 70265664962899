import _ from "lodash";
import React from "react";
import { useHistory } from "react-router-dom";
import AppWizard from "../../../shared/appWizard/appWizard";
import ProviderSelection from "../../stackCreation/providerSelection";

import { clouds } from "../../../utils/icons";

import "./gcpProviderIntegration.scss";

const GcpProviderIntegration = () => {
  const history = useHistory();

  const configWizard = [
    {
      step_title: "Integration Method",
      step_description:
        "Select how would you like to integrate your Google Cloud",
      content_title: "Select Integration Method",
      content: (
        <ProviderSelection
          hideHelm
          hideCft
          hidePulumi
          showGcp
          onClickMethod
          comingSoonPC
          gcpIntegration
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="GcpProviderIntegration">
      <AppWizard
        iconSrc={clouds("gcp")}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default GcpProviderIntegration;
