import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./viewSwitcher.scss";

const ViewSwitcher = ({ openViewModal }) => {
  const activeInventoryView = useSelector(
    (state) => state.screenViewReducer.activeInventoryView
  );

  return (
    <div className="ViewSwitcher row" onClick={openViewModal}>
      <FontAwesomeIcon icon="table" className="ViewSwitcher__icon" />
      <span className="ViewSwitcher__name">{!isEmpty(activeInventoryView) ? `View - ${activeInventoryView?.name}` : "Views"}</span>
      <FontAwesomeIcon icon="chevron-down" className="ViewSwitcher__arrow" />
    </div>
  );
};

export default ViewSwitcher;
