import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import TableWrapper from '../../../shared/tableWrapper/tableWrapper';
import { Tooltip } from 'antd';
import { ReactComponent as CostSym } from "../../../Images/ci/cost-sym.svg";
import { ReactComponent as ScanSym } from "../../../Images/ci/scan-sym.svg";
import { sendEvent } from '../../../utils/amplitude';
import { CiEvents } from '../../../utils/amplitudeEvents';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { capitalizeFirst } from '../../../utils/formatting';
import AppEmpty from '../../../shared/appEmpty/appEmpty';
import DiagnosticArr from './postApply/diagnostic/diagnosticArr';

const CONFIG_TABS = {
  plan: 'plan',
  cost: 'cost',
  scan: 'policy'
}

const PostPlanTable = ({ data = [], loading, parsedDataSummary = {}, pageSize = 10, setPageSize, setPageNumber, handleOpenConfig, hasDiagnosticResources }) => {
    const { t } = useTranslation("ci-pipeline");

    const dataLength = data?.length;
    const expandedRowIds = dataLength ? data.filter((element = {}) => !isEmpty(element.diagnostics)).map((element = {}) => element.id) : [];
    
    const getDetailPanelContent = hasDiagnosticResources ? useCallback((data = {}) => data.row?.diagnostics && <DiagnosticArr diagnostics={data.row.diagnostics} withHeaderBg={false} /> ,[]) : null;
    const getDetailPanelHeight = useCallback(() => "auto", []);

    const onFlagClick = (event, data, triggerTab = '') => {
      event.stopPropagation();
      sendEvent(CiEvents.ciRunResourceClick, { from_graph: false })
      handleOpenConfig(data, triggerTab);
    }
    
    const handleRowClick = (params = {}) => {
      const data = params.row || {};
      sendEvent(CiEvents.ciRunResourceClick, { from_graph: false })
      handleOpenConfig(data, CONFIG_TABS.plan);
    }

    const iconsMap = {
      create: <FontAwesomeIcon className="PostPlanTable__action-icon create" icon="plus-circle" />,
      delete: <FontAwesomeIcon className="PostPlanTable__action-icon delete" icon="minus-circle" />,
      update: <FontAwesomeIcon className="PostPlanTable__action-icon update" icon="pen" />,
      read: <FontAwesomeIcon className="PostPlanTable__action-icon read" icon="check-double" />,
      "no-op": <FontAwesomeIcon className="PostPlanTable__action-icon no-op" icon="ban" />,
      import: <FontAwesomeIcon className="PostPlanTable__action-icon import" icon="download" />,
    }

    const columns = [
        {
          headerName: t("columns.change"),
          field: "action",
          width: 80,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          align: "center",
          renderCell: (params) => {
            const action = params.row?.action;
            const actionComponent = iconsMap[action] || action;
            return (
                <Tooltip title={capitalizeFirst(action)}>
                  {actionComponent}
                </Tooltip>
            );
          }
        },
        {
          headerName: t("columns.providerType"),
          field: "providerName",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const providerName = params.row?.providerName;
            return (
                <Tooltip title={providerName}>
                  {providerName}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.providerName"),
          field: "name",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const name = params.row?.name;
            return (
                <Tooltip title={name}>
                  {name}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.assetType"),
          field: "type",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const type = params.row?.type;
            return (
                <Tooltip title={type}>
                  {type}
                </Tooltip>
            );
          },
        },
        {
          headerName:  t("columns.name"),
          field: "address",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const address = params.row?.address;
            return (
                <Tooltip title={address}>
                  {address}
                </Tooltip>
            );
          },
        },
        {
          headerName: t("columns.flags"),
          field: "status",
          width: 70,
          sortable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          align: 'center',
          renderCell: (params) => {
            const data = params?.row || {};
            const { scanResults = [], isCostResults = false } = data;
            const scanResultsLength = scanResults.length;
            return (
              <div className='row g5'>
                {scanResultsLength ? <ScanSym className="PostPlanTable__flags-icon" onClick={(e) => onFlagClick(e, data, CONFIG_TABS.scan)} /> : null}
                {isCostResults ? <CostSym className="PostPlanTable__flags-icon" onClick={(e) => onFlagClick(e, data, CONFIG_TABS.cost)} /> : null}
              </div>
            )
          }
        },
    ];

    if (!loading && isEmpty(data)) {
        return (
          <div className="PostPlanGraph center">
            <AppEmpty customStyle="code" text="The current Terraform plan does not contain any infrastructure changes" />
          </div>
        );
    }
    
    return (
      <div className="PostPlanTable">
        <TableWrapper
            autoHeight={!!dataLength}
            height="100%"
            loading={loading}
            rowKey="id"
            tableData={data || []}
            columns={columns}
            disableSelectionOnClick
            pageSize={pageSize}
            rowCount={parsedDataSummary?.notNoOpResourcesCount > dataLength ? parsedDataSummary?.notNoOpResourcesCount : dataLength}
            rowsPerPageOptions={[10, 25, 50]}
            handlePageChange={setPageNumber}
            onPageSizeChange={setPageSize} 
            onRowClick={handleRowClick}
            getDetailPanelContent={getDetailPanelContent}
            getDetailPanelHeight={getDetailPanelHeight}
            expandedRowIds={expandedRowIds}
            serverSide
        />
      </div>)
};

export default PostPlanTable;
