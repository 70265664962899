import React from "react";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";
import { useTranslation } from "react-i18next";
import isString from "lodash/isString";
import { Tooltip } from "antd";
import ActionBtn from "../../../../shared/actionBtn/actionBtn";
import { appToast } from "../../../../shared/appToast/appToast";


const Outputs = ({ data = {} }) => {
    const { t } = useTranslation("ci-pipeline");
    const dataLength = data?.length;

    const columns = [
        {
            headerName: t("columns.name"),
            field: "name",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const name = params.row?.name;
                return (
                    <Tooltip title={name}>
                        {name}
                    </Tooltip>
                );
            },
        },
        {
            headerName: t("columns.value"),
            field: "value",
            flex: 1,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                const value = params.row?.value;
                const valueToShow = isString(value) ? value : JSON.stringify(value, null, 2);
                return (
                    <Tooltip title={valueToShow}>
                        {valueToShow}
                    </Tooltip>
                );
            },
        },
        {
          headerName:  "",
          field: "isFailed",
          width: 80,
          align: "center",
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { value = "" } = params.row || {};
            const valueToCopy = isString(value) ? value : JSON.stringify(value, null, 2);
            return (
                valueToCopy && <ActionBtn
                text=""
                icon="copy"
                action="copy"
                stringToAction={valueToCopy}
                onClickDifferentActions={() => appToast("success", "Copied to clipboard")}
                />
            );
          },
        },
      ];
    
    return (
      <>
        <TableWrapper
            autoHeight={!!dataLength}
            rowKey="id"
            tableData={data || []}
            columns={columns}
            disableSelectionOnClick
            pageSize={10}
            handlePageChange={() => null} 
            onRowClick={() => null}
        />
    </>)
};

export default Outputs;