import React from "react";
import { useSelector } from "react-redux";
import { capitalize, map } from "lodash";
import { iacProviders } from "../../../utils/icons";
import Tooltip from "antd/lib/tooltip";
import { v4 as uuidv4 } from "uuid";

import "./iacStacksTypes.scss";
import { IAC_STACKS_MAP, IAC_TYPES } from "../../../consts/general";
import {ReactComponent as CollapseIcon} from '../../../Images/general_icons/collapse.svg'

const IacStacksTypes = ({
  iacTypes,
  aggTypes,
  selectedIacType,
  setSelectedIacType,
  loading,
  collapsed,
  setCollapsed
}) => {
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  let sortedByEnabled = [];
  let disabledTypes= []
  iacTypes?.forEach(type => {
    const match = aggTypes?.find((aggType) => aggType?.key === type);
    const disabled = !match;
    if (!disabled) {
      sortedByEnabled.push(type)
    }else{
      disabledTypes.push(type);
    }
  })
  sortedByEnabled = sortedByEnabled.concat(disabledTypes)
  return (
    <div>
    <div className={`IacStacksTypes ${collapsed && 'collapsed'}`}>
      {map(sortedByEnabled, (type) => {
        const match = aggTypes?.find((aggType) => aggType?.key === type);
        const disabled = !match;
        let count = 0;
        if (!disabled) {
          count = match?.doc_count;
        }
        const active = selectedIacType === type;
        const title = IAC_STACKS_MAP[type] || capitalize(type);
        return (
          <Tooltip
            title={disabled ? `No ${capitalize(type)} stacks` : ""}
            placement="top"
            key={uuidv4()}
          >
            <button
              className={`IacStacksTypes__button row ${
                active ? "active" : ""
              } ${disabled ? "disabled" : ""}`}
              onClick={() => (!loading && !disabled) && setSelectedIacType(type)}>
              <img
                src={iacProviders(
                  active
                    ? `darkPurple${capitalize(type === IAC_TYPES.k8s ? "argo" : type)}`
                    : `darkPurple${capitalize(type === IAC_TYPES.k8s ? "argo" : type)}`,
                  themeDark
                )}
                alt="iacType"
              />
              <div className="IacStacksTypes__button-text row">
                <span>
                  {title}
                </span>
                {count > 0 && (
                  <span
                    className={`IacStacksTypes__button-text-flag ${
                      active ? "active" : ""
                    }`}
                  >
                    {count}
                  </span>
                )}
              </div>
            </button>
          </Tooltip>
        );
      })}
    </div>
    <button className={`collapseToggle ${collapsed ? 'collapsed' : ''}`} onClick={() => setCollapsed(!collapsed)}>
        <CollapseIcon />
    </button>
    </div>
  );
};

export default IacStacksTypes;
