import React, { useEffect, useCallback } from "react";
import { useSelector } from "react-redux";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import debounce from "lodash/debounce";
import {
  formatIacStatus,
  providerNameFormatter,
} from "../../../utils/formatting";

const IssueConfiguration = ({
  form,
  wizardState,
  setWizardState,
  setSubmitDisabled,
  loadingIntegrations,
  allProviderIntegrations,
  selectedResources,
  fetchIntegrationMetadata,
  loadingMetadata,
  integrationMetadata,
  setExtraFields,
  insightsTotal,
  insights,
  selectedInsight,
  driftDrawer,
  loadingMetadataForSearch,
  fetchIntegrationMetadataForSearch,
}) => {
  const layout = {};
  const { TextArea } = Input;

  const jiraIntegrations = useSelector(
    (state) => state.jiraIntegrationReducer.integrations
  );

  useEffect(() => {
    form.setFieldsValue({
      integration: wizardState?.integration,
      project: wizardState?.project,
      issuetype: wizardState?.issuetype,
      summary: wizardState?.summary,
      assignee: wizardState?.assignee,
      description: wizardState?.description,
    });
    checkValidation({
      integration: wizardState?.integration,
      project: wizardState?.project,
      issuetype: wizardState?.issuetype,
      summary: wizardState?.summary,
      assignee: wizardState?.assignee,
      description: wizardState?.description,
    });
  }, [wizardState]);

  useEffect(() => {
    if (!isEmpty(wizardState?.integration)) {
      setWizardState({
        ...wizardState,
        project: null,
        issuetype: null,
        assignee: null,
      });
      fetchIntegrationMetadata(wizardState?.integration);
    }
  }, [wizardState?.integration]);

  const renderSummary = () => {
    let text = "[Firefly] ";
    if (driftDrawer) {
      text += `[IaC Drift] ${selectedResources[0]?.name}`;
    } else {
      if (!isEmpty(selectedInsight)) {
        map(selectedInsight?.labels, (label) => {
          text += `[${label}] `;
        });
        text += `${selectedInsight?.name} (${selectedInsight?.count})`;
      }
    }
    return text;
  };

  useEffect(() => {
      let assetDescription = "";
      let numberOfRows =
        selectedResources?.length > 10 ? 10 : selectedResources?.length;
      for (let index = 0; index < numberOfRows; index++) {
        const match = allProviderIntegrations.find(
          (integ) =>
            integ.accountNumber === selectedResources[index]?.providerId
        );
        const providerName = match
          ? match.name
          : selectedResources[index]?.providerId;
        if (!insights) {
          assetDescription += `Provider Name: ${providerName} (${providerNameFormatter(
            selectedResources[index]?.provider
          )})
IaC Status: ${formatIacStatus(selectedResources[index]?.state)}
Asset ID: ${selectedResources[index]?.arn}
`;
        }
      }
      if (selectedResources?.length > 10 && !insights) {
        assetDescription += `Total Assets: ${
          !insightsTotal ? selectedResources?.length : insightsTotal
        }
`;
      }
      if (insights && !isEmpty(selectedInsight)) {
        assetDescription += `Category: ${selectedInsight?.labels[0]}
Classification Name: ${selectedInsight?.name}
Total Assets: ${selectedInsight?.count}
Description: ${selectedInsight?.description}
`;
        if (selectedInsight?.suggestions) {
          assetDescription += `Check out the Firefly Insights page for our recommendation.
`;
        }
      }
      if (jiraIntegrations?.length === 1) {
        return setWizardState({
          ...wizardState,
          description: assetDescription,
          integration: jiraIntegrations[0]?.id,
          summary: insights || driftDrawer ? renderSummary() : "Firefly: ",
        });
      }
      setWizardState({
        ...wizardState,
        description: assetDescription,
        summary: insights || driftDrawer ? renderSummary() : "Firefly: ",
      });
  }, [allProviderIntegrations]);

  useEffect(() => {
    if (!isEmpty(wizardState?.project)) {
      const projectMatch = integrationMetadata?.metadata?.find(
        (project) => project.id === wizardState?.project
      );
      const taskMatch = projectMatch?.issuetypes?.find(
        (issuetype) => issuetype?.name === "Task"
      );
      setWizardState({
        ...wizardState,
        issuetype: taskMatch ? taskMatch?.id : null,
      });
    }
  }, [wizardState?.project]);

  useEffect(() => {
    if (!isEmpty(wizardState?.issuetype)) {
      const projectMatch = integrationMetadata?.metadata?.find(
        (project) => project.id === wizardState?.project
      );
      const taskMatch = projectMatch?.issuetypes?.find(
        (issuetype) => issuetype?.id === wizardState?.issuetype
      );
      const requiredFields = taskMatch?.fields;
      const extraFields = requiredFields?.filter(
        (field) => !Object.keys(wizardState)?.find((key) => key === field?.key)
      );
      //filter out reporter
      const fieldsNoReporter = extraFields?.filter(
        (field) => field?.key !== "reporter"
      );
      setExtraFields(fieldsNoReporter || []);
    }
  }, [wizardState?.issuetype]);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { integration, project, issuetype, summary, description } =
      formValues;

    if (
      isEmpty(integration) ||
      isEmpty(project) ||
      isEmpty(issuetype) ||
      isEmpty(summary) ||
      isEmpty(description)
    ) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const debounceCallBack = useCallback(
    debounce((integration, val) => {
      fetchIntegrationMetadataForSearch(integration, val);
    }, 350),
    []
  );

  const searchAssignee = (val) => {
    debounceCallBack(wizardState?.integration, val);
  };

  const renderIntegrationsOptions = () => {
    if (!isEmpty(jiraIntegrations)) {
      return map(jiraIntegrations, (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.id}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderProjectsOptions = () => {
    if (!isEmpty(integrationMetadata?.metadata)) {
      return map(integrationMetadata?.metadata, (project) => {
        return (
          <Select.Option value={project.id} key={project.id}>
            {project.name}
          </Select.Option>
        );
      });
    }
  };

  const renderIssueTypesOptions = () => {
    if (!isEmpty(wizardState?.project)) {
      const projectMatch = integrationMetadata?.metadata?.find(
        (project) => project.id === wizardState?.project
      );
      return map(projectMatch?.issuetypes, (issuetype) => {
        return (
          <Select.Option value={issuetype.id} key={issuetype.id}>
            <span className="row g8">
              <img src={issuetype?.iconUrl} />
              {issuetype.name}
            </span>
          </Select.Option>
        );
      });
    }
  };

  const renderAssigneesOptions = () => {
    if (!isEmpty(integrationMetadata?.users)) {
      return map(integrationMetadata?.users, (user) => {
        return (
          <Select.Option value={user.accountId} key={user.accountId}>
            <span className="row g8">
              <img src={user?.avatarUrls?.['48x48'] || ''} width="18px" height="18px" />
              {user.displayName}
            </span>
          </Select.Option>
        );
      });
    }
  };

  return (
    <Form
      {...layout}
      name="create-issue-form"
      form={form}
      className="IssueModal"
      initialValues={{
        integration: null,
        project: null,
        issuetype: null,
        summary: "",
        assignee: null,
        description: "",
      }}
      onValuesChange={(changedValues, allValues) => {
        setWizardState({ ...wizardState, ...allValues });
        checkValidation(allValues);
      }}
    >
      <div className="row IssueModal__row">
        <Form.Item
          label="Integration"
          name="integration"
          rules={[
            {
              required: true,
              message: "Integration is required",
            },
          ]}
          style={{
            marginBottom: "1rem",
            flexDirection: "column",
            flex: "1",
            width: "100%",
          }}
        >
          <Select
            placeholder="Select Integration"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingIntegrations}
            style={{
              width: "100%",
            }}
          >
            {renderIntegrationsOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label="Project"
          name="project"
          rules={[
            {
              required: true,
              message: "Project is required",
            },
          ]}
          style={{
            marginBottom: "1rem",
            flexDirection: "column",
            flex: "1",
            width: "100%",
          }}
        >
          <Select
            placeholder="Select Project"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingMetadata}
            disabled={isEmpty(wizardState?.integration)}
            style={{
              width: "100%",
            }}
          >
            {renderProjectsOptions()}
          </Select>
        </Form.Item>
      </div>

      <div className="row IssueModal__row">
        <Form.Item
          label="Issue Type"
          name="issuetype"
          rules={[
            {
              required: true,
              message: "Issue Type is required",
            },
          ]}
          style={{
            marginBottom: "1rem",
            flexDirection: "column",
            flex: "1",
            width: "100%",
          }}
        >
          <Select
            placeholder="Select Issue Type"
            showSearch
            filterOption={(input, option) =>
              option?.children?.props?.children[1]
                ?.toLowerCase()
                .indexOf(input?.toLowerCase()) >= 0
            }
            disabled={isEmpty(wizardState?.project)}
            style={{
              width: "100%",
            }}
          >
            {renderIssueTypesOptions()}
          </Select>
        </Form.Item>
        <Form.Item
          label="Assignee"
          name="assignee"
          style={{
            marginBottom: "1rem",
            flexDirection: "column",
            flex: "1",
            width: "100%",
          }}
        >
          <Select
            placeholder="Select Assignee"
            showSearch
            filterOption={(input, option) =>
              option?.children?.props?.children[1]
                ?.toLowerCase()
                .indexOf(input?.toLowerCase()) >= 0
            }
            disabled={isEmpty(wizardState?.issuetype)}
            loading={loadingMetadataForSearch}
            onSearch={(val) => searchAssignee(val)}
            style={{
              width: "100%",
            }}
          >
            {renderAssigneesOptions()}
          </Select>
        </Form.Item>
      </div>
      <Form.Item
        label="Summary"
        name="summary"
        style={{
          marginBottom: "1rem",
          flexDirection: "column",
          flex: "1",
        }}
        rules={[
          {
            required: true,
            message: "Summary is required",
          },
        ]}
      >
        <Input />
      </Form.Item>
      <Form.Item
        label="Description"
        name="description"
        style={{
          marginBottom: driftDrawer ? "0.2rem" : "1rem",
          flexDirection: "column",
        }}
        rules={[
          {
            required: true,
            message: "Description is required",
          },
        ]}
      >
        <TextArea rows={4} />
      </Form.Item>
      {driftDrawer && (
        <span className="IssueModal__note">
          Note: The ticket description will also include a table with the drift
          detail
        </span>
      )}
    </Form>
  );
};

export default IssueConfiguration;
