import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Spin, Tooltip } from 'antd';
import { ReactComponent as InfoIcon } from '../../../../Images/general_icons/Information Circle.svg'
import AppBtn from '../../../../shared/appBtn/appBtn';
import './IntegrationDiscoveryCard.scss'

const IntegrationDiscoveryCard = ({
    title,
    icon,
    tooltipContent,
    displayCount = 0,
    lastScanDate,
    onScanHandler,
    isLoading = false,
    scanLoading
}) => {

    return (
        <div className="IntegrationDiscoveryCard basic-card">
            <div className="IntegrationDiscoveryCard__title">
                {icon}
                <span>{title}</span>
                Total discovered
                <Tooltip title={tooltipContent} placement='bottomLeft'>
                    <InfoIcon />
                </Tooltip>
            </div>
            <div className="IntegrationDiscoveryCard__content">
                <div className="IntegrationDiscoveryCard__content-count">{isLoading ? <Spin /> : displayCount}</div>
                <div className={`IntegrationDiscoveryCard__content-footer`}>
                    {lastScanDate && <span className='IntegrationDiscoveryCard__content-footer-date'>
                        Last Scan: {lastScanDate}
                    </span>}
                    {onScanHandler != undefined && (
                        <AppBtn
                            loading={scanLoading}
                            disabled={isLoading || scanLoading}
                            onClick={onScanHandler}
                            text="Scan now"
                            icon={
                                <FontAwesomeIcon
                                    icon="sync-alt"
                                    color='white'
                                />
                            }
                        />

                    )}
                </div>
            </div>
        </div>
    );
}

export default IntegrationDiscoveryCard;