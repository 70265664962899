import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";

const OktaCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, orgName, apiToken, baseUrl } = formValues;

    if (
      _.isEmpty(name) ||
      _.isEmpty(orgName) ||
      _.isEmpty(apiToken) ||
      _.isEmpty(baseUrl)
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="OktaCreation col">
      <div className="OktaCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue OktaCreation__intro-list">
          <li key="1">Sign in to your Okta account.</li>
          <li key="2">
            Select <span className="purple-text med-bold">API</span>
            {` > `}
            <span className="purple-text med-bold">Create Token</span>.
          </li>
          <li key="3">
             Enter a descriptive name in the <span className="purple-text med-bold">Name</span> field and select
             <span className="purple-text med-bold"> Create Token</span>.
          </li>
          <li key="4">Copy the token.</li>
          <li key="5">
            Paste the API key into the <span className="purple-text med-bold">API Token</span> field.
          </li>
          <li key="6">
            Enter the Okta account URL in the <span className="purple-text med-bold">Base URL</span> field.
          </li>
          <li key="7">
            Select <span className="purple-text med-bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="okta-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Okta Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(changedValues, allValues) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>
        <Form.Item
          label="API Token"
          name="apiToken"
          rules={[
            {
              required: true,
              message: "API Token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter API Token" />
        </Form.Item>
        <Form.Item
          label="Organization Name"
          name="orgName"
          rules={[
            {
              required: true,
              message: "Organization Name is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="dev-12345678" />
        </Form.Item>
        <Form.Item
          label="Base URL"
          name="baseUrl"
          rules={[
            {
              required: true,
              message: "Base URL is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="okta.com" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default OktaCreation;
