import React from "react";
import { v4 as uuidv4 } from "uuid";
import Diagnostic from "./diagnostic";
import isEmpty from "lodash/isEmpty";

const DiagnosticArr = ({ diagnostics = [], withHeaderBg = true }) => {
    if (isEmpty(diagnostics)) return null;
    return <div className="DiagnosticArr col g10">
        {diagnostics.map((diagnostic) => {
            const key = uuidv4();
            return <Diagnostic key={key} item ={{ key, ...diagnostic }} withHeaderBg={withHeaderBg} />})
        }
    </div>
};

export default DiagnosticArr;
