import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";

const TorqCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, webhookUrl } = formValues;

    if (_.isEmpty(name) || _.isEmpty(webhookUrl)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="TorqCreation col">
      <div className="TorqCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue TorqCreation__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">Log in to your Torq account.</span>
          </li>
          <li key="2">
            <span className="dark-blue">
              Select <span className="bold">Integrations</span>
              {` > `}
              <span className="bold">Triggers</span>
              {` > `}
              <span className="bold">Firefly</span>.
            </span>
          </li>
          <li key="3">
            <span className="dark-blue">
              Select <span className="bold">Add</span>.
            </span>
          </li>
          <li key="4">
            Enter the <span className="bold">Integration Name</span> and select{" "}
            <span className="bold">Add</span>.
          </li>
          <li key="5">Copy the webhook URL.</li>
          <li key="6">
            In Firefly, enter a <span className="bold">Nickname</span> and paste
            the webhook URL.
          </li>
          <li key="7">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="torq-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Torq Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label="Webhook URL"
          name="webhookUrl"
          rules={[
            {
              required: true,
              message: "Webhook url is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default TorqCreation;
