import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import { getAllIntegrationCounts } from "../../redux/actions/integrationsActions";
import { calculateTotalIntegrations } from "../../utils/helpers";
import { numberWithCommas } from "../../utils/formatting";
import { useHistory } from "react-router-dom";
import Loading from "../../shared/loading/loading";
import IntegrationsHeader from "../../components/integrations/integrationsHeader/integrationsHeader";
import map from "lodash/map";
import IntegrationCountCard from "../../components/integrations/integrationCountCard/integrationCountCard";
import "./integrations.scss";

const Integrations = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("integrations");
  const history = useHistory();
  const [loading, setLoading] = useState(true);

  const integrationsCount = useSelector(
    (state) => state.integrationsReducer.counts
  );

  useEffect(() => {
    fetchIntegrationsCount();
  }, []);

  const fetchIntegrationsCount = async () => {
    setLoading(true);
    await dispatch(getAllIntegrationCounts());
    setLoading(false);
  };

  const integrationsTotal = useMemo(() => {
    return calculateTotalIntegrations(integrationsCount);
  }, [integrationsCount]);

  useEffect(() => {
    if(!loading && !integrationsTotal) {
      history.push("/integrations/new-integration");
    }
  },[loading, integrationsTotal])

  if (loading) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="basic-page Integrations col">
      <IntegrationsHeader count={integrationsTotal} />
      <div className="Integrations__list col">
        {map(integrationsCount || {}, (v, k) => {
          return (
            <div className="Integrations__list-block col" key={k}>
              <div className="Integrations__list-block-title row g5">
                <span className="bold">{t(`categories.${k}`)}</span>
                <span className="Integrations__list-block-title-sum">
                  {`(${numberWithCommas(calculateTotalIntegrations(v))})`}
                </span>
              </div>
              <div className="Integrations__list-block-items">
                {map(v || {}, (count, key) => {
                  return (
                    <IntegrationCountCard
                      key={key}
                      category={k}
                      integ={key}
                      count={count}
                    />
                  );
                })}
              </div>
            </div>
          );
        })}
      </div>
    </div>
  );
};

export default Integrations;
