import React, {useEffect, useState} from "react";
import {Link} from "react-router-dom";
import {
    getAllVcs,
  } from "../../../redux/actions/globalAppActions";
import { Input } from "antd";
import {searchResource, removeResource, cleanData} from "../../../redux/actions/removeResourceActions";
import {useDispatch, useSelector} from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { S3_ASSETS } from '../../../consts/config';
import Loading from "../../../shared/loading/loading";
import AppDrawer from '../../../shared/appDrawer/appDrawer';
import AppBtn from '../../../shared/appBtn/appBtn';
import AppEmpty from '../../../shared/appEmpty/appEmpty';

import "./removeResourceDrawer.scss";
import FoundResource from "./foundResource/foundResource";
import RemovedResource from "./removedResource/removedResource";
import {
    ERR_FILES_NOT_FOUND_IN_REPO,
    ERR_VCS_API_ERROR,
    ERR_VCS_INTEGRATION_NOT_FOUND,
    ERR_VCS_REPO_NOT_FOUND,
    ERR_RESOURCE_NOT_FOUND,
    ERR_INTERNAL_SERVER_ERROR,
    ERR_RESOURCE_NOT_MANAGED_BY_TF,
    ERR_RESOURCE_TF_STACK_NOT_FOUND,
    ERR_TF_VERSION_NOT_SUPPORTED,
    ERR_UPDATED_SOME_FILES, ERR_ES_SEARCH_RESULT_EMPTY
} from "../../../consts/errorCodes";

const RemoveResourceDrawer = ({close, visible, data, shouldCommentCode}) => {
    const [loadingVcs, setLoadingVcs] = useState(true);
    const [loadingSearchRequest, setLoadingSearchRequest] = useState(true);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    const [doneLoading, setDoneLoading] = useState(false);
    const [foundSuccess, setFoundSuccess] = useState(false);
    const [removedSuccess, setRemovedSuccess] = useState(false);
    const [foundResourceResultsWithText, setFoundResourceResultsWithText] = useState([]);
    const [removeResourceResultsWithText, setRemoveResourceResultsWithText] = useState([]);
    const [formValid, setFormValid] = useState(false);
    const [dispatchOk, setDispatchOk] = useState(false);
    const [prComment, setPrComment] = useState("");

    const { TextArea } = Input;

    const dispatch = useDispatch();
    const vcsIntegs = useSelector((state) => state.globalAppReducer.vcs);
    const foundResource = useSelector((state) => state.removeResourceReducer.findRemoveResult);

    useEffect(() => {
        cleanOldData()
        setFoundResourceResultsWithText([])
        setRemoveResourceResultsWithText([])
        setLoadingVcs(false);
        setLoadingSearchRequest(false);
        setLoadingSubmit(false);
        setPrComment("");
        if (visible) {
            getVcs();
        }
    }, [visible]);

    useEffect(() => {
        if (visible && !loadingVcs && !_.values(vcsIntegs).every(_.isEmpty)) {
            runSearch();
        }
    }, [vcsIntegs, loadingVcs]);

    useEffect(() => {
        if (!loadingVcs && !loadingSearchRequest) {
            setDoneLoading(true);
        }

        if (loadingVcs || loadingSearchRequest) {
            setDoneLoading(false);
        }
    }, [loadingSearchRequest, loadingVcs]);

    useEffect(() => {
        if (!_.isEmpty(foundResource) && doneLoading) {
            setFoundResourceResultsWithText(_.orderBy(getFoundResourceResultsWithText(), ["id"]))
        }
    }, [foundResource, doneLoading]);

    useEffect(() => {
        if (!_.isEmpty(foundResourceResultsWithText)) {
            const numChecked = _.filter(foundResourceResultsWithText, result => result?.isChecked).length
            setFormValid(Boolean(numChecked))
        }
    }, [foundResourceResultsWithText]);

    const cleanOldData = async () => {
        await dispatch(cleanData())
    }

    const getVcs = async () => {
        setLoadingVcs(true);
        await Promise.all([
            dispatch(getAllVcs()),
        ]);
        setLoadingVcs(false);
    }

    const runSearch = async () => {
        setLoadingSearchRequest(true);

        if (!_.values(vcsIntegs).every(_.isEmpty)) {
            const result = await dispatch(searchResource(data?.terraformObjectReferencesAncestry, shouldCommentCode))
            setDispatchOk(result?.ok)
        }
        setLoadingSearchRequest(false);
    };

    const getFoundResourceResultsWithText = () => {
        if (!_.isEmpty(foundResource)) {
            return _.map(foundResource, (obj) => {
                switch (obj?.error) {
                    case ERR_RESOURCE_TF_STACK_NOT_FOUND:
                        setFoundSuccess(false);
                        return {...obj, responseText: "Didn't find asset TF state."}
                    case ERR_RESOURCE_NOT_MANAGED_BY_TF:
                        setFoundSuccess(false)
                        return {...obj, responseText: "Asset is not managed by terraform."}
                    case ERR_TF_VERSION_NOT_SUPPORTED:
                        setFoundSuccess(false)
                        return {...obj, responseText: `Terraform version ${obj?.terraformVersion} is not supported.`}
                    case ERR_VCS_INTEGRATION_NOT_FOUND:
                        setFoundSuccess(false)
                        return {...obj, responseText: "Didn't find VCS integration."}
                    case ERR_ES_SEARCH_RESULT_EMPTY:
                        setFoundSuccess(false)
                        return {
                            ...obj,
                            responseText: `Didn't find asset code in the integrated VCS.`
                        }
                    case ERR_RESOURCE_NOT_FOUND:
                        setFoundSuccess(false)
                        return {
                            ...obj,
                            objectInfo: {
                                objectType: data?.assetType, objectName: data?.terraformObjectName,
                                objectModule: data?.terraformModule
                            },
                            responseText: `Didn't find asset code in the integrated VCS. Probably the resource code is already deleted, please make sure to remove it from the state.`
                        }
                    case ERR_VCS_API_ERROR:
                        setFoundSuccess(false)
                        return {...obj, responseText: `VCS API error.`}
                    case ERR_INTERNAL_SERVER_ERROR:
                        setFoundSuccess(false)
                        return {...obj, responseText: `Internal server error.`}
                    default:
                        if (!dispatchOk) {
                            setFoundSuccess(false)
                            return [{responseText: "Internal server error.", error: ERR_INTERNAL_SERVER_ERROR}];
                        }
                        setFoundSuccess(true)
                        return {
                            ...obj,
                            objectInfo: {
                                objectType: data?.assetType, objectName: data?.terraformObjectName,
                                objectModule: data?.terraformModule
                            },
                            isChecked: foundResource?.length === 1,
                            showDiff: true,
                            id: uuidv4(),
                            isSingle: foundResource?.length === 1
                        }
                }
            })
        }
        setFoundSuccess(false)
        return [{responseText: "Internal server error.", error: ERR_INTERNAL_SERVER_ERROR}];
    };

    const handleSubmit = async () => {
        setLoadingSubmit(true);

        const checkedObjs = _.filter(foundResourceResultsWithText, result => result?.isChecked)
        let removedResourceResults = []
        await Promise.all(_.map(checkedObjs, async obj => {
            const result = await dispatch(removeResource(data?.frn, data?.vcsId, obj?.repo, obj?.filesInfo, prComment))
            setDispatchOk(result?.ok)
            removedResourceResults.push({removedResource: result?.data, foundResources: obj})
        }));

        setRemoveResourceResultsWithText(_.map(removedResourceResults, result =>
            getRemoveResourceResultsWithText(result?.removedResource, result?.foundResources?.filesInfo, result?.foundResources?.objectInfo)
        ))
        setLoadingSubmit(false);
    }

    const getRemoveResourceResultsWithText = (obj, filesInfo, objectInfo) => {
        if (!_.isEmpty(obj)) {
            switch (obj?.error) {
                case ERR_VCS_INTEGRATION_NOT_FOUND:
                    setRemovedSuccess(false)
                    return {...obj, responseText: "Didn't find VCS integration."}
                case ERR_VCS_REPO_NOT_FOUND:
                    setRemovedSuccess(false)
                    return {...obj, responseText: `Failed to find the VCS repo ${obj?.repo}.`}
                case ERR_FILES_NOT_FOUND_IN_REPO:
                    setRemovedSuccess(false)
                    return {...obj, responseText: `Files not found in repo ${obj?.repo}.`}
                case ERR_VCS_API_ERROR:
                    setRemovedSuccess(false)
                    return {...obj, responseText: `VCS API error.`}
                case ERR_INTERNAL_SERVER_ERROR:
                    setRemovedSuccess(false)
                    return {...obj, responseText: `Internal server error.`}
                case ERR_UPDATED_SOME_FILES:
                    setRemovedSuccess(true)
                    return {...obj, objectInfo,
                        responseText: `Fixed ${obj?.numUpdated}/${_.size(filesInfo)} files. Couldn't find the other files in the repo.`}
                default:
                    if (!dispatchOk) {
                        setFoundSuccess(false)
                        return {responseText: "Internal server error.", error: ERR_INTERNAL_SERVER_ERROR};
                    }
                    setRemovedSuccess(true)
                    return {...obj, objectInfo}
            }
        }
        setFoundSuccess(false)
        return {responseText: "Internal server error.", error: ERR_INTERNAL_SERVER_ERROR};
    };

    const handleCloseDrawer = () => {
        setFoundResourceResultsWithText([])
        setRemoveResourceResultsWithText([])
        setLoadingVcs(false);
        setLoadingSearchRequest(false);
        setLoadingSubmit(false);
        close();
    };

    const setChecked = (e, objId) => {
        setFoundResourceResultsWithText((oldFoundResourceResultsWithText => {
            const obj = _.find(oldFoundResourceResultsWithText, result => result?.id === objId)
            obj.isChecked = e?.target?.checked
            return _.orderBy([..._.filter(oldFoundResourceResultsWithText, result => result?.id !== objId), obj], ["id"])
        }))
    }

    const setShowDiff = (objId) => {
        setFoundResourceResultsWithText((oldFoundResourceResultsWithText => {
            const obj = _.find(oldFoundResourceResultsWithText, result => result?.id === objId)
            obj.showDiff = !obj?.showDiff
            return _.orderBy([..._.filter(oldFoundResourceResultsWithText, result => result?.id !== objId), obj], ["id"])
        }))
    }

    return (
        <AppDrawer
            title="Remove asset code request"
            closeDrawer={handleCloseDrawer}
            visible={visible}
            width="80vw"
            zIndex={1002}
            footer={[
                <AppBtn
                    loading={loadingSubmit}
                    disabled={!formValid || (!doneLoading && removeResourceResultsWithText.length !== 0 && !foundSuccess)}
                    onClick={handleSubmit}
                    text="Create Pull Request"
                    key="create-pull-request"
                />
            ]}
        >
            <div className="RemoveResourceModal__content">
                {(loadingVcs || loadingSearchRequest || !doneLoading) && (
                    <div className="tab-page center">
                        <Loading />
                    </div>
                )}

                {!loadingVcs && _.values(vcsIntegs).every(_.isEmpty) && (
                    <div className="RemoveResourceModal__content-body tab-page center col" style={{ gap: '10px' }}>
                    <AppEmpty customStyle="git" text="Git integration was not found"/>
                        <div className="no-vcs-integs col">
                            <Link to="/integrations/github-integration">+ Add GitHub Integration</Link>
                            <Link to="/integrations/gitlab-integration">+ Add GitLab Integration</Link>
                            <Link to="/integrations/bitbucket-integration">+ Add Bitbucket Integration</Link>
                        </div>
                    </div>
                )}

                <div className="RemoveResourceModal__content-success">
                    {doneLoading && removeResourceResultsWithText.length === 0 &&
                        <div                    
                            className="RemoveResourceModal__content-success-form col center tab-page"
                        >
                            {!foundSuccess && <AppEmpty customStyle="code" text="Asset code not found"/>}
                            <span className="RemoveResourceModal__content-success-checkbox col">
                            {foundSuccess && 
                            <TextArea 
                                value={prComment}
                                rows={4}
                                onChange={e => setPrComment(e?.target?.value)}
                                placeholder="Pull Request Comment (Optional)"
                                style={{ width: "350px" }}
                            />}
                                {_.map(foundResourceResultsWithText, response => <FoundResource key={uuidv4()} obj={response} setShowDiff={setShowDiff} setChecked={setChecked}/>)}
                            </span>
                        </div>
                    }

                    {doneLoading && !loadingSubmit && removeResourceResultsWithText.length !== 0 &&
                        <div className="tab-page col center" style={{ gap: '20px' }}>
                            {removedSuccess && <img src={`${S3_ASSETS}/illustrations/rocket-computer.svg`} alt="rocket" />}
                            <span className="title">{removedSuccess ? <>Asset Code Removed!</> : <>Failed to remove asset code</>}</span>
                            <span className="centered small-title RemoveResourceModal__content-success-subtitle col">
                                {_.map(removeResourceResultsWithText, response => <RemovedResource key={uuidv4()} obj={response}/>)}
                            </span>
                        </div>
                    }
                </div>
            </div>
        </AppDrawer>
    );
};

export default RemoveResourceDrawer;
