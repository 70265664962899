import React from "react";
import _ from "lodash";
import Radio from "antd/lib/radio";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { appToast } from "../../../shared/appToast/appToast";
import { handleCopy } from "../../../utils/helpers";
import fireflyLogo from "../../../Images/firefly_logo.svg";
import "./singleView.scss";

const SingleView = ({
  name,
  active,
  setSelected,
  id,
  handleDelete,
  isDefault,
  handleDefault,
  isCustom,
}) => {
  const handleCopyToShare = () => {
    const sharebleUrl = `${
      window.location.origin + window.location.pathname
    }?view=${id}`;
    handleCopy(sharebleUrl);
    return appToast(
      "success",
      "Sharing is Caring!",
      "Shareble Link copied to clipboard"
    );
  };

  return (
    <div className={`SingleView row between ${active ? "active" : null}`}>
      <Radio
        value={id}
        onChange={(e) => setSelected(e.target.value)}
      >
        {name}
        {!_.isUndefined(isCustom) && !isCustom && (
          <img
            src={fireflyLogo}
            alt="firefly"
            className="SingleView__firefly"
          />
        )}
      </Radio>
      <div className="SingleView__actions row">
        <button
          className={`SingleView__actions-default ${
            isDefault ? "default" : null
          }`}
          onClick={() => handleDefault(id)}
        >
          {isDefault ? "Unset default view" : "Set as Default"}
        </button>
        <IconBtn type="share" onClick={handleCopyToShare} colorClass="purple" />
        {!_.isUndefined(isCustom) && !isCustom ? (
          <div style={{ width: "22px" }} />
        ) : (
          <IconBtn
            type="delete"
            onClick={() => handleDelete(id)}
            colorClass="red"
          />
        )}
      </div>
    </div>
  );
};

export default SingleView;
