import {
  capitalize,
  filter,
  isArray,
  isEmpty,
  lowerCase,
  map,
  sortBy,
} from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { queryModuleById } from "../../../redux/actions/iacStacksActions";
import Loading from "../../../shared/loading/loading";
import { v4 as uuidv4 } from "uuid";
import ScanResult from "./ScanResult";

import "./vulnerabilities.scss";
import AppModal from "../../../shared/appModal/appModal";

const Vulnerabilities = ({ row, visible, handleClose }) => {
  const [loading, setLoading] = useState(false);
  const [initScanResults, setInitScanResults] = useState(null);
  const [scanResults, setScanResults] = useState(null);
  const [selectedSeverityFilter, setSelectedSeverityFilter] = useState(null);
  const order = ["UNKNOWN", "LOW", "MEDIUM", "HIGH", "CRITICAL"];

  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      getScanResults();
    }
  }, [visible]);

  useEffect(() => {
    filterResultsBySeverity();
  }, [selectedSeverityFilter]);

  const getJsonData = (input) => {
    try {
      let jsonData = JSON.parse(input);
      if (isArray(jsonData)) {
        jsonData = sortBy(jsonData, (result) => result?.severity);
      }
      return jsonData;
    } catch (error) {
      return null;
    }
  };

  const filterResultsBySeverity = () => {
    if (!isEmpty(selectedSeverityFilter)) {
      const filteredScanResults = filter(
        initScanResults,
        (result) =>
          lowerCase(result?.severity) === lowerCase(selectedSeverityFilter)
      );
      return setScanResults(filteredScanResults);
    }
    setScanResults(initScanResults);
  };

  const getScanResults = async () => {
    setLoading(true);
    const module = await dispatch(queryModuleById(row?.id));
    const allResults = getJsonData(module?.scanResults);
    setInitScanResults(allResults);
    setScanResults(allResults);
    setLoading(false);
  };

  const renderTitle = () => {
    return (
      <div className="Vulnerabilities__container-filters row">
        <span style={{ marginRight: '20px' }}>Misconfigurations</span> 
        {map(order, (severity) => {
          const active = selectedSeverityFilter === severity;
          const shouldRender =
            row?.[`scanResultsSeverityCount.${severity}`] &&
            row?.[`scanResultsSeverityCount.${severity}`][0] > 0;
          if (shouldRender) {
            return (
              <button
                key={uuidv4()}
                className={`Vulnerabilities__container-filters-button row ${lowerCase(
                  severity
                )} ${active ? "active" : ""}`}
                onClick={() => {
                  if (active) {
                    return setSelectedSeverityFilter(null);
                  }
                  setSelectedSeverityFilter(severity);
                }}
              >
                <span>
                  {row?.[`scanResultsSeverityCount.${severity}`]
                    ? row?.[`scanResultsSeverityCount.${severity}`][0]
                    : null}
                </span>
                <span>{capitalize(severity)}</span>
              </button>
            );
          }
        })}
      </div>
    );
  };
  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      hideSubmitBtn
      width="70vw"
      title={loading ? "Misconfiguration" : renderTitle()}
      bodyClassName="Vulnerabilities-body"
      centered
    >
      <div className="Vulnerabilities">
        {loading ? (
          <div className="Vulnerabilities__loaderContainer center">
            <Loading />
          </div>
        ) : (
          <div className="Vulnerabilities__container col">
            <div className="Vulnerabilities__container-list col">
              {map(scanResults, (scanResult) => (
                <ScanResult key={uuidv4()} result={scanResult} />
              ))}
            </div>
          </div>
        )}
      </div>
    </AppModal>
  );
};

export default Vulnerabilities;
