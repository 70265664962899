import React from "react";
import { Form, Input, Radio } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";

const DatadogCreation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const sites = [
    { value: "US1", text: "US1" },
    { value: "US3", text: "US3" },
    { value: "US5", text: "US5" },
    { value: "EU1", text: "EU1" },
  ];

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, APIKey, APPKey, site } = formValues;

    if (
      _.isEmpty(name) ||
      _.isEmpty(APIKey) ||
      _.isEmpty(APPKey) ||
      _.isEmpty(site)
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="DatadogCreation col">
      <div className="DatadogCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue DatadogCreation__intro-list">
          <li key="1">
            <a
              href="https://app.datadoghq.com/organization-settings/application-keys"
              target="_blank"
              rel="noreferrer"
            >
              Create a new application key
            </a>
            .
          </li>
          <li key="2">
            From the upper-right corner, select{" "}
            <span className="bold">New Key</span>.
          </li>
          <li key="3">Copy and paste the application key into the box.</li>
          <li key="4">
            <a
              href="https://app.datadoghq.com/organization-settings/api-keys"
              target="_blank"
              rel="noreferrer"
            >
              Create a new API key
            </a>
            .
          </li>
          <li key="5">
            From the upper-right corner, select{" "}
            <span className="bold">New Key</span>.
          </li>
          <li key="6">Copy and paste the API key into the box.</li>
          <li key="7">
            Select your Datadog site and select{" "}
            <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="datadog-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Datadog",
        }}
        className="AppWizard__content-body-form DatadogCreation__form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          className="form-item-width"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>
        <Form.Item
           label="Application key"
          name="APPKey"
          className="form-item-width"
          rules={[
            {
              required: true,
              message: "Application key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter Application key" />
        </Form.Item>
        <Form.Item
          label="API Key"
          name="APIKey"
          className="form-item-width"
          rules={[
            {
              required: true,
              message: "API key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter API key" />
        </Form.Item>
        <Form.Item
          label="Site"
          name="site"
          rules={[
            {
              required: true,
              message: "Site selection is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Radio.Group value="site">
            {_.map(sites || [], (i) => (
              <Radio key={i?.value} value={i?.value}>
                <span className="text">{i?.text}</span>
              </Radio>
            ))}
          </Radio.Group>
        </Form.Item>
      </Form>
    </div>
  );
};

export default DatadogCreation;
