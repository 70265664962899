import { isEmpty, lowerCase } from "lodash";
import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";

import "./scanResult.scss";

const ScanResult = ({ result }) => {
  const renderHeader = () => {
    return isEmpty(result?.location?.filename) ? (
      ""
    ) : (
      <a href={result?.codeLink || result?.CodeLink} target="_blank" rel="noreferrer">
        {`${result?.location?.filename}:${result?.location?.start_line}:${result?.location?.end_line}`}
      </a>
    );
  };
  return (
    <div className="ScanResult col">
      <div className="ScanResult__header">{renderHeader()}</div>
      <div className="ScanResult__summary col">
        <span>
          <span
            className={`ScanResult__summary-vulnerability ${lowerCase(
              result?.severity
            )}`}
          >
            {`${result?.severity}: `}
          </span>
          {!isEmpty(result?.description)
            ? result?.description
            : result?.rule_description}
        </span>
        <div className="ScanResult__summary-code">
          <SyntaxHighlighter
            style={atomDark}
            showLineNumbers={true}
            language="hcl"
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
            startingLineNumber={result?.location?.start_line || 1}
          >
            {result?.code || result?.Code}
          </SyntaxHighlighter>
        </div>
      </div>
    </div>
  );
};

export default ScanResult;
