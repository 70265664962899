import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
export const GET_DASHBOARD_BASE_AGGREGATIONS = "GET_DASHBOARD_BASE_AGGREGATIONS";
export const GET_DASHBOARD_FIREFLY_AGGREGATIONS = "GET_DASHBOARD_FIREFLY_AGGREGATIONS";
export const SET_ONLY_PROD = "SET_ONLY_PROD";
export const SET_PROD_ACCOUNTS = "SET_PROD_ACCOUNTS";
export const SET_ONBOARDING_COMPLETE = "SET_ONBOARDING_COMPLETE";
export const GET_ACCOUNT_TOTAL_ASSETS = "GET_ACCOUNT_TOTAL_ASSETS";

const getDashboardBaseAggregations = (screenFilters, freeTextSearch, showError, onlyProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dashboard/base-aggs`,
      "POST",
      { screenFilters, freeTextSearch, onlyProd },
      undefined,
      undefined,
      showError
    );
    const data = await req.json();

    dispatch({
      type: GET_DASHBOARD_BASE_AGGREGATIONS,
      payload: data,
    });
  });
};

const getDashboardFireflyAggregations = (onlyProd, showError) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dashboard/firefly-aggs`,
      "POST",
      { onlyProd },
      undefined,
      undefined,
      showError
    );
    const data = await req.json();

    dispatch({
      type: GET_DASHBOARD_FIREFLY_AGGREGATIONS,
      payload: data,
    });
  });
};

const setOnlyProd = (onlyProd) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_ONLY_PROD,
      payload: onlyProd,
    });
  });
};

const setProdAccounts = (arr) => {
  return action(async (dispatch) => {
   
    dispatch({
      type: SET_PROD_ACCOUNTS,
      payload: arr,
    });
  });
};

const setOnboardingComplete = (isComplete) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_ONBOARDING_COMPLETE,
      payload: isComplete,
    });
  });
};

const getAccountTotalAssets = (onlyProd, showError) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dashboard/inventory-total`,
      "POST",
      { onlyProd },
      undefined,
      undefined,
      false
    );
    const data = await req.json();

    dispatch({
      type: GET_ACCOUNT_TOTAL_ASSETS,
      payload: data,
    });
  });
};

export { getDashboardBaseAggregations, getDashboardFireflyAggregations, setOnlyProd, setProdAccounts, setOnboardingComplete, getAccountTotalAssets };
