import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Empty } from "antd";
import _ from "lodash";
import TopFivePropertiesItem from "./topFivePropertiesItem";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./topFiveProperties.scss";

const TopFiveProperties = () => {
  const aggregationsIsManaged = useSelector(
    (state) => state.dashboardReducer.aggregationsIsManaged
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    organizeDriftedPropData();
  }, [aggregationsIsManaged]);

  const organizeDriftedPropData = () => {
    if (!_.isEmpty(aggregationsIsManaged)) {
      const driftedData = aggregationsIsManaged.find(
        (item) => item?.key === "modified"
      );
      if (
        !_.isEmpty(driftedData) &&
        !_.isEmpty(driftedData?.properties?.buckets)
      ) {
        const formattedData = _.map(
          driftedData?.properties?.buckets || [],
          (el) => {
            const type = el?.type?.buckets;
            return { ...el, type: _.isEmpty(type) ? "" : type[0]?.key };
          }
        );
        return setData(formattedData);
      }
    }
  };

  const renderList = () => {
    if (!_.isEmpty(data)) {
      return _.map(data || [], (item) => {
        return (
          <TopFivePropertiesItem
            key={item?.key}
            name={item?.key}
            count={item?.doc_count}
            resourceType={item?.type}
          />
        );
      });
    }
  };

  if (_.isEmpty(data)) {
    return (
      <div className="TopFiveProperties__empty basic-card center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      </div>
    );
  }

  return (
    <div className="TopFiveProperties row basic-card">
      <span className="TopFiveProperties__line"></span>
      <span className="TopFiveProperties__circle center">
        <FontAwesomeIcon icon="code-branch" />
      </span>
      <div className="TopFiveProperties__list col">{renderList()}</div>
    </div>
  );
};

export default TopFiveProperties;
