import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { Tooltip } from "antd";
import { useSelector, useDispatch } from "react-redux";
import DevOpsLoader from "../../../shared/devOpsLoader/devOpsLoader";
import { getScanStatus } from "../../../redux/actions/onbordingActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./initialDiscoveryAlert.scss";

const InitialDiscoveryAlert = () => {
  const dispatch = useDispatch();
  const account = useSelector((state) => state.onbordingReducer.account);
  const [loadingCheckScan, setLoadingCheckScan] = useState(false);

  useEffect(() => {
    if (
      !isEmpty(account) &&
      has(account, "initial_scan_complete") &&
      !account?.initial_scan_complete &&
      !loadingCheckScan
    ) {
      fetchScanStatus();
    }
  }, [account?.initial_scan_complete, loadingCheckScan]);

  const fetchScanStatus = async () => {
    if (
      !isEmpty(account) &&
      has(account, "initial_scan_complete") &&
      !account?.initial_scan_complete
    )
      setLoadingCheckScan(true);
    await dispatch(getScanStatus());
    setTimeout(() => {
      setLoadingCheckScan(false);
    }, 7000); //time to wait between the next check
  };

  if (
    !isEmpty(account) &&
    has(account, "initial_scan_complete") &&
    !account?.initial_scan_complete
  ) {
    return (
      <div className="InitialDiscoveryAlert row center">
        <DevOpsLoader />
        Initial Discovery Scanning In Progress
        <Tooltip
          placement="bottomRight"
          title="Firefly is working on your account. It will take us a little while to get your data ready. The time it takes us depends on how many assets you have. So stay tuned and don’t worry. We will send you an email when we’re done, and your inventory is ready."
          overlayInnerStyle={{
            width: "350px",
          }}
        >
          <FontAwesomeIcon
            icon={["far", "question-circle"]}
            className="InitialDiscoveryAlert__info"
          />
        </Tooltip>
      </div>
    );
  }

  return null;
};

export default InitialDiscoveryAlert;
