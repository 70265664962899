import { GET_ASSET_COMMENTS } from "../actions/commentsActions";

const initialState = {
  comments: [],
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_ASSET_COMMENTS:
      return { ...state, comments: payload };

    default:
      return state;
  }
};
