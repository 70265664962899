import React from "react";
import redTriangle from "../../Images/general_icons/redTriangle.svg";
import orangeTriangle from "../../Images/general_icons/orangeTriangle.svg";
import greenTriangle from "../../Images/general_icons/greenTriangle.svg";

import "./warningTriangle.scss";

const WarningTriangle = ({ type, table, minimal, noMargin }) => {
  const renderIconByType = () => {
    let src = null;

    switch (type) {
      case "red":
        src = redTriangle;
        break;
      case "orange":
        src = orangeTriangle;
        break;
      case "green":
        src = greenTriangle;
        break;

      default:
        break;
    }
    return src;
  };

  return (
    <div className={`WarningTriangle center ${type} ${minimal? 'minimal' : ''} ${noMargin? 'noMargin' : ''}`}>
      <img src={renderIconByType()} alt="warning icon" />
    </div>
  );
};

export default WarningTriangle;

// supported types =====> red || orange || green
