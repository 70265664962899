import React from "react";
import { useHistory } from "react-router";
import { useSelector } from "react-redux";
import AppModal from "../../../shared/appModal/appModal";
import { ReactComponent as WarningSign } from "../../../Images/general_icons/warningSign.svg";
import { ReactComponent as CloudX } from "../../../Images/general_icons/cloudX.svg";
import "./missingIntegrationsModal.scss";
import { providers } from "../../../utils/providers";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { clouds } from "../../../utils/icons";
import { providerNameFormatter } from "../../../utils/formatting";
import { capitalize, keys, map } from "lodash";
import { v4 as uuidv4 } from "uuid";

const MissingIntegrationsModal = ({ visible, handleClose, data }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const history = useHistory();
  return (
    <AppModal
      title={
        <span className="MissingIntegrationsModal__title row">
          <WarningSign />
          <span>Missing Integrations</span>
        </span>
      }
      visible={visible}
      handleClose={handleClose}
      bodyClassName="MissingIntegrationsModal col"
      width="35%"
      submitBtnText={`+ Add new integration`}
      handleOnSubmit={() => {
        const isSaas = data?.provider === "saas";
        const pathname = isSaas ? '/integrations' :`/integrations/${providers[data?.provider]?.integrationPath}-integration`
        history.push({
          pathname,
          state: data.provider === 'azurerm' ? data?.missingIntegrations?.[0] : null
        })
      }}
      destroyOnClose
    >
      <div className="MissingIntegrationsModal__summary">
        <div className="MissingIntegrationsModal__summary-section center">
          {keys(providers)?.includes(data?.provider) ? (
            <ProviderIcon provider={`plain${capitalize(data?.provider)}`} customStyle="modal" />
          ) : (
            <img
              alt="cloud"
              src={clouds(`plain${capitalize(data?.provider)}`, themeDark)}
              style={{
                height: "100%",
                transform: "translateY(2px)",
              }}
            />
          )}
        </div>

        <div className="MissingIntegrationsModal__summary-section col center">
          <span className="MissingIntegrationsModal__summary-section-title">{data?.count}</span>
          <span>Accounts</span>
        </div>
        <div className="MissingIntegrationsModal__summary-section col center">
          <span className="MissingIntegrationsModal__summary-section-title">{data?.missingIntegrations?.length || 0}</span>
          <span>Missing</span>
        </div>
      </div>
      <div className="MissingIntegrationsModal__text">
        {`We identified additional ${providerNameFormatter(data?.provider || '')} accounts that are declared in your IaC
        state but are not integrated with Firefly.`}
      </div>
      <div className="MissingIntegrationsModal__list col">
        {map(data?.missingIntegrations, (accountNumber) => (
          <span className="MissingIntegrationsModal__list-item row" key={uuidv4()}>
            <CloudX />
            <span>{accountNumber}</span>
          </span>
        ))}
      </div>
    </AppModal>
  );
};

export default MissingIntegrationsModal;
