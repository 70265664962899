import React, { useLayoutEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { toggleViewModal } from "../../redux/actions/screenViewActions";

// components
import PageName from "../../components/appHeader/pageName/pageName";
import BreadCrumbs from "../../components/appHeader/breadCrumbs/breadCrumbs";
import HeaderSearch from "../../components/appHeader/headerSearch/headerSearch";
import Feedback from "../../components/appHeader/feedback/feedback";
// import GuideBtn from "../../components/appHeader/guideBtn/guideBtn";
import UserConnected from "../../components/appHeader/userConnected/userConnected";
import ViewSwitcher from "../../components/appHeader/viewSwitcher/viewSwitcher";
import ViewManagerModal from "../../components/appHeader/viewManagerModal/viewManagerModal";
import OrganizationSelect from '../../components/appHeader/organizationSelect/organizationSelect';
import "./appHeader.scss";

const AppHeader = () => {
  const location = useLocation();
  const dispatch = useDispatch();

  const visibleSearchRoutes = ["/iac"];
  const visibleViewSelectRoutes = ["/inventory"];
  const [showHeaderSearch, setShowHeaderSearch] = useState(false);
  const [showViewSelect, setShowViewSelect] = useState(false);

  const viewModalOpen = useSelector(
    (state) => state.screenViewReducer.viewModalOpen
  );

  useLayoutEffect(() => {
    const pathName = location?.pathname || '';
    const isVisibleSearch = !!(visibleSearchRoutes.find((route) => pathName.includes(route)));
    setShowHeaderSearch(isVisibleSearch);
    setShowViewSelect(visibleViewSelectRoutes.includes(location?.pathname));
    return;
  }, [location?.pathname]);

  return (
    <div className={`AppHeader row between`}>
      <div className="AppHeader__routerIndication col">
        <PageName />
        <BreadCrumbs />
      </div>

      <div className="AppHeader__actions row">
        {showHeaderSearch && <HeaderSearch />}
        {showViewSelect && (
          <ViewSwitcher
            openViewModal={() => dispatch(toggleViewModal(false))}
          />
        )}
        {/* <GuideBtn /> */}
        <Feedback /> 
        <OrganizationSelect />
        <UserConnected />
      </div>

      <ViewManagerModal
        visible={viewModalOpen?.open}
        handleClose={() => dispatch(toggleViewModal(false))}
        isEditMode={viewModalOpen?.edit}
      />
    </div>
  );
};

export default AppHeader;
