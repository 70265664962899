import React from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { getNameById } from "../../utils/helpers";

import "./exclusionsLabelIndicator.scss";

const ExclusionsLabelIndicator = ({ exclusions, allExclusions }) => {

  const renderExclusionName = () => {
    if (!_.isEmpty(allExclusions) && !_.isEmpty(exclusions)) {
      return (
        <div className="col ExclusionsLabelIndicator__list" >
          <span className="bold purple-text">Matching exclusion rules:</span>
          <ul>
          {_.map(exclusions || [], (i) => {
            return <li key={i}>{getNameById(i, allExclusions)}</li>;
          })}
          </ul>
        </div>
      );
    }
  };

  return (
    <Tooltip
      placement="top"
      title={_.isEmpty(exclusions) ? null : renderExclusionName()}
      style={{ cursor: _.isEmpty(exclusions) ? "default" : "pointer" }}
      overlayInnerStyle={{
        width: '220px'
      }}
    >
      <button className="ExclusionsLabelIndicator center">
        <FontAwesomeIcon icon={faEyeSlash} color={_.isEmpty(exclusions) ? 'rgba(0,0,0,0)' : '#9872FE'}/>
      </button>
    </Tooltip>
  );
};

export default ExclusionsLabelIndicator;
