import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import {
  formatAwsStringWithUnderscore,
  formatIacStatus,
  getAllProvidersArray,
  renderProviderTypeText,
  formatDeletedFilter,
  formatTitleByIacType,
} from "../../../utils/formatting";
import {
  getNameById,
  getProvideAccountNameByNum,
  getClassificationDescById,
} from "../../../utils/helpers";
import FilterBadge from "../../../shared/filterBadge/filterBadge";
import { Badge, Popover } from "antd";

import "./filtersRowBadges.scss";
import { CLOUD_PROVIDERS_SUPPORT } from "../../../consts/general";

const FiltersRowBadges = ({
  filterEnvironments,
  filterTypes,
  filterClassifications,
  filterExcludedAssets,
  filterRegions,
  filterState,
  filterStacks,
  filterYears,
  filterIacTypes,
  filterProviderTypes,
  filterOwners,
  filterTags,
  filterResourceGroups,
  filterDeleted,
  filterTerraformObjectFullAddress,
  handleRemoveFilter,
  moduleFromUrl,
  handleClearAllFilters,
}) => {
  const [filtersArr, setFiltersArr] = useState([]);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const environments = useSelector(
    (state) => state.environmentReducer.environments
  );

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  const excludedAssets = useSelector((state) => state.exclusionsReducer.rules);

  const orchestrators = useSelector(
    (state) => state.orchestratorReducer.orchestrators
  );

  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  useEffect(() => {
    arrangeFilters();
  }, [
    filterEnvironments,
    filterTypes,
    filterClassifications,
    filterExcludedAssets,
    filterIacTypes,
    filterRegions,
    filterState,
    filterStacks,
    filterYears,
    filterProviderTypes,
    filterOwners,
    filterTags,
    filterResourceGroups,
    filterDeleted,
    filterTerraformObjectFullAddress,
    searchVal,
    moduleFromUrl,
    screenFilters,
  ]);
  const handleFormatFilterAccountName = (account) => {
    const allAccounts = getAllProvidersArray(providerIntegrations);
    return getProvideAccountNameByNum(account, allAccounts, true);
  };

  const arrangeFilters = () => {
    let newFilters = [];
    
    newFilters = _.union(
      newFilters,
      _.map(filterEnvironments || [], (filter) => ({
        id: filter,
        type: "environment",
        text: getNameById(filter, [...environments, ...orchestrators]),
        onRemove: () => handleRemoveFilter("environment", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterTypes || [], (filter) => ({
        id: filter,
        type: "resource_type",
        text: formatAwsStringWithUnderscore(filter),
        onRemove: () => handleRemoveFilter("resource_type", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterClassifications || [], (filter) => ({
        id: filter,
        type: "classification",
        text: getNameById(filter, classifications),
        onRemove: () => handleRemoveFilter("classification", filter),
        tooltip: getClassificationDescById(filter, classifications),
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterExcludedAssets || [], (filter) => ({
        id: filter,
        type: "excludedAsset",
        text: getNameById(filter, excludedAssets),
        onRemove: () => handleRemoveFilter("excludedAsset", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterIacTypes || [], (filter) => ({
        id: filter,
        type: "iacType",
        text: formatTitleByIacType(filter),
        onRemove: () => handleRemoveFilter("iacType", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterRegions || [], (filter) => ({
        id: filter,
        type: "regions",
        text: filter,
        onRemove: () => handleRemoveFilter("regions", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(screenFilters?.state || [], (filter) => ({
        id: filter,
        type: "state",
        text: formatIacStatus(filter),
        onRemove: () => handleRemoveFilter("state", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterStacks || [], (filter) => ({
        id: filter?.id,
        type: "stacks",
        text: filter?.name,
        onRemove: () => handleRemoveFilter("stacks", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterYears || [], (filter) => ({
        id: filter,
        type: "years",
        text: filter,
        onRemove: () => handleRemoveFilter("years", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterProviderTypes?.providerType || [], (filter) => ({
        id: filter,
        type: "providerType",
        text: renderProviderTypeText(filter),
        onRemove: () => handleRemoveFilter("providerType", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterProviderTypes?.providerSubType || [], (filter) => ({
        id: filter,
        type: "providerType",
        text: renderProviderTypeText(filter),
        onRemove: () => handleRemoveFilter("providerType", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterProviderTypes?.provider || [], (filter) => ({
        id: filter,
        type: "providerType",
        text: renderProviderTypeText(filter),
        onRemove: () => handleRemoveFilter("providerType", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterProviderTypes?.integrationId || [], (filter) => ({
        id: filter,
        type: "providerType",
        text: handleFormatFilterAccountName(filter),
        onRemove: () => handleRemoveFilter("providerType", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterOwners || [], (filter) => ({
        id: filter,
        type: "owners",
        text: filter,
        onRemove: () => handleRemoveFilter("owners", filter),
        tooltip: null,
      }))
    );

    newFilters = _.union(
      newFilters,
      _.map(filterTags || [], (filter) => ({
        id: filter,
        type: "tags",
        text: filter,
        onRemove: () => handleRemoveFilter("tags", filter),
        tooltip: null,
      }))
    );
    newFilters = _.union(
      newFilters,
      _.map(filterResourceGroups || [], (filter) => ({
        id: filter,
        type: "resourceGroups",
        text: filter,
        onRemove: () => handleRemoveFilter("resourceGroups", filter),
        tooltip: null,
      }))
    );

    if (screenFilters?.git) {
      newFilters.push({
        id: "git",
        type: "git",
        text: "Git",
        onRemove: () => handleRemoveFilter("git"),
        tooltip: null,
      });
    }
    if (screenFilters?.history) {
      newFilters.push({
        id: "history",
        type: "history",
        text: "History",
        onRemove: () => handleRemoveFilter("history"),
        tooltip: null,
      });
    }
    if (screenFilters?.comments) {
      newFilters.push({
        id: "comments",
        type: "comments",
        text: "Comments",
        onRemove: () => handleRemoveFilter("comments"),
        tooltip: null,
      });
    }
    if (screenFilters?.misconfiguration) {
      newFilters.push({
        id: "misconfiguration",
        type: "misconfigurations",
        text: "Misconfiguration",
        onRemove: () => handleRemoveFilter("misconfiguration"),
        tooltip: null,
      });
    }
    if (screenFilters?.reliability) {
      newFilters.push({
        id: "reliability",
        type: "reliability",
        text: "Reliability",
        onRemove: () => handleRemoveFilter("reliability"),
        tooltip: null,
      });
    }
    if (screenFilters?.optimization) {
      newFilters.push({
        id: "optimization",
        type: "optimization",
        text: "Optimization",
        onRemove: () => handleRemoveFilter("optimization"),
        tooltip: null,
      });
    }
    if (screenFilters?.excluded) {
      newFilters.push({
        id: "excluded",
        type: "excluded",
        text: "Show Excluded",
        onRemove: () => handleRemoveFilter("excluded"),
        tooltip: null,
      });
    }
    if (screenFilters?.vcsId) {
      newFilters.push({
        id: "vcsId",
        type: "vcsId",
        text: screenFilters?.vcsId,
        onRemove: () => handleRemoveFilter("vcsId"),
        tooltip: null,
      });
    }
    if (screenFilters?.vcsRepo) {
      newFilters.push({
        id: "vcsRepo",
        type: "vcsRepo",
        text: screenFilters?.vcsRepo,
        onRemove: () => handleRemoveFilter("vcsRepo"),
        tooltip: null,
      });
    }
    newFilters = _.union(
      newFilters,
      _.map(filterDeleted || [], (filter) => ({
        id: filter,
        type: "deleted",
        text: formatDeletedFilter(filter),
        onRemove: () => handleRemoveFilter("deleted", filter),
        tooltip: null,
      }))
    );
    newFilters = _.union(
      newFilters,
      _.map(filterTerraformObjectFullAddress || [], (filter) => ({
        id: filter,
        type: "terraformObjectFullAddress",
        text: filter,
        onRemove: () => handleRemoveFilter("terraformObjectFullAddress", filter),
        tooltip: null,
      }))
    );

    if (!_.isEmpty(searchVal)) {
      newFilters.push({
        id: "searchVal",
        type: "search",
        text: searchVal,
        onRemove: () => handleRemoveFilter("search"),
        tooltip: null,
      });
    }
    if (screenFilters?.module) {
      newFilters.push({
        id: screenFilters.module,
        type: "module",
        text: screenFilters.module,
        onRemove: () => handleRemoveFilter("module"),
        tooltip: null,
      });
    }
    return setFiltersArr(newFilters);
  };

  const getFilteredBadgesArray = (array) =>{
    return array?.filter(item => !CLOUD_PROVIDERS_SUPPORT.includes(item.id) && !providerIntegrations?.gcp?.some(integration => integration?.folder === item.id));
  }

  const renderFilterBadges = () => {
    const grouped = _.groupBy(filtersArr, "type");
    let badges = [];
    for (const filterType in grouped) {
      let filteredBadges = getFilteredBadgesArray(grouped[filterType]);
      if (filteredBadges?.length >= 3) {
        badges.push(
          <Popover
            key={typesKeyNames[filterType]}
            overlayClassName="FiltersRowBadges__popover"
            content={
              <div className="FiltersRowBadges__popover-wrapper">
                <div>
                  <h5>Selected {typesKeyNames[filterType]}</h5>
                </div>
                <div className="FiltersRowBadges__popover-wrapper__items">
                  {filteredBadges.map((item) => (
                    <FilterBadge
                      text={item?.text}
                      type={item?.type}
                      onClick={item?.onRemove}
                      key={item?.text}
                      tooltip={item?.tooltip}
                    />
                  ))}
                </div>
              </div>
            }
            placement="bottomLeft">
            <Badge
              count={filteredBadges.length}
              overflowCount={9}
              size="small"
              style={{ fontSize: 10, backgroundColor: "#9872FE" }}>
              <FilterBadge text={typesKeyNames[filterType]} type={filterType} onClick={() => handleRemoveFilter(filterType, null, true)}/>
            </Badge>
          </Popover>
        );
      } else {
        let filteredBadges = getFilteredBadgesArray(grouped[filterType]);
        badges = badges.concat(
          _.map(filteredBadges || [], (item) => {
            return (
              <FilterBadge
                text={item?.text}
                key={item?.text}
                type={item?.type}
                tooltip={item?.tooltip}
                onClick={item?.onRemove}
              />
            );
          })
        );
      }
    }
    return badges;
  };
  return (
    <div className="FiltersRowBadges row">
      {renderFilterBadges()}
    </div>
  );
};

const typesKeyNames = {
  providerType: "Data Sources",
  regions: "Locations",
  owners: "Owners",
  excludedAsset: "Excluded Assets",
  ["resource_type"]: "Asset Types",
  tags: "Tags",
  resourceGroups: "Resource Groups",
  iacType: "IaC Types",
  terraformObjectFullAddress: "Terraform Object Full Address",
};

export default FiltersRowBadges;
