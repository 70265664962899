import React, { useState, useCallback } from "react";
import AppToggle from "../../../shared/appToggle/appToggle";
import { useDispatch } from "react-redux";
import { toggelEnableInsight } from "../../../redux/actions/insightsV2Actions";
import has from "lodash/has";
import Spin from "antd/lib/spin";
import { LoadingOutlined } from '@ant-design/icons';

const AssetEnableCell = ({ value, id }) => {
  const dispatch = useDispatch();
  const [enable, setEnable] = useState(value || false);
  const [loading, setLoading] = useState(false);
  const loadingIcon = <LoadingOutlined style={{ fontSize: 18 }} />;

  const handleToggleEnabled = useCallback(async (e) => {
    e.stopPropagation();
    setLoading(true);
    const req = await dispatch(toggelEnableInsight(id));
    setLoading(false);
    if (req && has(req, "isEnabled")) {
      setEnable(req?.isEnabled);
    }
  }, [value, enable, id]);

  return loading ? (
    <Spin indicator={loadingIcon}/>
  ) : (
    <AppToggle
      checked={enable}
      toggleChecked={handleToggleEnabled}
    />
  );
};

export default AssetEnableCell;
