import React, { useState, useEffect } from 'react';
import moment from 'moment';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import NewAppModal from '../../../shared/newAppModal/newAppModal';
import CiProgress from './ciProgress';
import CiPolicyCostSummary from './ciPolicyCostSummary';
import PostApply from './postApply/postApply';
import PostPlan from './postPlan/postPlan';
import { timeDifferenceInWords } from '../../../utils/formatting';
import PostPlanConfig from './postPlanGraph/postPlanConfig/postPlanConfig';
import CiCode from './ciCode/ciCode';

import './ciModal.scss';

const CiModal = ({ runData = {}, modalVisible, setModalVisible }) => {
    const {  plan = [], apply = [], createdAt: startedAt = "", workspaceName = "", commitId = "", commitUrl = "", pullRequestId = '', pullRequestUrl = '', status = '', planTaskId: codeId = '' } = runData;
    const planSummary = plan[0] || {};
    const applySummary = apply[0] || {};

    const { id: planId = '', costResultsSummary = {}, scanResultsSummary = {}, parsedDataSummary: planParsedDataSummary = {}, changedFilesCodeSummary = {} } = planSummary;
    const { id: applyId = '', parsedDataSummary: applyParsedDataSummary = {} } = applySummary;
    const filesCount = changedFilesCodeSummary?.filesCount || 0;

    const defaultStep = status.includes('apply') ? 2 : 1;
  
    const [currentStep, setCurrentStep] = useState(defaultStep);
    const [openConfig, setOpenConfig] = useState(false);
    const [openInTab, setOpenInTab] = useState("");
    const [selectedResource, setSelectedResource] = useState({});

    const { t } = useTranslation("ci-pipeline", { keyPrefix: "postPlan" });

    const isCodeCurrentStep = currentStep === 0;
    const isPlanCurrentStep = currentStep === 1;

    useEffect(() => {
        setOpenConfig(false);
        setSelectedResource({});
        setOpenInTab("");
    }, [currentStep]);

    const handleOpenConfig = (data, triggerOpenTab = "") => {
        setOpenInTab(triggerOpenTab);
        setSelectedResource(data);
        setOpenConfig(true);
    }

    const renderApplyTitle = () => {
        const isApplyFailed = !!applyParsedDataSummary?.apply?.isFailed;
        return <div className="row g5">
            <FontAwesomeIcon icon={isApplyFailed ? "times-circle" : "check-double"} className={isApplyFailed ? "CiModal__modal-icon-failed" : "CiModal__modal-icon-success"} />
            <span>APPLY {isApplyFailed ? "FAILED" : "COMPLETED"}</span>
            <span className="sub-title CiModal__subTitle">{timeDifferenceInWords("", moment(startedAt), moment())}</span>
        </div>
    }
    
    const renderModalTitle = () => {
        const isPR = !!(pullRequestId && pullRequestUrl);
        const shortCommitId = commitId.slice(0, 6);
        const title = `Terraform Workspace - ${workspaceName}`;
        const link = isPR ? pullRequestUrl : commitUrl;
        const idToShow = isPR ? pullRequestId : shortCommitId;
        return <div className='row g5'>
            <span className="bold">{title}</span>
            <div className="row g5">
                  <span>{`(#${idToShow})`}</span>
                  <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                      e.stopPropagation();
                      window.open(link, "_blank");
                    }}/>
            </div>
        </div>
    }
    return (
      <NewAppModal
        visible={modalVisible}
        handleClose={() => setModalVisible(false)}
        destroyOnClose
        centered
        width={"85vw"}
        bodyClassName="CiModal__modal"
        title={renderModalTitle()}
        subtitle=""
        iconSrc={null}
        footer={null}
        headerSuffix={null}
      >      
            <div className="CiModal__modal-leftSide col">
                <CiProgress onChangeStep={(step) => setCurrentStep(step)} planId={planId} applyId={applyId} codeIsEnabled={codeId && filesCount > 0} defaultStep={defaultStep}/>
                {isPlanCurrentStep && <CiPolicyCostSummary data={{ costResultsSummary, scanResultsSummary }} />}
            </div>
            <div className={`CiModal__content ${openConfig ? 'hiddenOverflow' : ''}`}>
                <div className="CiModal__content-header row between">
                    <span className="CiModal__content-header-title bold">
                        {(isPlanCurrentStep || isCodeCurrentStep) ? t(`title`) : renderApplyTitle() }
                    </span>
                </div>
                <div className="CiModal__content-body">
                    {isCodeCurrentStep ? <CiCode codeId={codeId} filesCount={filesCount} />
                    : isPlanCurrentStep ? <PostPlan planId={planId} parsedDataSummary={planParsedDataSummary?.plan} handleOpenConfig={handleOpenConfig}/>
                    : <PostApply applyId={applyId} parsedDataSummary={applyParsedDataSummary?.apply} handleOpenConfig={handleOpenConfig} />}
                </div>
                {openConfig &&
                    <PostPlanConfig
                      visible={openConfig}
                      onClose={() => setOpenConfig(false)}
                      data={selectedResource}
                      defaultTab={openInTab}
                    />}
            </div>
     </NewAppModal>
    )
};

export default CiModal;