import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import { v4 as uuidv4 } from 'uuid';
import PostPlanTable from '../postPlanTable';
import PostPlanGraph from '../postPlanGraph/postPlanGraph';
import ActionsSummary from '../actionsSummary/actionsSummary';
import { useDispatch } from 'react-redux';
import { getWorkflowResources } from '../../../../redux/actions/ciWorkflowsActions';
import AppEmpty from '../../../../shared/appEmpty/appEmpty';
import DiagnosticArr from '../postApply/diagnostic/diagnosticArr';

const MAX_GRAPH_LENGTH = 100;
const MODES = {
    table: "table",
    graph: "graph",
}
const PostPlan = ({ planId, parsedDataSummary = {}, handleOpenConfig }) => {
    const { notNoOpResourcesCount: planResourcesCount = 0, hasDiagnosticResources = false, diagnostics: globalDiagnostics = [] } = parsedDataSummary;
    const defaultMode = planResourcesCount > MAX_GRAPH_LENGTH ? MODES.table : MODES.graph;
    
    const [dataByMode, setDataByMode] = useState({});
    const [loadingByMode, setLoadingByMode] = useState({});
    const [planPageNumber, setPlanPageNumber] = useState(1);
    const [planPageSize, setPlanPageSize] = useState(10);
    const dispatch = useDispatch();

    useEffect(() => {
        fetchRunData(defaultMode); // usually graph
    }, []);

    useEffect(() => {
        if (dataByMode?.table) {
            fetchRunData(MODES.table);
        }
    }, [planPageSize, planPageNumber]);

    useEffect(() => {
        if (dataByMode?.graph && !dataByMode?.table) { // once graph is fetched, fetch table
            fetchRunData(MODES.table);
        }
    }, [dataByMode]);

    const fetchRunData = async (mode) => {
        if (planResourcesCount == 0 && !hasDiagnosticResources) return;
        setLoadingByMode({ ...loadingByMode, [mode]: true });
        const options = { mode };
        if (mode === MODES.table) {
            const newOffset = (planPageNumber - 1) * planPageSize;
            options.offset = newOffset > planResourcesCount ? 0 : newOffset;
            options.size = planPageSize;
        }
        const response = await dispatch(getWorkflowResources(planId, options));
        if (response) {
            setLoadingByMode({ ...loadingByMode, [mode]: false });
            return setDataByMode({ ...dataByMode, [mode]: response.resources });
        } 
        setLoadingByMode({ ...loadingByMode, [mode]: false });
    };

    if ((planResourcesCount == 0 && !hasDiagnosticResources) || (!loadingByMode.graph && !loadingByMode.table && isEmpty(dataByMode?.graph?.nodes) && isEmpty(dataByMode?.table))) {
        return (
          <div className="PostPlanChart center empty">
            <AppEmpty customStyle="code" text="The current Terraform plan does not contain any infrastructure changes" />
          </div>
        );
    }

    return (
        <div className="PostPlan col g10">
           <ActionsSummary data={parsedDataSummary} />
           {defaultMode === "graph" && <PostPlanGraph data={dataByMode?.graph} loading={loadingByMode?.graph} handleOpenConfig={handleOpenConfig}/> }
           <PostPlanTable data={dataByMode?.table} loading={loadingByMode?.table} 
             parsedDataSummary={parsedDataSummary} pageSize={planPageSize} setPageSize={setPlanPageSize}
             setPageNumber={setPlanPageNumber} handleOpenConfig={handleOpenConfig} hasDiagnosticResources={hasDiagnosticResources} /> 
           {globalDiagnostics.length ? 
            <div className="col g10">
                <span className="bold">Global diagnostics</span>
                <DiagnosticArr diagnostics={globalDiagnostics}/>
            </div>
            : null}
        </div>
    );
};
export default PostPlan;