import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { getAccountIntegrations } from "../../../redux/actions/onbordingActions";
import map from "lodash/map";
import Loading from "../../../shared/loading/loading";
import IntegrationCard from "../integrationCard/integrationCard";
import { integrationIcons, clouds } from "../../../utils/icons";
import { findIntegrationTypeByKeyVal } from "../../../utils/helpers";
import "./gitStep.scss";
import { VCS_TYPES } from "../../../consts/general";

const GitStep = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(true);
  const integrations = useSelector(
    (state) => state.onbordingReducer.integrations?.git
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    handleFetchProviders();
  }, []);

  const handleFetchProviders = async () => {
    await dispatch(getAccountIntegrations());
    setLoading(false);
  };

  const providers = [
    {
      icon: clouds("github", themeDark),
      title: "GitHub",
      onClick: () => history.push("/integrations/github-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "type", "github"),
    },
    {
      icon: integrationIcons["gitlab"],
      title: "GitLab",
      onClick: () => history.push("/integrations/gitlab-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "type", "gitlab"),
    },
    {
      icon: integrationIcons["bitbucket"],
      title: "Bitbucket",
      onClick: () => history.push("/integrations/bitbucket-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "type", "bitbucket"),
    },
    {
      icon: integrationIcons["codecommit"],
      title: "AWS CodeCommit",
      onClick: () => history.push("/integrations/codecommit-integration"),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "type", "codecommit"),
    },
    {
      icon: integrationIcons[VCS_TYPES.azuredevops],
      title: "Azure DevOps",
      onClick: () => history.push(`/integrations/${VCS_TYPES.azuredevops}-integration`),
      coming_soon: false,
      connected: findIntegrationTypeByKeyVal(integrations, "type", VCS_TYPES.azuredevops),
    },
  ];

  const renderProviders = () => {
    return map(providers, (item) => {
      return (
        <IntegrationCard
          key={item?.title}
          icon={item?.icon}
          title={item?.title}
          onClick={item?.onClick}
          coming_soon={item?.coming_soon}
          connected={item?.connected}
        />
      );
    });
  };
  return (
    <div className="GitStep col">
      {loading ? (
        <div className="tab-page center">
          <Loading />
        </div>
      ) : (
        <div className="GitStep__list">{renderProviders()}</div>
      )}
    </div>
  );
};

export default GitStep;
