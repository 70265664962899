import React from "react";
import { useHistory } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./stepLink.scss";

const StepLink = ({
  text,
  isActive,
  done,
  number,
  currentStep,
  navigateToPrevSteps,
  centered,
  customStyle
}) => {
  const history = useHistory();
  const handleClick = () => {
    // onboarding
    if (navigateToPrevSteps !== null) {
      return navigateToPrevSteps();
    }
    if (!done) {
      history.push({
        pathname: `/integrations/new-integration`,
      });
    }
  }

  const getCursorState = () => {
    if (customStyle) {
      if (done) {
        return "auto";
      }
      return "pointer";
    }
    if (currentStep >= number) {
      return "pointer";
    }
    return "not-allowed"
  }
  return (
    <div
      className={`StepLink col center ${isActive ? "active" : ""} ${
        done ? "done" : ""
      }`}
    >
      <div className={`StepLink__text col`} style={{ height: !centered ? "100%" : "auto" }}>
        <span className="StepLink__text-inner col">{text}</span>
      </div>
      <span className={`StepLink__number`} onClick={handleClick}>
        <span
          className={`StepLink__number-inner center`}
          style={{ cursor: getCursorState() }}
        >
          {done ? (
            <FontAwesomeIcon
              icon="check"
              style={{ fontSize: "12px", animation: "grow 0.35s" }}
            />
          ) : (
            <span style={{ animation: "grow 0.35s" }}>{number}</span>
          )}
        </span>
      </span>
    </div>
  );
};

export default StepLink;
