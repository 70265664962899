import {
    GET_CONSUL_INTEGRATIONS,
    CREATE_CONSUL_INTEGRATION,
    DELETE_CONSUL_INTEGRATION,
    EDIT_CONSUL_INTEGRATION,
    GET_CONSUL_DATACENTERS,
    GET_CONSUL_KEYS
  } from "../actions/consulIntegrationAction";
  
  const initialState = {
    integrations: [],
    workspaces: [],
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_CONSUL_INTEGRATIONS:
        return { ...state, integrations: payload.result };
  
      case CREATE_CONSUL_INTEGRATION:
        return { ...state };
  
      case DELETE_CONSUL_INTEGRATION:
        return { ...state };

      case EDIT_CONSUL_INTEGRATION:
          return {...state};
    
      case GET_CONSUL_DATACENTERS:
        return {...state};
      
      case GET_CONSUL_KEYS:
        return {...state}
      default:
        return state;
    }
  }
  