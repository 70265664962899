import React, { useMemo, memo } from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import compact from "lodash/compact";
import uniq from "lodash/uniq";
import isEmpty from "lodash/isEmpty";
import { clouds } from "../../../utils/icons";
import { addProviderAndNameToProviderIdAggs } from "../../../utils/helpers";

const DataSourceCell = ({ value = [], accounts = [] }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const accountsNewFormat = map(accounts || [], (i) => ({ value: i }));
  const extendedAccounts = useMemo(
    () =>
      addProviderAndNameToProviderIdAggs(
        accountsNewFormat,
        providerIntegrations
      ),
    [providerIntegrations]
  );

  const dataSources = useMemo(() => {
    const allDataSources = map(value, (i) => {
      const typeName = i || "";

      // Ignore elements that include the word "objects" thats old data
      if (!typeName.includes("objects")) {
        return typeName;
      }

      return null; // Returning null for ignored elements
    }).filter(Boolean); // Filter out null values
    return allDataSources;
  }, [value, accounts]);

  const renderDataSources = useMemo(() => {
    const resultArray = compact(uniq(dataSources));
    return map(resultArray || [], (i) => {
      return (
        <div className="row g5" key={i}>
          <img
            src={clouds(i, themeDark)}
            alt={i}
            height={i === "aws" ? "15px" : "100%"}
            style={{ maxHeight: "20px", width: "auto" }}
            width="auto"
          />
        </div>
      );
    });
  }, [dataSources, value, providerIntegrations]);

  const renderAccounts = useMemo(() => {
    return map(extendedAccounts || [], (i) => {
      return (
        <div className="row g8" key={i?.value}>
          <img
            src={clouds(i?.provider, themeDark)}
            alt={i?.provider}
            height={i?.provider === "aws" ? "15px" : "100%"}
            style={{ maxHeight: "20px", width: "auto" }}
            width="auto"
          />
          {i?.name}
        </div>
      );
    });
  }, [extendedAccounts, accounts, providerIntegrations]);

  return (
    <div className="AssetTypeCell row g15">
      {!isEmpty(extendedAccounts) ? renderAccounts : renderDataSources}
    </div>
  );
};

export default memo(DataSourceCell);
