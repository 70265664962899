import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import {
  createGithubIntegration, getGithubOrganizations
} from "../../../redux/actions/githubIntegrationActions";
import AppWizard from "../../../shared/appWizard/appWizard";
import GithubProviderSetup from "./githubProviderSetup";
import SuccessBox from "../../../shared/successBox/successBox";
import { clouds } from "../../../utils/icons";
import "./githubProviderIntegration.scss";
import GithubProviderOrganization from "./githubProviderOrganization";

const GithubProviderIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({
    accessToken: "",
    nickname: "GitHub Integration",
    organization: null,
    code: "",
    installation_id: "",
  });
  const [organizations, setOrganizations] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Set up GitHub integration",
      content_title: "Set up GitHub integration",
      content: (
        <GithubProviderSetup
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
          setWizardState={setWizardState}
        />
      ),
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const { accessToken } = wizardState;

        const organizationsReq = await dispatch(
          getGithubOrganizations(accessToken)
        );

        if (organizationsReq?.req?.ok) {
          setOrganizations(organizationsReq?.organizations);
          return setLoadingSubmit(false);
        } else {
          setLoadingSubmit(false);
          throw new Error("GitHub Organizations Error");
        }
      },
      valid: true,
    },
    {
      step_title: "Organization",
      step_description: "Choose organization",
      content_title: "Organization",
      content: (
        <GithubProviderOrganization
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
          setWizardState={setWizardState}
          organizations={organizations}
        />
      ),
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const { accessToken, nickname, organization } = wizardState;

        const gitHubReq = await dispatch(
          createGithubIntegration(nickname, organization, accessToken )
        );

        if(gitHubReq?.ok) {
          return setLoadingSubmit(false);
        } else {
          setLoadingSubmit(false);
          throw new Error("GitHub Integration creation Error");
        }
      },
      valid: true,
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="GithubProviderIntegration">
      <AppWizard
        iconSrc={clouds("github", themeDark)}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default GithubProviderIntegration;
