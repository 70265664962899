import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { appToast } from "../../shared/appToast/appToast";

export const GET_EXCLUSIONS = "GET_EXCLUSIONS";
export const CREATE_EXCLUSION = "CREATE_EXCLUSION";
export const DELETE_CUSTOM_EXCLUSIONS = "DELETE_CUSTOM_EXCLUSIONS";
export const UPDATE_EXCLUSION = "UPDATE_EXCLUSION";
export const ANALYZE_EXCLUDED_RULES = "ANALYZE_EXCLUDED_RULES";
export const TEST_EXCLUDED_RULE = "TEST_EXCLUDED_RULE";
export const RESET_TEST_RESULT = "RESET_TEST_RESULT";

const getExclusions = (withGhostRules, showError=true) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions?version=2&withGhostRules=${withGhostRules}`,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );
    const rules = await req.json();
    dispatch({
      type: GET_EXCLUSIONS,
      payload: { rules },
    });
  });
};

const createExclusion = (name, description, code, type, unknowns, labels, username) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions?version=2`,
      "POST",
      {
        name,
        description,
        code,
        type: type,
        unknowns,
        labels,
        username
      }
    );
    dispatch({
      type: CREATE_EXCLUSION,
      payload: {},
    });

    return req;
  });
};

const updateExclusion = (id, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/${id}?version=2`,
      "PUT",
      payload
    );
    return req;
  });
};

const analyzeExcludedRules = (
  defaultExclusions = true,
  customExclusions = true
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    if (!defaultExclusions && !customExclusions) {
      dispatch({
        type: ANALYZE_EXCLUDED_RULES,
        payload: { excludedResources: [] },
      });
    }
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions?version=2`,
      "POST"
    );
    const allExcludedResources = await req.json();

    const excludedResources = _.uniqWith(
      _.filter(
        allExcludedResources,
        (rule) =>
          rule.isDefault === defaultExclusions ||
          rule.isDefault === !customExclusions
      ),
      _.isEqual
    );

    dispatch({
      type: ANALYZE_EXCLUDED_RULES,
      payload: { excludedResources },
    });
  });
};

const testExclusionRule = (
  name,
  description,
  code,
  type,
  providerIds,
  unknowns,
  notify,
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/test?version=2`,
      "POST",
      {
        name,
        description,
        code,
        type,
        providerIds,
        unknowns,
      }
    );
    if (req?.status === 200){
      let result = await req.json();
      if (result?.showWarning) {
        appToast("warning", "", "We found no asset matching this rule against a sample of 5000 assets. However, there may be matching assets in the entire set of assets in the given scope. Please consider reducing the scope before evaluating.");
      } else if (notify) {
        appToast("success", "", "Rego code test passed successfully");
      }
      const excluded = result?.excluded || [];
      dispatch({
        type: TEST_EXCLUDED_RULE,
        payload: {},
      });
      return { req, result: excluded};
    }
  });
};

const deleteCustomExclusions = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/exclusions/${id}?version=2`,
      "DELETE"
    );

    dispatch({
      type: DELETE_CUSTOM_EXCLUSIONS,
      payload: { ok: req.ok },
    });
  });
};

const resetTestResult = () => {
  return action(async (dispatch) => {
    dispatch({
      type: RESET_TEST_RESULT,
      payload: {},
    });
  });
};

export {
  getExclusions,
  updateExclusion,
  analyzeExcludedRules,
  testExclusionRule,
  createExclusion,
  deleteCustomExclusions,
  resetTestResult,
};
