import React from "react";
import { v4 as uuidv4 } from "uuid";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import "./dropMenu.scss";

const DropMenu = ({ links }) => {
  if (isEmpty(links)) {
    return null;
  }

  const renderMenu = () => {
    return map(links, (item) => {
      return (
        <div
          className={`DropMenu__item row ${item?.disabled ? "disabled" : ""} ${item?.readonly ? 'readonly' : ''}`}
          key={uuidv4()}
          onClick={
            item?.disabled
              ? () => {
                  return false;
                }
              : item?.onClick
          }
        >
          {item?.icon && <span className="DropMenu__item-icon">{item.icon}</span>}
          {item?.text}
        </div>
      );
    });
  };

  return <div className="DropMenu">{renderMenu()}</div>;
};

export default DropMenu;
