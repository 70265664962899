import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { useAuth0 } from "@auth0/auth0-react";
import { organizationChange } from "../../../redux/actions/userPreferencesActions";
import { getOrganizationAccounts } from "../../../redux/actions/organizationActions";
import AppModal from "../../../shared/appModal/appModal";
import Loading from "../../../shared/loading/loading";
import "./organizationModal.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const OrganizationModal = ({ visible, handleClose }) => {
  const { user } = useAuth0();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const organizationAccounts = useSelector(
    (state) => state.organizationReducer.accounts
  );

  useEffect(() => {
    handleFetchOrganization();
  }, []);

  const formatUserOrganizationMetaData = () => {
    const accounts = user?.["https://infralight.co/organization"];
    const list = !_.isEmpty(accounts) ? accounts.slice(2, -2) : [];
    const listNoComma = !_.isEmpty(list) ? list.replaceAll("'", "") : [];
    const listNoSpaces = !_.isEmpty(listNoComma) ? listNoComma.replaceAll(" ", "") : [];
    const outputList = !_.isEmpty(listNoSpaces) ? listNoSpaces.split(",") : [];
    return outputList;
  };

  const handleFetchOrganization = async () => {
    setLoading(true);
    const organizationAccounts = formatUserOrganizationMetaData();
    await dispatch(getOrganizationAccounts(organizationAccounts));
    setLoading(false);
  };

  const handleChange = async (id) => {
    setLoading(true);
    const req = await dispatch(organizationChange(id));
    if (req.ok) {
      return window.location.reload(false);
    }
    return setLoading(false);
  };

  const renderAccountslist = () => {
    return _.map(organizationAccounts || [], (item) => {
      const isActive = user?.["https://infralight.co/account_id"] === item?._id;
      return (
        <div
          key={item?._id}
          className={`OrganizationModal__item row ${isActive ? "active" : ""}`}
          onClick={() => handleChange(item?._id)}
        >
          {
            <FontAwesomeIcon
              icon="check"
              style={{ opacity: isActive ? 1 : 0 }}
            />
          }
          {item?.name}
        </div>
      );
    });
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      bodyClassName="OrganizationModal col"
      title="Your organization"
      subtitle="Click on a row to switch between your accounts"
      hideSubmitBtn
    >
      {loading ? (
        <div className="center" style={{ height: "200px" }}>
          <Loading />
        </div>
      ) : (
        renderAccountslist()
      )}
    </AppModal>
  );
};

export default OrganizationModal;
