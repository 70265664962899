import React, { useState, useEffect } from "react";
import { Button, Checkbox, Form, Input } from "antd";
import _ from "lodash";
import { validate, nicknameRegex } from "../../utils/validations";
import AppModal from "../../shared/appModal/appModal";
import { runFullScan } from "../../redux/actions/integrationsActions";
import { useDispatch } from "react-redux";
import { appToast } from "../../shared/appToast/appToast";
import MarkAsProduction from "../../shared/markAsProduction/markAsProduction";

// Edit integration api functions
import {
  editAwsIntegration,
  editSlackIntegration,
  editTeamsIntegration,
  editWebhookIntegration,
  editOpsgenieIntegration,
  editTorqIntegration,
  editPagerdutyIntegration,
  editProviderIntegration,
} from "../../redux/actions/integrationsActions";
import { editVcsIntegration } from "../../redux/actions/vcsActions";
import { editK8sIntegration } from "../../redux/actions/k8sIntegrationActions";
import { editAkamaiIntegration } from "../../redux/actions/akamaiIntegrationActions";
import { editDatadogIntegration } from "../../redux/actions/datadogIntegrationActions";
import { editGithubIntegration } from "../../redux/actions/githubIntegrationActions";
import { editOktaIntegration } from "../../redux/actions/oktaIntegrationActions";
import { editGcpProviderIntegration } from "../../redux/actions/gcpProviderIntegrationActions";
import { editGcpIntegration } from "../../redux/actions/gcpIntegrationActions";
import { editNewrelicIntegration } from "../../redux/actions/newrelicIntegrationActions";
import { editTfcIntegration } from "../../redux/actions/tfcIntegrationActions";
import { editConsulIntegration } from "../../redux/actions/consulIntegrationAction";
import { editJiraIntegration } from "../../redux/actions/jiraIntegrationActions";
import { editPulumiIntegration } from "../../redux/actions/pulumiIntegrationActions";
import { editCloudFlareIntegration } from "../../redux/actions/cloudFlareIntegrationActions";
import { PROVIDERS } from "../../consts/general";

const EditIntegration = ({ visible, handleClose, item, type, category, handleCloseRefreshEdit }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const layout = {};

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingScan, setLoadingScan] = useState(false);
  const [fetchChecked, setFetchChecked] = useState(false);
  const [isProd, setIsProd] = useState(false);
  const [formValid, setFormValid] = useState(true);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  useEffect(() => {
    setFetchChecked(item?.fetchable);
    setIsProd(item?.isProd);
  }, [item]);

  const handleCheckIfFormValid = (formValues) => {
    if (
      _.isEmpty(formValues.name) ||
      !validate(nicknameRegex.regex, formValues.name)
    ) {
      return setFormValid(false);
    }
    return setFormValid(true);
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    let req;
    let newName = values?.name || item?.name;
    let integType = type;
    let id = item?._id;
    let fetchable = item?.fetchable;

    // edit vcs
    if (category === "vcs") {
      integType === "githubvcs" ? "github" : integType;
      req = await dispatch(editVcsIntegration(id, newName, integType));
    }

    switch (integType) {
      case "aws":
        req = await dispatch(editAwsIntegration(id, newName, fetchable, isProd));
        break;
      case "gcs":
        req = await dispatch(editGcpIntegration(id, newName, fetchable));
        break;
      case "slack":
        req = await dispatch(editSlackIntegration(id, newName));
        break;
      case "teams":
        req = await dispatch(editTeamsIntegration(id, newName));
        break;
      case "k8s":
        req = await dispatch(editK8sIntegration(item?.clusterId, newName, fetchable, isProd));
        break;
      case "terraform":
        req = await dispatch(editTfcIntegration(id, newName));
        break;
      case "webhook":
        req = await dispatch(editWebhookIntegration(id, newName))
        break;
      case "consul":
        req = await dispatch(editConsulIntegration(id, newName))
        break;
      case "jira":
        req = await dispatch(editJiraIntegration(id, newName))
        break;
      case "pulumi":
        req = await dispatch(editPulumiIntegration(id, newName))
        break;
      case "opsgenie":
        req = await dispatch(editOpsgenieIntegration(id, newName))
        break;
      case "akamai":
        req = await dispatch(editAkamaiIntegration(id, newName))
        break;
      case "datadog":
        req = await dispatch(editDatadogIntegration(id, newName))
        break;
      case "github":
        req = await dispatch(editGithubIntegration(id, newName))
        break;
      case "okta":
        req = await dispatch(editOktaIntegration(id, newName))
        break;
      case "gcp":
        req = await dispatch(editGcpProviderIntegration(id, newName))
        break;
      case "newrelic":
        req = await dispatch(editNewrelicIntegration(id, newName))
        break;
      case "torq":
        req = await dispatch(editTorqIntegration(id, newName))
        break;
      case "cloudflare":
        req = await dispatch(editCloudFlareIntegration(id, newName))
        break;
      // PD notification
      case "pagerduty-notification":
        req = await dispatch(editPagerdutyIntegration(id, newName))
        break;
      // PD provider
      case "pagerduty":
        req = await dispatch(editProviderIntegration("pagerduty-provider", id, newName));
        break;
      case "ns1":
        req = await dispatch(editProviderIntegration(PROVIDERS.ns1, id, newName));
        break;
      case "mongodbatlas":
        req = await dispatch(editProviderIntegration(PROVIDERS.mongodbatlas, id));
        break;

      default:
        break;
    }
    setLoadingSubmit(false);
    if (!req) {
      return;
    }
    handleCloseRefreshEdit({name:newName, isProd});
  };

  const handleScan = async () => {
    let selected = "";
    switch (type) {
      case "aws":
        selected = "s3";
        break;

      case "gcs":
        selected = "gcs";
        break;

      default:
        appToast(
          "info",
          "State Scan Didn't Run",
          "State scan not supported on this integration type."
        );
        return;
    }

    if (selected) {
      setLoadingScan(true);
      const scanResult = await dispatch(runFullScan(item?.id, selected, true));

      setLoadingScan(false);
      if (!scanResult) {
        throw new Error(`Failed to scan integration for states.`);
      } else {
        appToast("success");
      }
    }
    setLoadingScan(false);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setLoadingSubmit(false);
    setLoadingScan(false);
    setFormValid(true);
    setFetchChecked(false);
    handleClose();
  };

  // const onEnter = (e) => {
  //   if (e?.key === "Enter" && formValid) {
  //     const values = form.getFieldsValue();
  //     handleSubmit(values);
  //   }
  // };

  return (
    <AppModal
      visible={visible}
      handleClose={handleCloseModal}
      title={`Edit ${_.capitalize(item?.type || "")} integration`}
      loadingSubmit={loadingSubmit}
      formName="edit-integration-form"
      submitBtnText="Save"
      submitBtnDisabled={!formValid}
      footerExtraItemOnRight={
        (type === "aws" || type === "gcs") && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingScan}
            className="app-form-button"
            disabled={!item?.fullScanEnabled}
            onClick={handleScan}
          >
            Run State Scan
          </Button>
        )
      }
    >
      <Form
        {...layout}
        name="edit-integration-form"
        form={form}
        className="EditIntegration"
        onFinish={handleSubmit}
        initialValues={{
          name: item?.name || "Choose a name",
        }}
        onValuesChange={(changedValues, allValues) => {
          handleCheckIfFormValid(allValues);
        }}
      >
        <Form.Item
          label="Name"
          name="name"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          hasFeedback
          rules={[
            {
              required: true,
              message: "Name is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          // onKeyUp={onEnter}
        >
          <Input />
        </Form.Item>
      </Form>
      {/* {_.isBoolean(item?.fetchable) && (
        <div className="fetchable-selection">
          <label className="form-label">
            Should Firefly fetch assets from this integration?
          </label>
          <AppToggle
            checked={fetchChecked}
            toggleChecked={() => {
              setFetchChecked(!fetchChecked);
            }}
            loading={false}
            disabled={item?.type !== "aws"}
          />
        </div>
      )} */}
      {type === "aws" || type === "k8s" ? (
        <div
          className="fetchable-selection"
          style={{ marginTop: "20px", marginBottom: "10px" }}
        >
          <Checkbox checked={isProd} onChange={() => setIsProd(!isProd)}>
            <MarkAsProduction edit />
          </Checkbox>
        </div>
      ) : null}
    </AppModal>
  );
};

export default EditIntegration;
