import React, { useEffect } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import CopyBox from "../../../shared/copyBox/copyBox";

const GithubProviderSetup = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const GITHUB_LINK = "https://github.com/settings/tokens/new";

  useEffect(() => {
    form.setFieldsValue({
      accessToken: wizardState?.accessToken,
    });
    checkValidation({
      accessToken: wizardState?.accessToken,
    });
  }, [wizardState]);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { accessToken } = formValues;

    if (_.isEmpty(accessToken)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="GithubProviderSetup col">
      <div className="GithubProviderSetup__intro col">
        <ul className="dark-blue GithubProviderSetup__intro-list">
          <li key="1">
            <div className="GithubProviderSetup__intro-list-item" style={{ paddingBottom: '2px'}}>
              Go{" "}
              <a
                target="_blank"
                href={GITHUB_LINK}
                rel="noreferrer"
                className="underline"
              >
                here
              </a>
              , to generate a personal access token with the following scopes:
            </div>
            <CopyBox
              text={`repo:status, repo_deployment, public_repo, repo:invite, security_events, read:org, read:public_key, read:repo_hook, admin:org_hook, notifications, read:user, user:email, read:discussion, read:enterprise, read:gpg_key`}
            />
          </li>
          <li key="2" style={{ paddingTop:'10px' }}>
            Copy the token, and paste it in the <span className="purple-text med-bold">Access Token</span> box.
          </li>
          <li key="3">
            Select <span className="purple-text med-bold">Next</span>.
          </li>
        </ul>
      </div>
      <Form
        {...layout}
        name="github-app-form"
        form={form}
        initialValues={{
          accessToken: "",
        }}
        className="GithubProviderSetup__form"
        onValuesChange={(allValues) => {
          setWizardState({ ...wizardState, ...allValues });
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Access Token"
          name="accessToken"
          rules={[
            {
              required: true,
              message: "Access Token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default GithubProviderSetup;
