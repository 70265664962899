import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { getInstructionsCode } from "../../../../redux/actions/keyPairsActions";
import { clouds } from "../../../../utils/icons";
import AppWizard from "../../../../shared/appWizard/appWizard";
import GcpProviderTerraformInstructions from "./gcpProviderTerraformInstructions";
import GcpFinalInstructions from "./gcpFinalInstructions";

import "./gcpProviderTerraformIntegration.scss";

const GcpProviderTerraformIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [wizardState, setWizardState] = useState({});
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const configWizard = [
    {
      step_title: "Configuration",
      step_description: "Modify default configuration values",
      content_title: "Terraform Provider Configuration",
      content: (
        <GcpProviderTerraformInstructions
          setSubmitDisabled={setSubmitDisabled}
          wizardState={wizardState}
          setWizardState={setWizardState}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const data = await dispatch(
          getInstructionsCode("gcp", { 
            name: wizardState?.name || "Google Cloud", 
            isEventDrivenDisabled: wizardState.isEventDrivenDisabled ?? false,
            isProd: wizardState.isProd ?? false,
            isIacAutoDiscoverDisabled: wizardState.isIacAutoDiscoverDisabled ?? false,
            regexExcludeProjectsDiscovery:  wizardState.regexExcludeProjectsDiscovery ?? [],
            projectId: wizardState.projectId || "",
          })
        );
        setWizardState({ ...wizardState, code: data?.code });
        setLoadingSubmit(false);
        if (_.isEmpty(data?.code)) {
          throw new Error("Failed to get instructions code. Please try again");
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "Final Instructions",
      content_title: "Terraform Provider Configuration",
      content: (
        <GcpFinalInstructions
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];
  return (
    <div className="GcpProviderTerraformIntegration">
      <AppWizard
        iconSrc={clouds("gcp", themeDark)}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        enableLastStepPrevious
      />
    </div>
  );
};

export default GcpProviderTerraformIntegration;
