import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { Input, Tabs, Form } from "antd";
import AppBtn from "../../../shared/appBtn/appBtn";
import AppModal from "../../../shared/appModal/appModal";
import Loading from "../../../shared/loading/loading";
import {
  queryBackendsData,
  setBackendRules,
} from "../../../redux/actions/iacStacksActions";
import ExclusionsTags from "./exclusionsTags";
import EventDrivenCode from "./eventDrivenCode";
import "./backendsTable.scss";
import GcsEncryption from "./gcsEncryption";

const BackendInfoModal = ({
  visible,
  handleCloseModal,
  rowId,
  form,
  activeTab,
  setActiveTab,
}) => {
  const { TabPane } = Tabs;
  const layout = {};
  const dispatch = useDispatch();

  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.iacStacksFilters
  );

  const [loadingTag, setLoadingTag] = useState("");
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState(null);
  const [disableAdd, setDisableAdd] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({ exclusion: "" });
      if (!isEmpty(rowId)) {
        getCrawlerData(true);
      }
    }
  }, [visible, rowId]);

  const getCrawlerData = async (initLoading) => {
    if (initLoading) {
      setLoading(true);
    }
    const backends = await dispatch(queryBackendsData(screenFilters));
    if (!isEmpty(backends?.hits)) {
      const crawler = backends?.hits?.find((cr) => cr?._id === rowId);
      setRowData(crawler || null);
    }
    if (initLoading) {
      setLoading(false);
    }
  };

  const keysByTypes = {
    s3: ["bucket", "key"],
    consul: ["datacenter", "key"],
    gcs: ["bucket", "path"],
  };

  const handleAddExclusion = async () => {
    setLoadingAdd(true);
    const exclusion = form.getFieldValue("exclusion");
    await dispatch(
      setBackendRules(rowId, exclusion, keysByTypes[rowData?.type])
    );
    form.setFieldsValue({ exclusion: "" });
    getCrawlerData();
    setLoadingAdd(false);
  };

  const handleRemoveExclusion = async (currentTag) => {
    setLoadingTag(currentTag);
    await dispatch(
      setBackendRules(
        rowId,
        currentTag,
        keysByTypes[rowData?.type],
        true
      )
    );
    getCrawlerData();
    setLoadingTag("");
  };

  return (
    <AppModal
      title="Backend Settings"
      visible={visible}
      handleClose={handleCloseModal}
      bodyClassName="BackendInfoModal"
      width="70%"
      centered
      hideSubmitBtn
    >
      {loading ? (
        <div className="full-page center">
          <Loading />
        </div>
      ) : (
        <Tabs
          defaultActiveKey="exclusions"
          onChange={(tab) => setActiveTab(tab)}
          activeKey={activeTab}
          tabBarStyle={{ padding: "0" }}
          className="BackendInfoModal"
        >
          <TabPane tab="Exclusions" key="exclusions">
            <div className="BackendInfoModal__exclusions col">
              <div className="BackendInfoModal__exclusions-add">
                <Form
                  {...layout}
                  name="exclusion-form"
                  form={form}
                  onValuesChange={(changedValues, allValues) => {
                    setDisableAdd(isEmpty(allValues?.exclusion));
                  }}
                >
                  <div className="BackendInfoModal__exclusions-add-container col">
                    <span className="bold">Add new exclusion pattern</span>
                    <div className="BackendInfoModal__exclusions-add-container-item row">
                      <span className="BackendInfoModal__exclusions-add-container-item-input">
                        <Form.Item
                          label=""
                          name="exclusion"
                          rules={[
                            {
                              required: true,
                              message: "Exclusion is required",
                            },
                          ]}
                          style={{ marginBottom: "0px" }}
                        >
                          <Input />
                        </Form.Item>
                      </span>
                      <span className="BackendInfoModal__exclusions-add-container-item-location row">
                        contain in location
                        <AppBtn
                          text="Add"
                          onClick={handleAddExclusion}
                          disabled={disableAdd}
                          loading={loadingAdd}
                        />
                      </span>
                    </div>
                  </div>
                </Form>
              </div>
              <div className="BackendInfoModal__exclusions-list col">
                <span className="bold">Excluded Patterns:</span>
                <ExclusionsTags
                  rowData={rowData}
                  keysByTypes={keysByTypes}
                  handleRemoveExclusion={handleRemoveExclusion}
                  loadingTag={loadingTag}
                />
              </div>
            </div>
          </TabPane>
          {rowData?.type === "s3" && (
            <TabPane tab="Event-driven" key="eventDriven">
              <EventDrivenCode rowId={rowId} />
            </TabPane>
          )}
          {rowData?.type === "gcs" && (
            <TabPane tab="Encryption" key="encryption">
              <GcsEncryption rowId={rowId} />
            </TabPane>
          )}
        </Tabs>
      )}
    </AppModal>
  );
};

export default BackendInfoModal;
