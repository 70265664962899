import React from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import PremiumBtn from "../../../shared/premiumBtn/premiumBtn";
import { useTranslation } from "react-i18next";
import "./accountTierBanner.scss";

const AccountTierBanner = () => {
  const { t } = useTranslation("sidebar");
  const tierType = useSelector(
    (state) => state?.onbordingReducer.account.tier_type
  );
  const licenseStart = useSelector(
    (state) => state.onbordingReducer.account?.license_start
  );
  const themeDark = useSelector((state) =>
    state.userPreferencesReducer.themeDark ? "dark" : "light"
  );
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  const freeTierImage = `https://firefly-app-assets.s3.amazonaws.com/account-tier-illustrations/robot_${themeDark}.svg`;
  const trialImage = `https://firefly-app-assets.s3.amazonaws.com/account-tier-illustrations/rocket_only_${themeDark}.svg`;
  const premiumlImage = `https://firefly-app-assets.s3.amazonaws.com/account-tier-illustrations/rocket_fly_${themeDark}.svg`;

  const getDaysLeft = () => {
    const trialEnd = moment(licenseStart).add(15, "days");
    let daysDiff = 0;
    // trial end is later than today
    if (trialEnd > moment()) {
      daysDiff = trialEnd.diff(moment(), "days");
    }
    return daysDiff;
  };

  let trialDays = getDaysLeft();

  switch (tierType) {
    case "FREE_TIER":
      if (!sidebarClosed) {
        return (
          <div className="AccountTierBanner center col">
            <img
              src={freeTierImage}
              alt="free"
              className="AccountTierBanner__img"
            />
            <div className="AccountTierBanner__content center col">
              <div className="AccountTierBanner__content-text col center">
                <span className="bold centered">{t('account-tier.free')}</span>
              </div>
              <PremiumBtn />
            </div>
          </div>
        );
      }
      return <PremiumBtn />;

    case "PREMIUM_TRIAL":
      if (!sidebarClosed) {
        return (
          <div className="AccountTierBanner center col">
            <img
              src={trialImage}
              alt="free"
              className="AccountTierBanner__img"
            />
            <div className="AccountTierBanner__content center col">
              {trialDays > 0 ? (
                <div className="AccountTierBanner__content-text col center">
                  <span className="bold centered">{t('account-tier.trial')}</span>
                  <span className="centered">{`Ends in ${trialDays} day${
                    trialDays > 1 ? "s" : ""
                  }`}</span>
                </div>
              ) : (
                <div className="AccountTierBanner__content-text col center">
                  <span className="bold centered">{t('account-tier.expired')}</span>
                </div>
              )}
              <PremiumBtn />
            </div>
          </div>
        );
      }
      return <PremiumBtn />;

    case "ENTERPRISE":
      if (!sidebarClosed) {
        return (
          <div className="AccountTierBanner center col">
            <img
              src={premiumlImage}
              alt="premium"
              className="AccountTierBanner__img"
            />
            <div className="AccountTierBanner__content center col">
              <div className="AccountTierBanner__content-text col center">
                <span className="bold centered">{t('account-tier.premium')}</span>
              </div>
            </div>
          </div>
        );
      }
      return null;

    default:
      return null;
  }
};

export default AccountTierBanner;
