import { CLOUD_PROVIDERS } from "../../consts/general";
import { formatAwsStringWithUnderscore } from "../../utils/formatting";

const getSaasMissingArray = (aggs) => {
    const missingSaas = [];
    Object.keys(aggs).forEach((provider) => {
        const isSaas = ![CLOUD_PROVIDERS.aws, CLOUD_PROVIDERS.k8s, CLOUD_PROVIDERS.azurerm, CLOUD_PROVIDERS.gcp].includes(provider);
        if (isSaas) {
            const mappedArray = (aggs[provider] || []).map((val = "") => `${formatAwsStringWithUnderscore(provider)} - ${val}`);
            missingSaas.push(...mappedArray);
        };
    });
    return missingSaas;
};

const getMissingIntegrationsValue =  (provider, missingAggs = {}, excludeMissingIntegrations, isSaas = false) => {
    const missingIntegrationsValue = isSaas ? getSaasMissingArray(missingAggs) : missingAggs[provider] || []
    if (!excludeMissingIntegrations.length) return missingIntegrationsValue;
    const filtteredMissingIntegration = missingIntegrationsValue.filter((integVal) => !excludeMissingIntegrations.find((excludeVal) => !isSaas ? excludeVal === integVal : excludeVal.includes(integVal)));
    return filtteredMissingIntegration
}

export {
    getMissingIntegrationsValue
}