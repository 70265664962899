import {
    CREATE_GITHUB_INTEGRATION,
    DELETE_GITHUB_INTEGRATION,
    EDIT_GITHUB_INTEGRATION,
    GET_GITHUB_INTEGRATIONS,
    GET_GITHUB_ORGANIZATIONS
  } from "../actions/githubIntegrationActions";
  
  const initialState = {
    integrations: [],
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_GITHUB_INTEGRATIONS:
        return { ...state, integrations: payload.integrations };
  
      case CREATE_GITHUB_INTEGRATION:
        return { ...state };
  
      case DELETE_GITHUB_INTEGRATION:
        return { ...state };
  
      case EDIT_GITHUB_INTEGRATION:
        return {...state};

      case GET_GITHUB_ORGANIZATIONS:
        return {...state};
  
      default:
        return state;
    }
  }
  