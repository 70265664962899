import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import { integrationIcons } from "../../../utils/icons";

import "./gitlabIntegration.scss";
import {
  createGitLabTokenIntegration,
} from "../../../redux/actions/vcsActions";
import GitlabConfiguration from "./gitlabConfiguration";

const GitlabIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Set up GitLab VCS",
      content_title: "GitLab version control system integration",
      content: (
        <GitlabConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);

        const result = await dispatch(
          createGitLabTokenIntegration(
            wizardState?.name,
            wizardState?.token,
            wizardState?.domain,
          )
        );
        setLoadingSubmit(false);
        if (!result?.ok) {
          throw new Error(`Failed to create GitLab integration.`);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="GitlabIntegration">
      <AppWizard
        iconSrc={integrationIcons["gitlab"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default GitlabIntegration;
