import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_ORGANIZATION_ACCOUNTS = "GET_ORGANIZATION_ACCOUNTS";

const getOrganizationAccounts = (accounts) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/organization/accounts`,
      "POST",
      { accounts },
      true,
      null,
      false
    );

    let data = [];

    if (req.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_ORGANIZATION_ACCOUNTS,
      payload: data,
    });
    return data;
  });
};

export { getOrganizationAccounts };
