import React from "react";
import { useSelector } from 'react-redux';
import CodeMirror from "@uiw/react-codemirror";
import { langs } from "@uiw/codemirror-extensions-langs";
import { tags as t } from "@lezer/highlight";
import { auraInit } from "@uiw/codemirror-theme-aura";

const CodeBlock = ({ height, editable, onChange, code, lang, customSettings }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  let extension = [];

  switch (lang) {
    case "js":
      extension = [langs.javascript()];
      break;
    case "json":
      extension = [langs.json()];
      break;
    case "yaml":
      extension = [langs.yaml()];
      break;
    default:
      extension = [langs.javascript()];
  }

  return (
    <CodeMirror
      value={code}
      height={height}
      editable={editable}
      theme={auraInit({
        settings: customSettings ? customSettings : {
          fontFamily: "monospace",
          background: themeDark ? "#181825" : "#30304A",
          gutterBackground: themeDark ? "#181825" : "#30304A",
          gutterForeground: "rgba(255, 255, 255, 0.5)",
        },
        styles: [{ tag: t.comment, color: "#6272a4" }],
      })}
      extensions={extension}
      width="100%"
      onChange={onChange}
    />
  );
};

export default CodeBlock;
