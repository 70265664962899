import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import AppWizard from "../../../shared/appWizard/appWizard";
import TFCCreation from "./tfcCreation";
import { iacProviders } from "../../../utils/icons";

import { createTfcIntegration } from "../../../redux/actions/tfcIntegrationActions";

import "./tfcIntegration.scss";
import SuccessBox from "../../../shared/successBox/successBox";

const TFCIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [integrationCreated, setIntegrationCreated] = useState(null);

  const handleSubmit = () => {
      return history.push("/integrations");
  };

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Create API token",
      content_title: "Terraform Cloud integration",
      content: (
        <TFCCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {

        setLoadingSubmit(true);
        const result = await dispatch(
          createTfcIntegration(wizardState.name, wizardState.apiToken, wizardState?.customDomain)
        );
        setLoadingSubmit(false);
        if (!result?.req?.ok) {
          throw new Error(`Failed to create TFC integration.`);
        }
        setIntegrationCreated(result?.integration);
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="TFCIntegration">
      <AppWizard
        iconSrc={iacProviders("terraform")}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        customDoneFunction={handleSubmit}
      />
    </div>
  );
};

export default TFCIntegration;
