import React from 'react';
import './iacInfoCards.scss'

const IacInsightCards = ({insights = [], type}) => {
    return (
        <div className="IacInfoCards" style={{gridTemplateColumns:`repeat(${insights.length}, ${type === 'cloudformation' ? 'minmax(200px, 200px)' : '1fr'})`}} >
            {insights?.map((insight) => (
                <div key={insight.title} className={insight.isActive ? 'active' : ''} onClick={insight.onClick} >
                    <span>{insight.title}</span>
                    <div>{insight.count}</div>
                </div>
            ))}
          
        </div>
    );
}

export default IacInsightCards;