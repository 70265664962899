import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const GET_WORKFLOWS_AGGS = "GET_WORKFLOWS_AGGS";
export const HANDLE_SET_CI_FILTERS = "HANDLE_SET_CI_FILTERS";
export const GET_CODE_FILES = "GET_CODE_FILES";

let abortWorkspacesController;
const getCiWorkspaces = (ciFilters, searchValue) => {
  if (abortWorkspacesController) {
    abortWorkspacesController.abort();
  }
  abortWorkspacesController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/ci/workspaces`,
        "POST",
        { filters: ciFilters, searchValue },
        undefined,
        undefined,
        true,
        null,
        abortWorkspacesController.signal
      );
      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      return data;
    }
    catch (err) {
      if (err.name === 'AbortError') {
        return { abort: true }
      }
      return {};
    }
  });
}

const getTasksByWorkspaceId = (workflowId, payload = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/${workflowId}/runs`,
      "POST",
      payload,
      true,
      null,
      false,
    );
    let data = null;
    if (req?.ok) {
      data = await req.json();
    }
    return data;


  });
}

const getWorkflowResources = (runId, options = {}) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/tasks/${runId}/resources/list`,
      "POST",
      options,
    );
    let data = null;
    if (req?.ok) {
      data = await req.json();
    }
    return data;
  })
};

const getWorkflowsAggs = (searchValue) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/ci/workspaces/aggs`,
      "POST",
      { searchValue },
      true,
      null,
      false,
    );
    let data = {};
    
    if (req?.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_WORKFLOWS_AGGS,
      payload: data,
    });
    return;
  })
};

const handleSetCiFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: HANDLE_SET_CI_FILTERS,
      payload: filters,
    });
  });
};

const getCodeFiles = (codeId, options = {}) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
          `${BACKEND_URL}/ci/tasks/${codeId}/changed-files-code/list`,
          "POST",
          options,
        );
        let data = {};
        if (req?.ok) {
         data = await req.json();
        }
        dispatch({
            type: GET_CODE_FILES,
            payload: data,
        });
        return data;
    })
};

export {
    getCiWorkspaces,
    getTasksByWorkspaceId,
    getWorkflowResources,
    getWorkflowsAggs,
    handleSetCiFilters,
    getCodeFiles,
};