import React, { useEffect, useState } from "react";
import _ from "lodash";
import { useDispatch } from "react-redux";
import { getCftDrift } from "../../../redux/actions/fixDriftActions";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import StackDrift from "./stackDrift/stackDrift";
import Loading from "../../../shared/loading/loading";
import {
  formatAwsStringWithUnderscore,
} from "../../../utils/formatting";
import "./fixCloudformationDrift.scss";

const FixCloudformationDrift = ({ close, visible, rowData }) => {
  const dispatch = useDispatch();
  const [loadingDriftData, setLoadingDriftData] = useState(false);
  const [driftData, setDriftData] = useState({});

  useEffect(() => {
    if (visible) {
      getDriftData();
    }
  }, [visible]);

  const getDriftData = async () => {
    setLoadingDriftData(true);
    const dataRes = await dispatch(getCftDrift(rowData?.frn));
    if (!dataRes?.ok) {
      return setLoadingDriftData(false);
    }
    setDriftData(dataRes?.data);
    setLoadingDriftData(false);
  };

  const handleCloseDrawer = () => {
    setDriftData({});
    setLoadingDriftData(false);
    close();
  };
  return (
    <AppDrawer
      title="Fix Drift"
      closeDrawer={handleCloseDrawer}
      visible={visible}
      width="80vw"
      zIndex={1001}
      subTitle={<div className="row" style={{ gap: '20px' }}>
      <span>
        Type:{" "}
        {!_.isEmpty(rowData?.assetType) &&
          formatAwsStringWithUnderscore(rowData?.assetType)}
      </span>
      <span>ID: {!_.isEmpty(rowData?.assetId) && rowData?.assetId}</span>
    </div>}
    >
      <div className="FixCloudformationDrift__content">
        {loadingDriftData && (
          <div className="tab-page center">
            <Loading />
          </div>
        )}
        {!_.isEmpty(driftData) && !loadingDriftData && (
          <div className="FixCloudformationDrift__content-success">
            {_.map(driftData, (driftObj, index) => (
              <StackDrift
                key={driftObj?.stackName + index}
                driftObj={driftObj}
              />
            ))}
          </div>
        )}
      </div>
    </AppDrawer>
  );
};

export default FixCloudformationDrift;
