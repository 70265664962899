import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const CREATE_PULUMI_INTEGRATION = "CREATE_PULUMI_INTEGRATION";
export const GET_PULUMI_INTEGRATIONS = "GET_PULUMI_INTEGRATIONS";
export const EDIT_PULUMI_INTEGRATION = "EDIT_PULUMI_INTEGRATION";
export const DELETE_PULUMI_INTEGRATION = "DELETE_PULUMI_INTEGRATION";
export const GET_PULUMI_STACKS = "GET_PULUMI_STACKS";

const createPulumiIntegration = (name, token, crawlerCreate) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pulumi`,
      "POST",
      {
        name,
        token,
        crawlerCreate
      }
    );

    dispatch({
      type: CREATE_PULUMI_INTEGRATION,
      payload: {},
    });

    const data = await req.json();

    return {req, integration: data?.integration};
  });
};

const getPulumiIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pulumi`,
      "GET"
    );

    const result = await integrationsReq.json();

    dispatch({
      type: GET_PULUMI_INTEGRATIONS,
      payload: { result },
    });

    return integrationsReq.ok;
  });
};

const editPulumiIntegration = (integration, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pulumi/${integration}`,
      "PUT",
      { name }
    );

    dispatch({
      type: EDIT_PULUMI_INTEGRATION,
      payload: {},
    });

    return req.ok;
  });
};

const deletePulumiIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/pulumi/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_PULUMI_INTEGRATION,
      payload: {},
    });

    return {};
  });
};

const getPulumiStacks = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/pulumi/${id}`,
      "GET"
    );

    const result = await integrationsReq.json();

    dispatch({
      type: GET_PULUMI_STACKS,
      payload: { result },
    });

    return integrationsReq.ok;
  });
};



export {
  createPulumiIntegration,
  getPulumiIntegrations,
  editPulumiIntegration,
  deletePulumiIntegration,
  getPulumiStacks
};
