import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import isEmpty from "lodash/isEmpty";
import { nicknameRegex, validate } from "../../../utils/validations";

const AzurePortal = ({
  wizardState = {},
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("azure-integration");

  useEffect(() => {
    // this check is for users that doing next-prev, to keep their wizard form state aligned
    checkValidation(wizardState);
  }, []);

  const checkValidation = (formValues) => {
    let formValid = true;
    const {
      name,
      subscriptionId,
      tenantId,
      applicationId,
      clientSecret,
      directoryDomain,
    } = formValues;

    if (
      isEmpty(name) ||
      isEmpty(subscriptionId) ||
      isEmpty(tenantId) ||
      isEmpty(applicationId) ||
      isEmpty(clientSecret) ||
      isEmpty(directoryDomain)
    ) {
      formValid = false;
    }

    if (!isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="AzurePortal col">
      <div className="AzurePortal__info col g20">
        <div className="col g8">
          <span className="bold font-16">{t("portal-info.title")}</span>
          <span>{t("portal-info.sub")}</span>
        </div>

        <ol className="col text g8">
          <li>
            <a
              href="https://portal.azure.com/#home"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {t("portal-info.1")}
            </a>
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.2"
              components={[<strong key="portal-info2" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.3"
              components={[<strong key="portal-info3"/>]}
            />
          </li>
          <li>
            <a
              href="https://portal.azure.com/#view/Microsoft_AAD_RegisteredApps/ApplicationsListBlade"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {t("portal-info.4")}
            </a>
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.5"
              components={[<strong key="portal-info5" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.6"
              components={[<strong key="portal-info6" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.7"
              components={[<strong key="portal-info7" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.8"
              components={[<strong key="portal-info8" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.9"
              components={[<strong key="portal-info9" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.10"
              components={[<strong key="portal-info10"/>]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.11"
              components={[<strong key="portal-info11" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.12"
              components={[<strong key="portal-info12"/>]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.13"
              components={[<strong key="portal-info13" />]}
            />
          </li>
          <li>
            {t("portal-info.14.title")}
            <ul className="col g5">
              <li>
                <b>{t("portal-info.14.1")}</b>
              </li>
              <li>
                <b>{t("portal-info.14.2")}</b>
              </li>
              <li>
                <b>{t("portal-info.14.3")}</b>
              </li>
            </ul>
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.15"
              components={[<strong key="portal-info15" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:portal-info.16"
              components={[<strong  key="portal-info16"/>]}
            />
          </li>
        </ol>
      </div>
      <Form
        name="azure-wizard-form"
        form={form}
        initialValues={{
          ...wizardState,
          name: wizardState.name || t("form.name-default-value"),
        }}
        className="AppWizard__content-body-form AzurePortal__form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label={t("form.name-label")}
          name="name"
          rules={[
            {
              required: true,
              message: t("form.name-error"),
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t("form.subscription-label")}
          name="subscriptionId"
          rules={[
            {
              required: true,
              message: t("form.subscription-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t("form.tenant-label")}
          name="tenantId"
          rules={[
            {
              required: true,
              message: t("form.tenant-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t("form.client-label")}
          name="applicationId"
          rules={[
            {
              required: true,
              message: t("form.client-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t("form.secret-label")}
          name="clientSecret"
          rules={[
            {
              required: true,
              message: t("form.secret-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          label={t("form.directory-label")}
          name="directoryDomain"
          rules={[
            {
              required: true,
              message: t("form.directory-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AzurePortal;
