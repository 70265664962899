import React, { useState, useEffect } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import SplashScreen from '../../shared/splashScreen/splashScreen';
import Lottie from "react-lottie";
import animationData from "../../components/appSidebar/appLogo/easy_animation_logo_icon.json";

import "./createAccount.scss";

const CreateAccount = () => {
  const { logout, user } = useAuth0();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    return postEmailAsCompayName();
  }, [user]);

  const postEmailAsCompayName = async () => {
    const requestWrapper = new RequestWrapper();
    
      try {
        await requestWrapper.sendRequest(`${BACKEND_URL}/account`, "PUT", {});
        return window.location.reload(false);
      } catch (error) {
        logout()
      }
    }

  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  if (loading) {
    return (
      <SplashScreen />
    );
  }

  return (
    <div id="create_account" className="CreateAccount center">
      <div className="CreateAccount__wrapper col">
        <div className="CreateAccount__wrapper-header col">
          <Lottie
            options={defaultOptions}
            height={50}
            width={50}
            isStopped={false}
            isPaused={false}
          />
        </div>
      </div>
    </div>
  );
};

export default CreateAccount;
