import React, { useEffect } from "react";
import { useDispatch } from "react-redux";

import { getAllRoles } from "../../redux/actions/profilesActions";

// components
import UsersList from '../../components/users/usersList/usersList';
import AccessKeys from '../../components/users/accessKeys/accessKeys';

import "./users.scss";

const Users = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    getRoles();
  }, []);

  const getRoles = async () => {
    await dispatch(getAllRoles());
  };

  return (
    <div className="basic-page users">
      <UsersList />
      <AccessKeys />
    </div>
  );
};

export default Users;
