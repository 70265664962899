import React from "react";
import prompts from "../../../containers/compose/prompts";
import _ from "lodash";
import "./providersList.scss";
import { useSelector } from "react-redux";

const ProvidersList = ({ onClick, selected }) => {
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  
  return (
    <div className="ProvidersList row g10">
      {_.map(prompts, (item) => (
        <div 
            onClick={() => onClick(item)}
            className={`ProvidersList__item center ${selected === item?.name ? 'active' : ''}`} 
            key={item?.name}>
          <img src={item?.icon || item?.iconByMode(themeDark) } alt={item?.name} />
        </div>
      ))}
    </div>
  );
};

export default ProvidersList;
