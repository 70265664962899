import _ from "lodash";
import {
  GET_ALL_CRAWLERS,
  GET_CRAWLERS,
  CLEAR_CRAWLERS,
} from "../actions/crawlerActions";

const initialState = {
  crawlers: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_CRAWLERS:
      return { ...state, crawlers: payload.data.crawlers };
    case GET_CRAWLERS:
      return { ...state, crawlers: payload.data };

    case CLEAR_CRAWLERS:
      return { ...state, crawlers: [] };

    default:
      return state;
  }
}
