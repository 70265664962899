import React, { useState, useMemo, useEffect } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { useTranslation } from "react-i18next";
import {
  createAzureIntegration,
  updateAzureIntegration,
} from "../../../redux/actions/azureIntegrationActions";
import { clouds, iacProviders, integrationIcons } from "../../../utils/icons";
import isEmpty from "lodash/isEmpty";
import SuccessBox from "../../../shared/successBox/successBox";
import AzurePortal from "./azurePortal";
// import AzureMethod from "./azureMethod";
import AzurePreferances from "./azurePreferances";
import AzurePowershell from "./azurePowershell";
import ControlledAppWizard from "../../../shared/appWizard/controlledAppWizard";
import "./azureIntegration.scss";

const AzureIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const { t } = useTranslation("azure-integration");
  const clientSecretEditDefault = "** Encrypted **";

  const [wizardState, setWizardState] = useState({
    method: "powershell",
    isProd: false,
    isEventDriven: true,
    isIacAutoDiscoverDisabled: false,
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [current, setCurrent] = useState(0);

  const isEditMode = useMemo(() => {
    return !isEmpty(history?.location?.state?._id);
  }, [history?.location?.state]);

  useEffect(() => {
    if (!isEmpty(history?.location?.state?._id)) {
      const integObj = history?.location?.state;
      setWizardState({
        ...integObj,
        clientSecret: clientSecretEditDefault,
        subscriptionId: integObj?.accountNumber,
      });
    }
  }, [history?.location?.state]);

  const renderStepIcon = useMemo(() => {
    // this is relevent when other integration options will be enbled
    // if(current === 0) {
    //   return;
    // }
    if (wizardState?.method === "powershell") {
      return integrationIcons["powershell"];
    }
    if (wizardState?.method === "form") {
      return clouds("azure");
    }
    if (wizardState?.method === "terraform") {
      return iacProviders("terraform");
    }
  }, [current, wizardState?.method]);

  const renderSetupBlockByMethod = () => {
    if (wizardState?.method === "form") {
      return (
        <AzurePortal
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isEditMode={isEditMode}
        />
      );
    }
    if (wizardState?.method === "powershell") {
      return (
        <AzurePowershell
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isEditMode={isEditMode}
        />
      );
    }

    return;
  };

  const handleSubmitWizard = async () => {
    setLoadingSubmit(true);
    let req;

    if(isEditMode) {
      // client secret value when edit mode
      const payloadClientSec =
      wizardState?.clientSecret === clientSecretEditDefault
        ? null : wizardState?.clientSecret;

      const editPayload = {...wizardState, clientSecret: payloadClientSec};

        req = await dispatch(updateAzureIntegration(editPayload));
    } else {
        req = await dispatch(createAzureIntegration(wizardState));
    }
    setLoadingSubmit(false);
    if (!req?.ok) {
      throw new Error(
        `Failed to ${isEditMode ? "update" : "create"} Azure integration.`
      );
    }
  };

  const configWizard = [
    {
      step_title: isEditMode
        ? t("preferences.nav-title-update")
        : t("preferences.nav-title"),
      step_description: isEditMode
        ? t("preferences.nav-sub-update")
        : t("preferences.nav-sub"),
      content_title: t("preferences.screen-title"),
      content: (
        <AzurePreferances
          isEditMode={isEditMode}
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
    },
    {
      step_title: t("setup.nav-title"),
      step_description: t("setup.nav-sub"),
      content_title: t("setup.screen-title"),
      content: renderSetupBlockByMethod(),
      valid: true,
      beforeNextStep: handleSubmitWizard
    },
    {
      step_title: t("completion.nav-title"),
      step_description: isEditMode? '' : t("completion.nav-sub"),
      content_title: isEditMode? t("completion.screen-title-update") : t("completion.screen-title"),
      content: (
        <SuccessBox setSubmitDisabled={setSubmitDisabled} doesTakeTime />
      ),
      valid: true,
    },
  ];

  return (
    <div className="AzureIntegration">
      <ControlledAppWizard
        iconSrc={renderStepIcon}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        current={current}
        setCurrent={setCurrent}
      />
    </div>
  );
};

export default AzureIntegration;
