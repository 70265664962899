import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import find from "lodash/find";
import { AllActiveIntegrationsCatalog } from "../../../containers/integrationsCatalog/integrations_index";
import { Link } from "react-router-dom";
import { numberWithCommas } from "../../../utils/formatting";
import "./integrationCountCard.scss";

const IntegrationCountCard = ({ integ, count, category }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const dataCard = useMemo(() => {
    const catalog = AllActiveIntegrationsCatalog(themeDark);
    const relevantCategory = catalog[category] || {};
    const integObject = find(
      relevantCategory || [],
      (i) => integ === i?.server_name
    );
    return integObject;

  },[integ, themeDark])
  
  return (
    <Link
      to={{
        pathname: `/integrations/${integ}`,
        state: { count, data: dataCard },
      }}
    >
      <div className="IntegrationCountCard basic-card col center between">
        <div className="IntegrationCountCard__text col center">
        <img
          src={dataCard?.icon}
          alt={dataCard?.name}
          className={`${dataCard?.name}-icon`}
        />
        <div className="bold">{dataCard?.title}</div>
        </div>
        <div className="IntegrationCountCard__count center">{numberWithCommas(count)}</div>
      </div>
    </Link>
  );
};

export default IntegrationCountCard;
