import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import JiraCreation from "./jiraCreation";

import { projects } from "../../../utils/icons";

import { createJiraIntegration } from "../../../redux/actions/jiraIntegrationActions";

import "./jiraIntegration.scss";

const JiraIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Jira Integration details creation",
      content_title: "Jira Integration",
      content: (
        <JiraCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const created = await dispatch(
          createJiraIntegration(
            wizardState.name,
            wizardState.domain,
            wizardState.mail,
            wizardState.token,
          )
        );
        setLoadingSubmit(false);
        if (_.isEmpty(created)) {
          throw new Error(
            `Failed to create Consul integration.`
          );
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="JiraIntegration">
      <AppWizard
        iconSrc={projects["jira"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default JiraIntegration;
