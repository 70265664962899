import action from "../middleware";
import RequestWrapper from '../../utils/requestWrapper';
import {BACKEND_URL} from "../../consts/config";

export const SEARCH_REMOVE_RESOURCE = "SEARCH_REMOVE_RESOURCE";
export const REMOVE_RESOURCE = "REMOVE_RESOURCE";
export const CLEAN_RESOURCE_REMOVE_DATA = "CLEAN_RESOURCE_REMOVE_DATA";

const cleanData = () => {
    return action(async dispatch => {
        dispatch({
            type: CLEAN_RESOURCE_REMOVE_DATA,
            payload: null
        });
    });
}

const searchResource = (references, shouldCommentCode) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const searchReq = await requestWrapper.sendRequest(`${BACKEND_URL}/remove-resource/search`, "POST",
            {
                references,
                shouldCommentCode
            },
            true,
            null,
            false);

        const data = await searchReq.json()
        dispatch({
            type: SEARCH_REMOVE_RESOURCE,
            payload: data
        });
        return {ok: searchReq?.ok};
    });
}

const removeResource = (frn, vcsId, repo, filesInfo, prComment) => {
    return action(async dispatch => {

        const requestWrapper = new RequestWrapper();
        const fixReq = await requestWrapper.sendRequest(`${BACKEND_URL}/remove-resource/remove`, "POST",
            {
                frn,
                vcsId,
                repo,
                filesInfo,
                prComment
            },
            true,
            null,
            false);

        const data = await fixReq.json()
        dispatch({
            type: REMOVE_RESOURCE,
            payload: data
        });
        return {ok: fixReq?.ok, data};
    });
}

export {
    searchResource,
    removeResource,
    cleanData
}
