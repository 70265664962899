import React from "react";
import { useSelector, useDispatch } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from "../../../utils/formatting";
import { useHistory } from "react-router-dom";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";

const MatchingAssetCell = ({ row = {} }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );

  const integrations = useSelector(
    (state) => state.insightsV2Reducer.filters?.integrations
  );
  
  const handleViewAssets = (e) => {
    e.stopPropagation(); //stop the code drawer from beeing open

    dispatch(
      setFilters({
        classifications: [row?._id],
        excluded: showExclusion,
        providerTypes: {
          integrationId: integrations,
        },
        masterType: []
      })
    );
    return history.push({
      pathname: "/inventory" ,
      search: "?defaultView=false",
    });
  };

  const { excluded_assets, total_assets } = row;
  const count = showExclusion ? excluded_assets + total_assets : total_assets;

  if (row?.isScanning) {
    return (
      <span className="row g8">
        Scaning
        <FontAwesomeIcon icon="spinner" className="purple-text" spin />
      </span>
    );
  }

  if (!row?.isEnabled) {
    return null;
  }

  if (!count && row?.isEnabled) {
    return (
      <span
        style={{ fontSize: "13px" }}
        className="purple-flag row g5"
      >
        <FontAwesomeIcon icon="layer-group" className="purple-text" />
        0
      </span>
    );
  }

  return (
    <span
      style={{ cursor: "pointer", fontSize: "13px" }}
      className="purple-flag row g5"
      onClick={handleViewAssets}
    >
      <FontAwesomeIcon icon="layer-group" className="purple-text" />
      {numberWithCommas(count)}
      <FontAwesomeIcon
        icon="external-link-alt"
        style={{ fontSize: "11px", marginLeft: "5px" }}
      />
    </span>
  );
};

export default MatchingAssetCell;
