import React from "react";
import _ from "lodash";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { formatJSONstring } from '../../../../utils/formatting';
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import Loading from "../../../../shared/loading/loading";
import ActionBtn from "../../../../shared/actionBtn/actionBtn";
import "./configuration.scss";

const MAX_STRING_LENGTH_TO_RENDER = 50000;
const Configuration = ({ item, loading, noFormat, startingLineNumber, onClickAction = () => null }) => {
  
  const renderCode = () => {
    if (loading) {
      return (
        <div className="Configuration__loading center">
          <Loading />
        </div>
      );
    }
    if (!_.isEmpty(item)) {
      if (item.length > MAX_STRING_LENGTH_TO_RENDER) {
        return (
          <div className="Configuration__no-data center" style={{ height: "54vh" }}>
            <AppEmpty 
             text={
              <div><span className="opac">The configuration size for your selected resource exceeds the display limit.</span>
                <div className="centered"><span className="opac">Click the </span><span>Download</span><span className="opac"> button to view the full details.</span></div>
              </div>}
             customStyle="custom"
          />
          </div>
        );
      }
      const foramatted = formatJSONstring(item);

      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
          startingLineNumber={startingLineNumber || 1}
        >
          {noFormat ? item : foramatted}
        </SyntaxHighlighter>
      );
    }
    return (
      <div className="Configuration__no-data center" style={{ height: "54vh" }}>
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  return (
    <div className="Configuration">
      {!_.isEmpty(item) && <div className="Configuration__btns row">
        <ActionBtn text="" icon="copy" action="copy" stringToAction={item} purple onClickDifferentActions={() => onClickAction("copy")} />
        <ActionBtn
          purple
          text=""
          action="download"
          fileType="json"
          fileName="configurations"
          icon="donwload"
          stringToAction={item}
          onClickDifferentActions={() => onClickAction("download")}
        />
      </div>}
      {renderCode()}
    </div>
  );
};

export default Configuration;
