import React from "react";
import { useSelector } from "react-redux";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import { ReactComponent as IacStack } from "../../../Images/general_icons/iac_stack.svg";
import { ReactComponent as Modules } from "../../../Images/general_icons/modules.svg";
import { ReactComponent as CloudIcon } from "../../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as Backends } from "../../../Images/general_icons/backends.svg";
import { ReactComponent as Repositories } from "../../../Images/general_icons/Git Logo.svg";
import Tooltip from "antd/lib/tooltip";

import "./iacStacksCategories.scss";

const IacStacksCategories = ({
  categories,
  selectedIacType,
  selectedCategory,
  setSelectedCategory,
}) => {
  const modulesAggs = useSelector(
    (state) => state.iacStacksReducer.modulesAggs
  );
  const categoryDetails = {
    appliedStacks: {
      text: "Applied Stacks",
      icon: <IacStack className="IacStacksCategories__icon" />,
    },

    installedArtifacts: {
      text: "Installed Artifacts",
      icon: <IacStack className="IacStacksCategories__icon" />,
    },
    installedCharts: {
      text: "Installed Charts",
      icon: <IacStack className="IacStacksCategories__icon" />,
    },
    modules: {
      text: "Modules",
      icon: <Modules className="IacStacksCategories__icon" />,
    },
    providers: {
      text: "Providers",
      icon: <CloudIcon className="IacStacksCategories__icon" />,
    },
    backends: {
      text: "Backends",
      icon: <Backends className="IacStacksCategories__icon" />,
    },
    repositories: {
      text: "Repositories",
      icon: <Repositories className="IacStacksCategories__icon" />,
    },
  };

  return (
    <div className="IacStacksCategories row">
      {map(categories[selectedIacType], (category) => {
        const disabled = category === "modules" && modulesAggs < 1;
        const active = category === selectedCategory;
        return (
          <Tooltip
            title={
              disabled
                ? "Please integrate your Modules Git Repo to get Modules visibility"
                : ""
            }
            placement="top"
            key={uuidv4()}
          >
            <button
              className={`IacStacksCategories__button row ${
                active ? "active" : ""
              } ${disabled ? "disabled" : ""}`}
              onClick={() => (disabled ? null : setSelectedCategory(category))}
            >
              {categoryDetails[category]?.icon}
              <div className="IacStacksCategories__button-text">
                {categoryDetails[category]?.text}
              </div>
            </button>
          </Tooltip>
        );
      })}
    </div>
  );
};

export default IacStacksCategories;
