import React from "react";
import { useSelector } from "react-redux";

// components
import NavLinks from "../../components/appSidebar/navLinks/navLinks";
import AppLogo from "../../components/appSidebar/appLogo/appLogo";
import AccountTierBanner from "../../components/appSidebar/accountTierBanner/accountTierBanner";
import CompanyLogo from "../../components/appSidebar/companyLogo/companyLogo";
import "./appSidebar.scss";
import ProvisioningBtn from "../../components/appSidebar/provisioningBtn/provisioningBtn";

const AppSidebar = () => {
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );
  return (
    <div className="AppSidebar">
      <AppLogo sidebar />
      <div className="col between">
        <div className="AppSidebar__links col">
          <ProvisioningBtn />
          <NavLinks />
        </div>
        <div
          className={`AppSidebar__actions ${sidebarClosed ? "no-padding" : ""}`}
        >
          <AccountTierBanner />
          <CompanyLogo />
        </div>
      </div>
    </div>
  );
};

export default AppSidebar;
