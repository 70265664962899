import { GET_ORGANIZATION_ACCOUNTS } from "../actions/organizationActions";

const initialState = {
  accounts: [],
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    
    case GET_ORGANIZATION_ACCOUNTS:
      return { ...state, accounts: payload };
      
    default:
      return state;
  }
};
