import {
  GET_SCREEN_VIEWS,
  GET_VIEW_BY_ID,
  CREATE_VIEW,
  DELETE_VIEW,
  TOGGLE_VIEW_MODAL,
  SET_ACTIVE_VIEW,
} from "../actions/screenViewActions";
import _ from "lodash";

const initialState = {
  views: [],
  viewModalOpen: {
    open: false,
    edit: false,
  },
  activeInventoryView: {},
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_SCREEN_VIEWS:
      return { ...state, views: payload.data };

    case GET_VIEW_BY_ID:
      return { ...state };

    case CREATE_VIEW:
      return { ...state };

    case DELETE_VIEW:
      return { ...state };

    case TOGGLE_VIEW_MODAL:
      return {
        ...state,
        viewModalOpen: {
          open: !state.viewModalOpen.open,
          edit: payload,
        },
      };

    case SET_ACTIVE_VIEW:
      return {
        ...state,
        activeInventoryView: payload,
      };

    default:
      return state;
  }
};
