import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import CreationStep from "./creationStep";

import {
  createSlackWebhhookIntegration,
  testSlackIntegration,
  createSlackAppIntegration
} from "../../../redux/actions/integrationsActions";
import { integrationIcons } from "../../../utils/icons";

import "./slackIntegration.scss";

const SlackIntegration = () => {
  const history = useHistory();
  const location = useLocation();
  const dispatch = useDispatch();
  const { search } = location;

  const [wizardState, setWizardState] = useState({
    webhookUrl: "",
    username: "",
    password: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [slackAppIntegDone, setSlackAppIntegDone] = useState(false);

  // get slack app code after autorization redirect to app and continue integration automatically
  useEffect(() => {
    if (search) {
      handleCreateSlackApp();
    }
  }, [search]);

  const handleCreateSlackApp = async () => {
    setSubmitDisabled(false);
    setLoadingSubmit(true);

    const searchParam = new URLSearchParams(search);
    const slackAppCode = searchParam.get("code");
    const appNickname = !_.isEmpty(wizardState?.name) ?  wizardState.name : 'Firefly Slack app';

    const appCreation = await dispatch(
      createSlackAppIntegration(appNickname, slackAppCode)
    );

    if (appCreation) {
      setSlackAppIntegDone(true);
      setLoadingSubmit(false);
      return;  
    } else {
      setSubmitDisabled(true);
      setLoadingSubmit(false);
    }
  };

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Integration setup",
      content_title: `Slack Integration`,
      content: (
        <CreationStep
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);

        const slackWebhookOk = await dispatch(
          testSlackIntegration(wizardState.webhookUrl)
        );
        if (slackWebhookOk) {
          await dispatch(
            createSlackWebhhookIntegration(wizardState.name, wizardState.webhookUrl)
          );
          setLoadingSubmit(false);
        } else {
          setLoadingSubmit(false);
          throw new Error("Invalid webhook url");
        }
        return setLoadingSubmit(false);
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="SlackIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={integrationIcons["slack"]}
        handleAutoComplete={slackAppIntegDone}
      />
    </div>
  );
};

export default SlackIntegration;
