import React from "react";
import './typingDots.scss';

const TypingDots = () => {
  return (
    <div className="TypingDots">
      <span></span>
      <span></span>
      <span></span>
    </div>
  );
};

export default TypingDots;
