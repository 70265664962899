import {
  GET_REPO_MODULES,
  GET_MODULES_BY_REPO,
  MODULE_CALL,
  SET_PULL_REQUEST_PARAMETERS,
  CREATE_MODULE,
  SELECTED_MODULE_FILE,
  CLEAR_MODULE_DATA,
} from "../actions/iacImportActions";
import _ from 'lodash';

const initialState = {
  moduleRepos: [],
  modules: [],
  moduleData: {},
  pullReqParams: null,
  moduleFileSelected: null,
  moduleDataCreated: {}
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_REPO_MODULES:
      return { ...state, moduleRepos: payload };
    case GET_MODULES_BY_REPO:
      return { ...state, modules: payload };
    case MODULE_CALL:
      return { ...state, moduleData: payload };
    case SET_PULL_REQUEST_PARAMETERS:
      return { ...state, pullReqParams: payload };
    case CREATE_MODULE:
      // set the module data and set the first object key as a selected file name
      const { redactedValues, ...files } = payload;
      return { ...state, moduleDataCreated: files, moduleFileSelected: !_.isEmpty(files) && _.isObject(files) ? Object.keys(files)[0] : null, redactedValues };
    case SELECTED_MODULE_FILE:
      return { ...state, moduleFileSelected: payload };
    case CLEAR_MODULE_DATA:
      return { ...state, moduleData: {}};
    default:
      return state;
  }
};
