import React, { memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelFilter from "../../../inventory/inventoryFilters/inventorySingelFilter";
import { formatAwsStringWithUnderscore } from "../../../../utils/formatting";

const ScopesFilter = () => {
  const dispatch = useDispatch();

  const scopeAggs = useSelector((state) => state.insightsV2Reducer.aggs?.type);
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const handleSetSelectedScope = useMemo(() => (arr) => {
    const payload = { ...insightFilters, type: arr };
    return dispatch(setInsightsFilters(payload));
  }, [dispatch, insightFilters]);

  const scopes = useMemo(() => {
    return (scopeAggs || []).map((i) => ({
      name: i?.value,
      formattedName: formatAwsStringWithUnderscore(i?.value, true),
      count: i?.count,
    }));
  }, [scopeAggs]);

  return (
    <InventorySingelFilter
      allTypes={scopes}
      selectedTypes={handleSetSelectedScope}
      checkedList={insightFilters?.type || []}
      title="Search"
    />
  );
};

export default memo(ScopesFilter);