import React, { Suspense } from "react";
import { Layout } from "antd";
import { useSelector } from "react-redux";
import AppHeader from "../../containers/appHeader/appHeader";
import AppSidebar from "../../containers/appSidebar/appSidebar";
import InitialDiscoveryAlert from "../../components/appHeader/initialDiscoveryAlert/initialDiscoveryAlert";
import DemoAlert from "../../components/appHeader/demoAlert/demoAlert";
import SuspendedBarAlert from "../../components/appHeader/suspendedBarAlert/suspendedBarAlert";
import Compose from "../../containers/compose/compose";
import SidebarToggle from "../../components/appSidebar/sidebarToggle/sidebarToggle";
import Loading from "../loading/loading";
import "./appLayout.scss";

const { Header, Sider, Content } = Layout;

const AppLayout = (props) => {
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  return (
    <div className="col">
      <DemoAlert />
      <InitialDiscoveryAlert />
      <SuspendedBarAlert />

      <Layout style={{ height: "100vh", flexDirection: "row" }}>
        <Sider
          collapsible
          collapsed={sidebarClosed}
          trigger={null}
          collapsedWidth={70}
          style={{
            // overflow: "auto",
            height: "100vh",
            position: "sticky",
            left: 0,
            top: 0,
            zIndex: 2,
          }}
        >
          <AppSidebar />
          <div className="AppSidebar__toggle">
            <SidebarToggle />
          </div>
        </Sider>
        <Layout className="site-layout" style={{ overflow: "auto", zIndex: 1 }}>
          <Header
            style={{
              padding: "0",
              minHeight: "90px",
              height: "max-content",
              display: "flex",
              flexDirection: "column",
            }}
          >
            <AppHeader />
          </Header>
          <Content
            className="app-content-wrapper"
            style={{
              backgroundColor: themeDark ? "#181825" : "#F6F5F8",
            }}
          >
            <Suspense fallback={<Loading />}>{props.children}</Suspense>
          </Content>
        </Layout>
      </Layout>
      <Compose />
    </div>
  );
};

export default AppLayout;
