import React from "react";
import { numberWithCommas, formatAwsStringWithUnderscore } from "../../../utils/formatting";

const TopFivePropertiesItem = ({ name, count, resourceType }) => {
  return (
    <div className="TopFivePropertiesItem row">
      <div className="TopFivePropertiesItem__text col">
        <span className="TopFivePropertiesItem__text-count">
          {numberWithCommas(count)}
        </span>
        <span>{formatAwsStringWithUnderscore(resourceType)}</span>
        <span className="purple-text" style={{ fontWeight: '300' }}>{name}</span>
      </div>
    </div>
  );
};

export default TopFivePropertiesItem;
