import React from "react";
import { useTranslation } from "react-i18next";
import { numberWithCommas } from "../../../utils/formatting";
import { ToolFilled } from "@ant-design/icons";
import { suggestionsArr } from "../../../consts/suggestions";

const SavingsCell = ({ row, handleShowSavings }) => {
  const { t } = useTranslation("insights");

  const isSuggestionsCodeExiest = suggestionsArr?.find((item) => item?.name === row?.name);

  const showSavings = (e) => {
    if(isSuggestionsCodeExiest) {
      return handleShowSavings(e)
    }
    return;
  }

  // we show savings only for active insights with matching assets
  if (row?.cost && row?.isEnabled && row?.total_assets) {
    return (
      <span className="row g5" onClick={showSavings}>
        <span
          style={{ cursor: "pointer", fontSize: "13px" }}
          className="green-flag"
        >
          {`${t("table.savings.cell")} ${numberWithCommas(row?.cost)}/m`}
        </span>
        {isSuggestionsCodeExiest && <ToolFilled style={{ fontSize: 14, color: "#71c9ca" }} />}
      </span>
    );
  }
  return null;
};

export default SavingsCell;
