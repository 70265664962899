import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import { Tooltip } from "antd";
import map from "lodash/map";
import find from "lodash/find";
import { ReactComponent as OptimizeIcon } from "../../../../Images/general_icons/insights-filters/optimize.svg";
import { ReactComponent as MisconfigIcon } from "../../../../Images/general_icons/insights-filters/misconfig.svg";
import { ReactComponent as ReliabilityIcon } from "../../../../Images/general_icons/insights-filters/reliability.svg";
import "./policyCategoryFilter.scss";

const PolicyCategoryFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const insightsFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const filtersOpen = useSelector(
    (state) => state.insightsV2Reducer.filtersOpen
  );

  const labelsAggs = useSelector(
    (state) => state.insightsV2Reducer.aggs?.labels
  );

  const items = [
    {
      title: t("filters.Optimization"),
      icon: <OptimizeIcon />,
      val: 'Optimization',
      exiest: find(labelsAggs || [], (i) => i?.value === 'Optimization'),
    },
    {
      title: t("filters.Misconfiguration"),
      icon: <MisconfigIcon />,
      val: 'Misconfiguration',
      exiest: find(labelsAggs || [], (i) => i?.value === 'Misconfiguration'),
    },
    {
      title: t("filters.Reliability"),
      icon: <ReliabilityIcon />,
      val: 'Reliability',
      exiest: find(labelsAggs || [], (i) => i?.value === 'Reliability'),
    },
  ];

  const handleSetFilter = (obj) => {
    if (!obj?.exiest) {
      return;
    }
    const newVal = insightsFilters?.labels === obj?.val ? [] : obj?.val;
    const payload = { ...insightsFilters, labels: newVal };
    return dispatch(setInsightsFilters(payload));
  };

  return (
    <div className="PolicyCategoryFilter col">
      {map(items, (i) => {
        return (
          <div
            className={`PolicyCategoryFilter__item ${i.exiest ? '' : 'disabled'}`}
            key={i.val}
            onClick={() => handleSetFilter(i)}
          >
            <Tooltip title={filtersOpen ? null : i.title} placement="right">
            <div
              className={`PolicyCategoryFilter__item-inner row g10 ${
                insightsFilters?.labels === i.val ? `active-${i.val}` : ""
              } `}
              style={{
                padding: filtersOpen ? "0 15px" : 0,
                justifyContent: filtersOpen ? "start" : "center",
                transition: "padding 0.5s, justify-content 0.5s",
              }}
            >
              {i.icon}
              {filtersOpen ? i.title : null}
            </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default memo(PolicyCategoryFilter);
