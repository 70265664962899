import React from "react";

const EventDrivenTooltipBox = () => {
  const link =
    "https://docs.aws.amazon.com/awscloudtrail/latest/userguide/cloudtrail-getting-started.html";
  return (
    <div className="col g10" style={{ padding: '10px' }}>
      <span>
        Using the Event-driven method for fetching your IaC stacks ensures that
        Firefly refreshes your assets in the inventory in near real-time as soon
        as they change. This method is the optimal choice for maintaining an
        up-to-date inventory of your assets.
      </span>
      <span>
        To use the event-driven service, enable CloudTrail in your AWS account.
      </span>
      <a href={link} target="_blank" rel="noopener noreferrer" className="underline" style={{ width: 'max-content' }}>Learn how</a>
    </div>
  );
};

export default EventDrivenTooltipBox;
