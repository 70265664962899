import React, { useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Column } from "@ant-design/charts";
import moment from "moment";
import { dateTimeFormat } from "../../../utils/formatting";
import { useSelector } from "react-redux";
import "./driftChart.scss";

const DriftChart = ({ data }) => {
  const [chartData, setChartData] = useState([]);
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    if (!isEmpty(data)) {
      setChartData(organizeDataToRender());
    }
  }, [data]);

  const organizeDataToRender = () => {
    if (!isEmpty(data)) {
      const newData = map(data, (item) => {
        return map(item?.state, (inner) => {
          return [
            {
              sum: inner.key === 'unmanaged' ? inner?.arns?.value : 0,
              name: 'Unmanaged',
              date: item?.createdAt,
            },
            {
              sum: inner.key === 'modified' ? inner?.arns?.value : 0,
              name: 'Drifted',
              date: item?.createdAt,
            },
            {
              sum: inner.key === 'ghost' ? inner?.arns?.value : 0,
              name: 'Ghost',
              date: item?.createdAt,
            },
            {
              sum: inner.key === 'insight' ? inner?.arns?.value : 0,
              name: 'Policy',
              date: item?.createdAt,
            },
          ];
        });
      });
      return newData.flat(2);
    }
  };

  var config = {
    data: chartData,
    height: 120,
    isStack: true,
    xField: "date",
    yField: "sum",
    seriesField: "name",
    dodgePadding: 1,
    intervalPadding: 2,
    yAxis: false,
    xAxis: {
      visible: true,
      line: {
        visible: true,
        style: {
        stroke: themeDark? '#464668' : '#ddd',
        },
      },
      label: false,
      // {
      //   formatter: (v) => '' // moment.unix(parseInt(v)).format("H:mm"),
      // },
    },
    tooltip: {
      title: (v) => moment.unix(parseInt(v)).format(dateTimeFormat),
      domStyles: {
        "g2-tooltip": { 
          backgroundColor: themeDark? '#212133' : 'white',
          color: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
         },
      },
    },
    color: ["#D76089", "#9872FE", "#F4B483", "#0176D2"],
    legend: {
      layout: "horizontal",
      position: 'bottom',
      animate: true,
      itemSpacing: 15,
      marker: {
        symbol: "circle",
      },
      itemName: {
        style: () => {
          return {
            fill: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
          };
        },
      },
      flipPage: false, // THIS ATTERIBUTE IS FOR HIDING THE INDEX PAGINATION
      pageNavigator: {
        marker: {
          style: {
            inactiveFill: '#ccc',
            inactiveOpacity: 1,
            fill: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
            opacity: 1,
            size: 8,
          },
        },
        text: {
          style: {
            fill: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
            fontSize: 12,
          },
        },
      },
    },
    animation: true,
    columnBackground: {
      style: {
        fill: themeDark? 'rgba(255, 255, 255, 0)' : 'rgba(0,0,0,0.03)',
      },
    },
  };

  return (
    <div className="DriftChart">
      <Column {...config} />
    </div>
  );
};

export default DriftChart;
