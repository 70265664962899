import React from 'react';
import details from '../../../Images/general_icons/insight-details.svg'
import notification from '../../../Images/general_icons/insight-notification.svg'
import rego from '../../../Images/general_icons/insight-rego.svg'
import './wizardStepHeader.scss';

const WizardStepHeader = ({ type }) => {

    const types = {
        details: { title: 'Policy Details', icon: details },
        rego: { title: 'Rego Playground', icon: rego },
        notification: { title: 'Notifications', icon: notification },
    };

    const typeData = types[type];

    // case passed wrong type property value
    if (!typeData) {
        return null;
    }

    return (
        <div className="WizardStepHeader row g10">
            <img src={typeData.icon} alt={type} />
           <span className="WizardStepHeader__text bold">{typeData.title}</span> 
        </div>
    )
}

export default WizardStepHeader
