import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setActiveScreenView } from "../../../redux/actions/screenViewActions";
import Collapse from "antd/lib/collapse";
import isEmpty from "lodash/isEmpty";
import isEqual from "lodash/isEqual";
import values from "lodash/values";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Spin } from "antd";
import { useTranslation } from "react-i18next";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as MatchingRules } from "../../../Images/general_icons/matchingRules.svg";
import { ReactComponent as Yak } from "../../../Images/general_icons/yak_new_outline.svg";
import { ReactComponent as CloudIcon } from "../../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as TypesIcon } from "../../../Images/general_icons/layers_outline.svg";
import { ReactComponent as LocationIcon } from "../../../Images/general_icons/location_outline.svg";
import { ReactComponent as YearsIcon } from "../../../Images/general_icons/calendar_outline.svg";
import { ReactComponent as Chevron } from "../../../Images/general_icons/chevron.svg";
import { ReactComponent as Tags } from "../../../Images/general_icons/tags.svg";
import { ReactComponent as ResourceGroupsIcon } from "../../../Images/general_icons/Resource_groups.svg";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import InventorySingelFilter from "./inventorySingelFilter";
import InventorySingelRadioFilter from "./inventorySingelRadioFilter";
import InventoryTreeFilter from "./inventoryTreeFilter";
import "./inventoryFilters.scss";
import ProviderMasterFilter from "./providerMasterFilter/providerMasterFilter";
import _, { isArray } from "lodash";
import { getItemById } from "../../../utils/helpers";

import { getExclusions as getExcludedAssets } from "../../../redux/actions/exclusionsActions";
import {
  getYearsAggregations,
} from "../../../redux/actions/inventoryv3Actions";

const ALL_CUSTOM_EXCLUSIONS_KEY = "allCustomExclusions";

const InventoryFilters = ({
  activeProviderTypes,
  providerTypeSelected,
  resourceTypes,
  resourceSelected,
  resetFilters,
  activeResurceTypesSelected,
  classifications,
  classificationSelected,
  activeClassificationSelected,
  handleClearAllFilters,
  activeExcludedAssetsSelected,
  excludedAssetSelected,
  activeTypesSelected,
  iacTypes,
  iacTypesSelected,
  activeRegionsSelected,
  regions,
  regionsSelected,
  handleSaveView,
  handleShareView,
  loadingShareTempView,
  yearsSelected,
  activeYearsSelected,
  activeOwnersSelected,
  owners,
  ownersSelected,
  activeTagsSelected,
  tags,
  tagsSelected,
  azureResourceGroups,
  azureResourceGroupsSelected,
  activeAzureResourceGroupsSelected,
  deletedRanges,
  activeDeletedRangeSelected,
  deletedRangeSelected,
  handleDeletedFilter,
  cloudsLoading
}) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();
  const [loadingYearsAggs, setLoadingYearsAggs] = useState(false);
  const [loadingExcludedAssetsAggs, setLoadingExcludedAssetsAggs] = useState(false);

  const closeInvFiltersByDefault = useSelector(
    (state) => state.onbordingReducer.account.disableFeatures?.closeInvFiltersByDefault
  ) || false;
  const DEFAULT_ACTIVE_FILTER = closeInvFiltersByDefault ? "" : "1";

  const aggregationsYears = useSelector(
    (state) => state.inventoryReducer.aggregationsYears
  );

  const aggregationsExcludedAssets = useSelector(
    (state) => state.inventoryReducer.aggregationsExcludedAssets
  );
  const excludedAssets = useSelector((state) => state.exclusionsReducer.rules);
  
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const [activePanel, setActivePanel] = useState(DEFAULT_ACTIVE_FILTER);
  // redux
  const views = useSelector((state) => state.screenViewReducer.views);
  const screenFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);


  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const { t } = useTranslation("inventory", { keyPrefix: "filters" });

  useEffect(() => {
    return handleSetActiveViewIfScreenFilterMatch();
  }, [screenFilters, views]);

  const handleSetActiveViewIfScreenFilterMatch = () => {
    if (!isEmpty(views)) {
      const match = views?.find((view) => {
        const filtersWithoutSearch = { ...view?.filters };
        delete filtersWithoutSearch["freeSearchText"];
        if (
          isEqual(screenFilters, filtersWithoutSearch) &&
          searchVal === view?.filters?.freeSearchText
        ) {
          return true;
        }
        return false;
      });
      if (match) {
        return dispatch(setActiveScreenView(match));
      } else {
        return dispatch(setActiveScreenView({}));
      }
    }
  };


  useEffect(() => {
    if (screenFilters?.deleted) {
      return setActivePanel("10");
    }
    return setActivePanel(DEFAULT_ACTIVE_FILTER)
  }, [screenFilters?.deleted]);

  useEffect(() => {
    const isLiveToggleAndDeletedPanel = activePanel === "10" && !screenFilters?.deleted
    if (isLiveToggleAndDeletedPanel) {  
      handleDeletedFilter(true)
    }
  }, [activePanel])

  const getActiveObjectNumber = (activeObj) => {
    let num = 0;
    map(_.keys(activeObj), (key) => {
      if(key != "provider"){
        num += activeObj[key].length;
      }
    });
    return num;
  };

  const handleCutsomArrow = (e) => {
    return (
      <Chevron
        style={{
          transform: e.isActive ?
            "translateY(2px)" : "rotate(-90deg) translateY(2px)",
          transition: "all .2s ease",
          width: "10px",
        }}
      />
    );
  };

  const filterIcons = [
    <CloudIcon
      className={`InventoryFilters__item-filterIcon-icon provider-type-icon ${
        !values(activeProviderTypes).every(isEmpty) ||
        (activePanel === "1")
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key="1"
    />,
    <LocationIcon
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeRegionsSelected) || activePanel === "2"
          ? "classificationActive"
          : "classification"
      }`}
      key="2"
    />,
    <TypesIcon
      className={`InventoryFilters__item-filterIcon-icon asset-type-icon ${
        !isEmpty(activeResurceTypesSelected) || activePanel === "3"
          ? "classificationActive"
          : "classification"
      }`}
      key="3"
    />,
    <Tags
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeTagsSelected) || activePanel === "4"
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key="4"
    />,
    <UserOutlined
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeOwnersSelected) || activePanel === "5"
          ? "emptyIconActive"
          : "emptyIcon"
      }`}
      key="5"
    />,
    <Yak
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeTypesSelected) || activePanel === "6"
          ? "classificationActive"
          : "classification"
      }`}
      key="6"
    />,
    <YearsIcon
      className={`InventoryFilters__item-filterIcon-icon years-icon ${
        !isEmpty(activeYearsSelected) || activePanel === "7"
          ? "classificationActive"
          : "classification"
      }`}
      key="7"
    />,
    <MatchingRules
      className={`InventoryFilters__item-filterIcon-icon ${
        !isEmpty(activeClassificationSelected) || activePanel === "8"
          ? "classificationActive"
          : "classification"
      }`}
      key="8"
    />,
    <FontAwesomeIcon
      icon={["far", "eye-slash"]}
      className={`InventoryFilters__item-filterIcon-icon
      ${
        !isEmpty(activeExcludedAssetsSelected) || activePanel === "9"
          ? "iconActive"
          : "classification"
      }`}
      key="9"
    />,
    <FontAwesomeIcon
    icon={["far", "trash-alt"]}
      className={`InventoryFilters__item-filterIcon-icon
      ${
        !isEmpty(activeDeletedRangeSelected) || activePanel === "10"
          ? "iconActive"
          : "classification"
      }`}
      key="10"
    />,
    <ResourceGroupsIcon
      className={`InventoryFilters__item-filterIcon-icon
      ${
        !isEmpty(activeAzureResourceGroupsSelected) || activePanel === "11"
          ? "iconActive"
          : ""
      }`}
      key="11"
    />,
  ];

  const handleYearsAggs = () => {
    const uniqueTypes = [];
    if (!_.isEmpty(aggregationsYears)) {
      for (let item of aggregationsYears) {
        uniqueTypes.push({
          name: item?.year,
          formattedName: item?.year,
          count: item?.doc_count,
        });
      }
    }
    const ordered = _.orderBy(uniqueTypes, ["name"], ["desc"]);
    return ordered;
  };

  const handleExcludedAssetsAggs = () => {
    const uniqueTypes = [];
    const excludeCustomItem = {
      key: ALL_CUSTOM_EXCLUSIONS_KEY,
      name: ALL_CUSTOM_EXCLUSIONS_KEY,
      isPinnedItem: true,
      formattedName: "All Custom Exclusions",
      subItems: [],
      count: 0,
    };
    if (!_.isEmpty(excludedAssets) && !_.isEmpty(aggregationsExcludedAssets) && isArray(aggregationsExcludedAssets)) {
      for (let item of aggregationsExcludedAssets) {
        const { name, isDefault } = getItemById(item.key, excludedAssets);
        if (name !== item.key){
          uniqueTypes.push({
            name: item.key,
            formattedName: name,
            count: item.doc_count,
          });
          const isCustomItem = name && !isDefault;
          if (isCustomItem) {
            excludeCustomItem.subItems.push(item.key)
            excludeCustomItem.count += item.doc_count;
          }
        }
      }
    }
    const ordered = _.orderBy(uniqueTypes, ["count"], ["desc"]);
    const excludeAggs = excludeCustomItem.count > 0 ? [excludeCustomItem, ...ordered] : ordered;
    return excludeAggs;
  };

  const handlePanelClick =  async (key) => {
    setActivePanel(key)

    if (key === "7" && _.isEmpty(aggregationsYears)){
      setLoadingYearsAggs(true);
      await dispatch(getYearsAggregations());
      setLoadingYearsAggs(false);
    }else if (key === "9" && _.isEmpty(excludedAssets)){
      setLoadingExcludedAssetsAggs(true);
      await dispatch(getExcludedAssets(false, false))
      setLoadingExcludedAssetsAggs(false);
    }

  };

  const panelHeader = (title, activeArr, idx, activeObj) => {
    return (
      <div className="InventoryFilters__item row">
        {filterIcons[idx - 1]}
        <span
          className={`InventoryFilters__item-title row ${
            !isEmpty(activeArr) ||
            !values(activeObj).every(isEmpty) ||
            (activePanel === idx)
              ? "active"
              : ""
          }`}
        >
          <span>{title}</span>
          {!isEmpty(activeArr) && (
            <span className="purple-text">{`(${activeArr?.length})`}</span>
          )}
          {!values(activeObj).every(isEmpty) &&
            getActiveObjectNumber(activeObj) > 0 && (
              <span className="purple-text">{`(${getActiveObjectNumber(
                activeObj
              )})`}</span>
            )}
        </span>
      </div>
    );
  };

  return (
    <div className="InventoryFilters">
      <div className="InventoryFilters__header row between">
        <span className="InventoryFilters__header-title">Filters</span>
        {!isEmpty(activeResurceTypesSelected) ||
        !isEmpty(activeTypesSelected) ||
        !isEmpty(activeClassificationSelected) ||
        !isEmpty(activeRegionsSelected) ||
        !isEmpty(activeExcludedAssetsSelected) ||
        !isEmpty(activeYearsSelected) ||
        !isEmpty(activeOwnersSelected) ||
        !isEmpty(activeTagsSelected) ||
        !isEmpty(activeDeletedRangeSelected) ||
        !values(activeProviderTypes).every(isEmpty) ? (
          <div className="InventoryFilters__header-actions row">
            <IconBtn
              onClick={handleSaveView}
              type="save"
              colorClass="filter"
              tooltipText="Save filters as view"
            />
            <IconBtn
              onClick={handleShareView}
              type="share"
              colorClass="filter"
              tooltipText="Share as temporary view"
              loading={loadingShareTempView}
            />
            <IconBtn
              onClick={handleClearAllFilters}
              type="clear"
              colorClass="filter"
              tooltipText="Clear all"
            />
          </div>
        ) : null}
      </div>
      <div className="InventoryFilters__masterFilterWrapper"><ProviderMasterFilter /></div>
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={handlePanelClick}
        accordion
      >
        <Panel
          header={panelHeader(t("titles.dataSource"), [], "1", activeProviderTypes)} key="1"
        >
          {cloudsLoading ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventoryTreeFilter
            providerTypeSelected={(filters) => providerTypeSelected(filters)}
          />
          )}
        </Panel>
        {!isEmpty(providerIntegrations?.azurerm) && <Panel header={panelHeader("AZ Resource Groups", activeAzureResourceGroupsSelected, "11")} key="11">
          <InventorySingelFilter
            allTypes={azureResourceGroups}
            selectedTypes={azureResourceGroupsSelected}
            checkedList={activeAzureResourceGroupsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "11"}
            hideText
          />
        </Panel>}
        <Panel
          header={panelHeader(t("titles.location"), activeRegionsSelected, "2")} key="2"
        >
          <InventorySingelFilter
            allTypes={regions}
            selectedTypes={regionsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "2"}
            checkedList={activeRegionsSelected}
          />
        </Panel>
          <Panel
            header={panelHeader(t("titles.assetType"), activeResurceTypesSelected, "3")} key="3"
          >
            <InventorySingelFilter
              allTypes={resourceTypes}
              selectedTypes={resourceSelected}
              checkedList={activeResurceTypesSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === "3"}
            />
          </Panel>
        <Panel header={panelHeader(t("titles.tags"), activeTagsSelected, "4")} key="4">
          <InventorySingelFilter
            allTypes={tags}
            selectedTypes={tagsSelected}
            checkedList={activeTagsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "4"}
            hideText
          />
        </Panel>
        <Panel header={panelHeader(t("titles.owner"), activeOwnersSelected, "5")} key="5">
          <InventorySingelFilter
            allTypes={owners}
            selectedTypes={ownersSelected}
            checkedList={activeOwnersSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "5"}
            hideText
          />
        </Panel>
        <Panel
          header={panelHeader(t("titles.iacType"), activeTypesSelected, "6")} key="6"
        >
          <InventorySingelFilter
            allTypes={iacTypes}
            selectedTypes={iacTypesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "6"}
            checkedList={activeTypesSelected}
            noAnimation
          />
        </Panel>
        <Panel header={panelHeader(t("titles.creationYear"), activeYearsSelected, "7")} key="7">
          {loadingYearsAggs ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventorySingelRadioFilter
              allTypes={handleYearsAggs()}
              selectedTypes={yearsSelected}
              checkedList={activeYearsSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === "7"}
            />
          )}
        </Panel>
        <Panel
          header={panelHeader(t("titles.insights"), activeClassificationSelected, "8")} key="8"
        >
          <InventorySingelRadioFilter
            allTypes={classifications}
            selectedTypes={classificationSelected}
            checkedList={activeClassificationSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "8"}
          />
        </Panel>
        <Panel
          header={panelHeader(t("titles.excludedAssets"), activeExcludedAssetsSelected,"9")} key="9"
        >
          {loadingExcludedAssetsAggs ? (
            <div className="center" style={{ padding: "30px 0" }}>
              <Spin />
            </div>
          ) : (
            <InventorySingelFilter
              allTypes={handleExcludedAssetsAggs()}
              selectedTypes={excludedAssetSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === "9"}
              checkedList={activeExcludedAssetsSelected}
            />
          )}
        </Panel>
        <Panel header={panelHeader(t("titles.deletedAssets"),activeDeletedRangeSelected,"10")} key="10">
          <InventorySingelRadioFilter
            allTypes={deletedRanges}
            selectedTypes={deletedRangeSelected}
            checkedList={activeDeletedRangeSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "10"}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default InventoryFilters;
