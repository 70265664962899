import React from "react";
import moment from "moment";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./historyNavSlide.scss";
import { dateFormat } from "../../../../../utils/formatting";

const HistoryNavSlide = ({ active, onClick, time, state, current }) => {
  const renderStateIcon = () => {
    switch (state) {
      case "unmanaged":
        return <FontAwesomeIcon icon="exclamation-circle" />;
      case "managed":
        return <FontAwesomeIcon icon="code" />;
      case "modified":
        return <FontAwesomeIcon icon="code-branch" />;
      case "ghost":
        return <FontAwesomeIcon icon="ghost" />;
      case "deleted":
        return <FontAwesomeIcon icon="trash" />;
      default:
        return <FontAwesomeIcon icon="question" />;
    }
  };
  return (
    <div className="HistoryNavSlide col">
      {current ? (
        <div className="HistoryNavSlide__current col center">Current</div>
      ) : (
        <div
          className={`HistoryNavSlide__content col center ${
            active ? "active" : ""
          }`}
          onClick={onClick}
        >
          <div>{moment(time).format(dateFormat)}</div>
          <div>{moment(time).format("H:mm")}</div>
        </div>
      )}
      <div className="HistoryNavSlide__state center">
        <div className="HistoryNavSlide__state-mask">
          <div className={`HistoryNavSlide__state-flag center ${state}`}>
            {renderStateIcon()}
          </div>
        </div>
      </div>
      <div className="HistoryNavSlide__line" />
    </div>
  );
};

export default HistoryNavSlide;
