import React, { useCallback } from "react";
import AppSwitch from "../../../shared/appSwitch/appSwitch";
import { toggleInventoryMode } from "../../../redux/actions/userPreferencesActions";
import { setSelectedServiceView } from "../../../redux/actions/inventoryv3Actions";
import { useDispatch, useSelector } from "react-redux";
import { Divider } from "antd";

const ChartsToggles = ({ handleSetFilter }) => {
  const inventoryMode = useSelector((state) => state.userPreferencesReducer.inventory_mode);
  const screenFilters = useSelector((state) => state.inventoryReducer.inventoryFilters);
  const dispatch = useDispatch();

  const handleToggleInventoryMode = useCallback((item) => {
    dispatch(setSelectedServiceView(null));
    return dispatch(toggleInventoryMode(item, true));
  }, []);

  const handleToggleLiveDeletedMode = () => {
    return handleSetFilter('deleted');
  };

return (
    <div className="no-padding-card InventoryCharts__toggles col center">
      <div className="InventoryCharts__toggles-item">
        <AppSwitch
          items={[
            { value: "assets", text: "Assets" },
            { value: "types", text: "Types" },
          ]}
          active={inventoryMode}
          onChange={handleToggleInventoryMode}
        />
      </div>
      <Divider className="InventoryCharts__toggles-separator"/>
      <div className="InventoryCharts__toggles-item">
        <AppSwitch
          items={[
            { value: "live", text: "Live" },
            { value: "deleted", text: "Deleted" },
          ]}
          active={screenFilters?.deleted ? 'deleted' : 'live'}
          onChange={handleToggleLiveDeletedMode}
        />
      </div>
    </div>
  );
};

export default ChartsToggles;
