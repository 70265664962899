import React from "react";
import isEmpty from "lodash/isEmpty";

// components
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import { tableColumns } from "./moduleBlocksTableConfig";

import "./moduleBlocksTable.scss";
import { useDispatch, useSelector } from "react-redux";
import { setModulesFilters } from "../../../redux/actions/iacStacksActions";
import { emptyModulesScreenFilters } from "../../../consts/iacStacks";

const ModuleBlocksTable = ({
  treeData,
  setModuleViewAssets,
  stackProviders,
  setSelectedCategory
}) => {
  const modules = useSelector((state) => state.iacStacksReducer.modules?.modules);
  const dispatch = useDispatch();

  const groupingColDef = {
    headerName: "Module Call Name",
    flex: 3,
    valueGetter: (params) =>
      !isEmpty(params?.row?.vcsBlockPath) ? (
        <a
          href={params?.row?.vcsBlockPath}
          target="_blank"
          rel="noopener noreferrer"
        >
          {params?.row?.moduleBlockName}
        </a>
      ) : (
        params?.row?.moduleBlockName
      ),
  };

  const handleClickModuleGraph = async (sourceModuleId) => {
    if (!isEmpty(sourceModuleId)) {
      await dispatch(setModulesFilters({ ...emptyModulesScreenFilters, _id: [sourceModuleId] }));
      setSelectedCategory("modules");
    }
  };
  return (
    <div className="ModuleBlocksTable center col">
      <TableWrapper
        autoHeight
        height="auto"
        rowKey="reference"
        tableData={treeData}
        columns={tableColumns(
          setModuleViewAssets,
          stackProviders,
          modules,
          handleClickModuleGraph
        )}
        disablePagination
        hideRowCount
        disableSelectionOnClick
        treeData
        getTreeDataPath={(row) => row?.ancestry}
        groupingColDef={groupingColDef}
        iacTable
        innerTable
      />
    </div>
  );
};

export default ModuleBlocksTable;
