import { GET_DRIFTS_EXCLUSIONS, UPDATE_DRIFT_EXCLUSION } from "../actions/driftsExclusionsActions";
import _ from "lodash";

const initialState = {
  rules: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_DRIFTS_EXCLUSIONS:
      return { ...state, rules: payload.rules };

    case UPDATE_DRIFT_EXCLUSION:
      return { ...state };

    default:
      return state;
  }
};
