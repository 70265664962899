import React, { useState } from "react";
import { useDispatch } from "react-redux";
import { testExclusionRule } from "../../../redux/actions/exclusionsActions";
import Base64 from "../../../utils/base64";

// components
import WizardStepHeader from "../wizardStepHeader/wizardStepHeader";
import RegoCodeBlock from "../regoCodeBlock/regoCodeBlock";
import RegoAccordionsBlock from "../regoAccordionsBlock/regoAccordionsBlock";
import { appToast } from "../../../shared/appToast/appToast";
import "./insightRego.scss";
import _ from "lodash";

const InsightRego = ({ wizardState, updateWizard, readOnly }) => {
  const dispatch = useDispatch();
  const [loadingTest, setLoadingTest] = useState(false);

  const handleChangeCode = (val) => {
    return updateWizard({ ...wizardState, rego: val });
  };

  const handleTestRule = async () => {
    // validate the test button
    let error = '';
    if (_.isEmpty(wizardState?.name)) {
      error = "You must write a name for your insight";
    }
    if (_.isEmpty(wizardState?.scope)) {
      error="You must select asset type";
    }
    if (_.isEmpty(wizardState?.rego)) {
      error="You must to write a code to evaluate your rule";
    }

    if(!_.isEmpty(error)) {
      return appToast(
        "info",
        "",
        error
      );
    }

    setLoadingTest(true);
    const codeBase64 = Base64.encode(wizardState?.rego || '');

    const req = await dispatch(testExclusionRule(
        wizardState?.name,
        wizardState?.description,
        codeBase64,
        wizardState?.scope,
        wizardState?.providers,
        [],
        true
    ));
    updateWizard({...wizardState, test_results: req?.result || []});
    setLoadingTest(false);
  };

  return (
    <div className="InsightRego basic-card col g15">
      <WizardStepHeader type="rego" />
      <div className="InsightRego__wrapper">
        <RegoCodeBlock
          code={wizardState?.rego || ""}
          onChange={handleChangeCode}
          readOnly={readOnly}
          loadingTest={loadingTest}
          handleTestRule={handleTestRule}
        />
        <RegoAccordionsBlock readOnly={readOnly} wizardState={wizardState} />
      </div>
    </div>
  );
};

export default InsightRego;
