import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Button, Spin } from "antd";
import _ from "lodash";
import { TransformWrapper, TransformComponent } from "react-zoom-pan-pinch";
import AppModal from "../../../shared/appModal/appModal";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import { S3_ASSETS } from "../../../consts/config";
import { getStackStateFile, getStackMap } from "../../../redux/actions/iacStacksActions";

import "./mapModal.scss";

const MapModal = ({
  visible,
  handleClose,
  title,
  rowData,
  imageUrl,
  setImageUrl,
}) => {
  const dispatch = useDispatch();
  const noDataImg = `${S3_ASSETS}/illustrations/noDataPurple.svg`;
  const [isFetchingStack, setIsFetchingStack] = useState(false);
  const [showTools, setShowTools] = useState(false);

  useEffect(() => {
    if (visible) {
      getMapPhoto();
    }
  }, [visible]);

  const getMapPhoto = async () => {
    setIsFetchingStack(true);
    if (!_.isEmpty(rowData?.stateLocalPath)) {
      const stateRes = await dispatch(getStackStateFile(rowData?.stateLocalPath));
      if (!_.isEmpty(stateRes)) {
        const payload = {
          hcl: stateRes,
          png: true,
        };
        const image = await dispatch(getStackMap(payload));
        if (image?.ok) {
          setImageUrl(image?.imageUrl);
          setShowTools(true);
        } else {
          handleInvalidImage();
        }
      } else {
        handleInvalidImage();
      }
      setIsFetchingStack(false);
    }
  };

  const handleInvalidImage = () => {
    setImageUrl(noDataImg);
    setShowTools(false);
  };
  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      hideFooter
      bodyClassName="MapModal"
      width="70vw"
      centered
    >
      <span className="modal-title">{title}</span>
      <div className="center MapModal__content">
        {isFetchingStack ? (
          <Spin />
        ) : (
          !_.isEmpty(imageUrl) && (
            <TransformWrapper
              initialScale={1}
              minScale={-100}
              wheel={{
                disabled: true,
              }}
            >
              {({ zoomIn, zoomOut, resetTransform, centerView, ...rest }) => (
                <React.Fragment>
                  {showTools && (
                    <div className="row MapModal__content-tools">
                      <IconBtn
                        type="zoom-in"
                        onClick={() => zoomIn()}
                        colorClass="purple"
                      />
                      <IconBtn
                        type="zoom-out"
                        onClick={() => zoomOut()}
                        colorClass="purple"
                      />
                      <IconBtn
                        type="center"
                        onClick={() => centerView()}
                        colorClass="purple"
                      />
                      <IconBtn
                        type="reset"
                        onClick={() => resetTransform()}
                        colorClass="purple"
                      />
                    </div>
                  )}
                  <TransformComponent>
                    <img
                      src={imageUrl || noDataImg}
                      alt="map-object"
                    />
                  </TransformComponent>
                </React.Fragment>
              )}
            </TransformWrapper>
          )
        )}
      </div>
      <div className="MapModal__btns row">
        <Button
          className="app-form-button close"
          type="Default"
          onClick={handleClose}
        >
          Close
        </Button>
      </div>
    </AppModal>
  );
};

export default MapModal;
