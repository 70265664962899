import React, { useRef, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import TableWrapper from "../../../../shared/tableWrapper/tableWrapper";
import { capitalizeFirst } from "../../../../utils/formatting";
import { Tooltip } from "antd";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import Loading from "../../../../shared/loading/loading";
import DiagnosticArr from "./diagnostic/diagnosticArr";

const ApplyResources = ({ data = [], loading, applyCount, pageSize, setPageSize, setPageNumber }) => {
    const { t } = useTranslation("ci-pipeline");

    const apiRef = useRef();
    
    const dataLength = data?.length;
    const expandedRowIds = data.filter((element = {}) => !isEmpty(element.diagnostics)).map((element = {}) => element.id)

    const iconsMap = {
      create: <FontAwesomeIcon className="ApplyResources__action-icon create" icon="plus-circle" />,
      delete: <FontAwesomeIcon className="ApplyResources__action-icon delete" icon="minus-circle" />,
      update: <FontAwesomeIcon className="ApplyResources__action-icon update" icon="pen" />,
      read: <FontAwesomeIcon className="ApplyResources__action-icon read" icon="check-double" />,
      "no-op": <FontAwesomeIcon className="ApplyResources__action-icon no-op" icon="ban" />,
      import: <FontAwesomeIcon className="ApplyResources__action-icon import" icon="download" />,
      failed: <FontAwesomeIcon className="CiModal__modal-icon-failed" icon="times-circle" />,
      success: <FontAwesomeIcon className="CiModal__modal-icon-success" icon="check-double" />,
    }

    const getDetailPanelContent = useCallback((data = {}) => data.row?.diagnostics && <DiagnosticArr diagnostics={data.row.diagnostics} withHeaderBg={false} /> ,[]);
    const getDetailPanelHeight = useCallback(() => "auto", []);
    
    const handleRowClick = () => {
      return null;
    }

    const columns = [
        {
            headerName: t("columns.address"),
            field: "address",
            flex: 2,
            filterable: false,
            disableColumnMenu: true,
            renderCell: (params) => {
                apiRef.current = params.api;
                const address = params.row?.address;
                return (
                    <Tooltip title={address}>
                        {address}
                    </Tooltip>
                );
            },
        },
        {
          headerName: t("columns.change"),
          field: "action",
          width: 80,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          align: "center",
          renderCell: (params) => {
            const action = params.row?.action;
            const actionComponent = iconsMap[action] || action;
            return (
                <Tooltip title={capitalizeFirst(action)}>
                  {actionComponent}
                </Tooltip>
            );
          }
        },
        {
          headerName: t("columns.applyDuration"),
          field: "applyDurationSeconds",
          flex: 1,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const applyDuration = params.row?.applyDurationSeconds;
            const applyDurationInSeconds = applyDuration ? `${applyDuration}s`: '-'
            return <span>{applyDurationInSeconds}</span>
          }
        },
        {
          headerName:  t("columns.status"),
          field: "isFailed",
          flex: 1, 
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { isFailed = false } = params.row || {};
            const component = isFailed ? iconsMap.failed :  iconsMap.success
            const status = `APPLY ${isFailed ? "FAILED" : "COMPLETED"}`;
            return (
                <div className="row g5">
                  {component}
                  <span className={`ApplyResources__status ${isFailed ? 'failed' : 'success'}`}>{status}</span>
                </div>
            );
          },
        },
      ];
    
    if (loading) {
      return <Loading />;
    }

    if (!dataLength && !loading) {
        return (
          <div className="ApplyResources">
            <AppEmpty customStyle="code" text="No resources data" />
          </div>
        );
    }

    return (
      <div className="ApplyResources">
          <TableWrapper
              loading={loading}
              autoHeight={!!dataLength}
              height="100%"
              rowKey="id"
              tableData={data || []}
              columns={columns}
              disableSelectionOnClick
              getDetailPanelContent={getDetailPanelContent}
              getDetailPanelHeight={getDetailPanelHeight}
              pageSize={pageSize}
              rowsPerPageOptions={[10, 25, 50]}
              rowCount={applyCount > dataLength ? applyCount : dataLength}
              handlePageChange={setPageNumber}
              onPageSizeChange={setPageSize}
              onRowClick={handleRowClick}
              expandedRowIds={expandedRowIds}
              serverSide
          />
    </div>
    )

};

export default ApplyResources;