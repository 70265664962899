import React from 'react';
import isFunction from 'lodash/isFunction';
import { Button } from 'antd';
import { useTranslation } from 'react-i18next';

const WorkflowManagementFooter = ({ configWizard = [], currentStep, setCurrentStep, handleSubmit, loadingSubmit, prevDisabled, submitDisabled, setSubmitDisabled , handleClose}) => {
    const { t } = useTranslation('common');

    const onClickNextStep = () => {
        setSubmitDisabled(true);
        setCurrentStep(currentStep + 1);
    }
    
    const onClickPreviousStep = () => {
        setSubmitDisabled(false);
        setCurrentStep(currentStep - 1);
    }

    return (
        <div className="col g5">
            <div className="row between">
                <Button style={{ margin: "0 8px" }} onClick={handleClose} disabled={currentStep === configWizard.length - 1 && configWizard.length > 1}>
                Close
                </Button>
                <div className="WorkflowManagement__footer-paging">
                {currentStep > 0 && (
                    <Button
                        style={{ margin: "0 8px" }}
                        onClick={() => onClickPreviousStep()}
                        disabled={currentStep === configWizard.length - 1 || prevDisabled}
                    >
                        {t('previous')}
                    </Button>
                )}
                {currentStep < configWizard.length - 1 && (
                <Button
                    type="primary"
                    onClick={async () => {
                    if (isFunction(configWizard[currentStep].beforeNextStep)) {
                        try {
                        await configWizard[currentStep].beforeNextStep();
                        } catch (err) {
                        return;
                        }
                    }
                    if (configWizard[currentStep].skipNextStep && currentStep + 2 <= configWizard.length - 1) {
                        return setCurrentStep(currentStep + 2);
                    }
                    onClickNextStep();
                    }}
                    disabled={submitDisabled}
                    loading={loadingSubmit}
                >
                    {t('next')}
                </Button>
                )}

                {currentStep === configWizard.length - 1 && (
                <Button
                    type="primary"
                    htmlType="submit"
                    loading={loadingSubmit}
                    disabled={submitDisabled}
                    onClick={handleSubmit}
                >
                    {t('done')}
                </Button>
                )}
            </div>
            </div>
            <span className='WorkflowManagement__footer-comment'>Firefly will create your workflows via a PR to your Repository</span>
        </div>
    )
};

export default WorkflowManagementFooter;