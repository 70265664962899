import React from "react";
import { Form, Input, Tooltip, Checkbox, Tag } from "antd";
import _ from "lodash";
import { accountNumberRegex, validate } from "../../../utils/validations";
import { useSelector } from "react-redux";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdrivenGrey.svg";
import Loading from "../../../shared/loading/loading";
import regionsOptions from "../../../consts/regions";
import { DESCRIPTIONS } from "../../../consts/general";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EventDrivenTooltipBox from "./eventDrivenTooltipBox";
import AppToggle from "../../../shared/appToggle/appToggle";

const RoleValidation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
  isProd,
  handelSetIsProd,
  loadingInit,
  isEventDriven,
  setIsEventDriven,
  handleIacAutoDiscovery,
  iacAutoDiscovery,
  regions,
  setLabels,
}) => {
  const [form] = Form.useForm();
  const { CheckableTag } = Tag;
  const layout = {};

  const redactorPreference = useSelector(
    (state) => state.profilesReducer.redactorPreference
  );

  const externalId = useSelector(
    (state) => state.onbordingReducer?.account?.awsIntegrationExternalId
  );

  const checkValidation = (formValues) => {
    let formValid = true;
    const { accountNumber, redactorArn } = formValues;

    if (_.isEmpty(accountNumber)) {
      formValid = false;
    }

    if (
      !_.isEmpty(accountNumber) &&
      !validate(accountNumberRegex.regex, accountNumber)
    ) {
      formValid = false;
    }

    if (redactorPreference && _.isEmpty(redactorArn)) {
      formValid = false;
    }

    if (_.isEmpty(externalId)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...regions, tag]
      : regions?.filter((t) => t !== tag);
    setLabels(nextSelectedTags);
    checkValidation(form.getFieldsValue());
  };

  if (loadingInit) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="AwsIntegration__steps col">
      <div className="CreateRole col">
        <div className="col">
          <div
            className="dark-blue col"
            style={{ marginBottom: "20px", gap: "4px" }}
          >
            <span>
              Firefly’s AWS integration requires creating an{" "}
              <span className="code-flag">IAM Role</span> in your AWS account.
            </span>
            <span>
              The IAM role allows Firefly to perform read-only (Get, List, and
              Describe) access for the metadata of AWS services.
            </span>
            <span>Use the AWS CloudFormation template for deployment.</span>
          </div>
          <span className="dark-blue bold">Before you begin</span>
          <ul className="dark-blue CreateRole-list">
            <li key="1">
              <span className="dark-blue">
                Log in to your desired AWS account with permission to create
                CloudFormation and IAM AWS resources
              </span>
            </li>
            <li key="2">
              <span className="dark-blue">
                Copy your AWS account ID (located at the top right corner of the
                AWS console) to the clipboard
              </span>
            </li>
            <li key="3">
              <span className="dark-blue">
                Paste the AWS account ID in the box
              </span>
            </li>
          </ul>
        </div>
      </div>
      <div className="RoleValidation">
        <Form
          {...layout}
          name="aws-wizard-form"
          form={form}
          initialValues={{
            accountNumber: wizardState.accountNumber || null,
            selectAllRegions: { checked: true },
          }}
          className="AppWizard__content-body-form"
          onValuesChange={(
            changedValues,
            allValues // this is how you can get the form values
          ) => {
            if (!_.isUndefined(changedValues.selectAllRegions)) {
              if (changedValues.selectAllRegions) {
                setLabels(_.keys(regionsOptions));
              } else {
                setLabels([]);
              }
            }
            setWizardState(Object.assign(wizardState, allValues));
            checkValidation(allValues);
          }}
        >
          <Form.Item
            label="AWS Account ID"
            name="accountNumber"
            rules={[
              {
                required: true,
                message: "AWS Account ID is required",
              },
              {
                message: accountNumberRegex.msg,
                max: 12,
              },
            ]}
            style={{
              marginBottom: "1.5rem",
              flexDirection: "column",
              width: "100%",
            }}
          >
            <Input placeholder="" />
          </Form.Item>

          {redactorPreference && (
            <Form.Item
              label="Terraform Redactor Lambda ARN"
              name="redactorArn"
              tooltip="When the CloudFormation template finishes, copy the Terraform Redactor Lambda ARN from the Stack Outputs and paste it here"
              rules={[
                {
                  required: true,
                  message: "Terraform Redactor Lambda ARN is required",
                },
              ]}
              style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            >
              <Input placeholder="Please fill Terraform Redactor Lambda ARN" />
            </Form.Item>
          )}

          <div className="AwsIntegrationConfiguration__eventDriven">
            <div className="AwsIntegrationConfiguration__eventDriven-container">
                <AppToggle text="Event-driven" checked={isEventDriven} toggleChecked={() => setIsEventDriven(!isEventDriven)}/>
                <EventDriven
                  className="AwsIntegrationConfiguration__eventDriven-icon"
                  onClick={() => setIsEventDriven(!isEventDriven)}
                />
            </div>
          <div>
            {DESCRIPTIONS.awsEventDrivenNote}
              <Tooltip
                placement="topRight"
                title={<EventDrivenTooltipBox />}
                overlayInnerStyle={{
                  width: "350px",
                }}
              >
                <FontAwesomeIcon
                  icon="question-circle"
                  style={{
                    marginLeft: "5px",
                    cursor: 'pointer'
                  }}
                />
              </Tooltip>
            </div>
          </div>
          {isEventDriven && (
            <div className="col event-driven-block">
              <Form.Item
                label=""
                style={{ marginBottom: "0", flexDirection: "column" }}
              >
                <div className="RoleValidation__list">
                  {_.map(_.keys(regionsOptions), (tag) => (
                    <CheckableTag
                      key={tag}
                      checked={regions?.indexOf(tag) > -1}
                      onChange={(checked) => handleChange(tag, checked)}
                    >
                      {tag}
                    </CheckableTag>
                  ))}
                </div>
              </Form.Item>
              <Form.Item
                name="selectAllRegions"
                valuePropName="checked"
                style={{
                  marginBottom: "0.6rem",
                  marginTop: "1.2rem",
                }}
              >
                <Checkbox checked={true}>
                  <span className="form-label">Select all regions</span>
                </Checkbox>
              </Form.Item>
            </div>
          )}
        <div className="AwsIntegration__autoDiscovery col">
          <AppToggle text="Enable IaC Auto-Discovery" toggleChecked={handleIacAutoDiscovery} checked={iacAutoDiscovery} />
          <span className="AwsIntegration__autoDiscovery-subtitle form-label description-font-size">Allow Firefly to scan your S3 buckets and discover stack files </span>
        </div>
        </Form>
      </div>
    </div>
  );
};

export default RoleValidation;
