import React, { memo, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelFilter from "../../../inventory/inventoryFilters/inventorySingelFilter";
import SeverityCell from "../../insightsTable/severityCell";

const ScopesFilter = () => {
  const dispatch = useDispatch();

  const severityAggs = useSelector((state) => state.insightsV2Reducer.aggs?.severity);
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const handleSetSelectedSeverity = useMemo(() => (arr) => {
    const payload = { ...insightFilters, severity: arr };
    return dispatch(setInsightsFilters(payload));
  }, [dispatch, insightFilters]);

  const scopes = useMemo(() => {
    return (severityAggs || []).map((i) => ({
      name: i?.value,
      formattedName: '',
      element: <SeverityCell value={i?.value} />,
      count: i?.count,
    }));
  }, [severityAggs]);

  return (
    <InventorySingelFilter
      allTypes={scopes}
      selectedTypes={handleSetSelectedSeverity}
      checkedList={insightFilters?.severity || []}
      title="Search"
    />
  );
};

export default memo(ScopesFilter);