import React from "react";
import map from "lodash/map";
import "./singleFilter.scss";

const SingleFilter = ({ content, active, onClick, loading }) => {
  return (
    <div className="SingleFilter">
      {map(content || [], (f) => {
        return (
          <div
            style={{ cursor: loading ? "not-allowed" : "pointer" }}
            className="SingleFilter__inner"
            key={f?.key}
            onClick={() => onClick(f?.key)}
          >
            <div
              className={`SingleFilter__inner-item row g8 center ${
                active === f?.key ? "active" : ""
              }`}
            >
                {f?.icon}
              {f?.title}
            </div>
          </div>
        );
      })}
    </div>
  );
};

export default SingleFilter;
