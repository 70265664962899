import React, { useEffect, useState } from 'react';
import { Collapse } from 'antd';
import isEmpty from 'lodash/isEmpty';
import isString from 'lodash/isString';
import Loading from '../../../../shared/loading/loading';
import { useDispatch, useSelector } from 'react-redux';
import { getCodeFiles } from '../../../../redux/actions/ciWorkflowsActions';
import AppEmpty from '../../../../shared/appEmpty/appEmpty';
import { ReactComponent as FolderIcon } from "../../../../Images/general_icons/Folder_icon.svg";
import { ReactComponent as Chevron } from "../../../../Images/general_icons/chevron.svg";
import CodeDiff from '../../../../shared/codeDiff/codeDiff';

import './ciCode.scss';

const { Panel } = Collapse;

const CiCode = ({ codeId = '', filesCount = 0 }) => {
    const [loading, setLoading] = useState(false);
    const [activeFile, setActiveFile] = useState({});
    
    const codeFiles = useSelector((state) => state.ciWorkflowsReducer.codeFiles);
    const folders = Object.keys(codeFiles) || [];
    const [activeFolder, setActiveFolder] = useState("");

    const allFiles = Object.values(codeFiles).flat();
    const dispatch = useDispatch();

    const fetchCodeFiles = async () => {
        setLoading(true);
        const response = await dispatch(getCodeFiles(codeId));
        if (!isEmpty(response)) {
            const keys = Object.keys(response);
            setActiveFolder(keys[0]);
        }
        setLoading(false);
    };
    
    useEffect(() => {
        if (codeId && filesCount > 0) {
            fetchCodeFiles();
        }
    }, []);

    const onFolderClick = (folder) => setActiveFolder(folder === activeFolder ? '' : folder);
    const onFileClick = (file = {}, folder) => {
        setActiveFile(file);
        setActiveFolder(folder);
        const elementToScroll = document.getElementById(file.id);
        if (elementToScroll) elementToScroll.scrollIntoView({ behavior: "smooth" });
    }

    if (loading) return <Loading/>;
    if (isEmpty(codeFiles) && !loading) {
        return (
        <div className="CiCode center">
            <AppEmpty customStyle="code" text="The current Terraform plan does not contain any infrastructure changes" />
        </div>)
    }

    return (
        <div className="CiCode">
            <div className="CiCode__folders col">
                {folders.map((folder) => {
                    const files = codeFiles[folder] || [];
                    const isActiveFolder = activeFolder === folder;
                    return (
                        <div className="CiCode__folder col g10" key={folder}>
                            <div className={`CiCode__folder-titleContainer ${isActiveFolder ? 'active' : ""} row g8`} onClick={() => onFolderClick(folder)}>
                                <Chevron className={`CiCode__folder-titleContainer-arrow ${isActiveFolder ? "active" : ""}`} />
                                <FolderIcon />
                                <span className="CiCode__folder-titleContainer-title">{folder}</span>
                            </div>
                            {isActiveFolder && <div className="CiCode__folder-files">
                                <div className='row g15'>
                                    <div className='CiCode__folder-divider'/>
                                    <div className="col g10">
                                        {files.map(( file = {}) => <span className={`CiCode__folder-files-file ${ file.fileSuffix === activeFile.fileSuffix ? 'active' : ""} `} 
                                        onClick={() => onFileClick(file, folder)} key={file.id}>{file.fileSuffix}</span>)}
                                    </div>
                                </div>
                            </div>}
                        </div>
                    )
                })}
            </div>
            <div className="CiCode__content col g10">
                {allFiles.map((file = {}) => {
                    const { id = "", filePath = "", currentContent = "", previousContent = "" } =  file;
                    const oldValue = isString(previousContent) ? previousContent : JSON.stringify(previousContent, null, 2);
                    const newValue = isString(currentContent) ? currentContent : JSON.stringify(currentContent, null, 2);
                    return (
                    <div key={id} id={id}>
                        <Collapse className="CiCode__content-collapse" defaultActiveKey={id}>
                            <Panel header={filePath} key={id}>
                                <div className="CiCode__content-collapse-content">
                                 <CodeDiff oldValue={oldValue} newValue={newValue} lang="yaml" hideLineNumbers={false} />
                                </div>
                            </Panel>
                        </Collapse>
                    </div>)
            })}
            </div>
        </div>
    )
};

export default CiCode;