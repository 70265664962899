import React from "react";
import { Bar } from "@ant-design/charts";
import { useSelector } from "react-redux";
import _ from "lodash";
import { formatIfDecimal } from "../../utils/formatting";
import "./assetsLineChart.scss";

const AssetsLineChart = ({
  unmanaged,
  managed,
  modified,
  ghost,
  indexPosition,
  noIndex,
  height,
  noLabel,
  decimal,
  noTooltip,
  legend = {},
}) => {
  const dataArr = [
    {
      status: "Codified",
      Assets: "Assets",
      value: managed,
    },
    {
      status: "Drifted",
      Assets: "Assets",
      value: modified,
    },
    {
      status: "Unmanaged",
      Assets: "Assets",
      value: unmanaged,
    },
    {
      status: "Ghost",
      Assets: "Assets",
      value: ghost,
    },
  ];

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const renderIndexValue = (number, sum) => {
    if (decimal) {
      return formatIfDecimal((number / sum) * 100, 1) + "%";
    }
    return ((number / sum) * 100).toFixed(0) + "%";
  };

  const config = {
    data: dataArr,
    xField: "value",
    yField: "Assets",
    seriesField: "status",
    isPercent: true,
    isStack: true,
    xAxis: false,
    yAxis: false,
    height: height ? height : 38,
    maxBarWidth: 15,
    label: noLabel
      ? false
      : {
          position: "middle",
          content: function content(item) {
            const all = dataArr.reduce(function (prev, cur) {
              return prev + cur.value;
            }, 0);
            return all > 0 && item.value > 0
              ? (item.value * 100).toFixed(0) + "%"
              : null;
          },
          style: { fill: "#fff", opacity: 1, fontWight: "bold" },
        },
    color: ["#67CEBC", "#9872FE", "#D76089", "#F4B483"],
    intervalPadding: 0,
    tooltip: {
      domStyles: {
        "g2-tooltip": { 
          display: noTooltip ? "none" : "block", 
          backgroundColor: themeDark? '#212133' : 'white',
          color: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
         },
      },
      formatter: (data) => {
        const match = dataArr.find((item) => item?.status === data?.status);
        return { name: data?.status, value: match?.value };
      },
    },
    legend: noIndex
      ? false
      : {
          layout: "horizontal",
          position: indexPosition,
          itemValue: {
            formatter: (val, obj, idx) => {
              const match = dataArr.find((item) => item.status === obj?.id);
              const all = dataArr.reduce(function (prev, cur) {
                return prev + cur.value;
              }, 0);
              return match?.value > 0
                ? renderIndexValue(match?.value, all)
                : "-";
            },
            style: (item, index) => {
              return {
                fill: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
              };
            },
          },
          itemName: {
            style: (item, index) => {
              return {
                fill: themeDark? 'rgba(255, 255, 255, 0.801)' : '#515151',
              };
            },
          },
          animate: true,
          itemSpacing: legend.itemSpacing || 4,
          marker: {
            symbol: "circle",
          },
          flipPage: false, // THIS ATTERIBUTE IS FOR HIDING THE INDEX PAGINATION
          pageNavigator: {
            marker: {
              style: {
                inactiveFill: '#ccc',
                inactiveOpacity: 0.8,
                fill: '#515151',
                opacity: 0.8,
                size: 8,
              },
            },
            text: {
              style: {
                fill: '#515151',
                fontSize: 12,
              },
            },
          },
          itemHeight: legend.itemHeight || 17
        },
  };

  return (
    <div className="AssetsLineChart">
      <Bar {...config} />
    </div>
  );
};

export default AssetsLineChart;