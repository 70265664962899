import React, { useCallback, useEffect, useState } from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { useTranslation } from "react-i18next";
import { useDispatch } from "react-redux";

import ProvidersList from "../providersList/providersList";
import ProvisionLangs from "../provisionLangs/provisionLangs";
import GradientTextarea from "../../../shared/gradientTextarea/gradientTextarea";
import ProvisionCode from "../provisionCode/provisionCode";
import { requestProvision } from "../../../redux/actions/globalAppActions";
import { appToast } from "../../../shared/appToast/appToast";
import { ReactComponent as SendMessage } from "../../../Images/general_icons/send_msg_gradient.svg";
import prompts from "./prompts";
import ProvisionMessages from "./provisionMessages";



const ProvisionContent = ({ provisionData, setProvisionData, selectedFile, setSelectedFile }) => {
  const { t } = useTranslation(["compose", "common"]);
  const dispatch = useDispatch();

  const [selectedProvider, setSelectedProvider] = useState(prompts[0]);
  const [selectedLang, setSelectedLang] = useState(null);
  const [prompt, setPrompt] = useState(prompts[0]?.prefix);
  const [loading, setLoading] = useState(false);
  const [chatSessionId, setChatSessionId] = useState("");
  const [messages, setMessages] = useState([]);

  const messagesDiv = document.getElementsByClassName('ProvisionMessages col') || [];
  
  const responseMessages = t('compose:autoMessagesResponse', { returnObjects: true } );

  useEffect(() => {
    if (!isEmpty(messagesDiv) && !isEmpty(messages)) { 
      messagesDiv[0].scrollTop = messagesDiv[0].scrollHeight; //scroll to bottom of messages
    }
  }, [messages])

  const handleSetProvider = useCallback((obj) => {
    setSelectedProvider(obj);
    if (has(obj, "prefix")) {
      setPrompt(obj?.prefix);
    }
    if (has(obj, "languages")) {
      setSelectedLang(obj?.languages[0]?.name);
      setPrompt(obj?.languages[0]?.prefix);
    }
  }, []);

  const handleSetLanguage = useCallback((langObj) => {
    setSelectedLang(langObj?.name);
    if (has(langObj, "prefix")) {
      setPrompt(langObj?.prefix);
    }
  }, []);

  const handleRequestProvision = async () => {
    if (isEmpty(prompt)) {
      throw appToast("info", "", t("compose:errors.empty-prompt"));
    }
    if (loading) {
      throw appToast("info", "", t("compose:errors.loading"));
    }
    const text = prompt;
    const messagesBeforeResponse = [...messages, { text , isUser: true }];
    setMessages(messagesBeforeResponse);
    setLoading(true);
    setPrompt(""); //clear input
    const req = await dispatch(requestProvision(text, selectedProvider?.name, chatSessionId));
    const { output, sessionId } = req || {};
    setProvisionData(output);
    setSelectedFile(isEmpty(output) ? null : output[0]);
    setChatSessionId(sessionId);
    if (!isEmpty(output)) {
      const randResponseMsg = responseMessages[Math.floor(Math.random() * responseMessages.length)];
      setMessages([...messagesBeforeResponse, { text: randResponseMsg }]);
      setPrompt("");
    }
    setLoading(false);
  }

  const handleKeyDown = (event) => {
     if (event.key === 'Enter') {
      event.preventDefault();
      handleRequestProvision();
     }
  }
  const handleClear = () => {
    setMessages([]);
    setPrompt("");
    setSelectedFile(null);
    setProvisionData([]);
  }
  return (
    <div className="Compose row">
      <div className="Compose__languages col">
        <ProvidersList
          onClick={handleSetProvider}
          selected={selectedProvider?.name}
        />
        <div className="Compose__msgsPrompt col g10">
          <div className="row between">
            <ProvisionLangs
              options={
                has(selectedProvider, "languages")
                  ? selectedProvider?.languages
                  : []
              }
              selected={selectedLang}
              onClick={handleSetLanguage}
            />
          </div>
          <GradientTextarea
            rows="1"
            value={prompt}
            upperContent={<ProvisionMessages messages={messages} loading={loading}/>}
            insideButton={<SendMessage className="Compose__sendMsgIcon" onClick={handleRequestProvision}/>}
            placeholder={t("compose:prompt-placeholder")}
            onChange={(e) => setPrompt(e.target.value)}
            onKeyDown={handleKeyDown}
          />
          <div
            className="Compose__languages-btns g10 row"
            style={{ marginTop: "10px" }}
          >
            <button className="btn-cancel" onClick={handleClear}>
              {t("common:clear")}
            </button>
           {/* { isEmpty(messages) &&
            <GradientBtn
              text={t("compose:generate-btn")}
              onClick={handleRequestProvision}
              loading={loading}
              rows="2"
              disabled={loading}
              icon={<img src={img} alt="globe" />}
            /> } */}
          </div>
        </div>
      </div>
      <div className="Compose__code">
        <ProvisionCode
          data={provisionData}
          selected={selectedFile}
          setSelected={setSelectedFile}
          loading={loading}
        />
      </div>
    </div>
  );
};

export default ProvisionContent;
