import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Checkbox, Form, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  getConsulKeys,
  getConsulIntegrations,
  getConsulDatacenters,
} from "../../../redux/actions/consulIntegrationAction";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";
import FormLabels from "../../../shared/formLabels/formLabels";

const Consul = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  setTags,
  allTags,
  currentSelectedTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const consulIntegrations = useSelector(
    (state) => state.consulIntegrationReducer.integrations
  );

  const [datacenters, setDatacenters] = useState([]);
  const [stateFiles, setStateFiles] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedDatacenter, setSelectedDatacenter] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingDatacenters, setLoadinDatacenters] = useState(false);
  const [loadingStates, setLoadingStates] = useState(false);
  const [autoDiscover, setAutoDiscover] = useState(true);
  // fetch Consul Integrations when starting
  useEffect(() => {
    fetchIntegrations();
  }, []);

  // fetch all Consul on Integration change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration)) {
      fetchConsulDatacenters();
    }
  }, [selectedIntegration]);

  // fetch all Consul keys on Integration or Datacenter change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration) && !_.isEmpty(selectedDatacenter)) {
      fetchConsulKeys();
    }
  }, [selectedIntegration, selectedDatacenter]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getConsulIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchConsulDatacenters = async () => {
    setLoadinDatacenters(true);
    const res = await dispatch(getConsulDatacenters(selectedIntegration));
    if (!_.isEmpty(res)) {
      setDatacenters(res);
    }
    setLoadinDatacenters(false);
  };

  const fetchConsulKeys = async () => {
    setLoadingStates(true);
    const res = await dispatch(
      getConsulKeys(selectedIntegration, selectedDatacenter)
    );
    if (!_.isEmpty(res)) {
      setStateFiles(res);
    }
    setLoadingStates(false);
  };

  const renderConsulIntegrationsOptions = () => {
    if (!_.isEmpty(consulIntegrations)) {
      return _.map(consulIntegrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderConsulDatacenterOptions = () => {
    if (!_.isEmpty(datacenters)) {
      return _.map(datacenters || [], (datacenter) => {
        return (
          <Select.Option value={datacenter} key={datacenter}>
            {datacenter}
          </Select.Option>
        );
      });
    }
  };

  const renderConsulStatesOptions = () => {
    if (!_.isEmpty(stateFiles)) {
      return _.map(stateFiles || [], (state) => {
        return (
          <Select.Option value={state} key={state}>
            {state}
          </Select.Option>
        );
      });
    }
  };

  const setIsFormValidFlag = (formValues) => {
    handleSetIsFormValid(
      !_.isEmpty(formValues.consulIntegration) &&
        !_.isEmpty(formValues.dataCenter) &&
        (formValues.discovery === "auto" ||
          (!_.isEmpty(formValues?.terraformStates) &&
            formValues.discovery === "manual"))
    );
  };
  return (
    <Form
      {...layout}
      name="consul-settings-form"
      form={form}
      className="StateConfiguration"
      onValuesChange={(changedValues, allValues) => {
        // if the change was the value of selected all, set the selected states before moving forward
        if (!_.isUndefined(changedValues.selectAll)) {
          let stateList = changedValues.selectAll ? stateFiles : [];
          allValues.terraformStates = stateList;
          form.setFieldsValue({ terraformStates: stateList });
        }

        if (changedValues.discovery === "manual") {
          form.setFieldsValue({ terraformStates: [] });
        }
        if (changedValues.discovery === "auto") {
          form.setFieldsValue({ terraformFolder: [] });
        }

        changeSelectedStateSettings(allValues);
        setIsFormValidFlag(allValues);
      }}
    >
      <Form.Item
        label="Consul Integration"
        name="consulIntegration"
        help={
          !loadingIntegrations && _.isEmpty(consulIntegrations) ? (
            <Link to="/integrations/consul-integration">
              + Add Consul Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Consul Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select Consul Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderConsulIntegrationsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="Datacenter"
        name="dataCenter"
        rules={[
          {
            required: true,
            message: "selecting a Datacenter is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select Data center"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(bucket) => setSelectedDatacenter(bucket)}
          loading={loadingDatacenters}
        >
          {renderConsulDatacenterOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="Terraform states"
        name="discovery"
        rules={[
          {
            required: true,
            message: "selecting a terraform discovery is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select terraform discovery"
          onSelect={(discovery) => setAutoDiscover(discovery === "auto")}
        >
          <Select.Option value="auto" key="auto">
            <FontAwesomeIcon
              icon={faSatelliteDish}
              style={{ marginRight: "5px" }}
            />{" "}
            Auto-Discover your Datacenter
          </Select.Option>
          <Select.Option value="manual" key="manual">
            Select specific keys
          </Select.Option>
        </Select>
      </Form.Item>
      {autoDiscover ? null : (
        <div>
          <Form.Item
            label="Keys"
            name="terraformStates"
            rules={[
              {
                required: true,
                message: "selecting a Key is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Select
              placeholder="Please select Keys"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="tags"
              onSelect={(state) => {
                let tmpSelectedStates = selectedStates;
                tmpSelectedStates.push(state);
                return setSelectedStates(tmpSelectedStates);
              }}
              onDeselect={(state) => {
                let tmpSelectedStates = selectedStates;
                _.remove(tmpSelectedStates, (item) => item === state);
                return setSelectedStates(tmpSelectedStates);
              }}
              loading={loadingStates}
            >
              {renderConsulStatesOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="selectAll" valuePropName="checked">
            <Checkbox checked={false} disabled={_.isEmpty(stateFiles)}>
              <span className="selectAll__checbox">Select All</span>
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};
export default Consul;
