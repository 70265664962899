import React from "react";
import HeaderSearchBox from "../../../shared/headerSearchBox/headerSearchBox";
import { useDispatch, useSelector } from "react-redux";
import { setSearchVal } from "../../../redux/actions/globalAppActions";
import "./headerSearch.scss";

const HeaderSearch = ({ width }) => {
  const dispatch = useDispatch();
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  return (
    <div className="HeaderSearch row">
      <HeaderSearchBox
        placeholder="Search"
        value={(val) => {
          dispatch(setSearchVal(val));
        }}
        currentValue={searchVal}
        resetSearch={(val) => {
          dispatch(setSearchVal(val));
        }}
        width={width}
      />
    </div>
  );
};

export default HeaderSearch;
