import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faFlag } from "@fortawesome/free-solid-svg-icons";

const MarkAsProduction = ({ edit }) => {
  return (
    <div className="col">
      <span className="form-label">
        Mark as Production
        <FontAwesomeIcon
          icon={faFlag}
          style={{ marginLeft: "10px", opacity: ".7" }}
        />
      </span>
      {edit ? (
        <span className="form-label description-font-size">
          In the <b>Dashboard</b>, you can view production accounts separately.
        </span>
      ) : (
        <span className="form-label description-font-size">
          This account will be marked as production. You can edit this at any
          time in the <b>Integrations</b> window. Once done, you will be able to view the production accounts separately in the <b>Dashboard</b>.
        </span>
      )}
    </div>
  );
};

export default MarkAsProduction;
