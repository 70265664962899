// provider icons
import awsIcon from "../Images/clouds/AWS.svg";
import awsDarkIcon from "../Images/clouds/AWSDark.svg";
import purpleAws from "../Images/clouds/purpleAws.svg";
import whiteAws from "../Images/clouds/whiteAws.svg";
import blackAws from "../Images/clouds/blackAws.svg";
import gcp from "../Images/clouds/Google.svg";
import purpleGcp from "../Images/clouds/purpleGcp.svg";
import whiteGcp from "../Images/clouds/whiteGcp.svg";
import blackGcp from "../Images/clouds/blackGcp.svg";
import azureIcon from "../Images/clouds/Azure.svg";
import purpleAzure from "../Images/clouds/purpleAzure.svg";
import whiteAzure from "../Images/clouds/whiteAzure.svg";
import blackAzure from "../Images/clouds/blackAzure.svg";
import kubernetes from "../Images/clouds/kubernetes.svg";
import kubernetesDark from "../Images/clouds/kubernetesDark.svg";
import whiteK8s from "../Images/clouds/whiteK8s.svg";
import blackK8s from "../Images/clouds/blackK8s.svg";
import purpleK8s from "../Images/clouds/purpleK8s.svg";
import oracle from "../Images/clouds/oracle.svg";
import alibaba from "../Images/clouds/alibaba.svg";
import datadog from "../Images/clouds/datadog.svg";
import datadogDark from "../Images/clouds/datadogDark.svg";
import cloudflare from "../Images/clouds/cloudFlare.svg";
import akamai from "../Images/clouds/akami.svg";
import miniAkamai from "../Images/clouds/mini_akamai.svg";
import newrelic from "../Images/clouds/newrelic.svg";
import newrelicDark from "../Images/clouds/newrelicDark.svg";
import miniNewrelic from "../Images/clouds/mini_newrelic.svg";
import miniNewrelicDark from "../Images/clouds/mini_newrelic_dark.svg";
import unknown from "../Images/clouds/unknown.svg";
import okta from "../Images/clouds/okta.svg";
import oktaDark from "../Images/clouds/oktaDark.svg";
import kafka from "../Images/clouds/kafka.svg";
import kafkaDark from "../Images/clouds/kafkaDark.svg";
import grafana from "../Images/clouds/grafana.svg";
import chef from "../Images/clouds/chef.svg";
import spot from "../Images/clouds/spot.svg";
import postgresql from "../Images/clouds/postgresql.svg";
import helmDark from "../Images/clouds/helmDark.svg";
import auth0 from "../Images/clouds/auth0.svg";
import mongodb from "../Images/clouds/mongodb.svg";
import vault from "../Images/clouds/vault.svg";
import vaultDark from "../Images/clouds/vaultDark.svg";
import torq from "../Images/clouds/torq.svg"
import ns1 from "../Images/clouds/NS1.svg"
import dark_ns1 from "../Images/clouds/dark_ns1.svg"
import deploymentManager from "../Images/clouds/deploymentManager.svg"
import cloudComposer from "../Images/clouds/cloud_composer.svg"  
import apacheAirflow from "../Images/clouds/apache_airflow.svg"
import googleCloudSdk from "../Images/clouds/googleCloudSdk.svg"
import cdk8s from "../Images/clouds/cdk8s.svg"
import gke from "../Images/clouds/gke.svg"
import http from "../Images/general_icons/http.svg"
import purpleSaas from "../Images/clouds/purpleSaas.svg"
import saas from "../Images/clouds/saas.svg"
import darkSaas from "../Images/clouds/darkSaas.svg"

// iac providers
import helm from "../Images/iacProviders/helm.svg";
import greenHelm from "../Images/iacProviders/green_helm.svg";
import pulumi from "../Images/iacProviders/pulumi.svg";
import darkPurplePulumi from "../Images/iacProviders/darkPurplePulumi.svg";
import whitePulumi from "../Images/iacProviders/whitePulumi.svg";
import terraform from "../Images/iacProviders/terraform.svg";
import purpleTerraform from "../Images/iacProviders/purpleTerraform.svg";
import darkPurpleTerraform from "../Images/iacProviders/darkPurpleTerraform.svg";
import whiteTerraform from "../Images/iacProviders/whiteTerraform.svg";
import greenTerraform from "../Images/iacProviders/green_terraform.svg";
import greenPulumi from "../Images/iacProviders/green_pulumi.svg";
import greyTerraform from "../Images/iacProviders/greyTerraform.svg";
import cloudFormation from "../Images/iacProviders/cloudFormation.svg";
import greenCloudFormation from "../Images/iacProviders/green_cloud_formation.svg";
import greyCloudFormation from "../Images/iacProviders/greyCloudFormation.svg";
import purpleCloudFormation from "../Images/iacProviders/purpleCloudFormation.svg";
import darkPurpleCloudFormation from "../Images/iacProviders/darkPurpleCloudFormation.svg";
import whiteCloudFormation from "../Images/iacProviders/whiteCloudFormation.svg";
import cdk from "../Images/iacProviders/cdk.png";
import purpleHelm from "../Images/iacProviders/purpleHelm.svg";
import darkPurpleHelm from "../Images/iacProviders/darkPurpleHelm.svg";
import purplePulumi from "../Images/iacProviders/purple_pulumi.svg";
import argocd from "../Images/iacProviders/argocd.svg";
import cloudDeploy from "../Images/iacProviders/cloudDeploy.svg";
import kustomize from "../Images/iacProviders/kustomize.svg"
import greenKustomize from "../Images/iacProviders/greenKustomize.svg"
import purpleKustomize from "../Images/iacProviders/purpleKustomize.svg"
import darkPurpleKustomize from "../Images/iacProviders/darkPurpleKustomize.svg"
import whiteKustomize from "../Images/iacProviders/whiteKustomize.svg"
import whiteArgo from "../Images/iacProviders/whiteArgo.svg";
import blackArgo from "../Images/iacProviders/blackArgo.svg";
import greenArgo from "../Images/iacProviders/greenArgo.svg";
import purpleArgo from "../Images/iacProviders/purpleArgo.svg";
import darkPurpleArgo from "../Images/iacProviders/darkPurpleArgo.svg";
import greenCrossplane from "../Images/iacProviders/greenCrossplane.svg";
import greenExternalDns from "../Images/iacProviders/greenExternalDns.svg";
import greenEksctl from "../Images/iacProviders/greenEks.svg";
import greenGke from "../Images/iacProviders/greenGke.svg";
import greenCloudComposer from "../Images/iacProviders/greenCloudComposer.svg";
import greenDeploymentManager from "../Images/iacProviders/greenDeploymentManager.svg";
import azureBackend from "../Images/iacProviders/Storage-Accounts.svg"

// integrations
import github from "../Images/integrations/github.svg";
import githubDark from "../Images/integrations/githubDark.svg";
import gitlab from "../Images/integrations/gitlab.svg";
import bitbucket from "../Images/integrations/bitbucket.svg";
import webhook from "../Images/integrations/webhooks.svg";
import teams from "../Images/integrations/teams.svg";
import EmailIcon from "../Images/general_icons/Email_icon.svg";
import slack from "../Images/integrations/slack.svg";
import terraformCloud from "../Images/integrations/terraform_cloud.svg";
import terragrunt from "../Images/general_icons/terragrunt.png";
import opa from "../Images/general_icons/opa.svg";
import git from "../Images/general_icons/git.svg";
import consul from "../Images/integrations/consul.svg";
import gcs from "../Images/integrations/gcs.svg";
import opsgenie from "../Images/integrations/opsgenie.svg";
import pagerduty from "../Images/integrations/pagerduty.svg";
import discord from "../Images/integrations/discord.svg";
import codecommit from "../Images/integrations/codecommit.svg"
import smallCDK from "../Images/integrations/cdk.svg"
import bash from "../Images/integrations/bash.svg"
import ansible from "../Images/integrations/ansible.svg"
import docker from "../Images/integrations/docker.svg"
import bicep from "../Images/integrations/bicep.svg"
import crossplane from "../Images/integrations/crossplane.svg"
import azuredevops from "../Images/integrations/azuredevops.svg"
import azurePipelines from "../Images/integrations/azure-pipelines.svg"
import jenkins from '../Images/general_icons/jenkins.svg'
import powershell from '../Images/general_icons/powershell.svg'

// codify
import arm from '../Images/codify/azure_resource_manager.svg'

// buckets
import aws_bucket from "../Images/general_icons/aws_bucket.svg";
import gcp_bucket from "../Images/general_icons/gcp_bucket.png";
import servicenow from "../Images/general_icons/service_now.svg"

//projects
import jira from "../Images/general_icons/jira.svg";

// insights
import misconfiguration from "../Images/general_icons/misconfiguration.svg";
import reliability from "../Images/general_icons/reliability.svg";
import optimization from "../Images/general_icons/optimization.svg";
import _ from "lodash";
import { CODIFY_TYPES } from "../consts/general";

export const iacProviders = (key, dark) => {
  let obj = {
    helm: dark ? helmDark : helm,
    pulumi,
    purplePulumi,
    darkPurplePulumi,
    whitePulumi,
    terraform,
    purpleTerraform,
    darkPurpleTerraform,
    whiteTerraform,
    greyTerraform,
    cloudFormation,
    cloudformation: cloudFormation,
    greyCloudFormation,
    purpleCloudFormation,
    purpleCloudformation: purpleCloudFormation,
    darkPurpleCloudFormation,
    darkPurpleCloudformation: darkPurpleCloudFormation,
    whiteCloudformation: whiteCloudFormation,
    kubernetes: dark ? kubernetesDark : kubernetes,
    k8s: dark ? kubernetesDark : kubernetes,
    terragrunt,
    cdk,
    purpleHelm,
    darkPurpleHelm,
    whiteHelm: helmDark,
    argocd,
    cloudDeploy,
    kustomize,
    whiteKustomize,
    purpleKustomize,
    darkPurpleKustomize,
    argo: dark ? whiteArgo : blackArgo,
    whiteArgo,
    darkPurpleArgo,
    purpleArgo,
    crossPlane: crossplane,
    externalDns: greenExternalDns,
    eksctl: greenEksctl,
    gke,
    'goog-dm': deploymentManager,
    composer: cloudComposer,
    airflow: apacheAirflow,
    azureBackend,
  };
  return _.isEmpty(obj[key]) ? unknown : obj[key];
};

export const greenIacProviders = {
  helm: greenHelm,
  pulumi: greenPulumi,
  terraform: greenTerraform,
  cloudFormation: greenCloudFormation,
  cloudformation: greenCloudFormation,
  kustomize: greenKustomize,
  k8s: greenArgo,
  crossPlane: greenCrossplane,
  crossplane: greenCrossplane,
  externalDns: greenExternalDns,
  eksctl: greenEksctl,
  gke: greenGke,
  'goog-dm': greenDeploymentManager,
  composer: greenCloudComposer,
  airflow: apacheAirflow,
};

export const purpleIacProviders = {
  helm: purpleHelm,
  pulumi: purplePulumi,
  terraform: terraform,
  cloudFormation: purpleCloudFormation,
  cloudformation: purpleCloudFormation,
  kustomize: purpleKustomize,
  k8s: purpleArgo,
};

export const integrationIcons = {
  github,
  gitlab,
  bitbucket,
  webhook,
  teams,
  slack,
  terraformCloud,
  opa,
  consul,
  gcs,
  opsgenie,
  git,
  pagerduty,
  discord,
  torq,
  slackApp: slack,
  codecommit,
  cdk: smallCDK,
  bash,
  ansible,
  docker,
  bicep,
  crossplane,
  azuredevops,
  powershell,
  email: EmailIcon
};

export const buckets = {
  aws_bucket,
  gcp_bucket,
  consul,
  cloudFormation,
  cloudformation: cloudFormation,
  pulumi,
};

export const projects = {
  jira,
  servicenow,
};

export const insights = {
  misconfiguration,
  reliability,
  optimization,
};

export const clouds = (key, dark) => {
  let obj = {
    aws: dark ? awsDarkIcon : awsIcon,
    awsDark: awsDarkIcon,
    plainAws: dark ? whiteAws : blackAws,
    purpleAws,
    gcp,
    plainGcp: dark ? whiteGcp : blackGcp,
    purpleGcp,
    google: gcp,
    azure: azureIcon,
    azurerm: azureIcon,
    purpleAzure,
    purpleAzurerm:purpleAzure,
    plainAzure: dark ? whiteAzure : blackAzure,
    plainAzurerm: dark ? whiteAzure : blackAzure,
    k8s: dark ? kubernetesDark : kubernetes,
    kubectl: dark ? kubernetesDark : kubernetes,
    kubernetes: dark ? kubernetesDark : kubernetes,
    k8sDark: kubernetesDark,
    purpleK8s,
    plainK8s: dark ? whiteK8s : blackK8s,
    oracle,
    alibaba,
    datadog: dark ? datadogDark : datadog,
    cloudflare,
    akamai: miniAkamai,
    full_akamai: akamai,
    newrelic: dark ? miniNewrelicDark : miniNewrelic,
    full_newrelic: dark ? newrelicDark : newrelic,
    none: unknown,
    okta: dark ? oktaDark : okta,
    github: dark ? githubDark : github,
    pagerduty,
    kafka: dark ? kafkaDark : kafka,
    kafkaDark,
    helm: dark ? helmDark : helm,
    helmDark,
    grafana,
    consul,
    chef,
    spot,
    postgresql,
    auth0,
    mongodb,
    mongodbatlas: mongodb,
    mongo: mongodb,
    null: terraform,
    local: terraform,
    random_string: terraform,
    vault : dark ? vaultDark : vault,
    vaultDark,
    bitbucket,
    pulumi,
    terraform,
    gitlab,
    ns1: dark? dark_ns1 : ns1,
    codecommit,
    azuredevops,
    gcs,
    tfc:terraform,
    slack,
    teams,
    torq,
    jira,
    webhook,
    opsgenie,
    gke,
    random:terraform,
    time:terraform,
    purpleSaas,
    plainSaas: dark ? darkSaas : saas
  };
  return _.isEmpty(obj[key]) ? unknown : obj[key];
};

export const moduleOriginTypes = (key, dark) => {
  let obj = {
    s3: aws_bucket,
    gcs: gcp_bucket,
    registry: terraform,
    github: dark ? githubDark : github,
    gitlab,
    codecommit,
    azuredevops,
    bitbucket,
    git,
    http,
  };
  return _.isEmpty(obj[key]) ? unknown : obj[key];
};

export const remoteTypes = (key, dark) => {
  let obj = {
    s3: aws_bucket,
    gcs: gcp_bucket,
    consul,
    cloudformation: cloudFormation,
    pulumi,
    k8s: dark ? kubernetesDark : kubernetes,
    tfc: terraform,
    'gitlab-s3': aws_bucket,
    azure: azureIcon,
    azurerm: azureIcon,
  };
  return _.isEmpty(obj[key]) ? unknown : obj[key];
};

export const codifyIcons = (key, dark) => {
  const obj = {
    [CODIFY_TYPES.terraform]: terraform,
    [CODIFY_TYPES.pulumi]: pulumi,
    [CODIFY_TYPES.cloudformation]: cloudFormation,
    [CODIFY_TYPES.helm]: dark ? helmDark : helm,
    [CODIFY_TYPES.cdk]: cdk,
    [CODIFY_TYPES.crossplane]: crossplane,
    [CODIFY_TYPES.ansible]: ansible,
    [CODIFY_TYPES.deploymentManager]: deploymentManager,
    [CODIFY_TYPES.configConnector]: gke,
    [CODIFY_TYPES.googleCloudSdk]: googleCloudSdk,
    [CODIFY_TYPES.cdk8s]: cdk8s,
    [CODIFY_TYPES.manifest]: dark ? kubernetesDark : kubernetes,
    [CODIFY_TYPES.tfcdk]: terraform,
    [CODIFY_TYPES.arm]: arm,
    [CODIFY_TYPES.bicep]: bicep,
  }
  return obj[key];
}

export const ciDataSourceIcons = (key, dark) => {
  const obj = {
    github: dark ? githubDark: github,
    "github-actions": dark ? githubDark: github,
    gitlab,
    "gitlab-pipelines": gitlab,
    "azure-pipelines": azurePipelines,
    "bitbucket-pipelines": bitbucket,
    "codecommit-pipelines": codecommit,
    bitbucket,
    codecommit,
    azuredevops,
    jenkins,
    terraform,
  }
  return obj[key];
}