import _ from "lodash";
import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const FETCH_REVISIONS = "FETCH_REVISIONS";
export const FETCH_REVISION_BY_FRN = "FETCH_REVISION_BY_FRN";
export const COMPARE_HISTORY = "COMPARE_HISTORY";
export const RESET_HISTORY = "RESET_HISTORY";

const getHistoryRevisionsByFrn = (provider, integrationId, assetType, frn, queryPage, pageSize) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/timefly/getRevisions`,
      "POST",
      { provider, integrationId, assetType, frn, queryPage, pageSize }
    );

    let data = [];
    if (req.ok) {
      data = await req.json();
    }
    dispatch({
      type: FETCH_REVISIONS,
      payload: data,
    });
    return data;
  });
};

const getHistoryRevisionById = (provider, integrationId, assetType, frn, runId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/timefly/getRevision`,
      "POST",
      { provider, integrationId, assetType, frn, runId }
    );

    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    return dispatch({
      type: FETCH_REVISION_BY_FRN,
      payload: data,
    });
  });
};

const compareHistory = (provider, integrationId, assetType, frn, oldRunId, currentRunId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/timefly/compare/v2`,
      "POST",
      { provider, integrationId, assetType, frn, revision1: oldRunId, revision2: currentRunId }
    );

    let data = {};

    if (req.ok) {
      data = await req.json();
    }
    return dispatch({
      type: COMPARE_HISTORY,
      payload: data,
    });
  });
};

const resetHistoryState = () => {
  return action(async (dispatch) => {
    return dispatch({
      type: RESET_HISTORY,
    });
  });
};

const getDeletedConfig = (frn, assetType) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/timefly/deleted-config`,
      "POST",
      { frn, assetType }
    );

    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    return data;
  });
};

export { getHistoryRevisionsByFrn, getHistoryRevisionById, resetHistoryState, compareHistory, getDeletedConfig };
