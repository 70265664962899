import React, { useEffect } from "react";

const AwsOverview = ({ setSubmitDisabled }) => {
  useEffect(() => {
    setSubmitDisabled(false);
  }, []);

  return (
    <div className="AwsOverview col">
      <ul>
        <li><span className="small-dark-blue">Firefly is a Cloud Asset Management solution that enables Cloud Owners to discover their entire cloud and better manage it.</span></li>
        <li><span className="small-dark-blue">To enable cloud scanning, Firefly asks for a Read-Only permission set called Security-Audit, which allows the scanning for the configuration of cloud resources, and not their data.</span></li>
        <li><span className="small-dark-blue">For example, Firefly will know about the existence of a storage bucket, but can’t read or know about the objects inside it.</span></li>
        <li><span className="small-dark-blue">To discover IaC state files (and specifically Pulumi’s stacks), Firefly asks for Read-Only permissions [S3:GetObject] to AWS S3 Buckets which hold “.tfstate” files.</span></li>
      </ul>
 
      <span className="small-dark-blue">
      <a href="https://github.com/infralight/pulumi-firefly-aws-read-only/blob/main/aws-ts-onboarding/" target="_blank" rel="noreferrer">Firefly's Readonly Pulumi Provider</a> is compatible with{" "}
          Pulumi v3.21.1
      </span>
    </div>
  );
};

export default AwsOverview;
