import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { createVcsIntegration } from "../../../redux/actions/vcsActions";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import { integrationIcons } from "../../../utils/icons";
import DevOpsForm from "./devOpsForm";
import { VCS_TYPES } from "../../../consts/general";
import "./devOpsIntegration.scss";

const DevOpsIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Azure DevOps Integration setup",
      content_title: "Azure DevOps Integration",
      content: (
        <DevOpsForm
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const req = await dispatch(
          createVcsIntegration(
            VCS_TYPES.azuredevops, {
            name: wizardState?.name,
            token: wizardState?.token,
          })
        );
        setLoadingSubmit(false);
        if (!req?.ok) {
          throw new Error(`Failed to create NS1 integration.`);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="DevOpsIntegration">
      <AppWizard
        iconSrc={integrationIcons[VCS_TYPES.azuredevops]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default DevOpsIntegration;
