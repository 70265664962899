import { filter, isEmpty, map } from "lodash";
import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import VulnerabilityLine from "../../../shared/vulnerabilityLine/vulnerabilityLine";
import { getVulnerabilityData } from "../../../utils/helpers";
import "./moduleBlocksTable.scss";

export const tableColumns = (
  setModuleViewAssets,
  stackProviders,
  modules,
  handleClickModuleGraph
) => {
  const getSupportedProviders = () => {
    let supportedProviders = [];
    if (!isEmpty(stackProviders)) {
      supportedProviders = filter(
        stackProviders,
        (provider) => provider?.isProviderSupported
      );
      supportedProviders = map(
        supportedProviders,
        (provider) => provider?.providerIntegrationId
      );
    }
    return supportedProviders;
  };

  const getModuleRowData = (sourceModuleId) => {
    const module = modules?.find((mod) => mod?.id === sourceModuleId);
    return module ? module : null;
  };

  const formatLink = (sourceModuleName) => {
    if (sourceModuleName?.startsWith("github.com")) {
      const splittedLink = sourceModuleName?.split("/");
      return (
        <a
          href={`https://${sourceModuleName}`}
          target="_blank"
          rel="noopener noreferrer"
        >
          {splittedLink[splittedLink?.length - 1]}
        </a>
      );
    }
    return sourceModuleName;
  };

  return [
    {
      headerName: "Module Name",
      field: "sourceModuleName",
      flex: 3,
      renderCell: (params) =>
        !isEmpty(params?.row?.vcsSourceModulePath) ? (
          <a
            href={params?.row?.vcsSourceModulePath}
            target="_blank"
            rel="noopener noreferrer"
          >
            {params?.row?.sourceModuleName}
          </a>
        ) : isEmpty(params?.row?.sourceModuleName) ? (
          "Not found in an integrated Git"
        ) : (
          formatLink(params?.row?.sourceModuleName)
        ),
    },
    {
      headerName: "Module Version",
      field: "sourceModuleVersion",
      flex: 2,
      renderCell: (params) =>
          isEmpty(params?.row?.sourceModuleVersion) ? (
              "-"
          ) : (
              params?.row?.sourceModuleVersion
          ),
    },
    {
      headerName: "Misconfigurations",
      field: "id",
      flex: 3,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        const module = getModuleRowData(params?.row?.sourceModuleId);
        return (
          <VulnerabilityLine
            data={getVulnerabilityData(module)}
            onClick={() => handleClickModuleGraph(params?.row?.sourceModuleId)}
            customStyle={!isEmpty(params?.row?.sourceModuleId) ? { cursor: "pointer" } : ""}
          />
        );
      },
    },
    {
      headerName: "View Assets",
      field: "frns",
      flex: 2,
      filterable: false,
      renderCell: (params) =>
        !isEmpty(params?.row?.frns) ? (
          <div
            className="row ModuleBlocksTable__flag"
            onClick={(e) => {
              e.stopPropagation();
              setModuleViewAssets({
                name: params?.row?.moduleBlockName,
                frns: params?.row?.frns,
                integrationIds: getSupportedProviders(),
              });
            }}
          >
            <FontAwesomeIcon icon="layer-group" />
            {params?.row?.frns?.length}
          </div>
        ) : (
          ""
        ),
    },
  ];
};
