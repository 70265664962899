import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";

import './copyBox.scss';

const CopyBox = ({ text, darkMode, wordBreak }) => {
    const [isCopied, setIsCopied] = useState(false);

    const onCopyClicked = () => {
        setIsCopied(true);
        setTimeout(() => {
            setIsCopied(false);
        }, 1500);
      };

    return (
        <div className={`CopyBox row between ${darkMode && "darkMode"} ${wordBreak && "wordBreak"}`}>
            <span className="CopyBox__text">
              {text}
            </span>
            <CopyToClipboard
              text={text}
              onCopy={onCopyClicked}
            >
              {isCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className={`CopyBox__icon active ${darkMode && "darkMode"}`}
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className={`CopyBox__icon ${darkMode && "darkMode"}`}
                />
              )}
            </CopyToClipboard>
        </div>
    )
};

export default CopyBox;
