import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";

const CreationStep = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const [type, setType] = useState("slackApp"); // slackApp || slackWebhook;
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, webhookUrl } = formValues;

    if (_.isEmpty(name) || _.isEmpty(webhookUrl)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const renderIntegrationsTypes = () => {
    const types = [
      {
        value: "slackApp",
        name: "Slack App",
      },
      {
        value: "slackWebhook",
        name: "Slack Webhook",
      },
    ];
    return _.map(types || [], (item) => {
      return (
        <Select.Option value={item.value} key={item.value}>
          {item.name}
        </Select.Option>
      );
    });
  };

  return (
    <div className="CreationStep col">
      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name,
          webhookUrl: wizardState.webhookUrl || "",
          type: "slackApp",
        }}
        className="CreationStep__info col"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <div className="CreationStep__info dark-blue">
          Select the desired integration type.
        </div>
        <Form.Item
          label="Integration type"
          name="type"
          rules={[
            {
              required: true,
              message: "Integration type is required",
            },
          ]}
          style={{ marginBottom: "10px", flexDirection: "column" }}
        >
          <Select
            placeholder="Integration type selection"
            onSelect={(integType) => setType(integType)}
          >
            {renderIntegrationsTypes()}
          </Select>
        </Form.Item>
        <div className="dark-blue CreationStep__info">
          <span className="dark-blue bold">Procedure</span>
          <ol className="dark-blue CreationStep__info-orderedlist">
            {type === "slackApp" ? (
              <ol className="dark-blue CreationStep__info-orderedlist">
                <li key="1">
                  To install the Firefly Slack application, go{" "}
                  <a
                    href="https://slack.com/oauth/v2/authorize?client_id=1492740204002.2875994121623&scope=channels:join,channels:read,chat:write,chat:write.public,groups:read,im:read,mpim:read&user_scope="
                    className="webhook-link"
                    target="_blank" rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </li>
                <li key="2">
                  Select <span className="bold">Allow</span>.
                </li>
              </ol>
            ) : type === "slackWebhook" ? (
              <ol className="dark-blue CreationStep__info-orderedlist">
                <li key="1">
                  To generate a webhook URL for a specific Slack channel, go{" "}
                  <a
                    href="https://gofireflyio.slack.com/apps/A0F7XDUAZ-incoming-webhooks?tab=more_info"
                    className="webhook-link"
                    target="_blank" rel="noopener noreferrer"
                  >
                    here
                  </a>
                  .
                </li>
                <li key="2">
                  Select <span className="bold">Add to Slack</span>, and select
                  a Slack channel.
                </li>
                <li key="3">
                  Select{" "}
                  <span className="bold">
                    Add Incoming Webhooks integration
                  </span>
                  , and copy the <span className="bold">Webhook URL</span>.
                </li>
                <li key="4">In Firefly, enter the name of your integration.</li>
                <li key="5">
                  In the <span className="bold">Webhook URL</span> box, paste
                  the URL.
                </li>
                <li key="6">
                  Select <span className="bold">Next</span>
                  {` > `}
                  <span className="bold">Done</span>.
                </li>
              </ol>
            ) : null}
          </ol>
        </div>
        {type === "slackWebhook" && (
          <Form.Item
            label="Nickname"
            name="name"
            rules={[
              {
                required: true,
                message: "Nickname is required",
              },
              {
                pattern: nicknameRegex.regex,
                message: nicknameRegex.msg,
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input />
          </Form.Item>
        )}

        {type === "slackWebhook" && (
          <Form.Item
            label="Webhook URL"
            name="webhookUrl"
            rules={[
              {
                required: true,
                message: "Webhook URL is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input />
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default CreationStep;
