import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spin } from "antd";
import _ from "lodash";
import { createCodeCommmitIntegration } from "../../../redux/actions/vcsActions";
import SuccessBox from "../../../shared/successBox/successBox";

const CodeCommitValidation = ({ wizardState = {}, setSubmitDisabled }) => {
  const [loadingAssumeRole, setLoadingAssumeRole] = useState(false);
  const [retries, setRetries] = useState(0);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const externalId = useSelector(
    (state) => state.onbordingReducer?.account?.awsIntegrationExternalId
  );

  useEffect(() => {
    let timeoutId;
    if (retries < 11 && !success) {
      if (retries > 0) {
        timeoutId = setTimeout(() => {
          handleAssumeRole();
        }, 30000);
      } else {
        handleAssumeRole();
      }
    }
    return () => clearTimeout(timeoutId);
  }, [retries, success]);

  const handleAssumeRole = async () => {
    setLoadingAssumeRole(true);
    const created = await dispatch(
      createCodeCommmitIntegration(
        wizardState.name,
        wizardState.accountNumber,
        externalId
      )
    );
    // if iteration fail
    if (!created?.req?.ok && retries < 10) {
      setRetries(retries + 1);
      return;
    }
    // if all iterations failed
    if (!created?.req?.ok && retries == 10) {
       return setLoadingAssumeRole(false);
    }
    // integration created
    if (created?.req?.status === 200) {
      setSuccess(true);
      setLoadingAssumeRole(false);
      return;
    }
    setLoadingAssumeRole(false);
  };
  return (
    <div className="Integration__steps col">
      {loadingAssumeRole && (
        <div className="Integration-createRole col">
          <div className="Integration-createRole__load row">
            <Spin />
            <span className="purple-text bold">
              Waiting for assume-role integration to initiate...
            </span>
          </div>
          <span className="small-dark-blue">
            On the AWS Console tab, make sure to complete the steps in the{" "}
            <span className="purple-flag">Quick create stack</span> screen by
            clicking the <span className="purple-flag">Create stack</span>{" "}
            button
          </span>
          <span className="small-dark-blue">
            This might take a few minutes.
          </span>
        </div>
      )}
      {!loadingAssumeRole && success && (
        <div className="tab-page center">
           <SuccessBox setSubmitDisabled={setSubmitDisabled} />
        </div>
      )}
      {!loadingAssumeRole && !success && (
        <span className="Integration-failure col small-dark-blue">
          <span>
            Your AWS integration was unsuccessful. Verify the accuracy of the
            following:
          </span>
          <ul className="Integration-failure-list">
            <li key="1">Account permissions</li>
            <li key="2">Account ID</li>
          </ul>
          <span>Contact us via Intercom for support.</span>
        </span>
      )}
    </div>
  );
};

export default CodeCommitValidation;
