import {
  GET_K8S_INTEGRATIONS,
  GET_K8S_INTEGRATION_FETCHING_STATUS,
  CREATE_K8S_INTEGRATION,
  DELETE_K8S_INTEGRATION,
  EDIT_K8S_INTEGRATION
} from "../actions/k8sIntegrationActions";

const initialState = {
  integrations: [],
  metadata: []
};

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case GET_K8S_INTEGRATIONS:
      return {...state, integrations: payload.integrations, metadata: state.metadata};

    case GET_K8S_INTEGRATION_FETCHING_STATUS:
      return {...state, integrations: state.integrations, metadata: payload.metadata};

    case CREATE_K8S_INTEGRATION:
      return {...state};

    case DELETE_K8S_INTEGRATION:
      return {...state};

    case EDIT_K8S_INTEGRATION:
      return {...state};

    default:
      return state;
  }
}
