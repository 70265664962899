import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Form, Select, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";

import {
  getPulumiIntegrations,
  getPulumiStacks,
} from "../../../redux/actions/pulumiIntegrationActions";
import FormLabels from "../../../shared/formLabels/formLabels";

const PulumiCloud = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  setTags,
  allTags,
  currentSelectedTags,
  pulumiSelectedStacks,
  setPulumiSelectedStacks,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const location = useLocation();

  const pulumiIntegrations = useSelector(
    (state) => state.pulumiIntegrationReducer.integrations
  );
  const pulumiStacks = useSelector(
    (state) => state.pulumiIntegrationReducer.stacks
  );

  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingPulumiStacks, setLoadingPulumiStacks] = useState(false);
  const [autoDiscover, setAutoDiscover] = useState(false);

  // fetch PULUMI Integrations when starting
  useEffect(() => {
    fetchIntegrations();
    form.setFieldsValue({ discovery: "manual" });
  }, []);

  useEffect(() => {
    if (!_.isEmpty(location?.state?.integration) && location?.state?.type === "pulumi") {
      setSelectedIntegration(location?.state?.integration);
      form.setFieldsValue({ pulumiIntegration: location?.state?.integration });
    }
  }, [location?.state?.type, location?.state?.integration]);

  // fetch all Pulumi Cloud stacks on Integration change and region change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration)) {
      form.setFieldsValue({ pulumiStacks: [] });
      setPulumiSelectedStacks([]);
      fetchPulumiStacks();
    }
  }, [selectedIntegration]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getPulumiIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchPulumiStacks = async () => {
    setLoadingPulumiStacks(true);
    await dispatch(getPulumiStacks(selectedIntegration));
    setLoadingPulumiStacks(false);
  };

  const renderPulumiIntegrationsOptions = () => {
    if (!_.isEmpty(pulumiIntegrations)) {
      return _.map(pulumiIntegrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderPulumiStacksOptions = () => {
    if (!_.isEmpty(pulumiStacks)) {
      return _.map(pulumiStacks || [], (item) => {
        return (
          <Select.Option
            value={item?.identifier}
            key={item?.identifier}
            disabled={item?.alreadyExist || !_.isEmpty(item?.missingIntegration)}
          >
            {item?.stackName}
          </Select.Option>
        );
      });
    }
  };

  const handleCheckIfFormValid = (formValues) => {
    let formValid = true;
    const { pulumiIntegration, discovery, pulumiStacks } = formValues;

    if (_.isEmpty(pulumiIntegration)) {
      formValid = false;
    }

    if (discovery === "manual" && _.isEmpty(pulumiStacks)) {
      formValid = false;
    }
    return handleSetIsFormValid(formValid);
  };

  const checkMissingIntegrations = () => {
    if (!_.isEmpty(pulumiStacks)) {
      return _.map(pulumiStacks, (item) => {
        if (!_.isEmpty(item?.missingIntegration)) {
          return (
            <Link to="/integrations/aws-integration/pulumi" key={item?.identifier}>
              {`+ Missing AWS Integration "${item?.missingIntegration}"`}
            </Link>
          );
        }
      });
    }
  };

  return (
    <Form
      {...layout}
      name="pulumi-settings-form"
      form={form}
      onValuesChange={(changedValues, allValues) => {
        handleCheckIfFormValid(allValues);

        if (!_.isUndefined(changedValues.selectAll)) {
          if (changedValues.selectAll) {
            const stacksNotInUse = _.filter(
              pulumiStacks || [],
              (item) => !item?.alreadyExist && _.isEmpty(item?.missingIntegration)
            );
            const stackNames = _.map(stacksNotInUse || [], (item) => {
              return item?.identifier;
            });
            setPulumiSelectedStacks(stacksNotInUse);
            form.setFieldsValue({ pulumiStacks: stackNames });
          } else {
            setPulumiSelectedStacks([]);
            form.setFieldsValue({ pulumiStacks: [] });
          }
        }
        changeSelectedStateSettings(allValues);
      }}
    >
      <Form.Item
        label="Pulumi Cloud Integrations"
        name="pulumiIntegration"
        help={
          !loadingIntegrations && _.isEmpty(pulumiIntegrations) ? (
            <Link to="/integrations/pulumi-integration">
              + Add Pulumi Cloud Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Pulumi Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderPulumiIntegrationsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="Discovery method"
        name="discovery"
        rules={[
          {
            required: true,
            message: "selecting a discovery method is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select method"
          onSelect={(discovery) => setAutoDiscover(discovery === "auto")}
        >
          <Select.Option value="auto" key="auto">
            <FontAwesomeIcon
              icon={faSatelliteDish}
              style={{ marginRight: "5px" }}
            />{" "}
            Auto-Discover stacks
          </Select.Option>
          <Select.Option value="manual" key="manual">
            Select specific stacks
          </Select.Option>
        </Select>
      </Form.Item>

      {!autoDiscover && (
        <div>
          <Form.Item
            label="Pulumi stacks"
            name="pulumiStacks"
            rules={[
              {
                required: true,
                message: "Selecting stacks is required",
              },
            ]}
            style={{ marginBottom: "1rem", flexDirection: "column" }}
            help={
              !loadingPulumiStacks && !_.isEmpty(pulumiStacks) && !loadingIntegrations
                ? checkMissingIntegrations()
                : null
            }
          >
            <Select
              placeholder="Select"
              showSearch
              filterOption={(input, option) => {
                if (option && typeof option === "string") {
                  return (
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  );
                }
              }}
              loading={loadingPulumiStacks}
              mode="multiple"
              showArrow
              onSelect={(stack) => {
                let match = pulumiStacks?.find(
                  (item) => item?.identifier === stack
                );
                return setPulumiSelectedStacks([
                  ...pulumiSelectedStacks,
                  match,
                ]);
              }}
              onDeselect={(stack) => {
                let output = pulumiSelectedStacks?.filter(
                  (item) => item?.identifier !== stack
                );
                return setPulumiSelectedStacks(output);
              }}
            >
              {renderPulumiStacksOptions()}
            </Select>
          </Form.Item>

          <Form.Item name="selectAll" valuePropName="checked">
            <Checkbox
              checked={false}
              disabled={
                _.isEmpty(pulumiStacks) ||
                loadingPulumiStacks ||
                _.every(pulumiStacks, {
                  alreadyExist: true,
                })
              }
            >
              <span className="selectAll__checbox">Select All</span>
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};

export default PulumiCloud;
