import React from "react";

import CardBox from "../../shared/cardBox/cardBox";
import S3Bucket from "./stateConfiguration/s3Bucket";
import Tfc from "./stateConfiguration/tfc";
import GcsBucket from "./stateConfiguration/gcsBucket";
import Consul from "./stateConfiguration/consul";
import CloudFormation from "./stateConfiguration/cloudFormation";
import PulumiCloud from "./stateConfiguration/pulumi";
import { iacProviders, buckets, integrationIcons } from "../../utils/icons";

const StateLocation = ({
  selectedStateLocation,
  handleSelectedStateLocation,
  handleChangeSelectedStateSettings,
  handleIsValidStateLocation,
  selectedProvider,
  handleGetCftStacks,
  handleGetCftGlobalStacks,
  handleIsGlobal,
  handleIntegrationExternalId,
  setTags,
  allTags,
  currentSelectedTags,
  pulumiSelectedStacks,
  setPulumiSelectedStacks
}) => {
  const changeSelectedStateSettings = async (data) => {
    handleChangeSelectedStateSettings(data);
  };

  return (
    <div className="StateLocation col">
      {selectedProvider !== "cloudFormation" && selectedProvider !== "pulumi" && (
        <div className="StateLocation__list row">
          <CardBox
            title="AWS S3 Bucket"
            active={selectedStateLocation === "s3"}
            onClick={() => handleSelectedStateLocation("s3")}
            img={buckets["aws_bucket"]}
          />
          <CardBox
            title="Terraform Cloud"
            active={selectedStateLocation === "tfc"}
            onClick={() => handleSelectedStateLocation("tfc")}
            img={iacProviders("terraform")}
          />
          <CardBox
            title="GCP GCS Bucket"
            active={selectedStateLocation === "gcs"}
            onClick={() => handleSelectedStateLocation("gcs")}
            img={buckets["gcp_bucket"]}
          />
          <CardBox
            title="Hashicorp Consul"
            active={selectedStateLocation === "consul"}
            onClick={() => handleSelectedStateLocation("consul")}
            img={integrationIcons["consul"]}
          />

          <CardBox title="InfraLight state management" comingSoon />
        </div>
      )}

      <div className="StateLocation__forms">
        {selectedProvider === "cloudFormation" && (
          <CloudFormation
            changeSelectedStateSettings={(data) =>
              changeSelectedStateSettings(data)
            }
            handleSetIsFormValid={(validFlag) =>
              handleIsValidStateLocation(validFlag)
            }
            handleGetCftStacks={(cftStacks) => handleGetCftStacks(cftStacks)}
            handleGetCftGlobalStacks={(cftGlobalStacks) =>
              handleGetCftGlobalStacks(cftGlobalStacks)
            }
            handleIsGlobal={(isGlobal) => handleIsGlobal(isGlobal)}
            handleIntegrationExternalId={(externalId) =>
              handleIntegrationExternalId(externalId)
            }
            setTags={setTags}
            allTags={allTags}
            currentSelectedTags={currentSelectedTags}
          />
        )}

        {selectedProvider === "pulumi" && (
          <PulumiCloud
            changeSelectedStateSettings={(data) =>
              changeSelectedStateSettings(data)
            }
            handleSetIsFormValid={(validFlag) =>
              handleIsValidStateLocation(validFlag)
            }
            pulumiSelectedStacks={pulumiSelectedStacks}
            setPulumiSelectedStacks={setPulumiSelectedStacks}
            setTags={setTags}
            allTags={allTags}
            currentSelectedTags={currentSelectedTags}
          />
        )}

        {selectedProvider !== "cloudFormation" &&
          selectedProvider !== "pulumi" && (
            <span>
              {selectedStateLocation === "s3" && (
                <S3Bucket
                  changeSelectedStateSettings={(data) =>
                    changeSelectedStateSettings(data)
                  }
                  handleSetIsFormValid={(validFlag) =>
                    handleIsValidStateLocation(validFlag)
                  }
                  setTags={setTags}
                  allTags={allTags}
                  currentSelectedTags={currentSelectedTags}
                />
              )}
              {selectedStateLocation === "tfc" && (
                <Tfc
                  changeSelectedStateSettings={(data) =>
                    changeSelectedStateSettings(data)
                  }
                  handleSetIsFormValid={(validFlag) =>
                    handleIsValidStateLocation(validFlag)
                  }
                  setTags={setTags}
                  allTags={allTags}
                  currentSelectedTags={currentSelectedTags}
                />
              )}
              {selectedStateLocation === "gcs" && (
                <GcsBucket
                  changeSelectedStateSettings={(data) =>
                    changeSelectedStateSettings(data)
                  }
                  handleSetIsFormValid={(validFlag) =>
                    handleIsValidStateLocation(validFlag)
                  }
                  setTags={setTags}
                  allTags={allTags}
                  currentSelectedTags={currentSelectedTags}
                />
              )}
              {selectedStateLocation === "consul" && (
                <Consul
                  changeSelectedStateSettings={(data) =>
                    changeSelectedStateSettings(data)
                  }
                  handleSetIsFormValid={(validFlag) =>
                    handleIsValidStateLocation(validFlag)
                  }
                  setTags={setTags}
                  allTags={allTags}
                  currentSelectedTags={currentSelectedTags}
                />
              )}
            </span>
          )}
      </div>
    </div>
  );
};

export default StateLocation;
