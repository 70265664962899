import React, { useEffect, useCallback } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setFilters } from "../../../../redux/actions/inventoryv3Actions";
import { useTranslation } from "react-i18next";
import find from "lodash/find";
import { ReactComponent as CloudIcon } from "./cloud_icon.svg";
import { ReactComponent as K8sIcon } from "./k8s_icon.svg";
import { ReactComponent as SaasIcon } from "./saas_icon.svg";
import { emptyInventoryScreenFilters } from "../../../../consts/inventory";
import "./providerMasterFilter.scss";

const ProviderMasterFilter = () => {
  const { t } = useTranslation("inventory", { keyPrefix: "filters" });
  const dispatch = useDispatch();
  const aggs = useSelector(
    (state) => state.inventoryReducer.aggregationsMaster
  );
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );
  const masterType = screenFilters?.masterType || [];

  useEffect(() => {
    if(searchVal) {
      dispatch(setFilters({ ...screenFilters, masterType: [] }));
    }
  }, [searchVal])

  const cloud = find(aggs || [], (i) => i?.key === "cloud");
  const k8s = find(aggs || [], (i) => i?.key === "k8s");
  const saas = find(aggs || [], (i) => i?.key === "saas");

  const types = {
    cloud: {
      key: "cloud",
      title: t("master.cloud"),
      count: cloud?.integrations,
      icon: <CloudIcon />,
    },
    k8s: {
      key: "k8s",
      title: t("master.k8s"),
      count: k8s?.integrations,
      icon: <K8sIcon />,
    },
    saas: {
      key: "saas",
      title: t("master.saas"),
      count: saas?.integrations,
      icon: <SaasIcon />,
    },
  };

  useEffect(() => {
    const masterProviderType = masterType[0];
    const noNeedDefaultMasterType = !masterProviderType ? true : types[masterProviderType]?.count > 0;
    if (noNeedDefaultMasterType) return;

    const defaultFilterType = types.cloud.count > 0 ? "" : (types.k8s.count > 0 ? "k8s" : (types.saas.count > 0 ? "saas" : ""));
    if (defaultFilterType !== masterProviderType) {
      handleSetFilter(types[defaultFilterType]);
    }
  }, [aggs])

  const handleSetFilter = useCallback((type = {}) => {
    if (!type.count) {
      return;
    }
    const value = masterType.includes(type.key) ? [] : [type.key];
    return dispatch(setFilters({ ...emptyInventoryScreenFilters, masterType: value }));
  },[dispatch, masterType]);

  const renderType = (type) => {
    const isActiveType = masterType.includes(type);
    const { count: typeCount, title: typeTitle, icon: typeIcon } = types[type] || {}
    return (
      <div
        className={`ProviderMasterFilter__inner-btn ${type} center g5 ${isActiveType ? 'active' : ''} ${typeCount ? '' : 'disabled'}`}
        onClick={() => handleSetFilter(types[type])}
        style={{ cursor: typeCount ? 'pointer' : 'not-allowed' }}
      >
        {typeIcon}
        {typeTitle}
        {typeCount && <span className={`ProviderMasterFilter__inner-btn-bubble center ${type}`}>{typeCount}</span>}
      </div>
    );
  };
  return (
    <div className="ProviderMasterFilter">
      <div className="ProviderMasterFilter__inner">
        {renderType("cloud")}
        <div className="ProviderMasterFilter__inner-divider first" />
        {renderType("k8s")}
        <div className="ProviderMasterFilter__inner-divider second" />
        {renderType("saas")}
      </div>
    </div>
  );
};

export default ProviderMasterFilter;
