import {
    CREATE_GCP_PROVIDER_INTEGRATION,
    DELETE_GCP_PROVIDER_INTEGRATION,
    EDIT_GCP_PROVIDER_INTEGRATION,
    GET_GCP_PROVIDER_INTEGRATIONS,
    UPDATE_GCP_PROVIDER_INTEGRATION
  } from "../actions/gcpProviderIntegrationActions";
  
  const initialState = {
    integrations: [],
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_GCP_PROVIDER_INTEGRATIONS:
        return { ...state, integrations: payload.integrations };
  
      case CREATE_GCP_PROVIDER_INTEGRATION:
        return { ...state };
      case UPDATE_GCP_PROVIDER_INTEGRATION:
        return { ...state };
  
      case DELETE_GCP_PROVIDER_INTEGRATION:
        return { ...state };
  
      case EDIT_GCP_PROVIDER_INTEGRATION:
        return {...state}
  
      default:
        return state;
    }
  }
  