import React from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { renderEllipsis } from "../../../utils/formatting";
import "./companyLogo.scss";

const CompanyLogo = () => {

  const logoUrl = useSelector(
    (state) => state.onbordingReducer.account?.icon_url
  );
  const darkLogoUrl = useSelector(
    (state) => state.onbordingReducer.account?.icon_dark_url
  );
  const accountName = useSelector(
    (state) => state?.onbordingReducer.account?.name
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  if (sidebarClosed) {
    return <div className="CompanyLogo center" />;
    // return null
  }

    return (
      <div className="CompanyLogo center col">
        {!isEmpty(logoUrl) ? (<div
          className="CompanyLogo__logo"
          style={{
            backgroundImage: `url(${
              themeDark && !isEmpty(darkLogoUrl) ? darkLogoUrl : logoUrl
            })`,
          }}
        />
        ) : (
        <div className="CompanyLogo__name bold">
          {renderEllipsis(accountName, 12)}
        </div>
      )}
      </div>
    );
};

export default CompanyLogo;
