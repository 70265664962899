import _ from "lodash";
import React from "react";
import IacStatusFlag from "../../../../shared/iacStatusFlag/iacStatusFlag";
import OwnerIndication from "../../ownerIndication/ownerIndication";
import AssetInfoCard from "./assetInfoCard";
import AssetClickableChips from "./assetTags/assetTags";
import CustomPropertiesTags from "../../../../shared/customPropertiesTags/customPropertiesTags";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { formatUnixDate } from "../../../../utils/formatting";
import moment from 'moment';
import AssetDataSourceCell from '../../inventoryTable/assetDataSourceCell';

const BasicInfo = ({ asset, handleOpenAnalysis, parentData, onClickTag,onClickResourceGroup }) => {

  const deleteMode = asset?.state === "deleted";

  const handleRenderId = () => {
    let assetId = asset?.assetId;

    if (asset?.state === "ghost" || asset?.state === "pending") {
      assetId = asset?.frn;
    }
    return (
      <div className="row" style={{ gap: "10px", alignItems: 'flex-start' }}>
        <span>{assetId}</span>
        {asset?.consoleURL && !_.isEmpty(asset?.consoleURL) && (
          <span
            onClick={() => window.open(asset?.consoleURL, "_blank")}
            className="console-icon-link"
          >
            <FontAwesomeIcon icon="external-link-alt" />
          </span>
        )}
      </div>
    );
  };

  const renderProperties = () => {
    return <div className="row" style={{ gap: "10px" }}>
    {!_.isEmpty(asset?.resourceStatus) && (
      <div className="purple-flag">
        {asset?.resourceStatus}
      </div>
    )}
    <CustomPropertiesTags
      data={asset?.customColumns}
      disableClick
    />
  </div>
  }

  return (
    <AssetInfoCard
      contentArr={[
        { label: "ID", content: handleRenderId(), display: true },
        { label: "Data Source", content: <AssetDataSourceCell row={asset} />, display: true },
        {
          label: "Resource group",
          content: <AssetClickableChips chips={[asset?.providerGroupId]} onClickChip={onClickResourceGroup} />,
          display: !_.isEmpty(asset?.providerGroupId),
        },
        {
          label: "IaC Status",
          content: (
            <IacStatusFlag
              state={!_.isEmpty(parentData) && asset?.isChild ? parentData?.state : asset?.state}
              iacType={!_.isEmpty(parentData) && asset?.isChild ? parentData?.iacType : asset?.iacType}
              hasControllerSources={!_.isEmpty(parentData) && asset?.hasControllerSources ? parentData?.hasControllerSources : asset?.hasControllerSources}
              handleOpenAnalysis={handleOpenAnalysis}
              rowData={asset}
              handleOpenMulti={() => {
                return false;
              }}
              isLocked={false}
            />
          ),
          display: true,
        },
        {
          label: "Owner",
          content: (
            <OwnerIndication
              data={asset?.ownerData}
              drawerView
              handleClickOwner={() => {
                return false;
              }}
            />
          ),
          display: !_.isEmpty(asset?.ownerData?.userIdentity?.displayName),
        },
        {
          label: "Location",
          content: asset?.region,
          display: !_.isEmpty(asset?.region),
        },
        {
          label: "Properties",
          content: renderProperties(),
          display: !_.isEmpty(asset?.resourceStatus) || (!_.isEmpty(asset?.customColumns) && asset?.customColumns?.length > 2),
        },
        {
          label: "Tags",
          content: <AssetClickableChips chips={asset?.tagsList} onClickChip={onClickTag} />,
          display: !_.isEmpty(asset?.tagsList),
        },
        {
          label: deleteMode ? "Deletion Detection Date" : "Created On",
          content: deleteMode? moment(asset?.deletedAt).format("MM/DD/YYYY") : formatUnixDate(asset?.resourceCreationDate),
          display: deleteMode ? _.has(asset, 'deletedAt') && asset.deletedAt : _.has(asset, 'resourceCreationDate') && asset.resourceCreationDate,
        },
      ]}
      // grid
    />
  );
};

export default BasicInfo;
