import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_GCP_INTEGRATIONS = "GET_GCP_INTEGRATIONS";
export const CREATE_GCP_INTEGRATION = "CREATE_GCP_INTEGRATION";
export const DELETE_GCP_INTEGRATION = "DELETE_GCP_INTEGRATION";
export const EDIT_GCP_INTEGRATION = "EDIT_GCP_INTEGRATION";
export const GET_ALL_GCS_BUCKETS = "GET_ALL_GCS_BUCKETS";
export const GET_ALL_GCS_OBJECTS = "GET_ALL_GCS_OBJECTS";

const getGcpIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp`,
        "GET"
    );

    const integrations = await integrationsReq.json();

    dispatch({
      type: GET_GCP_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

const createGcpIntegration = (name, projectId, serviceAccountKey, fullScanEnabled=true) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/`,
        "POST",
        {
          name,
          projectId,
          serviceAccountKey,
          fullScanEnabled
        }
    );

    dispatch({
      type: CREATE_GCP_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteGcpIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_GCP_INTEGRATION,
      payload: {},
    });
  });
};

const editGcpIntegration = (id, name, fetchable) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/gcp/${id}`,
      "PUT",
    {name, fetchable}
    );

    dispatch({
      type: EDIT_GCP_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};

const getAllGcsBuckets = (integration) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${integration}/buckets`,
        "GET"
    );
    dispatch({
      type: GET_ALL_GCS_BUCKETS,
      payload: {},
    });
    const buckets = await req.json();
    return { buckets };
  });
};

const getAllGcsObjects = (integration, bucket) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/gcp/${integration}/bucket/${bucket}/objects`,
        "GET"
    );

    dispatch({
      type: GET_ALL_GCS_OBJECTS,
      payload: {},
    });
    const objects = await res.json();
    return { objects };
  });
};

export { getGcpIntegrations, createGcpIntegration, deleteGcpIntegration, getAllGcsBuckets, getAllGcsObjects, editGcpIntegration };
