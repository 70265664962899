import React from "react";
import { Link } from "react-router-dom";
import "./notFound.scss";

const NotFound = () => {
  return (
    <div className="NotFound">
      <span>404!</span>
      <span>Sorry! The page you were looking for doesn't exist.</span>
      <Link to="/">Go Back Home</Link>
    </div>
  );
};

export default NotFound;
