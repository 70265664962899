import {
  GET_ALL_STACKS,
  GET_STACKS,
  CREATE_STACK,
  STACKS_STATUS,
  GET_ALL_TAGS,
  SET_STACKS_FILTERS,
  STACKS_AGG,
  GET_STACK_MAP
} from "../actions/stacksActions";
const initialState = {
  data: [],
  envStatus: [],
  stacks: [],
  total: 0,
  tags: [],
  aggregationTypes: [],
  aggregationAwsProviders: [],
  aggregationK8sProviders: [],
  aggregationAkamaiProviders: [],
  aggregationDatadogProviders: [],
  aggregationGithubProviders: [],
  aggregationOktaProviders: [],
  aggregationGcpProviders: [],
  aggregationNewrelicProviders: [],
  aggregationCloudflareProviders: [],
  aggregationLocations: [],
  aggregationTags: [],
  aggregationStatus: [],
  aggregationIacTypes: {},
  stacksfilters: {
    types: [],
    providerIds: [],
  },
  agg: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_STACKS:
      return {
        ...state,
        stacks: payload.stacks,
        total: payload.total,
        aggregationTypes: payload.aggs?.types,
        aggregationAwsProviders: payload.aggs?.awsProviders,
        aggregationK8sProviders: payload.aggs?.k8sProviders,
        aggregationAkamaiProviders: payload.aggs?.akamaiProviders,
        aggregationDatadogProviders: payload.aggs?.datadogProviders,
        aggregationGithubProviders: payload.aggs?.githubProviders,
        aggregationOktaProviders: payload.aggs?.oktaProviders,
        aggregationGcpProviders: payload.aggs?.gcpProviders,
        aggregationNewrelicProviders: payload.aggs?.newrelicProviders,
        aggregationCloudflareProviders: payload.aggs?.cloudflareProviders,
        aggregationLocations: payload.aggs?.locations,
        aggregationTags: payload.aggs?.tags,
        aggregationStatus: payload.aggs?.statuses,
        aggregationIacTypes: payload.aggs?.iacTypes,
      };
    case GET_STACKS:
      return { ...state, data: payload.data };

    case CREATE_STACK:
      return { ...state };

    case STACKS_STATUS:
      return { ...state, envStatus: payload.data };

    case GET_ALL_TAGS:
      return { ...state, tags: payload.data };

    case SET_STACKS_FILTERS:
      return {
        ...state,
        stacksfilters: payload,
      };

    case STACKS_AGG:
      return { ...state, agg: payload.data };
    case GET_STACK_MAP:
      return { ...state };

    default:
      return state;
  }
}
