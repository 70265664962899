import { GET_ALL_NOTIFICATIONS } from '../actions/notificationsActions';

const initialState = {
    notifications: [],
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_ALL_NOTIFICATIONS:
            return { ...state, notifications: payload.notifications };

        default:
            return state;
    }
}
