  import {
  CREATE_DATADOG_INTEGRATION,
  DELETE_DATADOG_INTEGRATION,
  EDIT_DATADOG_INTEGRATION,
  GET_DATADOG_INTEGRATIONS
} from "../actions/datadogIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_DATADOG_INTEGRATIONS:
      return { ...state, integrations: payload.integrations };

    case CREATE_DATADOG_INTEGRATION:
      return { ...state };

    case DELETE_DATADOG_INTEGRATION:
      return { ...state };

    case EDIT_DATADOG_INTEGRATION:
      return {...state}

    default:
      return state;
  }
}
