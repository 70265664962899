import React from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { toggleFilters, setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import PolicyTypeFilter from "./policyTypeFilter/policyTypeFilter";
import PolicyCategoryFilter from "./policyCategoryFilter/policyCategoryFilter";
import TogglesFilter from "./togglesFilter/togglesFilter";
import PolicyCollapseFilters from "./policyCollapseFilters/policyCollapseFilters";
import { ReactComponent as CollapseIcon } from "../../../Images/general_icons/insights-filters/filters-collapse.svg";
import { insightsFilterTemplate } from "../../../consts/insights";
import isEqual from "lodash/isEqual";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import "./insightsFilters.scss";

const InsightsFilters = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const insightsReducer = useSelector((state) => state.insightsV2Reducer);

  const handleToggleFilters = () => {
    return dispatch(toggleFilters());
  };
  const handleClearFilters = () => {
    return dispatch(setInsightsFilters(insightsFilterTemplate));
  };

  return (
    <div className="InsightsFilters">
      <PolicyTypeFilter />
      <PolicyCategoryFilter />
      <TogglesFilter />
      <PolicyCollapseFilters />
      <div
        className={`InsightsFilters__footer ${
          insightsReducer.filtersOpen ? "row between" : "col-reverse g10"
        }`}
        style={{ paddingLeft: "5px" }}
      >
        <button
          onClick={handleToggleFilters}
          className={`InsightsFilters-close-btn ${
            insightsReducer.filtersOpen ? "collapse" : "expand"
          }`}
        >
          <CollapseIcon />
        </button>
        {!isEqual(insightsReducer.filters, insightsFilterTemplate) && (
          <div className="row g3 center" onClick={handleClearFilters}>
            <IconBtn type="clear" colorClass="purple" />
            {insightsReducer.filtersOpen && (
              <span className="InsightsFilters-clear-btn purple-text underline">
                {t("filters.clear-btn")}
              </span>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

export default InsightsFilters;
