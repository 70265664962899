import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import AppWizard from "../../../../shared/appWizard/appWizard";
import SuccessBox from "../../../../shared/successBox/successBox";
import GcpProviderServiceCreation from "./gcpProviderServiceCreation";
import { createGcpProviderIntegration, updateGcpProviderIntegration } from "../../../../redux/actions/gcpProviderIntegrationActions";

import { useDispatch } from "react-redux";
import { clouds } from "../../../../utils/icons";
import { isEmpty } from "lodash";

import "./gcpProviderServiceIntegration.scss";


const GcpProviderServiceIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [currentProvider, setCurrentProvider] = useState(history?.location?.state)

  const isUpdateMode = !isEmpty(currentProvider);

  const configWizard = [
    {
      step_title: isUpdateMode ? "Update" : "Setup",
      step_description: "Google Cloud integration details",
      content_title: "Google Cloud Integration",
      content: (
        <GcpProviderServiceCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          currentProvider={currentProvider}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        if (isUpdateMode) {
          const result = await dispatch(
            updateGcpProviderIntegration(
            wizardState.name,
            wizardState.serviceAccountKey,
            wizardState.isEventDrivenDisabled,
            wizardState.isProd,
            wizardState.isIacAutoDiscoverDisabled,
            wizardState.regexExcludeProjectsDiscovery,
            currentProvider.id
            )
          );
          setLoadingSubmit(false);
          if (!result?.ok) {
            throw new Error(
              `Failed to update Google Cloud integration.`
            );
          }
        } else {
          const result = await dispatch(
            createGcpProviderIntegration(
              wizardState.name,
              wizardState.serviceAccountKey,
              wizardState.isEventDrivenDisabled,
              wizardState.isProd,
              wizardState.isIacAutoDiscoverDisabled,
              wizardState.regexExcludeProjectsDiscovery,

            )
          );
          setLoadingSubmit(false);
          if (!result?.ok) {
            throw new Error(
              `Failed to create Google Cloud integration.`
            );
          }
        }
      },
    },

      {
      step_title: "Completion",
      step_description: "",
      content_title: `Integration ${isUpdateMode ? "Updated" : "Created"}!`,
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle={isUpdateMode ? "Changes were successfully saved":"The Resources from the Integration will take up to one hour to appear in the inventory"}
          doesTakeTime={true}
        />
      ),
      valid: true,
    }
  ];

  return (
    <div className="GcpProviderServiceIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations",{cloudType:'gcp'})}
        iconSrc={clouds("gcp")}
        customFirstNextText={isUpdateMode && "Update"}
        customDoneText={isUpdateMode && "Done"} 
      />
    </div>
  );
};

export default GcpProviderServiceIntegration;
