import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import {
  getAllVcs,
} from "../../../redux/actions/globalAppActions";
import {
  searchDriftFix,
  fixDrift,
  cleanData,
} from "../../../redux/actions/fixDriftActions";
import { useDispatch, useSelector } from "react-redux";
import { Input } from "antd";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import Loading from "../../../shared/loading/loading";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import AppBtn from "../../../shared/appBtn/appBtn";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import FoundDrift from "./foundDrift/foundDrift";
import FixedDrift from "./fixedDrift/fixedDrift";
import {
  ERR_DRIFT_PROPERTIES_NOT_FOUND_IN_IAC,
  ERR_FILES_NOT_FOUND_IN_REPO,
  ERR_FOUND_SOME_DRIFTS,
  ERR_VCS_API_ERROR,
  ERR_VCS_INTEGRATION_NOT_FOUND,
  ERR_VCS_REPO_NOT_FOUND,
  ERR_RESOURCE_NOT_FOUND,
  ERR_INTERNAL_SERVER_ERROR,
  ERR_RESOURCE_NOT_MANAGED_BY_TF,
  ERR_RESOURCE_TF_STACK_NOT_FOUND,
  ERR_TF_VERSION_NOT_SUPPORTED,
  ERR_UPDATED_SOME_FILES,
  ERR_ES_SEARCH_RESULT_EMPTY,
  ERR_FOUND_SOME_DRIFTS_NO_CHANGE,
  ERR_NO_CHANGE,
} from "../../../consts/errorCodes";
import { S3_ASSETS } from "../../../consts/config";
import "./fixDriftDrawer.scss";

const FixDriftDrawer = ({ close, visible, data }) => {
  const [loadingVcs, setLoadingVcs] = useState(true);
  const [loadingSearchRequest, setLoadingSearchRequest] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [doneLoading, setDoneLoading] = useState(false);
  const [foundSuccess, setFoundSuccess] = useState(false);
  const [fixedSuccess, setFixedSuccess] = useState(false);
  const [foundDriftResultsWithText, setFoundDriftResultsWithText] = useState(
    []
  );
  const [fixedDriftResultsWithText, setFixedDriftResultsWithText] = useState(
    []
  );
  const [formValid, setFormValid] = useState(false);
  const [dispatchOk, setDispatchOk] = useState(false);
  const [prComment, setPrComment] = useState("");

  const dispatch = useDispatch();
  const vcsIntegs = useSelector((state) => state.globalAppReducer.vcs);
  const foundFixDrift = useSelector(
    (state) => state.fixDriftReducer.findDriftResult
  );
  
  const { TextArea } = Input;

  useEffect(() => {
    cleanOldData();
    setFoundDriftResultsWithText([]);
    setFixedDriftResultsWithText([]);
    setLoadingVcs(false);
    setLoadingSearchRequest(false);
    setLoadingSubmit(false);
    setPrComment("");
    if (visible) {
      getVcs();
    }
  }, [visible]);

  useEffect(() => {
    if (
      visible &&
      !loadingVcs &&
      !_.values(vcsIntegs).every(_.isEmpty)
    ) {
      runSearch();
    }
  }, [vcsIntegs, loadingVcs]);

  useEffect(() => {
    if (!loadingVcs && !loadingSearchRequest) {
      setDoneLoading(true);
    }

    if (loadingVcs || loadingSearchRequest) {
      setDoneLoading(false);
    }
  }, [loadingSearchRequest, loadingVcs]);

  useEffect(() => {
    if (!_.isEmpty(foundFixDrift) && doneLoading) {
      setFoundDriftResultsWithText(
        _.orderBy(getFoundDriftResultsWithText(), ["id"])
      );
    }
  }, [foundFixDrift, doneLoading]);

  useEffect(() => {
    if (!_.isEmpty(foundDriftResultsWithText)) {
      const numChecked = _.filter(
        foundDriftResultsWithText,
        (result) => result?.isChecked
      ).length;
      setFormValid(Boolean(numChecked));
    }
  }, [foundDriftResultsWithText]);

  const cleanOldData = async () => {
    await dispatch(cleanData());
  };

  const getVcs = async () => {
    setLoadingVcs(true);
    await Promise.all([
      dispatch(getAllVcs()),
    ]);
    setLoadingVcs(false);
  };

  const runSearch = async () => {
    setLoadingSearchRequest(true);

    if (!_.values(vcsIntegs).every(_.isEmpty)) {
      const result = await dispatch(searchDriftFix(data?.terraformObjectReferencesAncestry, data?.vcsWorkingDirectory, data?.drift));
      setDispatchOk(result?.ok);
    }
    setLoadingSearchRequest(false);
  };

  const getFoundDriftResultsWithText = () => {
    if (!_.isEmpty(foundFixDrift)) {
      return _.map(foundFixDrift, (obj) => {
        switch (obj?.error) {
          case ERR_RESOURCE_TF_STACK_NOT_FOUND:
            setFoundSuccess(false);
            return { ...obj, responseText: "Didn't find asset TF state." };
          case ERR_RESOURCE_NOT_MANAGED_BY_TF:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: "Asset is not managed by terraform.",
            };
          case ERR_TF_VERSION_NOT_SUPPORTED:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `Terraform version ${obj?.terraformVersion} is not supported.`,
            };
          case ERR_VCS_INTEGRATION_NOT_FOUND:
            setFoundSuccess(false);
            return { ...obj, responseText: "Didn't find VCS integration." };
          case ERR_RESOURCE_NOT_FOUND:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `Didn't find asset code in the integrated VCS.`,
            };
          case ERR_ES_SEARCH_RESULT_EMPTY:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `Didn't find asset code in the integrated VCS.`,
            };
          case ERR_DRIFT_PROPERTIES_NOT_FOUND_IN_IAC:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `Drifted properties values not supported or not found in repo ${obj?.repo}.`,
            };
          case ERR_VCS_API_ERROR:
            setFoundSuccess(false);
            return { ...obj, responseText: `VCS API error.` };
          case ERR_INTERNAL_SERVER_ERROR:
            setFoundSuccess(false);
            return { ...obj, responseText: `Internal server error.` };
          case ERR_FOUND_SOME_DRIFTS_NO_CHANGE:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `Found ${obj?.numFound}/${data?.drift?.length} drift properties in repository ${obj?.repo} in file path ${obj?.resourceFilePath}.\nCouldn't find the other drift properties.\nThere was no change in the properties values. Probably the drift is already fixed, please make sure to update the state.`,
            };
          case ERR_NO_CHANGE:
            setFoundSuccess(false);
            return {
              ...obj,
              responseText: `We found all drifted properties. There is no change in the property values. The drift was probably fixed. Copy and run the following command to update the state:`,
              objectInfo: {
                objectType: data?.assetType, objectName: data?.terraformObjectName,
                objectModule: data?.terraformModule
              },
            };
          case ERR_FOUND_SOME_DRIFTS:
            setFoundSuccess(true);
            return {
              ...obj,
              objectInfo: {
                objectType: data?.assetType, objectName: data?.terraformObjectName,
                objectModule: data?.terraformModule
              },
              isChecked: foundFixDrift?.length === 1,
              showDiff: true,
              id: uuidv4(),
              isSingle: foundFixDrift?.length === 1,
              responseText: `Found ${obj?.numFound}/${data?.drift?.length} drift properties in repository ${obj?.repo} in file path ${obj?.resourceFilePath}.\nCouldn't find the other drift properties.`,
            };
          default:
            if (!dispatchOk) {
              setFoundSuccess(false);
              return [
                {
                  responseText: "Internal server error.",
                  error: ERR_INTERNAL_SERVER_ERROR,
                },
              ];
            }
            setFoundSuccess(true);
            return {
              ...obj,
              isChecked: foundFixDrift?.length === 1,
              showDiff: true,
              id: uuidv4(),
              objectInfo: {
                objectType: data?.assetType, objectName: data?.terraformObjectName,
                objectModule: data?.terraformModule
              },
              isSingle: foundFixDrift?.length === 1,
            };
        }
      });
    }
    setFoundSuccess(false);
    return [
      {
        responseText: "Internal server error.",
        error: ERR_INTERNAL_SERVER_ERROR,
      },
    ];
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);

    const checkedObjs = _.filter(
      foundDriftResultsWithText,
      (result) => result?.isChecked
    );
    let fixedDriftResults = [];
    await Promise.all(
      _.map(checkedObjs, async (obj) => {
        const result = await dispatch(
          fixDrift(data?.frn, data?.vcsId, obj?.repo, obj?.filesInfo, prComment)
        );
        setDispatchOk(result?.ok);
        fixedDriftResults.push({
          fixedFixDrift: result?.data,
          foundFixDrift: obj,
        });
      })
    );

    setFixedDriftResultsWithText(
      _.map(fixedDriftResults, (result) =>
        getFixedDriftResultsWithText(
          result?.fixedFixDrift,
          result?.foundFixDrift?.filesInfo,
          result?.foundFixDrift?.objectInfo
        )
      )
    );
    setLoadingSubmit(false);
  };

  const getFixedDriftResultsWithText = (obj, filesInfo, objectInfo) => {
    if (!_.isEmpty(obj)) {
      switch (obj?.error) {
        case ERR_VCS_INTEGRATION_NOT_FOUND:
          setFixedSuccess(false);
          return { ...obj, responseText: "Didn't find VCS integration." };
        case ERR_VCS_REPO_NOT_FOUND:
          setFixedSuccess(false);
          return {
            ...obj,
            responseText: `Failed to find the VCS repo ${obj?.repo}.`,
          };
        case ERR_FILES_NOT_FOUND_IN_REPO:
          setFixedSuccess(false);
          return {
            ...obj,
            responseText: `Files not found in repo ${obj?.repo}.`,
          };
        case ERR_VCS_API_ERROR:
          setFixedSuccess(false);
          return { ...obj, responseText: `VCS API error.` };
        case ERR_INTERNAL_SERVER_ERROR:
          setFixedSuccess(false);
          return { ...obj, responseText: `Internal server error.` };
        case ERR_UPDATED_SOME_FILES:
          setFixedSuccess(true);
          return {
            ...obj,
            objectInfo,
            responseText: `Fixed ${obj?.numUpdated}/${_.size(
              filesInfo
            )} files. Couldn't find the other files in the repo.`,
          };
        default:
          if (!dispatchOk) {
            setFoundSuccess(false);
            return {
              responseText: "Internal server error.",
              error: ERR_INTERNAL_SERVER_ERROR,
            };
          }
          setFixedSuccess(true);
          return { ...obj, objectInfo };
      }
    }
    setFoundSuccess(false);
    return {
      responseText: "Internal server error.",
      error: ERR_INTERNAL_SERVER_ERROR,
    };
  };

  const handleCloseDrawer = () => {
    setFoundDriftResultsWithText([]);
    setFixedDriftResultsWithText([]);
    setLoadingVcs(false);
    setLoadingSearchRequest(false);
    setLoadingSubmit(false);
    close();
  };

  const setChecked = (e, objId) => {
    setFoundDriftResultsWithText((oldFoundDriftResultsWithText) => {
      const obj = _.find(
        oldFoundDriftResultsWithText,
        (result) => result?.id === objId
      );
      obj.isChecked = e?.target?.checked;
      return _.orderBy(
        [
          ..._.filter(
            oldFoundDriftResultsWithText,
            (result) => result?.id !== objId
          ),
          obj,
        ],
        ["id"]
      );
    });
  };

  const setShowDiff = (objId) => {
    setFoundDriftResultsWithText((oldFoundDriftResultsWithText) => {
      const obj = _.find(
        oldFoundDriftResultsWithText,
        (result) => result?.id === objId
      );
      obj.showDiff = !obj?.showDiff;
      return _.orderBy(
        [
          ..._.filter(
            oldFoundDriftResultsWithText,
            (result) => result?.id !== objId
          ),
          obj,
        ],
        ["id"]
      );
    });
  };

  return (
    <AppDrawer
      title="Fix drift request"
      closeDrawer={handleCloseDrawer}
      visible={visible}
      width="80vw"
      zIndex={1001}
      footer={[
        <AppBtn
          loading={loadingSubmit}
          disabled={
            !formValid ||
            (!doneLoading &&
              fixedDriftResultsWithText?.length !== 0 &&
              !foundSuccess)
          }
          onClick={handleSubmit}
          text="Create Pull Request"
          key="create-pr"
        />,
      ]}
    >
      <div className="FixDriftModal__content">
        {(loadingVcs || loadingSearchRequest || !doneLoading) && (
          <div className="tab-page center">
            <Loading />
          </div>
        )}

        {!loadingVcs && _.values(vcsIntegs).every(_.isEmpty) && (
          <div className="FixDriftModal__content-body tab-page center col" style={{ gap: '10px' }}>
            <AppEmpty customStyle="git" text="Git integration was not found" />
            <div className="no-vcs-integs col">
              <Link to="/integrations/github-integration">
                + Add GitHub Integration
              </Link>
              <Link to="/integrations/gitlab-integration">
                + Add GitLab Integration
              </Link>
              <Link to="/integrations/bitbucket-integration">
                + Add Bitbucket Integration
              </Link>
            </div>
          </div>
        )}

        <div className="FixDriftModal__content-success col center tab-page">
          {doneLoading && fixedDriftResultsWithText.length === 0 && (
            <div className="FixDriftModal__content-success-form col">
              {!foundSuccess && (
                <AppEmpty text="Drift not found" customStyle="code" />
              )}
              <span className="FixDriftModal__content-success-checkbox">
              {foundSuccess && 
                <TextArea 
                  value={prComment}
                  rows={4}
                  onChange={e => setPrComment(e?.target?.value)}
                  placeholder="Pull Request Comment (Optional)"
                  style={{ width: "350px" }}
                />}
                {_.map(foundDriftResultsWithText, (response) => (
                  <FoundDrift
                    key={uuidv4()}
                    obj={response}
                    setShowDiff={setShowDiff}
                    setChecked={setChecked}
                  />
                ))}
              </span>
            </div>
          )}

          {doneLoading &&
            !loadingSubmit &&
            fixedDriftResultsWithText.length !== 0 && (
              <>
                <div className="col center">
                  {fixedSuccess && (
                    <img
                      src={`${S3_ASSETS}/illustrations/rocket-computer.svg`}
                      alt="rocket"
                    />
                  )}
                  <span className="title">
                    {fixedSuccess ? (
                      <>Drift fixed!</>
                    ) : (
                      <>Failed fixing drift</>
                    )}
                  </span>
                  <span className="centered FixDriftModal__content-success-subtitle col">
                    {_.map(fixedDriftResultsWithText, (response) => (
                      <FixedDrift key={uuidv4()} obj={response} />
                    ))}
                  </span>
                </div>
              </>
            )}
        </div>
      </div> 
    </AppDrawer>
  );
};

export default FixDriftDrawer;
