import React, { useEffect } from "react";
import _ from "lodash";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import DarkCopyBox from "../../../../shared/darkCopyBox/darkCopyBox";

const GcpFinalInstructions = ({ wizardState, setSubmitDisabled }) => {
  const projectId = wizardState?.projectId || "<PROJECT_NAME>";

  useEffect(() => {
    setSubmitDisabled(false);
  });

  const renderCode = () => {
    if (!_.isEmpty(wizardState)) {
      var hcl = wizardState?.code;
      return <DarkCopyBox text={hcl} />;
    }
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  return (
    <div className="GcpFinalInstructions col">
      <div>
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue GcpFinalInstructions__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">
              Create a directory for the Terraform file of your Google Cloud
              Platform project.
            </span>
          </li>
          <li key="2">
            <span className="dark-blue">
              At your gcloud CLI, run the command:
              <br />
              <span className="purple-flag" style={{ fontSize: "12px" }}>
                {`gcloud config set project ${projectId}`}
              </span>
            </span>
          </li>
          <li key="3">
            <span className="dark-blue">
              Copy the details created by the wizard, and paste them into the
              Terraform code file.
              <div className="GcpFinalInstructions__cmds-item col">
                {renderCode()}
              </div>
            </span>
          </li>
          <li key="4">
              <div className="row g5">
                <span className="dark-blue">Run</span>
                <span className="purple-flag" style={{ fontSize: "12px" }}>
                  terraform init
                </span>
                <span className="purple-flag" style={{ fontSize: "12px" }}>
                  terraform plan
                </span>
                <span className="purple-flag" style={{ fontSize: "12px" }}>
                  terraform apply
                </span>
              </div>
          </li>
          <li key="5">
            <span className="dark-blue">
              After running the commands above a new integration will be created.
            </span>
          </li>
          <li key="6">
            <span className="dark-blue">
              Select <span className="bold">Done</span>.
            </span>
          </li>
        </ol>
      </div>
    </div>
  );
};

export default GcpFinalInstructions;
