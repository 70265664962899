import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import { inventoryEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";

export const GET_CHILDREN_LEVEL = "GET_CHILDREN_LEVEL";
export const GET_PARENT_FAMILY = "GET_PARENT_FAMILY";

const getRelationLevelByParent = (frn, integrationId, pageNumber, pageSize) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/relationship`,
      "POST",
      { parentFrn: frn, parentIntegrationId: integrationId, pageNumber, pageSize }
    );
    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    // product event
    sendEvent(inventoryEvents.nestedChildrenClick, {frn, integrationId, action: "User clicked parent to view relationship" } )

    dispatch({
      type: GET_CHILDREN_LEVEL,
      payload: data,
    });
    
    return data;
  });
};

const getRelationFamily = (frn, integrationId, pageNumber, pageSize, graph = false) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/relationship/family`,
      "POST",
      { parentFrn: frn, parentIntegrationId: integrationId, pageNumber, pageSize, graph }
    );
    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_PARENT_FAMILY,
      payload: data,
    });
    
    return data;
  });
};

export {
  getRelationLevelByParent,
  getRelationFamily
};