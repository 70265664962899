export const emptyIacScreenFilters = {
  stateType: [],
  modules: [],
  status: [],
  "stackProviders.providerType": [],
  providerStatuses: [],
  crawlerId: [],
  stateMainRemoteLocationString: [],
  "stackProviders.providerIntegrationId": [],
  stackAssetTypes: [],
  iacState: [],
  "stackProviders.providerAccountId": [],
};

export const emptyModulesScreenFilters = {
  _id: [],
  moduleOrigin: [],
  misconfigurations: [],
  moduleOriginType: [],
  vcsId: [],
  repoFullName: [],
};
