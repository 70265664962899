import React, { useState, useEffect, useCallback } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import debounce from "lodash/debounce";

import "./headerSearchBox.scss";

const HeaderSearchBox = ({
  placeholder,
  value,
  resetSearch,
  currentValue,
  hideClear,
  dynamicSearch = false,
}) => {
  const [val, setVal] = useState("");
  const [active, setActive] = useState(false);

  useEffect(() => {
    setVal(currentValue);
  }, [currentValue]);

  const handleChange = (e) => {
    const newValue = e.target.value;
    setVal(newValue);
    if (dynamicSearch) {
      debounceFunc(newValue);
    }
  };

  const debounceFunc = useCallback(debounce((val) => value(val), 500));
  
  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !dynamicSearch) {
      e.preventDefault();
      handleSearch();
    }
  };

  const handleSearch = () => {
      return value(val);
  };

  const handleClear = () => {
    setVal("");
    return resetSearch("");
  };

  return (
    <div
      className={`HeaderSearchBox row`}
      style={{
        width: "280px",
      }}
    >
      <div className="HeaderSearchBox__inner">
        <input
          placeholder={active || val?.length > 0 ? null : placeholder}
          onChange={handleChange}
          onKeyDown={handleKeyDown}
          value={val}
          onFocus={() => setActive(true)}
          onBlur={() => setActive(false)}
        />
        {val?.length > 0 && !hideClear &&(
          <FontAwesomeIcon
            icon="times-circle"
            className="HeaderSearchBox__inner-clear"
            onClick={handleClear}
          />
        )}
      </div>
      {!dynamicSearch &&
      <button
        className="HeaderSearchBox__btn"
        onClick={handleSearch}
        style={{ height: '40px', padding: '5px 15px' }}
      >
        <FontAwesomeIcon icon="search" />
      </button>}
    </div>
  );
};

export default HeaderSearchBox;
