import React, { useState } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import Loading from "../../../../../shared/loading/loading";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";
import AppSwitch from "../../../../../shared/appSwitch/appSwitch";
import RevisionCompare from "./revisionCompare";
import RevisionInfo from "./revisionInfo";
import { ReactComponent as InfoIcon } from "../../../../../Images/inventory_asset_tabs/info_icon.svg";
import { ReactComponent as CompareIcon } from "../../../../../Images/general_icons/outline-compare.svg";
import RevisionStateChange from "./revisionStateChange";
import RevisionIdentical from "./revisionIdentical";
import "./historyContent.scss";

const HistoryContent = ({ loadingSingleRevision, selectedRevision }) => {
  const asset_revision = useSelector(
    (state) => state.historyReducer.asset_revision
  );
  const asset_compare = useSelector(
    (state) => state.historyReducer.asset_compare
  );
  const [view, setView] = useState("compare");

  if (loadingSingleRevision) {
    return (
      <div className="center tab-page">
        <Loading />
      </div>
    );
  }

  if (!loadingSingleRevision && _.isEmpty(asset_revision)) {
    return (
      <div className="tab-page center">
        <AppEmpty customStyle="code" text="No Data For date" />
      </div>
    );
  }

  const getHistoryContent = () => {
    switch (asset_compare.mode) {
      case "state":
        return <RevisionStateChange asset_compare={asset_compare} selectedRevision={selectedRevision} />
      case "configuration":
        return <RevisionCompare
          asset_compare={asset_compare}
          selectedRevision={selectedRevision}
        />
      case "equal":
        return <RevisionIdentical selectedRevision={selectedRevision} />

    }
  }

  return (
    <div className="HistoryContent col">
      <div className="HistoryContent__title row between">
        <span className="HistoryContent__title-text bold">{view === 'compare' ? 'Compare revisions' : 'Revision information'}</span>
        <AppSwitch
          items={[
            { text: <CompareIcon />, value: "compare", tooltip: 'Compare revisions' },
            { text: <InfoIcon />, value: "info", tooltip: 'Revision information' },
          ]}
          active={view}
          onChange={setView}
        />
      </div>
      {view === "compare" ? (
        getHistoryContent()
      ) : (
        <RevisionInfo asset_revision={asset_revision} />
      )}

    </div>
  );
};

export default HistoryContent;
