import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { setFilters } from "../../../redux/actions/inventoryActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  numberWithCommas,
  formatAwsStringWithUnderscore,
} from "../../../utils/formatting";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";

const Top5ListItem = ({ name = "", count, onlyProd, prodAccounts }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const getMasterType = () => {
    const type = name.split("_")[0];
    if (['aws', 'gcp', 'google', 'azure', 'azurerm'].includes(type)) {
      return "cloud";
    }
    if (['kubernetes', 'k8s'].includes(type)) {
      return "k8s";
    }
    return "saas"
  }
  
  const resourceTypeClick = () => {
    if (count > 0) {
      const masterType = getMasterType();
      dispatch(
        setFilters({
          ...emptyInventoryScreenFilters,
          types: [name],
          providerTypes: {
            providerType: [],
            providerSubType: [],
            provider: [],
            providerId: onlyProd ? prodAccounts : [],
          },
          masterType: [masterType],
        })
      );
      history.push({
        pathname: `/inventory`,
        search: `?type=${name}&masterType=${masterType}`,
      });
    }
  };

  return (
    <div className="Top5ListItem center row" onClick={resourceTypeClick}>
      <div className="Top5ListItem__desc center col">
        <div className="Top5ListItem__text col">
          <span className="Top5ListItem__text-count">
            {numberWithCommas(count)}
          </span>
          <span className="Top5ListItem__text-title">
            {formatAwsStringWithUnderscore(name)}
          </span>
        </div>
      </div>
      <div className="Top5ListItem__arrow" >
          <FontAwesomeIcon icon="angle-right" />
      </div>
    </div>
  );
};

export default Top5ListItem;
