import React, { useState } from "react";
import { useSelector } from "react-redux";
import Collapse from "antd/lib/collapse";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import map from "lodash/map";
import { ReactComponent as Repository } from "../../../Images/general_icons/repository.svg";
import { ReactComponent as Misconfiguration } from "../../../Images/general_icons/plainMisconfiguration.svg";
import { ReactComponent as Chevron } from "../../../Images/general_icons/chevron.svg";
import { ReactComponent as BackendsIcon } from "../../../Images/general_icons/backends.svg";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import InventorySingelFilter from "../../inventory/inventoryFilters/inventorySingelFilter";

import "./modulesFilters.scss";
import { filter, keys, indexOf, sortBy } from "lodash";

const priorityOrder = ["CRITICAL", "HIGH", "MEDIUM", "LOW"]; // Define the custom order

const ModulesFilters = ({
  activeRepositories,
  activeMisconfigurations,
  activeModuleSourceTypes,
  handleClearAllFilters,
  resetFilters,
  repositories,
  misconfigurations,
  moduleSourceTypes,
  repositoriesSelected,
  misconfigurationsSelected,
  moduleSourceTypesSelected,
}) => {
  const { Panel } = Collapse;
  const [activePanel, setActivePanel] = useState([]);
  
  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.modulesFilters
  );

  const getActiveObjectNumber = (activeObj) => {
    let num = 0;
    map(values(activeObj), (arr) => {
      num += arr?.length;
    });
    return num;
  };

  const handleCutsomArrow = (e) => {
    return (
      <Chevron
        style={{
          transform: e.isActive
            ? "rotate(-90deg) translateY(2px)"
            : "translateY(2px)",
          transition: "all .2s ease",
          width: "10px",
        }}
      />
    );
  };

  const filterIcons = {
    origin: (
      <Repository
        className={`IacStacksFilters__item-filterIcon-icon ${
          !values(activeRepositories).every(isEmpty) ||
          activePanel?.includes("1")
            ? "emptyIconActive"
            : "emptyIcon"
        }`}
      />
    ),
    misconfiguration: (
      <Misconfiguration
        className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
          !values(activeMisconfigurations).every(isEmpty) ||
          activePanel?.includes("3")
            ? "emptyIconActive"
            : "emptyIcon"
        }`}
      />
    ),
    sourceType: (
        <BackendsIcon
            className={`IacStacksFilters__item-filterIcon-icon ${
                !isEmpty(activeModuleSourceTypes) || activePanel?.includes("2")
                    ? "emptyIconActive"
                    : "emptyIcon"
            }`}
        />
    ),
  };

  const panelHeader = (title, activeArr, idx, icon, activeObj) => {
    return (
      <div className="IacStacksFilters__item row">
        {filterIcons[icon]}
        <span
          className={`IacStacksFilters__item-title row ${
            !isEmpty(activeArr) ||
            !values(activeObj).every(isEmpty) ||
            activePanel?.includes(idx)
              ? "active"
              : ""
          }`}
        >
          <span>{title}</span>
          {!isEmpty(activeArr) && (
            <span className="purple-text">{`(${activeArr?.length})`}</span>
          )}
          {!values(activeObj).every(isEmpty) &&
            getActiveObjectNumber(activeObj) > 0 && (
              <span className="purple-text">{`(${getActiveObjectNumber(
                activeObj
              )})`}</span>
            )}
        </span>
      </div>
    );
  };

  const checkShouldShowClearAll = () => {
    let shouldShow = false;
    let notEmptyFilterKeys = filter(
      keys(screenFilters),
      (filterKey) => !isEmpty(screenFilters[filterKey])
    );
    if (!isEmpty(notEmptyFilterKeys)) {
      if (
        notEmptyFilterKeys?.includes("stateType") &&
        notEmptyFilterKeys?.length === 1
      ) {
        shouldShow = false;
      } else {
        shouldShow = true;
      }
    }
    return shouldShow;
  };

  const renderSortedMisconfigurations = () =>{
    const sortedMisconfigurations = sortBy(misconfigurations, (item) => {
      return indexOf(priorityOrder, item.name);
    });
    return sortedMisconfigurations;
  }

  return (
    <div className="ModulesFilters">
      <div className="ModulesFilters__header row between">
        <span className="ModulesFilters__header-title">Filters</span>
        {checkShouldShowClearAll() ? (
          <div className="ModulesFilters__header-actions row">
            <IconBtn
              onClick={handleClearAllFilters}
              type="clear"
              colorClass="filter"
              tooltipText="Clear all"
            />
          </div>
        ) : null}
      </div>
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel
          header={panelHeader(
            "Module Source",
            activeRepositories,
            "1",
            "origin"
          )}
          key="1"
        >
          <InventorySingelFilter
            allTypes={repositories}
            selectedTypes={repositoriesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "1"}
            checkedList={activeRepositories}
          />
        </Panel>
        <Panel
            header={panelHeader(
                "Module Source Type",
                activeRepositories,
                "2",
                "sourceType"
            )}
            key="2"
        >
          <InventorySingelFilter
              allTypes={moduleSourceTypes}
              selectedTypes={moduleSourceTypesSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === "2"}
              checkedList={activeModuleSourceTypes}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Misconfigurations",
            activeMisconfigurations,
            "3",
            "misconfiguration"
          )}
          key="3"
        >
          <InventorySingelFilter
            allTypes={renderSortedMisconfigurations()}
            selectedTypes={misconfigurationsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "3"}
            checkedList={activeMisconfigurations}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default ModulesFilters;
