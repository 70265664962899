import {
  GET_JIRA_INTEGRATIONS,
  CREATE_JIRA_INTEGRATION,
  EDIT_JIRA_INTEGRATION,
  DELETE_JIRA_INTEGRATION,
} from "../actions/jiraIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_JIRA_INTEGRATION:
      return { ...state };

    case GET_JIRA_INTEGRATIONS:
      return { ...state, integrations: payload.result };

    case EDIT_JIRA_INTEGRATION:
      return { ...state };

    case DELETE_JIRA_INTEGRATION:
      return { ...state };
    default:
      return state;
  }
}
