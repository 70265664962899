import React, { useState, useEffect } from "react";
import _ from "lodash";
import { appToast } from "../../../shared/appToast/appToast";
import img from "../../../Images/general_icons/globeVector.svg";
import GradientBtn from "../../../shared/gradientBtn/gradientBtn";
import GradientTextarea from "../../../shared/gradientTextarea/gradientTextarea";
import "./descriptionBlock.scss";

const DescriptionBlock = ({
  name,
  value,
  onChange,
  onClick,
  placeholder,
  loading,
  wizardState,
}) => {
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    checkIfDisable();
  }, [wizardState]);

  const checkIfDisable = () => {
    let output = false;
    setErrors([]);

    if (_.isEmpty(wizardState?.scope)) {
      setErrors(["You must select asset type"]);
      output = true;
    }
    if (wizardState?.scope?.length > 1) {
      setErrors(["only 1 asset type is allowed"]);
      output = true;
    }

    if (wizardState?.scope?.some((item) => item?.includes("objects"))) {
      setErrors(["Only single asset type is allowed not a whole data scource"]);
      output = true;
    }
    if (_.isEmpty(wizardState?.description)) {
      setErrors(["You must to write a description of your rule"]);
      output = true;
    }

    return output;
  };

  const handleValidateOnClick = () => {
    if (checkIfDisable()) {
      return appToast(
        "info",
        "",
        _.map(errors, (item) => item)
      );
    }
    if (loading) {
      return;
    }
    return onClick();
  };

  return (
    <div className="DescriptionBlock col g10">
        <GradientTextarea
          name={name}
          value={value}
          onChange={onChange}
          placeholder={placeholder}
          rows="3"
        />
      <GradientBtn
        text="Generate using AI"
        loading={loading}
        icon={<img src={img} alt="globe" />}
        onClick={handleValidateOnClick}
      />
    </div>
  );
};

export default DescriptionBlock;
