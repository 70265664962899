import React, { useMemo } from "react";
import { insights } from "../../../utils/icons";

const CategoryCell = ({ value }) => {
  const icon = useMemo(() => {
    switch (value) {
      case "Reliability":
        return <img src={insights["reliability"]} alt="reliability" />;

      case "Optimization":
        return <img src={insights["optimization"]} alt="optimization" />;

      case "Misconfiguration":
        return (
          <img src={insights["misconfiguration"]} alt="misconfiguration" height="13px" />
        );

      default:
        return null;
    }
  }, [value]);

  return (
    <div className="CategoryCell row g10">
      {icon}
      {value}
    </div>
  );
};

export default CategoryCell;
