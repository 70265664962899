import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { clouds } from "../../../utils/icons";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import _ from "lodash";
import { getControllerSources, getIac, getIacStack } from "../../../redux/actions/iacStacksActions";
import { getInventoryResource } from "../../../redux/actions/inventoryActions";
import { resetHistoryState, getDeletedConfig } from "../../../redux/actions/historyActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { sendEvent } from "../../../utils/amplitude";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import whiteGcp from "../../../Images/clouds/whiteGcp.svg"
import whiteAzure from "../../../Images/clouds/whiteAzure.svg"
import whiteAws from "../../../Images/clouds/whiteAws.svg"
import { ReactComponent as CloudMigrationWhiteIcon } from "../../../Images/clouds/cloud_migration_white.svg"

// components
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import CodifyButton from "./codifyButton/codifyButton";
import AssetTabs from "./assetTabs/assetTabs";
import AssetComments from "./assetComments/assetComments";
import Configuration from "./assetConfig/configuration";
import AppBtn from "../../../shared/appBtn/appBtn";
import AssetInfo from "./assetInfo/assetInfo";
import AssetHistory from "./assetHistory/assetHistory";
import {TABS_KEYS, PROVIDERS_SUPPORT_EVENT_VIEWER, PROVIDERS, CLOUD_PROVIDERS} from "../../../consts/general";
import EventsViewer from "./eventsViewer/eventsViewer";
import { getAssetEventsCount, resetAssetEvents } from "../../../redux/actions/assetEventsActions";
import AssetRelationship from "./assetRelationship/assetRelationship";
import "./assetDataModal.scss";
import Loading from "../../../shared/loading/loading";
import { faShareAlt } from "@fortawesome/free-solid-svg-icons";
import { appToast } from "../../../shared/appToast/appToast";
import IacStatusFlag from "../../../shared/iacStatusFlag/iacStatusFlag";

const AssetDataModal = ({
  visible,
  handleClose,
  data,
  handleRemoveAsset,
  handleCodifyClick,
  handleCreateJiraIssue,
  handleOpenAnalysis,
  onClickTag,
  onClickResourceGroup,
  defaultTab,
  parentData,
  innerSectionTab,
  handleAssetModalFromChildren,
  handleNextAsset,
  nextAssetDisabled,
  handlePrevAsset,
  prevAssetDisabled,
  fetchingAssetLoading,
  handleChangeTab,
  handleResetParentTab
}) => {
  const dispatch = useDispatch();
  const [activeTab, setActiveTab] = useState("general");
  const [infoActiveTab, setInfoActiveTab] = useState("info");
  const [assetData, setAssetData] = useState({});
  const [loadingConfig, setLoadingConfig] = useState(true);
  const [loadingIac, setLoadingIac] = useState(false);
  const [cftStacksData, setCftStacksData] = useState([]);
  const [stacksData, setStacksData] = useState([]);
  const [loadingEventsCount, setLoadingEventsCount] = useState(false)
  const selectedRevision = useSelector((state) => state.historyReducer.asset_revision);

  useEffect(() => {
    if (visible && data) {
      setActiveTab(defaultTab);
      setInfoActiveTab(innerSectionTab);
      setAssetData(data);
      handleEventsCount(data);
      fetchControllerSources(data);
      handleFetchConfiguration();
      handleFetchIac();
    }
  }, [data, visible]);

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const handleEventsCount = (data) => {
    if (PROVIDERS_SUPPORT_EVENT_VIEWER.includes(data.provider)) {
      setLoadingEventsCount(true)
      dispatch(getAssetEventsCount(data))
      setTimeout(() => setLoadingEventsCount(false), 1000)
    }
  }
  const handleCloseModal = () => {
    setCftStacksData([]);
    setStacksData([]);
    dispatch(resetHistoryState());
    dispatch(resetAssetEvents())
    return handleClose();
  };

  const fetchControllerSources = async (data = {}) => {
    const { frn: targetFrn, integrationId: targetIntegrationId, hasControllerSources } = data;
    if (!hasControllerSources) return;
    const payload = {
        targetFrn,
        targetIntegrationId,
    }
    await dispatch(getControllerSources(payload))
  }

  const handleFetchConfiguration = async () => {
    if (!_.isEmpty(data?.inventoryItem)) {
      return setLoadingConfig(false);
    }
    setLoadingConfig(true);
    let newAsset = data;

    // fetch configuration for deleted assets
    if(data?.state === 'deleted') {
      if(_.isEmpty(data?.metadata) || data?.metadata === 'deleted') {
      const deletedConfig = await dispatch(getDeletedConfig(data?.frn, data?.assetType))
      newAsset.metadata = deletedConfig;
    }
    setAssetData(newAsset);
    setLoadingConfig(false);
    return;
   } 

  //  regular assets
    const providerIdKey = newAsset?.state === "ghost" ? "stackId" : null;
    const isGhostOrPendingToGhost = newAsset?.state === "ghost" ||
    (newAsset?.state === "pending" && newAsset?.pendingTo === "ghost")
    let inventory = await dispatch(
        isGhostOrPendingToGhost ? getIac(newAsset?.frn)
        : getInventoryResource(
            [newAsset?.arn],
            [newAsset?.assetType],
            "inv",
            null,
            null,
            true
          ),
      providerIdKey,
      newAsset?.stackId
    );
    inventory =
      isGhostOrPendingToGhost
        ? inventory
        : _.first(inventory?.responseObjects || []);
    newAsset.inventoryItem =
      (isGhostOrPendingToGhost
        ? inventory?.attributes
        : inventory?.inventoryItem) || {};
    newAsset.originalProviderId = inventory?.providerId;
  
    setAssetData(newAsset);
    setLoadingConfig(false);
  };

  const handleFetchIac = async () => {
    setLoadingIac(true);

    if (!_.isEmpty(data?.stackIds)) {
      let stacksData = [];
      for (const stackId of data?.stackIds) {
        const stack = await dispatch(getIacStack(stackId));
        if (!_.isEmpty(stack)) {
          stacksData.push(stack);
        }
      }
      setStacksData(stacksData);
    } else {
      if (!_.isEmpty(data?.stackId) && data?.stackId !== "unassigned") {
        const stack = await dispatch(getIacStack(data?.stackId));
        if (!_.isEmpty(stack)) {
          setStacksData([stack]);
        }
      } else {
        if (!_.isEmpty(data?.cftStackIds)) {
          let cftStacksData = [];
          for (const stackId of data?.cftStackIds) {
            const stack = await dispatch(getIacStack(stackId));
            if (!_.isEmpty(stack)) {
              cftStacksData.push(stack);
            }
          }
          setCftStacksData(cftStacksData);
        } else {
          if (
            !_.isEmpty(data?.cftStackId) &&
            data?.cftStackId !== "unassigned"
          ) {
            const stack = await dispatch(getIacStack(data?.cftStackId));
            if (!_.isEmpty(stack)) {
              setCftStacksData([stack]);
            }
          }
        }
      }
    }
    setLoadingIac(false);
  };

  const handleResetDefaultTab = () => {
    setActiveTab(TABS_KEYS.general);
    handleResetParentTab()
  }

  const renderTabsContent = () => {
    switch (activeTab) {
      case TABS_KEYS.general:
        return (
          <AssetInfo
            asset={assetData}
            onClickTag={onClickTag}
            onClickResourceGroup={onClickResourceGroup}
            loadingIac={loadingIac}
            stacksData={stacksData}
            cftStacksData={cftStacksData}
            handleOpenAnalysis={() => {
              sendEvent(inventoryEvents.driftButton, {
                action: "user clicked drift details from asset info modal",
              });
              handleOpenAnalysis(
                !_.isEmpty(parentData) && data?.isChild ? parentData : data
              );
            }}
            parentData={parentData}
            infoActiveTab={infoActiveTab}
            handleAssetModalFromChildren={handleAssetModalFromChildren}
          />
        );
      case TABS_KEYS.config:
        return (
          <Configuration
            item={assetData?.state === 'deleted' ? assetData?.metadata : assetData?.inventoryItem}
            loading={loadingConfig}
          />
        );
      case TABS_KEYS.history:
        return <AssetHistory asset={assetData} />;
      case TABS_KEYS.comments:
        return (
          <AssetComments
            asset={assetData}
            setNewCount={(comments) => setAssetData({ ...assetData, comments })}
          />
        );
      case TABS_KEYS.eventViewer: 
        return <EventsViewer asset={assetData} />
      case TABS_KEYS.relationship: 
        return <AssetRelationship asset={assetData} />;
      default:
        return (
          <AssetInfo
            asset={assetData}
            onClickTag={onClickTag}
            loadingIac={loadingIac}
            stacksData={stacksData}
            cftStacksData={cftStacksData}
            handleOpenAnalysis={() => {
              sendEvent(inventoryEvents.driftButton, {
                action: "user clicked drift details from asset info modal",
              });
              handleOpenAnalysis(
                !_.isEmpty(parentData) && data?.isChild ? parentData : data
              );
            }}
            parentData={parentData}
            infoActiveTab={infoActiveTab}
            handleAssetModalFromChildren={handleAssetModalFromChildren}
          />
        );
    }
  };


  const getJumpToConsoleProviderIcon = () =>{
    switch(data.provider){
      case CLOUD_PROVIDERS.aws: 
        return <img src={whiteAws} alt="Gcp icon" width="22px"/>;
      case CLOUD_PROVIDERS.gcp:
        return <img src={whiteGcp} alt="Gcp icon" width="16px"/>;
      case CLOUD_PROVIDERS.azure:
      case CLOUD_PROVIDERS.azurerm:
          return <img src={whiteAzure} alt="Azure icon" width="16px"/>
      default:
        return <FontAwesomeIcon icon="external-link-alt" />;

    }
  }

  const renderFooter = () => {
    const isHistoryTab = activeTab === TABS_KEYS.history;
    return (
      <div className="row between">
        <div className="row" style={{ gap: "10px" }}>
          {(data?.state === "modified" ||
            (!_.isEmpty(parentData) &&
              data?.isChild &&
              parentData?.state === "modified")) && (
            <AppBtn
              text="Drift Details"
              onClick={() => {
                sendEvent(inventoryEvents.driftButton, {
                  action: "user clicked drift details from asset info modal",
                });
                handleOpenAnalysis(
                  !_.isEmpty(parentData) && data?.isChild ? parentData : data
                );
              }}
              disabled={data?.isLocked}
              icon={<FontAwesomeIcon icon="code-branch" />}
            />
          )}
          {
            <CodifyButton
              text="Codify"
              data={data}
              handleCodifyClick={handleCodifyClick}
              parentData={parentData}
              disabled={isHistoryTab}
              icon={<span>{`{ }`}</span>}
            />
          }
          {_.includes([PROVIDERS.aws, PROVIDERS.gcp, PROVIDERS.azurerm], data?.provider) && (
            <CodifyButton
                text="Migrate"
                data={{
                  isCloudMigration: true,
                  ...data
                }}
                handleCodifyClick={handleCodifyClick}
                parentData={parentData}
                disabled={isHistoryTab}
                icon={<CloudMigrationWhiteIcon className="AssetDataModal__footer-action-migrateIcon"/>}
            />
          )}
          {isHistoryTab && (
            <CodifyButton
              text="Codify Revision"
              data={{
                revisionId: selectedRevision?.runId,
                ...selectedRevision,
              }}
              handleCodifyClick={handleCodifyClick}
              disabled={
                data?.isLocked ||
                (data?.pendingTo === "ghost" && data?.state === "pending") || data?.state === "deleted"
              }
              icon={<span>{`{ }`}</span>}
            />
          )}
           
        </div>
        <div
          className="row AssetDataModal__footer-action-right"
          style={{ gap: "10px" }}
        >
          <AppBtn
            icon={<FontAwesomeIcon icon={faShareAlt} />}
            tooltipText="Share Asset"
            onClick={() => {
              const assetId = data?.id || `${data?.integrationId}-${data?.assetType}-${data?.frn}`;
              navigator.clipboard.writeText(`${window.location.origin}/inventory?asset=${assetId}`);
              appToast(
                "success",
                "Sharing is Caring!",
                "Shareable Link copied to clipboard"
              )
            }}

          />
          <AppBtn
            tooltipText="Delete Asset"
            onClick={() => {
              sendEvent(inventoryEvents.removeAssetButton, {
                action: "user clicked delete asset from asset info modal",
              });
              handleRemoveAsset(data);
            }}
            disabled={isViewer || !data?.deleteCommand}
            icon={<FontAwesomeIcon icon="trash" />}
          />
          <AppBtn
            tooltipText="Create Jira Issue"
            // secondary
            onClick={() => {
              sendEvent(inventoryEvents.createIssue, {
                action: "user clicked Create Jira Issue from asset info modal",
              });
              handleCreateJiraIssue(data);
            }}
            disabled={data?.isLocked}
            icon={<FontAwesomeIcon icon={["fab", "jira"]} />}
          />
          <AppBtn
            tooltipText="Jump to Code"
            // secondary
            onClick={() => {
              sendEvent(inventoryEvents.jumpToCode, {
                action: "user clicked jump to code from asset info modal",
              });
              return window.open(data?.vcsCodeLink, "_blank");
            }}
            disabled={!data?.vcsCodeLink || _.isEmpty(data?.vcsCodeLink)}
            icon={
              <FontAwesomeIcon
                icon={["fab", "git-alt"]}
                style={{ fontSize: "17px", transform: "translateY(2px)" }}
              />
            }
          />
          {data?.consoleURL && !_.isEmpty(data?.consoleURL) && (
            <AppBtn
              tooltipText="Jump to Console"
              // secondary
              onClick={() => {
                sendEvent(inventoryEvents.jumpToConsole, {
                  action: "user clicked jump to console from asset info modal",
                });
                return window.open(data?.consoleURL, "_blank");
              }}
              disabled={false}
              icon={getJumpToConsoleProviderIcon()}
              />
            )}
        </div>
      </div>
    );
  };
  const renderHeaderSuffix = () => {
    return <div className="AssetDataModal_suffix_actions">
    <AppBtn
      text="Prev"
      tooltipText="Previous Asset"
      onClick={handlePrevAsset}
      disabled={prevAssetDisabled}
      icon={<FontAwesomeIcon icon="arrow-left" />} />
    <span className="vertical-line"></span>
    <AppBtn
      text="Next"
      tooltipText="Next Asset"
      onClick={handleNextAsset}
      disabled={nextAssetDisabled}
      suffixIcon={<FontAwesomeIcon icon="arrow-right" />} />
  </div>
  };

 if (fetchingAssetLoading)
   return (
     <NewAppModal visible={true} centered>
       <Loading />
     </NewAppModal>
   );
  
  const renderAssetTitle = () => {
    return <div className="AssetDataModal_title">
      <span>{data?.name}</span>
      <IacStatusFlag
        state={!_.isEmpty(parentData) && assetData?.isChild ? parentData?.state : assetData?.state}
        iacType={!_.isEmpty(parentData) && assetData?.isChild ? parentData?.iacType : assetData?.iacType}
        hasControllerSources={!_.isEmpty(parentData) && assetData?.hasControllerSources ? parentData?.hasControllerSources : assetData?.hasControllerSources}
        rowData={assetData}
        handleOpenMulti={() => false}
        isLocked={false} />
    </div>;
  };
  
  return (
    <NewAppModal
      visible={visible}
      handleClose={handleCloseModal}
      destroyOnClose
      centered
      width={"85vw"}
      bodyClassName="AssetDataModal"
      title={renderAssetTitle() }
      subtitle={formatAwsStringWithUnderscore(data?.assetType, true)}
      iconSrc={clouds(data?.provider, themeDark)}
      footer={renderFooter()}
      headerSuffix={renderHeaderSuffix()}
    >
      <AssetTabs
        activeTab={activeTab}
        handleSetTab={(tab) => {
          setActiveTab(tab);
          handleChangeTab(tab)
        }
      }
        assetData={assetData}
        loadingEvents={loadingEventsCount}
        handleResetTab={handleResetDefaultTab}
      />
      <div className="AssetDataModal__tab">{renderTabsContent()}</div>
    </NewAppModal>
  );
};

export default AssetDataModal;
