import {
  TOGGLE_ENTER_ORCHESTRATOR,
  GET_ORCHESTRATORS,
  GET_VARIABLES,
  GET_RUNS,
  GET_RUN_STAGES,
  GET_POLICIES,
  GET_ORCHES_BY_ID,
} from "../actions/orchestratorActions";
import _ from "lodash";

const initialState = {
  userInOrchestror: false,
  envData: null,
  orchestrators: [],
  variables: [],
  runs: [],
  stages: [],
  policies: [],
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case TOGGLE_ENTER_ORCHESTRATOR:
      return { ...state, userInOrchestror: payload.isOrchestrator,  envData: payload.envData };

    case GET_ORCHESTRATORS:
      let tempOrchestrators = [];
      if (!_.isEmpty(payload.data)) {
        tempOrchestrators = payload.data.map((item) => ({
          ...item,
          type: "orchestrator",
        }));
      }
      return { ...state, orchestrators: tempOrchestrators };

    case GET_VARIABLES:
      return { ...state, variables: payload.data };
      
    case GET_ORCHES_BY_ID:
      return { ...state, envData: payload.data };

      case GET_RUNS:
      return { ...state, runs: payload.data };

      case GET_RUN_STAGES:
      return { ...state, stages: payload.data };

      case GET_POLICIES:
      return { ...state, policies: payload.data };

    default:
      return state;
  }
};
