import {
    SET_SERACH_VAL_HEADER,
    GET_ALL_CLOUDS,
    GET_ALL_IAC,
    GET_ALL_VCS,
    GET_ALL_NOTIFICATIONS,
    GET_ALL_PROJECTS,
    TOGGLE_PROVISIONING,
  } from "../actions/globalAppActions";
  
  const initialState = {
    searchVal: "",
    clouds: {},
    iac: {},
    vcs: {},
    notifications: {},
    project: {},
    provionOpen: false,
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
  
      case SET_SERACH_VAL_HEADER:
        return { ...state, searchVal: payload };

      case GET_ALL_CLOUDS:
        return { ...state, clouds: payload?.clouds };

      case GET_ALL_IAC:
        return { ...state, iac: payload?.iac };

      case GET_ALL_VCS:
        return { ...state, vcs: payload?.vcs };

      case GET_ALL_NOTIFICATIONS:
        return { ...state, notifications: payload?.notifications };

      case GET_ALL_PROJECTS:
        return { ...state, project: payload?.project };
      case TOGGLE_PROVISIONING:
        return { ...state, provionOpen: !state.provionOpen };
        default:
        return state;
    }
  }
  