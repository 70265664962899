import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
import { numberToPastUnixDate } from "../../utils/formatting";

export const GET_DRIFTS = "GET_DRIFTS";
export const UPDATE_RESOURCE_DRIFTS = "UPDATE_RESOURCE_DRIFTS";
export const UPDATE_CONFIGURATION_DRIFTS = "UPDATE_CONFIGURATION_DRIFTS";
export const CHANGE_SELECTED_CATEGORY = "CHANGE_SELECTED_CATEGORY";
export const CHANGE_FILTER = "CHANGE_FILTER";
export const SELECT_DRIFT = "SELECT_DRIFT";
export const GET_DRIFTS_LIST = "GET_DRIFTS_LIST";

const getDrifts = (
  startDate,
  filteredResourceTypes,
  filteredAccounts,
  filteredResourceStates,
) => {
  return action(async (dispatch) => {
    
    let queryAdditionalFilters = [];
    // create the resource type filter of the query

    if (!_.isEmpty(filteredResourceTypes)) {
      let resourceTypeQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredResourceTypes, (filter) => {
        resourceTypeQuery.bool.should.push({
          term: {
            "assetType.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceTypeQuery);
    }

    // create the Account filter of the query
    if (!_.isEmpty(filteredAccounts)) {
      let resourceAccountQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredAccounts, (filter) => {
        resourceAccountQuery.bool.should.push({
          term: {
            "integrationId.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceAccountQuery);
    }

    // create the filter iac status of the query
    if (!_.isEmpty(filteredResourceStates)) {
      let resourceQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredResourceStates, (filter) => {
        resourceQuery.bool.should.push({
          term: {
            "state.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceQuery);
    }

    const requestWrapper = new RequestWrapper();
    const unixDate = numberToPastUnixDate(startDate);

    let payload = {
      startDate: unixDate,
      queryAdditionalFilters,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drifts`,
      "POST",
      payload
    );

    const data = await req.json();

    dispatch({
      type: GET_DRIFTS,
      payload: { data },
    });

    return { drift: data };
  });
};

const getDriftList = (
  startDate,
  filteredResourceTypes,
  filteredAccounts,
  filteredResourceStates,
  filterInsights,
) => {
  return action(async (dispatch) => {
    let queryAdditionalFilters = [];
    // create the resource type filter of the query

    if (!_.isEmpty(filteredResourceTypes)) {
      let resourceTypeQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredResourceTypes, (filter) => {
        resourceTypeQuery.bool.should.push({
          term: {
            "assetType.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceTypeQuery);
    }

    // create the Account filter of the query
    if (!_.isEmpty(filteredAccounts)) {
      let resourceAccountQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredAccounts, (filter) => {
        resourceAccountQuery.bool.should.push({
          term: {
            "integrationId.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceAccountQuery);
    }

    // create the filter iac status of the query
    if (!_.isEmpty(filteredResourceStates)) {
      let resourceQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filteredResourceStates, (filter) => {
        resourceQuery.bool.should.push({
          term: {
            "state.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceQuery);
    }

    // classification filter
    if (!_.isEmpty(filterInsights)) {
      let resourceQuery = {
        bool: {
          should: [],
          minimum_should_match: 1,
        },
      };
      _.forEach(filterInsights, (filter) => {
        resourceQuery.bool.should.push({
          term: {
            "classificationName.keyword": {
              value: filter,
            },
          },
        });
      });
      queryAdditionalFilters.push(resourceQuery);
    }

    const requestWrapper = new RequestWrapper();
    const unixDate = numberToPastUnixDate(startDate);

    let payload = {
      startDate: unixDate,
      queryAdditionalFilters,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/drifts/list`,
      "POST",
      payload
    );

    const data = await req.json();
    dispatch({
      type: GET_DRIFTS_LIST,
      payload: { data },
    });
  });
};

const updateResourceDrifts = (newDrifts, resolvedDrifts, drifts) => {
  return action(async (dispatch) => {
    dispatch({
      type: UPDATE_RESOURCE_DRIFTS,
      payload: { new: newDrifts, resolved: resolvedDrifts, drifts },
    });
  });
};

const updateConfigurationDrifts = (newDrifts, resolvedDrifts, drifts) => {
  return action(async (dispatch) => {
    dispatch({
      type: UPDATE_CONFIGURATION_DRIFTS,
      payload: { new: newDrifts, resolved: resolvedDrifts, drifts },
    });
  });
};

const changeSelectedCategory = (category) => {
  return action(async (dispatch) => {
    dispatch({
      type: CHANGE_SELECTED_CATEGORY,
      payload: { category },
    });
  });
};

const changeFilter = (filterQuery) => {
  return action(async (dispatch) => {
    dispatch({
      type: CHANGE_FILTER,
      payload: { filterQuery },
    });
  });
};

const selectDrift = (selectedDrift) => {
  return action(async (dispatch) => {
    dispatch({
      type: SELECT_DRIFT,
      payload: { selectedDrift },
    });
  });
};

export {
  getDrifts,
  getDriftList,
  updateResourceDrifts,
  updateConfigurationDrifts,
  changeSelectedCategory,
  changeFilter,
  selectDrift,
};
