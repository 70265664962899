import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCode, faCopy } from "@fortawesome/free-solid-svg-icons";

import "./darkCopyBox.scss";

const DarkCopyBox = ({ text }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClicked = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className="DarkCopyBox__wrapper">
      <div className="DarkCopyBox__wrapper-box row between">
        <span className="DarkCopyBox__wrapper-box-text">{text}</span>
        <CopyToClipboard text={text} onCopy={onCopyClicked}>
          {isCopied ? (
            <FontAwesomeIcon
              icon={faCheck}
              className="DarkCopyBox__wrapper-box-icon active"
            />
          ) : (
            <FontAwesomeIcon
              icon={faCopy}
              className="DarkCopyBox__wrapper-box-icon"
            />
          )}
        </CopyToClipboard>
      </div>
      <span className="DarkCopyBox__bubble center">
        <FontAwesomeIcon icon={faCode} color="#ffffff" />
      </span>
    </div>
  );
};

export default DarkCopyBox;
