import React from "react";
import { Collapse, Divider, Tooltip } from "antd";
import AppEmpty from "../../../../../shared/appEmpty/appEmpty";
import { v4 as uuidv4 } from "uuid";

import { renderEllipsis } from "../../../../../utils/formatting";
import './costAnalysis.scss';

const { Panel } = Collapse;

const CostAnalysis = ({ data }) => {
    const { action = '', costResults = [], simpleResourceId = '' } = data;
    const costElement = costResults[0] || {};
    const { before = {}, after = {},  } = costElement;
    const costObj = action === 'delete' ? before : after;
    const { subResources = [], costComponents = [], monthlyCost: monthlyTotalCost = "0" } = costObj;
    const isDeleteAction = action === 'delete';
    const title = isDeleteAction ? 'Reduced cost' : 'Expected cost';
    const isCostComponents = costComponents.length > 0;
    const isSubResources = subResources.length > 0;

    const renderCollapsePanel = ( costComponents = [], monthlyTotalCost = '', panelName = '', index = '1' ) => {
        return (
            <Panel key={index} header={
                <div className="CostAnalysis__header">
                    <Tooltip title={panelName?.length > 35 ? panelName : ''}>
                       <span className="CostAnalysis__header-panelName">{renderEllipsis(panelName, 35)} </span>
                    </Tooltip>
                    <span></span>
                    <span className="CostAnalysis__costTitle bold">${monthlyTotalCost == 0 ? monthlyTotalCost : parseFloat(monthlyTotalCost).toFixed(2)}/m</span>
                </div>}>
                {<div className="col">
                    {costComponents.map((component = {}, ind) => {
                        const { name = '', unit = '', price = '', monthlyCost: monthlyItemCost } = component;
                        const isNotTheLastIndex = ind !== costComponents.length - 1;
                        const isMonthlyItemCost = monthlyItemCost && monthlyItemCost != 0;
                        const cost = isMonthlyItemCost ? monthlyItemCost : price;
                        const formattedCost = cost == 0 ? cost : parseFloat(cost).toFixed(2);
                        return (
                            <div className="col"  key={uuidv4()}>
                                <div className="CostAnalysis__header inner">
                                    <span>{name}</span>
                                    <span>{unit}</span>
                                    <span className="bold">{isMonthlyItemCost ? `$${formattedCost}/m` : `$${formattedCost}/m (per ${unit})`}</span>
                                </div>
                                {isNotTheLastIndex && <Divider />}
                            </div>
                        )
                    })}
                </div>}
            </Panel>
        );
    }
    if (!isCostComponents && !isSubResources) {
        return <div className="CostAnalysis__empty">
            <AppEmpty text="No data" customStyle="code" />
        </div>
    }
    return (
        <div className="CostAnalysis col g10">
            <span className={`CostAnalysis__title ${isDeleteAction ? 'delete' : 'create'}`}>{title}</span>
            <div className="CostAnalysis__header titles">
                <span>Name</span>
                <span>Unit</span>
                <span>Cost</span>
            </div>
            {isCostComponents && 
            <Collapse
                className="CostAnalysis"
                expandIconPosition="right"
                defaultActiveKey="1">
                    {renderCollapsePanel(costComponents, monthlyTotalCost, simpleResourceId)}
            </Collapse>}
            {subResources.map((subResource = {}, index) => {
               const { costComponents: subResourceCostComponents = [], monthlyCost: monthlySubResourceTotal = '0', subResourceId: subResourceName = '' } = subResource;
               return (
                <Collapse
                    className="CostAnalysis"
                    expandIconPosition="right"
                    defaultActiveKey="1"
                    key={index}>
                    {renderCollapsePanel(subResourceCostComponents, monthlySubResourceTotal, subResourceName, index)}
                </Collapse>
               )}) 
            }
        </div>)
};



export default CostAnalysis;