import React, { useState, useEffect } from "react";

import AppModal from "../../../shared/appModal/appModal";
import { Form, Select } from "antd";
import { useDispatch } from "react-redux";
import _ from "lodash";

import { assignRole, removeRole } from "../../../redux/actions/profilesActions";
import { useTranslation } from "react-i18next";

const RoleAssignment = ({
  visible,
  handleClose,
  handleCloseRefresh,
  data,
  rolesArr,
}) => {
  const { t } = useTranslation('users')
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  useEffect(() => {
    if (visible) {
      form.setFieldsValue({
        role: handleSetUserCurrentRole(),
      });
    }
  }, [visible]);

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);

    const viewerRoleId = rolesArr.find((item) => item?.name === "Viewer");
    let req;

    if (data?.role === 'Viewer') {
      req = await dispatch(
        removeRole(data?.user_id, [viewerRoleId?.id])
      );
    } else {
      req = await dispatch(
        assignRole(data?.user_id, [viewerRoleId?.id])
      );
    }

    if (req?.ok) {
      handleCloseRefresh();
      form.resetFields();
    }
    setLoadingSubmit(false);
  };

  const handleCloseModal = () => {
    form.resetFields();
    setLoadingSubmit(false);
    handleClose();
  };

  const renderRoles = () => {
    const outputRoles = _.map(rolesArr || [], (role) => (
      <Select.Option value={role?.id} key={role?.id}>
        <div className="col">
          <span>{role?.name}</span>
          <span className="muted">{role?.description}</span>
        </div>
      </Select.Option>
    ));
    return outputRoles;
  };

  const handleSetUserCurrentRole = () => {
    if (!_.isEmpty(rolesArr) && !_.isEmpty(data)) {
      const matchRoleId = rolesArr.find(item => item?.name === data?.role)
      return matchRoleId?.id
    }
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleCloseModal}
      title={t('user-management.role-assign-modal.title', { user: !_.isEmpty(data) ? data?.name : "" })}
      loadingSubmit={loadingSubmit}
      formName="assign-role-form"
      submitBtnText={t('user-management.role-assign-modal.submit-btn')}
    >
      <Form
        {...layout}
        name="assign-role-form"
        form={form}
        className="InviteUser"
        onFinish={handleSubmit}
      >
        <Form.Item
          label={t('user-management.role-assign-modal.form.role.label')}
          name="role"
          rules={[
            {
              required: true,
              message: t('user-management.role-assign-modal.form.role.required'),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select placeholder={t('user-management.role-assign-modal.form.role.placeholder')}>{renderRoles()}</Select>
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default RoleAssignment;
