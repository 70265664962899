import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';
import { appToast } from '../../../../shared/appToast/appToast';
import { sendTestNotification } from '../../../../redux/actions/notificationsActions';
import { useDispatch } from 'react-redux';
import { getAllSlackChannels, updateIntegrationsById } from '../../../../redux/actions/integrationsActions';
import { sendEvent } from '../../../../utils/amplitude';
import { integrationEvents } from '../../../../utils/amplitudeEvents';

const NotificationHeartbeatButton = ({ item = {}, setSelectedSlackApp, setShowSlackChannelsPopup, testNotificationLoading, setTestNotificationLoading }) => {
    const { t } = useTranslation('integrations');

    const dispatch = useDispatch();

    const handleSendTestNotification = async() => {
        const notificationIntegrationId = item._id;
        const payload = { notificationIntegrationId: notificationIntegrationId }
        setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: true });
        if (item.service === "slackApp") {
          if (item.slackChannel) {
            payload.slackChannel = item.slackChannel;
          } else {
            await dispatch(getAllSlackChannels(notificationIntegrationId));
            setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false });
            setSelectedSlackApp(item);
            return setShowSlackChannelsPopup(true);
          }
        }
        const response = await dispatch(sendTestNotification(payload));
        setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
        
        const { status = { lastState: 'unhealthy'} } = response || {};
        dispatch(updateIntegrationsById(notificationIntegrationId, { status }));
        const lastState = status.lastState;
        const isSuccessfulTest = lastState === "healthy";
        sendEvent(integrationEvents.sendTestNotification, { integrationId: notificationIntegrationId, integrationName: item.name,  integrationType: item.service, success: isSuccessfulTest })
        if (isSuccessfulTest) {
          return appToast("success", "", t("notification-heartbeat.alerts.success"))
        }
        if (!isSuccessfulTest && status.error) {
          return appToast("error", "", `${t("notification-heartbeat.alerts.failure")} - ${status.error}`)
        }
    }

    return (
        <Tooltip title={t("notification-heartbeat.tooltips.sendTestButton")}>
            <FontAwesomeIcon spin={testNotificationLoading[item._id]} icon="paper-plane" className="SingleIntegrationItem-sendNotification" onClick={() => handleSendTestNotification(item)}/>
        </Tooltip>
    )
};

export default NotificationHeartbeatButton;