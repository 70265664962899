import {
    CREATE_NEWRELIC_INTEGRATION,
    DELETE_NEWRELIC_INTEGRATION,
    EDIT_NEWRELIC_INTEGRATION,
    GET_NEWRELIC_INTEGRATIONS
  } from "../actions/newrelicIntegrationActions";
  
  const initialState = {
    integrations: [],
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_NEWRELIC_INTEGRATIONS:
        return { ...state, integrations: payload.integrations };
  
      case CREATE_NEWRELIC_INTEGRATION:
        return { ...state };
  
      case DELETE_NEWRELIC_INTEGRATION:
        return { ...state };
  
      case EDIT_NEWRELIC_INTEGRATION:
        return {...state}
  
      default:
        return state;
    }
  }
  