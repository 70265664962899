import React, { useState } from "react";
import { useHistory } from "react-router-dom";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import AkamaiCreation from "./akamaiCreation";

import { useDispatch, useSelector } from "react-redux";
import { createAkamaiIntegration } from "../../../redux/actions/akamaiIntegrationActions";
import {clouds} from "../../../utils/icons";

import "./akamaiIntegration.scss";

const AkamaiIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Akamai Integration details",
      content_title: "Akamai Integration",
      content: (
        <AkamaiCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);

        const result = await dispatch(
          createAkamaiIntegration(
            wizardState.name,
            wizardState.host,
            wizardState.clientSecret,
            wizardState.accessToken,
            wizardState.clientToken,
          )
        );
        setLoadingSubmit(false);
        if (!result?.ok) {
          throw new Error(
            `Failed to create Akamai integration.`
          );
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle="The Resources from the Integration will take up to one hour to appear in the inventory"
          doesTakeTime={true}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="AkamaiIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={clouds("akamai", themeDark)}
      />
    </div>
  );
};

export default AkamaiIntegration;
