import "./removedResource.scss";
import React from "react";
import _ from "lodash";
import {ERR_UPDATED_SOME_FILES} from "../../../../consts/errorCodes";
import CopyBox from "../../../../shared/copyBox/copyBox";


const RemovedResource = ({obj}) => {
    return (
        <div className="col RemovedResource">
            <span>
            {!_.isUndefined(obj?.error) && (obj?.responseText)}
            </span>
            {(_.isUndefined(obj?.error) || obj?.error === ERR_UPDATED_SOME_FILES) &&
                <>
                    <span>
                    Take a look at your <a href={obj?.url} target="_blank" rel="noopener noreferrer">Pull
                        request</a> in the repo {obj?.repo}.
                    </span>
                    <span className="muted">Removed asset code in {_.keys(obj?.updatedFiles).length === 1 ? "file" : "files" }
                        {_.map(_.keys(obj?.updatedFiles), (filePath) => " " + filePath)}.
                    </span>
                    <span className="RemovedResource__removed-commandDescription">
                          To update the Terraform state, run:
                      </span>
                    <span className="RemovedResource__removed-command row">
                         <CopyBox
                             text={`terraform state rm "${obj?.objectInfo?.objectModule && `${obj?.objectInfo?.objectModule}.`}${obj?.objectInfo?.objectType}.${obj?.objectInfo?.objectName}"`}
                             darkMode
                         />
                    </span>
                </>
            }
        </div>
    );
};

export default RemovedResource;
