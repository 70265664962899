import {REMOVE_RESOURCE, CLEAN_RESOURCE_REMOVE_DATA, SEARCH_REMOVE_RESOURCE} from '../actions/removeResourceActions';

const initialState = {
    findRemoveResult: null,
    removeResult: null
};

export default function (state = initialState, {type, payload}) {
    switch (type) {
        case SEARCH_REMOVE_RESOURCE:
            return {...state, findRemoveResult: payload};

        case REMOVE_RESOURCE:
            return {...state, removeResult: payload};

        case CLEAN_RESOURCE_REMOVE_DATA:
            return {...state, removeResult: null, findRemoveResult: null};

        default:
            return state;
    }
}
