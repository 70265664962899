import action from "../middleware";

import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
export const GET_STACKS = "GET_STACKS";
export const CREATE_STACK = "CREATE_STACK";
export const DELETE_STACK = "DELETE_STACK";
export const STACKS_STATUS = "STACKS_STATUS";
export const GET_ALL_STACKS = "GET_ALL_STACKS";
export const GET_ALL_TAGS = "GET_ALL_TAGS";
export const SET_STACKS_FILTERS = "SET_STACKS_FILTERS";
export const STACKS_AGG = "STACKS_AGG";
export const GET_STACK_MAP = "GET_STACK_MAP";

const getAllStacks = (
  filterProviders,
  filterTypes,
  filterLocations,
  filterStatus,
  filterProviderNames,
  filterTags,
  searchVal,
  filterPage,
  isDateSortedDescending
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      filterProviders,
      filterTypes,
      filterLocations,
      filterStatus,
      filterProviderNames,
      filterTags,
      searchVal,
      filterPage,
      isDateSortedDescending,
    };

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/enriched`,
      "POST",
      payload
    );

    const data = await response.json();

    dispatch({
      type: GET_ALL_STACKS,
      payload: { stacks: data?.stacks, total: data?.total, aggs: data?.aggs },
    });
  });
};

const deleteStack = (stackId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedStackReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/${stackId}`,
      "DELETE"
    );

    const data = await deletedStackReq.json();
    return { ok: true, response: data };
  });
};

const getStacksByEnvironmentId = (environmentId, page, status, search) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const statusName = _.isUndefined(status) ? "" : status;
    const envId = environmentId === "all" ? "" : environmentId;

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/enrichedV2?status=${statusName}&page=${page}&name=${search}&environmentId=${envId}`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: GET_STACKS,
      payload: { data },
    });
  });
};

const createCFTStack = (
  iacType,
  name,
  tags,
  awsIntegration,
  externalId,
  region,
  status
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrations = {
      aws: {
        id: awsIntegration,
        externalId,
      },
    };

    const metadata = {
      region,
      status,
    };

    const payload = {
      iacType,
      name,
      tags,
      integrations,
      metadata,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const stack = await req.json();

    return { req, stack };
  });
};

const createS3Stack = (
  iacType,
  name,
  tags,
  awsIntegration,
  s3Bucket,
  s3Key
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const metadata = {
      terraformLocation: {
        selected: "s3",
        s3: {
          awsIntegration,
          s3Bucket,
          s3Key,
        },
      },
    };

    const payload = {
      iacType,
      name,
      tags,
      metadata,
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const json = await res.json();
    return { ok: res.ok, stack: json };
  });
};

const createGCSStack = (
  iacType,
  name,
  tags,
  gcpIntegration,
  gcsBucket,
  gcsKey
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const metadata = {
      terraformLocation: {
        selected: "gcs",
        gcs: {
          gcpIntegration,
          gcsBucket,
          gcsKey,
        },
      },
    };

    const payload = {
      iacType,
      name,
      tags,
      metadata,
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const json = await res.json();
    return { ok: res.ok, stack: json };
  });
};

const createTFCStack = (
  iacType,
  name,
  tags,
  tfcIntegration,
  tfcOrganization,
  tfcWorkspace
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const metadata = {
      terraformLocation: {
        selected: "tfc",
        tfc: {
          tfcIntegration,
          tfcOrganization,
          tfcWorkspace,
        },
      },
    };

    const payload = {
      iacType,
      name,
      tags,
      metadata,
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const json = await res.json();
    return { ok: res.ok, stack: json };
  });
};

const createConsulStack = (
  iacType,
  name,
  tags,
  consulIntegration,
  consulDatacenter,
  consulKey
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const metadata = {
      terraformLocation: {
        selected: "consul",
        consul: {
          consulIntegration,
          consulDatacenter,
          consulKey,
        },
      },
    };

    const payload = {
      iacType,
      name,
      tags,
      metadata,
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const json = await res.json();
    return { ok: res.ok, stack: json };
  });
};

const getTags = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/tags/all`,
      "GET"
    );
    const data = await req.json();
    dispatch({
      type: GET_ALL_TAGS,
      payload: { data },
    });

    return data;
  });
};

const getGlobalStackById = (stackId) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/${stackId}`,
      "GET"
    );

    const data = await response.json();
    return data;
  });
};

const deleteStackByEnvironmentId = (environmentId, stackId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedStackReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/environments/${environmentId}/stack/${stackId}`,
      "DELETE"
    );

    const data = await deletedStackReq.json();
    return { ok: true, response: data };
  });
};

const getStacksStatus = (environmentId, searchVal) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const envId = environmentId === "all" ? "" : environmentId;

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/status?name=${searchVal}&environmentId=${envId}`,
      "GET"
    );

    const data = await response.json();

    dispatch({
      type: STACKS_STATUS,
      payload: { data },
    });
    return data;
  });
};

const exportStacks = (environmentId, searchVal, status) => {
  return action(async () => {
    const requestWrapper = new RequestWrapper();

    const statusName = _.isUndefined(status) ? "" : status;
    const envId = environmentId === "all" ? "" : environmentId;

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/stacks/export?name=${searchVal}&environmentId=${envId}&status=${statusName}`,
      "GET"
    );
    return { file: response.blob(), req: response };
  });
};

const setStacksFilters = (filters) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_STACKS_FILTERS,
      payload: filters,
    });
  });
};

const getStacksAgg = (onlyProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/agg/getAll`,
      "POST",
      { onlyProd }
    );

    const data = await response.json();

    dispatch({
      type: STACKS_AGG,
      payload: { data },
    });
    return data;
  });
};

const getStacksAggByIntegration = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/agg/${integrationId}`,
      "GET"
    );

    const data = await response.json();

    return { req: response, data };
  });
};

const createPulumiStack = (iacType, name, tags, metadata, integrations) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      iacType,
      name,
      tags,
      metadata,
      integrations,
    };

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/`,
      "POST",
      payload
    );
    dispatch({
      type: CREATE_STACK,
      payload: {},
    });

    const json = await res.json();
    return { ok: res.ok, stack: json };
  });
};

const editStackTags = (stackId, tags) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const response = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/tags/${stackId}`,
      "PUT",
      { tags }
    );
    return response?.ok;
  });
};

const getStackMap = (payloadBody) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/global-stacks/map`,
      "POST",
      payloadBody,
      undefined,
      undefined,
      false
    );
    dispatch({
      type: GET_STACK_MAP,
      payload: {},
    });
    const reqData = await res.json();
    return { ok: res.ok, imageUrl: reqData?.imageUrl };
  });
};

export {
  createS3Stack,
  createCFTStack,
  createGCSStack,
  createTFCStack,
  getTags,
  getGlobalStackById,
  createConsulStack,
  deleteStack,
  deleteStackByEnvironmentId,
  getStacksByEnvironmentId,
  getStacksStatus,
  exportStacks,
  getAllStacks,
  setStacksFilters,
  getStacksAgg,
  getStacksAggByIntegration,
  createPulumiStack,
  editStackTags,
  getStackMap
};
