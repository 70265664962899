import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import Base64 from "../../../utils/base64";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import AppBtn from "../../../shared/appBtn/appBtn";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import CategoryCell from "../insightsTable/categoryCell";
import { setFilters } from "../../../redux/actions/inventoryv3Actions";
import "./codeDrawer.scss";

const CodeDrawer = ({ visible, closeDrawer, data = {} }) => {
  const { t } = useTranslation("insights");
  const [code, setCode] = useState(null);
  const history = useHistory();
  const dispatch = useDispatch();
  const showExclusion = useSelector(
    (state) => state.insightsV2Reducer.filters?.showExclusion
  );
  const inventoryFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  useEffect(() => {
    if (visible) {
      fetchCode();
    }
  }, [visible]);

  const fetchCode = () => {
    setCode(`${isEmpty(data?.rego) ? "" : Base64.decode(data?.rego)}`);
  };

  const renderCode = () => {
    if (!isEmpty(code)) {
      return (
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language="hcl"
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {code}
        </SyntaxHighlighter>
      );
    }
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  const renderCategory = () => {
    if (!isEmpty(data?.type)) {
      return <CategoryCell value={data?.labels} />;
    }
    return null;
  };

  const handleEdit = () => {
    return history.push({
      pathname: "/governance/create-custom-control",
      state: {
        data: { ...data, id: data?._id },
      },
    });
  };

  const handleViewAssets = () => {
    dispatch(
      setFilters({
        ...inventoryFilters,
        classifications: [data?._id],
        excluded: showExclusion,
        masterType: []
      })
    );
    return history.push("/inventory");
  };

  const renderWhenDisabled = () => {
    const { excluded_assets, total_assets, isScanning } = data || {};
    return (!excluded_assets && !total_assets) || isScanning;
  };

  return (
    <AppDrawer
      title={data?.name}
      subTitle={renderCategory()}
      closeDrawer={closeDrawer}
      visible={visible}
      width="900px"
      hideClose
      footer={[
        <AppBtn
          text={t("table.menu.edit")}
          onClick={handleEdit}
          icon={<FontAwesomeIcon icon="pen" />}
          key="edit"
        />,
        <AppBtn
          text={t("table.menu.assets")}
          onClick={handleViewAssets}
          icon={<FontAwesomeIcon icon="layer-group" />}
          disabled={renderWhenDisabled()}
          key="view-assets"
        />,
      ]}
    >
      <div className="CodeDrawer__body">{renderCode()}</div>
    </AppDrawer>
  );
};

export default CodeDrawer;
