import React from "react";
import { Handle, Position } from "react-flow-renderer";
import AssetTypeImage from "../../../../../shared/assetTypeImage/assetTypeImage";
import {
  formatAwsStringWithUnderscore,
  providerAssetImagesFormatter,
  renderEllipsis,
} from "../../../../../utils/formatting";
import "./diagramAssetBlock.scss";

const DiagramAssetBlock = ({ asset = {}, onClick, isPostPlan }) => {
  const { data = {} } = asset;
  const { name = '', assetType = '', provider = '', sourceHandleVisible, targetHandleVisible, action: ciAction = '' } = data;
  const elispsisLength = assetType.length + 4;
  const postPlanClassName = `ci ${ciAction}`

  return (
    <div className={`row g10 DiagramAssetBlock ${isPostPlan ? postPlanClassName : ''} `} onClick={onClick}>
      {sourceHandleVisible && <Handle type="source" id="a" position={Position.Right} />}
      <AssetTypeImage
        assetType={assetType}
        provider={providerAssetImagesFormatter(provider)}
        customStyle={{ height: "30px", width: "30px", borderRadius: "4px" }}
      />
      <div className={`${isPostPlan ? 'DiagramAssetBlock__textContainer ' : ''}col`}>
        <span className="bold">
          {formatAwsStringWithUnderscore(assetType)}
        </span>
        <span className="sub-title">
          {renderEllipsis(name, elispsisLength)}
        </span>
      </div>
      {targetHandleVisible && (
        <Handle type="target" position={Position.Left} />
      )}
    </div>
  );
};

export default DiagramAssetBlock;
