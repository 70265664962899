import { ReactComponent as MatchingRules } from "../../../Images/general_icons/classification.svg";
import i18n from '../../../i18n'
export const notificationValues = {
    drift: 'Drift',
    unmanagedResource: 'UnmanagedResource',
    ghostResource: 'GhostResource',
    insightDetected: 'InsightDetected',
    stateFileChange: 'StateFileChange',
    systemActivity: 'SystemActivity',
}

export const notificationTypesIndex = [
    {
      id: 1,
      value: notificationValues.drift,
      name: i18n.t('notifications:create-notification.form.types.drift'),
      displayName: i18n.t('notification:types.drift.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "code-branch",
    },
    {
      id: 2,
      value: notificationValues.unmanagedResource,
      name: i18n.t('notifications:create-notification.form.types.unmanaged-resource'),
      displayName: i18n.t('notification:types.unmanaged-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "exclamation-circle",
    },
    {
      id: 3,
      value: notificationValues.ghostResource,
      name: i18n.t('notifications:create-notification.form.types.ghost-resource'),
      displayName: i18n.t('notification:types.ghost-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "ghost",
    },
    {
      id: 4,
      value: notificationValues.insightDetected,
      name: i18n.t('notifications:create-notification.form.types.insight-detected'),
      displayName: i18n.t('notification:types.insight-detected.display'),
      relatedScope: "insight",
      disabled: false,
      icon: <MatchingRules className={notificationValues.insightDetected}/>,
      
    },
];
