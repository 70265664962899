import React, { useState } from "react";
import { Form, Input, Checkbox } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";

const WebhookCreation = ({
  type,
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const [needCreds, setNeedCreds] = useState(false);
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, webhookUrl, username, password } = formValues;

    if (_.isEmpty(name) || _.isEmpty(webhookUrl)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }

    if (needCreds) {
      if (_.isEmpty(username) || _.isEmpty(password)) {
        formValid = false;
      }
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="WebhookCreation col">
      <div className="WebhookCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue WebhookCreation__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">
              {type !== "teams" ? (
                "Go to your service or app, and generate an incoming webhook URL."
              ) : (
                <span>
                  Go{" "}
                  <a
                    href="https://learn.microsoft.com/en-us/microsoftteams/platform/webhooks-and-connectors/how-to/add-incoming-webhook"
                    target="_blank"
                    rel="noreferrer"
                    className="underline"
                  >
                    here
                  </a>
                  , to generate a webhook URL.
                </span>
              )}
            </span>
          </li>
          <li key="2">
            <span className="dark-blue">
            In Firefly, enter the <span className="bold">Nickname</span> and {" "}
              <span className="bold">Webhook URL</span>.
            </span>
          </li>
          <li key="3">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || type,
          webhookUrl: wizardState.webhookUrl || "",
          username: wizardState.username || "",
          password: wizardState.password || "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please fill integration name" />
        </Form.Item>
        <Form.Item
          label="Webhook URL"
          name="webhookUrl"
          rules={[
            {
              required: true,
              message: "Webhook URL is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input />
        </Form.Item>
        {type === "webhook" && (
          <Form.Item name="needCreds" valuePropName="checked">
            <Checkbox
              checked={needCreds}
              onChange={() => {
                setNeedCreds(!needCreds);
              }}
            >
              <span className="WebhookCreation__checbox">
                Add custom credentials
              </span>
            </Checkbox>
          </Form.Item>
        )}
        {type === "webhook" && needCreds === true && (
          <Form.Item
            label="Username"
            name="username"
            rules={[
              {
                required: true,
                message: "Username is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input />
          </Form.Item>
        )}
        {type === "webhook" && needCreds === true && (
          <Form.Item
            label="Password"
            name="password"
            rules={[
              {
                required: true,
                message: "Password is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input.Password />
          </Form.Item>
        )}
      </Form>
    </div>
  );
};

export default WebhookCreation;
