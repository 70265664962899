import React from "react";
import Lottie from "react-lottie";
import animationData from "./loader_anime.json";

import "./loading.scss";

const Loading = () => {
  const defaultOptions = {
    loop: true,
    autoplay: true,
    animationData: animationData,
  };

  return (
    <div className="Loading">
      <Lottie
        options={defaultOptions}
        height={70}
        width={70}
        isStopped={false}
        isPaused={false}
      />
    </div>
  );
};

export default Loading;
