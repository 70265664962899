import React, { useEffect } from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import { sendEvent } from "../../../../utils/amplitude";
import { generalEvents } from "../../../../utils/amplitudeEvents";

import { formatJSONstring } from "../../../../utils/formatting";

const EventReqRes = ({ eventData = {}, assetData = {}, isAWSProvider }) => {
  const data = isAWSProvider ? eventData.detail : eventData;
  const { requestParameters = "", responseElements = "", errorMessage = "", eventName = "" } = data || {};

  const formattedRequestParameters = formatJSONstring(requestParameters);
  const formattedResponseElements = formatJSONstring(responseElements);
  const formattedErrorMessage = formatJSONstring(errorMessage);

  useEffect(() => {
    sendEvent(generalEvents.eventViewerEventExpansion, {
        assetType: assetData.assetType,
        assetName: assetData.name,
        eventTime: eventData[isAWSProvider ? "@timestamp": "createdAt"],
        eventName,
    })
  },[])
  
  return (
    <div className="EventResReqContainer col">
      {formattedRequestParameters && <div className="ReqResCont col">
        Request
        <div className="ReqResBox">
          <SyntaxHighlighter
            style={atomDark}
            language="json"
            showLineNumbers={true}
            wrapLines={true}
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
            }}
          >
            {formattedRequestParameters}
          </SyntaxHighlighter>
        </div>
      </div>}
      {formattedResponseElements && <div className="ReqResCont col">
        Response
        <div className="ReqResBox">
          <SyntaxHighlighter
            style={atomDark}
            language="json"
            showLineNumbers={true}
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap"},
            }}
          >
            {formattedResponseElements}
          </SyntaxHighlighter>
        </div>
      </div>}
       {formattedErrorMessage && <div className="ReqResCont col">
        Error Message
        <div className="ReqResBox">
          <SyntaxHighlighter
            style={atomDark}
            language="json"
            showLineNumbers={true}
            lineProps={{
              style: { wordBreak: "break-all", whiteSpace: "pre-wrap"},
            }}
          >
            {formattedErrorMessage}
          </SyntaxHighlighter>
        </div>
      </div>}
    </div>
  );
};

export default EventReqRes;
