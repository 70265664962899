import React from "react";
import capitalize from "lodash/capitalize";
import isEmpty from 'lodash/isEmpty';
import has from 'lodash/has';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import FreeTierMask from "../../components/inventory/freeTierMask/freeTierMask";
import { purpleIacProviders, greenIacProviders } from "../../utils/icons";
import { capitalizeFirst, formatIacStatus } from "../../utils/formatting";
import Tooltip from "antd/lib/tooltip";
import { RESOURCE_STATE_TYPES, IAC_TYPES } from "../../consts/general";
import { useTranslation } from "react-i18next";
import "./iacStatusFlag.scss";

const PENDING_CODIFIED_SUPPORT = ['externalDns', 'crossPlane', 'eksctl', 'gke', 'goog-dm', 'composer', 'airflow'];

const IacStatusFlag = ({
  state,
  iacType,
  handleOpenAnalysis,
  rowData = {},
  iacTab,
  handleOpenMulti,
  modal,
  dashboard,
  isLocked,
  hasControllerSources,
}) => {
  const { t } = useTranslation('inventory');
  const { provider = "", isBetaProvider = false } = rowData;

  let output = "";
  switch (state) {
    case RESOURCE_STATE_TYPES.unmanaged:
      output = (
        <div
          className={`IacStatusFlag unmanagedFlag row ${
            iacTab
              ? "iacTab"
              : modal
              ? "modal"
              : dashboard
              ? "dashboardFlag"
              : ""
          }`}
        >
          <FontAwesomeIcon icon="exclamation-circle" />
          <span>{capitalize(state)}</span>
        </div>
      );
      break;

    case RESOURCE_STATE_TYPES.managed:
      const isTerraformPartialSupport = IAC_TYPES.terraform === iacType && isBetaProvider;
      const isPendingCodified = (PENDING_CODIFIED_SUPPORT.includes(iacType) && !hasControllerSources) || isTerraformPartialSupport;
      const tooltipTitle = isPendingCodified ? t(`tooltips.pendingCodified${isTerraformPartialSupport ? capitalizeFirst(provider) : ''}`): "";
      output = (
        <Tooltip
          placement="top"
          title={tooltipTitle}
         >
          <div
            className={`IacStatusFlag managedFlag${isPendingCodified ? ' pending' : ''} row ${
              iacTab
                ? "iacTab"
                : modal
                ? "modal-managedFlag"
                : dashboard
                ? "dashboardFlag dashboardFlag-managedFlag"
                : ""
            }`}
          >
            {!isEmpty(iacType) && (
              <img
                src={greenIacProviders[iacType]}
                className="IacStatusFlag-icon"
                alt="IaC icon"
              />
            )}
            {dashboard && <FontAwesomeIcon icon="code" />}
            <span>Codified</span>
          </div>
        </Tooltip>
      );
      break;

    case RESOURCE_STATE_TYPES.modified:
      if (handleOpenAnalysis && (!isEmpty(rowData) || modal)) {
        output = isLocked ? (
          <FreeTierMask noBlur>
            <div
              className="row"
              style={{ gap: "10px", cursor: "pointer" }}
            >
              <FontAwesomeIcon
                icon="search"
                className="purple-text IacStatusFlag-analyzer"
              />
              <div
                className={`IacStatusFlag driftFlag row ${
                  iacTab ? "iacTab" : modal ? "modal" : ""
                }`}
              >
                {!isEmpty(iacType) ? (
                  <img
                    src={purpleIacProviders[iacType]}
                    className="IacStatusFlag-icon"
                    alt="IaC icon"
                  />
                ) : (
                  <FontAwesomeIcon icon="code-branch" />
                )}
                <span>Drifted</span>
              </div>
            </div>
          </FreeTierMask>
        ) : (
          <div
            className="row"
            style={{ gap: "10px", cursor: "pointer" }}
            onClick={(e) => {
              e.stopPropagation();
              if (isLocked) {
                return;
              }
              handleOpenAnalysis(rowData);
            }}
          >
            <FontAwesomeIcon
              icon="search"
              className="purple-text IacStatusFlag-analyzer"
            />
            <div
              className={`IacStatusFlag driftFlag row ${
                iacTab ? "iacTab" : modal ? "modal" : ""
              }`}
            >
              {!isEmpty(iacType) ? (
                <img
                  src={purpleIacProviders[iacType]}
                  className="IacStatusFlag-icon"
                  alt="IaC icon"
                />
              ) : (
                <FontAwesomeIcon icon="code-branch" />
              )}
              <span>Drifted</span>
            </div>
          </div>
        );
      } else {
        output = (
          <div
            className={`IacStatusFlag driftFlag row ${
              iacTab
                ? "iacTab"
                : modal
                ? "modal"
                : dashboard
                ? "dashboardFlag"
                : ""
            }`}
          >
            {!isEmpty(iacType) ? (
              <img
                src={purpleIacProviders[iacType]}
                className="IacStatusFlag-icon"
                alt="IaC icon"
              />
            ) : (
              <FontAwesomeIcon icon="code-branch" />
            )}
            <span>Drifted</span>
          </div>
        );
      }
      break;
    case RESOURCE_STATE_TYPES.ghost:
      output = (
        <div
          className={`IacStatusFlag ghostFlag row ${
            iacTab ? "iacTab" : modal ? "modal" : dashboard ? "dashboard" : ""
          }`}
          onClick={(e) => {
            e.stopPropagation();
            handleOpenMulti(rowData);
          }}
        >
          <FontAwesomeIcon icon="ghost" />
          <span>{capitalize(state)}</span>
        </div>
      );
      break;

      case RESOURCE_STATE_TYPES.deleted:
        output = (
          <div
            className={`IacStatusFlag deletedFlag row`}
            onClick={(e) => {
              e.stopPropagation();
            }}
          >
            <FontAwesomeIcon icon={["far", "trash-alt"]} />
            <span>{has(rowData, 'prevState') && !isEmpty(rowData?.prevState) ? formatIacStatus(rowData?.prevState) : capitalize(state)}</span>
          </div>
        );
      break;

    case RESOURCE_STATE_TYPES.pending:
      output = (
        <Tooltip
        placement="top"
        title={t('tooltips.pending')}
      >
          <div
            className={`IacStatusFlag row 
            ${rowData?.pendingTo === 'ghost' ? 'ghostPendingFlag' : ''} 
            ${rowData?.pendingTo === 'unmanaged' ? 'unmanagedPendingFlag' : ''} 
            ${iacTab ? "iacTab" : modal ? "modal" : dashboard ? "dashboard" : ""}`}
          >
            <FontAwesomeIcon icon="hourglass" />
            <span>{capitalize(rowData?.pendingTo) || 'Pending'}</span>
          </div>
        </Tooltip>
      );
      break;
    default:
      break;
  }
  return output;
};

export default React.memo(IacStatusFlag);
