import React, { useEffect } from "react";
import _ from "lodash";
import { useSelector } from "react-redux";

// components
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import SearchBox from "../../../shared/searchBox/searchBox";
import "./inventoryMiniTable.scss";

const InventoryMiniTable = ({
  searchValue,
  handlePageChange,
  handleTableSorterChange,
  tableData,
  fetchingData,
  totalObjects,
  columns,
  tablePageSize,
  customHeight,
  movePagination,
  searchWithBackground,
  onPageSizeChange,
  noCheckbox,
  disableSelectionOnClick,
  handleSelectedRows,
  rowKey
}) => {
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  useEffect(() => {
    handleSelectedRows([]);
  }, [searchVal]);

  return (
    <div className="basic-card InventoryMiniTable col">
      <div className="InventoryMiniTable__header row between">
        <SearchBox
          placeholder="Search"
          value={searchValue}
          resetSearch={searchValue}
          withBackground={searchWithBackground}
          width={350}
        />
      </div>
      <TableWrapper
        height={!_.isEmpty(customHeight) ? customHeight : "calc(100vh - 370px)"}
        rowKey={rowKey ? rowKey : "uniqueId"}
        tableData={tableData}
        columns={columns}
        loading={fetchingData}
        isCheckBox={!noCheckbox}
        pageSize={tablePageSize}
        serverSide
        rowCount={totalObjects}
        selectedRowsArr={handleSelectedRows}
        handlePageChange={handlePageChange}
        handleSortChange={handleTableSorterChange}
        movePagination={movePagination}
        onPageSizeChange={onPageSizeChange}
        disableSelectionOnClick={disableSelectionOnClick}
      />
    </div>
  );
};

export default InventoryMiniTable;
