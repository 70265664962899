import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Select, Checkbox } from "antd";
import _, { isEmpty, isEqual, map } from "lodash";
import { useTranslation } from "react-i18next";
import { getAllNotifications } from "../../../redux/actions/globalAppActions";
import WizardStepHeader from "../wizardStepHeader/wizardStepHeader";
import { integrationIcons } from "../../../utils/icons";
import { getAllSlackChannels, getPagerdutyServices } from "../../../redux/actions/integrationsActions";
import Loading from "../../../shared/loading/loading";
import { getNotificationByInsightId } from "../../../redux/actions/notificationsActions";
import "./insightNotification.scss";
import { getAllProfiles } from "../../../redux/actions/profilesActions";


const InsightNotification = ({ wizardState, updateWizard, isInsightEditMode }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  
  const { t } = useTranslation("insights", { keyPrefix: 'notifications'})
  
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);
  const [slackAppSelected, setSlackAppSelected] = useState(false);
  const [loadingSlackChannels, setLoadingSlackChannels] = useState(false);
  const [pdAdminIntegrationSelected, setPdAdminIntegrationSelected] = useState(false)
  const [loadingPdServices, setLoadingPdServices] = useState(false)
  const [loading, setLoading] = useState(true);
  const [emailAddressesLoading, setEmailAddressesLoading] = useState(false);
  const [didMount, setDidMount] = useState(false);
  const [initialValues, setInitialValues] = useState(null);


  const notificationsIntegrations = useSelector(
    (state) => state.globalAppReducer.notifications
  );
  const slackChannels = useSelector(
    (state) => state.integrationsReducer.channels
  );
  const pdServices = useSelector(
    (state) => state.integrationsReducer.pdServices
  );
  const users = useSelector((state) => state.profilesReducer.profiles);


  useEffect(() => {
    handleFetchNotificationIntegration();
  }, []);
  useEffect(() => {
    if(form.getFieldValue('notificationIntegration') == 'emailAddresses' && isEmpty(users)){
      handleFetchEmailAddresses()
    }
  }, [form.getFieldValue('notificationIntegration')]);

  useEffect(() => {
    (async () => {
      if (isInsightEditMode && !didMount) {
        if (wizardState.id && !isEmpty(notificationsIntegrations) && !initialValues) {
          await handleFetchInsightNotification();
          setDidMount(true);
          setLoading(false);
        }
        if(form.get == 'emailAddresses' && isEmpty(users)){
          handleFetchEmailAddresses();
        }
      } else {
        setLoading(false);
      }
    })()
  }, [wizardState, notificationsIntegrations])

  const handleFetchEmailAddresses = async () => {
    setEmailAddressesLoading(true);
    await dispatch(getAllProfiles());
    setEmailAddressesLoading(false);
  };

  const handleFetchInsightNotification = async () => {

    const notification = await dispatch(getNotificationByInsightId(wizardState.id));

    if (!isEmpty(notification) && !isEmpty(notification.notificationIntegrations)) {
      const values = {
        notificationId: notification.id,
        subscribed: true,
        notificationIntegration: notification.notificationIntegrations[0],
        ...(!isEmpty(notification.channels) && { channels: notification.channels }),
        ...((!isEmpty(notification.pd_service) && !notification.pd_service.includes(null)) && { pd_service: notification.pd_service }),
      }
      form.setFieldsValue(values);
      setInitialValues(values)
      updateWizard({
        ...values,
        notificationFormValid: checkFormValidation(values)
      })
      const isSlackApp = checkSlackAppSelected(notification.notificationIntegrations[0]);
      if (isSlackApp) {
        await fetchSlackChannels();
        setSlackAppSelected(true)
      }
    }
  }

  const handleFetchNotificationIntegration = async () => {
    setLoadingIntegrations(true);
    await dispatch(getAllNotifications());
    setLoadingIntegrations(false);
  };

  const renderIntegrations = () => {
    const arrays = _.values(notificationsIntegrations || {});
    const flatten = _.flatten(arrays);
    flatten.push({
      id:'emailAddresses',
      service:'email',
      name:'Email Address'
    });
    return _.map(flatten || [], (item) => {
      return (
        <Select.Option key={item?.id} value={item?.id}>
          <span className="InsightNotification__select row">
            <img
              src={integrationIcons[item?.service]}
              alt="integration icon"
              className={item?.service}
            />
            {item.name}
          </span>
        </Select.Option>
      );
    });
  };

  const fetchSlackChannels = async () => {
    if (!isEmpty(notificationsIntegrations.slack)) {
      const slackApp = notificationsIntegrations.slack?.find(
        (item) => item?.service === "slackApp"
      );

      if (!isEmpty(slackApp)) {
        setLoadingSlackChannels(true);
        await dispatch(getAllSlackChannels(slackApp?.id));
        setLoadingSlackChannels(false);
      }
    }
  };

  const renderSlackChannels = () => {
    if (!loadingSlackChannels && !isEmpty(slackChannels)) {
      return map(slackChannels || [], (channel) => (
        <Select.Option value={channel.id} key={channel.id}>
          <span>
            {`# `}
            {channel.name}
          </span>
        </Select.Option>
      ));
    }
  };
  
  const renderEmailAddresses = () => {
    if (!emailAddressesLoading && !_.isEmpty(users)) {
      return _.map(users || [], (user) => (
        <Select.Option value={user.email} key={user.user_id}>
          <span>{user.email}</span>
        </Select.Option>
      ));
    }
  };

  const fetchPdServices = async (id) => {
    setLoadingPdServices(true)
    await dispatch(getPagerdutyServices(id));
    setLoadingPdServices(false)
  }
  const renderPdServices = () => {
    if (!loadingPdServices && !isEmpty(pdServices)) {
      return map(pdServices || [], (service) => (
        <Select.Option value={service?.id} key={service?.id}>
          <span>
            {service?.name}
          </span>
        </Select.Option>
      ));
    }
  };

  const checkSlackAppSelected = selectedIntegration => !isEmpty(notificationsIntegrations.slack?.find(
    (item) =>
      item?.service === "slackApp" && selectedIntegration == item?.id
  ))
  const checkPdAdminSelected = selectedIntegration => !isEmpty(notificationsIntegrations.pagerduty?.find(
    (item) =>
      item?.service === "pagerduty" && isEmpty(item?.publicKey) && selectedIntegration == item?.id
  ))

  const handleDestinationChange = selectedIntegration => {
    if (!isEmpty(notificationsIntegrations.slack)) {
      const slackApp = checkSlackAppSelected(selectedIntegration);
      if (slackApp) {
        setSlackAppSelected(true);
        fetchSlackChannels();
      } else {
        setSlackAppSelected(false);
      }
    }
    if (!isEmpty(notificationsIntegrations.pagerduty)) {
      const pdSelected = checkPdAdminSelected(selectedIntegration)

      if (pdSelected) {
        fetchPdServices(selectedIntegration);
      }
      setPdAdminIntegrationSelected(pdSelected);
    }
  }

  const handleFormChange = async (allValues) => {
    const data = { ...allValues };
    
    if (data.subscribed){
      if(data.notificationIntegration != wizardState?.notification?.notificationIntegration){
        data.channels = []
        form.setFieldsValue({channels:[]})
      }
      data.notificationFormValid = checkFormValidation(data);
    }

    if (isInsightEditMode) {
      if (!isEmpty(initialValues)) {
        data.notificationId = initialValues.notificationId
        data.isUpdated = !isEqual(initialValues, allValues);
        if (!checkSlackAppSelected(data.notificationIntegration) &&  data.notificationIntegration != "emailAddresses")
          delete data.channels;
      } else {
        data.isCreated = data.subscribed;
      }
    }
    updateWizard(data);
  }

  const checkFormValidation = (values) => {
    let isValid = true;
    const { notificationIntegration, channels, pd_service } = values;
    if (isEmpty(notificationIntegration)) isValid = false
    else if (checkSlackAppSelected(notificationIntegration) && isEmpty(channels)) isValid = false;
    else if (notificationIntegration == 'emailAddresses' && isEmpty(channels)) isValid = false;
    else if (checkPdAdminSelected(notificationIntegration) && !pd_service) isValid = false;
    return isValid;
  }

  return (
    <div className="InsightNotification basic-card col g15">
      <WizardStepHeader type="notification" />
      {loading ?
        <Loading /> : (
          <Form
            name="notify-insight"
            form={form}
            layout="inline"
            onValuesChange={(changedValues, allValues) => {
              handleFormChange(allValues)
            }}
            style={{ marginTop: "10px" }}
          >
            <Form.Item
              name="subscribed"
              valuePropName="checked"
              style={{
                marginBottom: "15px",
                flexDirection: "column",
              }}
            >
              <Checkbox>
                <span className="WebhookCreation__checbox">
                  {t("placeholder")}
                </span>
              </Checkbox>
            </Form.Item>

            <Form.Item

              name="notificationIntegration"
              style={{ marginBottom: ".8rem" }}
              rules={[
                {
                  required: true,
                  message: "selecting alert destination is required",
                },
              ]}

            >
              <Select
                disabled={!form.getFieldValue('subscribed')}
                placeholder="Select Destination"
                loading={loadingIntegrations}
                style={{
                  minWidth: "350px",
                }}
                onChange={handleDestinationChange}
                showArrow
                showSearch
                filterOption={(input, option) =>
                  option?.children?.props?.children[1]
                    .toLowerCase()
                    .indexOf(input.toLowerCase()) >= 0
                }
              >
                {renderIntegrations()}
              </Select>
            </Form.Item>
            {slackAppSelected && (
              <Form.Item
                name="channels"
                rules={[
                  {
                    required: true,
                    message: "selecting a channel is required",
                  },
                ]}
                style={{ marginBottom: "1.2rem", flexDirection: "column" }}
              >
                <Select
                  maxTagCount={2}
                  disabled={!form.getFieldValue('subscribed')}
                  placeholder="Select channels*"
                  loading={loadingSlackChannels}
                  mode="multiple"
                  showArrow
                  showSearch
                  filterOption={(input, option) =>
                    option?.children?.props?.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderSlackChannels()}
                </Select>
              </Form.Item>
            )}
            {pdAdminIntegrationSelected && (
              <Form.Item
                name="pd_service"
                rules={[
                  {
                    required: true,
                    message: "selecting a service is required",
                  },
                ]}
                style={{ marginBottom: "1.2rem", flexDirection: "column" }}
              >
                <Select
                  maxTagCount={2}
                  disabled={!form.getFieldValue('subscribed')}
                  placeholder="Select Pagerduty Service"
                  loading={loadingPdServices}
                  showArrow
                  showSearch
                  filterOption={(input, option) =>
                    option?.children?.props?.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderPdServices()}
                </Select>
              </Form.Item>
            )}
            {form.getFieldValue('notificationIntegration') == 'emailAddresses' && (
              <Form.Item
                name="channels"
                rules={[
                  {
                    required: true,
                    message: "Selecting an email address is required",
                  },
                ]}
                style={{ marginBottom: "1.2rem", flexDirection: "column" }}
              >
                <Select
                  maxTagCount={2}
                  disabled={emailAddressesLoading}
                  placeholder="Select email addresses*"
                  loading={emailAddressesLoading}
                  mode="multiple"
                  showArrow
                  showSearch
                  filterOption={(input, option) =>
                    option?.children?.props?.children[1]
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {renderEmailAddresses()}
                </Select>
              </Form.Item>
            )}
          </Form>
        )}
    </div>
  );
};

export default InsightNotification;
