import React, { useState } from "react";
import AppModal from "../../../shared/appModal/appModal";
import { CopyToClipboard } from "react-copy-to-clipboard";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faCopy } from "@fortawesome/free-solid-svg-icons";
import { useTranslation } from "react-i18next";

const CreateAccessKey = ({ visible, handleClose, keyPair }) => {
  const { t } = useTranslation('users')
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isAccessKeyCopied, setIsAccessKeyCopied] = useState(false);
  const [isSecretKeyCopied, setIsSecretKeyCopied] = useState(false);

  const onCopyAccessKey = () => {
    setIsAccessKeyCopied(true);
    setTimeout(() => {
      setIsAccessKeyCopied(false);
    }, 1500);
  };
  const onCopySecretKey = () => {
    setIsSecretKeyCopied(true);
    setTimeout(() => {
      setIsSecretKeyCopied(false);
    }, 1500);
  };
  const handleCloseModal = () => {
    setLoadingSubmit(false);
    handleClose();
  };
  return (
    <AppModal
      visible={visible}
      handleOnSubmit={handleCloseModal}
      handleClose={handleCloseModal}
      title={t('access-keys.create-modal.title')}
      loadingSubmit={loadingSubmit}
      submitBtnText={t('access-keys.create-modal.close-btn')}
      hideCloseBtn
      bodyClassName="CreateAccessKey col"
    >
      <div className="CreateAccessKey__intro col">
        <span>
          {t('access-keys.create-modal.sub-title1')}
        </span>
        <span className="bold">
          {t('access-keys.create-modal.sub-title2')}
        </span>
      </div>

      <div className="CreateAccessKey__keys col">
        <div className="CreateAccessKey__keys-item col">
          <label>{t('access-keys.create-modal.form.access-key')}</label>
          <div className="CreateAccessKey__keys-item-key row between">
            <span className="CreateAccessKey__keys-item-key-text">
              {"" || keyPair?.accessKey}
            </span>
            <CopyToClipboard text={keyPair?.accessKey} onCopy={onCopyAccessKey}>
              {isAccessKeyCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="CreateAccessKey__keys-item-key-icon active"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className="CreateAccessKey__keys-item-key-icon"
                />
              )}
            </CopyToClipboard>
          </div>
        </div>

        <div className="CreateAccessKey__keys-item col">
          <label>{t('access-keys.create-modal.form.secret-key')}</label>
          <div className="CreateAccessKey__keys-item-key row between">
            <span className="CreateAccessKey__keys-item-key-text">
              {"" || keyPair?.secretKey}
            </span>
            <CopyToClipboard text={keyPair?.secretKey} onCopy={onCopySecretKey}>
              {isSecretKeyCopied ? (
                <FontAwesomeIcon
                  icon={faCheck}
                  className="CreateAccessKey__keys-item-key-icon active"
                />
              ) : (
                <FontAwesomeIcon
                  icon={faCopy}
                  className="CreateAccessKey__keys-item-key-icon"
                />
              )}
            </CopyToClipboard>
          </div>
        </div>
      </div>
    </AppModal>
  );
};

export default CreateAccessKey;
