  import {
  CREATE_AKAMAI_INTEGRATION,
  DELETE_AKAMAI_INTEGRATION,
  EDIT_AKAMAI_INTEGRATION,
  GET_AKAMAI_INTEGRATIONS
} from "../actions/akamaiIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_AKAMAI_INTEGRATIONS:
      return { ...state, integrations: payload.integrations };

    case CREATE_AKAMAI_INTEGRATION:
      return { ...state };

    case DELETE_AKAMAI_INTEGRATION:
      return { ...state };

    case EDIT_AKAMAI_INTEGRATION:
      return {...state}

    default:
      return state;
  }
}
