import { clouds, iacProviders, integrationIcons } from "../../../utils/icons";

export default [
  {
    name: "Terraform",
    prefix: "generate terraform template for",
    icon: clouds("terraform", true),
  },
  {
    name: "CloudFormation",
    prefix: "generate cloudformation template for",
    icon: iacProviders("cloudFormation", true),
  },
  {
    name: "AWS CDK",
    icon: integrationIcons["cdk"],
    languages: [
      { name: "TypeScript", prefix: "generate cdk typescript that" },
      { name: "Go", prefix: "generate cdk go that" },
      { name: "Python", prefix: "generate cdk python that" },
      { name: "Java", prefix: "generate cdk java that" },
      { name: "C#", prefix: "generate cdk c# that" },
    ],
  },
  {
    name: "Pulumi",
    icon: iacProviders("pulumi", true),
    languages: [
      { name: "TypeScript", prefix: "generate pulumi typescript that" },
      { name: "Go", prefix: "generate pulumi go that" },
      { name: ".NET", prefix: "generate pulumi .net that" },
      { name: "Java", prefix: "generate pulumi java that" },
      { name: "YAML", prefix: "generate pulumi yaml that" },
    ],
  },
  {
    name: "Crossplane",
    prefix: "generate crossplane composite resource for",
    icon: integrationIcons["crossplane"],
  },
  {
    name: "Bicep",
    prefix: "generate bicep template for",
    icon: integrationIcons["bicep"],
  },
  {
    name: "Ansible",
    prefix: "generate ansible template for",
    icon: integrationIcons["ansible"],
  },
  {
    name: "Bash",
    prefix: "generate shell script that",
    icon: integrationIcons["bash"],
  },
  {
    name: "Helm Chart",
    prefix: "generate helm chart for",
    icon: iacProviders("helm", true),
  },
  {
    name: "Kubernetes",
    prefix: "generate k8s manifest for",
    icon: clouds("kubernetes", true),
  },
  {
    name: "Docker",
    prefix: "generate dockerfile that build",
    icon: integrationIcons["docker"],
  },
];
