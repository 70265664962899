import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import {
  setSelectedServiceView,
} from "../../../redux/actions/inventoryv3Actions";
import ServiceListItem from "../servicesList/serviceListItem";
import './inventoryServiceWrapper.scss';
import ServicesList from "../servicesList/servicesList";

const InventoryServiceWrapper = ({ loading, children }) => {
  const dispatch = useDispatch();
  const selectedService = useSelector(
    (state) => state.inventoryReducer.selectedService
  );
  const inventoryFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const inventory_mode = useSelector(
    (state) => state.userPreferencesReducer.inventory_mode
  );

  useEffect(() => {
    checkInventoryFiltersStatus()
  },[inventoryFilters, selectedService])

  // adjust services list behavior according to asset types filter
  const checkInventoryFiltersStatus = () => {
    if(_.isEmpty(inventoryFilters?.types) && !_.isEmpty(selectedService)) {
      return setSelectedServiceView(null)
    }
    if(!_.isEmpty(selectedService) && !_.isEmpty(inventoryFilters?.types) && !inventoryFilters?.types?.includes(selectedService?.key)) {
      return setSelectedServiceView(null)
    }
  }

  if(inventory_mode === "assets") {
    return children;
  }

  if(inventory_mode === "types" && _.isEmpty(selectedService)) {
    return <ServicesList loading={loading} />
  }

  return (
    <div className={`InventoryServiceWrapper ${!_.isEmpty(selectedService) ? 'active' : ''}`}>
      {!_.isEmpty(selectedService) && (
        <ServiceListItem
          name={selectedService?.key}
          count={selectedService?.doc_count}
          iacCoverage={selectedService?.asset_type_data?.buckets}
          onClear={() => {
            Promise.all([
              dispatch(setSelectedServiceView(null)),
            ]);
          }}
        />
      )}
      {children}
    </div>
  );
};

export default InventoryServiceWrapper;
