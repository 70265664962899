import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Tooltip } from "antd";
import { getIconMutedColor } from "../../utils/helpers";
import "./relationshipLabelIndicator.scss";

const RelationshipLabelIndicator = ({
  relationshipDetected,
  handleOpenConnectionTab,
}) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  return (
    <Tooltip
      placement="top"
      title="Asset relationsip"
      style={{ cursor: "pointer" }}
      overlayInnerStyle={{
        fontSize: "13px",
      }}
    >
      <button
        className="RelationshipLabelIndicator center"
        onClick={
          !relationshipDetected
            ? () => {
                return false;
              }
            : handleOpenConnectionTab
        }
        style={{
          cursor: !relationshipDetected ? "default" : "pointer",
        }}
      >
        <svg
          width="13"
          height="13"
          viewBox="0 0 16 16"
          fill="none"
          xmlns="http://www.w3.org/2000/svg"
        >
          <path
            d="M6 3.49902C6 4.02946 6.21071 4.53816 6.58579 4.91324C6.96086 5.28831 7.46957 5.49902 8 5.49902C8.53043 5.49902 9.03914 5.28831 9.41421 4.91324C9.78929 4.53816 10 4.02946 10 3.49902C10 2.96859 9.78929 2.45988 9.41421 2.08481C9.03914 1.70974 8.53043 1.49902 8 1.49902C7.46957 1.49902 6.96086 1.70974 6.58579 2.08481C6.21071 2.45988 6 2.96859 6 3.49902V3.49902Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
          <path
            d="M0.5 12.499C0.5 13.0295 0.710714 13.5382 1.08579 13.9132C1.46086 14.2883 1.96957 14.499 2.5 14.499C3.03043 14.499 3.53914 14.2883 3.91421 13.9132C4.28929 13.5382 4.5 13.0295 4.5 12.499C4.5 11.9686 4.28929 11.4599 3.91421 11.0848C3.53914 10.7097 3.03043 10.499 2.5 10.499C1.96957 10.499 1.46086 10.7097 1.08579 11.0848C0.710714 11.4599 0.5 11.9686 0.5 12.499V12.499Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
          <path
            d="M11.5 12.499C11.5 13.0295 11.7107 13.5382 12.0858 13.9132C12.4609 14.2883 12.9696 14.499 13.5 14.499C14.0304 14.499 14.5391 14.2883 14.9142 13.9132C15.2893 13.5382 15.5 13.0295 15.5 12.499C15.5 11.9686 15.2893 11.4599 14.9142 11.0848C14.5391 10.7097 14.0304 10.499 13.5 10.499C12.9696 10.499 12.4609 10.7097 12.0858 11.0848C11.7107 11.4599 11.5 11.9686 11.5 12.499V12.499Z"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
          <path
            d="M4.5 12.499H11.5"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
          <path
            d="M9.10547 5.16504L12.6268 10.6984"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
          <path
            d="M6.89438 5.16504L3.37305 10.6984"
            strokeLinecap="round"
            strokeLinejoin="round"
            stroke={
              !relationshipDetected ? getIconMutedColor(themeDark) : "#F257BB"
            }
          />
        </svg>
      </button>
    </Tooltip>
  );
};

export default RelationshipLabelIndicator;
