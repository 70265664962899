import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBtn from "../../../shared/appBtn/appBtn";
import MissingGitIllustration from '../../../Images/illustrations/git-missing-intergration.svg'
import "./emptyIac.scss";

const EmptyIac = ({title = "", subtitle = "", scrollTo = "" }) => {
  const history = useHistory();
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const handleIntegrationClick = () => {
    return history.push({
      pathname: "/integrations/new-integration",
      state: { scrollTo }
    })
  };

  return (
    <div className="EmptyIac col center">
      <img src={MissingGitIllustration} alt="empty environments" />
      <div className="EmptyIac__text col center">
        <span className="title">{title}</span>

        <div className="EmptyIac__text-action center col">
          <span className="lighterText">
            {subtitle}
          </span>
          <AppBtn
            text="Click to Integrate"
            onClick={handleIntegrationClick}
            disabled={isViewer}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyIac;
