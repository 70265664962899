import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { v4 as uuidv4 } from "uuid";
import { useHistory } from "react-router-dom";
import NewAppModal from "../../../shared/newAppModal/newAppModal";
import { ReactComponent as CiPipelineIcon } from '../../../Images/general_icons/sidebar/ci-pipeline.svg';
import AppWizard from "../../../shared/appWizard/appWizard";
import WorkflowManagementFooter from "./workflowManagementFooter";
import GeneralWorkflowConfiguration from "./workflowsSteps/generalConfiguration";
import TerraformWorkflowConfiguration from "./workflowsSteps/tfConfiguration";
import AdvancedWorkflowConfiguration from "./workflowsSteps/advancedConfiguration";
import { deleteMockArrays } from "./mangementHelper";
import SuccessBox from "../../../shared/successBox/successBox";

const NEW_ELEMENT = { name: "", value: "", key: uuidv4() };
const WIZARD_CREATE_MOCK = { ciTool: "github-action", tfVariables: [NEW_ELEMENT], tfSensitiveLabels: [NEW_ELEMENT], providersCredentials: [NEW_ELEMENT], awsProfiles: [NEW_ELEMENT] };

import './workflowManagement.scss';

const WorkflowManagement = ({ isEditMode = false, modalVisible, setModalVisible, workflowName="workflow" }) => {
    const { t } = useTranslation("ci-pipeline", { keyPrefix: "workflowManagement" });
    const history = useHistory();
    const [wizardState, setWizardState] = useState(isEditMode ? {} : WIZARD_CREATE_MOCK);
    const [currentStep, setCurrentStep] = useState(0);
    const [submitDisabled, setSubmitDisabled] = useState(false);
    const [loadingSubmit, setLoadingSubmit] = useState(false);
    
    const renderModalTitle = () => {
        return (
            <div className="row g10">
                <CiPipelineIcon className="WorkflowManagement__header-icon"/>
                <span className="bold">{t("title")}</span>
            </div>)
    }
    const configWizard = [
        {
            step_title: t("general.title"),
            step_description: t("general.description"),
            content: (<GeneralWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
            beforeNextStep: () => null
        },
        {
            step_title: t("terraform.title"),
            step_description: t("terraform.description"),
            content: (<TerraformWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
            beforeNextStep: () => null
        },
        {
            step_title: t("advanced.title"),
            step_description: t("advanced.description"),
            content: (<AdvancedWorkflowConfiguration wizardState={wizardState} setWizardState={setWizardState} setSubmitDisabled={setSubmitDisabled} isEditMode={isEditMode}/>),
            beforeNextStep: () => {
                const clearWizard = deleteMockArrays(wizardState, ["tfVariables", "tfSensitiveLabels", "providersCredentials", "awsProfiles"]);
            }
        },
        {
            step_title: t("completion.title"),
            step_description: t("completion.description"),
            content: (
            <SuccessBox
                setSubmitDisabled={setSubmitDisabled}
                title={t("completion.successTitle")}
                subTitle={isEditMode ? 'Workflow has been edited successfully' : `A new workflow has been created successfully`}
          />)
        }
    ];

    return (
            <NewAppModal
                visible={modalVisible}
                handleClose={() => setModalVisible(false)}
                destroyOnClose
                centered
                width="85vw"
                bodyClassName="WorkflowManagement__content"
                customFooterClassName="WorkflowManagement__footer"
                title={renderModalTitle()}
                subtitle={<span className="WorkflowManagement__subtitle">{isEditMode ? `Edit ${workflowName} configuration`: t("addSubtitle")}</span>}
                iconSrc={null}
                footer={
                    <WorkflowManagementFooter 
                        loadingSubmit={loadingSubmit} 
                        submitDisabled={submitDisabled} 
                        configWizard={configWizard} 
                        currentStep={currentStep}
                        setCurrentStep={setCurrentStep}
                        setLoadingSubmit={setLoadingSubmit}
                        setSubmitDisabled={setSubmitDisabled}
                        handleClose={() => setModalVisible(false)}
                        handleSubmit={() => {
                            setModalVisible(false);
                            history.push("/workflows")
                        }}
                        isEditMode={isEditMode}
                    />
                }
                headerSuffix={null}
                >
                <div className="WorkflowManagement"> 
                <AppWizard
                    iconSrc={null}
                    loadingSubmit={loadingSubmit}
                    configWizard={configWizard}
                    handleClose={() => setModalVisible(false)}
                    submitDisabled={submitDisabled}
                    setSubmitDisabled={setSubmitDisabled}
                    handleSubmit={() => history.push("/workflows")}
                    customCurrent={currentStep}
                />
                </div>
         </NewAppModal>

    )
};

export default WorkflowManagement;