import React, { useState, useEffect } from "react";
import _ from "lodash";
import "./vulnerabilityLine.scss";

const mockData = [
  { name: "unknown", color: "#562AB2", value: 0 },
  { name: "low", color: "#5246B9", value: 0 },
  { name: "medium", color: "#4E64C0", value: 0 },
  { name: "high", color: "#498BC8", value: 0 },
  { name: "critical", color: "#3FD3D8", value: 0 },
];
const VulnerabilityLine = ({ data, onClick, customStyle, isCiTable = false }) => {
  const [chartData, setChartData] = useState(mockData);

  useEffect(() => {
    arrangeChartData();
  }, [data]);

  //   set the vulnerabilities values base on the data passed
  const arrangeChartData = () => {
    if (data) {
      const dataToMap = isCiTable ? mockData.slice(1, 4) : chartData;
      const newChartData = _.map(dataToMap || [], (item = {}) => {
        const name = isCiTable? item.name?.toUpperCase() : item?.name;
        return { ...item, value: data?.[name] || 0 };
      });
      return setChartData(newChartData);
    }
  };

  const renderChart = () => {
    // sort and arrange the data
    if (!_.isEmpty(chartData)) {
      return _.map(chartData || [], (item) => {
        return (
          <div
            key={item?.name}
            style={{ backgroundColor: item?.color }}
            className={`VulnerabilityLine__block ${item?.name} center`}
          >
            {item?.value}
          </div>
        );
      });
    }
  };

  // if no data was passed to the component
  if (!data || _.isEmpty(data)) {
    return null;
  }

  return (
    <div
      className="VulnerabilityLine row"
      onClick={onClick ? onClick : null}
      style={customStyle ? customStyle : null}
    >
      <span className="VulnerabilityLine__arrow-left" />
      {renderChart()}
      <span className="VulnerabilityLine__arrow-right" />
    </div>
  );
};

export default VulnerabilityLine;
