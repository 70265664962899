import React from "react";
import { Tag } from "antd";

const FilterTag = ({ value, onClose }) => {
  return (
    <Tag
      closable
      onClose={onClose}
      className="filter-tag"
    >
      {value}
    </Tag>
  );
};

export default FilterTag;
