import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { Checkbox, Form, Input, Select } from "antd";
import _ from "lodash";
import { getAllKeyPairs } from "../../../../redux/actions/keyPairsActions";
import MarkAsProduction from "../../../../shared/markAsProduction/markAsProduction";

const AwsIntegrationConfiguration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
  isProd,
  handelSetIsProd,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const [checked, setChecked] = useState(false);
  const [loadingAccessKeys, setLoadingAccessKeys] = useState(false);
  const [useExistingKey, setUseExistingKey] = useState(false);

  const keyPairs = useSelector((state) => state.keyPairsReducer.keyPairs);

  useEffect(() => {
    setSubmitDisabled(false);
    setWizardState({
      ...wizardState,
      ...{
        name: "AWS",
        accessKeyType: "new",
        selectedKey: null,
      },
    });
    fetchAccessKeys();
  }, []);

  const fetchAccessKeys = async () => {
    if (_.isEmpty(keyPairs)) {
      setLoadingAccessKeys(true);
      await dispatch(getAllKeyPairs());
      setLoadingAccessKeys(false);
    }
  };

  const onSelectKeyType = (type) => {
    setUseExistingKey(type !== "new");
  };

  const renderAccessKeys = () => {
    return _.map(keyPairs || [], (key) => (
      <Select.Option value={key?.access_key} key={uuidv4()}>
        {_.upperCase(key?.access_key)}
      </Select.Option>
    ));
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name } = formValues;

    if (_.isEmpty(name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="AwsIntegrationConfiguration">
      <Form
        {...layout}
        name="aws-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "AWS",
          accessKeyType: wizardState.accessKeyType || "new",
          selectedKey: wizardState?.selectedKey || null,
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          allValues.name = allValues.name || "AWS";
          setWizardState({
            ...wizardState,
            ...allValues,
            ...{ fetchSecrets: checked },
          });
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Integration Nickname"
          name="name"
          tooltip="AWS Account Nickname"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Please enter AWS Account Nickname" />
        </Form.Item>

        <Form.Item
          name="accessKeyType"
          label="AccessKey"
          tooltip="Selecting an existing access key will require you to use a secret key that was generated previously"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Please select an option"
            onChange={onSelectKeyType}
          >
            <Select.Option value="new" key="new">
              Create a New AccessKey
            </Select.Option>
            <Select.Option value="existing" key="existing">
              Use Existing AccessKey
            </Select.Option>
          </Select>
        </Form.Item>

        {useExistingKey && (
          <Form.Item
            name="selectedKey"
            label="AccessKey selection"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Select
              placeholder="AccessKey selection"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              loading={loadingAccessKeys}
            >
              {renderAccessKeys()}
            </Select>
          </Form.Item>
        )}
      </Form>
      <div className="secrets-selection" style={{ marginTop: "25px" }}>
      <Checkbox checked={isProd} onChange={handelSetIsProd}>
        <MarkAsProduction />
        </Checkbox>
      </div>
    </div>
  );
};

export default AwsIntegrationConfiguration;
