import React, { useState, useEffect } from "react";
import { Collapse } from "antd";
import { useDispatch } from "react-redux";
import _ from "lodash";
import { getInvAssets } from "../../../redux/actions/classificationsActions";
// components
import InventoryMiniTable from "../../inventory/inventoryMiniTable/inventoryMiniTable";
import "./regoAccordionsBlock.scss";
import CodeBlock from "../../../shared/codeBlock/codeBlock";
import { useTranslation } from "react-i18next";

const RegoAccordionsBlock = ({ wizardState, readOnly }) => {
  const { Panel } = Collapse;
  const dispatch = useDispatch();

  const { t } = useTranslation("insights", { keyPrefix: "regoPlayground" });

  const [activePanel, setActivePanel] = useState([]);
  const [selectedAsset, setSelectedAsset] = useState('');
  const [selectedRows, setSelectedRows] = useState([]);
  const [loadingTable, setLoadingTable] = useState(false);
  const [invAssets, setInvAssets] = useState([]);
  const [invTotal, setInvTotal] = useState(0);
  const [pageSize, setPageSize] = useState(50);
  const [searchVal, setSearchVal] = useState(null);
  const [page, setPage] = useState(1);

  useEffect(() => {
    handleFetchInv();
  }, [page, searchVal, pageSize, wizardState?.scope, wizardState?.providers]);

  const handleFetchInv = async () => {
    setLoadingTable(true);
    const providers = (wizardState?.providers || []).map((provider) => provider === 'google_all' ? 'gcp_all' : provider)
    const req = await dispatch(
      getInvAssets(
        providers,
        wizardState?.scope,
        searchVal,
        pageSize,
        page
      )
    );
    setInvTotal(req?.total || 0);
    setInvAssets(req?.hits || []);
    setLoadingTable(false);
  };

  const renderTestResults = () => {
    if (!_.isEmpty(wizardState?.test_results)) {
      return _.map(wizardState?.test_results, (item) => {
        return (
          <div key={item?.id} className="RegoAccordionsBlock__results-item">
            {item?.arn}
          </div>
        );
      });
    }
    return (
      <div className="RegoAccordionsBlock__results-empty center centered col g10">
        <span>
          {`${t("helper")} `}
          <span className="bold">Evaluate</span>.
        </span>
      </div>
    );
  };

  if (readOnly) {
    return (
      <div className="RegoAccordionsBlock">
        <div className="RegoAccordionsBlock__results">
          {renderTestResults()}
        </div>
      </div>
    );
  }

  return (
    <div className="RegoAccordionsBlock">
      <Collapse
        expandIconPosition="right"
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel header="SELECT ASSET" key="1">
          <div className="RegoAccordionsBlock__table">
            <InventoryMiniTable
              rowKey="uniqueId"
              handleSelectedRows={(items) => {
                setSelectedRows(items);
                const jsonItem = JSON.stringify(
                  JSON.parse(_.last(items || [])?.tfObject || null),
                  null,
                  2
                );
                setSelectedAsset(_.isString(jsonItem) ? jsonItem : "");
              }}
              selectedRowsArr={selectedRows}
              clearSelected={() => {
                setSelectedRows([]);
                setSelectedAsset({});
              }}
              searchValue={(val) => {
                setSearchVal(val);
                setPage(1);
              }}
              tableData={invAssets}
              fetchingData={loadingTable}
              totalObjects={invTotal}
              handlePageChange={setPage}
              columns={[
                {
                  headerName: "Name",
                  field: "assetId",
                  flex: 1,
                  filterable: false,
                  sortable: false,
                  resizable: false,
                  disableColumnMenu: true,
                },
              ]}
              customHeight={"270px"}
              noCheckbox
              pageSize={pageSize}
              onPageSizeChange={setPageSize}
            />
          </div>
        </Panel>
        <Panel header="INPUT SCHEMA" key="2">
          {!_.isEmpty(selectedAsset) && _.isString(selectedAsset) ? (
            <CodeBlock
              editable={false}
              height="270px"
              code={selectedAsset || ""}
              lang="json"
            />
          ) : (
            <div className="RegoAccordionsBlock__emptyjson center centered">Select asset to view it's input schema</div>
          )}
        </Panel>
        <Panel
          header={`MATCHING RESULTS ${
            !_.isEmpty(wizardState?.test_results)
              ? "(" + wizardState?.test_results?.length + ")"
              : ""
          }`}
          key="3"
        >
          <div className="RegoAccordionsBlock__results">
            {renderTestResults()}
          </div>
        </Panel>
      </Collapse>
    </div>
  );
};

export default RegoAccordionsBlock;
