import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import { inventoryEvents } from "../../utils/amplitudeEvents";
import { sendEvent } from "../../utils/amplitude";

export const GET_ASSET_COMMENTS = "GET_ASSET_COMMENTS";
export const CREATE_COMMNENT = "CREATE_COMMNENT";
export const UPDATE_COMMNENT = "UPDATE_COMMNENT";
export const DELETE_COMMENT = "DELETE_COMMENT";

const getAssetComments = (integrationId, frn) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/comments/asset`,
      "POST",
      { integrationId, frn }
    );
    let data = [];

    if (req.ok) {
      data = await req.json();
    }

    dispatch({
      type: GET_ASSET_COMMENTS,
      payload: data,
    });
    return data;
  });
};

const createComment = (integrationId, frn, assetType, comment, userId, userName, userEmail) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/comments`,
      "POST",
      { integrationId, frn, assetType, comment, userId, userName, userEmail }
    );
    let data = {};

    if (req.ok) {
      data = await req.json();
    }
    // product event
    sendEvent(inventoryEvents.commentActions, { action: "User created a comment" } )

    dispatch({
      type: CREATE_COMMNENT,
      payload: data,
    });
    return;
  });
};

const deleteComment = (id, integrationId, frn, assetType) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/comments/delete`,
      "POST",
      {id, integrationId, frn, assetType}
    );
    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    // product event
    sendEvent(inventoryEvents.commentActions, { action: "User deleted a comment" } )

    dispatch({
      type: DELETE_COMMENT,
      payload: data,
    });
    return;
  });
};

const updateComment = (id, comment) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/comments/${id}`,
      "PUT",
      {comment}
    );
    let data = {};

    if (req.ok) {
      data = await req.json();
    }

    // product event
    sendEvent(inventoryEvents.commentActions, { action: "User edited comment" } )

    dispatch({
      type: UPDATE_COMMNENT,
      payload: data,
    });
    return;
  });
};

export {
  getAssetComments,
  createComment,
  deleteComment,
  updateComment
};
