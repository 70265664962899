import {
  GET_PULUMI_INTEGRATIONS,
  CREATE_PULUMI_INTEGRATION,
  EDIT_PULUMI_INTEGRATION,
  DELETE_PULUMI_INTEGRATION,
  GET_PULUMI_STACKS,
} from "../actions/pulumiIntegrationActions";

const initialState = {
  integrations: [],
  stacks: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case CREATE_PULUMI_INTEGRATION:
      return { ...state };

    case GET_PULUMI_INTEGRATIONS:
      return { ...state, integrations: payload?.result };

    case EDIT_PULUMI_INTEGRATION:
      return { ...state };

    case DELETE_PULUMI_INTEGRATION:
      return { ...state };

    case GET_PULUMI_STACKS:
      return { ...state, stacks: payload?.result };
    default:
      return state;
  }
}
