import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { getAssetComments } from "../../../../redux/actions/commentsActions";
import _ from "lodash";
import SingleComment from "./singleComment";
import Loading from "../../../../shared/loading/loading";

import "./assetComments.scss";

const AssetComments = ({ asset, setNewCount }) => {
  const dispatch = useDispatch();
  const [reLoadingCommets, setReLoadingCommets] = useState(true);
  const [commentsArr, setCommentsArr] = useState([]);

  useEffect(() => {
    handleFetchComment();
  }, [asset]);

  useEffect(() => {
    if (asset?.comments !== commentsArr?.length && !reLoadingCommets) {
      setNewCount(commentsArr?.length);
    }
  }, [commentsArr, reLoadingCommets]);

  const handleFetchComment = async () => {
    setReLoadingCommets(true);
    if (!_.isEmpty(asset)) {
      const req = await dispatch(
        getAssetComments(asset?.integrationId, asset?.frn)
      );
      setCommentsArr(req);
    }
    setReLoadingCommets(false);
  };

  const renderCommentsList = () => {
    if (!_.isEmpty(commentsArr)) {
      return _.map(commentsArr, (item) => (
        <div className="AssetComments__item" key={item?.id}>
          <SingleComment
            data={item}
            asset={asset}
            refresh={handleFetchComment}
          />
        </div>
      ));
    }
  };
  if (reLoadingCommets) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="AssetComments col">
      <div className="AssetComments__item">
        <SingleComment newComment asset={asset} refresh={handleFetchComment} />
      </div>
      {renderCommentsList()}
    </div>
  );
};

export default AssetComments;
