import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { useLocation } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";

import {
  getFireflyAggregations,
  getBaseAggregations,
  getInventoryHits,
  setFilters,
  getFlagsAggregations,
  getDeletedAggregations,
  getMasterTypesAggregations
} from "../../redux/actions/inventoryv3Actions";
import { getClassifications } from "../../redux/actions/classificationsActions";
import { getExclusions } from "../../redux/actions/driftsExclusionsActions";
import { getJiraIntegrations } from "../../redux/actions/jiraIntegrationActions";
import {
  getAllClouds,
  setSearchVal,
  getAllVcs
} from "../../redux/actions/globalAppActions";
import {
  getUserScreenViews,
  setActiveScreenView,
  createView,
  toggleViewModal,
} from "../../redux/actions/screenViewActions";
import { getNameById, handleCopy, stringToBoolean } from "../../utils/helpers";
import { getViewById } from "../../redux/actions/screenViewActions";
import { appToast } from "../../shared/appToast/appToast";
import {
  formatAwsStringWithUnderscore,
  capitalizeFirst,
  providerNameFormatter,
  formatDeletedFilter,
  formatTitleByIacType,
} from "../../utils/formatting";
import { iacProviders } from "../../utils/icons";
import { updateTabelPageSize } from "../../redux/actions/userPreferencesActions";

// components
import InventoryCharts from "../../components/inventory/inventoryCharts/inventoryCharts";
import InventoryTableWrapper from "../../components/inventory/inventoryTableWrapper/inventoryTableWrapper";
import Loading from "../../shared/loading/loading";
import EmptyInventory from "../../components/inventory/emptyInventory/emptyInventory";
import InventoryFilters from "../../components/inventory/inventoryFilters/inventoryFilters";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import AppTag from "../../shared/appTag/appTag";
import OwnerIndication from "../../components/inventory/ownerIndication/ownerIndication";
import "./inventory.scss";
import { IAC_TYPES } from "../../consts/general";

const Inventory = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const { search } = location;
  const { user } = useAuth0();

  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [filterPage, setFilterPage] = useState(1);
  const [resetFilters, setResetFilters] = useState(false);
  const [resetClouds, setResetClouds] = useState(false);
  const [fetchingView, setFetchingView] = useState(true);
  const [tableSorting, setTableSorting] = useState([]);
  const [loadingShareTempView, setLoadingShareTempView] = useState(false);
  const [moduleFromUrl, setModuleFromUrl] = useState(null);
  const [selectedRows, setSelectedRows] = useState([]);
  const [cloudsLoading, setCloudsLoading] = useState(false);

  const responseObjects = useSelector(
    (state) => state.inventoryReducer.responseObjects
  );

  const totalObjects = useSelector(
    (state) => state.inventoryReducer.totalObjects
  );

  const aggregationsIsManaged = useSelector(
    (state) => state.inventoryReducer.aggregationsIsManaged
  );
  const aggregationsAssetType = useSelector(
    (state) => state.inventoryReducer.aggregationsAssetType
  );

  const aggregationsRegion = useSelector(
    (state) => state.inventoryReducer.aggregationsRegion
  );

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const aggregationsClassificaition = useSelector(
    (state) => state.inventoryReducer.aggregationsClassificaition
  );

  const aggregationsIacTypes = useSelector(
    (state) => state.inventoryReducer.aggregationsIacTypes
  );

  const aggregationsOwners = useSelector(
    (state) => state.inventoryReducer.aggregationsOwners
  );

  const aggregationsTags = useSelector(
    (state) => state.inventoryReducer.aggregationsTags
  );
  const azureResourceGroups = useSelector(
    (state) => state.inventoryReducer.azureResourceGroups
  );

  const aggregationsDeleted = useSelector(
    (state) => state.inventoryReducer.aggregationsDeleted
  );

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const tablePageSize = useSelector(
    (state) => state.userPreferencesReducer.tablePageSize
  );
  const selectedService = useSelector(
    (state) => state.inventoryReducer.selectedService
  );
  const inventory_mode = useSelector(
    (state) => state.userPreferencesReducer.inventory_mode
  );

  useLayoutEffect(() => {
    handleCheckViewFromUrl();
    dispatch(setSearchVal(""));
  }, []);

  useEffect(() => {
    setResetFilters(false);
    setResetClouds(false);
    dispatch(setSearchVal(""));
  }, []);

  useEffect(() => {
    if (!initLoading && !fetchingView) {
      getNewPageData();
      setResetFilters(false);
      setResetClouds(false);
    }
  }, [
    screenFilters,
    filterPage,
    tableSorting,
    searchVal,
    tablePageSize,
    moduleFromUrl,
    selectedService,
  ]);

  useEffect(() => {
    if (!initLoading && !fetchingView) {
      let showError = false;
      Promise.all([
        dispatch(getBaseAggregations(screenFilters, searchVal, false, showError)),
        dispatch(getFlagsAggregations(screenFilters, searchVal, showError)),
        dispatch(getFireflyAggregations(screenFilters, searchVal, showError))
      ])
    }
  }, [screenFilters, searchVal]);

  useEffect(() => {
    setFilterPage(1);
  }, [searchVal]);

  const handleCheckViewFromUrl = async () => {
    setFetchingView(true);
    const viewsArr = await dispatch(getUserScreenViews("inventory", user?.sub));
    if (search) {
      const searchParam = new URLSearchParams(search);
      const viewIdFromUrl = searchParam.get("view");
      const stackIdFromUrl = searchParam.get("stack");
      const stateFromUrl = searchParam.get("state");
      const providerFromUrl = searchParam.get("provider");
      const moduleFromUrl = searchParam.get("module");
      const classificationsParam = searchParam.get("classifications");
      const excludedParam = searchParam.get("excluded");
      const vcsFromUrl = searchParam.get("vcs");
      
      if (moduleFromUrl) {
        setModuleFromUrl(moduleFromUrl);
      }

      if(classificationsParam) {
        await dispatch(setFilters({...emptyInventoryScreenFilters, classifications: [classificationsParam], excluded: stringToBoolean(excludedParam) }));
        dispatch(setActiveScreenView({}));
        return setFetchingView(false);
      }

      if (viewIdFromUrl) {
        const req = await dispatch(getViewById(viewIdFromUrl));
        if (!_.isEmpty(req)) {
          await dispatch(setSearchVal(req[0]?.filters?.freeSearchText));
          await dispatch(setFilters(req[0]?.filters));
        }
      }
      if (stackIdFromUrl || stateFromUrl || providerFromUrl || moduleFromUrl || vcsFromUrl) {
        // TODO fetch stack details to present name on the filter and update inventory filters..
        dispatch(setActiveScreenView({}));
        return setFetchingView(false);
      }
    } else {
      if (!_.isEmpty(viewsArr)) {
        const checkIfDefault = viewsArr?.find((view) => view?.isDefault);
        if (checkIfDefault) {
          await dispatch(setSearchVal(checkIfDefault?.filters?.freeSearchText));
          await dispatch(setFilters(checkIfDefault?.filters));
        }
      }
    }
    setFetchingView(false);
  };

  useEffect(() => {
    const fetchInitData = async () => {
      setInitLoading(true);
      let showError = false;
      await Promise.all([
        getNewPageData(),
        getCloudsData(),
        dispatch(getClassifications(false, showError)),
        dispatch(getFireflyAggregations(screenFilters, searchVal, showError)),
        dispatch(getBaseAggregations(screenFilters, searchVal, false, showError)),
        dispatch(getFlagsAggregations(screenFilters, searchVal, showError)),
        dispatch(getDeletedAggregations(screenFilters, searchVal, showError)),
        dispatch(getMasterTypesAggregations(emptyInventoryScreenFilters, searchVal, showError)),
        dispatch(getExclusions(showError)),
        dispatch(getJiraIntegrations(showError)),
        dispatch(getAllVcs(showError)),

      ]);
    };
    if (!fetchingView) {
      fetchInitData();
    }
  }, [fetchingView]);

  const getNewPageData = async () => {
    setLoading(true);
    let showError = true
      let newFiltersWhenServiceSelected = !_.isEmpty(selectedService) && inventory_mode === 'types' ? {...screenFilters, types: [selectedService?.key]} : screenFilters;
      await Promise.all([
      dispatch(
        getInventoryHits(
          newFiltersWhenServiceSelected,
          searchVal,
          filterPage,
          tableSorting,
          tablePageSize,
          showError
        )
      ),
    ]);
    setLoading(false);
    setInitLoading(false);
  };

  const getCloudsData = async () => {
    setCloudsLoading(true);
    const fields = "id,name,isProd,isEventDriven,dailyFetch,lastSuccessfulSyncTime,folder,accountNumber";
    await dispatch(getAllClouds(false, fields))
    setCloudsLoading(false);
  };

  const handleArragerRegionFilterArr = () => {
    let regionFilterArray = [];
    // providers adding (<PROVIDER>) to their title
    let locationFormattingProviders = ["aws", "gcp"];
    const formatLocationTitle = (item) => {
      let title = item?.key;
      if (item?.key !== "global") {
        let providerType = item?.provider?.buckets[0]?.key;
        if (locationFormattingProviders?.includes(providerType)) {
          title = `${item?.key} (${providerNameFormatter(providerType)})`;
        }
      }
      return title;
    };
    if (!_.isEmpty(aggregationsRegion)) {
      for (let item of aggregationsRegion) {
        regionFilterArray.push({
          name: item.key,
          formattedName: formatLocationTitle(item),
          count: item.doc_count,
        });
      }
    }
    return regionFilterArray;
  };

  const handleArrangeOwnersFilterArr = () => {
    if (!_.isEmpty(aggregationsOwners)) {
      return _.map(aggregationsOwners || [], (item) => {
        const ownerObj = !_.isEmpty(item?.owner_data?.hits?.hits)
          ? item?.owner_data?.hits?.hits?.[0]?._source?.ownerData
          : {};
        return {
          name: item.key,
          formattedName: item.key,
          count: item.doc_count,
          element: <OwnerIndication data={ownerObj} filterView />,
        };
      });
    }
    return [];
  };

  const handleArrangeTagsFilterArr = () => {
    let tagsFilterArray = [];
    if (!_.isEmpty(aggregationsTags)) {
      for (let item of aggregationsTags) {
        tagsFilterArray.push({
          name: item.key,
          formattedName: item.key,
          count: item.doc_count,
          element: <AppTag text={item.key} minimal shorten />,
        });
      }
    }
    return tagsFilterArray;
  };
  const handleArrangeResourceGroupsFilterArr = () => {
    let tagsFilterArray = [];
    if (!_.isEmpty(azureResourceGroups)) {
      for (let item of azureResourceGroups) {
        tagsFilterArray.push({
          name: item.key,
          formattedName: item.key,
          count: item.doc_count,
          element: <AppTag text={item.key} minimal shorten />,
        });
      }
    }
    return tagsFilterArray;
  };

  const handleArrageFilterTypes = () => {
    const uniqueTypes = [];
    if (!_.isEmpty(aggregationsAssetType)) {
      for (let assetType of aggregationsAssetType.buckets) {
        uniqueTypes.push({
          name: assetType.key,
          formattedName: formatAwsStringWithUnderscore(assetType.key),
          count: assetType.doc_count,
        });
      }
    }
    return uniqueTypes;
  };

  const handleArrageFilterClassifications = () => {
    const uniqueTypes = [];
    if (!_.isEmpty(aggregationsClassificaition)) {
      for (let item of aggregationsClassificaition) {
        uniqueTypes.push({
          name: item.key,
          formattedName: getNameById(item.key, classifications),
          count: item.doc_count,
        });
      }
    }
    const ordered = _.orderBy(uniqueTypes, ["count"], ["desc"]);
    return ordered;
  };

  const handleArrageFilterDeleted = () => {
    const ranges = [];

    if (!_.isEmpty(aggregationsDeleted) && _.isArray(aggregationsDeleted)) {
      for (let item of aggregationsDeleted) {
        ranges.push({
          name: item?.key,
          formattedName: formatDeletedFilter(item?.key),
          count: item?.count,
        });
      }
    }
    const ordered = _.orderBy(ranges, 'order',);
    return ordered;
  };

  const handleRemoveBadgeFilter = (type, filter, removeAll=false) => {
    switch (type) {
      case "resource_type":
        if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, types: [] }));
        }
        const newTypes = _.filter(
          screenFilters?.types,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, types: newTypes }));
      case "classification":
        const newClassifications = _.filter(
          screenFilters?.classifications,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(
          setFilters({ ...screenFilters, classifications: newClassifications })
        );
      case "excludedAsset":
         if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, exclusions: [] }));
        }
        const newExcludedAssets = _.filter(
          screenFilters?.exclusions,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(
          setFilters({ ...screenFilters, exclusions: newExcludedAssets })
        );
      case "iacType":
         if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, iacTypes: [] }));
        }
        const newIacTypes = _.filter(
          screenFilters?.iacTypes,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(
          setFilters({ ...screenFilters, iacTypes: newIacTypes })
        );
      case "regions":
        if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, regions: [] }));
        }
        const newRegions = _.filter(
          screenFilters?.regions,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, regions: newRegions }));

      case "state":
        const newState = _.filter(
          screenFilters?.state,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, state: newState }));

      case "stacks":
        const newStacks = _.filter(
          screenFilters?.stacks,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, stacks: newStacks }));

      case "years":
        const newYears = _.filter(
          screenFilters?.years,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, years: newYears }));

      case "owners":
        if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, owners: [] }));
        }
        const newOwners = _.filter(
          screenFilters?.owners,
          (item) => item !== filter
        );
        setFilterPage(1); 
        return dispatch(setFilters({ ...screenFilters, owners: newOwners }));

      case "tags":
         if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, tags: [] }));
        }
        const newTags = _.filter(
          screenFilters?.tags,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, tags: newTags }));

      case "resourceGroups":
         if(removeAll){
          setFilterPage(1);
          return dispatch(setFilters({ ...screenFilters, resourceGroups: [] }));
        }
        const newResourceGroups = _.filter(
          screenFilters?.resourceGroups,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, resourceGroups: newResourceGroups }));

      case "misconfiguration": case "reliability": case "optimization": case "git": case "history": case "comments": case "excluded":
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, [type]: !screenFilters?.[type] }));

      case "deleted":
        const newDeleted = _.filter(
          screenFilters?.deletedRange,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, deletedRange: newDeleted }));
      case "terraformObjectFullAddress":
        const newTfObjectFullAddress = removeAll ? [] : _.filter(screenFilters?.terraformObjectFullAddress, (item) => item !== filter);
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, terraformObjectFullAddress: newTfObjectFullAddress }));
      case "vcsId": case "vcsRepo":
        setFilterPage(1);
        return dispatch(setFilters({ ...screenFilters, [type]: "" }));
      case "search":
        setFilterPage(1);
        dispatch(setSearchVal(""));
        break;
      case "providerType":
        if(removeAll){
          setFilterPage(1);
          return dispatch(
            setFilters({
              ...screenFilters,
              providerTypes: emptyInventoryScreenFilters.providerTypes
            })
          );
        }
        const newProviderType = _.filter(
          screenFilters?.providerTypes?.providerType,
          (item) => item !== filter
        );
        const newProviderSubType = _.filter(
          screenFilters?.providerTypes?.providerSubType,
          (item) => item !== filter
        );
        let newProvider = _.filter(
          screenFilters?.providerTypes?.provider,
          (item) => item !== filter
        );
        if(!_.some(providerIntegrations, provider => filter in provider)){
          const providerName = _.findKey(providerIntegrations, integrations =>{
            return _.some(integrations, integration => integration?.id == filter)
          });
         newProvider = _.without(newProvider, providerName)
        } // to make the parent of removed filter half checked 
        const newIntegrationId = _.filter(
          screenFilters?.providerTypes?.integrationId,
          (item) => item !== filter
        );
        setFilterPage(1);
        return dispatch(
          setFilters({
            ...screenFilters,
            providerTypes: {
              providerType: newProviderType,
              providerSubType: newProviderSubType,
              provider: newProvider,
              integrationId: newIntegrationId,
            },
          })
        );
      case "module":
        setFilterPage(1);
        setResetFilters(true);
        setResetClouds(true);
        dispatch(setFilters(emptyInventoryScreenFilters));
        setModuleFromUrl(null);
      default:
        break;
    }
  };

  const handleShareTempView = async () => {
    setLoadingShareTempView(true);
    let payload = {
      screen: "inventory",
      user_id: user?.sub,
      filters: { ...screenFilters, freeSearchText: searchVal },
      name: "Temp-View",
      isDefault: false,
      isTemporary: true,
    };
    const newTempView = await dispatch(createView(payload));
    if (_.isEmpty(newTempView)) {
      return setLoadingShareTempView(false);
    }
    setLoadingShareTempView(false);
    const sharebleUrl = `${
      window.location.origin + window.location.pathname
    }?view=${_.toString(newTempView?._id)}`;
    handleCopy(sharebleUrl);
    return appToast(
      "success",
      "Sharing is Caring!",
      "Shareble Link copied to clipboard"
    );
  };

  const handleArrageFilterIacTypes = () => {
    const uniqueTypes = [];
    if (!_.isEmpty(aggregationsIacTypes)) {
      for (let item of aggregationsIacTypes) {
        const key = item?.key
        if (key !== "unmanaged") {
          const icon = key === IAC_TYPES.k8s ? "argo" : key;
          const name = formatTitleByIacType(key);
          uniqueTypes.push({
            name: key,
            formattedName: capitalizeFirst(name),
            count: item.doc_count,
            icon: iacProviders(icon, themeDark),
          });
        }
      }
    }
    return uniqueTypes;
  };

  const handleSetSelectedRows = (data = [], isIds = false) => {
    if (!isIds) return setSelectedRows(data)
    const ids = data
    const newSelected = _.filter(selectedRows, obj => ids.includes(obj.id));
    const toBeAdded = _.filter(responseObjects, obj => ids.includes(obj.id) && !_.find(selectedRows, { id: obj.id }));
    const updatedSelected = _.concat(newSelected, toBeAdded);
    return setSelectedRows(updatedSelected);
}

  if (initLoading || fetchingView) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  if (
    !initLoading &&
    !fetchingView &&
    _.isEmpty(responseObjects) &&
    _.isEmpty(searchVal) &&
    _.values(providerIntegrations).every(_.isEmpty)
  ) {
    return <EmptyInventory />;
  }

  return (
    <div className="Inventory col">
      <InventoryFilters
        activeProviderTypes={screenFilters?.providerTypes}
        providerTypeSelected={(filters) => {
          setResetFilters(true);
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, providerTypes: filters }));
        }}
        resourceTypes={handleArrageFilterTypes()}
        resourceSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, types: filters }));
        }}
        activeResurceTypesSelected={screenFilters?.types}
        resetFilters={resetFilters}
        classifications={handleArrageFilterClassifications()}
        activeClassificationSelected={screenFilters?.classifications}
        classificationSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, classifications: filters }));
        }}
        handleClearAllFilters={() => {
          setResetFilters(true);
          setResetClouds(true);
          dispatch(setFilters(emptyInventoryScreenFilters));
        }}
        activeExcludedAssetsSelected={screenFilters?.exclusions}
        excludedAssetSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, exclusions: filters }));
        }}
        iacTypes={handleArrageFilterIacTypes()}
        activeTypesSelected={screenFilters?.iacTypes}
        iacTypesSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, iacTypes: filters }));
        }}
        activeRegionsSelected={screenFilters?.regions}
        regions={handleArragerRegionFilterArr()}
        regionsSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, regions: filters }));
        }}
        handleSaveView={() => dispatch(toggleViewModal(true))}
        handleShareView={handleShareTempView}
        loadingShareTempView={loadingShareTempView}
        yearsSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, years: filters }));
        }}
        activeYearsSelected={screenFilters?.years}
        activeOwnersSelected={screenFilters?.owners}
        owners={handleArrangeOwnersFilterArr()}
        ownersSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, owners: filters }));
        }}
        activeTagsSelected={screenFilters?.tags}
        tags={handleArrangeTagsFilterArr()}
        tagsSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, tags: filters }));
        }}
        azureResourceGroups={handleArrangeResourceGroupsFilterArr()}
        azureResourceGroupsSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, resourceGroups: filters }));
        }}
        activeAzureResourceGroupsSelected={screenFilters?.resourceGroups}
        deletedRanges={handleArrageFilterDeleted()}
        activeDeletedRangeSelected={screenFilters?.deletedRange}
        deletedRangeSelected={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, deletedRange: filters }));
        }}
        handleDeletedFilter={(filters) => {
          setFilterPage(1);
          dispatch(setFilters({ ...screenFilters, deleted: filters }));
        }}
        cloudsLoading={cloudsLoading}
      />

      <div className="Inventory__right col">
        <InventoryCharts
          handleSetFilterManaged={(filterItem) => {
            setFilterPage(1);
            const outputFilter = screenFilters?.state?.includes(filterItem)
              ? screenFilters?.state?.filter((item) => item !== filterItem)
              : [filterItem];
            dispatch(setFilters({ ...screenFilters, state: outputFilter }));
          }}
          filterManaged={screenFilters?.state}
          aggregationsIsManaged={aggregationsIsManaged}
          handleSetFlagFilter={(filter) => {
            setFilterPage(1);
            dispatch(setFilters({ ...screenFilters, [filter]: !screenFilters?.[filter] }))
          }}
        />

        <InventoryTableWrapper
          searchValue={(val) => {
            dispatch(setSearchVal(val));
          }}
          tableData={responseObjects}
          fetchingData={loading}
          totalObjects={totalObjects}
          handlePageChange={(page) => {
            setFilterPage(page);
          }}
          handleTableSorterChange={(sorter) => {
            setTableSorting(sorter);
          }}
          // -- filters ---
          filterTypes={screenFilters?.types}
          filterClassifications={screenFilters?.classifications}
          filterExcludedAssets={screenFilters?.exclusions}
          filterIacTypes={screenFilters?.iacTypes}
          filterRegions={screenFilters?.regions}
          filterState={screenFilters?.state}
          filterStacks={screenFilters?.stacks}
          filterYears={screenFilters?.years}
          filterProviderTypes={screenFilters?.providerTypes}
          filterOwners={screenFilters?.owners}
          filterTags={screenFilters?.tags}
          filterResourceGroups={screenFilters?.resourceGroups}
          filterDeleted={screenFilters?.deletedRange}
          filterTerraformObjectFullAddress={screenFilters?.terraformObjectFullAddress}
          handleRemoveBadgeFilter={handleRemoveBadgeFilter}
          onPageSizeChange={(pageSize) =>
            dispatch(updateTabelPageSize(pageSize))
          }
          tablePageSize={tablePageSize}
          moduleFromUrl={moduleFromUrl}
          handleClearAllFilters={() => {
            setResetFilters(true);
            setResetClouds(true);
            setModuleFromUrl(null);
            dispatch(setFilters(emptyInventoryScreenFilters));
          }}
          resetPage={() => setFilterPage(1)}
          disableSelectionOnClick
          selectedRows={selectedRows}
          handleSelectedRows={handleSetSelectedRows}
        />
      </div>
    </div>
  );
};

export default Inventory;
