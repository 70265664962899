import React, { useState, useEffect, useRef } from "react";
import { Tag, Input } from "antd";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { PlusOutlined } from "@ant-design/icons";

import "./formLabels.scss";

const FormLabels = ({ allLabels, selectedLabels, currentSelected, tags, hideNewTag }) => {

  const { CheckableTag } = Tag;
  const inputEl = useRef(null);

  const [selectedTags, setSelectedTags] = useState([]);
  const [inputVisible, setInputVisible] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const [newLabels, setNewLabels] = useState([]);

  useEffect(() => {
    setInputVisible(false);
    if (!_.isEmpty(currentSelected)) {
      setSelectedTags(currentSelected);
      const difference = currentSelected.filter(x => allLabels.indexOf(x) === -1); //get the newly created tags only
      setNewLabels(difference);
    } 
  }, [currentSelected]);

  useEffect(() => {
    selectedLabels(selectedTags);
  }, [selectedTags]);

  useEffect(() => {
    if (inputEl.current && inputVisible) {
      inputEl.current.focus();
    }
  }, [inputEl, inputVisible]);

  const handleChange = (tag, checked) => {
    const nextSelectedTags = checked
      ? [...selectedTags, tag]
      : selectedTags.filter((t) => t !== tag);
    setSelectedTags(nextSelectedTags);
  };

  const handleInputChange = (e) => {
    setInputValue(e.target.value);
  };

  const handleInputConfirm = () => {
    let tempLabels = [];
    let tempChecked = [];
    // if this value not already exiest
    if (
      inputValue &&
      !newLabels.includes(inputValue) &&
      !selectedTags.includes(inputValue)
    ) {
      tempLabels = [...newLabels, inputValue];
      tempChecked = [...selectedTags, inputValue];
      setSelectedTags(tempChecked);
      setNewLabels(tempLabels);
    }
    setInputVisible(false);
    setInputValue("");
  };

  const handleShowInput = () => {
    setInputVisible(true);
  };

  const handleRemoveNewLabel = (removedLabel) => {
    const tempLabels = newLabels.filter((tag) => tag !== removedLabel);
    const tempChecked = selectedTags.filter((tag) => tag !== removedLabel);
    setNewLabels(tempLabels);
    setSelectedTags(tempChecked);
  };

  return (
    <div className="FormLabels row">
      {allLabels.map((tag) => (
        <CheckableTag
          key={uuidv4()}
          checked={selectedTags.indexOf(tag) > -1}
          onChange={(checked) => handleChange(tag, checked)}
        >
          {tag}
        </CheckableTag>
      ))}
      {newLabels.map((tag) => (
        <Tag
          key={uuidv4()}
          visible
          closable
          className="new-label"
          onClose={() => handleRemoveNewLabel(tag)}
        >
          {tag}
        </Tag>
      ))}
      {inputVisible && (
        <Input
          ref={inputEl}
          type="text"
          size="small"
          style={{ width: 100 }}
          value={inputValue}
          onChange={handleInputChange}
          onBlur={handleInputConfirm}
          onPressEnter={handleInputConfirm}
        />
      )}
      {!inputVisible && !hideNewTag && (
        <Tag onClick={handleShowInput} className="add-new-teg">
          <PlusOutlined /> {`New ${tags ? "Tag" : "Label"}`}
        </Tag>
      )}
    </div>
  );
};

export default FormLabels;
