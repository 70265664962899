import React from "react";
import { useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import AppBtn from "../../../shared/appBtn/appBtn";
import { S3_ASSETS } from '../../../consts/config';
import "./emptyInventory.scss";

const EmptyInventory = () => {
  const history = useHistory();
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  return (
    <div className="EmptyInventory col center">
      <img src={`${S3_ASSETS}/illustrations/man-woman-dashboard.svg`} alt="empty environments" />
      <div className="EmptyInventory__text col center">
        <span className="title">Welcome to Firefly</span>

        <div className="EmptyInventory__text-action center col">
          <span className="lighterText">
            Let's start with integrating your Data sources.
          </span>
          <AppBtn
            text="Click to Integrate"
            onClick={() => history.push("/integrations/new-integration")}
            disabled={isViewer}
          />
        </div>
      </div>
    </div>
  );
};

export default EmptyInventory;
