import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import OktaCreation from "./oktaCreation";
import { useDispatch, useSelector } from "react-redux";
import { createOktaIntegration } from "../../../redux/actions/oktaIntegrationActions";
import { clouds } from "../../../utils/icons";

import "./oktaIntegration.scss";

const OktaIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Okta integration details",
      content_title: "Okta integration",
      content: (
        <OktaCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);

        const result = await dispatch(
          createOktaIntegration(
            wizardState.name,
            wizardState.orgName,
            wizardState.apiToken,
            wizardState.baseUrl,
          )
        );
        setLoadingSubmit(false);
        if (!result?.ok) {
          throw new Error(`Failed to create Okta integration.`);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle="The Resources from the Integration will take up to one hour to appear in the inventory"
          doesTakeTime={true}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="OktaIntegration">
      <AppWizard
        loadingSubmit={loadingSubmit}
        iconStyle={{ height: "28px" }}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={clouds("okta", themeDark)}
      />
    </div>
  );
};

export default OktaIntegration;
