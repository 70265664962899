import React, { useState, useEffect, useMemo } from "react";
import { useTranslation } from "react-i18next";
import { getRelationFamily } from "../../../../redux/actions/relationshipActions";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import Loading from "../../../../shared/loading/loading";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import InventoryTable from "../../inventoryTable/inventoryTable";
import RelationshipAssetConfig from "./relationshipChartComponents/relationshipAssetConfig";

const RelationshipTable = ({ asset }) => {
  const { t } = useTranslation("inventory", { keyPrefix: "asset-popup" });
  const dispatch = useDispatch();
  const pageSize = 20;
  const page = 1;

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );
  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );
  const excludedAssets = useSelector((state) => state.exclusionsReducer.rules);

  const [loading, setLoading] = useState(false);
  const [treeData, setTreeData] = useState([]);
  const [configOpen, setConfigOpen] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);

  useEffect(() => {
    if (asset && asset?.hasOwnerTargets) { // fetch tree only for reltaions and not trace
      fetchRelationsip();
    }
  }, [asset]);

  const fetchRelationsip = async () => {
    setLoading(true);
    const { frn, integrationId } = asset || {};
    const req = await dispatch(
      getRelationFamily(frn, integrationId, page, pageSize)
    );
    setTreeData(req);
    setLoading(false);
  };

  const configDrawer = useMemo(() => {
    return (
      <RelationshipAssetConfig
        visible={configOpen}
        onClose={() => setConfigOpen(false)}
        row={selectedRow}
      />
    );
  }, [configOpen, selectedRow]);

  const table = useMemo(() => {
    return (
      <InventoryTable
        tableData={treeData}
        rowCount={treeData?.length || 0}
        loading={loading}
        providerIntegrations={providerIntegrations}
        classifications={classifications}
        excludedAssets={excludedAssets}
        tablePageSize={1000}
        getTreeDataPath={(row) => row?.hierarchy || []}
        treeData
        handleSelectedRowsArr={() => {
          return;
        }}
        onRowClick={(row) => {
          setSelectedRow(row?.row);
          setConfigOpen(true);
        }}
        handleOpenAnalysis={() => {
          return;
        }}
        handleOpenMulti={() => {
          return;
        }}
        handleOpenInsights={() => {
          return;
        }}
        handleTagClicked={() => {
          return;
        }}
        handleOpenCommentsTab={() => {
          return;
        }}
        handleClickOwner={() => {
          return;
        }}
        handleOpenHistoryTab={() => {
          return;
        }}
        handleOpenGit={() => {
          return;
        }}
        handleOpenConnectionTab={() => {
          return;
        }}
      />
    );
  }, [treeData, setSelectedRow, loading]);

  if (loading) {
    return <Loading />;
  }

  // no data tree - empty state
  if (!loading && isEmpty(treeData)) {
    return (
      <div className="RelationshipTable basic-card center">
        <AppEmpty customStyle="code" text={t("relationships.list.no-data")} />
      </div>
    );
  }

  return (
    <div className="RelationshipTable basic-card">
      {table}
      {configDrawer}
    </div>
  );
};

export default RelationshipTable;
