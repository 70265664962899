import React from "react";
import { useIntercom } from "react-use-intercom";
import { ReactComponent as TrophyStarIcon } from "../../Images/general_icons/sidebar/trophy-star.svg";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import "./premiumBtn.scss";

const PremiumBtn = () => {
  const { t } = useTranslation("sidebar");
  const { showNewMessages, show } = useIntercom();
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  const handleUpgrade = () => {
    show();
    showNewMessages(t('account-tier.upgrade-intecome-text'));
  };

  return (
    <div className={`PremiumBtn row center ${sidebarClosed ? 'minimal' : ''}`} onClick={handleUpgrade}>
      <TrophyStarIcon className="PremiumBtn__icon"/>
      {!sidebarClosed && <div className="PremiumBtn__text">{t('account-tier.upgrade-btn')}</div>}
    </div>
  );
};

export default PremiumBtn;