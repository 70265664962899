import React, { useEffect } from "react";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheckCircle } from "@fortawesome/free-solid-svg-icons";
import { S3_ASSETS } from "../../consts/config";
import "./successBox.scss";

const SuccessBox = ({ setSubmitDisabled, title, subTitle, doesTakeTime }) => {
  useEffect(() => {
    setSubmitDisabled(false);
  }, []);

  return (
    <div className="SuccessBox center col full-page">
      <span className="SuccessBox__image">
        <img
          src={
            doesTakeTime
              ? `${S3_ASSETS}/illustrations/time_indication.svg`
              : `${S3_ASSETS}/illustrations/rocket-computer.svg`
          }
          alt="success"
        />
      </span>
      <div className="SuccessBox__text col center">
        <div className="SuccessBox__text-title row">
          <FontAwesomeIcon
            icon={faCheckCircle}
            className="SuccessBox__text-title-icon"
          />
          <span className="SuccessBox__text-title-text">
            {!_.isEmpty(title) ? title : "Success!"}
          </span>
        </div>
        {!_.isEmpty(subTitle) && (
          <span className="SuccessBox__text-subTitle">{subTitle}</span>
        )}
      </div>
    </div>
  );
};

export default SuccessBox;
