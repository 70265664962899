import { GET_HELM_CHARTS } from "../actions/helmChartsActions";

const initialState = {
  charts: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_HELM_CHARTS:
      return { ...state, charts: payload.data };

    default:
      return state;
  }
}
