import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Checkbox, Form, Select, Spin } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSatelliteDish } from "@fortawesome/free-solid-svg-icons";

import {
  getAwsIntegrations,
  getAllBuckets,
  getAllS3Objects,
} from "../../../redux/actions/integrationsActions";
import { appToast } from "../../../shared/appToast/appToast";
import FormLabels from "../../../shared/formLabels/formLabels";

const S3Bucket = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  setTags,
  allTags,
  currentSelectedTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const awsIntegrations = useSelector((state) => state.integrationsReducer.aws);

  const [buckets, setBuckets] = useState([]);
  const [stateFiles, setStateFiles] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedBucket, setSelectedBucket] = useState("");
  const [selectedStates, setSelectedStates] = useState([]);

  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingBucket, setLoadingBucket] = useState(false);
  const [loadingS3Objects, setLoadingS3Objects] = useState(false);

  const [bucketHasPermission, setbucketHasPermission] = useState(true);
  const [autoDiscover, setAutoDiscover] = useState(true);

  // fetch AWS Integrations when starting
  useEffect(() => {
    fetchIntegrations();
  }, []);

  // fetch all S3 Buckets on Integration change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration)) {
      fetchS3Buckets();
    }
  }, [selectedIntegration]);

  // fetch all S3 Objects on Integration or Bucket change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration) && !_.isEmpty(selectedBucket)) {
      fetchS3Objects();
    }
  }, [selectedIntegration, selectedBucket]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getAwsIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchS3Buckets = async () => {
    setLoadingBucket(true);
    const res = await dispatch(getAllBuckets(selectedIntegration));
    if (_.isEmpty(res.buckets)) {
      return setLoadingBucket(false);
    }
    setBuckets(res.buckets);
    setLoadingBucket(false);
  };

  const fetchS3Objects = async () => {
    setLoadingS3Objects(true);
    const res = await dispatch(
      getAllS3Objects(selectedIntegration, selectedBucket)
    );

    //Update permission issue
    setbucketHasPermission(res?.ok);

    if (!res?.ok) {
      setLoadingS3Objects(false);
      return appToast(
        "info",
        "",
        `Firefly was unable to list the objects from this S3 Bucket. Make sure we have the proper permissions so we can access state files.`
      );
    }
    const tfStateFiles = _.filter(res.objects, (obj) =>
      obj.Key.endsWith(".tfstate")
    );
    setStateFiles(tfStateFiles);
    setLoadingS3Objects(false);
  };

  const renderAwsIntegrationsOptions = () => {
    if (!_.isEmpty(awsIntegrations.integrations)) {
      return _.map(awsIntegrations.integrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderAwsBucketsOptions = () => {
    if (!_.isEmpty(buckets)) {
      return _.map(buckets || [], (bucket) => {
        return (
          <Select.Option value={bucket.Name} key={bucket.Name}>
            {bucket.Name}
          </Select.Option>
        );
      });
    }
  };

  const renderAwsTerraformStatesOptions = () => {
    if (!_.isEmpty(stateFiles)) {
      return _.map(stateFiles || [], (state) => {
        return (
          <Select.Option value={state.Key} key={state.Key}>
            {state.Key}
          </Select.Option>
        );
      });
    }
  };

  const renderAwsFolderOptions = () => {
    if (!_.isEmpty(stateFiles)) {
      const folderArr = _.uniq(
        _.map(stateFiles, (item) => {
          const folder = item?.Key.split("/");
          return `${folder[0]}/`;
        })
      );

      const output = _.map(folderArr || [], (folder) => {
        return (
          <Select.Option value={folder} key={folder}>
            {folder}
          </Select.Option>
        );
      });

      output.unshift(
        <Select.Option value="Entire Bucket" key="Entire Bucket">
          Entire Bucket
        </Select.Option>
      );
      return output;
    }
  };

  const setIsFormValidFlag = (formValues) => {
    handleSetIsFormValid(
      !_.isEmpty(formValues.awsIntegration) &&
        !_.isEmpty(formValues.s3Bucket) &&
        ((!_.isEmpty(formValues?.terraformFolder) &&
          formValues.discovery === "auto") ||
          (!_.isEmpty(formValues?.terraformStates) &&
            formValues.discovery === "manual"))
    );
  };

  const generateS3PolicyTemplateUrl = (bucketName, integrationId) => {
    const selectedIntegration = _.filter(
      awsIntegrations?.integrations || [],
      (integ) => integ.id == integrationId
    );
    if (_.isEmpty(selectedIntegration) || selectedIntegration.length > 1) {
      return;
    }
    const roleArn = _.first(selectedIntegration).roleArn;
    return `https://us-east-1.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://infralight-templates-public.s3.amazonaws.com/s3bucket-readonly/template.yml&param_BucketName=${bucketName}&stackName=infralight-${bucketName}-${integrationId}&param_RoleArn=${roleArn}`;
  };

  return (
    <Form
      {...layout}
      name="s3-settings-form"
      form={form}
      initialValues={{
        discovery: "auto",
      }}
      className="StateConfiguration"
      onValuesChange={(changedValues, allValues) => {
        // if the change was the value of selected all, set the selected states before moving forward
        if (!_.isUndefined(changedValues.selectAll)) {
          let stateList = changedValues.selectAll
            ? _.map(stateFiles, (state) => {
                return state.Key;
              })
            : [];
          allValues.terraformStates = stateList;
          form.setFieldsValue({ terraformStates: stateList });
        }

        if (changedValues.discovery === "manual") {
          form.setFieldsValue({ terraformStates: [] });
        }
        if (changedValues.discovery === "auto") {
          form.setFieldsValue({ terraformFolder: [] });
        }
        changeSelectedStateSettings(allValues);
        setIsFormValidFlag(allValues);
      }}
    >
      <Form.Item
        label="AWS Integration"
        name="awsIntegration"
        help={
          !loadingIntegrations && _.isEmpty(awsIntegrations.integrations) ? (
            <Link to="/integrations/aws-integration">
              + Add AWS Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Aws Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select Aws Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderAwsIntegrationsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="S3 Bucket"
        name="s3Bucket"
        rules={[
          {
            required: true,
            message: "selecting a S3 Bucket is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select S3 Bucket"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(bucket) => setSelectedBucket(bucket)}
          loading={loadingBucket}
        >
          {renderAwsBucketsOptions()}
        </Select>
      </Form.Item>
      {loadingS3Objects && <Spin />}
      {!bucketHasPermission &&
        !loadingS3Objects &&
        !_.isEmpty(selectedBucket) &&
        !_.isEmpty(selectedIntegration) && (
          <div
            className="row between"
            style={{ transform: "translateY(-15px)" }}
          >
            <a
              target="_blank"
              href={generateS3PolicyTemplateUrl(
                selectedBucket,
                selectedIntegration
              )}
              rel="noreferrer"
            >
              + Grant permission
            </a>
            <span
              className="purple-text"
              style={{ cursor: "pointer" }}
              onClick={() => fetchS3Objects()}
            >
              Reload
            </span>
          </div>
        )}

      <Form.Item
        label="Terraform states"
        name="discovery"
        rules={[
          {
            required: true,
            message: "selecting a terraform discovery is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select terraform discovery"
          onSelect={(discovery) => setAutoDiscover(discovery === "auto")}
        >
          <Select.Option value="auto" key="auto">
            <FontAwesomeIcon
              icon={faSatelliteDish}
              style={{ marginRight: "5px" }}
            />{" "}
            Auto-Discover state files
          </Select.Option>
          <Select.Option value="manual" key="manual">
            Select specific state files
          </Select.Option>
        </Select>
      </Form.Item>

      {autoDiscover ? (
        <Form.Item
          label="Scope Selection"
          name="terraformFolder"
          rules={[
            {
              required: true,
              message: "selecting a folder is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Select Scope"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            loading={loadingS3Objects}
          >
            {renderAwsFolderOptions()}
          </Select>
        </Form.Item>
      ) : (
        <div>
          <Form.Item
            label="File Selection"
            name="terraformStates"
            rules={[
              {
                required: true,
                message: "selecting Terraform state files is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Select
              placeholder="Select Terraform states files (.tfstate file)"
              showSearch
              filterOption={(input, option) =>
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
              mode="tags"
              onSelect={(state) => {
                let tmpSelectedStates = selectedStates;
                tmpSelectedStates.push(state);
                return setSelectedStates(tmpSelectedStates);
              }}
              onDeselect={(state) => {
                let tmpSelectedStates = selectedStates;
                _.remove(tmpSelectedStates, (item) => item === state);
                return setSelectedStates(tmpSelectedStates);
              }}
              loading={loadingS3Objects}
            >
              {renderAwsTerraformStatesOptions()}
            </Select>
          </Form.Item>
          <Form.Item name="selectAll" valuePropName="checked">
            <Checkbox checked={false} disabled={_.isEmpty(stateFiles)}>
              <span className="selectAll__checbox">Select All</span>
            </Checkbox>
          </Form.Item>
        </div>
      )}
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};

export default S3Bucket;
