import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import moment from 'moment';
import TableWrapper from "../../shared/tableWrapper/tableWrapper";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "react-i18next";
import CiModal from "./ciModal/ciModal";
import { sendEvent } from "../../utils/amplitude";
import { CiEvents } from "../../utils/amplitudeEvents";
import { renderEllipsis } from "../../utils/formatting";
import ActionsSummary from "./ciModal/actionsSummary/actionsSummary";
import VulnerabilityLine from "../../shared/vulnerabilityLine/vulnerabilityLine";
import OwnerIndication from "../inventory/ownerIndication/ownerIndication";
import { useDispatch } from "react-redux";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import { setFilters as setInventoryFilters } from "../../redux/actions/inventoryv3Actions";

const CI_STEP_DATE_FORMAT = "MMM DD YYYY, HH:mm"

const CiStepsTable = ({ tasks = [], statusTitles, workspaceName = '', loading = false, runsCount, pageSize = 10, setPageSize, setPageNumber, iacStackId }) => {
    const tasksLength = tasks.length;
    const { t } = useTranslation("ci-pipeline");
    const [modalVisible, setModalVisible] = useState(false);
    const [selectedRow, setSelectedRow] = useState({});
    const dispatch = useDispatch();
    const history = useHistory();
    
    const handleRowClick = (params = {}) => {
      const rowData = params.row || {};
      sendEvent(CiEvents.prRowClick, { prId: rowData.runId, workspaceName })
      setSelectedRow(rowData);
      setModalVisible(true);
    }

    const onAssetsClick = (resourcesAddresses) => {
      const stacks = [
        {
          id: iacStackId,
          name: workspaceName,
          iacType: "terraform"
        }
      ]
      dispatch(
        setInventoryFilters({
          ...emptyInventoryScreenFilters,
          stacks,
          terraformObjectFullAddress: resourcesAddresses,
          masterType: []
        })
      );
      history.push({
        pathname: `/inventory`,
        search: `?stack=${stacks[0].id}`,
      });
    }

    const columns = [
      {
        headerName: t("columns.startedAt"),
        field: "createdAt",
        width: 130,
        filterable: false,
        disableColumnMenu: true,
        valueGetter: ({ row }) => moment(row.createdAt).format(CI_STEP_DATE_FORMAT)
      },
      {
        headerName: t("columns.buildId"),
        field: "buildId",
        flex: 1,
        filterable: false,
        disableColumnMenu: true,
        renderCell: (params) => {
          const { row } = params;
          const { buildUrl, buildId = "" } = row || {};
          return (
            <div className={`${buildUrl ? 'row g5 center': ''}`}>
              <span>#{buildId}</span>
              {buildUrl && (<FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                  e.stopPropagation();
                  window.open(buildUrl, "_blank");
                }}/>)}
            </div>
          );
        },
      },
      {
          headerName: t("columns.title"),
          field: "title",
          flex: 2,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { title = "", pullRequestId = "", pullRequestUrl = "" } = row || {};
            return (
              <div className={`${pullRequestId ? 'row g5 center': ''}`}>
                <Tooltip title={title.length > 50 ? title : ""}>{renderEllipsis(title, 50)}</Tooltip>
                <div className="row g5">
                  {pullRequestId && (<span>{`(#${pullRequestId})`}</span>)}
                  {pullRequestUrl && (<FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => { 
                      e.stopPropagation();
                      window.open(pullRequestUrl, "_blank");
                    }}/>)}
                </div>
              </div>
            );
          },
        },
        {
          headerName:  t("columns.commitId"),
          field: "commitId",
          flex: 1,
          filterable: false,
          sortable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { row } = params;
            const { commitUrl, commitId = "" } = row || {};
            const shownText = commitId ? `#${commitId.slice(0, 7)}` : ''
            return (
              <div className={`${commitUrl ? 'row g5 center': ''}`}>
                <Tooltip title={commitId}>
                  {shownText}
                </Tooltip>
                {commitUrl && (<FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' size="sm" onClick={(e) => {
                  e.stopPropagation();
                  window.open(commitUrl, "_blank")} 
                }/>)}
              </div>
            );
          },
        },
        {
          headerName:  t("columns.branch"),
          field: "branch",
          width: 100,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          valueGetter: (params) => params.row.branch
        },
        {
          headerName:  t("columns.summary"),
          field: "summary",
          width: 125,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [], apply = [] } = params?.row || {};
            const planSummary = plan[0];
            const applySummary = apply[0];
            const parsedDataSummary = applySummary?.parsedDataSummary?.apply || planSummary?.parsedDataSummary?.plan;
            return <ActionsSummary data={parsedDataSummary} liteEdition />
          }
        },
        {
          headerName:  t("columns.policyViolations"),
          field: "policyViolations",
          width: 150,
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [] } = params?.row || {};
            const scanResultsSummary = plan[0]?.scanResultsSummary || {};
            const severities = scanResultsSummary.rego?.notNoOpResourcesViolationsSeverityCount || { HIGH: 0, MEDIUM: 0, LOW: 0 };
            return <VulnerabilityLine data={severities} isCiTable />
          }
        },
        {
          headerName:  t("columns.costEstimation"),
          field: "costEstimation",
          width: 90,
          sortable: false,
          filterable: false,
          align: "center",
          disableColumnMenu: true,
          renderCell: (params) => {
            const { plan = [] } = params?.row || {};
            const costResultsSummary = plan[0]?.costResultsSummary || {};
            const beforeCost = costResultsSummary.cost?.before?.totalMonthlyCost || 0;
            const afterCost = costResultsSummary.cost?.after?.totalMonthlyCost || 0;
            const diffCost = afterCost - beforeCost;

            const wasPrice = beforeCost == 0 ? beforeCost : parseFloat(beforeCost).toFixed(2);
            const nowPrice = afterCost == 0 ? afterCost : parseFloat(afterCost).toFixed(2); 
            const diffPrice = diffCost == 0 ? 0 : parseFloat(Math.abs(diffCost)).toFixed(2);

            const component = diffCost > 0 ? <FontAwesomeIcon className="CiStepsCollapse__table-cost up" icon="arrow-up"/> 
            : diffCost < 0 ? <FontAwesomeIcon className="CiStepsCollapse__table-cost down" icon="arrow-down"/> 
            : null;
            const showDetails = !!component;
            return <Tooltip title={showDetails ? `Was - ${wasPrice}$ | Now - ${nowPrice}$` : ""}>
                    <div className="row g5">
                      {showDetails ? <span>{diffPrice}$</span> : null}
                      {component}
                    </div>
                   </Tooltip>
          }
        },
        {
          headerName:  t("columns.owner"),
          field: "owner",
          width: 77,
          align: "center",
          sortable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const ownerSummary = params?.row?.ownerSummary || {};
            const { name , email = '' , initials = "", customColor } = ownerSummary;
            const userIdentity = name ? { displayName: name, email, initials: initials.toUpperCase() , customColor } : {};
            const data = { userIdentity };
            return <OwnerIndication data={data} isCiRun/>
          }
        },
        {
          headerName: t("columns.status"),
          field: "status",
          width: 160,
          sortable: false,
          resizable: false,
          filterable: false,
          disableColumnMenu: true,
          renderCell: (params) => {
            const { status, apply = [] } = params.row;
            const applySummary = apply[0] || {};
            const { resourcesAddresses, notNoOpResourcesCount = 0 } = applySummary?.parsedDataSummary?.apply || {};
            const isInventoryLink = ['apply_success', 'apply_error'].includes(status) && resourcesAddresses?.length > 0 && iacStackId && notNoOpResourcesCount > 0;
            return (<div className="row g8">
              <span className={`CiStepsCollapse__header-status-text ${status} bold`}>{statusTitles[status]}</span>
              {isInventoryLink ? 
                <div className={`CiStepsCollapse__table-assets ${status} row`}
                  onClick={(e) => {
                    e.stopPropagation();
                    onAssetsClick(resourcesAddresses);
                  }}>
                  <FontAwesomeIcon icon="layer-group" />
                  { notNoOpResourcesCount }
                </div> : null}
              </div>)
          }
        },
    ];
    return (
      <div>
          <TableWrapper
            autoHeight={!!tasksLength}
            rowKey="runId"
            tableData={tasks || []}
            columns={columns}
            loading={loading}
            disableSelectionOnClick
            pageSize={pageSize}
            rowCount={runsCount}
            handlePageChange={setPageNumber}
            onPageSizeChange={setPageSize}
            onRowClick={handleRowClick}
            serverSide
           />
         {modalVisible && <CiModal runData={selectedRow} modalVisible={modalVisible} setModalVisible={setModalVisible} />}
      </div>
   )
}

export default CiStepsTable;