import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faExclamationCircle,
} from "@fortawesome/free-solid-svg-icons";
import _ from "lodash";
import "./notificationAlert.scss";

const NotificationAlert = ({
  text,
  text2,
  noClosable,
  noBorder,
  colorClass,
  noIcon,
  actionOnClose,
  handleCloseClicked,
}) => {
  const [visible, setVisible] = useState(true);

  if (!visible) {
    return null;
  }

  return (
    <div
      className={`NotificationAlert row between ${
        noBorder ? "noBorder" : null
      } ${colorClass}`}
    >
      <div className="NotificationAlert__text row">
        {!noIcon && <FontAwesomeIcon icon={faExclamationCircle} />}
        <div className="col" style={{ gap: "3px" }}>
          <span>{text}</span>
          {!_.isEmpty(text2) && <span>{text2}</span>}
        </div>
      </div>

      {!noClosable && (
        <button
          onClick={() => {
            setVisible(false);
            if(actionOnClose) {
              handleCloseClicked();
            }
          }}
          className="NotificationAlert__btn"
        >
          <FontAwesomeIcon icon={faTimes} />
        </button>
      )}
    </div>
  );
};

export default NotificationAlert;
