import React, { useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import DropMenu from "../../../shared/dropMenu/dropMenu";
import Popover from "antd/lib/popover";
import { ReactComponent as PlayIcon } from "../../../Images/general_icons/play.svg";

import "./backendsTable.scss";

const BackendActionsButton = ({
  rowData,
  handleScanClick,
  handleSettingsClick,
  loadingScan,
}) => {
  const [visible, setVisible] = useState(false);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const settingsTypes = ["s3", "consul", "gcs"];

  const hide = () => {
    setVisible(false);
  };

  const handleVisibleChange = (newVisible) => {
    setVisible(newVisible);
  };

  const getLinks = () => {
    const links = [
      {
        text: "Scan Now",
        icon: loadingScan ? (
          <FontAwesomeIcon
            icon="spinner"
            spin
            className="BackendsTable__item-icon actions spin"
          />
        ) : (
          <PlayIcon className="BackendsTable__item-icon actions play" />
        ),
        onClick: () => handleScanClick(rowData?._id),
        disabled: isViewer,
      },
    ];
    if (settingsTypes?.includes(rowData?.type)) {
      links?.push({
        text: "Settings",
        icon: <FontAwesomeIcon icon="cog" />,
        onClick: () => {
          handleSettingsClick(rowData);
          hide();
        },
        disabled: isEmpty(rowData),
      });
    }
    return links;
  };

  return (
    <Popover
      content={<DropMenu links={getLinks()} />}
      title={null}
      placement="bottomRight"
      destroyTooltipOnHide
      overlayClassName="BackendsTable"
      visible={visible}
      onVisibleChange={handleVisibleChange}
    >
      <div className="BackendsTable__toggle">
        <IconBtn type="menu-h" colorClass="h-menu" />
      </div>
    </Popover>
  );
};

export default BackendActionsButton;
