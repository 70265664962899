import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import Tooltip from "antd/lib/tooltip";
import { renderEllipsis } from "../../utils/formatting";
import "./customPropertiesTags.scss";
import _ from "lodash";

const CustomPropertiesTags = ({ data, onClick, disableClick }) => {
  const removeNumberAntUnderscore = (str) => {
    if (!isEmpty(str)) {
      const newStr = str.split("_");
      return newStr?.length > 1 ? newStr[0] : str;
    }
  };

  const handleAddition = (val, key) => {
    if (removeNumberAntUnderscore(key) === "MemorySize") {
      return `${val}mb`;
    }
    if (removeNumberAntUnderscore(key) === "version") {
      return `v${val}`;
    }
    return val;
  };

  const formatVal = (val, key) => {
    if (!isEmpty(val) && val.startsWith("[")) {
      const output = val
        .replaceAll("[", "")
        .replaceAll('"', " ")
        .replaceAll("]", "");
      return handleAddition(output, key);
    }
    return handleAddition(val, key);
  };

  const isJson = (str) => {
    try {
        JSON.parse(str);
    } catch (e) {
        return false;
    }
    return true;
}

  const renderTags = () => {
    if (isEmpty(data) || !isJson(data)) {
      return null;
    }

    const parsedData = JSON.parse(data);
    return map(parsedData || [], (val, key) => {
      if (val !== "null") {
        return (
          <Tooltip
            style={{ cursor: disableClick? "default" : "pointer" }}
            title={
              <span className="CustomPropertiesTags__tag col">
                <span className="small-title">{`${removeNumberAntUnderscore(
                  key
                )}: `}</span>
                <span>{formatVal(val, key)}</span>
              </span>
            }
            key={key}
          >
            <span
              className="purple-flag"
              onClick={(e) => {
                e.stopPropagation();
                if(!disableClick) {
                onClick(val);
                }
              }}
            >
              {renderEllipsis(formatVal(val, key), 15)}
            </span>
          </Tooltip>
        );
      }
    });
  };

  return <div className="CustomPropertiesTags row">{renderTags()}</div>;
};

export default CustomPropertiesTags;
