import { map, keys, groupBy, isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";

export const terraformConfig = (mutualColumns = {}, commonFinalColumns, renderVersion) => [
    mutualColumns.name?.terraform,
    {
      headerName: "Version",
      field: "metadata",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => renderVersion(params?.row),
    },
    mutualColumns.status,
    {
      headerName: "Backend",
      field: "stateMainRemoteLocationString",
      flex: 1,
      filterable: false,
      renderCell: (params) =>
        params?.row?.metadata?.remoteLink ? (
          <a
            href={params?.row?.metadata?.remoteLink}
            target="_blank"
            rel="noreferrer"
          >
            {params?.row?.stateMainRemoteLocationString}
          </a>
        ) : (
          params?.row?.stateMainRemoteLocationString
        ),
    },
    {
      headerName: "Data Source",
      field: "stackProviders",
      flex: 1,
      filterable: false,
      sortable: false,
      renderCell: (params) => {
        if (!isEmpty(params?.value)) {
          const groupedProviders = groupBy(params?.value, "providerType");
          return (
            <span className="IacStacksTable__item-list row">
              {map(keys(groupedProviders), (provider) => {
                return (
                  <div
                    className="IacStacksTable__item-list-cloudIcon"
                    key={uuidv4()}
                  >
                    <ProviderIcon
                      provider={provider}
                      customStyle="inventory"
                    />
                    {groupedProviders[provider]?.length > 1 && (
                      <span className="IacStacksTable__item-list-cloudIcon-bubble center">
                        {groupedProviders[provider]?.length}
                      </span>
                    )}
                  </div>
                );
              })}
            </span>
          );
        }
        return "-";
      },
    },
].concat(commonFinalColumns);
