import React from "react";
import { Link, useLocation } from "react-router-dom";
import withBreadcrumbs from "react-router-breadcrumbs-hoc";
import { capitalize, map, isEmpty } from "lodash";
import { useTranslation } from "react-i18next";
import "./breadCrumbs.scss";

const renderBreadcrumb = (breadcrumb, location, t) => {
  const breadCrumbChildren = breadcrumb?.props?.children
  
  if (breadCrumbChildren === "Home") {
    return t('titles.home');
  }
  if (breadCrumbChildren === "Iac") {
    return t('titles.iac');
  }
  if (breadCrumbChildren === "Aws-integration") {
    return t('titles.aws');
  }
  if (breadCrumbChildren === "Gcp-provider-integration") {
    return t('titles.gcp');
  }
  if (breadCrumbChildren === "Gcp") {
    return t('titles.gcp-service-account');
  }
  if (breadCrumbChildren === "Aws") {
    return t('titles.aws-title');
  }
  if (breadCrumbChildren === "Azurerm") {
    return t('titles.azure-title');
  }
  if (breadCrumbChildren === "Gcp-discovery") {
    return t('titles.gcp-discovery');
  }
  if (breadCrumbChildren === "Azure-discovery") {
    return t('titles.discovery-status');
  }
  if (breadCrumbChildren === "Gcs-integration") {
    return t('titles.gcs-integration-short');
  }
  if (breadCrumbChildren === "Pagerduty-integration") {
    return t('titles.pagerduty');
  }
  if (
    breadcrumb?.props?.children === "Create-custom-control" &&
    !isEmpty(location?.state?.data)
  ) {
    return t('titles.edit-insight');
  }
  if (breadCrumbChildren === "Codecommit-integration") {
    return t('titles.codecommit');
  }
  if (breadCrumbChildren === "Azuredevops-integration") {
    return t('titles.azuredevops');
  }
  if (breadCrumbChildren === "Ns1-integration") {
    return t('titles.ns1')
  }
  if (breadCrumbChildren === "Ci") return t('titles.ci');
  if (breadCrumbChildren === "MongodbAtlas-integration") {
    return t('titles.mongodbatlas')
  }
  if (breadCrumbChildren?.includes("-")) {
    let breadCrumbsArray = breadcrumb?.props?.children?.split("-");
    breadCrumbsArray = map(breadCrumbsArray, (bc) => capitalize(bc));
    if( breadCrumbsArray[0] == "Create" && !isEmpty(location?.state?.policy)){
      breadCrumbsArray[0] = "Edit"
    }
    return breadCrumbsArray?.join(" ");
  }
  return breadcrumb;
};

const BreadCrumbs = ({ breadcrumbs }) => {
  const location = useLocation();
  const { t } = useTranslation("header");

  const checkGcpEditMode = (e, match) => {
    // prevent navigation to integration selection on edit mode
    if(location.pathname === "/integrations/gcp-provider-integration/gcp"){
      if(match.path === "/integrations/gcp-provider-integration" && !isEmpty(location.state)){
        return e.preventDefault();
      }
    }
  }
  return (
    <div className="BreadCrumbs">
      {breadcrumbs.map(({ breadcrumb, match }, index) => (
        <div className="BreadCrumbs__single" key={match.url}>
          <Link to={match.url || ""} onClick={(e) => checkGcpEditMode(e, match)}>
            {renderBreadcrumb(breadcrumb, location, t)}
            {index < breadcrumbs.length - 1 && (
              <span style={{ paddingLeft: "8px" }}>/</span>
            )}
          </Link>
        </div>
      ))}
    </div>
  );
};

export default withBreadcrumbs()(BreadCrumbs);
