import React from "react";
import { Button, Drawer } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import "./appDrawer.scss";

// ** showBorders **
// this attribute adds borders to the body in case this is a non code drawer without a dark background

const AppDrawer = ({
  visible,
  closeDrawer,
  children,
  placement,
  width,
  title,
  footer,
  hideClose,
  titleFlag,
  subTitle,
  topBorder,
  zIndex,
  destroyOnClose,
  headerClassName = '',
  bodyClassName = '',
  ...props
}) => {

  const renderFooter = () => {
    if(!_.isEmpty(footer) && _.isArray(footer)) {
      return _.map(footer, item => <div key={uuidv4()}>{item}</div>)
    }
  }
  return (
    <Drawer
      title={null}
      placement={placement ? placement : "right"}
      closable={false}
      onClose={closeDrawer}
      visible={visible}
      className="AppDrawer"
      key={placement ? placement : "right"}
      width={width ? width : 450}
      zIndex={zIndex? zIndex : 1000}
      destroyOnClose={destroyOnClose ? destroyOnClose : false}
      {...props}
    >
      <div className="AppDrawer__content">
        <div className={`AppDrawer__content-header${headerClassName} col`}>
          <div className="AppDrawer__content-header-title row">
            <span className="title">{title}</span>
            {titleFlag}
          </div>
          {subTitle && (
            <div className="AppDrawer__content-header-sub">{subTitle}</div>
          )}
          <FontAwesomeIcon
            icon="times"
            onClick={closeDrawer}
            className="AppDrawer__content-header-close"
          />
        </div>
        <div
          className={`AppDrawer__content-body${bodyClassName} ${
            topBorder ? "topBorder" : ""
          }`}
        >
          {children}
        </div>
        <div className="AppDrawer__content-footer row">
          {renderFooter()}
          {!hideClose && (
            <Button type="Default" onClick={closeDrawer}>
              Close
            </Button>
          )}
        </div>
      </div>
    </Drawer>
  );
};

export default AppDrawer;
