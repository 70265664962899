import _ from "lodash";
import {
  GET_INSIGHTS_AGGREGATIONS,
  GET_INSIGHTS_ASSETS,
  GET_DASHBOARD_INSIGHTS,
  SET_INSIGHTS_FILTERS,
  GET_LATEST_INSIGHTS,
} from "../actions/insightsActions";

const initialState = {
  responseObjects: [],
  totalObjects: 0,
  aggregationsProviderId: [],
  aggregationsClassificaitions: [],
  dashboardInsights: [],
  latestInsights: {},
  insightsFilters: {
    labels: [],
    selectedInsight: {},
    providerIds: [],
  },
};

export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_INSIGHTS_AGGREGATIONS:
      const accounts = _.map(
        payload.aggregations?.classifications?.buckets || [],
        (item) => {
          return item?.accounts?.buckets;
        }
      );

      return {
        ...state,
        aggregationsClassificaitions:
          payload.aggregations?.classifications?.buckets,
        aggregationsProviderId: !_.isEmpty(accounts)
          ? _.uniqBy(_.flatten(accounts), "key")
          : [],
      };

    case GET_INSIGHTS_ASSETS:
      return {
        ...state,
        responseObjects: payload.responseObjects,
        totalObjects: payload.totalObjects,
      };

    case GET_DASHBOARD_INSIGHTS:
      return {
        ...state,
        dashboardInsights: payload?.data,
      };

    case GET_LATEST_INSIGHTS:
      return {
        ...state,
        latestInsights: payload?.data,
      };

    case SET_INSIGHTS_FILTERS:
      return {
        ...state,
        insightsFilters: payload,
      };

    default:
      return state;
  }
};
