import React, { useState } from "react";
import { CopyToClipboard } from "react-copy-to-clipboard";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./minimalCopy.scss";

const MinimalCopy = ({ text, purple }) => {
  const [isCopied, setIsCopied] = useState(false);

  const onCopyClicked = () => {
    setIsCopied(true);
    setTimeout(() => {
      setIsCopied(false);
    }, 1500);
  };

  return (
    <div className={`MinimalCopy ${purple ? "purple" : ""}`}>
      <CopyToClipboard text={text} onCopy={onCopyClicked}>
        {isCopied ? (
          <FontAwesomeIcon icon="check" className="MinimalCopy__icon active" />
        ) : (
          <FontAwesomeIcon icon="copy" className="MinimalCopy__icon" />
        )}
      </CopyToClipboard>
    </div>
  );
};

export default MinimalCopy;
