import React from "react";
import { useSelector } from "react-redux";
import Collapse from "antd/lib/collapse";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import map from "lodash/map";
import { ReactComponent as YakStatusIcon } from "../../../Images/general_icons/yak_status.svg";
import { ReactComponent as CloudStatusIcon } from "../../../Images/general_icons/cloud_status.svg";
import { ReactComponent as BackendsIcon } from "../../../Images/general_icons/backends.svg";
import { ReactComponent as CloudIcon } from "../../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as Chevron } from "../../../Images/general_icons/chevron.svg";
import { ReactComponent as TypesIcon } from "../../../Images/general_icons/layers_outline.svg";
import { ReactComponent as Repository } from "../../../Images/general_icons/repository.svg";
import { ReactComponent as LocationIcon } from "../../../Images/general_icons/location_outline.svg";
import { ReactComponent as TfVersionIcon } from "../../../Images/general_icons/Version_icon.svg";
import { ReactComponent as ProvidersIcon } from "../../../Images/general_icons/Providers_icon.svg";
import { ReactComponent as ModulesIcon } from "../../../Images/general_icons/Modules_icon.svg";
import { ReactComponent as RegionIcon } from "../../../Images/general_icons/Region_icon.svg";
import { ReactComponent as RuntimeIcon } from "../../../Images/general_icons/Runtime_icon.svg";
import { ReactComponent as StatusIcon } from "../../../Images/general_icons/Status_icon.svg";
import IconBtn from "../../../shared/iconBtn/iconBtn";
import InventorySingelFilter from "../../inventory/inventoryFilters/inventorySingelFilter";

import { filter, keys } from "lodash";
import { TITLES } from "../../../consts/general";
import "./iacStacksFilters.scss";

const IacStacksFilters = ({
  activeIacStatuses,
  activeProviderStatuses,
  activeBackends,
  activeProviderIntegrations,
  activeAssetTypes,
  activeClusters,
  activeNamespaces,
  activeTfVersions,
  activeRepositories,
  activeProvidersFullType,
  activeSourceModules,
  activeCftRegions,
  activeCftRuntime,
  activeCftStateStatus,
  handleClearAllFilters,
  resetFilters,
  assetTypes,
  repositories,
  iacStatuses,
  cloudformationIacStatuses,
  clusters,
  namespaces,
  iacStatusSelected,
  tfVersionsSelected,
  providersFullTypeSelected,
  sourceModulesSelected,
  providerStatuses,
  providerStatusSelected,
  cftStateStatusesSelected,
  cftRegionsSelected,
  cftRuntimeSelected,
  backends,
  backendsSelected,
  providerIntegrations,
  cftStateStatuses,
  cftRegions,
  cftRuntime,
  providerIntegrationsSelected,
  assetTypesSelected,
  repositoriesSelected,
  clustersSelected,
  namespacesSelected,
  selectedIacType,
  terraformVersions,
  sourceModules,
  providersFullType,
  activePanel,
  setActivePanel
}) => {
  const { Panel } = Collapse;
  
  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.iacStacksFilters
  );

  const getActiveObjectNumber = (activeObj) => {
    let num = 0;
    map(values(activeObj), (arr) => {
      num += arr?.length;
    });
    return num;
  };

  const handleCutsomArrow = (e) => {
    return (
      <Chevron
        style={{
          transform: e.isActive
            ? "translateY(2px)" : "rotate(-90deg) translateY(2px)",
          transition: "all .2s ease",
          width: "10px",
        }}
      />
    );
  };

  const filterIcons = {
    terraform: {
      yakStatus: (
        <YakStatusIcon
          className={`IacStacksFilters__item-filterIcon-icon ${
            !values(activeIacStatuses).every(isEmpty) ||
            activePanel?.includes("1")
              ? "classificationActive"
              : "classification"
          }`}
        />
      ),
      cloud: (
        <CloudIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeProviderIntegrations).every(isEmpty) ||
            activePanel?.includes("2")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      cloudStatus: (
        <CloudStatusIcon
          className={`IacStacksFilters__item-filterIcon-icon ${
            !values(activeProviderStatuses).every(isEmpty) ||
            activePanel?.includes("3")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      backends: (
        <BackendsIcon
          className={`IacStacksFilters__item-filterIcon-icon ${
            !isEmpty(activeBackends) || activePanel?.includes("4")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      types: (
        <TypesIcon
          className={`IacStacksFilters__item-filterIcon-icon asset-type-icon ${
            !isEmpty(activeAssetTypes) || activePanel?.includes("5")
              ? "classificationActive"
              : "classification"
          }`}
        />
      ),
      repositories: (
        <Repository
          className={`IacStacksFilters__item-filterIcon-icon ${
            !isEmpty(activeAssetTypes) || activePanel?.includes("6")
              ? "emptyIconActive" 
               : "emptyIcon"
          }`}
        />
      ),
      tfVersions: (
        <TfVersionIcon
          className={`IacStacksFilters__item-filterIcon-icon asset-type-icon ${
            !isEmpty(activeAssetTypes) || activePanel?.includes("7")
             ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      providers: (
        <ProvidersIcon
          className={`IacStacksFilters__item-filterIcon-icon asset-type-icon ${
            !isEmpty(activeAssetTypes) || activePanel?.includes("8")
             ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      modules: (
        <ModulesIcon
          className={`IacStacksFilters__item-filterIcon-icon asset-type-icon ${
            !isEmpty(activeAssetTypes) || activePanel?.includes("9")
             ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
    },
    helm: {
      cloud: (
        <CloudIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeClusters).every(isEmpty) || activePanel?.includes("1")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      location: (
        <LocationIcon
          className={`IacStacksFilters__item-filterIcon-icon asset-type-icon ${
            !isEmpty(activeNamespaces) || activePanel?.includes("2")
              ? "classificationActive"
              : "classification"
          }`}
        />
      ),
    },
    cloudformation: {
      yakStatus: (
        <YakStatusIcon
          className={`IacStacksFilters__item-filterIcon-icon ${
            !values(activeIacStatuses).every(isEmpty) ||
            activePanel?.includes("3")
              ? "classificationActive"
              : "classification"
          }`}
        />
      ),
      cloud: (
        <CloudIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeProviderIntegrations).every(isEmpty) ||
            activePanel?.includes("1")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      status: (
        <StatusIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeCftStateStatus).every(isEmpty) ||
            activePanel?.includes("2")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      region: (
        <RegionIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeCftRegions).every(isEmpty) ||
            activePanel?.includes("4")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
      runtime: (
        <RuntimeIcon
          className={`IacStacksFilters__item-filterIcon-icon provider-type-icon ${
            !values(activeCftRuntime).every(isEmpty) ||
            activePanel?.includes("5")
              ? "emptyIconActive"
              : "emptyIcon"
          }`}
        />
      ),
    },
  };

  const panelHeader = (title, activeArr, idx, icon, iacType, activeObj) => {
    return (
      <div className="IacStacksFilters__item row">
        {filterIcons[iacType] ? filterIcons[iacType][icon] : null}
        <span
          className={`IacStacksFilters__item-title row ${
            !isEmpty(activeArr) ||
            !values(activeObj).every(isEmpty) ||
            activePanel?.includes(idx)
              ? "active"
              : ""
          }`}
        >
          <span>{title}</span>
          {!isEmpty(activeArr) && (
            <span className="purple-text">{`(${activeArr?.length})`}</span>
          )}
          {!values(activeObj).every(isEmpty) &&
            getActiveObjectNumber(activeObj) > 0 && (
              <span className="purple-text">{`(${getActiveObjectNumber(
                activeObj
              )})`}</span>
            )}
        </span>
      </div>
    );
  };

  const collapses = {
    terraform: (
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel
          id="yakStatus"
          header={panelHeader(
            "IaC Status",
            activeIacStatuses,
            "1",
            "yakStatus",
            "terraform"
          )}
          key="1"
        >
          <InventorySingelFilter
            allTypes={iacStatuses}
            selectedTypes={iacStatusSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "1"}
            checkedList={activeIacStatuses}
          />
        </Panel>
        <Panel
          header={panelHeader(
            TITLES.dataSource,
            activeProviderIntegrations,
            "2",
            "cloud",
            "terraform"
          )}
          key="2"
        >
          <InventorySingelFilter
            allTypes={providerIntegrations}
            selectedTypes={providerIntegrationsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "2"}
            checkedList={activeProviderIntegrations}
          />
        </Panel>
        <Panel
          header={panelHeader(
            TITLES.dataSourceStatus,
            activeProviderStatuses,
            "3",
            "cloudStatus",
            "terraform"
          )}
          key="3"
        >
          <InventorySingelFilter
            allTypes={providerStatuses}
            selectedTypes={providerStatusSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "3"}
            checkedList={activeProviderStatuses}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Backend",
            activeBackends,
            "4",
            "backends",
            "terraform"
          )}
          key="4"
        >
          <InventorySingelFilter
            allTypes={backends}
            selectedTypes={backendsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "4"}
            checkedList={activeBackends}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Asset Type",
            activeAssetTypes,
            "5",
            "types",
            "terraform"
          )}
          key="5"
        >
          <InventorySingelFilter
            allTypes={assetTypes}
            selectedTypes={assetTypesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "5"}
            checkedList={activeAssetTypes}
          />
        </Panel>
        <Panel
            header={panelHeader(
                "Repository",
                activeRepositories,
                "6",
                "repositories",
                "terraform"
            )}
            key="6"
        >
          <InventorySingelFilter
              allTypes={repositories}
              selectedTypes={repositoriesSelected}
              reset={resetFilters}
              title="Search"
              active={activePanel === "6"}
              checkedList={activeRepositories}
          />
        </Panel>
        <Panel
          id="tfVersionPanel"
          header={panelHeader(
            "TF Versions",
            activeTfVersions,
            "7",
            "tfVersions",
            "terraform"
          )}
          key="7"
        >
          <InventorySingelFilter
            allTypes={terraformVersions}
            selectedTypes={tfVersionsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "7"}
            checkedList={activeTfVersions}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Providers",
            activeProvidersFullType,
            "8",
            "providers",
            "terraform"
          )}
          key="8"
        >
          <InventorySingelFilter
            allTypes={providersFullType}
            selectedTypes={providersFullTypeSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "8"}
            checkedList={activeProvidersFullType}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Modules",
            activeSourceModules,
            "9",
            "modules",
            "terraform"
          )}
          key="9"
        >
          <InventorySingelFilter
            allTypes={sourceModules}
            selectedTypes={sourceModulesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "9"}
            checkedList={activeSourceModules}
          />
        </Panel>
      </Collapse>
    ),
    helm: (
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel
          header={panelHeader("Clusters", activeClusters, "1", "cloud", "helm")}
          key="1"
        >
          <InventorySingelFilter
            allTypes={clusters}
            selectedTypes={clustersSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "1"}
            checkedList={activeClusters}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Namespaces",
            activeNamespaces,
            "2",
            "location",
            "helm"
          )}
          key="2"
        >
          <InventorySingelFilter
            allTypes={namespaces}
            selectedTypes={namespacesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "2"}
            checkedList={activeNamespaces}
          />
        </Panel>
      </Collapse>
    ),
    cloudformation: (
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel
          header={panelHeader("AWS account", activeProviderIntegrations, "1", "cloud", "cloudformation")}
          key="1"
        >
          <InventorySingelFilter
            allTypes={providerIntegrations}
            selectedTypes={providerIntegrationsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "1"}
            checkedList={activeProviderIntegrations}
          />
        </Panel>
        <Panel
          header={panelHeader("Status", activeCftStateStatus, "2", "status", "cloudformation")}
          key="2"
        >
          <InventorySingelFilter
            allTypes={cftStateStatuses}
            selectedTypes={cftStateStatusesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "2"}
            checkedList={activeCftStateStatus}
          />
        </Panel>
        <Panel
          header={panelHeader("Iac Status", activeIacStatuses, "3", "yakStatus", "cloudformation")}
          key="3"
        >
          <InventorySingelFilter
            allTypes={cloudformationIacStatuses}
            selectedTypes={iacStatusSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "3"}
            checkedList={activeIacStatuses}
          />
        </Panel>
        <Panel
          header={panelHeader("Region", activeCftRegions, "4", "region", "cloudformation")}
          key="4"
        >
          <InventorySingelFilter
            allTypes={cftRegions}
            selectedTypes={cftRegionsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "4"}
            checkedList={activeCftRegions}
          />
        </Panel>
        <Panel
          header={panelHeader("Language", activeCftRuntime, "5", "runtime", "cloudformation")}
          key="5"
        >
          <InventorySingelFilter
            allTypes={cftRuntime}
            selectedTypes={cftRuntimeSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "5"}
            checkedList={activeCftRuntime}
          />
        </Panel>
      </Collapse>
    ),
    pulumi: (
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel
          header={panelHeader(
            "IaC Status",
            activeIacStatuses,
            "1",
            "yakStatus",
            "terraform"
          )}
          key="1"
        >
          <InventorySingelFilter
            allTypes={iacStatuses}
            selectedTypes={iacStatusSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "1"}
            checkedList={activeIacStatuses}
          />
        </Panel>
        <Panel
          header={panelHeader(
            TITLES.dataSource,
            activeProviderIntegrations,
            "2",
            "cloud",
            "terraform"
          )}
          key="2"
        >
          <InventorySingelFilter
            allTypes={providerIntegrations}
            selectedTypes={providerIntegrationsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "2"}
            checkedList={activeProviderIntegrations}
          />
        </Panel>
        <Panel
          header={panelHeader(
            TITLES.dataSourceStatus,
            activeProviderStatuses,
            "3",
            "cloudStatus",
            "terraform"
          )}
          key="3"
        >
          <InventorySingelFilter
            allTypes={providerStatuses}
            selectedTypes={providerStatusSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "3"}
            checkedList={activeProviderStatuses}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Backend",
            activeBackends,
            "4",
            "backends",
            "terraform"
          )}
          key="4"
        >
          <InventorySingelFilter
            allTypes={backends}
            selectedTypes={backendsSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "4"}
            checkedList={activeBackends}
          />
        </Panel>
        <Panel
          header={panelHeader(
            "Asset type",
            activeAssetTypes,
            "5",
            "types",
            "terraform"
          )}
          key="5"
        >
          <InventorySingelFilter
            allTypes={assetTypes}
            selectedTypes={assetTypesSelected}
            reset={resetFilters}
            title="Search"
            active={activePanel === "5"}
            checkedList={activeAssetTypes}
          />
        </Panel>
      </Collapse>
    )
  };

  const checkShouldShowClearAll = () => {
    let shouldShow = false;
    let notEmptyFilterKeys = filter(keys(screenFilters), filterKey => !isEmpty(screenFilters[filterKey]));
    if (!isEmpty(notEmptyFilterKeys)) {
      if (notEmptyFilterKeys?.includes("stateType") && notEmptyFilterKeys?.length === 1) {
        shouldShow = false;
      } else {
        shouldShow = true;
      }
    }
    return shouldShow;
  }

  return (
    <div className="IacStacksFilters">
      <div className="IacStacksFilters__header row between">
        <span className="IacStacksFilters__header-title">Filters</span>
        {checkShouldShowClearAll() ? (
          <div className="IacStacksFilters__header-actions row">
            <IconBtn
              onClick={handleClearAllFilters}
              type="clear"
              colorClass="filter"
              tooltipText="Clear all"
            />
          </div>
        ) : null}
      </div>
      {collapses[selectedIacType]}
    </div>
  );
};

export default IacStacksFilters;
