import React from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import "./appSwitch.scss";

const AppSwitch = ({ onChange, active, items }) => {
  const renderItems = () => {
    if (!_.isEmpty(items)) {
      return _.map(items || [], (item = {}) => {
        const { value, text, tooltip, disabled = false } = item;
        return (
          <Tooltip placement="bottom" title={tooltip} key={value}>
            <div
              className={`AppSwitch__slice center ${
                value === active ? "active" : ""
              }${disabled ? "disabled" : ""}`}
              onClick={() => !disabled ? onChange(value) : null}
            >
              {text}
            </div>
          </Tooltip>
        );
      });
    }
    return null;
  };

  return <div className="AppSwitch row">{renderItems()}</div>;
};

export default AppSwitch;
