import {
  GET_ALL_KEYPAIRS,
  CREATE_KEYPAIR,
  DELETE_KEYPAIR,
  UPDATE_KEYPAIR,
  GET_INSTRUCTIONS_CODE,
} from "../actions/keyPairsActions";

const initialState = {
  keyPairs: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_ALL_KEYPAIRS:
      return { ...state, keyPairs: payload.keyPairs };

    case CREATE_KEYPAIR:
      return { ...state };

    case DELETE_KEYPAIR:
      return { ...state };

    case UPDATE_KEYPAIR:
      return { ...state };

    case GET_INSTRUCTIONS_CODE:
      return { ...state };

    default:
      return state;
  }
}
