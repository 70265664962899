import {
  GET_CLASSIFICATIONS,
  DELETE_CLASSIFICATION,
  UPDATE_CLASSIFICATION,
  CREATE_CLASSIFICATION,
  GET_TOTAL_COST,
  GET_SCOPES,
  SET_CATEGORIES,
  TOGGLE_INSIGHT_ENABLE,
  GET_WIZARD_ASSETS
} from "../actions/classificationsActions";
import _ from "lodash";

const initialState = {
  classifications: [],
  totalCost: 0,
  scopes: [],
  categories: [],
  wizardAssets: [],
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_CLASSIFICATIONS:
      return { ...state, classifications: _.orderBy(payload.data || [], 'isEnabled', 'desc')};

    case DELETE_CLASSIFICATION:
      return { ...state };

    case UPDATE_CLASSIFICATION:
      return { ...state };

    case CREATE_CLASSIFICATION:
      return { ...state };

    case GET_TOTAL_COST:
      return { ...state, totalCost: payload.data };

    case GET_SCOPES:
      return { ...state, scopes: payload };

    case SET_CATEGORIES:
      return { ...state, categories: payload };

    case GET_WIZARD_ASSETS:
      return { ...state, wizardAssets: payload };

    case TOGGLE_INSIGHT_ENABLE:
      let newCategories = _.map(state.classifications, item => {
        if(item?.id === payload) {
          return { ...item, isEnabled: !item?.isEnabled}
        }
        return item;
      });
      return { ...state, classifications: newCategories };

    default:
      return state;
  }
};
