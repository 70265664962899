import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";

const DevOpsForm = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, token } = formValues;

    if (_.isEmpty(name) || _.isEmpty(token)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="DevOpsForm col">
      <div className="DevOpsForm__intro col">
        <span className="dark-blue bold">
          Procedure
        </span>
        <ol className="DevOpsForm__intro-orderedlist">
          <li key="1">
          Log in to your Azure DevOps organization and follow their instructions to{" "}
          <a
              target="_blank"
              rel="noreferrer"
              href="https://learn.microsoft.com/en-us/azure/devops/organizations/accounts/use-personal-access-tokens-to-authenticate?view=azure-devops&tabs=Windows#create-a-pat"
            >
              create a new PAT
            </a>.
            <ul className="DevOpsForm__intro-orderedlist-sub">
              <li key="a">
                Select <span className="bold">All accessible organizations</span>.
              </li>
              <li key="b">
                In <span className="bold">Scopes</span>, select <span className="bold">Custom defined</span>.
              </li>
              <li key="c">
                In <span className="bold">Code</span>, select <span className="bold">Read & manage</span>.
              </li>
              <li key="d">
                Copy the token.
              </li>
            </ul>
          </li>
        
          <li key="2">
            Paste the token into the <span className="bold">Access Token</span> field below.
          </li>
          <li key="3">
            To facilitate the scanning of your organization,{" "}
            <a
              target="_blank"
              rel="noreferrer"
              href="https://marketplace.visualstudio.com/items?itemName=ms.vss-code-search&targetId=57a5b06e-9245-4c5d-8d32-60b67b7c7ca1&utm_source=vstsproduct&utm_medium=SearchExtStatus"
            >
              install Code Search
            </a> for each organization.
          </li>
          <li key="4">
            Enter a descriptive name in the <span className="bold">Nickname</span> field.
          </li>
          <li key="5">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="devops-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Azure DevOps Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Access Token"
          name="token"
          rules={[
            {
              required: true,
              message: "Access Token is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default DevOpsForm;
