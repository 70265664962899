import action from "../middleware";

import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";

export const TOGGLE_ENTER_ORCHESTRATOR = "USER_ENTER_ORCHESTRATOR";
export const GET_ORCHES_BY_ID = "GET_ORCHES_BY_ID";
export const CREATE_ORCHESTRATOR = "CREATE_ORCHESTRATOR";
export const CONFIG_ORCHESTRATOR = "CONFIG_ORCHESTRATOR";
export const DELETE_ORCHESTRATOR = "DELETE_ORCHESTRATOR";
export const GET_ORCHESTRATORS = "GET_ORCHESTRATORS";
export const GET_RUNS = "GET_RUNS";
export const TRIGGER_RUN = "TRIGGER_RUN";
export const GET_RUN_BY_ID = "GET_RUN_BY_ID";
export const GET_RUN_STAGES = "GET_RUN_STAGES";
export const GET_RUN_SUB_STAGES = "GET_RUN_SUB_STAGES";
export const GET_RUN_SUB_STAGES_ARTIFACTS = "GET_RUN_SUB_STAGES_ARTIFACTS";
export const GET_VARIABLES = "GET_VARIABLES";
export const DELETE_VARIABLES = "DELETE_VARIABLES";
export const CREATE_VARIABLE = "CREATE_VARIABLE";
export const UPDATE_VARIABLE = "UPDATE_VARIABLE";
export const APPLY_ORCHESTRATOR = "APPLY_ORCHESTRATOR";
export const GET_POLICIES = 'GET_POLICIES';
export const ADD_POLICY = 'ADD_POLICY';
export const DELETE_POLICY = 'DELETE_POLICY';

const toggleEnterOrchestrator = (isOrchestrator, envData) => {
  return action(async (dispatch) => {
    dispatch({
      type: TOGGLE_ENTER_ORCHESTRATOR,
      payload: { isOrchestrator, envData },
    });
  });
};

const getOrchestratorById = (orchestratorId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_ORCHES_BY_ID,
      payload: { data },
    });

    return data;
  });
};

const getRunById = (orchestratorId, runId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs/${runId}`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_RUN_BY_ID,
      payload: { },
    });

    return data;
  });
};

const createOrchestrator = (name, owner, labels) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      owner,
      labels,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments`,
      "POST",
      payload
    );

    dispatch({
      type: CREATE_ORCHESTRATOR,
      payload: {},
    });
    const json = await req.json();
    return { environment: json, req: req };
  });
};

const configOrchestrator = (
  orchestratorId,
  isTerragrunt,
  autoApprove,
  terraformVersion,
  vcs,
  name,
  owner,
  policy
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      isTerragrunt,
      autoApprove,
      terraformVersion,
      vcs,
      name,
      owner,
      policy
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}`,
      "PUT",
      payload
    );

    const data = await req.json();

    dispatch({
      type: CONFIG_ORCHESTRATOR,
      payload: { data },
    });
    return { ok: req.ok, environment: data };
  });
};

const getOrchestrators = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_ORCHESTRATORS,
      payload: { data },
    });
  });
};

const deleteOrchestrator = (orchestratorId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}`,
      "DELETE"
    );

    const data = await req.json();

    dispatch({
      type: DELETE_ORCHESTRATOR,
      payload: { data },
    });
  });
};

const getVariables = (orchestratorId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/variables`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_VARIABLES,
      payload: { data },
    });
  });
};

const deleteVariables = (orchestratorId, variableId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/variables/${variableId}`,
      "DELETE"
    );

    const data = await req.json();

    dispatch({
      type: DELETE_VARIABLES,
      payload: { data },
    });
  });
};

const createVariable = (orchestratorId, payload) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/variables`,
      "POST",
      payload
    );

    const data = await req.json();

    dispatch({
      type: CREATE_VARIABLE,
      payload: { data },
    });
    return req;
  });
};

const updateVariable = (orchestratorId, payload, varId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/variables/${varId}`,
      "PUT",
      payload
    );

    const data = await req.json();

    dispatch({
      type: UPDATE_VARIABLE,
      payload: { data },
    });
    return req;
  });
};

const getRuns = (orchestratorId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_RUNS,
      payload: { data },
    });
    
    return req;
  });
};

const triggerRun = (orchestratorId, description, userId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      description,
      userId,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs`,
      "POST",
      payload
    );
    const data = await req.json();
      dispatch({
        type: TRIGGER_RUN,
        payload: { data },
      });
      return data;
  });
};

const getRunStages = (orchestratorId, runId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs/${runId}/stages`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_RUN_STAGES,
      payload: { data },
    });
    return { ok: req.ok, runs: data };
  });
};

const getRunSubStages = (orchestratorId, runId, stageId, type) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    // optional types : substages || artifacts

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs/${runId}/stages/${stageId}/${type}`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_RUN_SUB_STAGES,
      payload: { data },
    });

    return { ok: req.ok, data: data };
  });
};

const getRunSubStagesArtifact = (orchestratorId, runId, stageId, subId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    // optional types : substages || artifacts

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs/${runId}/stages/${stageId}/substages/${subId}/artifacts`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_RUN_SUB_STAGES_ARTIFACTS,
      payload: { data },
    });
    return { ok: req.ok, data: data };
  });
};

const applyOrchestrator = (orchestratorId, runId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/runs/${runId}/apply`,
      "POST"
    );

    const data = await req.json();

    dispatch({
      type: APPLY_ORCHESTRATOR,
      payload: { data },
    });
    return { ok: req.ok, data: data };
  });
};

const getPolicies = (orchestratorId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/policies`,
      "GET"
    );

    const data = await req.json();

    dispatch({
      type: GET_POLICIES,
      payload: { data },
    });
  });
};

const addPolicy = (orchestratorId, ruleId, isPostPlan) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      ruleId,
      isPostPlan,
    };

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/policies`,
      "POST",
      payload
    );

    dispatch({
      type: ADD_POLICY,
      payload: {},
    });

    const json = await req.json();
    return { ok: req.ok, policy: json };
  });
};

const deletePolicy = (orchestratorId, policyId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/dragonfly/environments/${orchestratorId}/policies/${policyId}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_POLICY,
      payload: { },
    });

    const json = await req.json();
    return { ok: req.ok, policy: json };
  });
};

export {
  toggleEnterOrchestrator,
  getOrchestratorById,
  createOrchestrator,
  configOrchestrator,
  getOrchestrators,
  deleteOrchestrator,
  getVariables,
  deleteVariables,
  createVariable,
  updateVariable,
  getRunById,
  getRuns,
  triggerRun,
  getRunStages,
  getRunSubStages,
  getRunSubStagesArtifact,
  applyOrchestrator,
  getPolicies,
  addPolicy,
  deletePolicy
};
