import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { handleToggleProvisioning } from "../../../redux/actions/globalAppActions";
import { useTranslation } from "react-i18next";
import { Tooltip } from "antd";
import { ReactComponent as ComposeIcon } from "../../../Images/general_icons/sidebar/compose.svg";
import "./provisioningBtn.scss";

const ProvisioningBtn = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("sidebar");
  const sidebarClosed = useSelector(
    (state) => state.userPreferencesReducer.sidebarClosed
  );

  return (
    <div
      className={`ProvisioningBtn ${sidebarClosed ? "minimal" : ""}`}
      onClick={() => dispatch(handleToggleProvisioning())}
    >
      <div>
        <button>
          <Tooltip title={sidebarClosed ? t("compose") : ''} placement="right">
           <ComposeIcon />
          </Tooltip>
          {!sidebarClosed && <span>{t("compose")}</span>}
        </button>
      </div>
    </div>
  );
};

export default ProvisioningBtn;
