import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Collapse } from "antd";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import has from "lodash/has";
import {
  formatAwsStringWithUnderscore,
  formatIacStatus,
} from "../../../utils/formatting";
import InventorySingelFilter from "../../inventory/inventoryFilters/inventorySingelFilter";
import DriftTypeIcon from "../driftTypeIcon/driftTypeIcon";
import { ReactComponent as Chevron } from "../../../Images/general_icons/chevron.svg";
import { clouds } from "../../../utils/icons";
import "./driftFilters.scss";
import { useTranslation } from "react-i18next";

const DriftFilters = ({
  activeProviders,
  activeState,
  activeResourceTypes,
  activeInsights,
  setFilterEventType,
  setSelecetProviders,
  setSelectedResourceTypes,
  setSelectedInsights,
}) => {
  const { t } = useTranslation('event-explorer')
  const { Panel } = Collapse;
  const [activePanel, setActivePanel] = useState(["1"]);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const aggAssetType = useSelector(
    (state) => state.driftsReducer?.aggAssetType
  );
  const aggProvider = useSelector((state) => state.driftsReducer?.aggProvider);
  const aggState = useSelector((state) => state.driftsReducer?.aggState);
  const aggsInsights = useSelector(
    (state) => state.driftsReducer?.aggsInsights
  );

  useEffect(() => {
    setActivePanel(["1"]);
  }, []);

  // render intengraion name using it's id.
  const renderIntegrationNameById = (match) => {
    const result = has(match, "name")
      ? match?.name
      : match?.clusterId
        ? match?.clusterId
        : "Unknown";
    return result
  };

  const handleArrageFilterTypes = () => {
    let outputArr = [];
    if (!isEmpty(aggAssetType)) {
      map(aggAssetType, (val) => {
        return outputArr.push({
          name: val?.key,
          formattedName: formatAwsStringWithUnderscore(val?.key),
          count: val?.doc_count,
        });
      });
    }
    return outputArr;
  };

  const getIntegrationDict = (providerIntegrationsObject) => {
    const integrationsDict = {};
    for (const [provider, integrations] of Object.entries(providerIntegrationsObject)) {
      for (const integration of integrations) {
        integration['provider'] = provider;
        if (integration.id) {
          integrationsDict[integration.id] = integration;
        }
      }
    }
    return integrationsDict
  }

  const handleArrageProviders = () => {

    const outputArr = [];
    if (!isEmpty(aggProvider)) {
        const integrations = getIntegrationDict(providerIntegrations);

        map(aggProvider, (val) => {
          const match = integrations[val?.key]
          const providerType = !isEmpty(match) ? match.provider : "none"

          return outputArr.push({
            name: val?.key,
            formattedName: renderIntegrationNameById(match),
            count: val?.doc_count,
            icon: clouds(providerType, themeDark),
            isProdFlag: !isEmpty(match) && match?.isProd,
            provider: providerType
          });
        });
      }
    return outputArr;
  };

  const handleArrageIacStatus = () => {
    let outputArr = [];
    if (!isEmpty(aggState)) {
      map(aggState, (val) => {
        return outputArr.push({
          name: val?.key,
          formattedName: formatIacStatus(val?.key),
          count: val?.doc_count,
          element: <DriftTypeIcon type={val?.key} small />,
        });
      });
    }
    return outputArr;
  };

  const handleArrageInsights = () => {
    let outputArr = [];
    if (!isEmpty(aggsInsights)) {
      map(aggsInsights, (val) => {
        return outputArr.push({
          name: val?.key,
          formattedName: val?.key,
          count: val?.doc_count,
        });
      });
    }
    return outputArr;
  };

  const handleCutsomArrow = (e) => {
    return (
      <Chevron
        style={{
          transform: e.isActive
            ? "translateY(2px)" : "rotate(-90deg) translateY(2px)",
          transition: "all .2s ease",
          width: "10px",
        }}
      />
    );
  };

  const panelHeader = (title, activeArr, idx) => {
    return (
      <div className="InventoryFilters__item row">
        <span
          className={`InventoryFilters__item-title row ${!isEmpty(activeArr) || activePanel?.includes(idx) ? 'active' : ''
            }`}
        >
          <span>{title}</span>
          {!isEmpty(activeArr) && (
            <span className="purple-text">{`(${activeArr?.length})`}</span>
          )}
        </span>
      </div>
    );
  };

  return (
    <div className="DriftFilters">
      <Collapse
        expandIconPosition="right"
        expandIcon={handleCutsomArrow}
        activeKey={activePanel}
        onChange={(panel) => setActivePanel(panel)}
        accordion
      >
        <Panel header={panelHeader(t('filters.event-type'), activeState, "1")} key="1">
          <InventorySingelFilter
            allTypes={handleArrageIacStatus()}
            selectedTypes={setFilterEventType}
            checkedList={activeState}
            title={t('filters.search')}
            active={activePanel === "1"}
            noAnimation
          />
        </Panel>
        <Panel header={panelHeader(t('filters.data-source'), activeProviders, "2")} key="2">
          <InventorySingelFilter
            allTypes={handleArrageProviders()}
            selectedTypes={setSelecetProviders}
            checkedList={activeProviders}
            title={t('filters.search')}
            active={activePanel === "2"}
            noAnimation
          />
        </Panel>
        <Panel
          header={panelHeader(t('filters.asset-type'), activeResourceTypes, "3")}
          key="3"
        >
          <InventorySingelFilter
            allTypes={handleArrageFilterTypes()}
            selectedTypes={setSelectedResourceTypes}
            title={t('filters.search')}
            active={activePanel === "3"}
            checkedList={activeResourceTypes}
          />
        </Panel>
        <Panel header={panelHeader(t('filters.insights'), activeInsights, "4")} key="4">
          <InventorySingelFilter
            allTypes={handleArrageInsights()}
            selectedTypes={setSelectedInsights}
            checkedList={activeInsights}
            title={t('filters.search')}
            active={activePanel === "4"}
          />
        </Panel>
      </Collapse>
    </div>
  );
};

export default DriftFilters;
