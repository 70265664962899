import React from "react";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import _ from 'lodash';

// components
import Loading from "../../../shared/loading/loading";
import AppEmpty from '../../../shared/appEmpty/appEmpty';


import "./terraformCodification.scss";

const TerraformCodification = ({ isFetchingIaC, iacData, language="hcl"}) => {

  if (isFetchingIaC) {
    return (
      <div className="full-page center">
        <Loading />
      </div>
    );
  }

  if (!isFetchingIaC && _.isEmpty(iacData)) {
    return (
      <div className="full-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  }

  if(!_.isEmpty(iacData)) {
    return (
      <div className="TerraformCodification">
        <SyntaxHighlighter
          style={atomDark}
          showLineNumbers={true}
          language={language}
          lineProps={{
            style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
          }}
        >
          {iacData}
        </SyntaxHighlighter>
        </div>
    );
  }
};

export default TerraformCodification;
