import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Form, Input } from "antd";
import _ from "lodash";
import { getOnPremiseIp } from "../../../redux/actions/integrationsActions";

import {
  emailRegex,
  nicknameRegex,
  validate,
} from "../../../utils/validations";

const JiraCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const { user } = useAuth0();
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const publicIps = useSelector((state) => state.integrationsReducer.publicIps);
  const [loadingIps, setLoadingIps] = useState(true);

  useEffect(() => {
    handleFetchOnpremIPS();
  }, []);

  const handleFetchOnpremIPS = async () => {
    setLoadingIps(true);
    await dispatch(getOnPremiseIp());
    setLoadingIps(false);
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, domain, mail, token } = formValues;

    if (
      _.isEmpty(name) ||
      _.isEmpty(domain) ||
      _.isEmpty(mail) ||
      _.isEmpty(token)
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    if (!_.isEmpty(mail) && !validate(emailRegex.regex, mail)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const renderIps = () => {
    if (!loadingIps && !_.isEmpty(publicIps)) {
      return (
        <div>
          <div className="form-label">
            If your Jira Cloud is secured by whitelisted IP addresses, allow
            external access to the IP addresses below:
          </div>
          <div className="row g5">
            {_.map(publicIps || [], (item) => {
              return (
                <span className="code-flag" key={item}>
                  {item}
                </span>
              );
            })}
          </div>
        </div>
      );
    }
  };

  return (
    <div className="JiraCreation col">
      <div className="JiraCreation__intro col">
        <span className="dark-blue bold">Procedure</span>
        <ol className="dark-blue JiraCreation__intro-orderedlist">
          <li key="1">
            <span className="dark-blue">Log in to your Jira account.</span>
          </li>
          <li key="2">
            <span className="dark-blue">
              Select <span className="bold">Create API token</span>.
            </span>
          </li>
          <li key="3">
            Add a label, and select <span className="bold">Create</span>.
          </li>
          <li key="4">Copy the API token.</li>
          <li key="5">
            Paste the token in the <span className="bold">Jira Token</span> box,
            and add the required information.
          </li>
          <li key="6">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>
      <Form
        {...layout}
        name="jira-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Jira Integration",
          mail: user?.email ? user.email : "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(changedValues, allValues) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <div className="JiraCreation__form">
          <Form.Item
            label="Nickname"
            name="name"
            rules={[
              {
                required: true,
                message: "Nickname is required",
              },
              {
                pattern: nicknameRegex.regex,
                message: nicknameRegex.msg,
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input placeholder="Please fill integration name" />
          </Form.Item>
          <div className="row" style={{ gap: "5px" }}>
            <Form.Item
              label="Jira Domain"
              name="domain"
              rules={[
                {
                  required: true,
                  message: "Domain is required",
                },
              ]}
              style={{
                marginBottom: "1.5rem",
                flexDirection: "column",
                width: "70%",
              }}
            >
              <Input placeholder="<DomainName>" />
            </Form.Item>
            <span
              style={{ transform: "translateY(-2px)" }}
              className="form-label"
            >{`.atlassian.net`}</span>
          </div>
          <Form.Item
            label="Jira User Email"
            name="mail"
            rules={[
              {
                required: true,
                message: "Mail is required",
              },
              {
                pattern: emailRegex.regex,
                message: emailRegex.msg,
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input placeholder="Please fill Jira user email" />
          </Form.Item>
          <Form.Item
            label="Jira Token"
            name="token"
            rules={[
              {
                required: true,
                message: "Jira token is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
            <Input.Password />
          </Form.Item>
        </div>

        {renderIps()}
      </Form>
    </div>
  );
};

export default JiraCreation;
