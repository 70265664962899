import React from "react";
import _ from "lodash";
import BasicInfo from "../../assetInfo/basicInfo";
import Insights from "../../assetInfo/insights/insights";
import VcsCode from "../../assetInfo/vcsCode/vcsCode";
import AssetInfoCard from "../../assetInfo/assetInfoCard";

const RevisionInfo = ({ asset_revision }) => {
  return (
    <div className="RevisionInfo col">
      <BasicInfo
        asset={asset_revision}
        handleOpenAnalysis={() => {
          return false;
        }}
        onClickTag={() => {
          return false;
        }}
        parentData={null}
      />
      {!_.isEmpty(asset_revision?.classifications) && (
        <AssetInfoCard
        contentArr={[
          {
            label: "Insights",
            content: <Insights classificationIds={asset_revision?.classifications} disableLink />,
            display: !_.isEmpty(asset_revision?.classifications),
          },
        ]}
      />
      )}
      {(!_.isEmpty(asset_revision?.vcsCodeLink) ||
        !_.isEmpty(asset_revision?.vcsRepo) ||
        !_.isEmpty(asset_revision?.vcsProvider)) && (
        <VcsCode asset={asset_revision} />
      )}
    </div>
  );
};

export default RevisionInfo;
