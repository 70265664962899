import React from "react";
import isEmpty from "lodash/isEmpty";
import { Link } from "react-router-dom";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";

// components
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";

import "./providerAccountsTable.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useDispatch } from "react-redux";

const ProviderAccountsTable = ({
  children,
  selectedIacType,
  setSelectedCategory,
  setFilters,
}) => {
    const dispatch = useDispatch();
  const statuses = {
    notSupported: "Not Supported",
    notIntegrated: "Not Integrated",
    integrated: "Integrated",
  };

  const columns = [
    {
      headerName: "Provider ID",
      field: "providerId",
      flex: 1,
      renderCell: (params) => {
        let accountId = params?.row?.providerId;
        if (isEmpty(accountId)) {
          // if the row is Provider Type
          if (isEmpty(params?.row?.status)) {
            accountId = "";
          } else {
            accountId = "Unknown";
          }
        }
        return accountId;
      },
    },
    {
      headerName: "Status",
      field: "status",
      flex: 1,
      renderCell: (params) => {
        return isEmpty(params?.row?.status) ? (
          ""
        ) : params?.row?.status === "notIntegrated" ? (
          <Link to={`/integrations/${params?.row?.link}-integration`}>
            + Add Integration
          </Link>
        ) : (
          statuses[params?.row?.status]
        );
      },
    },
    {
      headerName: "Number of state files",
      field: "numStacks",
      flex: 1,
      renderCell: (params) => {
        return isEmpty(params?.row?.numStacks?.toString()) ? (
          ""
        ) : (
          <div
            className="ProviderAccountsTable__flag row"
            onClick={async (e) => {
              e.stopPropagation();
              await dispatch(
                setFilters({
                  ...emptyIacScreenFilters,
                  stateType: [selectedIacType],
                  "stackProviders.providerAccountId": [params?.row?.providerId],
                  "stackProviders.providerType": [params?.row?.type],
                })
              );
              setSelectedCategory("appliedStacks");
            }}
          >
            <FontAwesomeIcon icon="file-code" />
            {params?.row?.numStacks?.toString()}
          </div>
        );
      },
    },
  ];

  return (
    <div className="ProviderAccountsTable center col">
      <TableWrapper
        autoHeight
        rowKey="id"
        tableData={children}
        columns={columns}
        disablePagination
        hideRowCount
        disableSelectionOnClick
        innerTable
      />
    </div>
  );
};

export default ProviderAccountsTable;
