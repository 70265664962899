import React from "react";
import _, { isEmpty } from "lodash";
import { Avatar, Tooltip } from "antd";
import Robot from "../../../Images/general_icons/owner_robot.svg";
import {
  getTimeFromDateString,
  renderEllipsis,
} from "../../../utils/formatting";
import gradientAvatar from "../../../Images/general_icons/gradient-avatar.svg";
import './ownerIndication.scss';

const TYPES = {
  iam: "IAMUser",
  role: "AssumedRole",
  email: "EMAILUser",
};

const toolTipInfo = (ownerData ,isCiRun) => {
  let firstOwnerToolTip;
  if (ownerData?.eventName === "GitCommit") {
    firstOwnerToolTip = (
      <span className="col">
        <span>
          Code Owner:{" "}
          <span className="bold">{ownerData?.userIdentity?.displayName}</span>
        </span>
        {!isEmpty(ownerData?.eventTime) &&
          new Date(ownerData?.eventTime)?.getFullYear() !== 1 && (
            <span>{`Commit Date: ${new Date(
              ownerData?.eventTime
            )?.toLocaleDateString()} ${getTimeFromDateString(
              ownerData?.eventTime
            )}`}</span>
          )}
      </span>
    );
  } else {
    firstOwnerToolTip = (
      <span className="col">
        <span>
          {isCiRun ? '' :`Event `}Owner:{" "}
          <span className="bold">{ownerData?.userIdentity?.displayName}</span>
        </span>
        {isCiRun ? <span>{ownerData?.userIdentity?.email ? `Email: ${ownerData?.userIdentity?.email}` : ""}</span>
          : <span>{`Event Name: ${ownerData?.eventName}`}</span>}
        {!isEmpty(ownerData?.eventTime) &&
          new Date(ownerData?.eventTime)?.getFullYear() !== 1 && (
            <span>{`Event Date: ${new Date(
              ownerData?.eventTime
            )?.toLocaleDateString()} ${getTimeFromDateString(
              ownerData?.eventTime
            )}`}</span>
          )}
      </span>
    );
  }

  let secondOwnerToolTip;
  if (!_.isEmpty(ownerData?.otherOwnerData)) {
    secondOwnerToolTip = toolTipInfo(ownerData?.otherOwnerData);
  }

  return (
    <span className="col">
      {firstOwnerToolTip}
      {secondOwnerToolTip}
    </span>
  );
};

const OwnerIndication = ({
  data,
  handleClickOwner,
  filterView,
  drawerView,
  isCiRun = false
}) => {
  const isCodeOwner =
    (data?.userIdentity?.displayName && data?.eventName === "GitCommit") ||
    (data?.otherOwnerData?.userIdentity?.displayName &&
      data?.otherOwnerData?.eventName === "GitCommit");

  const renderDifferentSrc = (data) => {
    switch (data?.userIdentity?.type) {
      case TYPES["iam"]:
        return null;
      case TYPES["role"]:
        return Robot;
      case TYPES["email"]:
        return null;

      default:
        return null;
    }
  };

  const renderDifferentTooltip = () => {
    if (filterView) {
      return data?.userIdentity?.displayName;
    }
    if (drawerView) {
      return null;
    }
    return toolTipInfo(data, isCiRun);
  };

  const baseAvatar = (data) => {
    return (
      <Tooltip
        placement="top"
        title={renderDifferentTooltip()}
        style={{ cursor: "pointer" }}
        overlayInnerStyle={{
          fontSize: "13px",
        }}
      >
        <div className="row" style={{ gap: "10px" }}>
          <div>
            <Avatar
              size="small"
              style={{
                backgroundColor: isCodeOwner
                  ? "rgba(152, 114, 254, 0)"
                  : data?.userIdentity?.customColor,
                cursor: "pointer",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
              className={isCodeOwner ? 'hexagon' : ''}
              src={renderDifferentSrc(data)}
              onClick={
                filterView || drawerView
                  ? () => {
                      return false;
                    }
                  : (e) => {
                    e.stopPropagation()
                    handleClickOwner(data?.userIdentity?.displayName)
                  }
              }
            >
              <span className={`${isCodeOwner ? 'isCodeOwner' : ''}`}>{data?.userIdentity?.initials || "U"}</span>
            </Avatar>
          </div>
          {(filterView || drawerView) &&
            renderEllipsis(
              data?.userIdentity?.displayName,
              drawerView ? 200 : 16
            )}
        </div>
      </Tooltip>
    );
  };

  if (_.isEmpty(data?.userIdentity)) {
    return (
      <Avatar
        size="small"
        icon={<img src={gradientAvatar} alt="avatar" />}
        style={{
          backgroundColor: "rgba(152, 114, 254, 0)",
        }}
      />
    );
  }

  return baseAvatar(data);
};

export default React.memo(OwnerIndication);

