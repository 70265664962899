import React, { useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import InventorySingelFilter from "../../../inventory/inventoryFilters/inventorySingelFilter";
import { clouds } from "../../../../utils/icons";
import filter from "lodash/filter";
import { isValidObjectId } from "../../../../utils/validations";
import { addProviderAndNameToProviderIdAggs } from "../../../../utils/helpers";

const DataSourcesFilter = () => {
  const dispatch = useDispatch();
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const dataSourceAggs = useSelector(
    (state) => state.insightsV2Reducer.aggs?.dataSources
  );
  const insightFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const dataSources = useMemo(() => {
    const filteredData = filter(dataSourceAggs || [], (item) =>
      isValidObjectId(item?.value)
    );

    const extended = addProviderAndNameToProviderIdAggs(filteredData, providerIntegrations);

    return (extended || []).map((i) => ({
      name: i?.value,
      formattedName: i?.name,
      count: i?.count,
      element: <img src={clouds(i?.provider, themeDark)} alt={i?.provider} />,
    }));
  }, [dataSourceAggs, providerIntegrations]);

  const handleSetSelectedDataSource = useMemo(
    () => (arr) => {
      const payload = { ...insightFilters, integrations: arr };
      return dispatch(setInsightsFilters(payload));
    },
    [dispatch, insightFilters]
  );

  return (
    <InventorySingelFilter
      allTypes={dataSources}
      selectedTypes={handleSetSelectedDataSource}
      checkedList={insightFilters?.integrations || []}
      title="Search"
    />
  );
};

export default DataSourcesFilter;
