import React from "react";
import ReactPlayer from "react-player";
import quoteImg from "../../../Images/general_icons/quote.svg";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { S3_ASSETS } from "../../../consts/config";
import "./welcomeStep.scss";

const WelcomeStep = () => {
  // const [vidoeOpen, setVideoOpen] = useState(false);
  const videoUrl = "https://youtu.be/KzwmfXbzJaM";
  const appsFlyerStoryUrl =
    "https://www.firefly.ai/success-stories/appsflyer";

  // Learn More
  return (
    <div className="WelcomeStep center col">
      {/* <div
        className="WelcomeStep__image center"
        style={{ backgroundImage: `url(${`${S3_ASSETS}/illustrations/demo.png`})` }}
        onClick={() => setVideoOpen(true)}
      >
        <button className="WelcomeStep__image-play">
          <FontAwesomeIcon icon="play" />
        </button>
      </div> */}
      <ReactPlayer
        url={videoUrl}
        controls
        config={{
          youtube: {
            playerVars: { showinfo: 2 },
          },
        }}
      />

      <div className="WelcomeStep__quote col">
        <img
          src={quoteImg}
          alt="quote"
          className="WelcomeStep__quote-sign left-sign"
        />
        <div className="WelcomeStep__quote-text col">
          <span className="WelcomeStep__quote-text-title">
            With Firefly we are able to codify hundreds of resources every
            month.
          </span>
          <span className="WelcomeStep__quote-text-sub">
            Eliran Bivas - Cloud Native Horizontal Leader at AppsFlyer{" "}
            <a href={appsFlyerStoryUrl} target="_blank" rel="noreferrer">
              Learn More
            </a>
          </span>
        </div>
        <img
          src={quoteImg}
          alt="quote"
          className="WelcomeStep__quote-sign right-sign"
        />
      </div>

      {/* <Modal
        title={null}
        visible={vidoeOpen}
        onCancel={() => setVideoOpen(false)}
        footer={null}
        className="WelcomeStep__videoModal"
        closable={false}
        centered={true}
        width={640}
        destroyOnClose // kill the component on close to stop the video
      ></Modal> */}
    </div>
  );
};

export default WelcomeStep;
