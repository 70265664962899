import React, { useState, useEffect, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";

import { getDrifts, getDriftList } from "../../redux/actions/driftsActions";

import Loading from "../../shared/loading/loading";
import AppToggle from "../../shared/appToggle/appToggle";
import DriftChart from "../../components/drifts/driftChart/driftChart";
import EmptyDrifts from "../../components/drifts/emptyDrifts/emptyDrifts";
import DriftAnalyzeDrawer from "../../components/drifts/driftAnalyzeDrawer/driftAnalyzeDrawer";
import DriftList from "../../components/drifts/driftList/driftList";
import DriftFilters from "../../components/drifts/driftFilters/driftFilters";

import "./drifts.scss";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import { useTranslation } from "react-i18next";

const Drifts = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('event-explorer')
  const [initWasFetched, setInitWasFetched] = useState(false);
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingList, setLoadingList] = useState(false);
  const [loadingFilter, setLoadingFilter] = useState(false);
  const [selectedRow, setSelectedRow] = useState({});
  const [analyzeDrawerOpen, setAnalyzeDrawerOpen] = useState(false);
  const [selectedProviders, setSelectedProviders] = useState([]);
  const [filterIacType, setFilterIacType] = useState([]);
  const [selectedFilterTypes, setSelectedFilterTypes] = useState([]);
  const [activeDateFilter, setActiveDateFilter] = useState("30");
  const [selectedInsights, setSelectedInsights] = useState([]);
  const [isAllActive, setIsAllActive] = useState(false);

  const allDrifts = useSelector((state) => state.driftsReducer.data);
  const aggState = useSelector((state) => state.driftsReducer?.aggState);

  useEffect(() => {
    fetchInit();
  }, []);

  useEffect(() => {
    if (initWasFetched) {
      fetchDriftsList();
      fetchChartData();
    }
  }, [
    activeDateFilter,
    selectedFilterTypes,
    selectedProviders,
    filterIacType,
    selectedInsights,
  ]);

  const fetchInit = async () => {
    setInitWasFetched(false);
    setLoadingInit(true);
    const fields = "id,name,clusterId,isProd"
    await Promise.all([
      dispatch(getAllClouds(false,fields,false)),
      dispatch(getDrifts(activeDateFilter)),
      dispatch(getDriftList(activeDateFilter)),
    ]);
    setLoadingInit(false);
    setInitWasFetched(true);
  };

  // fetch all drifts
  const fetchChartData = async () => {
    setLoadingFilter(true);
    await dispatch(
      getDrifts(
        activeDateFilter,
        selectedFilterTypes,
        selectedProviders,
        filterIacType
      )
    );
    setLoadingFilter(false);
  };

  const fetchDriftsList = async () => {
    setLoadingList(true);
    await dispatch(
      getDriftList(
        activeDateFilter,
        selectedFilterTypes,
        selectedProviders,
        filterIacType,
        selectedInsights
      )
    );
    setLoadingList(false);
  };

  const columnChart = useMemo(() => {
    return <DriftChart data={allDrifts} loading={loadingFilter} />;
  }, [allDrifts]);

  if (loadingInit) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  if (!loadingInit && isEmpty(aggState)) {
    return <EmptyDrifts />;
  }

  return (
    <div className="Drifts">
      <div className="col">
        <DriftFilters
          activeProviders={selectedProviders}
          activeState={filterIacType}
          activeResourceTypes={selectedFilterTypes}
          activeInsights={selectedInsights}
          setSelecetProviders={(filters) => {
            setSelectedProviders(filters);
          }}
          setFilterEventType={(filters) => {
            setFilterIacType(filters);
          }}
          setSelectedResourceTypes={(filters) => {
            setSelectedFilterTypes(filters);
          }}
          setSelectedInsights={(filters) => {
            setSelectedInsights(filters);
          }}
        />
        <div className="Drifts__arns row between">
          <span> {t('filters.show-arns')}</span>
          <AppToggle
            checked={isAllActive}
            toggleChecked={() => {
              setIsAllActive(!isAllActive);
            }}
            loading={false}
          />
        </div>
      </div>

      <div className="Drifts__content col">
        {columnChart}
        <DriftList
          loading={loadingList || loadingFilter}
          handleOpenAnalyzer={() => setAnalyzeDrawerOpen(true)}
          handleSelectRow={(row) => setSelectedRow(row)}
          isAllActive={isAllActive}
        />
      </div>

      <DriftAnalyzeDrawer
        visible={analyzeDrawerOpen}
        closeDrawer={() => setAnalyzeDrawerOpen(false)}
        data={selectedRow}
        driftPage
      />
    </div>
  );
};

export default Drifts;
