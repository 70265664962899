import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Column } from "@ant-design/plots";
import { Empty } from "antd";
import _ from "lodash";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import Top5ListItem from "./top5ListItem";
import "./top5UnmanagedResources.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const Top5UnmanagedResources = ({ onlyProd, prodAccounts }) => {
  const aggregationsIsManaged = useSelector(
    (state) => state.dashboardReducer.aggregationsIsManaged
  );
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const [data, setData] = useState([]);

  useEffect(() => {
    organizeUnmanagedData();
  }, [aggregationsIsManaged]);

  const organizeUnmanagedData = () => {
    if (!_.isEmpty(aggregationsIsManaged)) {
      const unmanagedData = aggregationsIsManaged.find(
        (item) => item?.key === "unmanaged"
      );
      if (
        !_.isEmpty(unmanagedData) &&
        !_.isEmpty(unmanagedData?.regions?.buckets)
      ) {
        const chartData = _.map(
          unmanagedData?.regions?.buckets || [],
          (col) => {
            return { ...col };
          }
        );
        return setData(chartData);
      }
    }
  };

  const config = {
    autoFit: true,
    data: data || [],
    xField: "key",
    yField: "doc_count",
    yAxis: false,
    label: {
      position: "middle",
      // 'top', 'bottom', 'middle',
      style: {
        fill: "#ffffff",
        opacity: 1,
        fontWeight: "700",
      },
    },
    xAxis: {
      label: {
        autoHide: true,
        autoRotate: false,
        formatter: (val) => formatAwsStringWithUnderscore(val),
      },
    },
    columnStyle: {
        fill: "#e36f7e",
        radius: [4, 4, 0, 0],
    },
    // color: "#E36F7E",
    columnBackground: {
      style: {
        fill: `l(90) 0:rgba(227, 111, 126, 1) 1:rgba(227, 111, 126, 0.4)`,
        radius: [4, 4, 0, 0],
      },
    },

    tooltip: {
      customContent: (idx, val) => {
        return (
          <div className="col sub-title" style={{ padding: "10px" }}>
            <span className="bold">
              {!_.isEmpty(val)
                ? formatAwsStringWithUnderscore(val[0]?.data?.key)
                : ""}
            </span>
            <span>{!_.isEmpty(val) ? val[0]?.data?.doc_count : ""}</span>
          </div>
        );
      },
      domStyles: {
        "g2-tooltip": {
          backgroundColor: themeDark ? "#212133" : "white",
          color: themeDark ? "rgba(255, 255, 255, 0.801)" : "#515151",
        },
      },
    },
  };

  const renderChart = () => {
    if (!_.isEmpty(data)) {
      return <Column {...config} />;
    }
  };

  const renderList = () => {
    if (!_.isEmpty(data)) {
      return _.map(data || [], (item) => {
        return (
          <Top5ListItem
            key={item?.key}
            name={item?.key}
            count={item?.doc_count}
            onlyProd={onlyProd}
            prodAccounts={prodAccounts}
          />
        );
      });
    }
  };

  if (_.isEmpty(data)) {
    return (
      <div className="Top5UnmanagedResources__empty no-flex-card center">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      </div>
    );
  }

  return (
    <div className="Top5UnmanagedResources no-flex-card">
      <div className="Top5UnmanagedResources__chart col">{renderChart()}</div>
      <div className="Top5UnmanagedResources__container row">
        <span className="Top5UnmanagedResources__container-line"></span>
        <span className="Top5UnmanagedResources__container-circle center">
          <FontAwesomeIcon icon="exclamation-circle" />
        </span>
        <div className="Top5UnmanagedResources__container-list col">
          {renderList()}
        </div>
      </div>
    </div>
  );
};

export default Top5UnmanagedResources;
