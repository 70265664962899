import { EMPTY_CI_WORKFLOWS_AGGS_FILTERS } from "../../consts/general";
import {
    GET_WORKFLOWS_AGGS,
    HANDLE_SET_CI_FILTERS,
    GET_CODE_FILES
  } from "../actions/ciWorkflowsActions";
  
  const initialState = {
    codeFiles: {},
    ciFilters: EMPTY_CI_WORKFLOWS_AGGS_FILTERS,
    ciAggs: EMPTY_CI_WORKFLOWS_AGGS_FILTERS,
  };
  
  export default function (state = initialState, { type, payload }) {
    switch (type) {
      case GET_WORKFLOWS_AGGS:
        return { ...state, ciAggs: payload };
      case HANDLE_SET_CI_FILTERS:
        return { ...state, ciFilters: payload };
      case GET_CODE_FILES:
        return { ...state, codeFiles: payload };
        default:
        return state;
  }
}