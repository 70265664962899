import React from "react";
import moment from "moment";
import { formatAwsStringWithUnderscore } from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faMapMarkerAlt,
  faDatabase,
  faCloud,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

import ProviderFlag from "../../../shared/providerFlag/providerFlag";
import DriftTypeIcon from "../driftTypeIcon/driftTypeIcon";

import "./driftItem.scss";

const DriftItem = ({ data }) => {
  return (
    <div className="DriftItem row between">
      <div className="row" style={{ gap: "15px" }}>
        <DriftTypeIcon type={data?.state} />
        <div className="DriftItem__content row">
          <div className="DriftItem__content-header center col">
            <span className="DriftItem__content-header-dateSmall row">
              {moment(parseInt(data?.createdAt)).format("MMM DD")}
            </span>
            <span className="DriftItem__content-header-date row">
              {moment(parseInt(data?.createdAt)).format("HH:mm")}
            </span>
          </div>

          <div className="DriftItem__content-text col">
            <div className="DriftItem__content-text-info row">
              <ProviderFlag
                provider={data?.provider}
                providerId={data?.providerId}
              />
              {data?.classificationName && (
                <span className="DriftItem__content-text-info-item row">
                  <FontAwesomeIcon icon={faCloud} className="icon" />
                  <span>{data?.classificationName}</span>
                </span>
              )}
              {data?.assetType && (
                <span className="DriftItem__content-text-info-item row">
                  <FontAwesomeIcon icon={faDatabase} className="icon" />
                  {formatAwsStringWithUnderscore(data?.assetType, true)}
                </span>
              )}
              {data?.region && (
                <div className="DriftItem__content-text-info-item row">
                  <FontAwesomeIcon icon={faMapMarkerAlt} className="icon" />
                  <span>{data?.region}</span>
                </div>
              )}
              {data?.frns && (
                <div className="DriftItem__content-text-info-item row">
                  <FontAwesomeIcon icon={faLayerGroup} className="icon" />
                  <span>{data?.frns?.length}</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default DriftItem;
