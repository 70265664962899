import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import AppToggle from "../appToggle/appToggle";

import "./prodToggle.scss";

const ProdToggle = ({ value, setStoreValue, disabled }) => {
  const [val, setVal] = useState(value);

  const handleChange = () => {
    setVal(!val);
    setStoreValue(!val);
  };

  return (
    <div className="ProdToggle row">
      <FontAwesomeIcon icon="flag" className="ProdToggle__icon" />
      <span className="muted">Production</span>
      <AppToggle
        disabled={disabled}
        checked={val}
        toggleChecked={handleChange}
        loading={false}
      />
    </div>
  );
};

export default ProdToggle;
