import React, { useEffect, useState } from "react";
import { isEmpty } from "lodash";
import { Form, Input } from "antd";
import { useDispatch } from "react-redux";
import AppBtn from "../../../shared/appBtn/appBtn";
import { setGcsEncryption } from "../../../redux/actions/iacStacksActions";
import "./backendsTable.scss";
import { appToast } from "../../../shared/appToast/appToast";

const GcsEncryption = ({ rowId }) => {
  const [disableAdd, setDisableAdd] = useState(true);
  const [loadingAdd, setLoadingAdd] = useState(false);
  const layout = {};
  const [form] = Form.useForm();
  const dispatch = useDispatch();

  useEffect(() => {
    form.setFieldsValue({ encryption: "" });
  }, []);

  const handleSetEncryption = async () => {
    setLoadingAdd(true);
    const encryption = form.getFieldValue("encryption");
    const didUpdateEncryptionKey = await dispatch(
      setGcsEncryption(rowId, encryption)
    );
    setLoadingAdd(false);
    if (!didUpdateEncryptionKey) {
      return;
    }
    form.setFieldsValue({ encryption: "" });
    return appToast(
      "success",
      "Success",
      "Encryption Key updated"
    );
  };

  return (
    <div className="BackendInfoModal__encryption">
      <Form
        {...layout}
        name="encryption-form"
        form={form}
        onValuesChange={(changedValues, allValues) => {
          setDisableAdd(isEmpty(allValues?.encryption));
        }}
      >
        <div className="BackendInfoModal__encryption-container col">
          <span className="bold">Set the GCS Encryption Key</span>
          <span className="BackendInfoModal__encryption-input">
            <Form.Item
              label=""
              name="encryption"
              rules={[
                {
                  required: true,
                  message: "Encryption Key is required",
                },
              ]}
              style={{ marginBottom: "0px" }}
            >
              <Input.Password />
            </Form.Item>
            <span className="BackendInfoModal__encryption-input-btn">
              <AppBtn
                text="Set"
                onClick={handleSetEncryption}
                disabled={disableAdd}
                loading={loadingAdd}
              />
            </span>
          </span>
        </div>
      </Form>
    </div>
  );
};

export default GcsEncryption;
