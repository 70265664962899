import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import { nicknameRegex, validate } from "../../../utils/validations";

const Pd1Form = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, authtoken } = formValues;

    if (_.isEmpty(name) || _.isEmpty(authtoken)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="PdForm col">
      <div className="PdForm__intro col">
        <span className="PdForm__subtitle">Before you begin, verify you have <span className="bold">admin or account owner</span> permissions.</span>
        <span className="dark-blue bold">
          Procedure
        </span>
        <ol className="PdForm__intro-orderedlist">
          <li key="1">
            Log in to your PagerDuty account.
          </li>
          <li key="2">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.pagerduty.com/docs/api-access-keys#section-generating-a-general-access-rest-api-key"
            >
              Create an API Access Key
            </a>.
          </li>
          <li key="3">
            <a
              target="_blank"
              rel="noreferrer"
              href="https://support.pagerduty.com/docs/api-access-keys#section-generate-a-user-token-rest-api-key"
            >
              Create a User Token
            </a> (optional).
          </li>
          <li key="4">
            Enter a descriptive name in the <span className="bold">Nickname</span> field.
          </li>
          <li key="5">
            Copy and paste the API key into the <span className="bold">API Access Key
            </span> field.
          </li>
          <li key="6">
            Copy and paste the User Token into the <span className="bold">User Token
            </span> field.
          </li>
          <li key="7">
            Select <span className="bold">Next</span>.
          </li>
        </ol>
      </div>

      <Form
        {...layout}
        name="pagerduty-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "PagerDuty Integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          className="PdForm__input"
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          className="PdForm__input"
          label="API Access Key"
          name="authtoken"
          rules={[
            {
              required: true,
              message: "API Access Key is required",
            },
          ]}
        >
          <Input.Password />
        </Form.Item>
        <Form.Item
          className="PdForm__input"
          label="User Token"
          name="usertoken"
          tooltip={<span>Required for fetching <span className="purple-flag">pagerduty_slack_connection</span> resources</span>}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default Pd1Form;
