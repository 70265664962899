import React from "react";
import { useHistory } from "react-router-dom";
import toLower from "lodash/toLower";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { getNameDescriptionAndCategoryById } from "../../../../../utils/helpers";
import { Tooltip } from "antd";
import AssetInfoCard from "../assetInfoCard";

import "./insights.scss";
import { insights } from "../../../../../utils/icons";

const Insights = ({ classificationIds, disableLink }) => {
  const history = useHistory();
  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  const renderIcon = (category) => {
    const string = toLower(category);
    switch (string) {
      case "reliability":
        return <img src={insights["reliability"]} alt="reliability" />;

      case "optimization":
        return <img src={insights["optimization"]} alt="optimization" />;

      case "misconfiguration":
        return (
          <img src={insights["misconfiguration"]} alt="misconfiguration" />
        );

      default:
        return null;
    }
  };

  const renderClassificationName = (classificationId) => {
    const classification = getNameDescriptionAndCategoryById(
      classificationId,
      classifications
    );
    return (
      <Tooltip
        placement="topLeft"
        title={
          <div className="Insights__insight-content col">
            <div
              className={`row Insights__insight-content-title row ${toLower(
                classification?.labels?.[0]
              )}`}
            >
              <span
                className={`row Insights__insight-content-title-flag ${toLower(
                  classification?.labels?.[0]
                )}-flag`}
              >
                {renderIcon(classification?.labels?.[0])}
                {!isEmpty(classification?.labels) && classification?.labels[0]}
              </span>
            </div>
            <span>
              {!isEmpty(classification?.description) &&
                classification?.description}
            </span>
          </div>
        }
        style={{ cursor: "pointer" }}
        overlayInnerStyle={{
          fontSize: "13px",
        }}
      >
        <div
          className="Insights__insight row"
          onClick={() => {
            if (!disableLink) {
              return history.push({
                pathname: `/governance`,
                state: {
                  classification: classification?.name,
                },
              });
            }
            return;
          }}
        >
          <div className="Insights__insight-content col">
            <div
              className={`row Insights__insight-content-title row ${toLower(
                classification?.labels?.[0]
              )}`}
            >
              <span
                className={`row Insights__insight-content-title-flag ${toLower(
                  classification?.labels?.[0]
                )}-flag`}
              >
                {renderIcon(classification?.labels?.[0])}
                {/* {!isEmpty(classification?.labels) && classification?.labels[0]} */}
              </span>
              <span className={`Insights__insight-content-title-text ${disableLink ? '' : 'underline'}`}>
                {!isEmpty(classification?.name) && classification?.name}
              </span>
            </div>
            {/* <span className="Insights__insight-content-text">
            {!isEmpty(classification?.description) &&
              classification?.description}
          </span> */}
          </div>
        </div>
      </Tooltip>
    );
  };

  return (
    <AssetInfoCard
      contentArr={[
        {
          label: "",
          content: (
            <div className="Insights col">
              {map(classificationIds, (classificationId) => (
                <div key={uuidv4()} className="Insights__insight col">
                  {renderClassificationName(classificationId)}
                </div>
              ))}
            </div>
          ),
          display: !isEmpty(classificationIds),
        },
      ]}
    />
  );
};

export default Insights;
