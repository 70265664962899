import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import { integrationIcons } from "../../../utils/icons";

import "./bitbucketIntegration.scss";
import { createBitBucketIntegration } from "../../../redux/actions/vcsActions";
import BitBucketConfiguration from "./bitbucketConfiguration";

const BitBucketIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Setup Bitbucket Integration",
      content_title: "Setup Bitbucket Integration",
      content: (
        <BitBucketConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);

        const result = await dispatch(
          createBitBucketIntegration(
            wizardState?.name,
            wizardState?.username,
            wizardState?.workspace,
            wizardState?.password
          )
        );
        setLoadingSubmit(false);
        if (!result?.ok) {
          throw new Error(`Failed to create Bitbucket integration.`);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="BitBucketIntegration">
      <AppWizard
        iconSrc={integrationIcons["bitbucket"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default BitBucketIntegration;
