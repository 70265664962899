import indexOf from 'lodash/indexOf';

export const ONBORDING_STATUS_NAMES = [
    "welcome",
    "provider",
    "iac",
    "git",
    "notification",
    "done"
];

// if -1 than status not found so return the last one idx === "done"
// add +1 to status return - so the first step of wizrd wil be 1 and not 0
export const getStatusIdxByName = (name) => {
    let statusNum = indexOf(ONBORDING_STATUS_NAMES, name ,0);
    return statusNum === -1 ? ONBORDING_STATUS_NAMES?.length : statusNum + 1;
}