import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import _ from "lodash";
export const CLEAR_GENERATED_IAC = "CLEAR_GENERATED_IAC";
export const FETCH_MULTI_GENERATED_IAC = "FETCH_MULTI_GENERATED_IAC";
export const FETCH_TERRAFORM_CMD = "FETCH_TERRAFORM_CMD";
export const GET_REPO_MODULES = "GET_REPO_MODULES";
export const GET_MODULES_BY_REPO = "GET_MODULES_BY_REPO";
export const MODULE_CALL = "MODULE_CALL";
export const SET_PULL_REQUEST_PARAMETERS = "SET_PULL_REQUEST_PARAMETERS";
export const CREATE_MODULE = "CREATE_MODULE";
export const SELECTED_MODULE_FILE = "SELECTED_MODULE_FILE";
export const DONWLOAD_ZIP_MODULE_FILES = "DONWLOAD_ZIP_MODULE_FILES";
export const CLEAR_MODULE_DATA = "CLEAR_MODULE_DATA";

let fetchGeneratedIacAbortController;
const fetchGeneratedIaC = ({
  accountId,
  resourceType,
  resourceId,
  iacType,
  driftNumber,
  returnPayloadType = "hcl",
  resources = [],
  provider,
  providerId,
  codificationMode = "",
  revisionId,
  isDeleted = false,
  frn,
}) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();

    if (fetchGeneratedIacAbortController) {
      fetchGeneratedIacAbortController.abort();
    }
    fetchGeneratedIacAbortController = new AbortController();

    const payload = {
      assetType: resourceType,
      assetId: `${accountId}:${resourceId}`,
      version: 1,
      iacType: iacType,
      driftNumber,
      returnPayloadType,
      resources,
      provider,
      providerId,
      codificationMode,
      revisionId,
      isDeleted,
      frn,
    };

    const url = `${BACKEND_URL}/reverseLearning`;
    try {
      const req = await requestWrapper.sendRequest(
        url,
        "POST",
        payload,
        undefined,
        undefined,
        true,
        1800,
        fetchGeneratedIacAbortController.signal
      );

      const data = await req.json();
      return _.isEmpty(data) ? null : data;
    }
    catch (err) {
      if (err.name === 'AbortError') {
        return { abort: true }
      }
      return {};
  }
  });
};

const fetchTerraformCmd = (iacType, resources) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      iacType: iacType,
      resources,
    };

    const url = `${BACKEND_URL}/reverseLearning/terraformCmd`;
    const req = await requestWrapper.sendRequest(url, "POST", payload);

    const data = await req.json();
    return data;
  });
};

const getRepositoriesWithModules = (resourcesTypes, withModuleVars = false, isComposeModuleCall = false) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();

    const url = `${BACKEND_URL}/reverseLearning/module-repos`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      {
        resourcesTypes,
        withModuleVars,
        isComposeModuleCall,
      },
      undefined,
      undefined,
      false
    );

    let repos = [];

    if (req.ok) {
      let data = await req.json();
      repos = _.map(data || [], (item) => item?.key);
    }

    return dispatch({
      type: GET_REPO_MODULES,
      payload: repos,
    });
  });
};

const getModulesByRepo = (repoFullName, resourcesTypes, withModuleVars = false, isComposeModuleCall = false) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();

    const url = `${BACKEND_URL}/reverseLearning/modules`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      { repoFullName, resourcesTypes, withModuleVars, isComposeModuleCall },
      undefined,
      undefined,
      false
    );

    let modules = [];

    if (req.ok) {
      let data = await req.json();
      modules = data;
    }

    return dispatch({
      type: GET_MODULES_BY_REPO,
      payload: modules,
    });
  });
};

const moduleCall = (obj) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();
    const url = `${BACKEND_URL}/reverseLearning/codify-module`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      obj,
      undefined,
      undefined,
      false
    );

    let code = null;

    if (req.ok) {
      let data = await req.json();
      const { HCL } = data;
      const newHcl = _.isEmpty(HCL) ? "" : `${HCL}`;
      code = {...data, HCL: newHcl};
    }

    dispatch({
      type: MODULE_CALL,
      payload: code,
    });
    return code;
  });
}

const clearModuleData = () => (action(async (dispatch) => {
  dispatch({
      type: CLEAR_MODULE_DATA,
  });
}));

const setPullRequestParameters = (obj) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_PULL_REQUEST_PARAMETERS,
      payload: obj,
    });
  });
};

const createModule = (obj) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();
    const url = `${BACKEND_URL}/reverseLearning/create-module`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      obj,
      undefined,
      undefined,
      false
    );

    let code = '';

    if (req.ok) {
      code = await req.json();
    }

    dispatch({
      type: CREATE_MODULE,
      payload: code,
    });
    return code;
  });
}

const downloadZipModuleFiles = (obj) => {
  return action(async (dispatch, getState) => {
    const requestWrapper = new RequestWrapper();
    const url = `${BACKEND_URL}/reverseLearning/create-module/download-zip`;
    const req = await requestWrapper.sendRequest(
      url,
      "POST",
      obj
    );
    return { req, result: await req.blob() };
  });
}

const setModuleFile = (fileName) => {
  return action(async (dispatch) => {
    dispatch({
      type: SELECTED_MODULE_FILE,
      payload: fileName,
    });
  });
};

let abortController;
const fetchGeneratedCodify = (payload) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const timeout = 180000;
    const url = `${BACKEND_URL}/reverseLearning/codify`;
    try {
      const req = await requestWrapper.sendRequest(
        url,
        "POST",
        payload,
        undefined,
        undefined,
        true,
        timeout,
        abortController.signal
      );
      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      return data;
    }
    catch (err) {
        if (err.name === 'AbortError') {
          return { abort: true }
        }
        return {};
    }
  });
}

const fetchGeneratedCloudMigration = (payload) => {
  if (abortController) {
    abortController.abort();
  }
  abortController = new AbortController();
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const timeout = 180000;
    const url = `${BACKEND_URL}/reverseLearning/migrate`;
    try {
      const req = await requestWrapper.sendRequest(
          url,
          "POST",
          payload,
          undefined,
          undefined,
          true,
          timeout,
          abortController.signal
      );
      let data = null;
      if (req?.ok) {
        data = await req.json();
      }
      return data;
    }
    catch (err) {
      if (err.name === 'AbortError') {
        return { abort: true }
      }
      return {};
    }
  });
}


export {
  fetchGeneratedIaC,
  fetchTerraformCmd,
  getRepositoriesWithModules,
  getModulesByRepo,
  moduleCall,
  setPullRequestParameters,
  createModule,
  setModuleFile,
  downloadZipModuleFiles,
  fetchGeneratedCodify,
  fetchGeneratedCloudMigration,
  clearModuleData,
};
