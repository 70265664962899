import React, { memo } from "react";
import { useTranslation } from "react-i18next";
import { useSelector, useDispatch } from "react-redux";
import { setInsightsFilters } from "../../../../redux/actions/insightsV2Actions";
import { Tooltip } from "antd";
import map from "lodash/map";
import find from "lodash/find";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as ExcludedIcon } from "../../../../Images/general_icons/insights-filters/excluded.svg";
import AppToggle from "../../../../shared/appToggle/appToggle";
import "./togglesFilter.scss";

const TogglesFilter = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation("insights");
  const insightsFilters = useSelector(
    (state) => state.insightsV2Reducer.filters
  );

  const filtersOpen = useSelector(
    (state) => state.insightsV2Reducer.filtersOpen
  );

  const insightAggs = useSelector((state) => state.insightsV2Reducer.aggs);

  const items = [
    {
      title: t("filters.production"),
      icon: <FontAwesomeIcon icon="flag" color="inherit" />,
      key: "onlyProduction",
      val: insightsFilters?.onlyProduction,
      exiest: true,
    },
    {
      title: t("filters.excluded"),
      icon: <ExcludedIcon />,
      key: "showExclusion",
      val: insightsFilters?.showExclusion,
      exiest: true,
    },
    {
      title: t("filters.assets"),
      icon: <FontAwesomeIcon icon="layer-group" color="inherit" />,
      key: "onlyMatchingAssets",
      val: insightsFilters?.onlyMatchingAssets,
      exiest: true,
    },
    {
      title: t("filters.notifications"),
      icon: <FontAwesomeIcon icon="bell" color="inherit" />,
      key: "onlySubscribed",
      val: insightsFilters?.onlySubscribed,
      exiest: find(insightAggs?.isSubscribed || [], (i) => i?.value === true),
    },
    {
      title: t("filters.enabled"),
      icon: <FontAwesomeIcon icon="power-off" color="inherit" />,
      key: "onlyEnabled",
      val: insightsFilters?.onlyEnabled,
      exiest: find(insightAggs?.isEnabled || [], (i) => i?.value === true),
    },
  ];

  const handleSetFilter = (obj) => {
    if (!obj?.exiest) {
      return;
    }
    const payload = { ...insightsFilters, [obj?.key]: !obj?.val };
    return dispatch(setInsightsFilters(payload));
  };

  return (
    <div className="TogglesFilter col">
      {map(items, (i, idx) => {
        return (
          <div
            className={`TogglesFilter__item ${i.exiest ? "" : "disabled"} ${i?.val ? `active` : ""}`}
            key={i.key}
            onClick={() => handleSetFilter(i)}
            style={{ padding: filtersOpen ? "0 15px" : "0 6px" }}
          >
            <Tooltip title={filtersOpen ? null : i.title} placement="right">
              <div
                className={`TogglesFilter__item-inner row g5`}
                style={{
                  justifyContent: filtersOpen ? "space-between" : "center",
                  borderBottom:
                    idx + 1 === items?.length ? "none" : "1px solid",
                  transition: "padding 0.5s, justify-content 0.5s",
                }}
              >
                <span className="row g10">
                  {i.icon}
                  {filtersOpen ? i.title : null}
                </span>

                {filtersOpen && (
                  <AppToggle
                    checked={i?.val}
                    toggleChecked={() => handleSetFilter(i)}
                  />
                )}
              </div>
            </Tooltip>
          </div>
        );
      })}
    </div>
  );
};

export default memo(TogglesFilter);
