import React, { memo } from "react";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router-dom";
import { numberWithCommas } from "../../../utils/formatting";
import AppBtn from "../../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './singleIntegrationHeader.scss';

const SingleIntegrationHeader = ({ data, count }) => {
  const { t } = useTranslation("integrations");
  const history = useHistory();
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const handleNavigate = () => {
    return history.push(data?.link);
  };
  const handleBack = () => {
    return history.push('/integrations');
  };

  return (
    <div className="SingleIntegrationHeader row between">
      <div className="row g10">
        <button className="btn-icon" onClick={handleBack}>
        <FontAwesomeIcon icon="arrow-left" />
        </button>
        <img src={data?.icon} alt="icon" className={`${data?.name}-icon`} />
        <span className="title bold">
          {`${data?.title} (${numberWithCommas(count)})`}
        </span>
      </div>
      <AppBtn
        text={t("header.add-btn")}
        onClick={handleNavigate}
        icon={<FontAwesomeIcon icon="plus" />}
        disabled={isViewer}
      />
    </div>
  );
};

export default memo(SingleIntegrationHeader);
