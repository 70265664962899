import React, { useCallback } from "react";
import { useTranslation } from "react-i18next";
import HeaderSearchBox from "../../../shared/headerSearchBox/headerSearchBox";
import SingleFilter from "./SingleFilter";
import map from "lodash/map";
import every from "lodash/every";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

// icons
import { ReactComponent as EventIcon } from "../../../Images/general_icons/integration-filter-icons/event_driven.svg";
import { ReactComponent as NotEventIcon } from "../../../Images/general_icons/integration-filter-icons/periodic-scan.svg";
import { ReactComponent as ProdIcon } from "../../../Images/general_icons/integration-filter-icons/production.svg";
import { ReactComponent as NotProdIcon } from "../../../Images/general_icons/integration-filter-icons/non-production.svg";
import { ReactComponent as SynceIcon } from "../../../Images/general_icons/integration-filter-icons/sync.svg";
import { ReactComponent as NotSyncIcon } from "../../../Images/general_icons/integration-filter-icons/oo-sync.svg";
import "./singleIntegrationFilters.scss";

const SingleIntegrationFilters = ({
  query,
  setQuery,
  filters,
  setFilters,
  loading,
  handleClearFilters,
  integrationType,
}) => {
  const { t } = useTranslation("integrations");
  const allowedTypesAllFiltersBlocks = ["aws", "k8s", "azurerm", "gcp"];

  const filterBlocks = {
    isEventDriven: {
      blocks: [
        {
          title: t("single-integration.filters.ed"),
          icon: <EventIcon />,
          key: true,
        },
        {
          title: t("single-integration.filters.not-ed"),
          icon: <NotEventIcon />,
          key: false,
        },
      ],
      show: ["aws", "gcp", "azurerm"],
    },
    isSync: {
      blocks: [
        {
          title: t("single-integration.filters.sync"),
          icon: <SynceIcon />,
          key: true,
        },
        {
          title: t("single-integration.filters.not-sync"),
          icon: <NotSyncIcon />,
          key: false,
        },
      ],
      show: [], // empty means dont show
    },
    isProd: {
      blocks: [
        {
          title: t("single-integration.filters.prod"),
          icon: <ProdIcon />,
          key: true,
        },
        {
          title: t("single-integration.filters.not-prod"),
          icon: <NotProdIcon />,
          key: false,
        },
      ],
      show: ["aws", "gcp", "azurerm", "k8s"],
    },
  };

  const handleChangeFilter = useCallback(
    (type, key) => {
      if (loading) {
        return;
      }
      let newFilters = {
        ...filters,
        [type]: filters?.[type] === key ? null : key,
      };
      return setFilters(newFilters);
    },
    [filters, loading]
  );

  return (
    <div className="SingleIntegrationFilters row between">
      <HeaderSearchBox
        placeholder={t("single-integration.search")}
        value={setQuery}
        currentValue={query}
        resetSearch={setQuery}
      />

      {allowedTypesAllFiltersBlocks?.includes(integrationType) && (
        <div className="row g15 SingleIntegrationFilters__blocks">
          <span>{t("single-integration.filters.title")}</span>
          {map(filterBlocks || {}, (v, k) => {
            if (v?.show?.includes(integrationType)) {
              return (
                <SingleFilter
                  key={k}
                  content={v?.blocks}
                  active={filters?.[k]}
                  onClick={(inner_key) => handleChangeFilter(k, inner_key)}
                  loading={loading}
                />
              );
            }
          })}
          <button
            className="SingleIntegrationFilters__blocks-clear center"
            onClick={handleClearFilters}
            disabled={every(filters, (i) => i === null)}
          >
            <FontAwesomeIcon icon="times" />
          </button>
        </div>
      )}
    </div>
  );
};

export default SingleIntegrationFilters;
