import React, { useEffect, useState } from "react";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import findLastIndex from "lodash/findLastIndex";
import { v4 as uuidv4 } from "uuid";
import { useTranslation } from "react-i18next";
import { useHistory } from "react-router";
import { useDispatch, useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Divider, Radio, Select } from "antd";
import ProvisionCode from "../provisionCode/provisionCode";
import GradientBtn from "../../../shared/gradientBtn/gradientBtn";
import TfVar from "../tfVar/tfVar";
import { ReactComponent as NoCodeIcon } from "../../../Images/general_icons/no_code.svg";
import { ReactComponent as SelectModuleIcon } from "../../../Images/general_icons/select_module.svg";
import {
  MODULE_CALL_PATHS,
  handleModuleCallRequests,
  handleToggleProvisioning
} from "../../../redux/actions/globalAppActions";
import { getModulesByRepo, getRepositoriesWithModules } from "../../../redux/actions/iacImportActions";
import { sendEvent } from "../../../utils/amplitude";
import { composeEvents } from "../../../utils/amplitudeEvents";
import { clouds } from "../../../utils/icons";
import useScrollPosition from "../../../hooks/useScrollPosition";
import AppEmpty from "../../../shared/appEmpty/appEmpty";

import "./moduleCall.scss";

const NON_TOGGLE_VCS_SUPOORT = ['github', 'bitbucket'];

const ModuleCall = ({ generetedModuleCall, setGeneratedModuleCall }) => {
  const { t } = useTranslation("compose", { keyPrefix: "moduleCall" });
  const dispatch = useDispatch();

  const [hasInitialFetch, setHasInitialFetch] = useState(false);
  const [loadingInit, setLoadingInit] = useState(false);
  const [loadingModule, setLoadingModule] = useState(false);
  const [generateLoading, setGenerateLoading] = useState(false);
  const [selectedRepo, setSelectedRepo] = useState(null);
  const [selectedModule, setSelectedModule] = useState(null);
  const [tfVars, setTfVars] = useState([]);
  const [showModuleProtocolRadio, setShowModuleProtocolRadio] = useState(false);
  const [moduleProtocol, setModuleProtocol] = useState('ssh');
  const [moduleLink, setModuleLink] = useState('');
  const [customDescription, setCustomDescription] = useState({
    title1: t('descriptions.initial'),
    title2: ''
  })

  const tfVarsDiv = document.getElementById('tfVars-scroll');
  const { hasMoreToScroll } = useScrollPosition(tfVarsDiv);
  
  const history = useHistory();

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  const modules = useSelector((state) => state.iacImportReducer.modules);
  const moduleRepos = useSelector((state) => state.iacImportReducer.moduleRepos);
  
  useEffect(() => {
    getInitData();
    sendEvent(composeEvents.generateModuleCallTab, {});
    return () => {
      setGeneratedModuleCall(null);
      setCustomDescription(null);
    }
  }, []);

  useEffect(() => {
    if (isEmpty(moduleRepos)) return;
    const hasPrivateRepos = findLastIndex(moduleRepos || [], ({ isPublic }) => !isPublic) > -1;
    if (!hasPrivateRepos) {
        setCustomDescription({
          title1: customDescription?.title1,
          title2: <span>Note: to use private modules you should <a onClick={onIntegrateClick}>integrate your git</a></span>
        })
      }
  }, [moduleRepos])

  useEffect(() => {
    if (modules?.length === 1 && hasInitialFetch) {
      const { moduleName = '', moduleFullPathMD5, moduleSourceType } = modules[0] || {};
      const mod = [moduleName, moduleFullPathMD5, moduleSourceType];
      onSelectModule(mod)
    }
  }, [modules])

  const getInitData = async () => {
    setLoadingInit(true);
    await Promise.all([
      dispatch(getRepositoriesWithModules([], true, true)),
    ]);
    setLoadingInit(false);
    setHasInitialFetch(true);
  };

  const renderRepos = () => {
    if (!isEmpty(moduleRepos)) {
      return map(moduleRepos || [], ({ repoFullName = "", vcsType= "", isPublic = false } = {}) => {
        return (
          <Select.Option value={[repoFullName, vcsType]} key={uuidv4()}>
            <span className={`ModulesTab__repo row between`}>
              <div className="row g15">
                <img src={clouds(vcsType, themeDark)} alt="integration" />
                {repoFullName}
              </div>
              {isPublic && <span className="purple-flag ModulesTab__repo-public">Public</span>}
            </span>
          </Select.Option>
        );
      });
    }
  };

  const renderModules = () => {
    if (!isEmpty(modules) && selectedRepo) {
      return map(modules || [], ({ moduleName = '', moduleFullPathMD5, moduleSourceType } = {}) => {
        return (
          <Select.Option value={[moduleName, moduleFullPathMD5, moduleSourceType]} key={uuidv4()}>
            {moduleName}
          </Select.Option>
        );
      });
    }
  };

  const getModules = async (repoName) => {
    if (repoName) {
      setLoadingModule(true);
      setSelectedModule(null);
      await dispatch(getModulesByRepo(repoName, [], true, true));
      setLoadingModule(false);
    }
  };

  const onChangeModuleProtocol = (e) => setModuleProtocol(e.target.value);

  const onChangeTfVarValue = (value, key) => {
    const updatedTfArray = map(tfVars, (tfVar = {}) => (tfVar.key === key ? { ...tfVar, default: value } : tfVar))
    setTfVars(updatedTfArray)
  }
  const onGenerateModuleCall  = async () => {
    setGenerateLoading(true);
    const [moduleName, moduleFullPathMD5, moduleSourceType] = selectedModule || [];
    const payload = {
      moduleName,
      moduleFullPathMD5,
      moduleSourceType,
      moduleProtocol,
      tfVars,
    }
    const req = await dispatch(handleModuleCallRequests(payload, MODULE_CALL_PATHS.moduleCall)) || {};
    const { error, data } = req;
    if (error) {
      setGeneratedModuleCall(null);
      setCustomDescription({
        title1: <div className="col g5 center">
                  <div className="row g5">
                      <FontAwesomeIcon className="ModuleCall__errors-icon" icon="exclamation-triangle" />          
                      {t('descriptions.error')}
                  </div>
                  <span className="ModuleCall__errors">{error}</span>
                </div>,
        title2: ''
      })
      setGenerateLoading(false);
    }
    setGeneratedModuleCall({ content: data, selectedModule: moduleName });
    return setGenerateLoading(false);
  }
  
  const handleGetModuleTfVars = async (module = []) => {
    const [moduleName, moduleFullPathMD5, moduleSourceType] = module;
    const payload = {
      moduleName,
      moduleFullPathMD5,
      moduleSourceType,
    }
    const req = await dispatch(handleModuleCallRequests(payload, MODULE_CALL_PATHS.getVars)) || {};
    const { moduleGitLink, moduleVars = [] } = req;
    setModuleLink(moduleGitLink)
    if (!isEmpty(moduleVars)) {
      setTfVars(moduleVars)
      return setCustomDescription({
        title1: t('descriptions.moduleSelected'),
        title2: ''
      })
    }
    setTfVars([]);
    setCustomDescription({
      title1: <span className="ModuleCall__errors-noFound">{t('descriptions.noVarsFound')}</span>,
      title2: t('descriptions.initial'),
    })
  }

  const onSelectModule = (module) => {
    handleGetModuleTfVars(module);
    return setSelectedModule(module);
  }
  
  const handleScroll = (divToScroll) => (divToScroll.scrollTop = divToScroll.scrollHeight);

  const onIntegrateClick = () => {
    dispatch(handleToggleProvisioning())
    history.push({
      pathname: "/integrations/new-integration",
      state: { scrollTo: 'vcs'}
    })
  }

  return (
    <div className="Compose row">
      <div className="ModuleCall basic-card config col g10">
        <span className="ModuleCall__title row bold">
          <SelectModuleIcon/>
          {t('selectModule.title')}
        </span>
        <div className="ModuleCall__select row g10">
          <Select
            placeholder={loadingInit ? "loading..." : "Choose Repo"}
            showSearch
            filterOption={(input, option) => {
              const [repoName] = option?.value || []
              return repoName?.toLowerCase().indexOf(input?.toLowerCase()) >= 0
            }
            }
            disabled={loadingInit || loadingModule}
            loading={loadingInit}
            className="ModuleCall__select-item"
            onChange={(item) => {
              const [repoName, vcsType] = item || []
              getModules(repoName);
              setSelectedRepo(repoName);
              setTfVars([]);
              if (!NON_TOGGLE_VCS_SUPOORT.includes(vcsType)) {
                return setShowModuleProtocolRadio(true)
              }
              setShowModuleProtocolRadio(false)
            }}
          >
            {renderRepos()}
          </Select>
          <Select
            placeholder={loadingModule ? "loading..." : "Choose Module"}
            showSearch
            filterOption={(input, option) =>  option?.children?.toLowerCase().indexOf(input?.toLowerCase()) >= 0}
            disabled={loadingModule}
            loading={loadingModule}
            value={selectedModule}
            className="ModuleCall__select-item"
            onSelect={(module) => onSelectModule(module)}
          >
            {renderModules()}
          </Select>
          {selectedModule && moduleLink && <FontAwesomeIcon className="ModuleCall__select-link" icon='external-link-alt' onClick={() => window.open(moduleLink,"_blank")} />}
        </div>
        {showModuleProtocolRadio && !isEmpty(tfVars) && (
          <div className="row ModulesTab__header-radio">
            <span className="form-label bold">Git Connection Protocol</span>
            <Radio.Group onChange={onChangeModuleProtocol} value={moduleProtocol}>
             <Radio value="ssh" key="ssh">ssh</Radio>
              <Radio value="https" key="https">https</Radio>
           </Radio.Group>
          </div>)}
       {!isEmpty(tfVars) && 
        <span className="ModuleCall__title tf row bold">
          <NoCodeIcon/>
          {t('terraformVariable.title')}
        </span>}
       
        <div className="col ModuleCall__tfVars">
            <div className="ModuleCall__tfVars-container col between" id="tfVars-scroll">
              <div className="col">
                {map(tfVars, (tfVar = {}) => (
                  <div className="col" key={tfVar.key}> 
                    <TfVar tfVar={tfVar} onChangeValue={onChangeTfVarValue}/>
                    <Divider/>
                  </div>
                ))}
              </div>
              {!isEmpty(tfVars) && <GradientBtn text="Generate" onClick={onGenerateModuleCall}/>}
            </div>
            {tfVars?.length > 2 && hasMoreToScroll && <FontAwesomeIcon className="ModuleCall__tfVars-scrollIcon" icon="chevron-down" onClick={() => handleScroll(tfVarsDiv)} />}
         </div>
      </div>
      <div className="ModuleCall__code-cost col between g20">
        <div className="ModuleCall code">
          <ProvisionCode
              loading={generateLoading}
              data={generetedModuleCall ? [{filePath: 'main.tf'}] : null}
              setSelected={() => null}
              selected={{
                content: generetedModuleCall || '',
                filePath: 'main.tf'
              }}
              customDescription={customDescription}
              noFiles
          />
        </div>
        <div className="ModuleCall cost basic-card">
            <AppEmpty customStyle="cost" text="Cost Forecasting" big/>
        </div>
      </div>
    </div>
  );
};

export default ModuleCall;