import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";

import img from "../../../Images/illustrations/provision.svg";
import CodeBlock from "../../../shared/codeBlock/codeBlock";
import TypingDots from "../../../shared/typingDots/typingDots";
import { useTranslation } from "react-i18next";
import LongLoader from "../../../shared/longLoader/longLoader";

import "./provisionCode.scss";
import { useSelector } from "react-redux";

const ProvisionCode = ({ data, selected, setSelected, loading, customDescription = {}, noFiles = false}) => {
  const { t } = useTranslation("compose");
  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const codeBackground = themeDark ? "#181825" : "#30304A";

  if (loading) {
    const msg1 = t("loading.msg1");
    const msg2 = t("loading.msg2");
    return (
      <div className="ProvisionCode center col g20">
        <img src={img} alt="provision" />
        <div className="ProvisionCode__brand bold row g10">
          <LongLoader
            msg1={msg1}
            msg2={msg2}
            loading={loading}
            duration={30000}
          />
          <TypingDots />
        </div>
      </div>
    );
  }

  if (isEmpty(data)) {
    const { title1 = t("description.line1"), title2 = t("description.line2")} = customDescription || {};
    return (
      <div className="ProvisionCode center col g20">
        <img src={img} alt="provision" />
        <div className="ProvisionCode__brand bold col center">
          <span>{title1}</span>
          <span>{title2}</span>
        </div>
      </div>
    );
  }

  return (
    <div className={`ProvisionCode ${!noFiles ? 'split': ''}`} style={{ backgroundColor: "#181825" }}>
      {!noFiles && <div className="ProvisionCode__files col">
        {map(data || [], (item) => {
          return (
            <div
              className={`ProvisionCode__files-item ${
                selected?.filePath === item?.filePath ? "active" : ""
              }`}
              key={item?.filePath}
              onClick={() => setSelected(item)}
            >
              {item?.filePath}
            </div>
          );
        })}
      </div>}
      <div className={`ProvisionCode__code ${noFiles ? 'noPadding': ''}`}>
        <CodeBlock
          height={"100%"}
          editable={false}
          code={selected?.content}
          lang="js"
          customSettings={{
            fontFamily: "monospace",
            background: codeBackground,
            gutterBackground: codeBackground,
            gutterForeground: "rgba(255, 255, 255, 0.5)",
          }}
        />
      </div>
    </div>
  );
};

export default ProvisionCode;
