import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Form, Input, Select, Divider, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import _ from "lodash";
import { getAllClouds } from "../../../redux/actions/globalAppActions";
import {
  getCategories,
  getAssetTypes,
  generateRegoAi,
} from "../../../redux/actions/classificationsActions";
import WizardStepHeader from "../wizardStepHeader/wizardStepHeader";
import InsightCategoryLabel from "../../Insights/insightCategoryLabel/insightCategoryLabel";
import DescriptionBlock from "../descriptionBlock/descriptionBlock";
import ProviderSelect from "../../createInsight/providerSelect/providerSelect";
import AddCategory from "../addCategory/addCategory";
import {
  descriptionRegex,
  nameWithSpaceRegex,
} from "../../../utils/validations";
import AssetTypeSelect from "../assetTypeSelect/assetTypeSelect";
import { insightSeverityTypes } from "../../../consts/insights";
import "./insightDetails.scss";
import SeverityCell from "../../Insights/insightsTable/severityCell";

const InsightDetails = ({ wizardState, updateWizard, readOnly }) => {
  const dispatch = useDispatch();
  const [form] = Form.useForm();
  const [categories, setCategories] = useState([]);
  const [loadingCategories, setLoadingCategories] = useState(false);
  const [loadingProviders, setLoadingProviders] = useState(false);
  const [loadingAI, setLoadingAI] = useState(false);
  const [loadingAssetTypes, setLoadingAssetTypes] = useState(false);
  const [assetTypes, setAssetTypes] = useState([]);

  const providers = useSelector((state) => state.globalAppReducer.clouds);
  const assetSamples = useSelector(
    (state) => state.classificationsReducer.wizardAssets
  );
  
  useEffect(() => {
    handleFetchCategories();
    handleFetchClouds();
    handleFetchTypes();
  }, []);

  useEffect(() => {
    form.setFieldsValue({
      name: wizardState?.name,
      labels: wizardState?.labels,
      providers: wizardState?.providers,
      scope: wizardState?.scope,
      description: wizardState?.description,
      severity: wizardState?.severity || 2,
    });
  }, [wizardState]);

  const renderCategoryOptions = () => {
    return _.map(categories || [], (item) => {
      return (
        <Select.Option
          key={item}
          value={item}
          className="InsightDetails__categories-item"
        >
          <InsightCategoryLabel category={item} withText />
        </Select.Option>
      );
    });
  };

  const renderSeverityOptions = () => {
    return _.map(insightSeverityTypes || [], (item, idx) => {
      return (
        <Select.Option
          key={idx + 1}
          value={idx + 1}
          className="InsightDetails__categories-item"
        >
          <SeverityCell value={idx + 1} />
        </Select.Option>
      );
    });
  };

  const handleFetchClouds = async () => {
    setLoadingProviders(true);
    await Promise.all([dispatch(getAllClouds())]);
    setLoadingProviders(false);
  };

  const handleFetchCategories = async () => {
    setLoadingCategories(true);
    const req = await dispatch(getCategories());
    setCategories(req);
    setLoadingCategories(false);
  };

  const handleFetchTypes = async (providers) => {
    setLoadingAssetTypes(true);
    const req = await dispatch(getAssetTypes(providers));
    setAssetTypes(req);
    setLoadingAssetTypes(false);
  };

  const handleGenerateAi = async () => {
    setLoadingAI(true);
    const assetType = !_.isEmpty(wizardState?.scope)
      ? wizardState?.scope[0]
      : null;
    const prompt = wizardState?.description;
    const samplesArr = _.isEmpty(assetSamples) ? [] : _.slice(assetSamples, 0, 1)
    const samples = _.map(samplesArr || [], i => i?.tfObject)
    const req = await dispatch(generateRegoAi(assetType, prompt, samples));
    updateWizard({ ...wizardState, rego: req });
    setLoadingAI(false);
  };
  
  const getSelectedProviders = (providers = []) => providers.map((provider) => provider === 'google_all' ? 'gcp_all' : provider);

  return (
    <div className="InsightDetails basic-card col g15">
      <WizardStepHeader type="details" />
      <Form
        name="create-insight"
        form={form}
        className="col g20"
        onValuesChange={(changedValues, allValues) => {
          updateWizard(allValues);
        }}
        initialValues={wizardState}
      >
        <div className="form-row">
          <Form.Item
            label="Name"
            name="name"
            rules={[
              {
                required: true,
                message: "name is required",
                whitespace: true,
                validateTrigger: "onSubmit",
              },
              {
                pattern: nameWithSpaceRegex.regex,
                message: nameWithSpaceRegex.msg,
              },
            ]}
            style={{ marginBottom: "0", flexDirection: "column" }}
          >
            <Input placeholder="Enter policy name" disabled={readOnly} />
          </Form.Item>
          <div className="form-row">
          <Form.Item
            label="Category"
            style={{
              marginBottom: "0",
              flexDirection: "column",
              width: "100%",
            }}
            name="labels"
          >
            <Select
              placeholder="Select category"
              loading={loadingCategories}
              style={{
                width: "100%",
              }}
              disabled={readOnly}
              dropdownRender={(menu) => (
                <div>
                  {menu}
                  <Divider style={{ margin: "0" }} />
                  <AddCategory
                    handleAddCategory={({ category }) => {
                      setCategories([...categories, category]);
                      form.setFieldsValue({ labels: category });
                      updateWizard({ ...wizardState, labels: category });
                    }}
                  />
                </div>
              )}
            >
              {renderCategoryOptions()}
            </Select>
          </Form.Item>

          <Form.Item
            label="Severity"
            style={{
              marginBottom: "0",
              flexDirection: "column",
              width: "100%",
            }}
            name="severity"
          >
            <Select
              placeholder="Select severity"
              style={{
                width: "100%",
              }}
            >
              {renderSeverityOptions()}
            </Select>
          </Form.Item>
          </div>
        </div>

        <div className="form-row">
          <Form.Item
            label="Data Source"
            style={{
              marginBottom: "0",
              flexDirection: "column",
              width: "100%",
            }}
            name="providers"
          >
            <ProviderSelect
              selected={getSelectedProviders(wizardState?.providers)}
              onChange={(values) => {
                updateWizard({ ...wizardState, providers: values });
                handleFetchTypes(values);
              }}
              loading={loadingProviders}
              providers={providers}
              readOnly={readOnly}
            />
          </Form.Item>

          <Form.Item
            label="Asset Type"
            style={{
              marginBottom: "0",
              flexDirection: "column",
              width: "100%",
            }}
            name="scope"
          >
            <AssetTypeSelect
              selected={wizardState?.scope}
              onChange={(values) =>
                updateWizard({ ...wizardState, scope: values })
              }
              loading={loadingAssetTypes}
              types={assetTypes}
              readOnly={readOnly}
            />
          </Form.Item>
        </div>
        <Form.Item
          label={
            <span className="row g10">
              Policy description{" "}
              <Tooltip
                placement="top"
                title="Prompt a description of the desired rule you wish to create"
              >
                <FontAwesomeIcon icon="question-circle" />
              </Tooltip>
            </span>
          }
          name="description"
          style={{ marginBottom: "0", flexDirection: "column" }}
          rules={[
            {
              pattern: descriptionRegex.regex,
              message: descriptionRegex.msg,
            },
          ]}
        >
          {readOnly ? (
            <Input.TextArea disabled />
          ) : (
            <DescriptionBlock
              placeholder=""
              name="description"
              onClick={handleGenerateAi}
              loading={loadingAI}
              wizardState={wizardState}
            />
          )}
        </Form.Item>
      </Form>
    </div>
  );
};

export default InsightDetails;
