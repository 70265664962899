import React, { useEffect } from "react";
import { Trans, useTranslation } from "react-i18next";
import { Form, Input } from "antd";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { guidRegex, nicknameRegex, validate } from "../../../utils/validations";
import DarkCopyBox from "../../../shared/darkCopyBox/darkCopyBox";
import { validate as uuidValidate } from 'uuid';

const AzurePowershell = ({
  wizardState = {},
  setWizardState,
  setSubmitDisabled,
  isEditMode
}) => {
  const [form] = Form.useForm();
  const { t } = useTranslation("azure-integration");

  useEffect(() => {
    // this check is for users that doing next-prev, to keep their wizard form state aligned
    checkValidation(wizardState);
  }, []);

  const checkValidation = (formValues) => {
    let formValid = true;
    const {
      name,
      tenantId,
      applicationId,
      clientSecret,
      directoryDomain,
    } = formValues;

    if (
      isEmpty(name) ||
      isEmpty(tenantId) || 
      !uuidValidate(tenantId) ||
      isEmpty(applicationId) || 
      !uuidValidate(applicationId) ||
      isEmpty(clientSecret) ||
      isEmpty(directoryDomain) ||
      tenantId === applicationId
    ) {
      formValid = false;
    }

    if (!isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  const checkApplicationIdChange = obj => {
    if(has(obj, 'applicationId')) {
      form.setFieldsValue({clientSecret: null})
    }
  }

  const script = [
    `$subscriptionId = "${wizardState?.subscriptionId}"`,
    `$isEventDriven = $${wizardState?.isEventDriven ? "true" : "false"}`,
    `$scriptPath ="https://infralight-templates-public.s3.amazonaws.com/azure_onboarding.ps1"`,
    `$script = (New-Object System.Net.WebClient).DownloadString($scriptPath);`,
    `$scriptBlock = [Scriptblock]::Create($script);Invoke-Command -ScriptBlock $scriptBlock;`,
  ];

  return (
    <div className="AzurePowershell col">
      <div className="AzurePowershell__info col g20">
        <div className="col g8">
          <span className="bold font-16">{t("powershell-info.title")}</span>
          <span>
            {t("powershell-info.sub")}
            <a
              href="https://learn.microsoft.com/en-us/azure/cloud-shell/get-started?tabs=azurecli"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {t("powershell-info.sub-link")}
            </a>
            {t("powershell-info.sub2")}
          </span>
        </div>

        <ol className="col text g5">
          <li>
            <a
              href="https://portal.azure.com/#home"
              target="_blank"
              rel="noopener noreferrer"
              className="underline"
            >
              {t("powershell-info.u1")}
            </a>
          </li>
          <li>
            {t("powershell-info.u2")}
            <div className="AzurePowershell__info-script">
              <DarkCopyBox text={script.join("\n")} />
            </div>
            <p style={{color:"orange"}}>
              {t("powershell-info.u3")}
            </p>
          </li>
          <li>
            {t("powershell-info.u5.title")}
            <ul className="col g5">
              <li>{t("powershell-info.u5.2")}</li>
              <li>{t("powershell-info.u5.3")}</li>
              <li>{t("powershell-info.u5.4")}</li>
              <li>{t("powershell-info.u5.5")}</li>
            </ul>
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:powershell-info.u6"
              components={[<strong key="powershell-info.u6" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:powershell-info.u7"
              components={[<strong key="powershell-info.u7" />]}
            />
          </li>
          <li>
            <Trans
              i18nKey="azure-integration:powershell-info.u8"
              components={[<strong key="powershell-info.u8"/>]}
            />
          </li>
        </ol>
      </div>

      <Form
        name="azure-wizard-form"
        form={form}
        initialValues={{
          ...wizardState,
          name: wizardState.name || t("form.name-default-value"),
        }}
        className="AppWizard__content-body-form AzurePowershell__form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          if(isEditMode) {
            checkApplicationIdChange(changedValues)
          }
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label={t("form.name-label")}
          name="name"
          rules={[
            {
              required: true,
              message: t("form.name-error"),
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>
        <Form.Item
          label={t("form.tenant-label")}
          name="tenantId"
          rules={[
            {
              required: true,
              message: t("form.tenant-error"),
            },
            {
              pattern: guidRegex.regex,
              message: `Tenant ID ${guidRegex.msg}`,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t("form.tenant-label")} disabled={isEditMode} />
        </Form.Item>
        <Form.Item
          label={t("form.client-label")}
          name="applicationId"
          rules={[
            {
              required: true,
              message: t("form.client-error"),
            },
            {
              pattern: guidRegex.regex,
              message: `Client ID ${guidRegex.msg}`,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t("form.client-label")} />
        </Form.Item>
        <Form.Item
          label={t("form.secret-label")}
          name="clientSecret"
          rules={[
            {
              required: true,
              message: t("form.secret-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder={t("form.secret-label")} />
        </Form.Item>
        <Form.Item
          label={t("form.directory-label")}
          name="directoryDomain"
          rules={[
            {
              required: true,
              message: t("form.directory-error"),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t("form.directory-label")} />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AzurePowershell;
