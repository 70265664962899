import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import union from "lodash/union";
import forEach from "lodash/forEach";
import keys from "lodash/keys";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import {
  capitalizeFirst,
  formatAwsStringWithUnderscore,
  formatIacStatusTitle,
  getAllProvidersArray,
  renderOprhanStatus,
} from "../../../utils/formatting";
import FilterBadge from "../../../shared/filterBadge/filterBadge";

import "./filtersRowBadges.scss";
import { lowerCase } from "lodash";

const FiltersRowBadges = ({ screenFilters, handleRemoveFilter }) => {
  const [filtersArr, setFiltersArr] = useState([]);

  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const backends = useSelector((state) => state.iacStacksReducer.backends.hits);
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );
  const modules = useSelector((state) => state.iacStacksReducer.modules?.modules);

  useEffect(() => {
    arrangeFilters();
  }, [screenFilters, searchVal]);

  const renderModuleName = (moduleId) => {
    if (!isEmpty(modules)) {
      const module = modules?.find(mod => mod?.id === moduleId);
      return module ? module.moduleFolderPath : moduleId;
    }
    return moduleId;
  }

  const renderTitleByFilterType = (filterType, text) => {
    switch (filterType) {
      case "status":
        return renderOprhanStatus(text);
      case "isBeingUsed":
        return "Unused modules";
      case "crawlerId":
        let crawlerName = text;
        if (!isEmpty(backends)) {
          const crawler = backends?.find((backend) => backend?._id === text);
          crawlerName = crawler?.remoteIntegrationName;
        }
        return crawlerName;
      case "hasNotIntegratedProviders":
        return "Not Integrated";
      case "hasIntegratedProviders":
        return "Integrated";
      case "hasNotSupported":
        return "Not Supported";
      case "stackProviders.providerIntegrationId":
        let integName = text;
        if (!isEmpty(providerIntegrations)) {
          let integrations = getAllProvidersArray(providerIntegrations);
          const integration = integrations.find((integ) => integ?.id === text);
          integName = integration?.name;
        }
        return integName;


      case "stackAssetTypes":
        return formatAwsStringWithUnderscore(text);
      case "iacState":
        return `Contains ${formatIacStatusTitle(text)}`;
      case "stackProviders.providerAccountId":
        return text;
      case "metadata.terraformMetadata.usedModules.id":
        return renderModuleName(text);
      case "metadata.helmMetadata.namespace":
        return text;
      case "_id":
        return renderModuleName(text);
      case "moduleOrigin":
      case "metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullName":
      case "repoFullName":
        return text;
      case "misconfigurations":
        return capitalizeFirst(lowerCase(text));
      default:
        return capitalizeFirst(text);
    }
  };

  const arrangeFilters = () => {
    let newFilters = [];

    forEach(keys(screenFilters), (filterType) => {
      if (filterType?.startsWith("has")) {
        newFilters.push({
          id: filterType,
          type: filterType,
          text: renderTitleByFilterType(filterType, filterType),
          onRemove: () => handleRemoveFilter(filterType, filterType),
          tooltip: null,
        });
      } else {
        if (filterType !== "stateType") {
          newFilters = union(
            newFilters,
            map(screenFilters[filterType] || [], (filter) => ({
              id: filter,
              key: filter,
              type: filterType,
              text: renderTitleByFilterType(filterType, filter),
              onRemove: () => handleRemoveFilter(filterType, filter),
              tooltip: null,
            }))
          );
        }
      }
    });

    if (!isEmpty(searchVal)) {
      newFilters.push({
        id: "searchVal",
        type: "search",
        text: searchVal,
        onRemove: () => handleRemoveFilter("search"),
        tooltip: null,
      });
    }

    return setFiltersArr(newFilters);
  };

  return (
    <div className="FiltersRowBadges row">
      {map(filtersArr || [], (item) => {
        return (
          <FilterBadge
            text={item?.text}
            key={item?.text}
            type={item?.type}
            tooltip={item?.tooltip}
            onClick={item?.onRemove}
          />
        );
      })}
    </div>
  );
};

export default FiltersRowBadges;
