import React from "react";
import isEmpty from "lodash/isEmpty";
import has from "lodash/has";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./suspendedBarAlert.scss";

const SuspendedBarAlert = () => {
  const account = useSelector((state) => state.onbordingReducer.account);

  if (
    !isEmpty(account) &&
    has(account, "active") &&
    !account?.active
  ) {
    return (
      <div className="SuspendedBarAlert row center">
        <FontAwesomeIcon icon="ban" />
        {`Suspended account - ${account?.name}`}
      </div>
    );
  }

  return null;
};

export default SuspendedBarAlert;
