import React, { useEffect } from "react";
import { v4 as uuidv4 } from "uuid";
import DarkCopyBox from "../../../shared/darkCopyBox/darkCopyBox";
import { useSelector } from "react-redux";

const K8sFinalInstructions = ({ wizardState, setSubmitDisabled }) => {
  const accountId = useSelector((state) => state.onbordingReducer.account?._id);

  useEffect(() => {
    setSubmitDisabled(false);
  });

  const { code: k8sCode, argoDomain, argoToken, awsBucket, awsBucketRegion  } = wizardState || {};

  const argoScriptAddition = wizardState.withArgo ?
  ` --set argocd.enabled=true --set argocd.image.tag=1.1.0 --set argocd.metadata.accountId=${accountId} --set argocd.metadata.crawlerId=${uuidv4()} --set argocd.domain=${argoDomain} --set argocd.token=${argoToken} --set argocd.redactorMirrorBucketName=${awsBucket} --set argocd.redactorMirrorBucketRegion=${awsBucketRegion}`
  : ''
  const codeScript = `helm repo add firefly https://gofireflyio.github.io/k8s-collector && ${k8sCode}${argoScriptAddition}`

  return (
    <div className="K8sFinalInstructions col">
      <div className="K8sFinalInstructions__intro col">
        <span className="small-dark-blue">
          Firefly's Kubernetes collector gathers information from your
          Kubernetes cluster and sends it to Firefly.
        </span>
        <span className="small-dark-blue">
          To install the collector on your Kubernetes cluster, copy and run the
          following commands in the cluster's command line:
        </span>
      </div>
      <div className="K8sFinalInstructions__cmds col" style={{ maxWidth: '600px', width: '50%' }}>
        <div className="K8sFinalInstructions__cmds-item col">
          <DarkCopyBox
            text={codeScript}
          />
          <span className="small-dark-blue">
            Select <span className="bold">Done</span>.
          </span>
        </div>
      </div>
    </div>
  );
};

export default K8sFinalInstructions;
