import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_DATADOG_INTEGRATIONS = "GET_DATADOG_INTEGRATIONS";
export const CREATE_DATADOG_INTEGRATION = "CREATE_DATADOG_INTEGRATION";
export const DELETE_DATADOG_INTEGRATION = "DELETE_DATADOG_INTEGRATION";
export const EDIT_DATADOG_INTEGRATION = "EDIT_DATADOG_INTEGRATION";

const getDatadogIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/datadog`,
        "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_DATADOG_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

  const createDatadogIntegration = (name, APIKey, APPKey, site) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/datadog/`,
        "POST",
        {
          name,
          APIKey,
          APPKey,
          site
        }
    );

    dispatch({
      type: CREATE_DATADOG_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteDatadogIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/datadog/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_DATADOG_INTEGRATION,
      payload: {},
    });
  });
};

const editDatadogIntegration = (id, name, fetchable) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/datadog/${id}`,
      "PUT",
    {name, fetchable}
    );

    dispatch({
      type: EDIT_DATADOG_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};


export { getDatadogIntegrations, createDatadogIntegration, deleteDatadogIntegration, editDatadogIntegration };
