import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import { CODE_COMMIT_TEMPLATE } from "../../../consts/config";
import AppWizard from "../../../shared/appWizard/appWizard";
import { integrationIcons } from "../../../utils/icons";
import CodeCommitConfiguration from "./codecommitConfiguration";
import "./codecommitIntegration.scss"
import CodeCommitValidation from "./codecommitValidation";

const STACK_NAME = "firefly-codecommit";
const RESOURCE_NAME_PREFIX = "infralight";
const REGION = "us-east-1";

const CodeCommitIntegration = () => {
  const history = useHistory();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const externalId = useSelector((state) => state.onbordingReducer?.account?.awsIntegrationExternalId);

  const getLaunchLink = () => {
    return `https://${REGION}.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=${CODE_COMMIT_TEMPLATE}&param_ExternalID=${externalId}&stackName=${STACK_NAME}&param_ResourceNamePrefix=${RESOURCE_NAME_PREFIX}`;
  };

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Setup AWS CodeCommit Integration",
      content_title: "Setup AWS CodeCommit Integration",
      content: (
        <CodeCommitConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        window.open(getLaunchLink(), "_blank");
      },
    },
    {
      step_title: "Validation",
      step_description: "Stack Validation",
      content_title: "Stack Validation",
      content: (
        <CodeCommitValidation
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    }
  ];

  return (
    <div className="CodeCommitIntegration">
      <AppWizard
        iconSrc={integrationIcons["codecommit"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        customFirstNextText={
          <span>
            Launch Stack
            <FontAwesomeIcon
              icon="arrow-circle-right"
              style={{ marginLeft: "10px" }}
            />
          </span>
        }
      />
    </div>
  );
};

export default CodeCommitIntegration;
