import React, { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./appAccordion.scss";

const AppAccordion = ({ header, content, card, isAllActive, noPadding, headerClassName, headerContainerClassName }) => {
  const [isActive, setIsActive] = useState(isAllActive);
  return (
    <div className={`AppAccordion ${card && "basic-card"} ${!isActive && "notActive"} ${noPadding && "noPadding"}`}>
      <div
        className={`${headerContainerClassName && headerContainerClassName} AppAccordion__header row between`}
        onClick={() => setIsActive(!isActive)}
      >
        <div className={headerClassName ? headerClassName : ""}>{header}</div>
        <span className="AppAccordion__header-icon">
          <FontAwesomeIcon
            icon="chevron-down"
            style={{ transform: isActive ? "rotate(-90deg)" : "" }}
          />
        </span>
      </div>
      <div className={`AppAccordion__content ${!isActive ? "notActive" : ''} ${noPadding && "noPadding"}`}>
        {content}
      </div>
    </div>
  );
};

export default AppAccordion;
