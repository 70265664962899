import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import {BACKEND_URL} from "../../consts/config";
import _ from 'lodash';

export const GET_K8S_INTEGRATIONS = "GET_K8S_INTEGRATIONS";
export const GET_K8S_INTEGRATION_FETCHING_STATUS = "GET_K8S_INTEGRATION_FETCHING_STATUS";
export const CREATE_K8S_INTEGRATION = "CREATE_K8S_INTEGRATION";
export const DELETE_K8S_INTEGRATION = "DELETE_K8S_INTEGRATION";
export const EDIT_K8S_INTEGRATION = "EDIT_K8S_INTEGRATION";

const getK8sIntegrations = (isFetchingPrecentage) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s/?isFetchingPrecentage=${!_.isUndefined(isFetchingPrecentage)}`,
      "GET"
    );
    const integrations = await integrationsReq.json();

    dispatch({
      type: GET_K8S_INTEGRATIONS,
      payload: { integrations },
    });

    return integrations;
  });
};

export const getK8sFetchingMetadata = (integrationId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const fetchingMetadataReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/inventory/fetching/k8s/${integrationId}`,
      "GET"
    );
    const metadata = await fetchingMetadataReq.json();

    dispatch({
      type: GET_K8S_INTEGRATION_FETCHING_STATUS,
      payload: { hasError: !fetchingMetadataReq.ok, metadata },
    });

    return metadata;
  });
};

const createK8sIntegration = (clusterId, accessKey, name, isProd, argoData) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s`,
      "POST",
      { clusterId, accessKey, name, isProd, argoData }
    );

    dispatch({
      type: CREATE_K8S_INTEGRATION,
      payload: {},
    });

    return req.ok;
  });
};

const deleteK8sIntegration = (clusterId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s/${clusterId}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_K8S_INTEGRATION,
      payload: {},
    });
  });
};

const editK8sIntegration = (clusterId, name, fetchable, isProd) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/k8s/${clusterId}`,
      "PUT",
    {name, fetchable, isProd}
    );

    dispatch({
      type: EDIT_K8S_INTEGRATION,
      payload: {},
    });
    return req.ok;
  });
};

export { getK8sIntegrations, createK8sIntegration, deleteK8sIntegration, editK8sIntegration };
