import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Form, Checkbox, Select } from "antd";
import { formatAwsStringWithUnderscore, providerNameFormatter } from "../../../../utils/formatting";
import { appToast } from "../../../../shared/appToast/appToast";
import { createExclusion } from "../../../../redux/actions/driftsExclusionsActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEyeSlash } from "@fortawesome/free-solid-svg-icons";

import AppModal from "../../../../shared/appModal/appModal";

import "./driftExclusionModal.scss";
import ProviderIcon from "../../../../shared/providerIcon/providerIcon";

const DriftExclusionModal = ({
  visible,
  data,
  properties,
  handleCloseModal,
  isPropertyExcluded
}) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formValid, setFormValid] = useState(false);
  const [selectedScope, setSelectedScope] = useState("");
  const [selectedProperties, setSelectedProperties] = useState([]);
  const [specificArn, setSpecificArn] = useState(false);
  const [providers, setProviders] = useState([]);
  const [selectedProviders, setSelectedProviders] = useState([]);

  const scopeOptions = {
    global: `All ${providerNameFormatter(data?.provider)} Assets`,
    type: `${formatAwsStringWithUnderscore(data?.assetType)}`,
    arn: "This specific ARN",
  };

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const [form] = Form.useForm();

  const dispatch = useDispatch();

  useEffect(() => {
    if (data?.state !== 'modified'){
      return
    }
    form.resetFields();
    setFormValid(false);
    form.setFieldsValue({ scope: "type" });
    setSelectedScope("type");
    setSpecificArn(false);
    let driftProviders = _.map(providerIntegrations[data?.provider], integ => {
      return integ?.accountNumber;
    });
    setProviders(driftProviders);
  }, [visible, data]);

  useEffect(() => {
    if (!specificArn) {
      if (_.isEmpty(selectedProviders)) {
        return setFormValid(false);
      }
    }
    if (_.isEmpty(selectedProperties)) {
      return setFormValid(false);
    }

    if (_.isEmpty(selectedScope)) {
      return setFormValid(false);
    }
    return setFormValid(true);
  }, [selectedProperties, selectedProviders, specificArn, selectedScope]);

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    let globalScope = `${data?.provider}objects`;
    const res = await dispatch(
      createExclusion(
        selectedScope,
        selectedProperties,
        data?.assetType,
        selectedProviders,
        data?.arn,
        globalScope
      )
    );
    if (!res?.ok) {
      return setLoadingSubmit(false);
    }
    setLoadingSubmit(false);
    appToast(
      "info",
      "Marked as excluded drifts",
      "Firefly will exclude this property from the Drift Detection. It may take up to an hour for the change to take effect."
    );
    handleCloseModal();
  };

  const renderIntegration = (accountId) => {
    let match = providerIntegrations[data?.provider]?.find(integ => integ.accountNumber === accountId);
    return (
      <span className="DriftExclusionModal__provider row">
        <ProviderIcon provider={data?.provider} />
        {match ? match?.name : "-"}
      </span>
    );
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleCloseModal}
      title="Exclude Drift"
      subtitle="Disable drift detection for the following properties:"
      submitBtnText="Exclude"
      submitBtnDisabled={!formValid}
      handleOnSubmit={handleSubmit}
      loadingSubmit={loadingSubmit}
      form="exclude-drifts"
      bodyClassName="DriftExclusionModal"
      submitBtnIcon={
        <FontAwesomeIcon icon={faEyeSlash} style={{ marginRight: "10px" }} />
      }
      width="60vh"
      zIndex={1001}
    >
      <Form
        name="exclude-drifts"
        form={form}
        className="content-wrapper"
        onValuesChange={(changedValues) => {
          // if the change was the value of selected all
          if (!_.isUndefined(changedValues.selectAllProperties)) {
            if (changedValues.selectAllProperties) {
              form.setFieldsValue({ properties: properties });
              setSelectedProperties(properties);
              setFormValid(true);
            } else {
              form.setFieldsValue({ properties: [] });
              setSelectedProperties([]);
            }
          }
          if (!_.isUndefined(changedValues.selectAllProviders)) {
            if (changedValues.selectAllProviders) {
              form.setFieldsValue({ providers: providers });
              setSelectedProviders(providers);
              setFormValid(true);
            } else {
              form.setFieldsValue({ providers: [] });
              setSelectedProviders([]);
            }
          }
          if (!_.isUndefined(changedValues.scope)) {
            setSelectedScope(form.getFieldValue("scope"));
            form.setFieldsValue({ providers: [] });
            setSelectedProviders([]);
            if (form.getFieldValue("scope") === "arn") {
              setSpecificArn(true);
            } else {
              setSpecificArn(false);
            }
          }
          if (!_.isUndefined(changedValues.properties)) {
            setSelectedProperties(form.getFieldValue("properties"));
          }
          if (!_.isUndefined(changedValues.providers)) {
            setSelectedProviders(form.getFieldValue("providers"));
          }
        }}
      >
        <Form.Item
          label="Scope"
          name="scope"
          rules={[
            {
              required: true,
              message: "Must select a scope",
            },
          ]}
          style={{
            marginBottom: "0.6rem",
            flexDirection: "column",
            flex: "0.8",
          }}
        >
          <Select
            placeholder="Select Scope"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(Object.keys(scopeOptions), (scope) => (
              <Select.Option key={uuidv4()} value={scope}>
                {scopeOptions[scope]}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label="Properties"
          name="properties"
          rules={[
            {
              required: true,
              message: "Must exclude at least one property",
            },
          ]}
          style={{
            marginBottom: "0.6rem",
            flexDirection: "column",
            flex: "0.8",
          }}
        >
          <Select
            placeholder="Select Properties"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            mode="multiple"
            disabled={_.isEmpty(properties)}
          >
            {!_.isEmpty(properties) &&
              _.map(properties, (property) => {
                if (!isPropertyExcluded(property)) {
                  return (
                    <Select.Option key={uuidv4()} value={property}>
                      {property}
                    </Select.Option>
                  );
                }
              })}
          </Select>
        </Form.Item>
        <Form.Item
          name="selectAllProperties"
          valuePropName="checked"
          style={{
            marginBottom: specificArn ? "0" : "0.6rem",
            marginTop: specificArn ? "1.2rem" : "0",
          }}
        >
          <Checkbox checked={false} disabled={_.isEmpty(properties)}>
            <span className="purple-text">Select All</span>
          </Checkbox>
        </Form.Item>
        {!specificArn && (
          <span>
            <Form.Item
              label="Data sources"
              name="providers"
              rules={[
                {
                  required: !specificArn,
                  message: "Must select at least one account",
                },
              ]}
              style={{
                marginBottom: "0.6rem",
                flexDirection: "column",
                flex: "0.8",
              }}
            >
              <Select
                placeholder="Select Data sources"
                showSearch
                filterOption={(input, option) =>
                  option.children.toLowerCase().indexOf(input.toLowerCase()) >=
                  0
                }
                mode="multiple"
                disabled={_.isEmpty(providers)}
              >
                {!_.isEmpty(providers) &&
                  _.map(providers, (accountId) => {
                    return (
                      <Select.Option key={uuidv4()} value={accountId}>
                        {renderIntegration(accountId)}
                      </Select.Option>
                    );
                  })}
              </Select>
            </Form.Item>
            <Form.Item
              name="selectAllProviders"
              valuePropName="checked"
              style={{ marginBottom: "0", marginTop: "0.5em" }}
            >
              <Checkbox checked={false} disabled={_.isEmpty(providers)}>
                <span className="purple-text">Select All</span>
              </Checkbox>
            </Form.Item>
          </span>
        )}
      </Form>
    </AppModal>
  );
};

export default React.memo(DriftExclusionModal);
