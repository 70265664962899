import React, { memo } from "react";
import { Tooltip } from "antd";

const AssetNameCell = ({ name = "", description = "" }) => {
  return (
    <Tooltip title={description} placement="topLeft">
      <div className="AssetNameCell center" style={{ height: "24px" }}>
        {name}
      </div>
    </Tooltip>
  );
};

export default memo(AssetNameCell);
