import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_NEWRELIC_INTEGRATIONS = "GET_NEWRELIC_INTEGRATIONS";
export const CREATE_NEWRELIC_INTEGRATION = "CREATE_NEWRELIC_INTEGRATION";
export const DELETE_NEWRELIC_INTEGRATION = "DELETE_NEWRELIC_INTEGRATION";
export const EDIT_NEWRELIC_INTEGRATION = "EDIT_NEWRELIC_INTEGRATION";

const getNewrelicIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/newrelic`,
        "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_NEWRELIC_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

  const createNewrelicIntegration = (name, APIKey, accountNumber, region) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/newrelic/`,
        "POST",
        {
          name,
          APIKey,
          accountNumber,
          region
        }
    );

    dispatch({
      type: CREATE_NEWRELIC_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteNewrelicIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/newrelic/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_NEWRELIC_INTEGRATION,
      payload: {},
    });
  });
};

const editNewrelicIntegration = (id, name, fetchable) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/newrelic/${id}`,
      "PUT",
    {name, fetchable}
    );

    dispatch({
      type: EDIT_NEWRELIC_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};


export { getNewrelicIntegrations, createNewrelicIntegration, deleteNewrelicIntegration, editNewrelicIntegration };
