import React, { useEffect } from "react";
import { Form, Input, Button } from "antd";
import { nameWithSpaceRegex } from "../../../utils/validations";
import "./addCategory.scss";

const AddCategory = ({ handleAddCategory }) => {
  const [form] = Form.useForm();

  useEffect(() => {
    form.resetFields();
  }, []);

  return (
    <div className="AddCategory">
      <Form
        name="category-form"
        form={form}
        className="row g10"
        onFinish={(values) => {
          handleAddCategory(values);
          form.resetFields();
        }}
      >
        <Form.Item
          name="category"
          rules={[
            {
              pattern: nameWithSpaceRegex.regex,
              message: nameWithSpaceRegex.msg,
            },
          ]}
          style={{ marginBottom: "0", width: "100%" }}
        >
          <Input placeholder="New Category" allowClear />
        </Form.Item>
        <Form.Item style={{ marginBottom: "0" }}>
          <Button type="primary" htmlType="submit">
            Add
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};

export default AddCategory;
