import { isEmpty, upperCase, keys } from "lodash";
import { iacProviders } from "../../../../utils/icons";
import { IAC_TYPES } from "../../../../consts/general";

const HELM_STATUSES_CATEGORIES = {
    deployed: "success",
    synced: "success",
    pending: "progress",
    uninstalling: "progress",
    unknown: "unknown",
};

const getHelmSuccessOrFail = (status) => {
    const statusWords = status?.split("-");
    if (keys(HELM_STATUSES_CATEGORIES)?.includes(statusWords[0])) {
      return HELM_STATUSES_CATEGORIES[status];
    }
    return "failure";
};

const getColumnsByK8sType = (type) => type === IAC_TYPES.helm ? [{
    headerName: "Chart Status",
    field: "stateMainRemoteLocationString",
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return (
        <ul
          className={`IacStacksTable__statusFlag ${getHelmSuccessOrFail(
            params?.row?.metadata?.stateStatus
          )}`}
        >
          <li className="bold">
            {upperCase(params?.row?.metadata?.stateStatus)}
          </li>
        </ul>
      );
    },
  },
  {
    headerName: "Chart",
    field: "isDeleted",
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return params?.row?.metadata?.helmMetadata?.chart
        ? params?.row?.metadata?.helmMetadata?.chart
        : "";
    },
  },
  {
    headerName: "Chart Version",
    field: "isExcluded",
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return params?.row?.metadata?.helmMetadata?.version;
    },
  },
  {
    headerName: "App Version",
    field: "lastScanned",
    flex: 1,
    filterable: false,
    renderCell: (params) => {
      return params?.row?.metadata?.helmMetadata?.appVersion || "";
    },
  },
] : [];

export const k8sConfig = (mutualColumns = {}, commonFinalColumns, k8sIacType, integrations, themeDark) => [
    mutualColumns.name?.k8s,
    ...getColumnsByK8sType(k8sIacType),
    {
      headerName: "Revision",
      field: "remoteIntegrationId",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const metadata = k8sIacType === IAC_TYPES.helm ? params?.row?.metadata?.helmMetadata : params?.row?.metadata?.k8sMetadata;
        return metadata?.revision ? `#${metadata?.revision}` : "";
      },
    },
    {
      headerName: "Cluster",
      field: "",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const providerAccountId = params?.row?.stackProviders
          ? params?.row?.stackProviders[0]?.providerAccountId
          : "";
          const match = integrations.find((integ) => integ.accountNumber === providerAccountId);
        if (!isEmpty(providerAccountId)) {
          return (
            <div className="row" style={{ gap: "10px" }}>
              <div className="IacStacksTable__item-cloudIcon">
                {!isEmpty(params?.row?.stackProviders[0]?.providerType) && (
                  <img
                    src={iacProviders(
                      params?.row?.stackProviders[0]?.providerType,
                      themeDark
                    )}
                    className={`IacStacksTable__cloudIcon ${params?.row?.stackProviders[0]?.providerType}`}
                    alt="iac icon"
                    style={{ height: "15px" }}
                  />
                )}
              </div>
              {match?.name ? match?.name : providerAccountId}
            </div>
          );
        }
      },
    },
    {
      headerName: "Namespace",
      field: "stateSubRemoteLocationString",
      flex: 1,
      filterable: false,
      renderCell: (params) => {
        const namespace = k8sIacType === IAC_TYPES.helm ? params?.row?.metadata?.helmMetadata?.namespace : params?.row?.metadata?.k8sMetadata?.k8sNamespace;
        return namespace || "";
      },
    },
  ].concat(commonFinalColumns)