import React, { useMemo, useState, useEffect } from "react";
import _ from "lodash";
import { Tooltip } from "antd";
import { numberWithCommas } from "../../utils/formatting";
import "./assetIacLine.scss";

const AssetIacLine = ({ data }) => {
  const [chart, setChart] = useState({
    total: 0,
    columns: [
      {
        status: "Codified",
        key: "managed",
        sum: 0,
      },
      {
        status: "Drifted",
        key: "modified",
        sum: 0,
      },
      {
        status: "Unmanaged",
        key: "unmanaged",
        sum: 0,
      },
      {
        status: "Ghost",
        key: "ghost",
        sum: 0,
      },
    ],
  });

  useEffect(() => {
    handleSetChartData();
  }, [data]);

  const handleSetChartData = () => {
    if (!_.isEmpty(data)) {
      const total = data.reduce(function (acc, obj) {
        if (obj?.key !== "pending") {
          return acc + obj?.doc_count;
        }
        return acc;
      }, 0);

      const columns = _.map(chart?.columns || [], (item) => {
        const findSum = _.find(
          data || [],
          (i) => i?.key === item?.key
        )?.doc_count;
        let sum = _.isUndefined(findSum) ? 0 : findSum;
        let precent = sum > 0 ? (sum / total) * 100 : 0;
        return { ...item, sum, precent };
      });

      return setChart({ total, columns });
    }
  };

  const renderChart = useMemo(() => {
    if (_.isEmpty(data)) {
      return "-";
    }
    return _.map(chart?.columns || [], (x) => {
      return (
        <div
          key={x?.key}
          className={`AssetIacLine__block ${x?.key}`}
          style={{ width: `${x?.precent}%` }}
        />
      );
    });
  }, [chart]);

  const renderTooltip = useMemo(() => {
    if (_.isEmpty(data)) {
      return null;
    }
    return (
      <div className="AssetIacLine__tooltip col">
        {_.map(chart?.columns || [], (item) => {
          if (item?.sum > 0) {
            return (
              <div
                key={item?.key}
                className={`AssetIacLine__tooltip-item row between ${item?.key}`}
              >
                <div className="row" style={{ gap: "5px" }}>
                  <span
                    className={`AssetIacLine__tooltip-item-dot ${item?.key}`}
                  />
                  <span>{item?.status}</span>
                </div>
                <span>{numberWithCommas(item?.sum)}</span>
                {/* <span>{formatIfDecimal(item?.precent)}%</span> */}
              </div>
            );
          }
        })}
      </div>
    );
  }, [chart]);

  return (
    <Tooltip title={_.isEmpty(data) ? null : renderTooltip}>
      <div className="AssetIacLine row">{renderChart}</div>
    </Tooltip>
  );
};

export default AssetIacLine;
