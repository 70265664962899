import React, { useEffect } from "react";
import { Form, Input } from "antd";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import './workflowsSteps.scss';

const TerraformWorkflowConfiguration = ({ wizardState = {}, setWizardState, setSubmitDisabled }) => {
    const [form] = Form.useForm();

    useEffect(() => {
      checkValidation();
    }, [wizardState]);

    const checkValidation = () => {
        const { tfVersion } = wizardState;
        const formValid = tfVersion;
        setSubmitDisabled(!formValid);
    }

    const onValuesChange = (changedValues) => {
        setWizardState({ ...wizardState, ...changedValues });
    };

    const onTfElementChange = (newVal = {}, isSensitiveArr) => {
        const { tfVariables = [], tfSensitiveLabels = [] } = wizardState;
        const arrToMap = isSensitiveArr ? tfSensitiveLabels : tfVariables;
        const updatedTfVarArray = arrToMap.map((tfVar) => { return tfVar.key === newVal.key ? newVal : tfVar });
        const updatedKey = isSensitiveArr ? 'tfSensitiveLabels' : 'tfVariables';
        onValuesChange({ [updatedKey]: updatedTfVarArray });
    }

    const handleDeleteTfElement = (key, isSensitiveArr) => {
        const { tfVariables = [], tfSensitiveLabels = [] } = wizardState;
        const arrayToFilter = isSensitiveArr ? tfSensitiveLabels : tfVariables;
        const updatedTfVarArray = arrayToFilter.filter((tfVar) => tfVar.key !== key);
        const updatedKey = isSensitiveArr ? 'tfSensitiveLabels' : 'tfVariables';
        onValuesChange({ [updatedKey]: updatedTfVarArray });
    }

    const onAddNewTfElements = (isMultiple, isSensitiveArr) => {
        const newVars = [];
        newVars.push({ name: '', value: '', key: uuidv4() });
        if (isMultiple) {
            newVars.push({ name: '', value: '',  key: uuidv4()}, { name: '', value: '',  key: uuidv4()});
        }
        const { tfVariables = [], tfSensitiveLabels = [] } = wizardState;
        const arrayKey = isSensitiveArr ? 'tfSensitiveLabels' : 'tfVariables';
        const oldArrayValue = isSensitiveArr ? tfSensitiveLabels : tfVariables;
        onValuesChange({ [arrayKey]: [...oldArrayValue, ...newVars] });
    };

    return (
        <div className="WorkflowsSteps col">
           <span className="bold">Terraform configuration</span>
           <span className="form-label">Set a Terraform configuration</span>
           <div className="col g10">
           <Form
                name="tf-config-form"
                form={form}
                initialValues={{
                    tfVarsPath: wizardState.tfVarsPath,
                    tfVersion: wizardState.tfVersion,
                    tfWorkspaceName: wizardState.tfWorkspaceName,
                }}
           >
                <Form.Item
                    label="Terraform Version"
                    name="tfVersion"
                    style={{ flexDirection: "column" }} 
                >
                  <Input placeholder="Terraform Version" value={wizardState.tfVersion || ""} onChange={(e) => onValuesChange({ tfVersion: e.target.value })}/>
                </Form.Item>

                <Form.Item
                    label="TF Vars file path (optional)"
                    name="tfVarsPath"
                    style={{ flexDirection: "column" }} 
                >
                  <Input placeholder="TF Vars file path " value={wizardState.tfVarsPath || ""} onChange={(e) => onValuesChange({ tfVarsPath: e.target.value })}/>
                </Form.Item>

                <Form.Item
                    label="Terraform Workspace name (optional)"
                    name="tfWorkspaceName"
                    style={{ flexDirection: "column" }} 
                >
                  <Input placeholder="Terraform Workspace name" value={wizardState.tfWorkspaceName || ""} onChange={(e) => onValuesChange({ tfWorkspaceName: e.target.value })}/>
                </Form.Item>

                <Form.Item
                    label="Terraform Variables (optional)"
                    name="tfVariables"
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Variable name</span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Variable value</span>
                    </div>
                    <div className="col g10">
                        {(wizardState.tfVariables || []).map((tfVar = {}) => {
                            return (
                                <div className="row g10" key={tfVar.key}>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Variable name" value={tfVar.name} onChange={(e) => onTfElementChange({ ...tfVar, name: e.target.value  })}/>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Variable value" value={tfVar.value} onChange={(e) => onTfElementChange({ ...tfVar, value: e.target.value })}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteTfElement(tfVar.key)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewTfElements()}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewTfElements(true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
                <Form.Item
                    label="Terraform Sensitive labels (optional)"
                    name="tfSensitiveLabels"
                    style={{ flexDirection: "column" }}
                >
                  <div className="col g5">
                    <div className="row">
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Secret name</span>
                        <span className="WorkflowsSteps__tfConfiguration-vars-title">Secret value (VCS)</span>
                    </div>
                    <div className="col g10">
                        {(wizardState.tfSensitiveLabels || []).map((tfSensitive = {}) => {
                            return (
                                <div className="row g10" key={tfSensitive.key}>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret name" value={tfSensitive.name} onChange={(e) => onTfElementChange({ ...tfSensitive, name: e.target.value  }, true)}/>
                                    <Input className="WorkflowsSteps__tfConfiguration-vars-input" placeholder="Enter Secret value (VCS)" value={tfSensitive.value} onChange={(e) => onTfElementChange({ ...tfSensitive, value: e.target.value }, true)}/>
                                    <FontAwesomeIcon icon="times" className="WorkflowsSteps-deleteBtn" onClick={() => handleDeleteTfElement(tfSensitive.key, true)}/>
                                </div>
                            )
                        })}
                    </div>
                    <div className="WorkflowsSteps__tfConfiguration-addVars row g10">
                        <FontAwesomeIcon icon="plus" className="WorkflowsSteps-addBtn"/>
                        <div className="WorkflowsSteps-addVarsTexts row g5">
                            <span className="WorkflowsSteps-addText" onClick={() => onAddNewTfElements(false, true)}>Add new</span>
                            <span className="WorkflowsSteps-orText">or</span>
                            <span className="WorkflowsSteps-addText"onClick={() => onAddNewTfElements(true, true)}>multiple at once</span>
                        </div>
                    </div>
                  </div>
                </Form.Item>
         </Form>
        </div>
       </div>
    );
};

export default TerraformWorkflowConfiguration;