import action from "../middleware";

import { BACKEND_URL } from '../../consts/config';
import CookiesManager from '../../utils/cookiesManager';

export const GET_BY_ENVIRONMENT = "GET_BY_ENVIRONMENT";

const getByEnvironment = (environmentId) => {
    return action(async dispatch => {

        const cookieMgr = new CookiesManager();
        const accessToken = cookieMgr.getAccessToken();

        const requestOptions = {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const response = await fetch(`${BACKEND_URL}/variables/${environmentId}`, requestOptions);
        const data = await response.json();

        dispatch({
            type: GET_BY_ENVIRONMENT,
            payload: { data }
        });

    });
}

const deleteById = (variableId) => {
    return action(async dispatch => {

        const cookieMgr = new CookiesManager();
        const accessToken = cookieMgr.getAccessToken();

        const requestOptions = {
            method: 'DELETE',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            }
        };

        const response = await fetch(`${BACKEND_URL}/variables/${variableId}`, requestOptions);
        await response.json();
    });
}

const create = (key, value, encrypted, description, environmentId) => {
    return action(async dispatch => {

        const cookieMgr = new CookiesManager();
        const accessToken = cookieMgr.getAccessToken();

        const payload = {
            key,
            value,
            description,
            encrypted
        };
        
        const requestOptions = {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Bearer ${accessToken}`
            },
            body: JSON.stringify(payload)
        };

        const response = await fetch(`${BACKEND_URL}/variables/${environmentId}`, requestOptions);
        await response.json();
    });
}

export {
    getByEnvironment,
    deleteById,
    create
}
