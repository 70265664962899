import React, { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Avatar, Form, Input, Button } from "antd";
import _ from "lodash";
import {
  createComment,
  deleteComment,
  updateComment,
} from "../../../../redux/actions/commentsActions";
import {
  renderInitalFromName,
  initalColorsByFirstLetter,
} from "../../../../utils/helpers";
import AppComment from "../../../../shared/appComment/appComment";
import "./singleComment.scss";

const SingleComment = ({ data, newComment, asset, refresh }) => {
  const [mode, setMode] = useState("view");
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [loadingDelete, setLoadingDelete] = useState(false);
  const { user } = useAuth0();
  const [form] = Form.useForm();
  const layout = {};
  const { TextArea } = Input;
  const inputRef = useRef(null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (newComment) {
      setMode("new");
    }
  }, []);

  useEffect(() => {
    if (mode === "new") {
      form.setFieldsValue({ comment: null });
      inputRef.current.focus();
    }
    if (mode === "edit") {
      form.setFieldsValue({ comment: data?.comment });
      inputRef.current.focus();
    }
  }, [mode]);

  const commentForm = () => {
    return (
      <Form
        {...layout}
        name="new-comment-form"
        form={form}
        initialValues={{
          comment: mode === "edit" ? data?.comment : null,
        }}
        onFinish={mode === "new" ? handleSubmit : handleUpdate}
      >
        <Form.Item
          label=""
          name="comment"
          rules={[
            {
              required: true,
              message: "Comment cannot be empty",
              whitespace: true,
              validateTrigger: "onSubmit",
            },
          ]}
          style={{ marginBottom: ".5rem", flexDirection: "column" }}
        >
          <TextArea rows={3} placeholder="Write comment" ref={inputRef} />
        </Form.Item>
        <Form.Item style={{ marginBottom: "0" }}>
          <Button type="primary" htmlType="submit" loading={loadingSubmit}>
            {mode === "edit" ? "Update" : "Post comment"}
          </Button>
          {mode === "edit" && (
            <Button type="text" onClick={() => setMode("view")}>
              Cancel
            </Button>
          )}
        </Form.Item>
      </Form>
    );
  };

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    await dispatch(
      createComment(
        asset?.integrationId,
        asset?.frn,
        asset?.assetType,
        values?.comment,
        user?.sub,
        user?.nickname,
        user?.email
      )
    );
    setLoadingSubmit(false);
    form.setFieldsValue({ comment: null });
    refresh();
  };

  const handleUpdate = async (values) => {
    setLoadingSubmit(true);
    await dispatch(updateComment(data?.id, values?.comment));
    setMode("view");
    setLoadingSubmit(false);
    refresh();
  };

  const handleDelete = async () => {
    setLoadingDelete(true);
    await dispatch(
      deleteComment(
        data?.id,
        asset?.integrationId,
        asset?.frn,
        asset?.assetType
      )
    );
    setMode("view");
    refresh();
    setLoadingDelete(false);
  };

  switch (mode) {
    case "view":
      return (
        <AppComment
          handleEdit={() => setMode("edit")}
          handleDelete={() => setMode("delete")}
          created={data?.createdAt}
          updated={data?.updatedAt}
          name={data?.userName}
          isUserAuthor={data?.userId === user?.sub}
          comment={data?.comment}
        />
      );
    case "edit":
      return (
        <div className="SingleComment__new">
          <Avatar
            alt="user"
            className="SingleComment__new-avatar"
            style={{ backgroundImage: initalColorsByFirstLetter(data?.name) }}
          >
            {renderInitalFromName(data?.name)}
          </Avatar>
          <div className="SingleComment__new-form">{commentForm()}</div>
        </div>
      );

    case "delete":
      return (
        <div className="SingleComment__delete col center">
          <span className="SingleComment__delete-title bold">
            Are you sure you want to delete this comment?
          </span>
          <div className="SingleComment__delete-actions row">
            <Button
              type="primary"
              loading={loadingDelete}
              danger
              onClick={handleDelete}
            >
              Delete
            </Button>
            <Button type="text" onClick={() => setMode("view")}>
              Cancel
            </Button>
          </div>
        </div>
      );
    case "new":
      return (
        <div className="SingleComment__new">
          <Avatar
            alt="user"
            className="SingleComment__new-avatar"
            style={{ backgroundImage: initalColorsByFirstLetter(user?.name) }}
          >
            {renderInitalFromName(user?.name)}
          </Avatar>
          <div className="SingleComment__new-form">{commentForm()}</div>
        </div>
      );

    default:
      break;
  }
};

export default SingleComment;
