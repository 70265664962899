import React, { useState, useEffect } from "react";

import AppModal from "../../../shared/appModal/appModal";
import { Form, Input, Select } from "antd";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { createUser } from "../../../redux/actions/profilesActions";
import { nameWithSpaceRegex, validate } from "../../../utils/validations";
import { useTranslation } from "react-i18next";

const InviteUser = ({ visible, handleClose, handleCloseRefresh, rolesArr }) => {
  const { t } = useTranslation('users')
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [formValid, setFormValid] = useState(false);

  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const roles = useSelector((state) => state.profilesReducer.roles);

  useEffect(() => {
    form.resetFields();
  }, [visible]);

  const handleSubmit = async (values) => {
    setLoadingSubmit(true);
    // exclude the admin role id
    let rolesToSend = roles.find(item => item.id === values.role);
    rolesToSend = !_.isEmpty(rolesToSend) ? [rolesToSend?.id] : [];
    let inviteReq = await dispatch(createUser(values.name, values.email, rolesToSend));

    if (inviteReq?.ok) {
      handleCloseRefresh();
    }
    setLoadingSubmit(false);
    handleClose();
  };

  const handleCloseModal = () => {
    form.resetFields();
    setLoadingSubmit(false);
    setFormValid(false);
    handleClose();
  };

  const handleCheckIfFormValid = (formValues) => {
    if (!_.isEmpty(formValues.name) && !_.isEmpty(formValues.email) && !_.isEmpty(formValues.role) && validate(nameWithSpaceRegex.regex, formValues.name)) {
      return setFormValid(true);
    }
    return setFormValid(false);
  };

  const renderRoles = () => {
    const outputRoles = _.map(rolesArr || [], (role) => (
      <Select.Option value={role?.id} key={role?.id}>
        <div className="col">
          <span>
            {role?.name}
          </span>
          <span className="muted">{role?.description}</span>
        </div>
      </Select.Option>
    ));
    return outputRoles;
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleCloseModal}
      title={t('user-management.invite-modal.title')}
      loadingSubmit={loadingSubmit}
      formName="invite-user-form"
      submitBtnText={t('user-management.invite-modal.form.submit-btn')}
      submitBtnDisabled={!formValid}
    >
      <Form
        {...layout}
        name="invite-user-form"
        form={form}
        className="InviteUser"
        onFinish={handleSubmit}
        onValuesChange={(changedValues, allValues) => {
          handleCheckIfFormValid(allValues);
        }}
      >
        <Form.Item
          label={t('user-management.invite-modal.form.name.label')}
          name="name"
          rules={[
            {
              required: true,
              message: t('user-management.invite-modal.form.name.required'),
            },
            {
              pattern: nameWithSpaceRegex.regex,
              message: nameWithSpaceRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          hasFeedback
        >
          <Input placeholder={t('user-management.invite-modal.form.name.placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('user-management.invite-modal.form.email.label')}
          name="email"
          rules={[
            {
              required: true,
              message: t('user-management.invite-modal.form.email.required'),
            },
            {
              type: "email",
              message: t('user-management.invite-modal.form.email.validation'),
            },
          ]}
          hasFeedback
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder={t('user-management.invite-modal.form.email.placeholder')} />
        </Form.Item>

        <Form.Item
          label={t('user-management.invite-modal.form.role.label')}
          name="role"
          rules={[
            {
              required: true,
              message: t('user-management.invite-modal.form.role.required'),
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder={t('user-management.invite-modal.form.role.placeholder')}
          >
            {renderRoles()}
          </Select>
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default InviteUser;
