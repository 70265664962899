import React from "react";
import { Button } from "antd";
import _ from "lodash";
import AppModal from "../appModal/appModal";
import CliSnippet from "../cliSnippet/cliSnippet";

import "./confirmationModal.scss";

const ConfirmationModal = ({
  visible,
  handleClose,
  title,
  description,
  loadingConfirm,
  onConfirm,
  cancelBtnText,
  confirmBtnText,
  cliSnippetContent,
  noDelete,
  remove,
  hidePrimaryButton
}) => {
  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      hideFooter
      bodyClassName="ConfirmationModal"
    >
      <span className="modal-title">{title}</span>
      {description && (
        <span className="ConfirmationModal__description">{description}</span>
      )}
      <div className="ConfirmationModal__btns row">
        <Button
          className="app-form-button close"
          type="Default"
          onClick={handleClose}
        >
          {cancelBtnText ? cancelBtnText : "Cancel"}
        </Button>

        {!hidePrimaryButton && <Button
          danger={!noDelete}
          type={noDelete && "primary"}
          htmlType="submit"
          loading={loadingConfirm}
          className={noDelete ? "app-form-button" : "app-form-delete"}
          onClick={onConfirm}
        >
          {confirmBtnText ? confirmBtnText : remove ? "Remove" : "Delete"}
        </Button>}
      </div>
      {!_.isEmpty(cliSnippetContent) && (
        <div className="ConfirmationModal__cli">
          <CliSnippet content={cliSnippetContent} deleteMode />
        </div>
      )}
    </AppModal>
  );
};

export default ConfirmationModal;
