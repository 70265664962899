import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_CLOUDFLARE_INTEGRATIONS = "GET_CLOUDFLARE_INTEGRATIONS";

const createCloudFlareIntegration = (name, token) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/cloudflare`,
      "POST",
      { name, token }
    );

    return res;
  });
};

const deleteCloudFlareIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/cloudflare/${id}`,
      "DELETE"
    );

    return res?.ok;
  });
};

const editCloudFlareIntegration = (id, name) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/cloudflare/${id}`,
      "PUT",
      { name }
    );

    return res.ok;
  });
};

const getCloudFlareIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/cloudflare`,
      "GET"
    );

    const integrations = await req.json();

    dispatch({
      type: GET_CLOUDFLARE_INTEGRATIONS,
      payload: integrations,
    });
    return req.ok;
  });
};

export {
  createCloudFlareIntegration,
  deleteCloudFlareIntegration,
  editCloudFlareIntegration,
  getCloudFlareIntegrations,
};
