import {
  CREATE_STACK,
  GET_CFT_STACKS,
  GET_STACKS_STATUS,
} from "../actions/cftStacksActions";

const initialState = {
  data: [],
  status: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CFT_STACKS:
      return { ...state, data: payload.data };

    case CREATE_STACK:
      return { ...state };

    case GET_STACKS_STATUS:
      return { ...state, status: payload.data };

    default:
      return state;
  }
}
