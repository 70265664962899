import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import toLower from "lodash/toLower";
import CountUp from "react-countup";
import { faLayerGroup } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import fireflyLogo from "../../../Images/firefly_logo.svg";
import opa from "../../../Images/general_icons/opa.svg";
import { numberWithCommas } from "../../../utils/formatting";

import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { getInsightType } from "../../../utils/formatting";
import { insights } from "../../../utils/icons";
import { insightsFilterTemplate } from "../../../consts/insights";

import "./insight.scss";

const Insight = ({ category, count, cost, onlyProd, prodAccounts }) => {

  const dispatch = useDispatch();
  const history = useHistory();

  const classifications = useSelector(
    (state) => state.classificationsReducer.classifications
  );

  // const insightsFilters = useSelector(
  //   (state) => state.insightsV2Reducer.filters
  // );

  const aggregationsClassificaition = useSelector(
    (state) => state.dashboardReducer.aggregationsClassificaition
  );

  const dashboardInsights = useSelector(
    (state) => state.insightsReducer.dashboardInsights
  );

  const categoryClick = () => {

    if (count > 0) {

      dispatch(
        setInsightsFilters({
          ...insightsFilterTemplate, labels: [category]
        })
      );
      history.push(`/governance`);
    }
  };

  const categoryInsightClick = () => {
    const match = classifications?.find(
      (classification) => classification?.id === dashboardInsights[category]
    );
    const aggMatch = aggregationsClassificaition?.find(
      (agg) => agg?.key === dashboardInsights[category]
    );
    const count = aggMatch ? aggMatch?.doc_count : 0;

    if (count > 0) {
      dispatch(
        setInsightsFilters({
          ...insightsFilterTemplate, id: match?.id
        })
      );
      history.push(`/governance`);
    }
  };

  const renderIcon = () => {
    const string = toLower(category);
    switch (string) {
      case "reliability":
        return <img src={insights["reliability"]} alt="reliability" />;

      case "optimization":
        return <img src={insights["optimization"]} alt="optimization" />;

      case "misconfiguration":
        return (
          <img src={insights["misconfiguration"]} alt="misconfiguration" />
        );

      default:
        return null;
    }
  };

  const renderScope = (types) => {
    if (!isEmpty(types)) {
      return <ProviderIcon provider={getInsightType(types)} />;
    }
  };

  const renderInsight = () => {
    const match = classifications?.find(
      (classification) => classification?.id === dashboardInsights[category]
    );
    const aggMatch = aggregationsClassificaition?.find(
      (agg) => agg?.key === dashboardInsights[category]
    );
    const count = aggMatch ? aggMatch?.doc_count : 0;
    return (
      <div
        className="Insight__singleInsight center"
        onClick={categoryInsightClick}
      >
        {count > 0 && (
          <div className={`Insight__singleInsight-arrow ${toLower(category)}`}>
            <FontAwesomeIcon
              icon="angle-right"
            />
          </div>
        )}
        <div className="Insight__singleInsight-actionsWrapper">
          <div className="Insight__singleInsight-actionsWrapper-actions col">
            <div className="Insight__singleInsight-actionsWrapper-actions-name row between">
              {match ? match?.name : "No insights found"}
            </div>
            {match && (
              <div className="Insight__singleInsight-actionsWrapper-actions-categories row">
                <img
                  className="firefly"
                  src={match?.isDefault ? fireflyLogo : opa}
                />
                {!isEmpty(match?.type) && renderScope(match?.type)}
                <div
                  className={`row Insight__singleInsight-actionsWrapper-actions-categories-count ${toLower(
                    match?.labels[0]
                  )}-flag`}
                >
                  <FontAwesomeIcon icon={faLayerGroup} />
                  <CountUp start={0} end={count > 0 ? count : 0} duration={1} />
                </div>
              </div>
            )}
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="Insight basic-card center">
      <div className={`Insight__container col ${toLower(category)}`}>
        <div className="Insight__category row" onClick={categoryClick}>
          <div className="Insight__category-count">
            <CountUp start={0} end={count} duration={1} />
          </div>
          <div className={`Insight__category-title ${toLower(category)} row`}>
            {renderIcon()}
            {category}
          </div>
          {cost > 0 && (
            <div className="Insight__category-flag">
              {`Save $${numberWithCommas(cost)}/m`}
            </div>
          )}
        </div>
        {renderInsight()}
      </div>
    </div>
  );
};

export default Insight;
