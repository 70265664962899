import { Tabs } from "antd";
import React from "react";
import { ReactComponent as ComposeIcon } from "../../../Images/general_icons/sidebar/compose.svg";
import { ReactComponent as Globe } from "../../../Images/general_icons/globeVector.svg";
import { iacProviders } from "../../../utils/icons";
import { useSelector } from "react-redux";
import { useTranslation } from "react-i18next";

const ComposeHeader = ({title, activeTab, onChangeTab, tabsKeys = {}}) => {
    const { TabPane } = Tabs;
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const { t } = useTranslation("compose", { keyPrefix: "tabs" });

    return (
        <div className="Compose__header">
            <span className="Compose__header-title row">
              <ComposeIcon className="Compose__header-title-icon" />
              {title}
              </span>
            <Tabs
              defaultActiveKey={tabsKeys.moduleCall}
              onChange={(key) => onChangeTab(key)}
              activeKey={activeTab}
            >
            <TabPane
                tab={
                  <span className="Compose__header-tab row">
                    <img className="Compose__header-tab-icon" src={iacProviders(`${themeDark ? 'white' : 'grey'}Terraform`)} alt="terraform"/>
                    {t("titles.moduleCall")}
                  </span>
              }
                key={tabsKeys.moduleCall}/>
              <TabPane
                tab={
                <span className="Compose__header-tab row">
                  <Globe className="Compose__header-tab-icon"/>
                  {t("titles.generateWithAI")}
                </span>
              }
                key={tabsKeys.generateAI}/>
            </Tabs>
        </div>
        )
}

export default ComposeHeader;