import {
  GET_TFC_INTEGRATIONS,
  CREATE_TFC_INTEGRATION,
  DELETE_TFC_INTEGRATION,
  EDIT_TFC_INTEGRATION,
  GET_ALL_TFC_ORGANIZATION_WORKSPACES,
} from "../actions/tfcIntegrationActions";

const initialState = {
  integrations: [],
  workspaces: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_TFC_INTEGRATIONS:
      return { ...state, integrations: payload.integrations };

    case CREATE_TFC_INTEGRATION:
      return { ...state };

    case DELETE_TFC_INTEGRATION:
      return { ...state };

    case EDIT_TFC_INTEGRATION:
      return {...state};

    case GET_ALL_TFC_ORGANIZATION_WORKSPACES:
      return {...state, workspaces: payload.data};

    default:
      return state;
  }
}
