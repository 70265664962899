import { GET_BY_ENVIRONMENT } from '../actions/variableActions';

const initialState = {
    data: []
};

export default function (state = initialState, { type, payload }) {
    switch (type) {
        case GET_BY_ENVIRONMENT:
            return {...state, data: payload.data};

        default:
            return state;
    }
}
