import React from "react";
import isEmpty from "lodash/isEmpty";
import Tooltip from "antd/lib/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCode,
  faTrash,
  faEdit,
  faEllipsisV,
  faEllipsisH,
  faPlusSquare,
  faShareAlt,
  faThumbtack,
} from "@fortawesome/free-solid-svg-icons";

import "./iconBtn.scss";

// ----- Types ------
// delete || menu || edit || code || share || plus

// ----- ColorClasses -------
// red || purple

const IconBtn = ({ disabled, onClick, colorClass, type, customIcon, style, tooltipText, loading }) => {
  const renderIcon = () => {
    switch (type) {
      case "delete":
        return <FontAwesomeIcon icon={faTrash} />;
      case "menu":
        return <FontAwesomeIcon icon={faEllipsisV} />;
      case "edit":
        return <FontAwesomeIcon icon={faEdit} />;
      case "code":
        return <FontAwesomeIcon icon={faCode} />;
      case "plus":
        return <FontAwesomeIcon icon={faPlusSquare} />;
      case "share":
        return <FontAwesomeIcon icon={faShareAlt} />;
      case "pin":
        return <FontAwesomeIcon icon={faThumbtack} />;
      case "zoom-in":
        return <FontAwesomeIcon icon="search-plus" />;
      case "zoom-out":
        return <FontAwesomeIcon icon="search-minus" />;
      case "center":
        return <FontAwesomeIcon icon="compress-arrows-alt" />;
      case "reset":
        return <FontAwesomeIcon icon="redo" />;
      case "clear":
        return <FontAwesomeIcon icon="times-circle" />;
      case "save":
        return <FontAwesomeIcon icon="save" />;
      case "menu-h":
        return <FontAwesomeIcon icon={faEllipsisH}/>;
      case "play":
        return <FontAwesomeIcon icon="play" />;
        case "eye":
          return <FontAwesomeIcon icon="eye" />;
        case "eye-slash":
          return <FontAwesomeIcon icon="eye-slash" />;
        case "child":
          return <FontAwesomeIcon icon="child" />;
      default:
        break;
    }
  };

  return (
    <Tooltip
      title={!isEmpty(tooltipText) ? tooltipText : ""}
      placement="top"
    >
    <button
      className={`IconBtn ${colorClass ? colorClass : ""} ${
        disabled ? "disable" : ""
      }`}
      disabled={disabled}
      onClick={onClick}
      style={style}
    >
      { loading ? <FontAwesomeIcon icon="spinner" spin />: isEmpty(customIcon) ? renderIcon() : customIcon}
    </button>
    </Tooltip>
  );
};

export default IconBtn;
