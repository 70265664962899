import React from "react";
import map from "lodash/map";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import { handleSetStep } from "../../redux/actions/onbordingActions";
import StepLink from "../../components/onBoarding/stepsSidebar/stepLink";
import "./progressBar.scss";

const ProgressBar = ({
  steps,
  currentStep,
  onBoarding,
  customMargin,
  customWidth,
  centered,
  customStyle,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const renderSteps = () => {
    return map(steps, (item, idx) => {
      return (
        <StepLink
          key={item?.text}
          text={item?.text}
          isActive={currentStep === idx + 1}
          done={currentStep > idx + 1}
          number={idx + 1}
          currentStep={currentStep}
          navigateToPrevSteps={
            onBoarding
              ? () => {
                  // if it the prev step allow user to navigate back from the steps bar
                  if (currentStep >= idx + 1) {
                    dispatch(handleSetStep(idx + 1));
                    return history.push("/welcome");
                  }
                  return false;
                }
              : null
          }
          centered={centered}
          customStyle={customStyle}
        />
      );
    });
  };
  return (
    <div
      className="ProgressBar__links"
      style={{
        paddingBottom: !centered ? "15px" : "0px",
      }}
    >
      <div
        className="ProgressBar__links-blocks"
        style={{
          gridTemplateColumns: `repeat(${steps?.length}, 1fr)`,
          height: !centered ? "50px" : "81px",
        }}
      >
        {renderSteps()}
      </div>
      <div
        className="ProgressBar__links-progress"
        style={{
          margin: customMargin ? customMargin : "0",
        }}
      >
        <div
          className="ProgressBar__links-progress-inner"
          style={{
            width: `${
              currentStep === 1 ? 0 : (currentStep - 1) * customWidth
            }%`,
          }}
        />
      </div>
    </div>
  );
};

export default ProgressBar;
