import React from "react";
import isEmpty from "lodash/isEmpty";
import Modal from "antd/lib/modal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/free-solid-svg-icons";

import "./appModalWizard.scss";

const AppModalWizard = ({
  visible,
  handleClose,
  title,
  subtitle,
  centered,
  bodyClassName,
  width,
  subtitleClassName,
  iconSrc,
  customFooter,
  content,
  zIndex
}) => {
  return (
    <Modal
      title={null}
      visible={visible}
      onCancel={handleClose}
      footer={null}
      className="AppModalWizard"
      closable={false}
      centered={centered ? true : false}
      width={width}
      zIndex={zIndex ? zIndex : 1000}
    >
      {!isEmpty(title) && (
        <div className="AppModalWizard__header col">
          <div className="AppModalWizard row between">
            <span className="AppModalWizard__header-wrapper row">
              {iconSrc && (
                <img
                  src={iconSrc}
                  alt="icon"
                  className="AppModalWizard__header-wrapper-icon row"
                />
              )}
              <span className="AppModalWizard__header-title bold">{title}</span>
            </span>
            <button
              className="AppModalWizard__header-close"
              onClick={handleClose}
            >
              <FontAwesomeIcon icon={faTimes} />
            </button>
          </div>
          {!isEmpty(subtitle) && (
            <div
              className={
                !isEmpty(subtitleClassName) ? subtitleClassName : "sub-title"
              }
            >
              {subtitle}
            </div>
          )}
        </div>
      )}
      <div className={`AppModalWizard__body ${bodyClassName}`}>{content}</div>
      {customFooter ? customFooter : null}
    </Modal>
  );
};

export default AppModalWizard;
