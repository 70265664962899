export const policyInitialCode = `package exclude
# The firefly boolean value will represent 
# whether the input is excluded or not.
default firefly = false
contains(arr, elem) {
  arr[_] = elem
}
# Use the keyword \`input\` as a reference to the asset you check
`;

export const FIREFLY_REGO_PREFIX = `# The "firefly" boolean value will represent the policy check results.
# "firefly" boolean defaults to false.
# Use the keyword "input" as a reference to the asset you check.
`;