import React, { useMemo } from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { Empty } from "antd";

// components
import AssetsLineChart from "../../../shared/assetsLineChart/assetsLineChart";
import StatusFilters from "../statusFilters/statusFilters";
import AssetFlagsFilter from "../assetFlagsFilter/assetFlagsFilter";

import "./inventoryCharts.scss";
import ChartsToggles from "./chartsToggles";

const InventoryCharts = ({
  handleSetFilterManaged,
  filterManaged,
  aggregationsIsManaged,
  handleSetFlagFilter
}) => {
  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const noDataSection = useMemo(() => {
    return (
      <div className="center InventoryCharts__empty">
        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description="No Data" />
      </div>
    );
  });

  // chart wrapped with use memo to stop render
  const condificationChart = useMemo(() => {
    let unmanaged = 0;
    let managed = 0;
    let modified = 0;
    let ghost = 0;

    if (!_.isEmpty(aggregationsIsManaged)) {
      for (const bucket of aggregationsIsManaged || []) {
        if (bucket.key === "managed") {
          managed += bucket.doc_count;
          continue;
        }
        if (bucket.key === "unmanaged") {
          unmanaged += bucket.doc_count;
          continue;
        }
        if (bucket.key === "modified") {
          modified += bucket.doc_count;
          continue;
        }
        if (bucket.key === "ghost") {
          ghost += bucket.doc_count;
          continue;
        }
      }
      if (unmanaged == 0 && managed == 0 && modified == 0 && ghost == 0) {
        return noDataSection
      }
      return (
        <AssetsLineChart
          unmanaged={unmanaged}
          managed={managed}
          modified={modified}
          ghost={ghost}
          indexPosition="bottom-left"
          noLabel
          decimal
          height={80}
          legend={{itemSpacing: 3, itemHeight: 7}}
        />
      );
    }
    return noDataSection
  }, [aggregationsIsManaged]);

  const statusFilters = useMemo(() => {
    let unmanaged = 0;
    let managed = 0;
    let modified = 0;
    let ghost = 0;

    if (!_.isEmpty(aggregationsIsManaged)) {
      for (const bucket of aggregationsIsManaged || []) {
        if (bucket.key === "managed") {
          managed += bucket.doc_count;
          continue;
        }
        if (bucket.key === "unmanaged") {
          unmanaged += bucket.doc_count;
          continue;
        }
        if (bucket.key === "modified") {
          modified += bucket.doc_count;
          continue;
        }
        if (bucket.key === "ghost") {
          ghost += bucket.doc_count;
          continue;
        }
      }
    }
    return (
      <StatusFilters
        handleSetFilterManaged={handleSetFilterManaged}
        filterManaged={filterManaged}
        unmanagedSum={unmanaged}
        managedSum={managed}
        modifiedSum={modified}
        ghostSum={ghost}
      />
    );
  }, [aggregationsIsManaged, filterManaged, screenFilters]);

  return (
    <div className="InventoryCharts">
      <ChartsToggles handleSetFilter={handleSetFlagFilter} />
      {statusFilters}
      <div className="InventoryCharts__charts">
        <div className="no-padding-card InventoryCharts__charts-item iac-chart">
          <span className="InventoryCharts__charts-item-title">
            IaC Coverage
          </span>
          {condificationChart}
        </div>

        <AssetFlagsFilter handleSetFilter={handleSetFlagFilter} />
      </div>
    </div>
  );
};

export default InventoryCharts;
