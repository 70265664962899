import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { useDispatch } from "react-redux";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import WebhookCreation from "./webhookCreation";

import {
  createTeamsIntegration,
  testTeamsIntegration,
  testWebhookIntegration,
  createWebhookIntegration,
} from "../../../redux/actions/integrationsActions";
import { integrationIcons } from "../../../utils/icons";

import "./webhookIntegration.scss";

const getTypeFromPathName = (history) => {
  const lastPath = history?.location?.pathname?.split("/")[2];
  return lastPath ? lastPath.split("-")[0] : "";
}

const WebhookIntegration = (props) => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({
    webhookUrl: "",
    username: "",
    password: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const type = history?.location?.state?.type || getTypeFromPathName(history);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Webhook creation",
      content_title: `${_.capitalize(type)} Integration`,
      content: (
        <WebhookCreation
          type={type}
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        switch (type) {
          case "teams":
            const teamsWebhookOk = await dispatch(
              testTeamsIntegration(wizardState.webhookUrl)
            );
            if (teamsWebhookOk) {
              await dispatch(
                createTeamsIntegration(wizardState.name, wizardState.webhookUrl)
              );
              setLoadingSubmit(false);
            } else {
              setLoadingSubmit(false);
              throw new Error("Invalid webhook url");
            }
            return;

          case "webhook":
            const webhookOk = await dispatch(
              testWebhookIntegration(
                wizardState.webhookUrl,
                wizardState.username,
                wizardState.password
              )
            );
            if (webhookOk) {
              const created = await dispatch(
                createWebhookIntegration(
                  wizardState.name,
                  wizardState.webhookUrl,
                  wizardState.username,
                  wizardState.password
                )
              );
              setLoadingSubmit(false);
              if (!created) {
                throw new Error("Invalid webhook url");
              }
            } else {
              setLoadingSubmit(false);
              throw new Error("Invalid webhook url");
            }
            return;

          default:
            setLoadingSubmit(false);
            return;
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="WebhookIntegraion">
      <AppWizard
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        iconSrc={integrationIcons[type]}
      />
    </div>
  );
};

export default WebhookIntegration;
