import "./foundResource.scss";
import {Checkbox} from "antd";
import AppToggle from "../../../../shared/appToggle/appToggle";
import CodeDiff from "../../../../shared/codeDiff/codeDiff";
import _ from "lodash";
import React from "react";
import CopyBox from "../../../../shared/copyBox/copyBox";
import {ERR_RESOURCE_NOT_FOUND} from "../../../../consts/errorCodes";


const FoundResource = ({obj, setChecked, setShowDiff}) => {
    const checkboxText = <div className="col"><span>{obj?.repo}, {obj?.resourceFilePath}</span></div>
    return (
        <span className="FoundResource col">
            <span>
                {!_.isUndefined(obj?.error) &&
                    <span style={{ opacity: .7 }}>
                        {obj?.responseText}
                    </span>
                }
                {!_.isUndefined(obj?.error) && (obj?.error === ERR_RESOURCE_NOT_FOUND) &&
                    <span className="FoundResource__find-diff-command row" style={{ marginTop: '5px' }}>
                        <CopyBox
                            text={`terraform state rm "${obj?.objectInfo?.objectModule && `${obj?.objectInfo?.objectModule}.`}${obj?.objectInfo?.objectType}.${obj?.objectInfo?.objectName}"`}
                            darkMode
                        />
                    </span>
                }
            </span>
            {(_.isUndefined(obj?.error)) &&
                (
                    <span>
                        <span className="FoundResource__find row between bold">
                            {!obj?.isSingle ? <Checkbox key={obj?.id} checked={obj?.isChecked} onChange={(e) => setChecked(e, obj?.id)}>
                                <span style={{ color: 'white' }}>{checkboxText}</span>
                            </Checkbox> : checkboxText}
                            <span className="row FoundResource__find-toggle">
                                <AppToggle
                                    checked={obj?.showDiff}
                                    toggleChecked={() => setShowDiff(obj?.id)}
                                    loading={false}
                                />
                            </span>
                        </span>
                        <span className="FoundResource__find row between">
                            {obj?.showDiff && (
                                <span className="col FoundResource__find-diff">
                                  <span className="FoundResource__find-diff-code">
                                      <CodeDiff lang="hcl" obj={obj}/>
                                  </span>
                                  <span className="FoundResource__find-diff-commandDescription row bold">
                                      To remove the asset from the cloud, run the following command:
                                  </span>
                                  <span className="FoundResource__find-diff-command row">
                                      <CopyBox
                                          text={`terraform destroy -target "${obj?.objectInfo?.objectModule && `${obj?.objectInfo?.objectModule}.`}${obj?.objectInfo?.objectType}.${obj?.objectInfo?.objectName}"`}
                                          darkMode
                                      />
                                  </span>
                                </span>
                            )}
                        </span>
                    </span>
                )
            }
        </span>
    );
};

export default FoundResource;
