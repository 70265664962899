import React, { useState } from "react";
import { useAuth0 } from "@auth0/auth0-react";
import _ from "lodash";
import OrganizationModal from "../organizationModal/organizationModal";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import './organizationSelect.scss';

const OrganizationSelect = () => {
  const { user } = useAuth0();
  const [organizationModalOpen, setOrganizationModalOpen] = useState(false);

  if (!_.isEmpty(user?.["https://infralight.co/organization"])) {
    return (
      <div>
      <button
      className="OrganizationSelect row"
      onClick={() => setOrganizationModalOpen(true)}
      >
        <FontAwesomeIcon icon="globe" style={{ fontSize: '18px' }} className="purple-text" />
        Organization
      </button>
      <OrganizationModal
          handleClose={() => setOrganizationModalOpen(false)}
          visible={organizationModalOpen}
        />
      </div>
    );
  }

  return null;
};

export default OrganizationSelect;
