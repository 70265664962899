import React from "react";
import Spin from 'antd/lib/spin';
import { LoadingOutlined } from '@ant-design/icons';

import "./appToggle.scss";

const AppToggle = ({ checked, toggleChecked, loading, isDefault, disabled, colorClass, text }) => {
  const loaderIcon = <LoadingOutlined style={{ fontSize: 12, fontWeight: 'bold' }} spin />;

  return (
    <div className="AppToggle__container row g5">
        <div
          className={`AppToggle ${!checked && "unChecked"} ${loading && 'loading'} ${isDefault && 'isDefault'} ${disabled ? "disabled" : ''}`}
          onClick={loading || disabled ? null : toggleChecked}
        >
          <div className={`AppToggle__inner ${!checked && "unChecked"} ${colorClass} center`}>
            {loading && <Spin indicator={loaderIcon} />}
          </div>
          <div className={`AppToggle__line ${colorClass}`} />
        </div>
        <span className="AppToggle__text">{text}</span>
    </div>
  );
};

export default AppToggle;