import React from "react";
import AppBtn from "../../shared/appBtn/appBtn";
import { S3_ASSETS } from '../../consts/config';
import "./viewerOverlay.scss";

const ViewerOverlay = ({ handleSetConfirmView }) => {
  const handleConfirmViewer = () => {
    localStorage.setItem("viewer", "confirm");
    handleSetConfirmView();
  };

  return (
    <div className="ViewerOverlay center row">
      <img src={`${S3_ASSETS}/illustrations/viewer_woman.png`} alt="ViewerImg" />
      <div className="ViewerOverlay__text col">
        <span className="title">You're logged-in as a Viewer</span>
        <span>Hence unable to perform any actions</span>
        <AppBtn
          text="Got it, Thanks!"
          onClick={() => {
            handleConfirmViewer();
          }}
        />
      </div>
    </div>
  );
};

export default ViewerOverlay;
