import action from "../middleware";
import { BACKEND_URL } from "../../consts/config";
import RequestWrapper from "../../utils/requestWrapper";
import { sendEvent } from "../../utils/amplitude";
import { composeEvents } from "../../utils/amplitudeEvents";
import { appToast } from "../../shared/appToast/appToast";

export const SET_SERACH_VAL_HEADER = "SET_SERACH_VAL_HEADER";
export const SET_USER_CLICK_CHANGELOG = "SET_USER_CLICK_CHANGELOG";
export const GET_ALL_CLOUDS = "GET_ALL_CLOUDS";
export const GET_ALL_IAC = "GET_ALL_IAC";
export const GET_ALL_VCS = "GET_ALL_VCS";
export const GET_ALL_NOTIFICATIONS = "GET_ALL_NOTIFICATIONS";
export const GET_ALL_PROJECTS = "GET_ALL_PROJECTS";
export const TOGGLE_PROVISIONING = "TOGGLE_PROVISIONING";


const setSearchVal = (value) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_SERACH_VAL_HEADER,
      payload: value,
    });
  });
};

const setUserClickChangelog = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/canny/lastseen`,
      "GET", {}, true, null, false
    );

    dispatch({
      type: SET_USER_CLICK_CHANGELOG,
    });
  });
};

const getAllClouds = (showError, fields, includeLastSync) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    let url = `${BACKEND_URL}/integrations/global/clouds`

    const params = []
    if (fields) {
      params.push(`fields=${fields}`)
    }
    if (includeLastSync !== undefined) {
      params.push(`includeLastSync=${includeLastSync}`)
    }
    if (params.length) {
      url += `?${params.join('&')}`
    }


    const req = await requestWrapper.sendRequest(
      url,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );

    const clouds = await req.json();
    dispatch({
      type: GET_ALL_CLOUDS,
      payload: { clouds },
    });
    return req?.ok;
  });
};

const getAllIac = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/iac`,
      "GET"
    );

    const iac = await req.json();
    dispatch({
      type: GET_ALL_IAC,
      payload: { iac },
    });
    return req?.ok;
  });
};

const getAllVcs = (showError = true) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/vcs`,
      "GET",
      {},
      undefined,
      undefined,
      showError
    );

    const vcs = await req.json();
    dispatch({
      type: GET_ALL_VCS,
      payload: { vcs },
    });
    return req?.ok;
  });
};

const getAllNotifications = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/notifications`,
      "GET"
    );

    const notifications = await req.json();
    dispatch({
      type: GET_ALL_NOTIFICATIONS,
      payload: { notifications },
    });
    return req?.ok;
  });
};
const getAllNotificationIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/notifications`,
      "GET"
    );

    const notifications = await req.json();

    return notifications;
  });
};

const getAllProjects = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/global/project`,
      "GET"
    );

    const project = await req.json();
    dispatch({
      type: GET_ALL_PROJECTS,
      payload: { project },
    });
    return req?.ok;
  });
};

const handleToggleProvisioning = () => {
  return action(async (dispatch) => {
    dispatch({
      type: TOGGLE_PROVISIONING,
    });
  });
};

const requestProvision = (prompt, codeProvider, sessionId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/reverseLearning/provision`,
      "POST",
      { prompt, codeProvider, sessionId },
      true,
      null,
      true,
      1200
    );

    // product event
    sendEvent(composeEvents.provisionRequest, { prompt, codeProvider, sessionId });

    let provision = [];

    if (req?.ok) {
      provision = await req.json();
    }

    return provision;
  });
};

export const MODULE_CALL_PATHS = { getVars: 'vars', moduleCall: 'module-call' }
const handleModuleCallRequests = (payload = {}, path) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const errorToast = path !== MODULE_CALL_PATHS.moduleCall;
    try {
      const req = await requestWrapper.sendRequest(
        `${BACKEND_URL}/no-code/${path}`,
        "POST",
        payload,
        true,
        null,
        errorToast,
      );

      if (path === MODULE_CALL_PATHS.moduleCall) {
        // product event when generating code
        sendEvent(composeEvents.generateModuleCallSubmit, { modulePath: payload.moduleFullPathMD5 })
      }
      let response = {};

      if (req?.ok) {
        response = await req.json();
      }
      else {
        const error = new Error();
        error.response = req;
        throw error;
      }
      return response;
    }
    catch (e) {
      const { response } = e;
      let message = ''
      const json = await response.json();
      message = json?.message;
      if (response?.status === 400 && path === MODULE_CALL_PATHS.moduleCall) {
        return { error: message };
      }
      return appToast("error", null, message);
    }
  });
}

const getRetoolEmbedUrl = (appName, themeMode) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/embed-retool?appName=${appName}&themeMode=${themeMode}`,
      "GET",
    );
    let data = '';

    if (req.ok) {
      data = await req.json();
    }
    return data;
  });
};

export {
  setSearchVal,
  setUserClickChangelog,
  getAllClouds,
  getAllIac,
  getAllVcs,
  getAllNotifications,
  getAllNotificationIntegrations,
  getAllProjects,
  handleToggleProvisioning,
  requestProvision,
  handleModuleCallRequests,
  getRetoolEmbedUrl,
};