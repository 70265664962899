import React from "react";
import isEmpty from "lodash/isEmpty";
import { Form, Input } from "antd";

const CREATE_ARGO_NEW_USER_URL = "https://argo-cd.readthedocs.io/en/stable/operator-manual/user-management/#create-new-user"
const CREATE_ARGO_TOKEN_URL = "https://argo-cd.readthedocs.io/en/stable/user-guide/commands/argocd_account_generate-token/"

const ArgoCDOverview = ({
  setSubmitDisabled,
  wizardState,
  setWizardState,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { argoDomain, argoToken, awsBucket, awsBucketRegion } = formValues;

    if (isEmpty(argoDomain) || isEmpty(argoToken) || isEmpty(awsBucket) || isEmpty(awsBucketRegion)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="K8sOverview col">
      <div className="K8sOverview__section col">
        <span className="dark-blue bold">Before you begin</span>
        <div className="dark-blue K8sOverview__section-list">
          <span className="dark-blue">
            Integrating your Argo CD will allow Firefly to show you Kubernetes object status from Argo in the Inventory (currently supported with AWS only)
          </span>
        </div>
      </div>
      <div className="K8sOverview__section col form-wizard-wrapper">
        <Form
          {...layout}
          name="k8s-wizard-form"
          form={form}
          initialValues={{}}
          className="AppWizard__content-body-form"
          onValuesChange={(
            changedValues,
            allValues // this is how you can get the form values
          ) => {
            setWizardState({
              ...wizardState,
              ...allValues,
            });
            checkValidation(allValues);
          }}
        >
          <Form.Item
            className="ArgoCDOverview__input"
            label="Argo CD Domain"
            name="argoDomain"
            tooltip="Your Argo CD server domain"
            rules={[
              {
                required: true,
                message: "Domain is required",
              }
            ]}
          >
            <Input placeholder="Please fill domain" />
          </Form.Item>
          <Form.Item
            className="ArgoCDOverview__input"
            label="Argo CD API Token"
            name="argoToken"
            tooltip={<span>We recommended to <a href={CREATE_ARGO_NEW_USER_URL} target="_blank" rel="noreferrer" className="med-bold underline">create a dedicated ArgoCD user</a>{" "}
            with read-only permissions, without admin permissions, and then <a href={CREATE_ARGO_TOKEN_URL} target="_blank" rel="noreferrer" className="med-bold underline">generate an account token for the new user.</a></span>}
            rules={[
              {
                required: true,
                message: "API Token is required",
              }
            ]}
          >
            <Input placeholder="Please fill API Token" />
          </Form.Item>
          <Form.Item
            className="ArgoCDOverview__input"
            label="AWS S3 Bucket"
            name="awsBucket"
            tooltip="Create a dedicated bucket in an AWS account integrated to Firefly for the Argo CD configuration output"
            rules={[
              {
                required: true,
                message: "AWS S3 Bucket is required",
              }
            ]}
          >
            <Input placeholder="Please fill AWS S3 Bucket" />
          </Form.Item>
          <Form.Item
            className="ArgoCDOverview__input"
            label="AWS S3 Bucket Region"
            name="awsBucketRegion"
            tooltip="Select the region of the S3 account."
            rules={[
              {
                required: true,
                message: "AWS S3 Bucket Region is required",
              },
            ]}>
            <Input placeholder="Please fill AWS S3 Bucket Region" />
          </Form.Item>
        </Form>
      </div>
    </div>
  );
};

export default ArgoCDOverview;
