import React from "react";
import _ from "lodash";
import { useIntercom } from "react-use-intercom";
import Tooltip from "antd/lib/tooltip";

import "./freeTierMask.scss";

const FreeTierMask = ({ children, noBlur, customText }) => {
  const { showNewMessages, show } = useIntercom();
  const handleClick = (e) => {
    e.stopPropagation();
    show();
    showNewMessages("Hi, I would like to learn more about the Firefly Premium plan");
  };

  return (
    <Tooltip placement="top" title={!_.isEmpty(customText) ? customText : "Upgrade to show information"}>
      <div className={`FreeTierMask ${noBlur && "noBlur"}`} onClick={handleClick}>
        {children}
      </div>
    </Tooltip>
  );
};

export default FreeTierMask;
