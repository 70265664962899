export const insightsFilterTemplate = {
    query: "",
    labels: [],
    isDefault: null,
    onlySubscribed: false,
    onlyProduction: false,
    onlyMatchingAssets: false,
    onlyEnabled: false,
    showExclusion: false,
    type: [],
    providers: [],
    integrations: [],
    severity: [],
    id: null
}

export const insightSeverityTypes = [
    { title: 'TRACE', color: '#562AB2' },
    { title: 'INFO', color: '#5246B9' },
    { title: 'LOW', color: '#4E64C0' },
    { title: 'MEDIUM', color: '#498BC8' },
    { title: 'HIGH', color: '#3FD3D8' },
  ]