import React, { useCallback, useEffect } from "react";
import { Form, Input } from "antd";
import isEmpty from "lodash/isEmpty";
import { useDropzone } from "react-dropzone";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFlag,
  faUpload,
} from "@fortawesome/free-solid-svg-icons";
import { nicknameRegex, validate } from "../../../../utils/validations";
import { ReactComponent as EventDriven } from "../../../../Images/general_icons/eventdrivenGrey.svg";
import { DESCRIPTIONS } from "../../../../consts/general";
import AppToggle from "../../../../shared/appToggle/appToggle";
import {ReactComponent as AdvancedSettingsIcon} from "../../../../Images/general_icons/advance_settings_icon.svg";
import EditableTagGroup from '../../../../shared/editableTagGroup/editableTagGroup'

const GCP_GUIDE_URL = "https://firefly-5.gitbook.io/firefly-documentation-portal/user-guides/integrations/integrate-your-providers-and-tools/integrate-your-data-sources/integrate-google-cloud/integrate-google-cloud-using-a-service-account-key";
const GCP_ADVANCE_GUIDE_URL = "https://firefly-5.gitbook.io/firefly-documentation-portal/user-guides/integrations/integrate-your-providers-and-tools/integrate-your-data-sources/integrate-google-cloud/integrate-google-cloud-using-a-service-account-key#discovering-multiple-projects-in-this-integration";

const GcpProviderServiceCreation = ({ wizardState, setWizardState, setSubmitDisabled, currentProvider }) => {
  const [form] = Form.useForm();
  const layout = {};


  useEffect(() => {
    checkValidation(form.getFieldsValue());
  }, [form.getFieldsValue()])
  
  const isUpdateMode = !isEmpty(currentProvider);
  useEffect(() => {
    if(isUpdateMode){
      setWizardState({...wizardState,...form.getFieldsValue()});

      form.setFieldsValue({
        name: currentProvider?.name,
        isEventDrivenDisabled: currentProvider.isEventDrivenDisabled ?? false,
        isProd: currentProvider.isProd ?? false,
        isIacAutoDiscoverDisabled: currentProvider.isIacAutoDiscoverDisabled ?? false,
        regexExcludeProjectsDiscovery: currentProvider.regexExcludeProjectsDiscovery ?? [],
      });
    }
  }, [])
  
  const onDrop = useCallback((acceptedFiles) => {
    handleSetJsonFile(acceptedFiles[0]);
  }, []);

  const { getRootProps, getInputProps } = useDropzone({ onDrop });

  const handleSetJsonFile = (file) => {
    if (!isEmpty(file)) {
      const fileReader = new FileReader();
      fileReader.readAsText(file, "UTF-8");
      fileReader.onload = (e) => {
        let serviceAccountKey = JSON.parse(JSON.stringify(e.target.result, null, 2));
        form.setFieldsValue({
          serviceAccountKey
        });
        setWizardState({...wizardState, ...form.getFieldsValue(), serviceAccountKey});
        return checkValidation({...form.getFieldsValue(), serviceAccountKey})
      };
    }
  }

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, serviceAccountKey } = formValues;

    if(isEmpty(name)) {
      formValid = false;
    }
    if(!isUpdateMode && isEmpty(serviceAccountKey)){
      formValid = false;
    }

    if(!isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  }

  const handleToggleField = (fieldName) => {
    setWizardState({...wizardState,...form.getFieldsValue(),[fieldName]:  !form.getFieldValue(fieldName)});
    form.setFieldsValue({ [fieldName]:  !form.getFieldValue(fieldName) });
  }
  const handleChangeRules = () => {
    const regexExcludeProjectsDiscovery = form.getFieldValue('regexExcludeProjectsDiscovery');
    setWizardState({...wizardState,...form.getFieldsValue(),regexExcludeProjectsDiscovery});
    form.setFieldsValue({ regexExcludeProjectsDiscovery});
  }

  return (
    <div className="GcpProviderServiceCreation col">
      <div className="GcpProviderServiceCreation__intro col">
        <span className="small-dark-blue">
          To integrate your Google Cloud project with Firefly, complete the procedure{" "}
          <a href={GCP_GUIDE_URL} target="_blank" rel="noreferrer">here</a>.
        </span>
      </div>
      <Form
        {...layout}
        name="gcp-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Google Cloud Integration",
          serviceAccountKey: wizardState.serviceAccountKey || "",
          isEventDrivenDisabled:wizardState.isEventDrivenDisabled ?? false,
          isProd:wizardState.isProd ?? false,
          isIacAutoDiscoverDisabled:wizardState.isIacAutoDiscoverDisabled ?? false,
          regexExcludeProjectsDiscovery:wizardState.regexExcludeProjectsDiscovery ?? [],
        }}
        className="AppWizard__content-body-form form-wizard-wrapper"
        onValuesChange={(
          changedValues,
          allValues
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>

        <Form.Item
          label="Service Account Key"
          name="serviceAccountKey"
          rules={ isUpdateMode ? [] : [
            {
              required: true,
              message: "Service account key is required",
            },
          ]}
          style={{ marginBottom: "1rem", flexDirection: "column" }}
        >
          <Input.TextArea
            placeholder={isUpdateMode ? "**********" : "Enter service account key (JSON format)"}
            rows={4}
            allowClear
          />
        </Form.Item>

        <div {...getRootProps()} className="drop-zone-box center">
          <input {...getInputProps()} />
          <div className="row" style={{ gap:'8px' }}>
            <span >Or upload / drag a JSON file</span>
            <FontAwesomeIcon icon={faUpload} /> 
          </div>
        </div>

        <div className="GcpProviderServiceCreation__advanced-options">
          <div className="GcpProviderServiceCreation__advanced-options-title">
            <AdvancedSettingsIcon />
            Advanced Options
          </div>
          <div>
          <Form.Item name="isEventDrivenDisabled">
            <span className="GcpProviderServiceCreation__custom-toggle">
              <AppToggle checked={!form.getFieldValue('isEventDrivenDisabled')} toggleChecked={() => handleToggleField('isEventDrivenDisabled')} />
              <div className="col g5">
                <span className={`GcpProviderServiceCreation__custom-toggle__title ${'checked'}`}>
                  <span className="toggle-label">Event-driven</span>
                  <EventDriven />
                </span>
                <span className="GcpProviderServiceCreation__custom-toggle__subtitle">
                  {DESCRIPTIONS.gcpEventDriven}
                </span>
              </div>
            </span>
          </Form.Item>
            <div>
              <div className="col g5">
                <span className="GcpProviderServiceCreation__heading">Multiple Projects Discovery</span>
                <span className="GcpProviderServiceCreation__custom-toggle__subtitle">To integrate multiple projects in this integration, complete the procedure {" "}
                  <a href={GCP_ADVANCE_GUIDE_URL} target="_blank" rel="noreferrer">here</a>.
                </span>
                <span className="GcpProviderServiceCreation__custom-toggle__subtitle">To exclude part of the projects under this service account, use the Regex rules below.</span>
              </div>
              <Form.Item
                name="regexExcludeProjectsDiscovery"
                label="Regex rules"
                style={{ flexDirection: "column" }}
              >
                <EditableTagGroup defaultValues={currentProvider?.regexExcludeProjectsDiscovery} onChange={handleChangeRules}  />
              </Form.Item>
            </div>
            <Form.Item name="isIacAutoDiscoverDisabled">
            <span className="GcpProviderServiceCreation__custom-toggle">
              <AppToggle checked={!form.getFieldValue('isIacAutoDiscoverDisabled')} toggleChecked={() => handleToggleField('isIacAutoDiscoverDisabled')} />
              <span>
                <div className={`GcpProviderServiceCreation__custom-toggle__title ${'checked'}`}>
                  <span className="toggle-label">IaC Discovery</span>
                </div>
                <div className="GcpProviderServiceCreation__custom-toggle__subtitle">
                  Allow Firefly to scan your GCS (buckets) and discover all Terraform state files.
                </div>
              </span>
            </span>
            </Form.Item>
          <Form.Item name="isProd">
            <span className="GcpProviderServiceCreation__custom-toggle">
              <AppToggle checked={form.getFieldValue('isProd')} toggleChecked={() => handleToggleField('isProd')} />
              <span>
                <div className={`GcpProviderServiceCreation__custom-toggle__title ${''}`}>
                  <span className="toggle-label">Mark as Production</span>
                  <FontAwesomeIcon icon={faFlag} />
                </div>
                <div className="GcpProviderServiceCreation__custom-toggle__subtitle">
                  This account will be marked as production. You can edit this at any time in the <b>Integrations</b> window. Once done, you will be able to view the production accounts separately in the <b>Dashboard</b>.
                </div>
              </span>
            </span>
          </Form.Item>
          </div>
        </div>
      </Form>
    </div>
  );
};

export default GcpProviderServiceCreation;
