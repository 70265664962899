import React, { useState } from "react"
import { Steps } from "antd";
import { ReactComponent as CodeIcon } from "../../../Images/ci/steps/code.svg"
import { ReactComponent as PlanIcon } from "../../../Images/ci/steps/plan.svg"
import { ReactComponent as ApplyIcon } from "../../../Images/ci/steps/apply.svg"

import "./ciProgress.scss";

const CiProgress = ({ onChangeStep = () => null, planId = "", applyId = "", codeIsEnabled = false, defaultStep = 1 }) => {
    const [current, setCurrent] = useState(defaultStep); // starting from PLAN if exists, else from APPLY

    const getStatusByIndex = (index) => current > index ? "finish" : ( current === index ? "active" : "next");

    const onClickStep = (index = 1) => {
        onChangeStep(index);
        setCurrent(index);
    }
    
    return (
        <div className="CiProgress">
            <Steps direction="vertical" size="small" current={current}>
                <Steps.Step title="Code" icon={<CodeIcon className={`CiProgress-icon ${getStatusByIndex(0)}`}/>} onClick={() => codeIsEnabled && onClickStep(0)} disabled={!codeIsEnabled}/>
                <Steps.Step title="Plan" icon={<PlanIcon className={`CiProgress-icon ${getStatusByIndex(1)}`}/>} onClick={() => planId && onClickStep(1)}/>
                <Steps.Step title="Apply" icon={<ApplyIcon className={`CiProgress-icon ${getStatusByIndex(2)}`}/>} onClick={() => applyId && onClickStep(2)} disabled={!applyId}/>
            </Steps>
        </div>
    );
};

export default CiProgress;