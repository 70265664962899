import React, { useEffect } from "react";
import Form from "antd/lib/form";
import Input from "antd/lib/input";
import Select from "antd/lib/select";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import NotificationAlert from "../../../shared/notificationAlert/notificationAlert";

const RequiredFields = ({
  form,
  wizardState,
  setWizardState,
  setSubmitDisabled,
  requiredFields,
  extraFields,
}) => {
  const layout = {};

  useEffect(() => {
    let newStateObject = {};
    map(Object.keys(wizardState), (fieldKey) => {
      newStateObject[fieldKey] = wizardState[fieldKey];
    });
    form.setFieldsValue(newStateObject);
    checkValidation(newStateObject);
  }, [wizardState]);

  const checkValidation = (formValues) => {
    let formValid = true;
    map(Object.keys(requiredFields), (fieldKey) => {
      if (isEmpty(formValues[fieldKey])) {
        formValid = false;
      }
    });
    return setSubmitDisabled(!formValid);
  };

  const renderFields = () => {
    if (!isEmpty(requiredFields)) {
      return map(Object.keys(requiredFields), (fieldKey) => {
        const match = extraFields?.find((field) => field?.key === fieldKey);

        switch (match?.schema?.type) {
          case "option":
            return (
              <Form.Item
                key={fieldKey}
                label={match?.name}
                name={fieldKey}
                rules={[
                  {
                    required: true,
                    message: `${match?.name} is required`,
                  },
                ]}
                style={{
                  marginBottom: "1.5rem",
                  flexDirection: "column",
                }}
              >
                <Select
                  placeholder={`Select ${match?.name}`}
                  showSearch
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {map(requiredFields[fieldKey], (option) => (
                    <Select.Option value={option.id} key={option.id}>
                      {option.value}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            );
            case "priority":
              return (
                <Form.Item
                  key={fieldKey}
                  label={match?.name}
                  name={fieldKey}
                  rules={[
                    {
                      required: true,
                      message: `${match?.name} is required`,
                    },
                  ]}
                  style={{
                    marginBottom: "1.5rem",
                    flexDirection: "column",
                  }}
                >
                  <Select
                    placeholder={`Select ${match?.name}`}
                    showSearch
                    filterOption={(input, option) =>
                      option.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {map(requiredFields[fieldKey], (option) => (
                      <Select.Option value={option.id} key={option.id}>
                        {option.name}
                      </Select.Option>
                    ))}
                  </Select>
                </Form.Item>
              );

          case "string":
            return (
              <Form.Item
                key={fieldKey}
                label={match?.name}
                name={fieldKey}
                style={{ marginBottom: "1.5rem", flexDirection: "column" }}
                rules={[
                  {
                    required: true,
                    message: `${match?.name} is required`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
            
          case "array":
            return (
              <Form.Item
                key={fieldKey}
                label={match?.name}
                name={fieldKey}
                rules={[
                  {
                    required: true,
                    message: `${match?.name} is required`,
                  },
                ]}
                style={{
                  marginBottom: "1.5rem",
                  flexDirection: "column",
                }}
              >
                <Select
                  placeholder={`Select ${match?.name}`}
                  showSearch
                  mode="multiple"
                  filterOption={(input, option) =>
                    option.children
                      .toLowerCase()
                      .indexOf(input.toLowerCase()) >= 0
                  }
                >
                  {map(requiredFields[fieldKey], (option)=>{
                    let val = option.value
                    if (val === undefined) {
                      val = option.name
                    }
                    
                    return (<Select.Option value={option.id} key={option.id}>
                      {val}
                    </Select.Option>)
                  })}
                    
                </Select>
              </Form.Item>
            );

          default:
            return (
              <Form.Item
                key={fieldKey}
                label={`${match?.name} (not supported by Firefly)`}
                name={fieldKey}
                style={{ marginBottom: "1.5rem", flexDirection: "column" }}
                rules={[
                  {
                    required: true,
                    message: `${match?.name} is required`,
                  },
                ]}
              >
                <Input />
              </Form.Item>
            );
        }
      });
    }
  };

  return (
    <div className="col g15" style={{ paddingTop: "10px" }}>
      <NotificationAlert
        text="Only the following field types are supported: URL, Select List, Dropdown, Short text."
        colorClass="stateFileEmpty noBorder"
        noClosable
      />

      <Form
        {...layout}
        name="create-issue-form"
        form={form}
        className="IssueModal"
        onValuesChange={(changedValues, allValues) => {
          setWizardState({ ...wizardState, ...allValues });
          checkValidation(allValues);
        }}
      >
        {renderFields()}
      </Form>
    </div>
  );
};

export default RequiredFields;
