import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
// redux actions
import {
  getInsightsByAccount,
  getInsightsByAggs,
} from "../../redux/actions/insightsV2Actions";
import { getAllClouds } from "../../redux/actions/globalAppActions";
import { getJiraIntegrations } from "../../redux/actions/jiraIntegrationActions";

// components
import InsightsFilters from "../../components/Insights/insightsFilters/insightsFilters";
import InsightsHeader from "../../components/Insights/insightsHeader/insightsHeader";
import InsightsTable from "../../components/Insights/insightsTable/insightsTable";
import "./insights.scss";

const Insights = () => {
  const dispatch = useDispatch();
  const [tabelLoading, setTableLoading] = useState(true);
  const insightsReducer = useSelector((state) => state.insightsV2Reducer);

  useEffect(() => {
    fetchTableData();
  }, [insightsReducer.filters, insightsReducer.tableFilters]);

  useEffect(() => {
    fetchFiltersData();
  }, [insightsReducer.filters]);

  useEffect(() => {
    fetchInit();
  }, []);

  const fetchTableData = async () => {
    setTableLoading(true);
    const payload = {
      ...insightsReducer.filters,
      ...insightsReducer.tableFilters,
    };
    await Promise.all([dispatch(getInsightsByAccount(payload))]);
    setTableLoading(false);
  };

  const fetchFiltersData = async () => {
    await Promise.all([dispatch(getInsightsByAggs(insightsReducer.filters))]);
  };

  const fetchInit = async () => {
    const cloudFields = "id,name,isProd,accountNumber";

    await Promise.all([
      dispatch(getJiraIntegrations()),
      dispatch(getAllClouds(false, cloudFields)),
    ]);
  };

  return (
    <div
      className={`InsightsPage ${
        insightsReducer.filtersOpen ? "" : "collapsed"
      }`}
    >
      <InsightsFilters/>
      <div className="InsightsPage__content col g20">
        <InsightsHeader />
        <InsightsTable loading={tabelLoading} />
      </div>
    </div>
  );
};

export default Insights;
