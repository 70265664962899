import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const AlertCell = ({ value = false }) => {
  return (
    <div className="AlertCell row g8" style={{ width: '100%' }}>
      <FontAwesomeIcon
        icon="bell"
        color={value ? "#9872FE" : "inherit"}
        style={{ fontSize: "14px" }}
      />
    </div>
  );
};

export default AlertCell;
