import {
  FETCH_REVISIONS,
  FETCH_REVISION_BY_FRN,
  RESET_HISTORY,
  COMPARE_HISTORY,
} from "../actions/historyActions";

import _ from 'lodash';

const initialState = {
  revisions: [],
  revisionsWithoutCurrent: [],
  asset_revision: {},
  asset_compare: {},
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case FETCH_REVISIONS:
      // adding index for the dates slider
      const revisionsWithIdx = _.map(payload || [], (item, idx) => {
        return { ...item, idx };
      });
      // adding an array without the current version
      const revisionsWithoutCurrent = _.filter(
        revisionsWithIdx || [],
        (rev) => !rev?.current
      );

      return { ...state, revisions: revisionsWithIdx, revisionsWithoutCurrent };

    case FETCH_REVISION_BY_FRN:
      return { ...state, asset_revision: payload };

    case COMPARE_HISTORY:
      return { ...state, asset_compare: payload };

    case RESET_HISTORY:
      return { ...state, revisions: [], asset_revision: {}, asset_compare: {} };

    default:
      return state;
  }
};
