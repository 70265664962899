import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { isEmpty } from "lodash";

export const GET_GCP_PROVIDER_INTEGRATIONS = "GET_GCP_PROVIDER_INTEGRATIONS";
export const CREATE_GCP_PROVIDER_INTEGRATION = "CREATE_GCP_PROVIDER_INTEGRATION";
export const UPDATE_GCP_PROVIDER_INTEGRATION = "UPDATE_GCP_PROVIDER_INTEGRATION";
export const DELETE_GCP_PROVIDER_INTEGRATION = "DELETE_GCP_PROVIDER_INTEGRATION";
export const EDIT_GCP_PROVIDER_INTEGRATION = "EDIT_GCP_PROVIDER_INTEGRATION";

const getGcpProviderIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google`,
      "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_GCP_PROVIDER_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

const createGcpProviderIntegration = (
  name,
  serviceAccountKey,
  isEventDrivenDisabled,
  isProd,
  isIacAutoDiscoverDisabled,
  regexExcludeProjectsDiscovery,
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google/`,
      "POST",
      {
        name,
        serviceAccountKey,
        isEventDrivenDisabled,
        isProd,
        isIacAutoDiscoverDisabled,
        regexExcludeProjectsDiscovery,
        isPrimary: true,
        shouldAutoDiscoverProjects: true,
      }
    );

    dispatch({
      type: CREATE_GCP_PROVIDER_INTEGRATION,
      payload: {},
    });
    return res;
  });
};
const updateGcpProviderIntegration = (
  name,
  serviceAccountKey,
  isEventDrivenDisabled,
  isProd,
  isIacAutoDiscoverDisabled,
  regexExcludeProjectsDiscovery,
  id
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google/${id}`,
      "PUT",
      {
        name,
        isEventDrivenDisabled,
        isProd,
        isIacAutoDiscoverDisabled,
        regexExcludeProjectsDiscovery,
        ...(!isEmpty(serviceAccountKey) ? { serviceAccountKey } : {}),
      }
    );

    dispatch({
      type: UPDATE_GCP_PROVIDER_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteGcpProviderIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_GCP_PROVIDER_INTEGRATION,
      payload: {},
    });
  });
};

const editGcpProviderIntegration = (id, name, fullScanEnabled) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/google/${id}`,
      "PUT",
      { name, fullScanEnabled }
    );

    dispatch({
      type: EDIT_GCP_PROVIDER_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};

export {
  getGcpProviderIntegrations,
  createGcpProviderIntegration,
  deleteGcpProviderIntegration,
  editGcpProviderIntegration,
  updateGcpProviderIntegration
};
