import { TreeSelect } from "antd";
import React from "react";

import "./CheckedMultiSelect.scss";

const CheckedMultiSelect = ({
  placeholder,
  treeData,
  loading,
  disabled,
  multiple = true,
  onChange,
  value,
  maxTagCount,
  treeCheckable
}) => {
  return (
    <TreeSelect
      getPopupContainer={trigger => trigger.parentNode}
      maxTagCount={maxTagCount}
      treeLine
      value={value}
      onChange={onChange}
      loading={loading}
      disabled={disabled}
      placeholder={placeholder}
      treeData={treeData}
      style={{ width: "100%" }}
      showArrow
      className="CheckedMultiSelect"
      dropdownClassName="CheckedMultiSelect__dropdown"
      showCheckedStrategy="SHOW_PARENT"
      treeCheckable={treeCheckable || multiple}
      multiple={multiple}
    />
  );
};

export default CheckedMultiSelect;
