import React, { useEffect, useRef, useState } from 'react';
import { Tag, Input, Tooltip } from 'antd';
import './editableTagGroup.scss'


const EditableTagGroup = ({onChange, defaultValues, rowDisplay = false}) => {
    const [tags, setTags] = useState([]);
    const [inputValue, setInputValue] = useState("");
    const [inputActive, setInputActive] = useState(false);
    const [didMount, setdidMount] = useState(false)
    const inputRef = useRef();

    useEffect(() => {
        if (didMount) {
            onChange(tags)
        } else {
            if (defaultValues) {
                setTags(defaultValues)
            }
            setdidMount(true)
        }
    }, [tags])
    
    

    const handleClose = (removedTag) => {
        const updatedTags = tags.filter(tag => tag !== removedTag);
        setTags(updatedTags);
    }

    const handleInputChange = (e) => {
        setInputValue(e.target.value);
    }

    const handleInputConfirm = () => {
        let updatedTags = [...tags];

        if (inputValue && updatedTags.indexOf(inputValue) === -1) {
            updatedTags = [...updatedTags, inputValue];
        }
        if(updatedTags.length !== tags.length){
            setTags(updatedTags);
        }
        setInputValue("")
    }
    
    return (
        <div className={`EditableTagGroup ${inputActive ? 'active' : ''} ${rowDisplay ? 'row': ""}`} onClick={() => inputRef.current.focus()}>
            {tags.map((tag) => {
                const isLongTag = tag.length > 20;
                const tagElem = (
                    <Tag key={tag} closable={true} onClose={() => handleClose(tag)} className='EditableTagGroup-tag'>
                        {isLongTag ? `${tag.slice(0, 20)}...` : tag}
                    </Tag>
                );
                return isLongTag ? <Tooltip title={tag} key={tag}>{tagElem}</Tooltip> : tagElem;
            })}
       
            <Input
                ref={inputRef}
                type="text"
                value={inputValue}
                onChange={handleInputChange}
                onBlur={() => {
                    handleInputConfirm()
                    setInputActive(false);
                }}
                onFocus={() =>  setInputActive(true)}
                onPressEnter={handleInputConfirm}
            />
        </div>
    );
}

export default EditableTagGroup;