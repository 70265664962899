import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import _ from "lodash";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import TorqCreation from "./torqCreation";
import { integrationIcons } from "../../../utils/icons";

import { createTorqIntegration } from "../../../redux/actions/integrationsActions";

import "./torqIntegration.scss";

const TorqIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Torq Integration setup",
      content_title: "Torq Integration",
      content: (
        <TorqCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const req = await dispatch(
          createTorqIntegration(
            wizardState.name,
            wizardState.webhookUrl,
          )
        );
        setLoadingSubmit(false);
        if (!req?.ok) {
          throw new Error(
            `Failed to create Torq integration.`
          );
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="TorqIntegration">
      <AppWizard
        iconSrc={integrationIcons["torq"]}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default TorqIntegration;
