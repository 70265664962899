import {GET_VCS_GITHUB, GET_VCS_GITLAB, DELETE_SPECIFIC_VCS_APP, EDIT_SPECIFIC_VCS_APP, GET_ALL_VSC_BY_ACCOUNT, GET_BITBUCKET_WORKSPACES, GET_VCS_REPOS} from '../actions/vcsActions';

const initialState = {
  githubApps: null,
  accountVcsIntegrations: [],
  reposByVcs: [],
};

export default function (state = initialState, {type, payload}) {
  switch (type) {
    case GET_VCS_GITHUB:
      return {...state, githubApps: payload.githubApps};

    case GET_VCS_GITLAB:
      return {...state, gitlabApps: payload.gitlabApps};

    case DELETE_SPECIFIC_VCS_APP:
      return {...state};

    case EDIT_SPECIFIC_VCS_APP:
      return {...state};

    case GET_ALL_VSC_BY_ACCOUNT:
      return {...state, accountVcsIntegrations: payload};

    case GET_BITBUCKET_WORKSPACES:
      return {...state}
    case GET_VCS_REPOS:
      return {...state, reposByVcs: payload };
    default:
      return state;
  }
}
