import React from "react";
import { formatUnixDate, formatUTCDateTime } from '../../../utils/formatting';
import FreeTierMask from "../freeTierMask/freeTierMask";

const AssetDateCell = ({ value, isFreeTier, isDeleteMode }) => {
  const date = isDeleteMode
    ? formatUTCDateTime(value)
    : formatUnixDate(value);

  return (
    <div className="AssetDateCell">
      {isFreeTier ? <FreeTierMask>{value}</FreeTierMask> : date }
    </div>
  );
};

export default AssetDateCell;
