import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { useHistory } from "react-router-dom";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";
import { capitalizeFirst } from "../../../../../utils/formatting";
import { setFilters } from "../../../../../redux/actions/iacStacksActions";
import { emptyIacScreenFilters } from "../../../../../consts/iacStacks";
import AssetInfoCard from "../assetInfoCard";
import { remoteTypes } from "../../../../../utils/icons";
import "./iacTab.scss";

const IaCTab = ({
  rowData,
  isFetchingStack,
  stacksData,
  cftStacksData,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const stackClick = (stackId, stateType) => {
    dispatch(
      setFilters({
        ...emptyIacScreenFilters,
        stackId: [stackId],
      })
    );
    history.push({
      pathname: `/iac`,
      search: `?view=${stateType}`,
    });
  };

  const renderContentName = () => {
    if (!_.isEmpty(cftStacksData)) {
      return "CloudFormation";
    }
    if (!_.isEmpty(stacksData)) {
      return capitalizeFirst(rowData?.iacType);
    }
    return null;
  };

  const renderStacksList = () => {
    if (!_.isEmpty(stacksData)) {
      return (
        <div className="IaCTab__list col">
          {_.map(stacksData, (stack) => (
            <div key={uuidv4()} className="IaCTab__list-item row">
              <div className="IaCTab__list-item-type row">
                <img
                  src={remoteTypes(stack?.remoteType, themeDark)}
                  alt={stack?.remoteType}
                />
                <span>{stack?.stateMainRemoteLocationString}</span>
              </div>
              <div
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  stackClick(stack?.stackId, stack?.stateType);
                }}
              >
                {stack?.stateSubRemoteLocationString}
              </div>
            </div>
          ))}
        </div>
      );
    }

    if (!_.isEmpty(cftStacksData)) {
      return (
        <div className="IaCTab__list col">
          {_.map(cftStacksData, (stack) => (
            <span key={uuidv4()} className="IaCTab__list-item">
              {stack?.prettyLocation | stack?.name}
            </span>
          ))}
        </div>
      );
    }
  };

  return (
    <AssetInfoCard
      loading={isFetchingStack}
      contentArr={[
        {
          label: "Type",
          content: renderContentName(),
          display: !_.isEmpty(rowData?.iacType) || !_.isEmpty(cftStacksData),
        },
        {
          label: "Names",
          content: renderStacksList(),
          display: !_.isEmpty(stacksData) || !_.isEmpty(cftStacksData),
        },
      ]}
    />
  );
};

export default IaCTab;
