import { useState, useMemo } from "react";
import ReactFlow, { Controls, Background } from "react-flow-renderer";
import DiagramAssetBlock from "./diagramAssetBlock";
import DiagramTraceBlock from "./diagramTraceBlock";
import RelationshipAssetConfig from "./relationshipAssetConfig";
import RelationshipTraceConfig from "./relationshipTraceConfig";
import "./diagramChart.scss";

const DiagramChart = ({ data = {} }) => {
  const [configOpen, setConfigOpen] = useState(false);
  const [traceOpen, setTraceOpen] = useState(false);
  const [selectedAsset, setSelectedAsset] = useState(null);
  const [selectedTrace, setSelectedTrace] = useState(null);

  const handleOpenConfig = (item) => {
    setSelectedAsset(item);
    setConfigOpen(true);
  };

  const handleOpenTrace = (item) => {
    setSelectedTrace(item);
    setTraceOpen(true);
  };

  const nodeTypes = useMemo(
    () => ({
      ASSET: (data) => (
        <DiagramAssetBlock
          asset={data}
          onClick={() => handleOpenConfig(data?.data)}
        />
      ),
      TRACE: (data) => (
        <DiagramTraceBlock 
          asset={data}
          onClick={() => handleOpenTrace(data?.data)}
        />
      ),
    }),
    []
  );

  return (
    <>
      <div
        style={{
          minHeight: "100%",
          minWidth: "100%",
          height: data?.height || "100%",
          width: data?.width || "100%",
        }}
      >
        <ReactFlow
          defaultNodes={data?.nodes || []}
          defaultEdges={data?.edges || []}
          nodeTypes={nodeTypes}
          attributionPosition="top-right"
          className="DiagramChart"
          minZoom={0.1}
          // defaultPosition={[(data?.height /2),(data?.width/2)]}
          fitView
          fitViewOptions={{ includeHiddenNodes: true, maxZoom: .9 }}
        >
          <Controls showInteractive={false} />
          <Background />
        </ReactFlow>
      </div>
      <RelationshipAssetConfig
        visible={configOpen}
        onClose={() => setConfigOpen(false)}
        row={selectedAsset}
      />
      <RelationshipTraceConfig
        visible={traceOpen}
        onClose={() => setTraceOpen(false)}
        row={selectedTrace}
      />
    </>
  );
};

export default DiagramChart;
