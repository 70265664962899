import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Popover } from "antd";

import "./actionsSummary.scss";

const TITLES = {
    create: 'To create',
    update: 'To change',
    delete: 'To destroy',
    import: 'To import'
}
const ActionsSummary = ({ data = {}, liteEdition = false }) => {
    const { createdResourcesCount: createCount = 0, updatedResourcesCount: updateCount = 0, deletedResourcesCount: deleteCount = 0, importedResourcesCount: importCount = 0 }  = data;
    const total = createCount + updateCount + deleteCount + importCount;
    const createWidthInPercent = (createCount / total) * 100 || 0;
    const updateWidthInPercent = (updateCount / total) * 100 || 0;
    const deleteWidthInPercent = (deleteCount / total) * 100 || 0;
    const importWidthInPercent = (importCount / total) * 100 || 0;

    let itemsCount = 0;
    if (createCount) itemsCount++;
    if (updateCount) itemsCount++;
    if (deleteCount) itemsCount++;
    if (importCount) itemsCount++;

    const renderPopoverContent = () => {
        return (
        <div className="ActionsSummary__popover col g5">
            {createCount ? <div className="ActionsSummary__popover-item row g5">
                <span className="ActionsSummary__popover-item-icon create">
                    <FontAwesomeIcon icon="plus-circle" className="ActionsSummary__item-icon" />
                </span>
                <span className="ActionsSummary__popover-item-text">{TITLES.create}:</span>
                <span className="ActionsSummary__popover-item-count create">{createCount}</span>
            </div> : null}
            {updateCount ? <div className="ActionsSummary__popover-item row g5">
                <span className="ActionsSummary__popover-item-icon update">
                    <FontAwesomeIcon icon="pen" className="ActionsSummary__item-icon" />
                </span>
                <span className="ActionsSummary__popover-item-text">{TITLES.update}:</span>
                <span className="ActionsSummary__popover-item-count update">{updateCount}</span>
            </div> : null}
            {deleteCount ? <div className="ActionsSummary__popover-item row g5">
                <span className="ActionsSummary__popover-item-icon delete">
                    <FontAwesomeIcon icon="minus-circle" className="ActionsSummary__item-icon" />
                </span>
                <span className="ActionsSummary__popover-item-text">{TITLES.delete}:</span>
                <span className="ActionsSummary__popover-item-count delete">{deleteCount}</span>
            </div> : null}
            {importCount ? <div className="ActionsSummary__popover-item row g5">
                <span className="ActionsSummary__popover-item-icon import">
                    <FontAwesomeIcon icon="download" className="ActionsSummary__item-icon" />
                </span>
                <span className="ActionsSummary__popover-item-text">{TITLES.import}:</span>
                <span className="ActionsSummary__popover-item-count import">{importCount}</span>
            </div> : null}
        </div>)
    };

    return (
        <Popover title={liteEdition ? "Summary" : ""} placement="top" content={liteEdition ? renderPopoverContent() : null}>
            <div className={`ActionsSummary row g5 ${liteEdition ? `lite ${itemsCount > 1 ? `items-${itemsCount}` : ''}`: ''}`}>
                {createCount ? 
                <div className="ActionsSummary__item col" style={{width: `${createWidthInPercent}%`}}>
                    <div className="ActionsSummary__item-count row g8">
                        <span className="ActionsSummary__item-count-num create">{createCount}</span>
                        <span className="ActionsSummary__item-count-text">{TITLES.create}</span>
                    </div>
                        <div className="ActionsSummary__item-bar row create">
                            <FontAwesomeIcon icon="plus-circle" className="ActionsSummary__item-icon" />
                        </div>
                </div> : null}
                {updateCount ?
                <div className="ActionsSummary__item col" style={{width: `${updateWidthInPercent}%`}}>
                    <div className="ActionsSummary__item-count row g8">
                        <span className="ActionsSummary__item-count-num update">{updateCount}</span>
                        <span className="ActionsSummary__item-count-text">{TITLES.update}</span>
                    </div>
                        <div className="ActionsSummary__item-bar row update">
                            <FontAwesomeIcon icon="pen" className="ActionsSummary__item-icon" />
                        </div>
                </div> : null}
                {deleteCount ? 
                <div className="ActionsSummary__item col" style={{width: `${deleteWidthInPercent}%`}}>
                    <div className="ActionsSummary__item-count row g8">
                        <span className="ActionsSummary__item-count-num delete">{deleteCount}</span>
                        <span className="ActionsSummary__item-count-text">{TITLES.delete}</span>
                    </div>
                        <div className="ActionsSummary__item-bar row delete">
                            <FontAwesomeIcon icon="minus-circle" className="ActionsSummary__item-icon" />
                        </div>
                </div>: null}
                {importCount ?
                <div className="ActionsSummary__item col" style={{width: `${importWidthInPercent}%`}}>
                    <div className="ActionsSummary__item-count row g8">
                        <span className="ActionsSummary__item-count-num import">{importCount}</span>
                        <span className="ActionsSummary__item-count-text">{TITLES.import}</span>
                    </div>
                        <div className="ActionsSummary__item-bar row import">
                            <FontAwesomeIcon icon="download" className="ActionsSummary__item-icon" />
                        </div>
                </div> : null}
            </div>
        </Popover>
    );
}

export default ActionsSummary;