import React, { useEffect } from "react";
import { Form, Input } from "antd";
import _ from "lodash";
import FormLabels from "../../shared/formLabels/formLabels";
import {
  descriptionRegexMultiline,
  nameWithSpaceRegex,
  validate,
} from "../../utils/validations";
import { useTranslation } from "react-i18next";

const ExclutionConfiguration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
  allLabels,
  setLabels,
  currentSelectedLabels,
}) => {
  const { t } = useTranslation('excluded-assets')
  const [form] = Form.useForm();
  const layout = {};
  const { TextArea } = Input;

  useEffect(() => {
    form.setFieldsValue({
      name: wizardState?.name,
      description: wizardState?.description,
    });
    checkValidation({
      name: wizardState?.name,
      description: wizardState?.description,
    });
  }, [wizardState]);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, description } = formValues;

    if (_.isEmpty(name)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nameWithSpaceRegex.regex, name)) {
      formValid = false;
    }

    if (
      !_.isEmpty(description) &&
      !validate(descriptionRegexMultiline.regex, description)
    ) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="ExclutionConfiguration col">
      <div className="ExclutionConfiguration__intro col">
        <span className="small-dark-blue">
          {t('exclusion-creation.steps.details.content.description')}
        </span>
      </div>
      <Form
        {...layout}
        name="policy-wizard-form"
        form={form}
        initialValues={{
          name: "",
          description: "",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState({ ...wizardState, ...allValues });
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label={t('exclusion-creation.steps.details.content.form.name.label')}
          name="name"
          rules={[
            {
              required: true,
              message: t('exclusion-creation.steps.details.content.form.name.required'),
            },
            {
              pattern: nameWithSpaceRegex.regex,
              message: nameWithSpaceRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter policy name" />
        </Form.Item>
        <Form.Item
          label={t('exclusion-creation.steps.details.content.form.description.label')}
          name="description"
          rules={[
            {
              required: false,
              message: t('exclusion-creation.steps.details.content.form.description.required'),
            },
            {
              pattern: descriptionRegexMultiline.regex,
              message: descriptionRegexMultiline.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <TextArea rows={3} placeholder="Enter policy description" />
        </Form.Item>
        <Form.Item
          label={t('exclusion-creation.steps.details.content.form.labels.label')}
          style={{ marginBottom: "0", flexDirection: "column" }}
        >
          <FormLabels
            allLabels={allLabels}
            selectedLabels={setLabels}
            currentSelected={currentSelectedLabels}
          />
        </Form.Item>
      </Form>
    </div>
  );
};

export default ExclutionConfiguration;
