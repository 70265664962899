import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import _, { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { Drawer, Tooltip } from "antd";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { atomDark } from "react-syntax-highlighter/dist/esm/styles/prism";
import {
  formatAwsStringWithUnderscore,
  formatNoUnderscore,
} from "../../../utils/formatting";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes, faFont, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { faGitAlt } from "@fortawesome/free-brands-svg-icons";
import { inventoryEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";

import AppEmpty from "../../../shared/appEmpty/appEmpty";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import AppBtn from "../../../shared/appBtn/appBtn";
import CodeDiff from "../../../shared/codeDiff/codeDiff";
import IssueModal from "../../inventory/issueModal/issueModal";
import DriftExclusionModal from "./driftExclusionModal/driftExclusionModal";
import FixDriftDrawer from "../fixDriftDrawer/fixDriftDrawer";
import FixCloudformationDrift from "../fixCloudformationDrift/fixCloudformationDrift";

import "./driftAnalyzeDrawer.scss";

const DriftAnalyzeDrawer = ({
  visible,
  data,
  closeDrawer,
  noJiraIntegrations,
}) => {
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const excludedDrifts = useSelector(
    (state) => state.driftsExclusionsReducer.rules
  );

  const [code, setCode] = useState("");
  const [json, setJson] = useState("");
  const [jsonMode, setJsonMode] = useState(false);
  const [exclusionVisible, setExclusionVisible] = useState(false);
  const [fixDriftOpen, setFixDriftOpen] = useState(false);
  const [fixCloudformationDriftOpen, setFixCloudformationDriftOpen] = useState(false);
  const [properties, setProperties] = useState([]);
  const [excludedProperties, setExcludedProperties] = useState([]);
  const [excludedPropertiesPrefixes, setExcludedPropertiesPrefixes] = useState([]);
  const [issueModalOpen, setIssueModalOpen] = useState(false);

  const fixDriftAllowed = ["terraform", "cloudFormation"];

  useEffect(() => {
    if (data?.state !== 'modified'){
      return
    }
    setProperties([]);
    setExcludedProperties([]);
    setCode("");
    if (visible && !_.isEmpty(data)) {
      formatCodeKeys(data?.drift);
      const jsonCode = !_.isEmpty(data?.drift)
        ? JSON.stringify(data?.drift, null, 2)
        : "";
      setJson(jsonCode);

      // send event when drawer is open
      sendEvent(inventoryEvents.driftDrawerOpen,
        { 
          assetType: data?.assetType, 
          assetName: data?.name, 
        })
    }
  }, [visible, data]);

  // create new obj cuz there are different keys => inventoryValue / k8sValue || awsValue
  const formatCodeKeys = (arr) => {
    if (!_.isEmpty(arr)) {
      const output = _.map(arr, (item) => {
        const availableIacKeys = [
          "stateValue",
          "helmValue",
          "tfValue",
          "iacValue",
        ];
        const availableProviderKeys = [
          "inventoryValue",
          "k8sValue",
          "awsValue",
          "providerValue",
        ];

        const iacKey = availableIacKeys.filter((key) => _.has(item, key));
        const providerKey = availableProviderKeys.filter((key) =>
          _.has(item, key)
        );
        return {
          inventory: item?.[providerKey],
          keyName: item?.keyName,
          state: item?.[iacKey],
        };
      });
      return setCode(output);
    }
    return;
  };

  useEffect(() => {
    if (!_.isEmpty(code)) {
      let codeKeys = [];
      let excludedProperties = [];
      let excludedPropertiesPrefixes = [];
      let globalScope = `${data?.provider}objects`;

      // enabled excluded drifts by asset type or global type
      const enabledExcludedDriftsByType = _.filter(
        excludedDrifts,
        (driftObj) =>
          driftObj?.isEnabled === true &&
          (driftObj?.type === data?.assetType || driftObj?.type === globalScope)
      );

      // excluded drifts by provider id
      const excludedDriftsByProvider = _.filter(
        enabledExcludedDriftsByType,
        (driftObj) => {
          if (!_.isEmpty(driftObj?.filters?.integrations)) {
            if (
              !_.isUndefined(
                driftObj?.filters?.integrations?.find(
                  (providerId) => providerId === data?.providerId
                )
              )
            ) {
              return driftObj;
            }
          } else {
            if (_.isEmpty(driftObj?.filters?.arn)) {
              return driftObj;
            }
          }
        }
      );

      // excluded drifts by arn
      const excludedDriftsByArn = _.filter(
        enabledExcludedDriftsByType,
        (driftObj) =>
          !_.isUndefined(
            driftObj?.filters?.arn?.find((singleArn) => singleArn === data?.arn)
          )
      );

      const matchingExcludedDrifts = _.concat(
        excludedDriftsByProvider || [],
        excludedDriftsByArn || []
      );

      _.map(matchingExcludedDrifts, (driftExclusion) => {
        excludedPropertiesPrefixes.push(driftExclusion?.prefixProperties);
      });

      setExcludedPropertiesPrefixes(_.flatten(excludedPropertiesPrefixes));

      _.map(code, (element) => {
        codeKeys.push(element?.keyName);
        if (!_.isEmpty(matchingExcludedDrifts)) {
          // excluded drifts by property
          const excludedDriftsByProperty = _.filter(
            matchingExcludedDrifts,
            (driftObj) =>
              !_.isUndefined(
                driftObj?.properties?.find((prop) => prop === element?.keyName)
              )
          );
          // if exclusion found and the property is not already on the list
          if (
            !_.isEmpty(excludedDriftsByProperty) &&
            _.isUndefined(
              excludedProperties.find((exprop) => exprop === element?.keyName)
            )
          ) {
            excludedProperties.push(element?.keyName);
          }
        }
      });
      setExcludedProperties(excludedProperties);
      setProperties(codeKeys);
    }
  }, [code]);

  const renderListOrText = (listStr) => {
    if (!_.isEmpty(listStr)) {
      if (listStr?.startsWith("map[")) {
        let stringToPrint = listStr.slice(4, -1);
        return stringToPrint;
      }
      if (listStr?.startsWith("[")) {
        let listArr = listStr.slice(1, -1).split(",");
        let formattedArr = _.map(listArr, (arn) => arn.slice(1, -1));
        let sortedArray = _.sortBy(formattedArr);
        let stringToPrint = "";
        _.map(sortedArray, (arn) => (stringToPrint += `${arn}\n`));
        return stringToPrint;
      }
      return listStr;
    }
    return "";
  };

  const getStateString = (state) => {
    let returnString = "";
    if (state.startsWith("{") || state.startsWith("[{")) {
      try {
        returnString = JSON.stringify(JSON.parse(state), null, 2);
        return returnString;
      } catch (error) {
        returnString = state;
        return returnString;
      }
    }
    return renderListOrText(state);
  };

  const isPropertyExcluded = (keyName) => {
    if (excludedProperties?.find((property) => property === keyName)) {
      return true;
    }
    if (excludedPropertiesPrefixes?.find((prefix) => keyName?.toString()?.startsWith(prefix))) {
      return true;
    }
    return false;
  }

  const renderCode = () => {

    if (!_.isEmpty(code)) {
      const codeWithExclusions = _.map(code, item => {
        return {...item, excluded: isPropertyExcluded(item?.keyName)}
      })

      // sort the array so excluded will be at end of list
      return _.map(_.orderBy(codeWithExclusions, 'excluded', ['asc']) || [], (element) => {
        return (
          <div
            className={`DriftAnalyzeDrawer__wrapper-body-item diff-code-grid ${
              element?.excluded ? "excluded" : ""
            }`}
            key={uuidv4()}
          >
            {element?.excluded ? (
              <Tooltip placement="top" title="Excluded drift">
                <span className="DriftAnalyzeDrawer__wrapper-body-item-flag center">
                  <FontAwesomeIcon icon={faEyeSlash} />
                </span>
              </Tooltip>
            ) : (
              <span className="DriftAnalyzeDrawer invisible" />
            )}
            <span className={`DriftAnalyzeDrawer__wrapper-body-item-title`}>
              {formatNoUnderscore(element?.keyName)}
            </span>
            <span className={`DriftAnalyzeDrawer__wrapper-body-items`}>
              <CodeDiff
                oldValue={getStateString(element?.inventory?.toString())}
                newValue={getStateString(element?.state.toString())}
              />
            </span>
          </div>
        );
      });
    }
    return (
      <div className="tab-page center">
        <AppEmpty text="No data" customStyle="code" />
      </div>
    );
  };

  const handleCloseModal = () => {
    setExclusionVisible(false);
  };

  const fixDriftClick = (iacType) => {
    if (iacType === "terraform") {
      return setFixDriftOpen(true);
    }
    return setFixCloudformationDriftOpen(true);
  }

  return (
    <div>
      <Drawer
        title={null}
        placement="right"
        closable={false}
        onClose={closeDrawer}
        visible={visible}
        key="right"
        className="DriftAnalyzeDrawer"
        width="80vw"
        maskStyle={{ backgroundColor: "rgba(0,0,0,.5)" }}
        destroyOnClose
        zIndex={1001}
      >
        <div className="DriftAnalyzeDrawer__wrapper col">
          <div className="DriftAnalyzeDrawer__wrapper-title">
            <span className="title">
              {data?.name || ""}
            </span>
            <div className="DriftAnalyzeDrawer__wrapper-title-sub row">
              <span>
                Type:{" "}
                {!_.isEmpty(data?.assetType) &&
                  formatAwsStringWithUnderscore(data?.assetType)}
              </span>
              <span>
                ID: {!_.isEmpty(data?.assetId) && data?.assetId}
              </span>
            </div>
          </div>

          <FontAwesomeIcon
            icon={faTimes}
            onClick={closeDrawer}
            className="DriftAnalyzeDrawer__wrapper__close"
          />
          {jsonMode ? (
            <div className="DriftAnalyzeDrawer__wrapper-body col">
              <SyntaxHighlighter
                style={atomDark}
                wrapLines={true}
                showLineNumbers={true}
                language="hcl"
                lineProps={{
                  style: { wordBreak: "break-all", whiteSpace: "pre-wrap" },
                }}
              >
                {json}
              </SyntaxHighlighter>
            </div>
          ) : (
            <div className="DriftAnalyzeDrawer__wrapper-body col">
              <div className="DriftAnalyzeDrawer__wrapper-body-header">
                <span className="DriftAnalyzeDrawer invisible">excluded</span>
                <span className="DriftAnalyzeDrawer__wrapper-body-header-text">
                  Property
                </span>
                <span className="DriftAnalyzeDrawer__wrapper-body-header-text">
                  Running Configuration
                </span>
                <span className="DriftAnalyzeDrawer__wrapper-body-header-text">
                  {"Desired Configuration (IaC)"}
                </span>
              </div>
              <div className="DriftAnalyzeDrawer__wrapper-body-code">
                {renderCode()}
              </div>
            </div>
          )}
          <div className="DriftAnalyzeDrawer__wrapper-footer row">
            <AppBtn
              plain
              text={!jsonMode ? "JSON View" : "Text View"}
              onClick={() => setJsonMode(!jsonMode)}
              icon={!jsonMode ? `{ }` : <FontAwesomeIcon icon={faFont} />}
              disabled={_.isEmpty(json)}
            />
            <ActionBtn
              text="Copy"
              icon="copy"
              disabled={_.isEmpty(json)}
              action="copy"
              stringToAction={json}
              refresh={visible}
            />
            <ActionBtn
              text="Export"
              disabled={_.isEmpty(json)}
              action="download"
              fileType="json"
              fileName="drift"
              icon="donwload"
              stringToAction={json}
              refresh={visible}
            />
            <AppBtn
              text="Issue"
              onClick={() => setIssueModalOpen(true)}
              icon={<FontAwesomeIcon icon={["fab", "jira"]} />}
              disabled={_.isEmpty(data) || isViewer}
              style={{ transform: "translateY(-1px)" }}
            />
            <AppBtn
              text="Fix Drift"
              onClick={() => fixDriftClick(data?.iacType)}
              disabled={isViewer || !fixDriftAllowed?.includes(data?.iacType) || isEmpty(data?.vcsCodeLink)}
              icon={<FontAwesomeIcon icon={faGitAlt} />}
              tooltipText={isEmpty(data?.vcsCodeLink) ? `The code doesn’t exist in an integrated Git` : ""}
            />
            <AppBtn
              text="Exclude Drift"
              onClick={() => setExclusionVisible(true)}
              disabled={
                isViewer || excludedProperties?.length === properties?.length
              }
              icon={<FontAwesomeIcon icon={faEyeSlash} />}
            />
          </div>
        </div>
      </Drawer>
      <DriftExclusionModal
        visible={exclusionVisible}
        data={data}
        properties={properties}
        handleCloseModal={handleCloseModal}
        isPropertyExcluded={isPropertyExcluded}
      />
      <IssueModal
        visible={issueModalOpen}
        handleClose={() => {
          setIssueModalOpen(false);
        }}
        selectedResources={[data]}
        noJiraIntegrations={noJiraIntegrations}
        driftDrawer
        code={code}
        isPropertyExcluded={isPropertyExcluded}
      />
      <FixDriftDrawer
        close={() => setFixDriftOpen(false)}
        visible={fixDriftOpen}
        data={data}
      />
      <FixCloudformationDrift
        close={() => setFixCloudformationDriftOpen(false)}
        visible={fixCloudformationDriftOpen}
        rowData={data}
      />
    </div>
  );
};

export default DriftAnalyzeDrawer;
