import React, { useEffect } from "react";
import _ from "lodash";
import CopyBox from '../../../../shared/copyBox/copyBox';

const AwsFinalInstructions = ({ wizardState, setSubmitDisabled, isProd }) => {

  useEffect(() => {
    setSubmitDisabled(false);
  })

  return (
    <div className="AWSSetup col">
      <div className="AWSSetup__intro col">
        <span className="small-dark-blue">
          On Pulumi Cloud, <a target="_blank" href="https://app.pulumi.com/new?template=https://github.com/infralight/pulumi-firefly-aws-read-only/blob/main/aws-ts-onboarding/README.md" rel="noreferrer">create new stack,</a>
        </span>
        <span className="small-dark-blue">and enter the following information:</span>
      </div>

      <div className="AWSSetup__props col">
        <div className="AWSSetup__props-item col">
          <label className="form-label">firefly:accessKey</label>
          <CopyBox text={wizardState.accessKey} />
        </div>

        <div className="AWSSetup__props-item col">
          <label className="form-label">firefly:secretKey</label>
          <CopyBox text={wizardState.secretKey} />
        </div>

        <div className="AWSSetup__props-item col">
          <label className="form-label">firefly:integrationName</label>
          <CopyBox text={wizardState.name} />
        </div>

        <div className="AWSSetup__props-item col">
          <label className="form-label">firefly:integrationIsProd</label>
          <CopyBox text={`${isProd}`} />
        </div>
      </div>
    </div>
  );
};

export default AwsFinalInstructions;
