import React from "react";
import { S3_ASSETS } from "../../consts/config";
import Lottie from "react-lottie";
import animationData from "../../components/appSidebar/appLogo/easy_animation_logo_icon.json";

import "./splashScreen.scss";

const SplashScreen = () => {
    const defaultOptions = {
        loop: true,
        autoplay: true,
        animationData: animationData,
      };
      
  return (
    <div className="SplashScreen">
      <Lottie
          options={defaultOptions}
          height={60}
          isStopped={false}
          isPaused={false}
        />
      <img src={`${S3_ASSETS}/logo/logo_text_dark.svg`} alt="logo" className="SplashScreen__text"/>
    </div>
  );
};

export default SplashScreen;
