import React from "react";
import { Drawer } from "antd";
import Configuration from "../../assetConfig/configuration";
import { formatAwsStringWithUnderscore } from "../../../../../utils/formatting";
import moment from 'moment';

const RelationshipTraceConfig = ({ visible, onClose, row = {} }) => {
  return (
    <Drawer
      title={
        <div className="col g5">
          <span className="bold">
            {formatAwsStringWithUnderscore(row?.assetType)}
          </span>
          <div className="row g5" style={{ fontSize: '12px', flexWrap: 'wrap' }}>
            {row?.updateAt && (
              <div className="row g5" style={{ marginRight: '10px' }}>
                <span className="bold">Date</span>
                <span style={{ opacity: .7 }}>
                  {moment(row?.updateAt).format("MM.DD.YYYY")}
                </span>
              </div>
            )}
            {row?.blockLastContributor && (
              <div className="row g5" style={{ marginRight: '10px' }}>
                <span className="bold">Last Contributor</span>
                <span style={{ opacity: .7 }}>
                  {row?.blockLastContributor}
                </span>
              </div>
            )}
            {row?.blockLastContributionCommitIdLink && (
              <div className="row g5">
                <span className="bold">Git</span>
                <a
                  className="underline"
                  style={{
                    opacity: 1,
                  }}
                  href={row?.blockLastContributionCommitIdLink}
                  target="_blank"
                  rel="noreferrer"
                >
                  {row?.repoFullName || "Link"}
                </a>
              </div>
            )}
          </div>
        </div>
      }
      placement="right"
      onClose={onClose}
      visible={visible}
      getContainer={false}
      style={{ position: "absolute" }}
      className="RelationshipAssetConfig"
      width={700}
    >
      <Configuration
        item={row?.blockCode}
        loading={false}
        noFormat
        startingLineNumber={row?.blockStartLine || 1}
      />
    </Drawer>
  );
};

export default RelationshipTraceConfig;
