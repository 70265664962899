import { _ } from "globalthis/implementation";
import React from "react";
import { insightSeverityTypes } from "../../../consts/insights";

const SeverityCell = ({ value = null }) => {

  return (
    <div className="SeverityCell row g10">
        <span
          style={{
            width: "8px",
            height: "8px",
            borderRadius: "50%",
            backgroundColor: insightSeverityTypes[value - 1]?.color || "transparent",
          }}
        />
      {insightSeverityTypes[value - 1]?.title || ""}
    </div>
  );
};

export default SeverityCell;
