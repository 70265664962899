import React from "react";
import _ from "lodash";
import CodeDiff from "../../../../shared/codeDiff/codeDiff";
import CopyBox from "../../../../shared/copyBox/copyBox";
import "./stackDrift.scss";

const StackDrift = ({ driftObj }) => {
  const getStateString = (state) => {
    let returnString = "";
    if (state?.startsWith("{") || state?.startsWith("[{")) {
      try {
        returnString = JSON.stringify(JSON.parse(state), null, 2);
        return returnString;
      } catch (error) {
        returnString = state;
        return returnString;
      }
    }
    return renderListOrText(state);
  };

  const renderListOrText = (listStr) => {
    if (!_.isEmpty(listStr)) {
      if (listStr?.startsWith("map[")) {
        let stringToPrint = listStr.slice(4, -1);
        return stringToPrint;
      }
      if (listStr?.startsWith("[")) {
        let listArr = listStr.slice(1, -1).split(",");
        let formattedArr = _.map(listArr, (arn) => arn.slice(1, -1));
        let sortedArray = _.sortBy(formattedArr);
        let stringToPrint = "";
        _.map(sortedArray, (arn) => (stringToPrint += `${arn}\n`));
        return stringToPrint;
      }
      return listStr;
    }
    return "";
  };
  return (
    <div className="StackDrift col">
      <span className="StackDrift__title bold"><span className="StackDrift__title-description">{`Stack Name: `}</span>{driftObj?.stackName}</span>
      <div className="col StackDrift__drift">
        <div className="StackDrift__drift-code">
        <CodeDiff
          lang="hcl"
          oldValue={getStateString(driftObj?.oldCode?.toString())}
          newValue={getStateString(driftObj?.newCode?.toString())}
        />
        </div>
        <span className="bold StackDrift__drift-cli">
          {`Fix the CloudFormation Template with the existing Cloud values using the following CLI code:`}
        </span>
        <CopyBox text={driftObj?.driftSolution} darkMode />
      </div>
    </div>
  );
};

export default StackDrift;
