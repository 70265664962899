import _ from "lodash";
import React from "react";
import AppBtn from "../../../../shared/appBtn/appBtn";
import { sendEvent } from "../../../../utils/amplitude";
import { inventoryEvents } from "../../../../utils/amplitudeEvents";
import { RESOURCE_STATE_TYPES } from "../../../../consts/general";

const CodifyButton = ({ data = {}, handleCodifyClick, parentData, disabled, ...attrs }) => {
  const dataState = data.state;
  const isUncodifiedState = dataState === RESOURCE_STATE_TYPES.ghost
  || (dataState === RESOURCE_STATE_TYPES.pending && data.pendingTo === RESOURCE_STATE_TYPES.ghost)
  || (dataState === RESOURCE_STATE_TYPES.deleted && data.prevState === RESOURCE_STATE_TYPES.ghost)
  const isDisabled =  disabled || data?.isLocked || isUncodifiedState;
  // if child than we are codifying the parent data
  const codifyData =  !_.isEmpty(parentData) && data.isChild ? parentData : data 
  return (
    <AppBtn
      onClick={() => {
        sendEvent(inventoryEvents.generateIac, {
          action: "user clicked codify from asset info modal",
        });
        handleCodifyClick(codifyData);
      }}
      disabled = {isDisabled}
      {...attrs}
    />
  );
};

export default CodifyButton;
