import React, { useEffect } from "react";
import { Form, Input, Select } from "antd";
import _ from "lodash";
import { validate, nicknameRegex } from "../../../utils/validations";
import { v4 as uuidv4 } from "uuid";

const GithubProviderOrganization = ({ wizardState, setWizardState, organizations, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  useEffect(() => {
    form.setFieldsValue({
      nickname: wizardState?.nickname,
      organization: wizardState?.organization,
    });
    checkValidation({
      nickname: wizardState?.nickname,
      organization: wizardState?.organization,
    });
  }, [wizardState]);

  const checkValidation = (formValues) => {
    let formValid = true;
    const { nickname, organization } = formValues;

    if (_.isEmpty(nickname) || _.isEmpty(organization)) {
      formValid = false;
    }

    if (!_.isEmpty(nickname) && !validate(nicknameRegex.regex, nickname)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="GithubProviderSetup col">
      <Form
        {...layout}
        name="github-app-form"
        form={form}
        initialValues={{
          nickname: "",
          organization: "",
        }}
        className="GithubProviderSetup__form"
        onValuesChange={(allValues) => {
          setWizardState({ ...wizardState, ...allValues });
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="nickname"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          rules={[
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
        >
          <Input placeholder="Please fill Nickname" />
        </Form.Item>
        <Form.Item
          label="Organization"
          name="organization"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Select Organization"
            showSearch
            filterOption={(input, option) =>
              option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {_.map(organizations, (org) => (
              <Select.Option key={uuidv4()} value={org}>
                {org}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default GithubProviderOrganization;
