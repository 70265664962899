import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import isEmpty from 'lodash/isEmpty';
import groupBy from 'lodash/groupBy';
import { getInsightsSuggestionAssets } from "../../../redux/actions/insightsV2Actions";
import AppModal from "../../../shared/appModal/appModal";
import { suggestionsArr } from "../../../consts/suggestions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import SingleSuggestion from "./singleSuggestion";
import Loading from '../../../shared/loading/loading';
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import { getProvideAccountNameByNum, getProviderDarkIconByAccount } from "../../../utils/helpers";
import { getAllProvidersArray } from "../../../utils/formatting";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";

import "./suggestionsModal.scss";


// We should think of a better way to fetch the suggestions, if there will be more then 5000, the use wont see all of them
const suggestionsSize = 5000 

const SuggestionsModal = ({
  visible,
  handleClose,
  data = {},
  tableFilters = {}
}) => {
  const dispatch = useDispatch();
  const [enrichSuggestion, setEnrichSuggestion] = useState({});
  const [loading, setLoading] = useState(false);
  const [providerGroupsData, setProviderGroupsData] = useState({});

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const suggestionsAssets = useSelector(
    (state) => state.insightsV2Reducer.suggestionsAssets
  );

  //   get suggestion name and description
  useEffect(() => {
    if (visible && !isEmpty(data)) {
      const match = suggestionsArr?.find((item) => item?.name === data?.name);
      setEnrichSuggestion(!isEmpty(match) ? match : {});
      fetchInsightAssets();
    }
  }, [visible, data]);

  useEffect(() => {
    if (!isEmpty(suggestionsAssets) && visible && !isEmpty(enrichSuggestion)) {
      const allAccounts = getAllProvidersArray(providerIntegrations);
      const providersGroup = groupBy(suggestionsAssets, "providerId");
      const providersData = {};
      Object.keys((providersGroup)).map((providerId) => {
        const data = providersGroup[providerId];
        const icon = getProviderDarkIconByAccount(providerId, allAccounts);
        const accountName = getProvideAccountNameByNum(providerId, allAccounts);
        const commandsList = data.map(item => setCommandByType(item, enrichSuggestion?.name));
        providersData[providerId] = { icon, accountName, commandsList };
      });
      setProviderGroupsData(providersData);
    }
  }, [suggestionsAssets]);

  const setCommandByType = (item, type) => {
    let output = "";
    switch (type) {
      case "AWS EC2 - Unattached EBS Volumes":
        output = `aws ec2 delete-volume --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS K8s - Unattached EBS Volumes":
        output = `aws ec2 delete-volume --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS GP2 Type EBS Volumes":
        output = `aws ec2 modify-volume --volume-type gp3 --volume-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS Unassociated Elastic IP Addresses":
        output = `aws ec2 release-address --allocation-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS Unused Classic Load Balancer":
        output = `aws elb delete-load-balancer --load-balancer-name ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS EBS Snapshots that are older than 6 months":
        output = `aws ec2 delete-snapshot --snapshot-id ${item?.resourceId} --region ${item?.region}`;
        break;
      case "AWS DB Snapshot older than 6 months":
        output = `aws rds delete-db-snapshot --db-snapshot-identifier ${item?.resourceId} --region ${item?.region}`;
        break;

      default:
        break;
    }
    return output;
  };

  const renderSubTitle = () => {
    if (!isEmpty(enrichSuggestion)) {
      return enrichSuggestion?.description;
    }
    return null;
  };

  const fetchInsightAssets = async () => {
    setLoading(true);
    await dispatch(getInsightsSuggestionAssets(data?._id, 1, suggestionsSize, tableFilters?.integrations));
    setLoading(false);
  };

  const onCopyAllSuggestions = () => {
    let string = "";
    Object.keys(providerGroupsData).map((providerId) => {
      const { commandsList, accountName } = providerGroupsData[providerId] || {};
      const header = `# ${accountName !== providerId ? `${accountName} - ${providerId}` : providerId} \n`;
      string += header
      const copyList = commandsList ?  commandsList.join("\n") : "";
      string += `${copyList} \n`;
    });
    return string;
  };

  const renderFooterLink = () => {
    if (!isEmpty(enrichSuggestion)) {
      return (
        <div className="row between">
          <div className="SuggestionsModal__footer purple-text row">
            <FontAwesomeIcon icon={faInfoCircle} />
            <a href={enrichSuggestion?.link} target="_blank" rel="noreferrer">
              {enrichSuggestion?.linkDesc}
            </a>
          </div>
        {!loading &&
         <div className="row g10">
            <span className="SuggestionsModal__footer-text">Copy All</span>
            <MinimalCopy text={onCopyAllSuggestions()} />
          </div> }
        </div> 
      );
    }
    return null;
  };

  const renderSuggestionList = () => {
    if (!isEmpty(providerGroupsData) && !isEmpty(suggestionsAssets) && visible && !isEmpty(enrichSuggestion)) {
      return Object.keys(providerGroupsData).map((keyName) => {
        const { icon, accountName, commandsList } = providerGroupsData[keyName] || {};
        return (
          <SingleSuggestion
            key={keyName}
            accountId={keyName}
            icon={icon}
            accountName={accountName}
            commandsList={commandsList}
          />
        );
      });
    }
    return <AppEmpty text="No suggestions are available for this insight" />
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      bodyClassName="SuggestionsModal col"
      title={!isEmpty(enrichSuggestion) ? enrichSuggestion?.name : data?.name}
      hideFooterBtns
      width={"900px"}
      customFoter={renderFooterLink()}
      subtitle={renderSubTitle()}
    >
      <div className="SuggestionsModal__content col">
        {loading? <Loading /> : renderSuggestionList()}
      </div>
    </AppModal>
  );
};

export default SuggestionsModal;
