import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const SEARCH_DRIFT_FIX = "SEARCH_DRIFT_FIX";
export const FIX_DRIFT = "FIX_DRIFT";
export const CLEAN_DRIFT_FIX_DATA = "CLEAN_DRIFT_FIX_DATA";

const cleanData = () => {
  return action(async (dispatch) => {
    dispatch({
      type: CLEAN_DRIFT_FIX_DATA,
      payload: null,
    });
  });
};

const searchDriftFix = (references, vcsWorkingDir, drift) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const searchReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-drift/search`,
      "POST",
      {
        references,
        vcsWorkingDir,
        drifts: drift,
      },
      true,
      null,
      false
    );

    const data = await searchReq.json();
    dispatch({
      type: SEARCH_DRIFT_FIX,
      payload: data,
    });
    return { ok: searchReq?.ok };
  });
};

const fixDrift = (frn, vcsId, repo, filesInfo, prComment) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const fixReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-drift/fix`,
      "POST",
      {
        frn,
        vcsId,
        repo,
        filesInfo,
        prComment
      },
      true,
      null,
      false
    );

    const data = await fixReq.json();
    dispatch({
      type: FIX_DRIFT,
      payload: data,
    });
    return { ok: fixReq?.ok, data };
  });
};

const getCftDrift = (frn) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const req = await requestWrapper.sendRequest(
      `${BACKEND_URL}/fix-cloudformation-drift/fixCloudformationDrift`,
      "POST",
      { frn },
      undefined,
      undefined,
      false
    );

    const data = await req.json();
    return { ok: req?.ok, data };
  });
};

export { searchDriftFix, fixDrift, cleanData, getCftDrift };
