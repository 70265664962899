import React from "react";
import _ from "lodash";
import Tooltip from "antd/lib/tooltip";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faTimes,
  faEyeSlash,
  faSearch,
  faCode,
  faFile,
  faDatabase,
} from "@fortawesome/free-solid-svg-icons";
import { UserOutlined } from "@ant-design/icons";
import { ReactComponent as MatchingRules } from "../../Images/general_icons/matchingRules.svg";
import { ReactComponent as Yak } from "../../Images/general_icons/yak.svg";
import { ReactComponent as YakStatusIcon } from "../../Images/general_icons/yak_status.svg";
import { ReactComponent as ProviderCloud } from "../../Images/general_icons/provider_cloud.svg";
import { ReactComponent as CloudConfigIcon } from "../../Images/general_icons/cloud_config.svg";
import { ReactComponent as LocationIcon } from "../../Images/general_icons/location_outline.svg";
import { ReactComponent as YearsIcon } from "../../Images/general_icons/calendar_outline.svg";
import { ReactComponent as BackendsIcon } from "../../Images/general_icons/backends.svg";
import { ReactComponent as CloudStatusIcon } from "../../Images/general_icons/cloud_status.svg";
import { ReactComponent as ModulesIcon } from "../../Images/general_icons/modules.svg";
import { ReactComponent as Tags } from "../../Images/general_icons/tags.svg";
import { ReactComponent as ResourceGroups } from "../../Images/general_icons/Resource_groups.svg";
import { ReactComponent as Repository } from "../../Images/general_icons/repository.svg";
import { ReactComponent as Misconfiguration } from "../../Images/general_icons/plainMisconfiguration.svg";
import { ReactComponent as Reliability } from "../../Images/general_icons/plainReliability.svg";
import { ReactComponent as Optimization } from "../../Images/general_icons/plainOptimization.svg";
import { ReactComponent as History } from "../../Images/general_icons/plainHistory.svg";
import { ReactComponent as Comments } from "../../Images/general_icons/plainComments.svg";
import "./filterBadge.scss";

const FilterBadge = ({ text, type, tooltip, onClick }) => {
  const renderDifferentStateIcons = () => {
    switch (text) {
      case "Drifted":
        return "code-branch";
      case "Unmanaged":
        return "exclamation-circle";
      case "Ghost":
        return "ghost";
      case "Codified":
        return "code";

      default:
        break;
    }
  };

  const renderIconByType = () => {
    let output = null;
    if (type) {
      switch (type) {
        case "environment":
          output = <FontAwesomeIcon icon={faCode} />;
          break;
        case "resource_type":
          output = <FontAwesomeIcon icon={faDatabase} />;
          break;

        case "classification":
          output = <MatchingRules className="FilterBadge__content-img" />;
          break;

        case "excludedAsset":
          output = <FontAwesomeIcon icon={faEyeSlash} />;
          break;

        case "iacType":
          output = <Yak className="FilterBadge__content-img" />;
          break;

        case "regions":
          output = <LocationIcon className="FilterBadge__content-img" />;
          break;

        case "state":
          output = <FontAwesomeIcon icon={renderDifferentStateIcons()} />;
          break;

        case "stacks":
          output = <FontAwesomeIcon icon={faFile} />;
          break;

        case "search":
          output = <FontAwesomeIcon icon={faSearch} />;
          break;

        case "years":
          output = <YearsIcon className="FilterBadge__content-img" />;
          break;

        case "providerType":
          output = <ProviderCloud className="FilterBadge__content-emptyImg" />;
          break;

        case "status":
          output = <YakStatusIcon className="FilterBadge__content-img" />;
          break;

        case "stackProviders.providerType":
          output = <CloudConfigIcon className="FilterBadge__content-img" />;
          break;
        case "crawlerId":
        case "moduleOriginType":
          output = <BackendsIcon className="FilterBadge__content-emptyImg" />;
          break;
        case "hasNotSupported":
          output = (
            <CloudStatusIcon className="FilterBadge__content-emptyImg" />
          );
          break;
        case "hasNotIntegratedProviders":
          output = (
            <CloudStatusIcon className="FilterBadge__content-emptyImg" />
          );
          break;
        case "hasIntegratedProviders":
          output = (
            <CloudStatusIcon className="FilterBadge__content-emptyImg" />
          );
          break;
        case "stateMainRemoteLocationString":
          output = <BackendsIcon className="FilterBadge__content-emptyImg" />;
          break;
        case "stackProviders.providerFullType":
        case "stackProviders.providerIntegrationId":
          output = <ProviderCloud className="FilterBadge__content-emptyImg" />;
          break;
        case "stackAssetTypes":
          output = <FontAwesomeIcon icon={faDatabase} />;
          break;
        case "iacState":
          output = <YakStatusIcon className="FilterBadge__content-img" />;
          break;
        case "stackProviders.providerAccountId":
          output = <ProviderCloud className="FilterBadge__content-emptyImg" />;
          break;

        case "owners":
          output = <UserOutlined className="FilterBadge__content-emptyImg" />;
          break;
        case "metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullName":
        case "metadata.terraformMetadata.usedModules.id":
          output = <ModulesIcon className="FilterBadge__content-emptyImg" />;
          break;

        case "module":
          output = <ModulesIcon className="FilterBadge__content-emptyImg" />;
          break;

        case "tags":
          output = <Tags className="FilterBadge__content-emptyImg" />;
          break;

        case "resourceGroups":
          output = <ResourceGroups className="FilterBadge__content-emptyImg" />;
          break;
        case "metadata.helmMetadata.namespace":
          output = <LocationIcon className="FilterBadge__content-img" />;
          break;
        case "metadata.k8sMetadata.namespace":
          output = <LocationIcon className="FilterBadge__content-img" />;
          break;

        case "_id":
          output = <ModulesIcon className="FilterBadge__content-emptyImg" />;
          break;

        case "repoFullName":
        case "moduleOrigin":
          output = <Repository className="FilterBadge__content-emptyImg" />;
          break;

        case "deleted":
          output = <FontAwesomeIcon icon={["far", "trash-alt"]} />;
          break;

        case "misconfigurations":
          output = (
            <Misconfiguration
              className="FilterBadge__content-emptyImg"
              style={{ width: "auto", height: "12px" }}
            />
          );
          break;
        case "reliability":
          output = <Reliability className="FilterBadge__content-emptyImg" />;
          break;

        case "optimization":
          output = <Optimization className="FilterBadge__content-emptyImg" />;
          break;
        case "git":
          output = <FontAwesomeIcon icon={['fab', "git-alt"]} className="FilterBadge__content-emptyImg"/>;
          break;
        case "history":
          output = <History className="FilterBadge__content-emptyImg" />;
          break;
        case "comments":
          output = <Comments className="FilterBadge__content-emptyImg" />;
          break;

        default:
          break;
      }
    }
    return output;
  };

    return (
      <Tooltip placement="top" title={!_.isEmpty(tooltip) ? tooltip : ''}>
        <div className="FilterBadge row" onClick={onClick}>
          <div className="FilterBadge__content row">
            {renderIconByType()}
            <span>{text}</span>
          </div>
         {onClick && <FontAwesomeIcon icon={faTimes} className="FilterBadge__close" />}
        </div>
      </Tooltip>
    );
};

export default FilterBadge;
