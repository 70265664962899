import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import AppToggle from "../../../shared/appToggle/appToggle";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdrivenGrey.svg";
import { Form, Input } from "antd";
import { guidRegex } from "../../../utils/validations";
import isEmpty from "lodash/isEmpty";
import { validate as uuidValidate } from 'uuid';
import { useLocation } from "react-router-dom/cjs/react-router-dom.min";


const AzurePreferances = ({
  wizardState = {},
  setWizardState,
  setSubmitDisabled,
  isEditMode
}) => {
  const [form] = Form.useForm();
  const location = useLocation();

  const { t } = useTranslation("azure-integration");

  useEffect(() => {
    // this check is for users that doing next-prev, to keep their wizard form state aligned
    if(location.state){
      setWizardState({...wizardState, subscriptionId: location.state})
    }
    checkValidation(wizardState);
  }, []);

  useEffect(() => {
    if(isEditMode && (wizardState?.subscriptionId || location?.state)) {
      form.setFieldsValue({ subscriptionId: wizardState?.subscriptionId})
      return setSubmitDisabled(false);
    }
  },[isEditMode, wizardState])

  const checkValidation = (formValues) => {
    let formValid = true;
    const {
      subscriptionId,
    } = formValues;

    if ( isEmpty(subscriptionId) || !uuidValidate(subscriptionId)) {
      formValid = false;
    }

    return setSubmitDisabled(!formValid);
  };

  const handleToggleField = (fieldName) => {
    setWizardState({
      ...wizardState,
      [fieldName]: !wizardState[fieldName],
    });
    return;
  };

  const items = [
    {
      title: t('preferences.items.ed-title'),
      description: t('preferences.items.ed-sub'),
      icon: <EventDriven style={{ height: '16px' }} />,
      toogleChecked: wizardState?.isEventDriven,
      key: "isEventDriven",
      disabled: isEditMode
    },
    {
      title: t('preferences.items.iac-title'),
      description: t('preferences.items.iac-sub'),
      toogleChecked: !wizardState?.isIacAutoDiscoverDisabled,
      key: "isIacAutoDiscoverDisabled",
    },
    {
      title: t('preferences.items.prod-title'),
      description: t('preferences.items.prod-sub'),
      icon: <FontAwesomeIcon icon="flag" opacity={.7} />,
      toogleChecked: wizardState?.isProd,
      key: "isProd",
    },
  ];

  return (
    <div className="AzurePreferances col">
      
    <Form
        form={form}
        initialValues={{
          ...wizardState
        }}
        onValuesChange={(
          changedValues,
          allValues
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
    >
    <div style={{display: "flex", flexDirection: "column"}}>
      <span className="text">{t("preferences.subscription-title")}</span>
      <span className="text">{t("preferences.subscription-title2")}</span>
    </div>
    <Form.Item
          name="subscriptionId"
          rules={[
            {
              required: true,
              message: t("form.subscription-error"),
            },
            {
              pattern: guidRegex.regex,
              message: `Subscription ID ${guidRegex.msg}`,
            },
          ]}
          style={{ marginBottom: "1.5rem", marginTop: "0.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Subscription ID" disabled={isEditMode} />
        </Form.Item>
      {map(items || [], (i = {}) => {
        return (
          <Form.Item>
          <div className="AzurePreferances__block row g5" key={i.key}>
            <AppToggle
              checked={i.toogleChecked}
              toggleChecked={() => handleToggleField(i.key)}
              disabled={i?.disabled}
            />
            <div className="col g5">
              <div className="row g5">
                <span className="bold">{i.title}</span>
                {i?.icon}
              </div>
              <span className="text">{i.description}</span>
            </div>
          </div>
           </Form.Item>
        );
      })}
    </Form>

    </div>
  );
};

export default AzurePreferances;
