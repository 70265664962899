import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Checkbox, Form, Input, Spin } from "antd";
import _ from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { appToast } from "../../../shared/appToast/appToast";
import { lookup } from "../../../utils/helpers";
import { createAwsIntegration } from "../../../redux/actions/integrationsActions";
import { nicknameRegex, validate } from "../../../utils/validations";
import MarkAsProduction from "../../../shared/markAsProduction/markAsProduction";

const Integration = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
  isProd,
  handelSetIsProd,
  setCreatedIntegration,
  iacAutoDiscovery,
  isEventDriven,
  regions,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const [loadingAssumeRole, setLoadingAssumeRole] = useState(false);
  const [retries, setRetries] = useState(0);
  const [success, setSuccess] = useState(false);

  const dispatch = useDispatch();
  const externalId = useSelector(
    (state) => state.onbordingReducer?.account?.awsIntegrationExternalId
  );

  useEffect(() => {
    let timeoutId;
    if (retries < 11 && !success) {
      if (retries > 0) {
        timeoutId = setTimeout(() => {
          handleAssumeRole();
        }, 30000);
      } else {
        handleAssumeRole();
      }
    }
    return () => clearTimeout(timeoutId);
  }, [retries, success]);

  const handleAssumeRole = async () => {
    setLoadingAssumeRole(true);
    const isIacAutoDiscoverDisabled = !iacAutoDiscovery;
    const created = await dispatch(
      createAwsIntegration(
        `firefly-aws-default-integ${Math.floor(
          Math.random() * (999 - 100 + 1) + 100
        )}`,
        _.trim(wizardState.accountNumber),
        _.trim(wizardState.redactorArn),
        _.trim(externalId),
        false,
        true,
        true,
        isEventDriven,
        regions,
        isIacAutoDiscoverDisabled,
      )
    );
    // if iteration fail
    if (!created?.req?.ok && retries < 10) {
      setRetries(retries + 1);
      return;
    }
    // if all iterations failed
    if (!created?.req?.ok && retries == 10) {
      setLoadingAssumeRole(false);
      const errorJson = created?.data;
      let message = null;
      if (!_.isEmpty(lookup(errorJson, "message"))) {
        message = lookup(errorJson, "message")[1];
      }
      return appToast("error", null, message);
    }
    // integration created
    if (created?.req?.status === 200) {
      setSuccess(true);
      setCreatedIntegration(created?.integration);
      setLoadingAssumeRole(false);
      return;
    }
    setLoadingAssumeRole(false);
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name } = formValues;

    if (_.isEmpty(name)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="AwsIntegration__steps col">
      <div className="CreateRole col">
        {loadingAssumeRole && (
          <div className="CreateRole col">
            <div className="CreateRole__load row">
              <Spin />
              <span className="purple-text bold">
                Waiting for assume-role integration to initiate...
              </span>
            </div>
            <span className="small-dark-blue">
              On the AWS Console tab, make sure to complete the steps in the{" "}
              <span className="purple-flag">Quick create stack</span> screen by
              clicking the <span className="purple-flag">Create stack</span>{" "}
              button
            </span>
            <span className="small-dark-blue">
              This might take a few minutes.
            </span>
          </div>
        )}
      </div>
      {!loadingAssumeRole && success && (
        <div className="Integration">
          <div className="Integration-integrationCheck row">
            <FontAwesomeIcon icon="check-circle" />
            <span className="Integration-integrationCheck-title bold">
              Cross-account Access Completed!
            </span>
          </div>
          <Form
            {...layout}
            name="aws-wizard-form"
            form={form}
            initialValues={{
              name: wizardState.name || null,
            }}
            className="AppWizard__content-body-form"
            onValuesChange={(
              changedValues,
              allValues // this is how you can get the form values
            ) => {
              setWizardState(Object.assign(wizardState, allValues));
              checkValidation(allValues);
            }}
          >
            <Form.Item
              label="Integration Nickname"
              name="name"
              rules={[
                {
                  required: true,
                  message: "Integration Nickname is required",
                },
                {
                  pattern: nicknameRegex.regex,
                  message: nicknameRegex.msg,
                },
              ]}
              style={{
                marginBottom: "1.5rem",
                flexDirection: "column",
                width: "50%",
              }}
            >
              <Input placeholder="Please fill Integration Nickname" />
            </Form.Item>

            <div className="ViewManagerModal__form-toggle col">
              <Checkbox checked={isProd} onChange={handelSetIsProd}>
                <MarkAsProduction />
              </Checkbox>
            </div>
          </Form>
        </div>
      )}
      {!loadingAssumeRole && !success && (
        <span className="Integration-failure col small-dark-blue">
          <span>
            Your AWS integration was unsuccessful. Verify the accuracy of the
            following:
          </span>
          <ul className="Integration-failure-list">
            <li key="1">Account permissions</li>
            <li key="2">Account ID</li>
          </ul>
          <span>Contact us via Intercom for support.</span>
        </span>
      )}
    </div>
  );
};

export default Integration;
