import React, { memo } from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { numberWithCommas } from '../../utils/formatting';
import './assetCountFlag.scss';

const AssetCountFlag = ({ sum, type }) => {

    const renderIconByTypes = () => {

        switch (type) {
            case 'asset':
                return "layer-group";
        
            case 'cost':
                return "dollar-sign";

            default:
                return "layer-group";
        }
    }

    return (
        <div className={`AssetCountFlag bold ${type}`}>
            <FontAwesomeIcon icon={renderIconByTypes()} />
            {type === "cost" ? "Coming Soon" : sum ? numberWithCommas(sum) : '-'}
        </div>
    )
}

export default memo(AssetCountFlag);
