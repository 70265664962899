import "./fixedDrift.scss";
import React from "react";
import _ from "lodash";
import {ERR_UPDATED_SOME_FILES} from "../../../../consts/errorCodes";
import CopyBox from "../../../../shared/copyBox/copyBox";


const FixedDrift = ({obj}) => {
    return (
        <span className="col FixedDrift">
            <span>
              {!_.isUndefined(obj?.error) && (obj?.responseText)}
            </span>
            {(_.isUndefined(obj?.error) || obj?.error === ERR_UPDATED_SOME_FILES) &&
                <>
                    <span>
                    Take a look at your <a href={obj?.url} target="_blank" rel="noopener noreferrer">Pull
                        request</a> in the repo {obj?.repo}.
                    </span>
                    <span className="muted">Fixed drift in {_.keys(obj?.updatedFiles).length === 1 ? "file" : "files" }
                        {_.map(_.keys(obj?.updatedFiles), (filePath) => " " + filePath)}.
                    </span>
                    <span className="FoundResource__fixed-commandDescription">
                        To refresh the Terraform state, run:
                    </span>
                    <span className="FixedDrift__fixed-command row">
                         <CopyBox
                             text={`terraform refresh -target "${obj?.objectInfo?.objectModule && `${obj?.objectInfo?.objectModule}.`}${obj?.objectInfo?.objectType}.${obj?.objectInfo?.objectName}"`}
                             darkMode
                        />
                    </span>
                </>
            }
        </span>
    );
};

export default FixedDrift;
