import _ from "lodash";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import {
  editAwsIntegration,
} from "../../../redux/actions/integrationsActions";
import { getUserRedactorPreference } from "../../../redux/actions/profilesActions";
import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import RoleValidation from "./roleValidation";
import Integration from "./integration";
import { clouds } from "../../../utils/icons";
import regionsOptions from "../../../consts/regions";

import "./awsIntegration.scss";

const AwsIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [loadingInit, setLoadingInit] = useState(true);

  const externalId = useSelector(
    (state) => state.onbordingReducer?.account?.awsIntegrationExternalId
  );
  const accountId = useSelector(
    (state) => state.onbordingReducer?.account?._id
  );
  const APPLE_ACCOUNT_ID = '62bc7c3ad8d7b47f6689dedc';
  const FINAL_ACCOUNT_ID = '63ee37a0451465fdf8e268e5';
  const ZOOMINFO_ACCOUNT_ID = '63bfd5d9e822e936714f84cf';

  const redactorPreference = useSelector(
    (state) => state.profilesReducer.redactorPreference
  );

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    getInitData();
  }, []);

  const getLaunchLink = () => {
    const STACK_NAME = "firefly-readonly";
    const RESOURCE_NAME_PREFIX = "infralight";
    const REGION = "us-east-1";

    let CFT_TEMPLATE =
      "https://infralight-templates-public.s3.amazonaws.com/config_template.yml";
    if (redactorPreference) {
      CFT_TEMPLATE =
        "https://infralight-templates-public.s3.amazonaws.com/terraform-redactor/template.yml";
    }
    // THIS IS TEMPORARY PATCH
    if(accountId === APPLE_ACCOUNT_ID) {
      CFT_TEMPLATE = "https://infralight-templates-public.s3.amazonaws.com/config_template_pb.yml"
    }
    if(accountId === FINAL_ACCOUNT_ID) {
      CFT_TEMPLATE = "https://infralight-templates-public.s3.amazonaws.com/config_template_final.yml"
    }
    if(accountId === ZOOMINFO_ACCOUNT_ID) {
      CFT_TEMPLATE = "https://infralight-templates-public.s3.amazonaws.com/config_template_zi.yml"
    }
    return `https://${REGION}.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=${CFT_TEMPLATE}&param_ExternalID=${externalId}&stackName=${STACK_NAME}&param_ResourceNamePrefix=${RESOURCE_NAME_PREFIX}`;
  };

  const getInitData = async () => {
    await Promise.all([
      dispatch(getUserRedactorPreference()),
    ]);
    setLoadingInit(false);
  };

  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [isProd, setIsProd] = useState(false);
  const [createdIntegration, setCreatedIntegration] = useState({});
  const [regions, setRegions] = useState(_.keys(regionsOptions));
  const [isEventDriven, setIsEventDriven] = useState(true);
  const [iacAutoDiscovery, setIacAutoDiscovery] = useState(true);


  useEffect(() => {
    if (isEventDriven && _.isEmpty(regions)) {
      setSubmitDisabled(true);
    } else {
      setSubmitDisabled(false);
    }
  }, [regions, isEventDriven]);

  const configWizard = [
    {
      step_title: "Account details",
      step_description: "Create read-only assume role",
      content_title: "Integrating AWS using CloudFormation",
      content: (
        <RoleValidation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isProd={isProd}
          handelSetIsProd={() => setIsProd(!isProd)}
          handleIacAutoDiscovery={() => setIacAutoDiscovery(!iacAutoDiscovery)}
          iacAutoDiscovery={iacAutoDiscovery}
          loadingInit={loadingInit}
          isEventDriven={isEventDriven}
          setIsEventDriven={setIsEventDriven}
          regions={regions}
          setLabels={(regions) => setRegions(regions)}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        window.open(getLaunchLink(), "_blank");
      },
    },
    {
      step_title: "Integration",
      step_description: "Integration details",
      content_title: "Integration",
      content: (
        <Integration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isProd={isProd}
          handelSetIsProd={() => setIsProd(!isProd)}
          setCreatedIntegration={setCreatedIntegration}
          isEventDriven={isEventDriven}
          iacAutoDiscovery={iacAutoDiscovery}
          regions={regions}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const edited = await dispatch(
          editAwsIntegration(
            createdIntegration?._id,
            wizardState?.name,
            createdIntegration?.fetchable,
            isProd
          )
        );
        setLoadingSubmit(false);
        if (!edited) {
          throw new Error(
            "Failed to create AWS integration. Please try again."
          );
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  return (
    <div className="AwsIntegration">
      <AppWizard
        iconSrc={clouds("aws", themeDark)}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        customFirstNextText={
          <span>
            Launch Stack
            <FontAwesomeIcon
              icon="arrow-circle-right"
              style={{ marginLeft: "10px" }}
            />
          </span>
        }
      />
    </div>
  );
};

export default AwsIntegration;
