import React, { useState, useEffect } from "react";
import _, { isEmpty } from "lodash";
import { v4 as uuidv4 } from "uuid";
import AppDrawer from "../../../shared/appDrawer/appDrawer";
import AppEmpty from "../../../shared/appEmpty/appEmpty";
import ActionBtn from "../../../shared/actionBtn/actionBtn";
import AppBtn from "../../../shared/appBtn/appBtn";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";
import AppToggle from "../../../shared/appToggle/appToggle";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { greenIacProviders } from "../../../utils/icons";

import "./multiRemoveAssetCode.scss";

const MultiRemoveAssetCode = ({
  visible,
  handleClose,
  selectedAssets,
  pullRequestRemove,
}) => {
  const [activeTab, setActiveTab] = useState("cmd");
  const [commentToggle, setCommentToggle] = useState(false);
  const [assetsByTab, setAssetsByTab] = useState({
    cmd: [],
    cmdCopy: null,
    pr: [],
  });

  useEffect(() => {
    if (visible && !_.isEmpty(selectedAssets)) {
      handleSortAssets();
    }
  }, [visible, selectedAssets]);

  const handleSortAssets = () => {
    let cmd = _.filter(
      selectedAssets || [],
      (item) => item?.state === "unmanaged" || item?.iacType !== "terraform"
    );

    // list the cmd commands and filter out assets without a delete command
    const listCmd = _.filter(
      _.map(cmd || [], (item) => {
        if (_.has(item, "deleteCommand") && !_.isEmpty(item?.deleteCommand)) {
          return item?.deleteCommand;
        }
        return null;
      }),
      (el) => el !== null
    );

    // prepare aws cmd to copy/export
    let cmdCopy = listCmd.join("\n");

    // filter the pull-request data for it's tab
    let pr = _.filter(
      selectedAssets || [],
      (item) => item?.state !== "unmanaged" && item?.iacType === "terraform"
    );
    setActiveTab(isEmpty(listCmd) ? "pr" : "cmd");
    return setAssetsByTab({ cmd: listCmd, cmdCopy, pr });
  };

  const renderCmdCommandsList = () => {
    if (_.isEmpty(assetsByTab?.cmd)) {
      return <AppEmpty text="No assets" customStyle="code" />;
    }
    return _.map(assetsByTab?.cmd || [], (item) => {
      return (
        <span
          key={uuidv4()}
          className="MultiRemoveAssetCode__body-cmd code-text row between"
        >
          {item}
          <MinimalCopy text={item} purple />
        </span>
      );
    });
  };

  const renderPullRequestList = () => {
    if (_.isEmpty(assetsByTab?.pr)) {
      return <AppEmpty text="No assets" customStyle="code" />;
    }
    return (
      <div>
        <span className="MultiRemoveAssetCode__body-pr-toggle row">
          <span>Comment out code</span>
          <AppToggle
            checked={commentToggle}
            toggleChecked={() => setCommentToggle(!commentToggle)}
          />
        </span>
        {_.map(assetsByTab?.pr || [], (item) => {
          return (
            <span
              key={uuidv4()}
              className="MultiRemoveAssetCode__body-pr row between"
            >
              <span className="code-text">{item?.name}</span>
              <AppBtn
                text="Pull request"
                onClick={() => pullRequestRemove(item, commentToggle)}
                icon={<FontAwesomeIcon icon={["fab", "git-alt"]} />}
              />
            </span>
          );
        })}
      </div>
    );
  };

  const renderContent = () => {
    switch (activeTab) {
      case "cmd":
        return renderCmdCommandsList();
      case "pr":
        return renderPullRequestList();
      default:
        return <AppEmpty text="No Data" customStyle="code" />;
    }
  };

  return (
    <AppDrawer
      title="Delete Assets"
      visible={visible}
      closeDrawer={handleClose}
      width={800}
      hideClose={activeTab !== "pr"}
      zIndex={1001}
      footer={
        activeTab === "pr"
          ? []
          : [
              <ActionBtn
                text="Export"
                disabled={_.isEmpty(assetsByTab?.cmdCopy)}
                action="download"
                fileType="txt"
                fileName="AWS Delete CMD"
                icon="donwload"
                stringToAction={assetsByTab?.cmdCopy}
                refresh={visible}
                key="export"
              />,
              <ActionBtn
                text="Copy All"
                icon="copy"
                disabled={_.isEmpty(assetsByTab?.cmdCopy)}
                action="copy"
                stringToAction={assetsByTab?.cmdCopy}
                refresh={visible}
                key="copy"
              />,
            ]
      }
    >
      <div className="MultiRemoveAssetCode col">
        <div className="MultiRemoveAssetCode__tabs row">
          {assetsByTab?.cmd?.length > 0 && (
            <button
              onClick={() => setActiveTab("cmd")}
              className={`MultiRemoveAssetCode__tabs-tab row center ${
                activeTab === "cmd" ? "cmd" : ""
              }`}
            >
              <FontAwesomeIcon icon="exclamation-circle" />
              {`Unmanaged (${assetsByTab?.cmd?.length})`}
            </button>
          )}
          {assetsByTab?.pr?.length > 0 && (
            <button
              onClick={() => setActiveTab("pr")}
              className={`MultiRemoveAssetCode__tabs-tab row center ${
                activeTab === "pr" ? "pr" : ""
              }`}
            >
              <img src={greenIacProviders["terraform"]} alt="terraform" />
              {`Managed (${assetsByTab?.pr?.length})`}
            </button>
          )}
        </div>
        <div className="MultiRemoveAssetCode__body col">{renderContent()}</div>
      </div>
    </AppDrawer>
  );
};

export default MultiRemoveAssetCode;
