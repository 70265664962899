import {
  GET_EXCLUSIONS,
  UPDATE_EXCLUSION,
  ANALYZE_EXCLUDED_RULES,
  TEST_EXCLUDED_RULE,
  RESET_TEST_RESULT,
} from "../actions/exclusionsActions";
import _ from "lodash";

// resetTestResult

const initialState = {
  rules: [],
  excludedResources: [],
  excludedByResourceType: {},
  testPassed: false,
};

export default (state = initialState, { type, payload }) => {
  let excludedResources;
  let excludedByResourceType;

  switch (type) {
    case GET_EXCLUSIONS:
      return { ...state, rules: payload.rules };

    case UPDATE_EXCLUSION:
      return { ...state };

    case ANALYZE_EXCLUDED_RULES:
      excludedResources = _.map(payload.excludedResources, (x) => x.id);
      excludedByResourceType = _.chain(payload.excludedResources)
        .groupBy("type")
        .mapValues((group) => _.map(group, (x) => x.id))
        .value();

      return { ...state, excludedResources, excludedByResourceType };

    case TEST_EXCLUDED_RULE:
      excludedResources = _.map(payload.excludedResources, (x) => x.id);
      excludedByResourceType = _.chain(payload.excludedResources)
        .groupBy("type")
        .mapValues((group) => _.map(group, (x) => x.id))
        .value();

      return {
        ...state,
        excludedResources,
        excludedByResourceType,
        testPassed: true,
      };

      case RESET_TEST_RESULT:
      return { ...state, testPassed: false };

    default:
      return state;
  }
};
