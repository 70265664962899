// list of insight ID'S we have suggestions for.
// these ID's are taken from the BE calssification init service

export const suggestionsArr = [
  {
    name: "AWS EC2 - Unattached EBS Volumes",
    id: "6130a2f5e04b3ceb462388b5",
    description:
      "Identify any unattached (unused) Elastic Block Store (EBS) volumes available in your AWS account, remove them in order to lower the cost of your monthly AWS bill and reduce the risk of confidential/sensitive data leaving your premise.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/ec2/delete-volume.html",
    linkDesc: "Delete-volume — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS K8s - Unattached EBS Volumes",
    id: "6130a2f5e04b3ceb462388b6",
    description:
      "Identify any unattached (unused) Elastic Block Store (EBS) volumes available in your AWS account, remove them in order to lower the cost of your monthly AWS bill and reduce the risk of confidential/sensitive data leaving your premise.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/ec2/delete-volume.html",
    linkDesc: "Delete-volume — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS GP2 Type EBS Volumes",
    id: "6152cc6145ff1de84b54483f",
    description:
      "If you're currently using gp2, you should consider switching to gp3 for your EBS volumes. It will save you about 20% on your EBS bill, and in many cases improves performance. you can easily migrate your EBS volumes to gp3 volumes without interrupting your Amazon EC2 instances.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/ec2/modify-volume.html",
    linkDesc: "Modify-volume — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS EBS Snapshots that are older than 6 months",
    id: "621d0b38e25e3b5589da0f63",
    description:
      "Identify any old Elastic Block Store (EBS) volumes' snapshots available in your AWS account, remove them in order to lower the cost of your monthly AWS bill and reduce the risk of confidential/sensitive data leaving your premise.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/ec2/delete-snapshot.html",
    linkDesc: "Delete-snapshot — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS DB Snapshot older than 6 months",
    id: "61534c8fb856cdf01a29c551",
    description:
      "Identify any old DB (RDS) snapshots available in your AWS account, remove them in order to lower the cost of your monthly AWS bill and reduce the risk of confidential/sensitive data leaving your premise.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/rds/delete-db-snapshot.html",
    linkDesc: "Delete-db-snapshot — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS Unassociated Elastic IP Addresses",
    id: "6e5d03734d244d719c62dfd1",
    description:
      "Check for unattached Amazon EIP addresses within your AWS cloud account and remove them to lower the cost of your AWS bill.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/ec2/release-address.html",
    linkDesc: "Release-address — AWS CLI 1.22.14 Command Reference",
  },
  {
    name: "AWS Unused Classic Load Balancer",
    id: "6e5d03734d244d719c62dfd2",
    description:
      "Identify unused Amazon Classic Load Balancers available within your AWS cloud account and delete these load balancers in order to lower the cost of your AWS bill.",
    link: "https://docs.aws.amazon.com/cli/latest/reference/elb/delete-load-balancer.html",
    linkDesc: "Delete-load-balancer — AWS CLI 1.22.14 Command Reference",
  },
];
