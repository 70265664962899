import React, { useEffect } from "react";
import { Form, Input } from "antd";
import AppModal from "../../../shared/appModal/appModal";
import { useTranslation } from "react-i18next";

const EditKeyPair = ({
  visible,
  handleClose,
  loadingSubmit,
  handleOnSubmit,
  accessKeyToEdit,
  handleEditKeyPair
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const { t } = useTranslation('users')

  useEffect(() => {
    if (visible) {
      form?.setFieldsValue(
        { description: accessKeyToEdit?.description }
      );
    }
  }, [visible]);


  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      title={t('access-keys.edit-modal.title')}
      loadingSubmit={loadingSubmit}
      handleOnSubmit={handleOnSubmit}
      formName="edit-key-pair"
      submitBtnText={t('access-keys.edit-modal.save-btn')}

      destroyOnClose
    >
      <Form
        {...layout}
        name="edit-key-pair"
        form={form}
        className="EditKeyPair"
        onFinish={(allValues) => handleEditKeyPair(allValues)}

      >
        <Form.Item
          label={t('access-keys.edit-modal.form.alias')}
          name="description"
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          rules={[
            {
              required: true,
              message: t('access-keys.edit-modal.form.required'),
            },
          ]}
        >
          <Input placeholder="" />
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default EditKeyPair;
