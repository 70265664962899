import React, { useEffect, useState } from "react";
import moment from "moment";
import { Collapse, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { ciDataSourceIcons } from "../../utils/icons";
import CiStepsTable from "./ciStepsTable";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import isEmpty from "lodash/isEmpty";
import { sendEvent } from "../../utils/amplitude";
import { CiEvents } from "../../utils/amplitudeEvents";
import { getTasksByWorkspaceId } from "../../redux/actions/ciWorkflowsActions";

const { Panel } = Collapse;

const CI_STEP_DATE_FORMAT = "MMM DD YYYY, HH:mm"

const CI_STATUSES_ICON = {
    FINISHED: "check-double",
    QUEUED: "spinner",
    RUNNING: "ellipsis-h",
    FAILED: "times-circle",
    planning: "ellipsis-h",
    applying: "ellipsis-h",
    "plan_success": "check-circle",
    "plan_error": "times-circle",
    "apply_error": "times-circle",
    "apply_success": "check-double",
}

const CI_STATUSES_TITLES = {
    FINISHED: "COMPLETED",
    QUEUED: "QUEUED",
    RUNNING: "IN PROGRESS",
    FAILED: "FAILED",
    planning: "PLANNING",
    applying: "APPLYING",
    "plan_success": "PLAN COMPLETED",
    "plan_error": "PLAN FAILED",
    "apply_error": "APPLY FAILED",
    "apply_success": "APPLIED",
}

const CI_RUNS_INTERVAL = 7000;
const CiStepsCollapse = ({ data = {} }) => {
    const [loading, setLoading] = useState(false);
    const [tasks, setTasks] = useState([]);
    const [pageNumber, setPageNumber] = useState(1);
    const [pageSize, setPageSize] = useState(10);
    const [collapseIsOpen, setCollapseIsOpen] = useState(false);

    const { workspaceId = "" , workspaceName = '', lastRunStatus: status = '', runnerType = '', repo: vcsRepo = '', repoUrl: vcsRepoUrl = '', vcsProvider = '', lastApplyTime = '', terraformVersion = '', runsCount = 0, backendSummary = {} } = data;
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const dispatch = useDispatch();
    const isVCSExists = vcsProvider && vcsRepo;

    const fetchTasksByWorkspaceId = async (firstFetch = false) => {
        if (!collapseIsOpen) return;
        if (firstFetch) {
            setLoading(true);
        }
        const newOffset = (pageNumber - 1) * pageSize;
        const response = await dispatch(getTasksByWorkspaceId(workspaceId, { offset: newOffset > runsCount ? 0 : newOffset ,size: pageSize }));
        if (isEmpty(response)) return;
        setTasks(response);
        setLoading(false);
    };

    useEffect(() => {
        let interval;
        if (collapseIsOpen) {
            fetchTasksByWorkspaceId(true);
            interval = setInterval(fetchTasksByWorkspaceId, CI_RUNS_INTERVAL);
         }
         else {
            clearInterval(interval);
         }
        return () => clearInterval(interval);
    }, [collapseIsOpen, pageNumber, pageSize]);

    const onCollapseChange = (openCollapses) => {
        const isCollapseOpened = !isEmpty(openCollapses);
        setCollapseIsOpen(isCollapseOpened);
        if (!isCollapseOpened) return;
        sendEvent(CiEvents.workflowCollapseClick, { repo: vcsRepo, vcs: vcsProvider, workspaceName })
    }

    return (
        <Collapse
            className="CiStepsCollapse"
            onChange={onCollapseChange}
            >
            <Panel header={
                <div className="CiStepsCollapse__header">
                    <div className="row g5">
                        {runnerType && <img className="CiStepsCollapse__header-vcs-icon" src={ciDataSourceIcons(runnerType, themeDark)} alt={runnerType}/>} 
                        <Tooltip title={workspaceName}>
                            <span className="CiStepsCollapse__header-vcs-text">{workspaceName}</span>
                        </Tooltip>
                    </div>
                <span className={`CiStepsCollapse__header__name`}>{lastApplyTime ? moment(lastApplyTime).format(CI_STEP_DATE_FORMAT) : "-"}</span>
                    {isVCSExists ? <span className="row CiStepsCollapse__header-vcs">
                        <img className="CiStepsCollapse__header-vcs-icon" src={ciDataSourceIcons(vcsProvider, themeDark)} alt={vcsProvider} onClick={() => window.open(vcsRepoUrl, "_blank")}/>
                        <Tooltip title={vcsRepo}>
                            <span className="CiStepsCollapse__header-vcs-text ModuleCall__select-link" onClick={() => window.open(vcsRepoUrl, "_blank")}>{vcsRepo}</span>
                        </Tooltip>
                    </span> : <span className=""></span>}
                    <div className="CiStepsCollapse__header-vcs row g5">
                        <img className="CiStepsCollapse__header-vcs-icon tf" src={ciDataSourceIcons("terraform")}/>
                        <span className="CiStepsCollapse__header-vcs-text">{terraformVersion}</span>
                    </div>
                    <div className="row CiStepsCollapse__header-status">
                        <FontAwesomeIcon className={`CiStepsCollapse__header-status-icon ${status}`} icon={["fas", CI_STATUSES_ICON[status]]}
                         spin={status === "planning" || status === "applying"}/>
                        <span className={`CiStepsCollapse__header-status-text ${status}`}>{CI_STATUSES_TITLES[status]}</span>
                    </div>
                </div>
            }
            key="1">
            {<CiStepsTable tasks={tasks} statusTitles={CI_STATUSES_TITLES} workspaceName={workspaceName} runsCount={runsCount} loading={loading} setPageNumber={setPageNumber} setPageSize={setPageSize} iacStackId={backendSummary.iacStackId}/>}
            </Panel>
        </Collapse>
    )
}

export default CiStepsCollapse;