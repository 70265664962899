import React from "react";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import MinimalCopy from "../../../shared/minimalCopy/minimalCopy";

import { v4 as uuidv4 } from "uuid";

import "./singleSuggestion.scss";

const SingleSuggestion = ({ accountName, commandsList, icon , accountId }) => {

  const handleFormatFilterAccountName = () => {
    return (
      <div className="SingleSuggestion__header-account row">
        <img
          src={icon}
          alt="cloud"
        />
        <span className="bold">
          {accountName !== accountId ? `${accountName} - ${accountId}` : accountId}
        </span>
      </div>
    );
  };

  const renderList = () => {
    if (!isEmpty(commandsList)) {
      return map(commandsList, (item) => {
        return (
          <span className="SingleSuggestion__item" key={uuidv4()}>
            {item}
          </span>
        );
      });
    }
  };

  const handleFormatToCopy = () => {
    if(!isEmpty(commandsList)) {
      const copyList = commandsList.join("\n");
      return copyList;
    }
  }

  return (
    <div className="SingleSuggestion">
      <div className="SingleSuggestion__header row between">
        {handleFormatFilterAccountName()}
        <MinimalCopy text={handleFormatToCopy()} />
      </div>
      <div className="col">{renderList()}</div>
    </div>
  );
};

export default SingleSuggestion;
