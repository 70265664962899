import React from "react";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { capitalizeFirst, renderEllipsis } from "../../../../../utils/formatting";
import DarkCopyBox from "../../../../../shared/darkCopyBox/darkCopyBox";
import './diagnostic.scss';

const Diagnostic = ({ item = {}, withHeaderBg = true}) => {
    const { key = '',  severity = '', summary = "", range = {}, snippet = {}, detail = "" } = item;
    const { filename = "", start = {} } = range;
    const { context = "", code = "", } = snippet;
    const { line: startLine = 0 } = start;
    const severLower = severity.toLowerCase();
    const severityTitle = capitalizeFirst(severLower);
    const contextAddition = context ? ` in ${context}` : "";
    const openingLine = `on ${filename} line ${startLine}${contextAddition}:`;
    const withHeaderBgColorBySeverity = withHeaderBg ? severLower: "";

    return (
        <div className="Diagnostic col" key={key}>
            <div className={`Diagnostic__header ${withHeaderBgColorBySeverity} row between`}>
                <div className="row g8">
                    <FontAwesomeIcon icon="exclamation" className={`Diagnostic__icon ${severLower}`}/>
                    <Tooltip title={summary?.length > 75 ? summary : ""}><span className="Diagnostic__header-title">{renderEllipsis(summary, 75)}</span></Tooltip>
                </div>
                <div className={`Diagnostic__severityBtn ${severLower}`}>{severityTitle}</div>
            </div>
            <div className="Diagnostic__body col g5">
                {startLine != 0  ? <span className="Diagnostic__text">{openingLine}</span> : null }
                {code ? <DarkCopyBox text={code}/> : null}
                <span className="Diagnostic__text">{detail}</span>
            </div>
        </div>);

}

export default Diagnostic;