import { Spin } from "antd";
import { isEmpty } from "lodash";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getEventDrivenCode } from "../../../redux/actions/iacStacksActions";
import Empty from "antd/lib/empty";
import CopyBox from "../../../shared/copyBox/copyBox";
import "./backendsTable.scss";

const EventDrivenCode = ({ rowId }) => {
  const [code, setCode] = useState("");
  const [loading, setLoading] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => {
    getCode();
  }, []);

  const getCode = async () => {
    setLoading(true);
    const code = await dispatch(getEventDrivenCode(rowId));
    setCode(code);
    setLoading(false);
  };
  const renderCode = () => {
    if (!isEmpty(code)) {
      return (
        <div className="BackendInfo__eventDriven-code col">
          <span>Run this terraform code to turn this backend into event-driven:</span>
          <CopyBox text={code} darkMode wordBreak />
        </div>
      );
    }
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />;
  };
  return (
    <div
      className={`BackendInfo__eventDriven ${
        loading || isEmpty(code) ? "center" : ""
      }`}
    >
      {loading ? <Spin /> : renderCode()}
    </div>
  );
};

export default EventDrivenCode;
