import React from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { ReactComponent as SavingsIcon }  from "../../../Images/dashboard/savings.svg";
import { ReactComponent as WorkflowsIcon }  from "../../../Images/general_icons/sidebar/ci-pipeline.svg";
import { setInsightsFilters } from "../../../redux/actions/insightsV2Actions";
import { getAbbreviateNumber } from "../../../utils/helpers";
import { numberWithCommas } from "../../../utils/formatting";

import "./cloudWorkflows.scss";

const CloudWorkflows = ({ totalCost: totalMonthCost = 0 }) => {
    const costInYear = totalMonthCost * 12;
    const savingsTotalCost = costInYear == 0 ? 0 : costInYear > 10000 ? getAbbreviateNumber(costInYear, 0) : numberWithCommas(costInYear.toFixed(0));
    
    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const insightsReducer = useSelector((state) => state.insightsV2Reducer);
    const history = useHistory();
    const dispatch = useDispatch();

    const handleTotalSavingsClick = () => {
        if (!savingsTotalCost) return;
        const payload = { ...insightsReducer?.insightsFilters, labels: "Optimization" };
        dispatch(setInsightsFilters(payload));
        history.push({ pathname: `/governance` });
    };

    return (
        <div className="CloudWorkflows col g10">
            <div className={`basic-card CloudWorkflows__card savings ${!themeDark ? "light" : ""} col g8 center`} onClick={handleTotalSavingsClick}>
                <SavingsIcon />
                <span className="CloudWorkflows__card-cost">
                    <span className="CloudWorkflows__card-cost-sum">${savingsTotalCost}</span>
                    <span className="CloudWorkflows__card-cost-slash">/yr</span>
                </span>
                <span className="CloudWorkflows__card-title">Projected Savings</span>
            </div>
            <span className="muted">Workflows</span>
            <div className="basic-card CloudWorkflows__card workflows col g8 center">
                <WorkflowsIcon className="CloudWorkflows__icon"/>
                <span className="CloudWorkflows__card-title">Coming soon</span>
            </div>
        </div>
    );
};

export default CloudWorkflows;