import React, { useState, useEffect } from "react";
import Loading from "../loading/loading";
import AppEmpty from "../appEmpty/appEmpty";
import { useDispatch, useSelector } from "react-redux";
import { getRetoolEmbedUrl } from "../../redux/actions/globalAppActions";
import "./retoolEmbedWrapper.scss";

const RetoolEmbedWrapper = ({
    retoolAppName,
    noUrlText = 'The page is currently unavailable',
}) => {
    const [retoolEmbedUrl, setRetoolEmbedUrl] = useState("");
    const [loadingUrl, setLoadingUrl] = useState(true);
    const dispatch = useDispatch();

    const isThemeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
  
    useEffect(() => {
        fetchUrl()
    }, [retoolAppName]);

    const fetchUrl = async () => {
        setLoadingUrl(true);
        const themeMode = isThemeDark ? 'dark' : 'light'; 
        const req = await dispatch(getRetoolEmbedUrl(retoolAppName, themeMode));
        if(req) {
            setRetoolEmbedUrl(req);
        }
        setLoadingUrl(false);
    }

    if(loadingUrl) {
       return <Loading />
    }
    
    if(!loadingUrl && !retoolEmbedUrl) {
       return <AppEmpty text={noUrlText} customStyle="code"  />
    }

    return (
        <div className="RetoolEmbedWrapper">
         <iframe
            src={retoolEmbedUrl}
            frameBorder="0"
            width="100%"
            height="100%"
            allowFullScreen/>
        </div>
    );
  };

export default RetoolEmbedWrapper;