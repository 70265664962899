import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck } from "@fortawesome/free-solid-svg-icons";
import { ReactComponent as DownloadIcon } from "../../Images/general_icons/download_icon.svg"
import { ReactComponent as CopyIcon } from "../../Images/general_icons/copy_icon.svg"
import { ReactComponent as JsonIcon } from "../../Images/general_icons/json_icon.svg"
import { downloadFile, handleCopy } from "../../utils/helpers";

import "./actionBtn.scss";

const ActionBtn = ({
  text,
  icon,
  disabled,
  action,
  stringToAction,
  refresh,
  onClick,
  fileType,
  fileName,
  purple,
  onClickDifferentActions = () => null,
}) => {
  const [actionSuccess, setActionSuccess] = useState(false);

  // make sure initial state on component load
  useEffect(() => {
    setActionSuccess(false);
  }, []);

  // boolean value to refresh to inital state
  useEffect(() => {
    if (refresh) {
      setActionSuccess(false);
    }
  }, [refresh]);

  // set button back to inital state to user be able to make action again
  useEffect(() => {
    if (actionSuccess) {
      setTimeout(() => {
        setActionSuccess(false);
      }, 2000);
    }
  }, [actionSuccess]);

  const handleDifferentActions = () => {
    if (disabled) {
      return false;
    }
    switch (action) {
      case "copy":
        handleCopy(stringToAction);
        setActionSuccess(true);
        onClickDifferentActions();
        break;
      case "download":
        downloadFile(stringToAction, fileType, fileName);
        setActionSuccess(true);
        onClickDifferentActions();
        break;
      default:
        break;
    }
  };

  const renderSelectedIcon = () => {
    switch (icon) {
      case "copy":
        return <CopyIcon/>;
      case "donwload":
        return <DownloadIcon/>;
      case "json":
        return <JsonIcon />
      default:
        break;
    }
  };

  const renderIconBasedSuccess = () => {
    if (!actionSuccess && icon) {
      return <span className={`ActionBtn__icon ${icon}`}>{renderSelectedIcon()}</span>;
    }
    if (actionSuccess) {
      return <FontAwesomeIcon icon={faCheck} />;
    }
  };

  return (
    <button
      className={`ActionBtn row ${disabled && "disabled"} ${
        actionSuccess && "action-success"
      } ${purple ? 'purple' : ''}`}
      onClick={onClick ? onClick : handleDifferentActions}
    >
      {renderIconBasedSuccess()}
      {text && <span className="ActionBtn__text">{text}</span>}
    </button>
  );
};

export default ActionBtn;
