import React, { useState } from "react";
import { Form, Input, Select } from "antd";
import _ from "lodash";

import { nicknameRegex, validate } from "../../../utils/validations";
import PagerDutyProcedures from "./pagerDutyProcedures";

const PagerDutyForm = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};
  const [type, setType] = useState("integration");

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, apiKey, serviceType } = formValues;

    if (_.isEmpty(name) || _.isEmpty(apiKey) || _.isEmpty(serviceType)) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="PagerDutyForm col">
      <Form
        {...layout}
        name="pagerduty-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "PagerDuty Integration",
          serviceType: "integration",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="" />
        </Form.Item>

        <Form.Item
          label="Integration Type"
          name="serviceType"
          rules={[
            {
              required: true,
              message: "selecting a type is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select onChange={(val) => setType(val)}>
          <Select.Option value="integration" key="integration">
              Integration Key
            </Select.Option>
            <Select.Option value="admin" key="admin">
              General Access REST API Key
            </Select.Option>
          </Select>
        </Form.Item>

        <PagerDutyProcedures type={type} style={{ marginBottom: '1rem' }}/>

        <Form.Item
          label="Key"
          name="apiKey"
          rules={[
            {
              required: true,
              message: "Key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password />
        </Form.Item>
      </Form>
    </div>
  );
};

export default PagerDutyForm;
