import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import _ from "lodash";
import { Form, Select, Spin, Checkbox, Tooltip } from "antd";
import { useDispatch, useSelector } from "react-redux";

import {
  getAwsIntegrations,
  getAllCftStacks,
  getAllCftResources,
  getAllAccountValidStacks,
} from "../../../redux/actions/integrationsActions";
import {
  getStacksByIntegration,
  getStacksByAccount,
} from "../../../redux/actions/cftStacksActions";
import { appToast } from "../../../shared/appToast/appToast";
import FormLabels from "../../../shared/formLabels/formLabels";
import regions from "../../../consts/regions";

const CloudFormation = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  handleGetCftStacks,
  handleGetCftGlobalStacks,
  handleIsGlobal,
  handleIntegrationExternalId,
  setTags,
  allTags,
  currentSelectedTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();

  const awsIntegrations = useSelector((state) => state.integrationsReducer.aws);

  const [cftStacks, setCftStacks] = useState([]);
  const [dbIntegrationCftStacks, setDbIntegrationCftStacks] = useState([]);
  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedRegion, setSelectedRegion] = useState("");
  const [selectedCftStacks, setSelectedCftStacks] = useState([]);

  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingCftStacks, setLoadingCftStacks] = useState(false);
  const [loadingCftResources, setLoadingCftResources] = useState(false);
  const [loadingAllRegions, setLoadingAllRegions] = useState(false);

  const [cftStackHasPermission, setCftStackHasPermission] = useState(true);
  const [checkResources, setCheckResources] = useState(true);
  const [disableStacks, setDisableStacks] = useState(false);

  // fetch AWS Integrations when starting
  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    const match = awsIntegrations?.integrations?.find(
      (integ) => integ.id === selectedIntegration
    );
    handleIntegrationExternalId(match ? match.accountNumber : "");
  }, [selectedIntegration]);

  // fetch all CloudFormation stacks on Integration change and region change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration) && !_.isEmpty(selectedRegion)) {
      form.setFieldsValue({ cftStacks: [] });
      setSelectedCftStacks([]);
      fetchCftStacks();
    }
  }, [selectedIntegration, selectedRegion]);

  useEffect(() => {
    if (_.isEmpty(selectedCftStacks)) {
      handleSetIsFormValid(false);
    } else {
      if (checkResources) {
        fetchCftResources();
      }
    }
  }, [selectedCftStacks]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getAwsIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchCftStacks = async () => {
    setLoadingCftStacks(true);
    const dbStacks = await dispatch(
      getStacksByIntegration(selectedIntegration, selectedRegion)
    );
    const res = await dispatch(
      getAllCftStacks(selectedIntegration, selectedRegion)
    );
    if (!res?.req?.ok || !dbStacks?.req?.ok) {
      form.setFieldsValue({ cftStacks: [] });
      setCftStacks([]);
      setSelectedCftStacks([]);
      return setLoadingCftStacks(false);
    }
    const notDeletedDbStacks = res?.stacks?.filter(
      (stack) => stack?.StackStatus !== "DELETE_COMPLETE"
    );
    setDbIntegrationCftStacks(dbStacks?.data);
    const noDuplicatesCftStacks = _.uniqWith(
      notDeletedDbStacks,
      (arrVal, othVal) => arrVal.StackName === othVal.StackName
    );
    setCftStacks(noDuplicatesCftStacks);
    handleGetCftStacks(noDuplicatesCftStacks);
    setLoadingCftStacks(false);
  };

  const fetchCftResources = async () => {
    setCftStackHasPermission(true);
    setLoadingCftResources(true);
    handleSetIsFormValid(false);
    let hasError = false;
    for (let stackName of selectedCftStacks) {
      const res = await dispatch(
        getAllCftResources(selectedIntegration, selectedRegion, stackName)
      );
      if (!res?.ok) {
        hasError = true;
        break;
      }
    }

    //Update permission issue
    setCftStackHasPermission(!hasError);

    if (hasError) {
      appToast(
        "info",
        "",
        `Firefly was unable to list the resources from this CloudFormation Stack. Make sure we have the proper permissions so we can access state files.`
      );
    } else {
      handleSetIsFormValid(true);
    }
    setLoadingCftResources(false);
  };

  const renderAwsIntegrationsOptions = () => {
    if (!_.isEmpty(awsIntegrations.integrations)) {
      return _.map(awsIntegrations.integrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderAwsRegionsOptions = () => {
    if (!_.isEmpty(regions)) {
      return _.map(Object.keys(regions) || [], (regionKey) => {
        return (
          <Select.Option value={regionKey} key={regionKey}>
            {regions[regionKey]?.name}
          </Select.Option>
        );
      });
    }
  };

  const renderCftStacksOptions = () => {
    if (!_.isEmpty(cftStacks)) {
      let returnArr = [];
      _.map(cftStacks || [], (stack) => {
        let match = null;
        if (!_.isEmpty(dbIntegrationCftStacks)) {
          match = dbIntegrationCftStacks.find(
            (dbStack) => dbStack?.name === stack?.StackName
          );
        }
        if (!_.isEmpty(match)) {
          returnArr.push(
            <Select.Option
              key={stack.StackName}
              value={stack.StackName}
              disabled
            >
              <Tooltip placement="top" title="This stack was already imported">
                {stack.StackName}
              </Tooltip>
            </Select.Option>
          );
        } else {
          returnArr.unshift(
            <Select.Option
              value={stack.StackName}
              key={stack.StackId}
            >
              {stack.StackName}
            </Select.Option>
          );
        }
      });
      return returnArr;
    }
  };

  const getStacksResources = () => {
    if (
      !_.isEmpty(selectedIntegration) &&
      !_.isEmpty(selectedRegion) &&
      !_.isEmpty(selectedCftStacks) &&
      checkResources
    ) {
      fetchCftResources();
    } else {
      handleSetIsFormValid(false);
    }
  };

  const generateCftPolicyTemplateUrl = () => {
    // TODO: generate cft policy
    // const selectedIntegration = _.filter(
    //   awsIntegrations?.integrations || [],
    //   (integ) => integ.id == integrationId
    // );
    // if (_.isEmpty(selectedIntegration) || selectedIntegration.length > 1) {
    //   appToast(
    //     "error",
    //     "",
    //     `Something went wrong with the selected AWS integration`
    //   );
    //   return;
    // }
    // const roleArn = _.first(selectedIntegration).roleArn;
    // return `https://us-east-1.console.aws.amazon.com/cloudformation/home?#/stacks/create/review?templateURL=https://infralight-templates-public.s3.amazonaws.com/s3bucket-readonly/template.yml&param_BucketName=${bucketName}&stackName=infralight-${bucketName}-${integrationId}&param_RoleArn=${roleArn}`;
  };

  const handleGetAllAccountStacks = async () => {
    setLoadingAllRegions(true);
    form.setFieldsValue({ region: null });
    form.setFieldsValue({ cftStacks: [] });
    setSelectedRegion(null);
    setSelectedCftStacks([]);
    const res = await dispatch(getAllAccountValidStacks(selectedIntegration));
    const dbStacks = await dispatch(getStacksByAccount(selectedIntegration));
    if (!res?.req?.ok || !dbStacks?.req?.ok) {
      return setLoadingAllRegions(false);
    }
    if (res?.stacks?.length === 750) {
      appToast(
        "info",
        "",
        `We discovered more than 750 stacks, and imported the first 750. Please contact support@firefly.ai or via Intercom.`
      );
    }
    const noDuplicatesCftStacks = _.uniqWith(
      res?.stacks,
      (arrVal, othVal) => arrVal.StackName === othVal.StackName
    );
    const noDisabledStacks = noDuplicatesCftStacks.filter(
      (stack) =>
        !dbStacks?.data?.find((dbStack) => dbStack.name === stack.StackName)
    );
    setSelectedCftStacks(noDisabledStacks);
    form.setFieldsValue({ cftStacks: [] });
    handleGetCftGlobalStacks(noDisabledStacks);
    setDisableStacks(true);
    handleIsGlobal(true);
    handleSetIsFormValid(true);
    setLoadingAllRegions(false);
  };

  return (
    <Form
      {...layout}
      name="cft-settings-form"
      form={form}
      onValuesChange={(changedValues, allValues) => {
        if (!_.isUndefined(changedValues.awsIntegration)) {
          form.setFieldsValue({ cftStacks: [] });
          setSelectedCftStacks([]);
        }
        if (!_.isUndefined(changedValues.region)) {
          form.setFieldsValue({ cftStacks: [] });
          setSelectedCftStacks([]);
        }
        if (!_.isUndefined(changedValues.selectAll)) {
          const noDisabledStacks = _.filter(cftStacks, (item) =>
            _.isEmpty(
              dbIntegrationCftStacks.find(
                (dbStack) => dbStack?.name === item.StackName
              )
            )
          );
          let cftStacksList = changedValues.selectAll
            ? _.map(noDisabledStacks, (item) => item?.StackName)
            : [];
          allValues.cftStacks = cftStacksList;
          form.setFieldsValue({ cftStacks: cftStacksList });
          if (changedValues.selectAll) {
            setSelectedCftStacks(cftStacksList);
          } else {
            setSelectedCftStacks([]);
            form.setFieldsValue({ cftStacks: [] });
          }
        }
        if (!_.isUndefined(changedValues.selectAllRegions)) {
          if (changedValues.selectAllRegions) {
            setCheckResources(false);
            handleGetAllAccountStacks();
          } else {
            setSelectedCftStacks([]);
            setDisableStacks(false);
            setCheckResources(true);
          }
        }
        changeSelectedStateSettings(allValues);
      }}
    >
      <Form.Item
        label="AWS Integration"
        name="awsIntegration"
        help={
          !loadingIntegrations && _.isEmpty(awsIntegrations.integrations) ? (
            <Link to="/integrations/aws-integration">
              + Add AWS Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Aws Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select Aws Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderAwsIntegrationsOptions()}
        </Select>
      </Form.Item>
      {loadingAllRegions && <Spin />}
      <Form.Item name="selectAllRegions" valuePropName="checked">
        <Checkbox
          checked={false}
          disabled={_.isEmpty(selectedIntegration) || loadingAllRegions}
        >
          <span className="selectAll__checbox">
            Select Stacks from all Regions
          </span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Region"
        name="region"
        rules={[
          {
            required: true,
            message: "Selecting a region is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select a region"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(reg) => setSelectedRegion(reg)}
          disabled={loadingAllRegions || disableStacks}
          // loading={loadingCftStacks}
        >
          {renderAwsRegionsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="CloudFormation Stack"
        name="cftStacks"
        rules={[
          {
            required: true,
            message: "Selecting a CloudFormation Stack is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Select CloudFormation Stack"
          showSearch
          filterOption={(input, option) => {
            if (option && typeof option === "string") {
              return (
                option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              );
            }
          }}
          // onSelect={(stack) => setSelectedCftStack(stack)}
          loading={loadingCftStacks}
          mode="multiple"
          onSelect={(stack) => {
            let tmpSelected = selectedCftStacks;
            tmpSelected.push(stack);
            getStacksResources(tmpSelected);
            return setSelectedCftStacks(tmpSelected);
          }}
          onDeselect={(stack) => {
            let tmpSelected = selectedCftStacks;
            _.remove(tmpSelected, (item) => item === stack);
            getStacksResources(tmpSelected);
            return setSelectedCftStacks(tmpSelected);
          }}
          disabled={loadingAllRegions || disableStacks}
        >
          {renderCftStacksOptions()}
        </Select>
      </Form.Item>
      {loadingCftResources && <Spin />}

      {!cftStackHasPermission &&
        !loadingCftResources &&
        !_.isEmpty(selectedCftStacks) &&
        !_.isEmpty(selectedRegion) &&
        !_.isEmpty(selectedIntegration) && (
          <div
            className="row between"
            style={{ transform: "translateY(-15px)" }}
          >
            <a
              target="_blank"
              href={generateCftPolicyTemplateUrl(
                selectedCftStacks,
                selectedRegion,
                selectedIntegration
              )}
              disabled
              rel="noreferrer"
            >
              + Grant permission
            </a>
            <span
              className="purple-text"
              style={{ cursor: "pointer" }}
              onClick={() => getStacksResources()}
            >
              Reload
            </span>
          </div>
        )}
      <Form.Item name="selectAll" valuePropName="checked">
        <Checkbox
          checked={false}
          disabled={_.isEmpty(cftStacks) || loadingAllRegions}
        >
          <span className="selectAll__checbox">Select All</span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};

export default CloudFormation;
