import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { Tooltip, Tree } from "antd";
import { formatCheckedKeysByFilters } from "../../../utils/helpers";
import {
  getAllProvidersArray,
  renderProviderTypeText,
} from "../../../utils/formatting";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./inventoryTreeFilter.scss";
import _, { flatten, isUndefined } from "lodash";
import { ReactComponent as EventDriven } from "../../../Images/general_icons/eventdriven-plain.svg";
import WarningTriangle from "../../../shared/warningTriangle/warningTriangle";
import moment from "moment";
import SearchBox from "../../../shared/searchBox/searchBox";
import { CLOUD_PROVIDERS_SUPPORT } from "../../../consts/general";
import {ReactComponent as FolderIcon} from '../../../Images/general_icons/Folder_icon.svg'

const InventoryTreeFilter = ({ providerTypeSelected }) => {
  const aggregationsProviderType = useSelector(
    (state) => state.inventoryReducer.aggregationsProviderType
  );

  const screenFilters = useSelector(
    (state) => state.inventoryReducer.inventoryFilters
  );

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const aggregationsProviderId = useSelector(
    (state) => state.inventoryReducer.aggregationsProviderId
  );

  const [treeData, setTreeData] = useState([]);
  const [expandedKeys, setExpandedKeys] = useState(["cloud","saas"]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [selectedKeys, setSelectedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState();
  const [providerGroups, setProviderGroups] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  //These types should be auto expanded on tree render
  const autoExpandproviderTypes = CLOUD_PROVIDERS_SUPPORT;

  useEffect(() => {
    const allProviders = getAllProvidersArray(providerIntegrations);
    const providerGroups = _.groupBy(allProviders, "provider");
    setProviderGroups(providerGroups);
  }, []);

  useEffect(() => {
    renderTreeData();
  }, [aggregationsProviderType]);

  useEffect(() => {
    renderTreeData()
    setCheckedKeys(_.difference(checkedKeys,["cloud","saas"]))
  }, [searchVal])
  
  useEffect(() => {
    setCheckedKeys(
      _.concat(
        screenFilters?.providerTypes?.providerType,
        screenFilters?.providerTypes?.providerSubType,
        screenFilters?.providerTypes?.provider,
        screenFilters?.providerTypes?.integrationId
      )
    );
  }, [
    screenFilters?.providerTypes?.providerType,
    screenFilters?.providerTypes?.providerSubType,
    screenFilters?.providerTypes?.provider,
    screenFilters?.providerTypes?.integrationId,
  ]);

  const isIntegOutOfSync = (lastSuccessfulSyncTime, dailyFetch= false) => {
    if (_.isEmpty(lastSuccessfulSyncTime)) {
      return true;
    } else {
      const diff = moment().diff(moment(lastSuccessfulSyncTime));
      const durationHours = moment.duration(diff).asHours();
      if (dailyFetch){
        return durationHours > 30 ? true : false;
      }
      return durationHours > 24 ? true : false;
    }
  };

  const mapTreeFilterItem = item => {
    return {
      title: <span className="provider-type-item row">
        <ProviderIcon provider={item?.icon} customStyle="tree" />
        {item?.name}
        {item?.isProd && (
          <FontAwesomeIcon
            icon="flag"
            style={{ opacity: ".7", marginLeft: "1px" }}
          />
        )}
        {item?.isEventDriven && (
          <EventDriven className="provider-type-item-eventDrivenLabel" />
        )}
        {item?.isIntegOutOfSync && (
          <Tooltip placement="top" title="Integration is out of sync">
            {/* wrapping in span is important - workaround for tooltip bug */}
            <span>
              <WarningTriangle type="orange" minimal noMargin />
            </span>
          </Tooltip>
        )}
      </span>,
      key: item?.key,
      disableCheckbox: false
    }
  }

  const renderCloudProvidersByType = (providerType) => {
    if (!_.isEmpty(providerIntegrations[providerType])) {
      const formattedData = _.map(
        providerIntegrations[providerType],
        (integ) => {
          if (
            aggregationsProviderId?.buckets?.find(
              (agg) => agg?.key === integ?.id
            )
          ) {
            return {
              icon: providerType,
              name: integ?.name,
              isProd: integ?.isProd,
              key: integ?.id,
              isEventDriven: integ?.isEventDriven,
              isIntegOutOfSync: isIntegOutOfSync(integ?.lastSuccessfulSyncTime, integ?.dailyFetch),
              folder:integ?.folder
            };
          }
          return;
        }
      );

      // filter out unknown integrations
      let ActiveIntegrations = _.filter(
        formattedData,
        (item) => !_.isEmpty(item?.name)
      );

      // sort account by ABC
      let sorted = _.orderBy(
        ActiveIntegrations,
        [(integ) => integ?.name.toLowerCase()],
        "asc"
      );
      
      if(!isEmpty(searchVal)){
        sorted = _.filter(sorted,(obj) => obj.name.toLowerCase().includes(searchVal.toLowerCase()))
      }

      let childrenArr = [];
      if(providerType == 'gcp'){
        const groupedByFolder = _.groupBy(sorted,'folder');
        for (const folder in groupedByFolder) {
          if(folder === "undefined"){
            groupedByFolder[folder].forEach(integration => childrenArr.push(mapTreeFilterItem(integration)))
          }else{
            childrenArr.push({
              title: <span className="provider-type-item row">
                <FolderIcon />
                  {folder}
              </span>,
              key:folder,
              children: groupedByFolder[folder].map(i => mapTreeFilterItem(i))
            })
          }
        }
      }else{
        childrenArr = map(sorted || [], (item) => {
          return mapTreeFilterItem(item);
        }) 
      }
      // prepare a tree output
       
      return childrenArr;
    }
    return [];
  };

  const renderCloudChildren = (awsMatch, k8sMatch, gcpMatch, azureMatch) => {
    let childrenArr = [];
    let keysToExpand = [];
    if (!_.isEmpty(providerIntegrations["aws"])) {
      childrenArr.push({
        title: (
          <span className="provider-type-item row">
            <ProviderIcon provider="aws" customStyle="tree" />
            {renderProviderTypeText("aws")}
          </span>
        ),
        key: "aws",
        disableCheckbox: isEmpty(awsMatch),
        children: renderCloudProvidersByType("aws"),
      });
      if (autoExpandproviderTypes?.includes("aws")) {
        keysToExpand?.push("aws");
      }
    }
    if (!_.isEmpty(providerIntegrations["k8s"])) {
      childrenArr.push({
        title: (
          <span className="provider-type-item row">
            <ProviderIcon provider="k8s" customStyle="tree" />
            {renderProviderTypeText("k8s")}
          </span>
        ),
        key: "k8s",
        disableCheckbox: isEmpty(k8sMatch),
        children: renderCloudProvidersByType("k8s"),
      });
      if (autoExpandproviderTypes?.includes("k8s")) {
        keysToExpand?.push("k8s");
      }
    }
    if (!_.isEmpty(providerIntegrations["gcp"])) {
      childrenArr.push({
        title: (
          <span className="provider-type-item row">
            <ProviderIcon provider="gcp" customStyle="tree" />
            {renderProviderTypeText("gcp")}
          </span>
        ),
        key: "gcp",
        disableCheckbox: isEmpty(gcpMatch),
        children: renderCloudProvidersByType("gcp"),
      });
      if (autoExpandproviderTypes?.includes("gcp")) {
        keysToExpand?.push("gcp");
      }
    }
    if (!_.isEmpty(providerIntegrations["azurerm"])) {
      childrenArr.push({
        title: (
          <span className="provider-type-item row">
            <ProviderIcon provider="azurerm" customStyle="tree" />
            {renderProviderTypeText("azurerm")}
          </span>
        ),
        key: "azurerm",
        disableCheckbox: isEmpty(azureMatch),
        children: renderCloudProvidersByType("azurerm"),
      });
      if (autoExpandproviderTypes?.includes("azurerm")) {
        keysToExpand?.push("azurerm");
      }
    }
    setExpandedKeys([...expandedKeys, ...keysToExpand]);

    childrenArr = _.filter(childrenArr, arr => !isEmpty(arr.children))
    return childrenArr;
  };

  const renderSaasChildren = () => {
    let saasChildOptions = ["datadog", "github", "akamai", "okta", "newrelic", "cloudflare", "ns1", "mongodbatlas", "pagerduty"];

    let child = map(saasChildOptions, (item) => {
      if (
        !isEmpty(providerIntegrations?.[item]) &&
        !isUndefined(providerIntegrations?.[item]) &&
        _.has(providerIntegrations, item)
      ) {
        return renderCloudProvidersByType(item);
      } else {
        return [];
      }
    });
    return flatten(child);
  };

  const renderTreeData = async () => {
    const cloudMatch = aggregationsProviderType?.find(
      (agg) => agg?.key === "cloud"
    );
    const iaasMatch = cloudMatch?.providerSubType?.buckets?.find(
      (agg) => agg?.key === "iaas"
    );
    const awsMatch = iaasMatch?.provider?.buckets?.find(
      (agg) => agg?.key === "aws"
    );
    const paasMatch = cloudMatch?.providerSubType?.buckets?.find(
      (agg) => agg?.key === "paas"
    );
    const k8sMatch = paasMatch?.provider?.buckets?.find(
      (agg) => agg?.key === "k8s"
    ) || aggregationsProviderType?.find(
      (agg) => agg?.key === "k8s"
    );
    const gcpMatch = iaasMatch?.provider?.buckets?.find(
      (agg) => agg?.key === "gcp"
    );
    const azureMatch = iaasMatch?.provider?.buckets?.find(
      (agg) => agg?.key === "azurerm"
    );

    const baseTree = [...renderCloudChildren(awsMatch, k8sMatch, gcpMatch, azureMatch), ...renderSaasChildren()];
    setTreeData(baseTree);
  };

  const onExpand = (expandedKeysValue) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  const onCheck = (checkedKeysValue) => {
    if (searchVal?.length > 0 && checkedKeysValue?.length > 0){
      checkedKeysValue = checkedKeysValue.filter((key) => !['aws', 'gcp', 'azurerm'].includes(key))
    }
    providerTypeSelected(
      formatCheckedKeysByFilters(checkedKeysValue, providerGroups)
    );
    setCheckedKeys(checkedKeysValue);
  };

  const onSelect = (selectedKeysValue, info) => {
    setSelectedKeys(selectedKeysValue);
  };

  return (
    <div className="InventoryTreeFilter">
     <div className="InventoryTreeFilter__title">
       <SearchBox
          placeholder="Search"
          value={(val) => setSearchVal(val)}
          resetSearch={(val) => setSearchVal(val)}
          grayMode
          noBorder
          hideReset
        />
     </div>
      <Tree
        checkable
        selectable={false}
        onExpand={onExpand}
        expandedKeys={expandedKeys}
        autoExpandParent={autoExpandParent}
        onCheck={onCheck}
        checkedKeys={checkedKeys}
        onSelect={onSelect}
        selectedKeys={selectedKeys}
        treeData={treeData}
        height={600}
      />
    </div>
  );
};

export default InventoryTreeFilter;
