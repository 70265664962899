import _ from "lodash";
import React from "react";

const AssetClickableChips = ({  chips, onClickChip }) => {
  const renderChips = () => {
    if (!_.isEmpty(chips) && _.isArray(chips)) {
      return (
        <div className="row" style={{ gap: "10px", flexWrap: "wrap" }}>
          {_.map(chips || [], (chip) => {
            return (
              <span
                className="tag-flag-big-clickable fs-12"
                key={chip}
                onClick={() => onClickChip(chip)}
              >
                {chip}
              </span>
            );
          })}
        </div>
      );
    }
    return null;
  };

  return renderChips();
};

export default AssetClickableChips;
