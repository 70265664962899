import React from "react";
import _ from "lodash";
import Loading from "../../../../shared/loading/loading";

const AssetInfoCard = ({ contentArr, loading, grid }) => {
  const renderContent = () => {
    if (loading) {
      return <Loading />;
    }

    if (!_.isEmpty(contentArr) && _.isArray(contentArr)) {
      return _.map(contentArr || [], (item) => {
        if (item?.display) {
          return (
            <div className="AssetInfoCard__list-item row" key={item?.label}>
              {item?.label && (
                <span className="AssetInfoCard__list-item-label">
                  {item?.label}
                </span>
              )}
              {item?.content}
            </div>
          );
        }
      });
    }
  };

  return (
    <div className="AssetInfoCard col">
      <div className={`AssetInfoCard__list ${grid? 'grid' : 'col'}`}>{renderContent()}</div>
    </div>
  );
};

export default AssetInfoCard;
