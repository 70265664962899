import React from "react";
import FreeTierMask from "../freeTierMask/freeTierMask";

const AssetNameCell = ({ value, freeTier }) => {
  return (
    <div className="AssetNameCell">
      {freeTier ? <FreeTierMask>{value}</FreeTierMask> : value}
    </div>
  );
};

export default AssetNameCell;
