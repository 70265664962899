import React, { useState, useEffect } from "react";
import { useHistory, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";
import { createGithubApp } from "../../../redux/actions/vcsActions";
import AppWizard from "../../../shared/appWizard/appWizard";
import GithubSetup from "./githubSetup";
import SuccessBox from "../../../shared/successBox/successBox";
import { clouds } from "../../../utils/icons";
import "./githubIntegration.scss";

const GithubIntegration = () => {
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({
    nickname: "GitHub Integration",
    code: "",
    installation_id: "",
  });
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  // get github app code after autorization redirect to app and continue integration automatically
  useEffect(() => {
    if (search) {
      handleASetGithubParameters();
    }
  }, [search]);

  const handleASetGithubParameters = () => {
    const searchParam = new URLSearchParams(search);
    const githubAppCode = searchParam.get("code");
    const githubInstallId = searchParam.get("installation_id");

    setWizardState({
      ...wizardState,
      code: githubAppCode,
      installation_id: githubInstallId,
    });
    setSubmitDisabled(_.isEmpty(githubAppCode));
  };

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Set up GitHub VCS",
      content_title: "GitHub version control system integration",
      content: (
        <GithubSetup
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
          setWizardState={setWizardState}
        />
      ),
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const { nickname, code, installation_id } = wizardState;

        const gitHubAppReq = await dispatch(
          createGithubApp(code, installation_id, nickname)
        );

        if (gitHubAppReq?.ok) {
          return setLoadingSubmit(false);
        } else {
          setLoadingSubmit(false);
          throw new Error("GitHub App creation Error");
        }
      },
      valid: true,
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle="The Resources from the Integration will take up to one hour to appear in the inventory"
          doesTakeTime
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="GithubIntegration">
      <AppWizard
        iconSrc={clouds("github", themeDark)}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
      />
    </div>
  );
};

export default GithubIntegration;
