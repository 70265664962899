import React, { useState ,useEffect, useCallback } from "react";
import isEmpty from "lodash/isEmpty";
import { useDispatch, useSelector } from "react-redux";
import { getCiWorkspaces } from "../../redux/actions/ciWorkflowsActions";
import CiStepsCollapse from "../../components/ciSteps/ciStepsCollapse";
import Loading from "../../shared/loading/loading";
import HeaderSearchBox from "../../shared/headerSearchBox/headerSearchBox";
import { useTranslation } from "react-i18next";
import { ReactComponent as CiPipelineIcon } from '../../Images/general_icons/sidebar/ci-pipeline.svg';
import CiFilters from "./ciFilters";
import AppBtn from "../../shared/appBtn/appBtn";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import WorkflowManagement from "../../components/ciSteps/workflowManagement/workflowManagement";

import './ciCustomTheme.scss';
import './ciSteps.scss';

const CI_WORKFLOWS_INTERVAL_TIME = 60000;

const CiSteps = () => {
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(false);
    const [searchInput, setSearchInput] = useState("");
    const [showCreateWorkflowModal, setShowCreateWorkflowModal] = useState(false);

    const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);
    const ciFilters = useSelector((state) => state.ciWorkflowsReducer.ciFilters);
    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const dispatch = useDispatch();
    const { t } = useTranslation("ci-pipeline");

    const fetchCiWorkflow = useCallback(async() => {
        const response = await dispatch(getCiWorkspaces(ciFilters, searchInput))
        if (isEmpty(response) || response?.abort) return;
        return setData(response);
    })
    
    useEffect(() => {
         if (themeDark) {
            document.documentElement.classList.remove("ciLight");
            document.documentElement.classList.add("ciDark");
         } else {
            document.documentElement.classList.remove("ciDark");
            document.documentElement.classList.add("ciLight");
        }
    }, [themeDark]);

    useEffect(async () => {
        setLoading(true);
        await fetchCiWorkflow();
        setLoading(false);
    }, [ciFilters, searchInput]);

    useEffect(() => {
        let interval = setInterval(fetchCiWorkflow, CI_WORKFLOWS_INTERVAL_TIME);
        return () => clearInterval(interval);
    }, [fetchCiWorkflow]);

    return (
        <div className="CiSteps col g20">
            <div className="CiSteps__container">
                <div className="col g8">
                    <div className="CiSteps__header-container row g8">
                        <CiPipelineIcon className="CiSteps__header-icon"/>
                        <span className="CiSteps__header-title">{t("title")}</span>
                    </div>
                    <CiFilters searchInput={searchInput}/>
                </div>
                <div className="col g8">
                    <div className="basic-card row between">
                        <HeaderSearchBox
                            placeholder="Search..."
                            value={(val) => setSearchInput(val)}
                            resetSearch={(val) => setSearchInput(val)}
                            currentValue={searchInput}
                        />
                        <AppBtn
                            text={t("add-workflow-btn")}
                            onClick={() => setShowCreateWorkflowModal(true)}
                            icon={<FontAwesomeIcon icon="plus" />}
                            disabled={isViewer}
                        />
                    </div>
                    <div className="CiSteps__workflows basic-card col">
                        <div className="CiStepsCollapse__header titles">
                            <span className="">{t("columns.workspace")}</span>
                            <span className="">{t("columns.lastApply")}</span>
                            <span className="">{t("columns.repository")}</span>
                            <span className="">{t("columns.tfVersion")}</span>
                            <span className="">{t("columns.lastStatus")}</span>
                        </div>
                    {loading ? <Loading /> 
                        : <div className="col g20">
                            {data.map((collapseData) => {
                                return (
                                    <CiStepsCollapse data={collapseData} key={collapseData?.workspaceId}/>
                                )
                            })}
                        </div>}
                    </div>
                </div>
            </div>
            {showCreateWorkflowModal && <WorkflowManagement modalVisible={showCreateWorkflowModal} setModalVisible={setShowCreateWorkflowModal} />}
        </div>
    )
}

export default CiSteps;