import {
    SEND_FEEDBACK
} from '../actions/feedbackActions';

const initialState = {
};

export default (state = initialState, { type, payload }) => {

    switch (type) {

        case SEND_FEEDBACK:
            return { ...state };
            
        default:
            return state;
    }
}
