import React from "react";
import { Handle, Position } from "react-flow-renderer";
import { iacProviders } from "../../../../../utils/icons";
import { formatAwsStringWithUnderscore } from "../../../../../utils/formatting";
import lowerCase from 'lodash/lowerCase'
import "./diagramTraceBlock.scss";

const DiagramTraceBlock = ({ asset = {}, onClick }) => {
  const { data = {} } = asset;

  return (
    <div className="DiagramTraceBlock row g10" onClick={onClick}>
      {data?.sourceHandleVisible && (
        <Handle type="source" position={Position.Right} />
      )}
      <img src={iacProviders("terraform")} alt="terraform" />
      <div className="col">
        <span className="DiagramTraceBlock__title bold">
          {data?.traceType}
        </span>
        <span className="sub-title">
          {lowerCase(formatAwsStringWithUnderscore(data?.assetType))}
        </span>
      </div>
      {data?.targetHandleVisible && (
        <Handle type="target" position={Position.Left} />
      )}
    </div>
  );
};

export default DiagramTraceBlock;
