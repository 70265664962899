import React from "react";
import isEmpty from "lodash/isEmpty";
import { Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSpinner } from "@fortawesome/free-solid-svg-icons";
import { renderEllipsis } from "../../utils/formatting";

import "./appTag.scss";

const AppTag = ({
  text,
  onClick,
  icon,
  loading,
  disabled,
  style,
  primaryMode,
  minimal,
  shorten,
}) => {

  return (
    <Tooltip title={shorten ? text : null}>
      <span
        className={`AppTag row 
        ${disabled ? "disabled" : ""}
        ${primaryMode ? "primaryMode" : ""}
        ${minimal ? "minimal" : ""}
        `}
        style={style}
        onClick={disabled ? () => { return false } : onClick}
      >
        {loading ? (
          <FontAwesomeIcon icon={faSpinner} spin className="AppTag__icon" />
        ) : icon ? (
          <span className="AppTag__icon">
            {icon}
          </span>
        ) : null}
        {!isEmpty(text) && <span className="AppTag__text"> {shorten ? renderEllipsis(text, 16) : text}</span>}
      </span>
    </Tooltip>
  );
};

export default AppTag;
