import React from "react";
import isEmpty from "lodash/isEmpty";
import { tableColumns } from "./config/iacStacksConfig";
import { useSelector } from "react-redux";

// components
import TableWrapper from "../../../shared/tableWrapper/tableWrapper";
import FiltersRowBadges from "../filtersRowBadges/filtersRowBadges";

import "./iacStacksTable.scss";

const IacStacksTable = ({
  handleSelectedRows,
  handlePageChange,
  handleTableSorterChange,
  tableData,
  fetchingData,
  totalObjects,
  selectedColumns,
  tablePageSize,
  onPageSizeChange,
  customHeight,
  movePagination,
  filersRowComponent,
  handleRemoveBadgeFilter,
  setStackViewAssets,
  setStackDrawerOpen,
  setStackRowData,
  screenFilters,
  selectedIacType,
  integrations,
  iacTableActions
}) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  return (
    <div className="IacStacksTable col">
      <div className="IacStacksTable__header row between">
        {filersRowComponent && (
          <FiltersRowBadges
            screenFilters={screenFilters}
            handleRemoveFilter={handleRemoveBadgeFilter}
          />
        )}
        {iacTableActions && iacTableActions}
      </div>

      <TableWrapper
        // height={!isEmpty(customHeight) ? customHeight : "calc(100vh - 285px)"}
        rowKey="stackId"
        tableData={tableData}
        columns={
          isEmpty(selectedColumns)
            ? tableColumns(
                setStackViewAssets,
                setStackDrawerOpen,
                setStackRowData,
                themeDark,
                selectedIacType,
                null,
                integrations
              )
            : selectedColumns
        }
        loading={fetchingData}
        pageSize={tablePageSize}
        serverSide
        rowCount={totalObjects}
        selectedRowsArr={handleSelectedRows}
        handlePageChange={handlePageChange}
        handleSortChange={handleTableSorterChange}
        movePagination={movePagination}
        disableSelectionOnClick
        onPageSizeChange={onPageSizeChange}
        hasCustomColumnMenu
      />
    </div>
  );
};

export default IacStacksTable;
