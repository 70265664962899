import React, { useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";

import AppWizard from "../../../shared/appWizard/appWizard";
import SuccessBox from "../../../shared/successBox/successBox";
import PulumiCreation from "./pulumiCreation";

import { iacProviders } from "../../../utils/icons";

import { createPulumiIntegration } from "../../../redux/actions/pulumiIntegrationActions";

import "./pulumiIntegration.scss";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const PulumiIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [crawlerCreate, setCrawlerCreate] = useState(true);
  const [integrationCreated, setIntegrationCreated] = useState(null);

  const configWizard = [
    {
      step_title: "Setup",
      step_description: "Set up Pulumi Cloud integration",
      content_title: "Pulumi Cloud integration",
      content: (
        <PulumiCreation
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          crawlerCreate={crawlerCreate}
          setCrawlerCreate={() => setCrawlerCreate(!crawlerCreate)}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        const result = await dispatch(
          createPulumiIntegration(
            wizardState.name,
            wizardState.token,
            crawlerCreate
          )
        );
        setLoadingSubmit(false);
        if (!result?.req?.ok) {
          throw new Error(`Failed to create pulumi integration.`);
        }
        setIntegrationCreated(result?.integration?._id);
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Integration Created!",
      content: (
        <SuccessBox
          setSubmitDisabled={setSubmitDisabled}
          subTitle={crawlerCreate ? "" : "Now it's time to import your stacks"}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="PulumiIntegration">
      <AppWizard
        iconSrc={iacProviders("pulumi")}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        customDoneFunction={
          crawlerCreate
            ? null
            : () =>
                history.push({
                  pathname: "/iac/Add-Iac-Stack",
                  state: { type: "pulumi", integration: integrationCreated },
                })
        }
        customDoneText={
          crawlerCreate ? null : (
            <span>
              Import Stacks
              <FontAwesomeIcon
                icon="long-arrow-alt-right"
                style={{ marginLeft: "10px" }}
              />
            </span>
          )
        }
      />
    </div>
  );
};

export default PulumiIntegration;
