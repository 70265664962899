import React from "react";
import { Form, Input } from "antd";
import _ from "lodash";

import {
  nicknameRegex,
  validate,
} from "../../../utils/validations";

const AkamaiCreation = ({ wizardState, setWizardState, setSubmitDisabled }) => {
  const [form] = Form.useForm();
  const layout = {};

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, host, clientSecret, accessToken, clientToken } = formValues;

    if(_.isEmpty(name) || _.isEmpty(host) || _.isEmpty(clientSecret) || _.isEmpty(accessToken) || _.isEmpty(clientToken)) {
      formValid = false;
    }

    if(!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  }

  return (
    <div className="AkamaiCreation col">
      <div className="AkamaiCreation__intro col">
        <span className="small-dark-blue">
         Insert akamai .edgrc credentials
        </span>
      </div>
      <Form
        {...layout}
        name="akamai-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "Akamai",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>
        <Form.Item
              label="Client Secret"
              name="clientSecret"
              rules={[
                  {
                      required: true,
                      message: "Client Secret is required",
                  },
              ]}
              style={{ marginBottom: "1.5rem", flexDirection: "column" }}
          >
              <Input.Password placeholder="Enter Client Secret" />
        </Form.Item>
        <Form.Item
          label="Host"
          name="host"
          rules={[
            {
              required: true,
              message: "Host is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter Host" />
        </Form.Item>
        <Form.Item
            label="Access Token"
            name="accessToken"
            rules={[
              {
                required: true,
                message: "Access Token is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter Access Token" />
        </Form.Item>
        <Form.Item
            label="Client Token"
            name="clientToken"
            rules={[
              {
                required: true,
                message: "Client Token is required",
              },
            ]}
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter Client Token" />
        </Form.Item>
      </Form>
    </div>
  );
};

export default AkamaiCreation;
