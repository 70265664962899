import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ConfirmationModal from "../../../../shared/confirmationModal/confirmationModal";
import { useTranslation } from "react-i18next";
import { useDispatch, useSelector } from "react-redux";
import EditIntegration from "../../../../components/singleIntegration/editIntegration";
import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { deleteIntegration } from "../../../../redux/actions/integrationsActions";
import { faPencilAlt, faTrash} from "@fortawesome/free-solid-svg-icons";

const DiscoveryActionButtons = ({ integration, onUpdateIntegration, onClickDelete, loading }) => {
    const history = useHistory();
    const [editModalOpen, setEditModalOpen] = useState(false);

    const isViewer = useSelector((state) => state.profilesReducer.isViewer);

    const handleEdit = () => {
        if (integration.type === 'gcp' && integration?.isPrimary) {
            history.push("/integrations/gcp-provider-integration/gcp", { ...integration, id: integration?._id })
            return;
        }
        if (integration.type === 'azurerm') {
            history.push("/integrations/azure-integration", { ...integration })
            return;
        }
        setEditModalOpen(true);
    };


    return (
        <>
            <div className="row integrationDiscovery__action-btns">
                <button className={`AppBtn ${loading && 'disabled'}`} onClick={handleEdit} disabled={isViewer || loading}>
                    <FontAwesomeIcon icon={faPencilAlt} />
                </button>
                <button className={`AppBtn ${loading && 'disabled'}`} onClick={() => onClickDelete(integration)} disabled={isViewer || loading}>
                    <FontAwesomeIcon icon={faTrash} />
                </button>

            </div>


          <EditIntegration
                visible={editModalOpen}
                handleClose={() => setEditModalOpen(false)}
                item={integration}
                type={integration.type}
                handleCloseRefreshEdit={(updatedValues) => {
                    onUpdateIntegration(updatedValues);
                    setEditModalOpen(false)
                }}
            />

        </>);
}

export default DiscoveryActionButtons;