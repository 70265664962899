import React, { useState, useEffect, useLayoutEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useHistory, useLocation } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import values from "lodash/values";
import filter from "lodash/filter";
import forEach from "lodash/forEach";
import keys from "lodash/keys";
import orderBy from "lodash/orderBy";
import map from "lodash/map";
import {
  setSearchVal,
  getAllClouds,
} from "../../redux/actions/globalAppActions";
import { updateTabelPageSize } from "../../redux/actions/userPreferencesActions";
import { setFilters as setInventoryFilters } from "../../redux/actions/inventoryv3Actions";
import {
  getIacStacksHits,
  getIacStacksBaseAggs,
  getModulesBaseAggs,
  setFilters,
  triggerScanNowLambda,
  queryBackendsData,
  queryModules,
  setModulesFilters,
  resetIacStacks,
} from "../../redux/actions/iacStacksActions";
import Loading from "../../shared/loading/loading";
import ProviderIcon from "../../shared/providerIcon/providerIcon";
import IacStacksTable from "../../components/iacStacks/iacStacksTable/iacStacksTable";
import IacStacksFilters from "../../components/iacStacks/iacStacksFilters/iacStacksFilters";
import BackendsTable from "../../components/iacStacks/backendsTable/backendsTable";
import ProvidersTable from "../../components/iacStacks/providersTable/providersTable";
import MapModal from "../../components/iacStacks/mapModal/mapModal";
import {
  capitalizeFirst,
  providerNameFormatter,
  renderOprhanStatus,
  getAllProvidersArray,
  formatAwsStringWithUnderscore,
  formatIacStatusTitle,
} from "../../utils/formatting";
import {
  emptyIacScreenFilters,
  emptyModulesScreenFilters,
} from "../../consts/iacStacks";
import IacStackDrawer from "../../components/iacStacks/iacStackDrawer/iacStackDrawer";
import ExportModal from "../../components/iacStacks/exportModal/exportModal";
import { providers } from "../../utils/providers";
import { flatten, isEqual, lowerCase } from "lodash";
import { emptyInventoryScreenFilters } from "../../consts/inventory";
import IacStacksTypes from "../../components/iacStacks/iacStacksTypes/iacStacksTypes";
import IacStacksCategories from "../../components/iacStacks/iacStacksCategories/iacStacksCategories";
import IacStacksContent from "../../components/iacStacks/iacStacksContent/iacStacksContent";
import ModulesTable from "../../components/iacStacks/modulesTable/modulesTable";
import TerraformStacksTable from "../../components/iacStacks/terraformStacksTable/terraformStacksTable";
import { sendEvent } from "../../utils/amplitude";
import { iacEvents } from "../../utils/amplitudeEvents";
import ModulesFilters from "../../components/iacStacks/modulesFilters/modulesFilters";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "./iacStacks.scss";
import { Popover } from "antd";
import DropMenu from "../../shared/dropMenu/dropMenu";
import IconBtn from "../../shared/iconBtn/iconBtn";
import { ALL_IAC_TYPES, IAC_TYPES, K8S_IAC_TYPES } from "../../consts/general";
import EmptyIac from "../../components/iacStacks/emptyIac/emptyIac";
import {moduleOriginTypes} from "../../utils/icons";
import RepositoryTable from "../../components/iacStacks/repositoryTable/repositoryTable";

const IacStacks = ({ match }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const { search } = location;
  const [loading, setLoading] = useState(false);
  const [initLoading, setInitLoading] = useState(true);
  const [filterPage, setFilterPage] = useState(1);
  const [selectedRows, setSelectedRows] = useState([]);
  const [tableSorting, setTableSorting] = useState([]);
  const [resetFilters, setResetFilters] = useState(false);
  const [resetModulesFilters, setResetModulesFilters] = useState(false);
  const [stackViewAssets, setStackViewAssets] = useState([]);
  const [moduleViewAssets, setModuleViewAssets] = useState([]);
  const [stackDrawerOpen, setStackDrawerOpen] = useState(false);
  const [stackRowData, setStackRowData] = useState(false);
  const [backendInfoModalOpen, setBackendInfoModalOpen] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);
  const [mapModalRowData, setMapModalRowData] = useState(null);
  const [mapModalOpen, setMapModalOpen] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const [activeInfoTab, setActiveInfoTab] = useState("exclusions");
  const [selectedIacType, setSelectedIacType] = useState("");
  const [selectedCategory, setSelectedCategory] = useState("");
  const [tabLoading, setTabLoading] = useState(false);
  const [viewFromUrl, setViewFromUrl] = useState(null);
  const [filtersActivePanel, setFiltersActivePanel] = useState();
  const [collapsed, setCollapsed] = useState(false)


  const responseObjects = useSelector(
    (state) => state.iacStacksReducer.responseObjects
  );

  const totalObjects = useSelector(
    (state) => state.iacStacksReducer.totalObjects
  );

  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);

  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.iacStacksFilters
  );

  const modulesScreenFilters = useSelector(
    (state) => state.iacStacksReducer.modulesFilters
  );

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const aggregationsIacTypes = useSelector(
    (state) => state.iacStacksReducer.aggregationsIacTypes
  );

  const aggregationsIacStatusesAgg = useSelector(
    (state) => state.iacStacksReducer.aggregationsIacStatusesAgg
  );
  const aggregationsTfVersions = useSelector(
    (state) => state.iacStacksReducer.aggregationsTfVersions
  );
  const aggregationsProvidersFullType = useSelector(
    (state) => state.iacStacksReducer.aggregationsProvidersFullType
  );
  const aggregationsSourceModuleFullName = useSelector(
    (state) => state.iacStacksReducer.aggregationsSourceModuleFullName
  );

  const aggregationsProviderTypes = useSelector(
    (state) => state.iacStacksReducer.aggregationsProviderTypes
  );

  const aggregationsNotIntegrated = useSelector(
    (state) => state.iacStacksReducer.aggregationsNotIntegrated
  );
  const aggregationsMissingAccountNumbers = useSelector(
    (state) => state.iacStacksReducer.aggregationsMissingAccountNumbers
  );

  const aggregationsNotSupported = useSelector(
    (state) => state.iacStacksReducer.aggregationsNotSupported
  );

  const aggregationsIntegrated = useSelector(
    (state) => state.iacStacksReducer.aggregationsIntegrated
  );
  const backends = useSelector((state) => state.iacStacksReducer.backends.hits);

  const aggregationsBackends = useSelector(
    (state) => state.iacStacksReducer.aggregationsBackends
  );
  const aggregationsCftStateStatus = useSelector(
    (state) => state.iacStacksReducer.aggregationsCftStateStatus
  );
  const aggregationsCftRegion = useSelector(
    (state) => state.iacStacksReducer.aggregationsCftRegion
  );

  const aggregationsProviderIntegrations = useSelector(
    (state) => state.iacStacksReducer.aggregationsProviderIntegrations
  );

  const aggregationsAssetTypes = useSelector(
    (state) => state.iacStacksReducer.aggregationsAssetTypes
  );

  const aggregationsIacState = useSelector(
    (state) => state.iacStacksReducer.aggregationsIacState
  );

  const aggregationProviderUrls = useSelector(
    (state) => state.iacStacksReducer.aggregationProviderUrls
  );

  const aggregationsProviderAccountId = useSelector(
    (state) => state.iacStacksReducer.aggregationsProviderAccountId
  );

  const aggregationsNamespaces = useSelector(
    (state) => state.iacStacksReducer.aggregationsNamespaces
  );

  const aggregationsRepositories = useSelector(
    (state) => state.iacStacksReducer.aggregationsRepositories
  );

  const aggregationsStackRepositories = useSelector(
    (state) => state.iacStacksReducer.aggregationsStackRepositories
  );

  const aggregationsModuleSourceTypes = useSelector(
    (state) => state.iacStacksReducer.aggregationsModuleSourceTypes
  );

  const aggregationsMisconfigurations = useSelector(
    (state) => state.iacStacksReducer.aggregationsMisconfigurations
  );
  const aggregationsIsBeingUsed = useSelector(
    (state) => state.iacStacksReducer.aggregationsIsBeingUsed
  );
  const aggregationsIsBeingUsedModules = useSelector(
    (state) => state.iacStacksReducer.aggregationsIsBeingUsed
  );
  const aggregationsProviderType = useSelector(
    (state) => state.iacStacksReducer.aggregationsProviderType
  );
  const aggregationsCftRuntime = useSelector(
    (state) => state.iacStacksReducer.aggregationsCftRuntime
  );
  const tablePageSize = useSelector(
    (state) => state.userPreferencesReducer.tablePageSize
  );
  const themeDark = useSelector(
      (state) => state.userPreferencesReducer.themeDark
  );

  const showStackContentsStateTypes = ["terraform", "helm", "cloudformation"];
  const hideStackContentsStatuses = ["unavailable"];
  const staticIacStatuses = ["modified", "ghost"];
  const categories = {
    terraform: ["appliedStacks", "providers", "backends", "modules" ,"repositories"],
    helm: ["installedCharts"],
    k8s: ["installedArtifacts"],
    kustomize: ["installedArtifacts"],
    cloudformation: ["appliedStacks","backends"],
    pulumi: ["appliedStacks","backends"],
  };

  const k8sOrHelmMetadata = selectedIacType === IAC_TYPES.helm ? IAC_TYPES.helm : IAC_TYPES.k8s;
  const namespaceField = k8sOrHelmMetadata === IAC_TYPES.k8s ? 'k8sNamespace' : 'namespace'

  useLayoutEffect(() => {
    dispatch(setSearchVal(""));
    getIsDefaultView();
  }, []);

  useEffect(() => {
    dispatch(setSearchVal(""));
    fetchInitData();
  }, []);

  useEffect(() => {
    setFilterPage(1);
    setSelectedRows([]);
  }, [searchVal]);

  useEffect(() => {
    if (!initLoading) {
      setSelectedRows([]);
      getNewPageData();
      setResetFilters(false);
    }
  }, [screenFilters, filterPage, tableSorting, searchVal, tablePageSize]);

  useEffect(() => {
    if (!values(screenFilters).every(isEmpty)) {
      sendEvent(iacEvents.filterSelected, { screenFilters: screenFilters });
    }
    if (!isEmpty(searchVal)) {
      sendEvent(iacEvents.search, { search: searchVal });
    }
    getNewAggs();
  }, [screenFilters, searchVal]);

  useEffect(() => {
    if (!isEmpty(selectedIacType)) {
      sendEvent(iacEvents.iacTypeSelected, { iacType: selectedIacType });
      dispatch(resetIacStacks());
      setFilterPage(1);
      setIacTypeFilter();
    }
  }, [selectedIacType]);

  useEffect(() => {
    if (!isEmpty(stackViewAssets)) {
      dispatch(
        setInventoryFilters({
          ...emptyInventoryScreenFilters,
          stacks: stackViewAssets,
          masterType: []
        })
      );
      history.push({
        pathname: `/inventory`,
        search: `?stack=${stackViewAssets[0]?.id}`,
      });
    }
  }, [stackViewAssets]);

  useEffect(() => {
    if (!isEmpty(moduleViewAssets)) {
      dispatch(
        setInventoryFilters({
          ...emptyInventoryScreenFilters,
          integrationIds: moduleViewAssets?.integrationIds,
          frns: moduleViewAssets?.frns,
          module: moduleViewAssets?.name
        })
      );
      history.push({
        pathname: `/inventory`,
        search: `?module=${moduleViewAssets?.name}`,
      });
    }
  }, [moduleViewAssets]);

  useEffect(() => {
    if (!initLoading) {
      getDefaultIacType();
    }
  }, [initLoading]);

  useEffect(() => {
    if (!isEmpty(selectedCategory)) {
      sendEvent(iacEvents.categorySelected, { category: selectedCategory });
      if (!isEmpty(searchVal)) {
        clearSearch();
      }
    }
  }, [selectedCategory]);

  const clearSearch = async () => {
    await dispatch(setSearchVal(""));
  };

  const getNewAggs = async () => {
    await dispatch(getIacStacksBaseAggs(screenFilters, searchVal));
    await dispatch(getModulesBaseAggs(modulesScreenFilters, ""));
  };

  const getIsDefaultView = () => {
    if (search) {
      const searchParam = new URLSearchParams(search);
      const viewFromUrl = searchParam.get("view");
      if (viewFromUrl) {
        setViewFromUrl(viewFromUrl);
      }
    }
  };

  const fetchInitData = async () => {
    setInitLoading(true);
    await Promise.all([
      dispatch(getAllClouds()),
      dispatch(queryBackendsData(screenFilters)),
      dispatch(queryModules()),
      dispatch(queryModules(10000)),
      getNewPageData(),
    ]);
    setInitLoading(false);
  };

  const getNewPageData = async () => {
    setLoading(true);
    await Promise.all([
      dispatch(
        getIacStacksHits(
          screenFilters,
          searchVal,
          filterPage,
          tableSorting,
          tablePageSize
        )
      ),
    ]);
    setTabLoading(false);
    setLoading(false);
  };

  const getDefaultIacType = () => {
    if (!isEmpty(viewFromUrl)) {
      return setSelectedIacType(viewFromUrl);
    }
    if(!isEmpty(match?.params?.type)) {
      return setSelectedIacType(match?.params?.type);
    }
    if (!isEmpty(aggregationsIacTypes)) {
      for (let iacType of ALL_IAC_TYPES) {
        const aggMatch = aggregationsIacTypes?.find(
          (agg) => agg?.key === iacType && agg?.doc_count > 0
        );
        if (!isEmpty(aggMatch)) {
          setSelectedIacType(iacType);
          break;
        }
      }
    }
  };

  const setIacTypeFilter = async () => {
    setSelectedCategory(
      K8S_IAC_TYPES.includes(selectedIacType) ? "installedArtifacts" : ( selectedIacType === IAC_TYPES.helm ? "installedCharts" : "appliedStacks")
    );
    const newFilterValue = isEmpty(selectedIacType) ? [] : [selectedIacType];
    const defaultFilters = viewFromUrl ? screenFilters : emptyIacScreenFilters;
    setTabLoading(true);
    setResetFilters(true);
    await dispatch(
      setFilters({ ...defaultFilters, stateType: newFilterValue })
    );
  };

  const handleCloseMapModal = () => {
    setMapModalRowData(null);
    setImageUrl("");
    setMapModalOpen(false);
  };

  const handleRemoveBadgeFilter = (type, filterType) => {
    if (type === "search") {
      setFilterPage(1);
      return dispatch(setSearchVal(""));
    }
    if (type?.startsWith("has")) {
      setFilterPage(1);
      const newFilters = { ...screenFilters };
      delete newFilters[type];
      return dispatch(setFilters(newFilters));
    }
    const newArr = filter(screenFilters[type], (item) => item !== filterType);
    setFilterPage(1);
    return dispatch(setFilters({ ...screenFilters, [type]: newArr }));
  };

  const handleRemoveModulesBadgeFilter = (type, filterType) => {
    if (type === "search") {
      setFilterPage(1);
      return dispatch(setSearchVal(""));
    }
    const newArr = filter(
      modulesScreenFilters[type],
      (item) => item !== filterType
    );
    // setFilterPage(1);
    return dispatch(
      setModulesFilters({ ...modulesScreenFilters, [type]: newArr })
    );
  };

  const formatIacStatusFilter = () => {
    let filterArray = [];
    for (let item of staticIacStatuses) {
      filterArray.push({
        name: item,
        formattedName: `Contains ${formatIacStatusTitle(item)}`,
        count: aggregationsIacState?.[item] ? aggregationsIacState?.[item] : 0,
        icon: null,
      });
    }
    if (!isEmpty(aggregationsIacStatusesAgg)) {
      for (let item of aggregationsIacStatusesAgg) {
        filterArray.push({
          name: item?.key,
          formattedName: renderOprhanStatus(item?.key),
          count: item?.filteredstatusAgg?.doc_count,
          icon: null,
        });
      }
    }

    return filterArray;
  };

  const formatProvidersFilter = () => {
    let filterArray = [];
    let allProviders = [...keys(providers), "kubernetes"];
    if (!isEmpty(aggregationsProviderTypes)) {
      for (let item of aggregationsProviderTypes) {
        filterArray.push({
          name: item?.key,
          formattedName: `${providerNameFormatter(item?.key)} ${
            !allProviders?.includes(item?.key) ? "(Not Supported)" : ""
          }`,
          count: item
            ? item["filteredstackProviders.providerTypeAgg"]?.doc_count
            : 0,
          element: <ProviderIcon provider={item?.key} customStyle="tree" />,
          isSupported: !allProviders?.includes(item?.key),
        });
      }
    }

    const ordered = orderBy(filterArray, "isSupported", "asc");
    return ordered;
  };

  const formatProviderStatusFilter = () => {
    let filterArray = [];
    let providerStatuses = [];
    if (!isEmpty(aggregationsIntegrated)) {
      const integratedMatch = aggregationsIntegrated?.find(
        (st) => st?.key_as_string === "true"
      );
      providerStatuses?.push({
        key: "hasIntegratedProviders",
        name: "Integrated",
        count: integratedMatch?.filteredhasIntegratedProvidersAgg?.doc_count,
      });
    }
    if (!isEmpty(aggregationsNotIntegrated)) {
      const notIntegratedMatch = aggregationsNotIntegrated?.find(
        (st) => st?.key_as_string === "true"
      );
      providerStatuses?.push({
        key: "hasNotIntegratedProviders",
        name: "Not Integrated",
        count:
          notIntegratedMatch?.filteredhasNotIntegratedProvidersAgg?.doc_count,
      });
    }
    if (!isEmpty(aggregationsNotSupported)) {
      const notSupportedMatch = aggregationsNotSupported?.find(
        (st) => st?.key_as_string === "true"
      );
      providerStatuses?.push({
        key: "hasNotSupported",
        name: "Not Supported",
        count: notSupportedMatch?.filteredhasNotSupportedAgg?.doc_count,
      });
    }
    for (let item of providerStatuses) {
      filterArray.push({
        name: item?.key,
        formattedName: item?.name,
        count: item?.count,
      });
    }

    return filterArray;
  };

  const formatBackendsFilter = () => {
    let filterArray = [];
    if (!isEmpty(aggregationsBackends)) {
      for (let item of aggregationsBackends) {
        if (item?.filteredstateMainRemoteLocationStringAgg?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: item?.key,
            count: item?.filteredstateMainRemoteLocationStringAgg?.doc_count,
            icon: null,
          });
        }
      }
    }

    const ordered = orderBy(filterArray, "name", "asc");
    return ordered;
  };

  
  const formatCommonAggregation = (aggregations, key) => {
    let filterArray = [];
    if (!isEmpty(aggregations)) {
      for (let item of aggregations) {
        if (item[key]?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: item?.key,
            count: item[key]?.doc_count,
            icon: null,
          });
        }
      }
    }

    const ordered = orderBy(filterArray, "name", "asc");
    return ordered;
  };

  const formatProviderIntegrationsFilter = () => {
    let filterArray = [];
    let integrations = getAllProvidersArray(providerIntegrations);
    if (!isEmpty(aggregationsProviderIntegrations)) {
      for (let item of aggregationsProviderIntegrations) {
        const match = integrations.find((integ) => integ.id === item?.key);
        if (
          !isEmpty(item?.key) &&
          item["filteredstackProviders.providerIntegrationIdAgg"]?.doc_count > 0
        ) {
          filterArray.push({
            name: item?.key,
            formattedName: match ? match?.name : item?.key,
            count:
              item["filteredstackProviders.providerIntegrationIdAgg"]
                ?.doc_count,
            element: (
              <ProviderIcon provider={match?.provider} customStyle="tree" />
            ),
            provider: match ? match?.provider : "none",
          });
        }
      }
    }

    const ordered = orderBy(filterArray, "provider", "asc");
    return ordered;
  };

  const formatAssetTypesFilter = () => {
    let filterArray = [];
    if (!isEmpty(aggregationsAssetTypes)) {
      for (let item of aggregationsAssetTypes) {
        if (item?.filteredstackAssetTypesAgg?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: formatAwsStringWithUnderscore(item.key),
            count: item?.filteredstackAssetTypesAgg?.doc_count,
            icon: null,
          });
        }
      }
    }

    return filterArray;
  };

  const formatStackRepositoriesFilter = () => {
    let filterArray = [];
    if (!isEmpty(aggregationsStackRepositories)) {
      for (let item of aggregationsStackRepositories) {
        if (item["filteredmetadata.vcsRepoAgg"]?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: item?.key,
            count: item["filteredmetadata.vcsRepoAgg"]?.doc_count,
            icon: null,
          });
        }
      }
    }

    return filterArray;
  };

  const formatClustersFilter = () => {
    let filterArray = [];
    let allProviders = [...keys(providers), "kubernetes"];
    let integrations = getAllProvidersArray(providerIntegrations);
    if (!isEmpty(aggregationsProviderAccountId)) {
      for (let item of aggregationsProviderAccountId) {
        const match = integrations.find((integ) => integ.accountNumber === item?.key);
        if (
          !isEmpty(item?.key) &&
          item["filteredstackProviders.providerAccountIdAgg"]?.doc_count > 0
        ) {
          filterArray.push({
            name: item?.key,
            formattedName: match ? match?.name : item?.key,
            count:
              item["filteredstackProviders.providerAccountIdAgg"]?.doc_count,
            element: <ProviderIcon provider="k8s" customStyle="tree" />,
            isSupported: !allProviders?.includes(item?.key),
          });
        }
      }
    }
    const ordered = orderBy(filterArray, "isSupported", "asc");
    return ordered;
  };
  const formatTfVersionsFilter = () => {
      let filterArray = [];
      if (!isEmpty(aggregationsTfVersions)) {
        for (let item of aggregationsTfVersions) {
          if (item["filteredmetadata.terraformMetadata.terraformVersionAgg"]?.doc_count > 0) {
            filterArray.push({
              name: item?.key,
              formattedName: item?.key,
              count: item["filteredmetadata.terraformMetadata.terraformVersionAgg"]?.doc_count,
              icon: null,
            });
          }
        }
      }
      const ordered = orderBy(filterArray, "name", "asc");
      return ordered;
  }
  const formatProvidersFullTypeFilter = () => {
    let filterArray = [];
    if (!isEmpty(aggregationsProvidersFullType)) {
      for (let item of aggregationsProvidersFullType) {
        if (item["filteredstackProviders.providerFullTypeAgg"]?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: item?.key,
            count: item["filteredstackProviders.providerFullTypeAgg"]?.doc_count,
            icon: null,
          });
        }
      }
    }
    const ordered = orderBy(filterArray, "name", "asc");
    return ordered;
}
const formatSourceModulesFilter = () => {
  let filterArray = [];
  if (!isEmpty(aggregationsSourceModuleFullName)) {
    for (let item of aggregationsSourceModuleFullName) {
      if (item["filteredmetadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullNameAgg"]?.doc_count > 0) {
        filterArray.push({
          name: item?.key,
          formattedName: item?.key,
          count: item["filteredmetadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullNameAgg"]?.doc_count,
          icon: null,
        });
      }
    }
  }
  const ordered = orderBy(filterArray, "count", "desc");
  return ordered;
}

  const formatNamespacesFilter = () => {
    const filterArray = [];
    (aggregationsNamespaces || []).map((item = {}) => {
      const {doc_count: count = 0, key = ""} = item;
      if (count && key) {
        filterArray.push({
          name: key,
          formattedName: key,
          count,
          icon: null,
        });
      }
    })
    return filterArray;
  };

  const formatRepositories = () => {
    let filterArray = [];
    (aggregationsRepositories || []).map((item = {}) => { 
      const count = item.filteredmoduleOriginAgg?.doc_count;
      if (count && item.key) {
        filterArray.push({
          name: item.key,
          formattedName: item.key,
          count,
        });
      }
    });
    const ordered = orderBy(filterArray, "count", "desc");
    return ordered;
  };

  const formatModuleSourceTypes = () => {
    let filterArray = [];
    (aggregationsModuleSourceTypes || []).map((item = {}) => {
      const count = item.filteredmoduleOriginTypeAgg?.doc_count;
      if (count && item.key) {
        filterArray.push({
          name: item.key,
          formattedName: item.key,
          count,
          element: (
              <img
                  src={moduleOriginTypes(item.key, themeDark)}
                  alt={item.key}
              />
          ),
        });
      }
    });
    const ordered = orderBy(filterArray, "count", "desc");
    return ordered;
  };

  const formatCloudformationIacStatusFilter = () =>{
    let filterArray = [];
    for (let item of staticIacStatuses) {
      filterArray.push({
        name: item,
        formattedName: `Contains ${formatIacStatusTitle(item)}`,
        count: aggregationsIacState?.[item] ? aggregationsIacState?.[item] : 0,
        icon: null,
      });
    }
    const ordered = orderBy(filterArray, "count", "desc");
    return ordered;
  }
  const formatCloudformationStatusFilter = () =>{
    let filterArray = [];
    if (!isEmpty(aggregationsCftStateStatus)) {
      for (let item of aggregationsCftStateStatus) {
        if (item["filteredmetadata.stateStatusAgg"]?.doc_count > 0) {
          filterArray.push({
            name: item?.key,
            formattedName: item?.key,
            count: item["filteredmetadata.stateStatusAgg"]?.doc_count,
            icon: null,
          });
        }
      }
    }

    const ordered = orderBy(filterArray, "count", "desc");
    return ordered;
  }
  const formatMisconfigurations = () => {
    let filterArray = [];
    if (!isEmpty(aggregationsMisconfigurations)) {
      for (let item of aggregationsMisconfigurations) {
        if (
          !isEmpty(item?.key) &&
          item?.doc_count > 0
        ) {
          filterArray.push({
            name: item?.key,
            formattedName: capitalizeFirst(lowerCase(item?.key)),
            count: item?.doc_count,
          });
        }
      }
    }

    const ordered = orderBy(filterArray, "count", "desc");
    return ordered;
  };

  const handleTriggerScanNowLambda = async (crawlerId) => {
    await dispatch(triggerScanNowLambda(crawlerId));
  };

  const formatProviderUrls = () => {
    let providerUrls = {};
    if (!isEmpty(aggregationProviderUrls)) {
      for (let linkAgg of aggregationProviderUrls) {
        let linkArr = linkAgg?.key?.split("/");
        let surfix = linkArr[linkArr?.length - 1];
        providerUrls[surfix] = linkAgg?.key;
      }
    }
    return providerUrls;
  };
  const getTerraformInsights = () => {
    return [{
      title: "Unique TF Versions",
      count: aggregationsTfVersions?.length,
      isActive: selectedCategory === "appliedStacks" &&  filtersActivePanel?.includes("7"),
      onClick: () => { 
       setFilterPage(1)
       setSelectedCategory('appliedStacks');
       setFiltersActivePanel(["7"]);
        setTimeout(() => {
          document.getElementById("tfVersionPanel")?.scrollIntoView({ behavior: "smooth"});
        }, 450);
      }
    },
    {
      title: "Drifted Stacks",
      count: aggregationsIacState?.modified,
      isActive:selectedCategory === 'appliedStacks' && isEqual(screenFilters.iacState, ["modified"]) &&  filtersActivePanel?.includes("1"),
      onClick: () => {
        setFilterPage(1)
        setSelectedCategory('appliedStacks');
        setFiltersActivePanel(["1"])
        setTimeout(() => {
          document.getElementById("yakStatus")?.scrollIntoView({ behavior: "smooth"});
        }, 450);
        dispatch(
          setFilters({
            ...emptyIacScreenFilters,
            stateType:["terraform"],
            iacState: ["modified"],
          })
        );
       }
    },
    {
      title: "Unused Modules",
      count: aggregationsIsBeingUsedModules?.find(agg => agg["key_as_string"] == "false")?.doc_count ?? 0,
      isActive: selectedCategory === 'modules' && modulesScreenFilters?.isBeingUsed?.includes(false) ,
      onClick: () => { 
       setFilterPage(1)
       setSelectedCategory('modules');
       setFiltersActivePanel(["2"])
       dispatch(
        setModulesFilters({
          ...emptyModulesScreenFilters,
          isBeingUsed : [false],
        })
      );
      }
    },
    {
      title: "Critical Misconfigurations",
      count: aggregationsMisconfigurations?.find(config => config?.key == 'CRITICAL')?.doc_count ?? 0,
      isActive: selectedCategory === 'modules' && modulesScreenFilters.misconfigurations?.includes('CRITICAL'),
      onClick: () => {
       setFilterPage(1) 
       setSelectedCategory('modules');
       setFiltersActivePanel(["2"])
       if(aggregationsMisconfigurations?.find(agg => agg?.key === 'CRITICAL')?.doc_count ?? 0 > 0){
        dispatch(
          setModulesFilters({
            ...emptyModulesScreenFilters,
            misconfigurations: ["CRITICAL"],
          }));
       }
      }
    },
    {
      title: "Providers in use",
      count: aggregationsProviderType?.length ?? 0,
      isActive: selectedCategory == 'providers',
      onClick: () => { 
        setFilterPage(1)
        setSelectedCategory("providers")
       }
    },
    {
      title: "Pending Integration",
      count: Object.values(aggregationsMissingAccountNumbers || {}).reduce((acc, item) => acc + item?.length, 0),
      isActive: selectedCategory == 'providers',
      onClick: () => {
        setFilterPage(1)
        setSelectedCategory("providers")
      }
    },
    ]
  }
  const getCloudformationInsights = () => {
    return [
      {
        title: "Drifted Stacks",
        count: aggregationsIacState?.modified,
        isActive:filtersActivePanel?.includes("3") && isEqual(screenFilters.iacState, ["modified"]),
        onClick: () => {
          setFilterPage(1)
          setFiltersActivePanel(["3"])
          setSelectedCategory("appliedStacks")
          dispatch(
            setFilters({
              ...emptyIacScreenFilters,
              stateType:["cloudformation"],
              iacState: ["modified"],
            })
          );
        }
      },
      {
        title: "Unique CDK Language",
        count: aggregationsCftRuntime?.length,
        isActive: filtersActivePanel?.includes("4"),
        onClick: ()=> {
          setFilterPage(1)
          setSelectedCategory("appliedStacks")
          setFiltersActivePanel(["4"])
        }
      },
    ]
  }

  const handleSetSelectedIacType = (type) => {
    history.push(`/iac/${type}`);
    setFiltersActivePanel();
    setCollapsed(true)
    return setSelectedIacType(type)
  }

  const links = [
    {
      text: "Export table",
      icon: <FontAwesomeIcon icon="download" />,
      onClick: () => setExportModalOpen(true),
      disabled: isEmpty(responseObjects),
    },
  ];

  const iacTableActions = (
    <Popover
      content={<DropMenu links={links} />}
      title={null}
      placement="bottomRight"
      destroyTooltipOnHide
      overlayClassName="IacTableActions"
    >
      <div>
        <IconBtn
          type="menu-h"
          colorClass="h-menu"
        />
      </div>
    </Popover>
  );

  if (initLoading) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  if (
    !initLoading &&
    isEmpty(responseObjects) &&
    isEmpty(searchVal) &&
    isEmpty(backends)
  ) {
    return <EmptyIac title="IaC Backend is Missing" subtitle = "Let's start with integrating your IaC backends (Data Source or IaC Remote State)."/>;
  }

  const returnActiveProviderStatuses = () => {
    let activeKeys = [];
    forEach(keys(screenFilters), (screenFilter) => {
      if (
        screenFilter?.startsWith("has") &&
        !isEmpty(screenFilters[screenFilter]) &&
        screenFilters[screenFilter][0] === true
      ) {
        activeKeys?.push(screenFilter);
      }
    });
    return activeKeys;
  };

  const returnActiveIacStatuses = () => {
    let activeKeys = [...screenFilters["iacState"], ...screenFilters["status"]];
    activeKeys = flatten(activeKeys);
    return activeKeys;
  };
  const returnActiveCftStatuses = () => {
    let activeKeys = screenFilters["metadata.stateStatus"] ?? [];
    activeKeys = flatten(activeKeys);
    return activeKeys;
  };

  return (
    
    <div>
      <div className="IacStacks">
        <IacStacksTypes
          iacTypes={ALL_IAC_TYPES}
          selectedIacType={selectedIacType}
          setSelectedIacType={handleSetSelectedIacType}
          aggTypes={aggregationsIacTypes}
          loading={tabLoading}
          collapsed={collapsed}
          setCollapsed={setCollapsed}
        />
       
        <IacStacksContent
          collapsed={collapsed}
          terraformInsights={getTerraformInsights()}
          cloudformationInsights={getCloudformationInsights()}
          iacStacksCategoriesComponent={
            <IacStacksCategories
            categories={categories}
            selectedIacType={selectedIacType}
            selectedCategory={selectedCategory}
            setSelectedCategory={setSelectedCategory}
          />
          }
          selectedIacType={selectedIacType}
          selectedCategory={selectedCategory}
          iacStacksFilters={
            <IacStacksFilters
              activePanel={filtersActivePanel}
              setActivePanel={setFiltersActivePanel}
              handleSetActivePanelsFromParent={activePanel => setFiltersActivePanel(activePanel)}
              activeIacStatuses={returnActiveIacStatuses()}
              activeProviderTypes={
                screenFilters
                  ? screenFilters["stackProviders.providerType"]
                  : []
              }
              activeProviderIntegrations={
                screenFilters
                  ? screenFilters["stackProviders.providerIntegrationId"]
                  : []
              }
              activeCftStateStatus={returnActiveCftStatuses()}
              activeCftRuntime={screenFilters["metadata.cloudFormationMetadata.cdkMetadata.languageWithVersion"] ?? []}
              activeCftRegions={screenFilters["stateRemoteLocation.cloudFormationLocation.region"] ?? []}
              activeProviderStatuses={returnActiveProviderStatuses()}
              activeBackends={screenFilters?.stateMainRemoteLocationString}
              activeAssetTypes={screenFilters?.stackAssetTypes}
              activeTfVersions={screenFilters?.["metadata.terraformMetadata.terraformVersion"]}
              activeRepositories={screenFilters?.["metadata.vcsRepo"]}
              activeSourceModules={screenFilters?.["metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullName"]}
              activeProvidersFullType={screenFilters?.["stackProviders.providerFullType"]}
              activeClusters={
                screenFilters
                  ? screenFilters["stackProviders.providerAccountId"]
                  : []
              }
              activeNamespaces={
                screenFilters
                  ? screenFilters[`metadata.${k8sOrHelmMetadata}Metadata.${namespaceField}`]
                  : []
              }
              handleClearAllFilters={() => {
                setResetFilters(true);
                dispatch(
                  setFilters({
                    ...emptyIacScreenFilters,
                    stateType: [selectedIacType],
                  })
                );
              }}
              resetFilters={resetFilters}
              iacStatuses={formatIacStatusFilter()}
              iacStatusSelected={(filters) => {
                setFilterPage(1);
                const staticFilters = [];
                const regularFilters = [];
                map(filters, (filter) => {
                  if (staticIacStatuses?.includes(filter)) {
                    return staticFilters?.push(filter);
                  }
                  regularFilters?.push(filter);
                });
                dispatch(
                  setFilters({
                    ...screenFilters,
                    status: regularFilters,
                    iacState: staticFilters,
                  })
                );
              }}
              tfVersionsSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    ["metadata.terraformMetadata.terraformVersion"]: filters,
                  })
                );
              }}
              providersFullTypeSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    ["stackProviders.providerFullType"]: filters,
                  })
                );
              }}
              sourceModulesSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    ["metadata.terraformMetadata.moduleBlocksTreeSlice.sourceModuleFullName"]: filters,
                  })
                );
              }}
              providerTypes={formatProvidersFilter()}
              providerTypeSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "stackProviders.providerType": filters,
                  })
                );
              }}
              providerStatuses={formatProviderStatusFilter()}
              providerStatusSelected={(filters) => {
                setFilterPage(1);
                let newFilters = { ...screenFilters };
                delete newFilters["hasIntegratedProviders"];
                delete newFilters["hasNotIntegratedProviders"];
                delete newFilters["hasNotSupported"];
                if (isEmpty(filters)) {
                  return dispatch(setFilters(newFilters));
                }
                for (let filter of filters) {
                  newFilters[filter] = [true];
                }
                dispatch(setFilters(newFilters));
              }}
              backends={formatBackendsFilter()}
              backendsSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    stateMainRemoteLocationString: filters,
                  })
                );
              }}
              terraformVersions={formatTfVersionsFilter()}
              providersFullType={formatProvidersFullTypeFilter()}
              sourceModules={formatSourceModulesFilter()}
              providerIntegrations={formatProviderIntegrationsFilter()}
              cftStateStatuses={formatCloudformationStatusFilter(aggregationsCftStateStatus,'filteredmetadata.stateStatusAgg')}
              cloudformationIacStatuses={formatCloudformationIacStatusFilter()}
              cftRegions={formatCommonAggregation(aggregationsCftRegion,'filteredstateRemoteLocation.cloudFormationLocation.regionAgg')}
              cftRuntime={formatCommonAggregation(aggregationsCftRuntime,'filteredmetadata.cloudFormationMetadata.cdkMetadata.languageWithVersionAgg')}
              providerIntegrationsSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "stackProviders.providerIntegrationId": filters,
                  })
                );
              }}
              cftStateStatusesSelected={(filters) => {
                setFilterPage(1);
                const staticFilters = [];
                const regularFilters = [];
                map(filters, (filter) => {
                  if (staticIacStatuses?.includes(filter)) {
                    return staticFilters?.push(filter);
                  }
                  regularFilters?.push(filter);
                });
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "metadata.stateStatus": regularFilters,
                    iacState: staticFilters,
                  })
                );
              }}
              cftRegionsSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "stateRemoteLocation.cloudFormationLocation.region": filters,
                  })
                );
              }}
              cftRuntimeSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "metadata.cloudFormationMetadata.cdkMetadata.languageWithVersion": filters,
                  })
                );
              }}
              assetTypes={formatAssetTypesFilter()}
              assetTypesSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    stackAssetTypes: filters,
                  })
                );
              }}
              repositories={formatStackRepositoriesFilter()}
              repositoriesSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                    setFilters({
                      ...screenFilters,
                      "metadata.vcsRepo": filters,
                    })
                );
              }}
              clustersSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    "stackProviders.providerAccountId": filters,
                  })
                );
              }}
              selectedIacType={K8S_IAC_TYPES.includes(selectedIacType) ? "helm" : selectedIacType}
              clusters={formatClustersFilter()}
              namespaces={formatNamespacesFilter()}
              namespacesSelected={(filters) => {
                setFilterPage(1);
                dispatch(
                  setFilters({
                    ...screenFilters,
                    [`metadata.${k8sOrHelmMetadata}Metadata.${namespaceField}`]: filters,
                  })
                );
              }}
            />
          }
          modulesFilters={
            <ModulesFilters
              activeRepositories={modulesScreenFilters?.moduleOrigin}
              activeMisconfigurations={modulesScreenFilters?.misconfigurations}
              activeModuleSourceTypes={modulesScreenFilters?.moduleOriginType}
              handleClearAllFilters={() => {
                setResetModulesFilters(true);
                dispatch(setModulesFilters(emptyModulesScreenFilters));
              }}
              activeModuleUsage={modulesScreenFilters.isBeingUsed}
              activeModuleRepoFullNames={modulesScreenFilters.moduleOrigin}
              resetFilters={resetModulesFilters}
              repositories={formatRepositories()}
              moduleSourceTypes={formatModuleSourceTypes()}
              moduleUsage={formatCommonAggregation(aggregationsIsBeingUsed,'filteredisBeingUsedAgg')}
              repositoriesSelected={(filters) => {
                dispatch(
                  setModulesFilters({
                    ...modulesScreenFilters,
                    moduleOrigin: filters,
                  })
                );
              }}
              moduleUsageSelected={(filters) => {
                dispatch(
                  setModulesFilters({
                    ...modulesScreenFilters,
                    isBeingUsed: filters,
                  })
                );
              }}
              misconfigurations={formatMisconfigurations()}
              misconfigurationsSelected={(filters) => {
                dispatch(
                  setModulesFilters({
                    ...modulesScreenFilters,
                    misconfigurations: filters,
                  })
                );
              }}
              moduleSourceTypesSelected={(filters) => {
                dispatch(
                  setModulesFilters({
                    ...modulesScreenFilters,
                    moduleOriginType: filters,
                  })
                );
              }}
            />
          }
          iacStacksTable={
            <IacStacksTable
              tableData={responseObjects}
              fetchingData={loading}
              totalObjects={totalObjects}
              handleSelectedRows={(items) => setSelectedRows(items)}
              handlePageChange={(page) => {
                setFilterPage(page);
              }}
              handleTableSorterChange={(sorter) => {
                setTableSorting(sorter);
              }}
              movePagination
              filersRowComponent
              handleRemoveBadgeFilter={handleRemoveBadgeFilter}
              showActionsMenu
              setStackViewAssets={setStackViewAssets}
              setStackDrawerOpen={setStackDrawerOpen}
              setStackRowData={setStackRowData}
              screenFilters={screenFilters}
              tablePageSize={tablePageSize}
              onPageSizeChange={(pageSize) =>
                dispatch(updateTabelPageSize(pageSize))
              }
              selectedIacType={selectedIacType}
              integrations={getAllProvidersArray(providerIntegrations)}
              iacTableActions={iacTableActions}
            />
          }
          terraformStacksTable={
            <TerraformStacksTable
              tableData={responseObjects}
              fetchingData={loading}
              totalObjects={totalObjects}
              handleSelectedRows={(items) => setSelectedRows(items)}
              handlePageChange={(page) => {
                setFilterPage(page);
              }}
              handleTableSorterChange={(sorter) => {
                setTableSorting(sorter);
              }}
              movePagination
              filersRowComponent
              handleRemoveBadgeFilter={handleRemoveBadgeFilter}
              showActionsMenu
              setStackViewAssets={setStackViewAssets}
              setStackDrawerOpen={setStackDrawerOpen}
              setStackRowData={setStackRowData}
              screenFilters={screenFilters}
              tablePageSize={tablePageSize}
              onPageSizeChange={(pageSize) =>
                dispatch(updateTabelPageSize(pageSize))
              }
              setModuleViewAssets={setModuleViewAssets}
              setSelectedCategory={setSelectedCategory}
              iacTableActions={iacTableActions}
            />
          }
          modulesTable={
            <ModulesTable
              setFilters={setFilters}
              setSelectedCategory={setSelectedCategory}
              searchVal={searchVal}
              handleRemoveBadgeFilter={handleRemoveModulesBadgeFilter}
              filersRowComponent
              setResetFilters={setResetModulesFilters}
            />
          }
          providersTable={
            <ProvidersTable
              setFilters={setFilters}
              selectedIacType={selectedIacType}
              setSelectedCategory={setSelectedCategory}
            />
          }
          backendsTable={
            <BackendsTable
              triggerScanNowLambda={(crawlerId) =>
                handleTriggerScanNowLambda(crawlerId)
              }
              setFilters={setFilters}
              backendInfoModalOpen={backendInfoModalOpen}
              setBackendInfoModalOpen={setBackendInfoModalOpen}
              activeInfoTab={activeInfoTab}
              setActiveInfoTab={setActiveInfoTab}
              selectedIacType={selectedIacType}
              setSelectedCategory={setSelectedCategory}
            />
          }
          repositoryTable={
            <RepositoryTable
                setSelectedCategory={setSelectedCategory}
                searchVal={searchVal}
            />
          }
          loading={tabLoading}
        />
      </div>

      <IacStackDrawer
        visible={stackDrawerOpen}
        closeDrawer={() => {
          setStackRowData(null);
          setStackDrawerOpen(false);
        }}
        rowData={stackRowData}
        setMapModalRowData={setMapModalRowData}
        setMapModalOpen={setMapModalOpen}
        showStackContents={
          showStackContentsStateTypes?.includes(stackRowData?.stateType) &&
          !hideStackContentsStatuses?.includes(stackRowData?.status)
        }
        providerUrls={formatProviderUrls()}
        iacType={selectedIacType}
      />
      <MapModal
        visible={mapModalOpen}
        handleClose={handleCloseMapModal}
        title={`${mapModalRowData?.stackName} - Infrastructure Map`}
        rowData={mapModalRowData}
        imageUrl={imageUrl}
        setImageUrl={setImageUrl}
      />
      <ExportModal
        visible={exportModalOpen}
        handleClose={() => setExportModalOpen(false)}
        tableSorting={tableSorting}
        selectedIacType={selectedIacType}
      />
    </div>
  );
};

export default IacStacks;
