import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";
import { appToast } from "../../shared/appToast/appToast";

export const GET_ALL_PROFILES = "GET_ALL_PROFILES";
export const GET_CURRENT_PROFILE = "GET_CURRENT_PROFILE";
export const CREATE_USER = "CREATE_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_ALL_ROLES = "GET_ALL_ROLES";
export const ASSIGN_ROLE = "ASSIGN_ROLE";
export const REMOVE_ROLE = "REMOVE_ROLE";
export const SET_IS_VIEWER = "SET_IS_VIEWER";
export const GET_USER_COMPANY_LOGO = "GET_USER_COMPANY_LOGO";
export const GET_USER_REDACTOR_PREFERENCE = "GET_USER_REDACTOR_PREFERENCE";
export const POST_ACCOUNT_ACTIVITY = "POST_ACCOUNT_ACTIVITY";

const getAllProfiles = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const profilesReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "GET"
    );

    const profiles = await profilesReq.json();
    dispatch({
      type: GET_ALL_PROFILES,
      payload: { profiles },
    });

    return profiles;
  });
};

const getCurrentProfile = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const currentReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/current`,
      "GET"
    );
    const current = await currentReq.json();

    dispatch({
      type: GET_CURRENT_PROFILE,
      payload: { current },
    });

    return current;
  });
};

const createUser = (name, email, roles) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      name,
      email,
      roles,
    };

    const invitedUser = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users`,
      "POST",
      payload
    );

    if (invitedUser.ok) {
      appToast("success");
    }

    dispatch({
      type: CREATE_USER,
      payload: {},
    });

    return invitedUser;
  });
};

const deleteUser = (userId) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const deletedUser = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/${userId}`,
      "DELETE"
    );
    let reducerPayload = ""
    if (deletedUser.ok) {
      appToast("success");
      reducerPayload = userId
    }

    dispatch({
      type: DELETE_USER,
      payload: reducerPayload,
    });

    return deletedUser;
  });
};

const getAllRoles = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();
    const allRolesReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/roles`,
      "GET"
    );

    const allRoles = await allRolesReq.json();
    dispatch({
      type: GET_ALL_ROLES,
      payload: { allRoles },
    });

    return allRoles;
  });
};

const assignRole = (userId, roles) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      userId: userId,
      roles: roles,
    };

    const assignReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/assign-roles`,
      "POST",
      payload
    );

    if (assignReq.ok) {
      appToast("success");
    }

    dispatch({
      type: ASSIGN_ROLE,
      payload: {},
    });

    return assignReq;
  });
};

const removeRole = (userId, roles) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const payload = {
      userId: userId,
      roles: roles,
    };

    const removeReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/users/remove-roles`,
      "POST",
      payload
    );

    if (removeReq.ok) {
      appToast("success");
    }

    dispatch({
      type: REMOVE_ROLE,
      payload: {},
    });

    return removeReq;
  });
};

const setIsUserViewer = (isViewer) => {
  return action(async (dispatch) => {
    dispatch({
      type: SET_IS_VIEWER,
      payload: { isViewer },
    });

    return;
  });
};

const getUserCompanyLogo = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const logoReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/account-logo`,
      "GET",
      {},
      true,
      null,
      false
    );

    const logo = await logoReq.json();
    dispatch({
      type: GET_USER_COMPANY_LOGO,
      payload: logo,
    });

    return logo;
  });
};

const getUserRedactorPreference = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const redactorPreferenceReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/account-redactor-preference`,
      "GET",
    );

    const redactorPreference = await redactorPreferenceReq.json();
    dispatch({
      type: GET_USER_REDACTOR_PREFERENCE,
      payload: redactorPreference,
    });

    return redactorPreference;
  });
};

const postAccountActivity = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const activity = await requestWrapper.sendRequest(
      `${BACKEND_URL}/account/last-activity`,
      "POST"
    );

    dispatch({
      type: POST_ACCOUNT_ACTIVITY,
    });

    return activity;
  });
};

export {
  getAllProfiles,
  getCurrentProfile,
  createUser,
  deleteUser,
  getAllRoles,
  assignRole,
  removeRole,
  setIsUserViewer,
  getUserCompanyLogo,
  getUserRedactorPreference,
  postAccountActivity,
};
