import thunk from "redux-thunk";
import { applyMiddleware, combineReducers, createStore } from "redux";
import { composeWithDevTools } from 'redux-devtools-extension/developmentOnly';
import { reducer as reduxFormReducer } from "redux-form";

import {
  environmentReducer,
  variableReducer,
  runsReducer,
  statesReducer,
  profilesReducer,
  keyPairsReducer,
  terraformReducer,
  vcsReducer,
  fixDriftReducer,
  removeResourceReducer,
  integrationsReducer,
  inventoryReducer,
  metricReducer,
  exclusionsReducer,
  driftsExclusionsReducer,
  homePageReducer,
  activeFeaturesReducer,
  driftsReducer,
  iacImportReducer,
  k8sIntegrationReducer,
  gcpIntegrationReducer,
  akamaiIntegrationReducer,
  datadogIntegrationReducer,
  githubIntegrationReducer,
  gcpProviderIntegrationReducer,
  newrelicIntegrationReducer,
  userPreferencesReducer,
  stacksReducer,
  cftStacksReducer,
  tfcIntegrationReducer,
  notificationsReducer,
  orchestratorReducer,
  feedbackReducer,
  iacPolicyReducer,
  classificationsReducer,
  crawlerReducer,
  consulIntegrationReducer,
  globalAppReducer,
  insightsReducer,
  helmChartsReducer,
  screenViewReducer,
  jiraIntegrationReducer,
  pulumiIntegrationReducer,
  dashboardReducer,
  onbordingReducer,
  iacStacksReducer,
  commentsReducer,
  organizationReducer,
  historyReducer,
  cloudflareReducer,
  assetEventsReducer,
  insightsV2Reducer,
  ciWorkflowsReducer,
} from "./redux/index";

const reducer = combineReducers({
  environmentReducer,
  variableReducer,
  runsReducer,
  statesReducer,
  profilesReducer,
  keyPairsReducer,
  terraformReducer,
  vcsReducer,
  fixDriftReducer,
  removeResourceReducer,
  integrationsReducer,
  inventoryReducer,
  metricReducer,
  exclusionsReducer,
  driftsExclusionsReducer,
  homePageReducer,
  activeFeaturesReducer,
  driftsReducer,
  iacImportReducer,
  k8sIntegrationReducer,
  gcpIntegrationReducer,
  akamaiIntegrationReducer,
  datadogIntegrationReducer,
  githubIntegrationReducer,
  gcpProviderIntegrationReducer,
  newrelicIntegrationReducer,
  userPreferencesReducer,
  stacksReducer,
  cftStacksReducer,
  tfcIntegrationReducer,
  notificationsReducer,
  orchestratorReducer,
  feedbackReducer,
  iacPolicyReducer,
  classificationsReducer,
  crawlerReducer,
  consulIntegrationReducer,
  globalAppReducer,
  insightsReducer,
  helmChartsReducer,
  screenViewReducer,
  jiraIntegrationReducer,
  pulumiIntegrationReducer,
  dashboardReducer,
  onbordingReducer,
  iacStacksReducer,
  commentsReducer,
  organizationReducer,
  historyReducer,
  cloudflareReducer,
  assetEventsReducer,
  insightsV2Reducer,
  ciWorkflowsReducer,
  form: reduxFormReducer,
});

const store = createStore(reducer, composeWithDevTools(applyMiddleware(thunk)));
export default store;