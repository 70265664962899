import React, { useState, useEffect } from "react";
import { Form, Input, Select, Checkbox, Tag } from "antd";
import regionsOptions from "../../../../consts/regions";
import AppBtn from "../../../../shared/appBtn/appBtn";
import addIcon from "../../../../Images/general_icons/add_circle.svg";
import { isEmpty, keys, map } from "lodash";
import { v4 as uuidv4 } from "uuid";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

const EventNotificationsByRegionConfig = ({
  form,
  setSubmitDisabled,
  bucketsByRegionMap,
  setBucketsByRegionMap,
}) => {
  const { CheckableTag } = Tag;
  const [validRegion, setValidRegion] = useState(false);
  const [isEventNotifications, setIsEventNotifications] = useState(false);
  const [localBucketsByRegionMap, setLocalBucketsByRegionMap] = useState({});

  const region = form.getFieldValue("region");
  const bucketNames = form.getFieldValue("bucketNames");

  useEffect(() => {
    checkIfRegionValid();
  }, [region]);

  useEffect(() => {
    if (!isEmpty(region)) {
      setLocalBucketsByRegionMap({
        ...localBucketsByRegionMap,
        [region]: bucketNames,
      });
    }
  }, [bucketNames]);

  const checkIfRegionValid = () => {
    if (
      keys(regionsOptions)?.includes(region) &&
      !keys(bucketsByRegionMap)?.includes(region)
    ) {
      setSubmitDisabled(true);
      return setValidRegion(true);
    }
    setValidRegion(false);
    setSubmitDisabled(false);
  };

  const handleSetIsEventNotifications = () => {
    if (isEventNotifications) {
      form?.setFieldsValue({ region: "", bucketNames: [] });
      setLocalBucketsByRegionMap({});
      setBucketsByRegionMap({});
      setSubmitDisabled(false);
    } else {
      setSubmitDisabled(true);
    }
    setIsEventNotifications(!isEventNotifications);
  };

  const onAddButtonClick = async () => {
    await setBucketsByRegionMap({
      ...localBucketsByRegionMap,
      ...bucketsByRegionMap,
    });
    form?.setFieldsValue({ region: "", bucketNames: [] });
    setValidRegion(false);
  };

  const onRemoveRegion = (reg) => {
    const newState = { ...bucketsByRegionMap };
    delete newState[reg];
    const newLocalState = { ...localBucketsByRegionMap };
    delete newLocalState[reg];
    setBucketsByRegionMap(newState);
    setLocalBucketsByRegionMap(newLocalState);
  };

  return (
    <div
      className={`EventNotificationsByRegionConfig ${
        !validRegion ? "disabledField" : ""
      } col`}
    >
      <Checkbox
        checked={isEventNotifications}
        onChange={handleSetIsEventNotifications}
      >
        <span className="form-label">
          S3 Event Notifications of TF State Files
        </span>
      </Checkbox>
      {isEventNotifications && (
        <div className="col event-notifications-block">
          <Form.Item
            label="Region"
            name="region"
            style={{ marginBottom: "1.5rem", flexDirection: "column" }}
            rules={[
              {
                message: "Invalid region",
                validator: (_, value) => {
                  if (isEmpty(value)) {
                    return Promise.resolve();
                  }
                  if (
                    keys(regionsOptions)?.includes(value) &&
                    !keys(bucketsByRegionMap)?.includes(value)
                  ) {
                    return Promise.resolve();
                  } else {
                    return Promise.reject("Invalid region");
                  }
                },
              },
            ]}
          >
            <Input />
          </Form.Item>
          <Form.Item
            label="Bucket Names"
            name="bucketNames"
            style={{
              marginBottom: "1.5rem",
              flexDirection: "column",
            }}
          >
            <Select
              mode="tags"
              allowClear
              dropdownStyle={{ display: "none" }}
              disabled={!validRegion}
            />
          </Form.Item>
          <div className="EventNotificationsByRegionConfig row between">
            {isEventNotifications && (
              <div className="EventNotificationsByRegionConfig__tags col">
                {map(keys(bucketsByRegionMap) || [], (reg) => (
                  <CheckableTag key={uuidv4()} checked>
                    <span className="EventNotificationsByRegionConfig__tags-content row">
                      <span>{`${reg}: ${bucketsByRegionMap[
                        reg
                      ]?.toString()}`}</span>
                      <FontAwesomeIcon
                        icon="times-circle"
                        onClick={() => onRemoveRegion(reg)}
                      />
                    </span>
                  </CheckableTag>
                ))}
              </div>
            )}
            <span className="EventNotificationsByRegionConfig__btn">
              <AppBtn
                onClick={onAddButtonClick}
                text="Add"
                icon={
                  <img
                    alt="add"
                    src={addIcon}
                    style={{ transform: "translateY(-1px)" }}
                  />
                }
                disabled={!validRegion || isEmpty(bucketNames)}
              />
            </span>
          </div>
        </div>
      )}
    </div>
  );
};

export default EventNotificationsByRegionConfig;
