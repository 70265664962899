import React from "react";
import _, { keys } from "lodash";
import {
  iacStatusIconByType,
  numberWithCommas,
} from "../../../utils/formatting";
import { Pie } from "@ant-design/plots";
import { useSelector } from "react-redux";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

import "./pieChart.scss";

const PieChart = ({ data, totalAssetsCount, datesData }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  const colors = {
    Unmanaged: "#D76089",
    Codified: "#67CEBC",
    Ghost: "#F4B483",
    Drifted: "#9872FE",
  };

  const getSevenDaysCount = (name) => {
    let allStates = _.map(datesData, (date) => {
      return _.map(date?.state, (st) => (st.key === name ? st : null));
    });
    allStates = _.flatten(allStates);
    allStates = allStates.filter((state) => !_.isEmpty(state));
    const totalCount = _.reduce(
      allStates,
      (prev, curr) => {
        return prev.count ? prev.count : prev + curr?.count;
      },
      0
    );
    return numberWithCommas(totalCount);
  };

  const config = {
    appendPadding: 0,
    data,
    angleField: "value",
    pieStyle: {
      stroke: themeDark ? "#212133" : "white",
      lineWidth: 5,
    },
    radius: 1,
    innerRadius: 0.8,
    tooltip: {
      customContent: (title, data) => {
        return (
          <div className="PieChart__tooltip">
            <span
              className="PieChart__tooltip-line"
              style={{ backgroundColor: colors[title] }}
            ></span>
            <span
              className="PieChart__tooltip-circle"
              style={{ backgroundColor: colors[title] }}
            >
              <FontAwesomeIcon
                icon={iacStatusIconByType(title)}
                style={{
                  top: "2px",
                  left:
                    title === "Unmanaged"
                      ? "2px"
                      : title === "Codified"
                      ? "1px"
                      : "3px",
                }}
              />
            </span>
            <div className="PieChart__tooltip-text col">
              <span className="bold">{`${
                _.round(
                  (data[0]?.data?.value / totalAssetsCount) * 100,
                  2
                ).toFixed(2) + "%"
              } ${title}`}</span>
              <span>
                {`Total ${numberWithCommas(
                  data[0]?.data?.value
                )} / +${getSevenDaysCount(title)} Past week`}
              </span>
            </div>
          </div>
        );
      },
    },
    label: {
      type: "spider",
      offset: "-50%",
      style: {
        textAlign: "center",
        fill: "rgba(255, 255, 255, 0)", // opacity 0 to hide the outer text of the pie with the numbers indication
      },
      value: {
        formatter: (v) =>
          _.round((v / totalAssetsCount) * 100, 2).toFixed(2) + "%",
      },
      position: "bottom",
      autoRotate: false,
      autoHide: true,
    },
    legend: false,
    colorField: "type", // or seriesField in some cases
    color: ({ type }) => {
      if (keys(colors)?.includes(type)) {
        return colors[type];
      }
      return "#C7C7C7";
    },
    statistic: {
      title: false,
      content: {
        style: {
          whiteSpace: 'pre-wrap',
          overflow: 'hidden',
          textOverflow: 'ellipsis',
          padding: '10px 0'
        },
        customHtml: () => {
          return <div
          style={{
            fontSize: 26,
            display: 'flex',
            flexDirection: "column",
            gap: '5px',
            color: themeDark ? "rgba(255, 255, 255, 0.801)" : "#515151",
            fontWeight: 300,
          }}
        >
          <span>IaC</span>
          <span>Coverage</span>
        </div>
        },
      },
    },
  };

  return (
    <Pie
      className="PieChart"
      {...config}
      style={{ width: "100%", height: "70%" }}
    />
  );
};

export default PieChart;
