import React from "react";
import { Form, Input, Select } from "antd";
import _, { keys } from "lodash";

import {
  numberRegex,
  nicknameRegex,
  validate,
} from "../../../utils/validations";
import usFlag from "../../../Images/general_icons/us-flag.svg";
import euFlag from "../../../Images/general_icons/eu-flag.svg";

import "./newrelicIntegration.scss";

const NewrelicCreation = ({
  wizardState,
  setWizardState,
  setSubmitDisabled,
}) => {
  const [form] = Form.useForm();
  const layout = {};

  const flagIcons = {
    US: usFlag,
    EU: euFlag,
  };

  const checkValidation = (formValues) => {
    let formValid = true;
    const { name, apiKey, accountNumber, region } = formValues;

    if (
      _.isEmpty(name) ||
      _.isEmpty(apiKey) ||
      _.isEmpty(accountNumber) ||
      _.isEmpty(region)
    ) {
      formValid = false;
    }

    if (!_.isEmpty(name) && !validate(nicknameRegex.regex, name)) {
      formValid = false;
    }
    if (
      !_.isEmpty(accountNumber) &&
      !validate(numberRegex.regex, accountNumber)
    ) {
      formValid = false;
    }
    return setSubmitDisabled(!formValid);
  };

  return (
    <div className="NewrelicCreation col">
      <div className="NewrelicCreation__intro col">
      <span className="dark-blue bold">Procedure</span>
        <ul className="dark-blue NewrelicCreation__intro-list">
          <li key="1">Log in to your New Relic account.</li>
          <li key="2">
            In New Relic, select the{" "}
            <span className="purple-text med-bold">account dropdown</span>
            {` icon > `}
            <span className="purple-text med-bold">API keys</span>.
          </li>
          <li key="3">Copy the account number of the desired account.</li>
          <li key="4">
            Enter a <span className="purple-text med-bold">Nickname</span>, and paste the
            account number into the <span className="purple-text med-bold">Account ID</span>{" "}
            box.
          </li>
          <li key="5">
            Go to the New Relic window, and select{" "}
            <span className="purple-text med-bold">Create a key</span>.
          </li>
          <li key="6">
            Select <span className="purple-text med-bold">User</span> as the{" "}
            <span className="purple-text med-bold">Key type</span>.
          </li>
          <li key="7">
            Enter a <span className="purple-text med-bold">Name</span>, and select{" "}
            <span className="purple-text med-bold">Create a key</span>.
          </li>
          <li key="8">
            Select the <span className="purple-text med-bold">more</span> icon, and select{" "}
            <span className="purple-text med-bold">Copy key</span>.
          </li>
          <li key="9">
            Paste the key into the <span className="purple-text med-bold">API Key</span> box.
          </li>
          <li key="10">
            Select <span className="purple-text med-bold">Region</span>
            {` > `}
            <span className="purple-text med-bold">Next</span>.
          </li>
        </ul>
      </div>
      <Form
        {...layout}
        name="newrelic-wizard-form"
        form={form}
        initialValues={{
          name: wizardState.name || "New Relic",
        }}
        className="AppWizard__content-body-form"
        onValuesChange={(
          changedValues,
          allValues // this is how you can get the form values
        ) => {
          setWizardState(Object.assign(wizardState, allValues));
          checkValidation(allValues);
        }}
      >
        <Form.Item
          label="Nickname"
          name="name"
          rules={[
            {
              required: true,
              message: "Nickname is required",
            },
            {
              pattern: nicknameRegex.regex,
              message: nicknameRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter integration name" />
        </Form.Item>
        <Form.Item
          label="Account ID"
          name="accountNumber"
          rules={[
            {
              required: true,
              message: "Account ID is required",
            },
            {
              pattern: numberRegex.regex,
              message: numberRegex.msg,
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input placeholder="Enter Account ID" />
        </Form.Item>
        <Form.Item
          label="API Key"
          name="apiKey"
          rules={[
            {
              required: true,
              message: "API Key is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Input.Password placeholder="Enter API Key" />
        </Form.Item>
        <Form.Item
          label="Region"
          name="region"
          rules={[
            {
              required: true,
              message: "selecting a Region is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select
            placeholder="Choose Region"
            showSearch
            filterOption={(input, option) =>
              option.children.props.children[1]
                ?.toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }
          >
            {keys(flagIcons).map((option) => (
              <Select.Option key={option} value={option}>
                <div className="NewrelicCreation__region row">
                  <img src={flagIcons[option]} alt="flag" />
                  {option}
                </div>
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Form>
    </div>
  );
};

export default NewrelicCreation;
