import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";
import { v4 as uuidv4 } from "uuid";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import {
  getAllProfiles,
  deleteUser,
} from "../../../redux/actions/profilesActions";

import UsersTable from "./usersTable";
import AppBtn from "../../../shared/appBtn/appBtn";
import ConfirmationModal from "../../../shared/confirmationModal/confirmationModal";
import InviteUser from "../../../components/users/usersList/inviteUser";
import RoleAssignment from "./roleAssignment";
import SearchBox from "../../../shared/searchBox/searchBox";

import "./usersList.scss";
import { useTranslation } from "react-i18next";

const UsersList = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('users')
  const users = useSelector((state) => state.profilesReducer.profiles);
  const roles = useSelector((state) => state.profilesReducer.roles);
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);

  const [loading, setLoading] = useState(false);
  const [selectedRow, setSelectedRow] = useState(null);
  const [confirmOpen, setConfirmOpen] = useState(false);
  const [deleteInProgress, setDeleteInProgress] = useState(false);
  const [inviteModalOpen, setInviteModalOpen] = useState(false);
  const [assignModalOpen, setAssignModalOpen] = useState(false);
  const [rolesArr, setRolesArr] = useState([]);
  const [searchVal, setSearchVal] = useState("");

  useEffect(() => {
    getUsers();
  }, []);

  useEffect(() => {
    handleCreateRolesArr();
  }, [roles]);

  const handleCreateRolesArr = () => {
    let baseRoles = [
      {
        description: "All permissions",
        id: uuidv4(),
        name: "Admin",
      },
    ];

    setRolesArr([...baseRoles, ...roles]);
  };

  const getUsers = async () => {
    setLoading(true);
    await dispatch(getAllProfiles());
    setLoading(false);
  };

  // store row value in state
  const handleSelectRow = (row) => {
    return setSelectedRow(row);
  };

  const handleCloseConfiramtion = () => {
    setConfirmOpen(false);
    setSelectedRow(null);
    setDeleteInProgress(false);
  };

  const handleDeleteUser = async () => {
    setDeleteInProgress(true);
    const deleteReq = await dispatch(deleteUser(selectedRow.user_id));
    setDeleteInProgress(false);
    if (deleteReq?.ok) {
      setConfirmOpen(false);
    }
  };

  const handleCloseInviteUser = async () => {
    setInviteModalOpen(false);
    await getUsers();
  };

  const handleCloseAssigneUser = async () => {
    setAssignModalOpen(false);
    await getUsers();
  };

  // filter the user with local search

  const filteredUsers = useMemo(() => {
    if (!_.isEmpty(users) && !loading) {
      let filteredUsers = _.map(users || [], (item) => {
        const role = _.isEmpty(item?.role) ? "Admin" : item?.role[0]?.name;
        return { ...item, role };
      });

      if (searchVal) {
        filteredUsers = _.filter(filteredUsers, (item) =>
          (item.name || "").toLowerCase().includes(searchVal.toLowerCase())
        );
      }

      return filteredUsers;
    }
  }, [searchVal, users, loading]);

  return (
    <div className="UsersList col">
      <span className="title">
        {t('user-management.title', { usersCount: _.isEmpty(users) ? 0 : users.length })}
      </span>
      <div className="basic-card UsersList__body col">
        <div className="AccessKeys__body-header row between text">
          <SearchBox
            placeholder={t('user-management.search-placeholder')}
            value={(val) => setSearchVal(val)}
            resetSearch={(val) => setSearchVal(val)}
            width={400}
          />
          <AppBtn
            text={t('user-management.invite-user-btn')}
            onClick={() => setInviteModalOpen(true)}
            icon={<FontAwesomeIcon icon={faPlus} />}
            disabled={isViewer}
          />
        </div>

        <UsersTable
          loading={loading}
          data={filteredUsers}
          handleSelectRow={handleSelectRow}
          handleConfirmDelete={() => setConfirmOpen(true)}
          handleAssignRole={() => setAssignModalOpen(true)}
        />
      </div>

      <ConfirmationModal
        visible={confirmOpen}
        handleClose={handleCloseConfiramtion}
        title={
          !_.isEmpty(selectedRow) ? t('user-management.delete-modal.title', { user: selectedRow.name }) : ""
        }
        description={t('user-management.delete-modal.description')}
        loadingConfirm={deleteInProgress}
        onConfirm={handleDeleteUser}
      />

      <InviteUser
        visible={inviteModalOpen}
        handleClose={() => setInviteModalOpen(false)}
        handleCloseRefresh={handleCloseInviteUser}
        rolesArr={rolesArr}
      />

      <RoleAssignment
        visible={assignModalOpen}
        handleClose={() => setAssignModalOpen(false)}
        handleCloseRefresh={handleCloseAssigneUser}
        rolesArr={rolesArr}
        data={!_.isEmpty(selectedRow) ? selectedRow : {}}
      />
    </div>
  );
};

export default UsersList;
