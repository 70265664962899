import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_GITHUB_INTEGRATIONS = "GET_GITHUB_INTEGRATIONS";
export const GET_GITHUB_ORGANIZATIONS = "GET_GITHUB_ORGANIZATIONS";
export const CREATE_GITHUB_INTEGRATION = "CREATE_GITHUB_INTEGRATION";
export const DELETE_GITHUB_INTEGRATION = "DELETE_GITHUB_INTEGRATION";
export const EDIT_GITHUB_INTEGRATION = "EDIT_GITHUB_INTEGRATION";

const getGithubIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/github`,
      "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_GITHUB_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

const getGithubOrganizations = (access_token) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/github/orgs`,
      "POST",
      {
        access_token,
      }
    );

    const organizations = await integrationsReq.json();
    dispatch({
      type: GET_GITHUB_ORGANIZATIONS,
      payload: {},
    });
    return { req: integrationsReq, organizations };
  });
};

const createGithubIntegration = (
  name,
  organization,
  accessToken
) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/github/`,
      "POST",
      {
        name,
        organization,
        access_token: accessToken,
      }
    );

    dispatch({
      type: CREATE_GITHUB_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteGithubIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/github/${id}`,
      "DELETE"
    );

    dispatch({
      type: DELETE_GITHUB_INTEGRATION,
      payload: {},
    });
  });
};

const editGithubIntegration = (id, name, fetchable) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/github/${id}`,
      "PUT",
      { name, fetchable }
    );

    dispatch({
      type: EDIT_GITHUB_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};

export {
  getGithubIntegrations,
  getGithubOrganizations,
  editGithubIntegration,
  deleteGithubIntegration,
  createGithubIntegration,
};
