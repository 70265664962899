import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import _ from "lodash";
import { Form, Select, Checkbox } from "antd";
import { useDispatch, useSelector } from "react-redux";
import {
  getTfcIntegrations,
  getAllTfcOrganizations,
  getAllTfcOrganizationsWorkspaces,
} from "../../../redux/actions/tfcIntegrationActions";
import FormLabels from "../../../shared/formLabels/formLabels";

const Tfc = ({
  changeSelectedStateSettings,
  handleSetIsFormValid,
  setTags,
  allTags,
  currentSelectedTags,
}) => {
  const [form] = Form.useForm();
  const layout = {};
  const dispatch = useDispatch();
  const location = useLocation();

  const tfcIntegrations = useSelector(
    (state) => state.tfcIntegrationReducer.integrations
  );

  const [organizations, setOrganizations] = useState([]);
  const [organizationsWorkspaces, setOrganizationsWorkspaces] = useState([]);

  const [selectedIntegration, setSelectedIntegration] = useState("");
  const [selectedOrganization, setSelectedOrganization] = useState("");

  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingOrganization, setLoadingOrganization] = useState(false);
  const [loadingWorkspaces, setLoadingWorkspaces] = useState(false);

  const [selectedWorkspaces, setSelectedWorkspaces] = useState([]);
  // fetch TFC Integrations when starting
  useEffect(() => {
    fetchIntegrations();
  }, []);

  useEffect(() => {
    if (!_.isEmpty(location?.state?.integration) && location?.state?.type === "terraform") {
      setSelectedIntegration(location?.state?.integration);
      form.setFieldsValue({ tfcIntegration: location?.state?.integration });
    }
  }, [location?.state?.type, location?.state?.integration]);

  // fetch all TFC Organizations on Integration change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration)) {
      fetchTfcOrganizations();
    }
  }, [selectedIntegration]);

  // fetch all TFC Organizations workspaces on Integration or Organization change
  useEffect(() => {
    if (!_.isEmpty(selectedIntegration) && !_.isEmpty(selectedOrganization)) {
      fetchTfcOrganizationWorkspaces();
    }
  }, [selectedIntegration, selectedOrganization]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await dispatch(getTfcIntegrations());
    setLoadingIntegrations(false);
  };

  const fetchTfcOrganizations = async () => {
    setLoadingOrganization(true);
    const res = await dispatch(getAllTfcOrganizations(selectedIntegration));
    if (!res?.ok) {
      return setLoadingOrganization(false);
    }
    setOrganizations(res.organizations);
    setLoadingOrganization(false);
  };

  const fetchTfcOrganizationWorkspaces = async () => {
    setLoadingWorkspaces(true);
    const response = await dispatch(
      getAllTfcOrganizationsWorkspaces(
        selectedIntegration,
        selectedOrganization
      )
    );
    if (!response?.res?.ok) {
      return setLoadingWorkspaces(false);
    }
    setOrganizationsWorkspaces(response.workspaces);
    setLoadingWorkspaces(false);
  };

  const renderTfcIntegrationsOptions = () => {
    if (!_.isEmpty(tfcIntegrations)) {
      return _.map(tfcIntegrations || [], (integration) => {
        return (
          <Select.Option value={integration.id} key={integration.name}>
            {integration.name}
          </Select.Option>
        );
      });
    }
  };

  const renderTfcOrganizationsOptions = () => {
    if (!_.isEmpty(organizations.data)) {
      return _.map(organizations.data || [], (organization) => {
        return (
          <Select.Option value={organization.id} key={organization.id}>
            {organization.id}
          </Select.Option>
        );
      });
    }
  };

  const renderTfcOrganizationWorkspacesOptions = () => {
    if (!_.isEmpty(organizationsWorkspaces)) {
      return _.map(organizationsWorkspaces || [], (workspace) => {
        return (
          <Select.Option
            value={workspace?.attributes?.name}
            key={workspace?.id}
          >
            {workspace?.attributes?.name}
          </Select.Option>
        );
      });
    }
  };

  const setIsFormValidFlag = (formValues) => {
    if (
      !_.isEmpty(formValues.tfcIntegration) &&
      !_.isEmpty(formValues.tfcOrganization) &&
      !_.isEmpty(formValues.tfcOrganizationWorkspace)
    ) {
      handleSetIsFormValid(true);
    } else {
      handleSetIsFormValid(false);
    }
  };

  return (
    <Form
      {...layout}
      name="tfc-settings-form"
      form={form}
      className="StateConfiguration"
      onValuesChange={(changedValues, allValues) => {
        if (!_.isUndefined(changedValues.selectAll)) {
          let workspaceList = changedValues.selectAll
            ? _.map(organizationsWorkspaces, (item) => {
                return item?.attributes?.name;
              })
            : [];
          allValues.tfcOrganizationWorkspace = workspaceList;
          form.setFieldsValue({ tfcOrganizationWorkspace: workspaceList });
        }
        changeSelectedStateSettings(allValues);
        setIsFormValidFlag(allValues);
      }}
    >
      <Form.Item
        label="Terraform Cloud Integration"
        name="tfcIntegration"
        help={
          !loadingIntegrations && _.isEmpty(tfcIntegrations) ? (
            <Link to="/integrations/tfc-integration">
              + Add Terraform Integration
            </Link>
          ) : null
        }
        rules={[
          {
            required: true,
            message: "Terraform Cloud Integration is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select TFC Integration"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(integration) => setSelectedIntegration(integration)}
          loading={loadingIntegrations}
        >
          {renderTfcIntegrationsOptions()}
        </Select>
      </Form.Item>
      <Form.Item
        label="Terraform Cloud Organization"
        name="tfcOrganization"
        rules={[
          {
            required: true,
            message: "selecting a Terraform Cloud Organization is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select TFC Organization"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          onSelect={(organization) => setSelectedOrganization(organization)}
          loading={loadingOrganization}
        >
          {renderTfcOrganizationsOptions()}
        </Select>
      </Form.Item>

      <Form.Item
        label="Terraform Cloud Organization workspace"
        name="tfcOrganizationWorkspace"
        rules={[
          {
            required: true,
            message:
              "selecting a Terraform Cloud Organization workspace is required",
          },
        ]}
        style={{ marginBottom: "1.5rem", flexDirection: "column" }}
      >
        <Select
          placeholder="Please select Terraform Cloud Organization workspace"
          showSearch
          filterOption={(input, option) =>
            option.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
          loading={loadingWorkspaces}
          mode="tags"
          onSelect={(workspace) => {
            let tmpSelected = selectedWorkspaces;
            tmpSelected.push(workspace);
            return setSelectedWorkspaces(tmpSelected);
          }}
          onDeselect={(workspace) => {
            let tmpSelected = selectedWorkspaces;
            _.remove(tmpSelected, (item) => item === workspace);
            return setSelectedWorkspaces(tmpSelected);
          }}
        >
          {renderTfcOrganizationWorkspacesOptions()}
        </Select>
      </Form.Item>
      <Form.Item name="selectAll" valuePropName="checked">
        <Checkbox checked={false} disabled={_.isEmpty(organizationsWorkspaces)}>
          <span className="selectAll__checbox">Select All</span>
        </Checkbox>
      </Form.Item>
      <Form.Item
        label="Tags"
        style={{ marginBottom: "0", flexDirection: "column" }}
      >
        <FormLabels
          allLabels={allTags}
          selectedLabels={setTags}
          currentSelected={currentSelectedTags}
        />
      </Form.Item>
    </Form>
  );
};

export default Tfc;
