import _ from "lodash";
import React, { useState, useEffect } from "react";
import { useLocation, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";

import SuccessBox from "../../shared/successBox/successBox";
import AppWizard from "../../shared/appWizard/appWizard";
import ProviderSelection from "./providerSelection";
import StateLocation from "./stateLocation";

// import SelectStackNames from "./selectStackNames";
import {
  createS3Stack,
  createCFTStack,
  createGCSStack,
  createTFCStack,
  createConsulStack,
  createPulumiStack,
} from "../../redux/actions/stacksActions";
import { createCrawler } from "../../redux/actions/crawlerActions";

import cloudImg from "../../Images/illustrations/gradientCloud.svg";

import "./stackCreation.scss";
import { useDispatch } from "react-redux";

const StackCreation = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const location = useLocation();
  const workspaces = useSelector(
    (state) => state.tfcIntegrationReducer.workspaces
  );

  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [selectedProvider, setSelectedProvider] = useState("");
  const [selectedStateLocation, setSelectedStateLocation] = useState("");
  const [selectedStateSettings, setSelectedStateSettings] = useState("");
  const [integrationExternalId, setIntegrationExternalId] = useState("");
  const [selectedTags, setSelectedTags] = useState([]);
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const [stacksNames, setStacksNames] = useState([]);
  const [cftStacks, setCftStacks] = useState([]);
  const [cftGlobalStacks, setCftGlobalStacks] = useState([]);
  const [pulumiSelectedStacks, setPulumiSelectedStacks] = useState([]);
  const [isGlobal, setIsGlobal] = useState(false);
  const [customCurrent, setCustomCurrent] = useState(0);

  useEffect(() => {
    if (!_.isEmpty(location?.state?.type)) {
      setSelectedProvider(location?.state?.type);
      setSelectedStateLocation(location?.state?.type === "terraform" ? "tfc" : "");
      setCustomCurrent(1);
    }
  }, [location?.state?.type]);

  const configWizard = [
    {
      step_title: "Provider",
      step_description: "IaC Provider selection",
      content_title: "Choose IaC Provider",
      content: (
        <ProviderSelection
          selectedProvider={selectedProvider}
          handleSelectProvider={(provider) => setSelectedProvider(provider)}
          setSubmitDisabled={setSubmitDisabled}
        />
      ),
    },
    {
      step_title: "Settings",
      step_description: "IaC State Location",
      content_title: "Choose IaC State Location",
      content: (
        <div>
          <StateLocation
            selectedStateLocation={selectedStateLocation}
            handleSelectedStateLocation={(location) =>
              setSelectedStateLocation(location)
            }
            handleChangeSelectedStateSettings={setSelectedStateSettings}
            handleIsValidStateLocation={(isValid) => {
              setSubmitDisabled(!isValid);
            }}
            selectedProvider={selectedProvider}
            handleGetCftStacks={(cftStacks) => setCftStacks(cftStacks)}
            handleGetCftGlobalStacks={(cftGlobalStacks) =>
              setCftGlobalStacks(cftGlobalStacks)
            }
            handleIsGlobal={(isGlobal) => setIsGlobal(isGlobal)}
            handleIntegrationExternalId={(externalId) =>
              setIntegrationExternalId(externalId)
            }
            pulumiSelectedStacks={pulumiSelectedStacks}
            setPulumiSelectedStacks={(arr) => setPulumiSelectedStacks(arr)}
            setTags={(tags) => setSelectedTags(tags)}
            allTags={location?.state?.labels || []}
            currentSelectedTags={selectedTags}
          />
        </div>
      ),
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        try {
          if (selectedProvider === "cloudFormation") {
            await handleCftSubmit();
          } else if (selectedProvider === "pulumi") {
            await handlePulumiSubmit();
          } else {
            if (selectedStateLocation === "s3") {
              await handleS3Submit();
            } else if (selectedStateLocation === "gcs") {
              await handleGcsSubmit();
            } else if (selectedStateLocation === "tfc") {
              await handleTfcSubmit();
            } else if (selectedStateLocation === "consul") {
              await handleConsulSubmit();
            }
          }
        } finally {
          setLoadingSubmit(false);
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "",
      content_title: "Stack Created!",
      content: <SuccessBox setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
  ];

  const handlePulumiSubmit = async () => {
    if (selectedStateSettings?.discovery === "auto") {
      const crawler = await dispatch(
        createCrawler(
          selectedStateSettings?.pulumiIntegration,
          " ",
          "pulumi",
          "Entire project",
          selectedTags
        )
      );
      if (_.isEmpty(crawler?.crawler)) {
        throw new Error("An error occured while creating a crawler.");
      }
    } else {
      if (!_.isEmpty(pulumiSelectedStacks)) {
        await Promise.all(
          _.map(pulumiSelectedStacks || [], async (item) => {
            const res = await dispatch(
              createPulumiStack(
                "pulumi",
                item?.stackName,
                selectedTags,
                {
                  ...item,
                  pulumiIntegrationId: selectedStateSettings?.pulumiIntegration,
                },
                {
                  aws: {
                    externalId: item?.awsExternalId,
                    id: item?.awsIntegrationId,
                  },
                }
              )
            );
            if (!res?.ok) {
              throw new Error("Error while creating pulumi stack");
            }
          })
        );
      }
    }
  };

  const handleCftSubmit = async () => {
    if (isGlobal && !_.isEmpty(cftGlobalStacks)) {
      cftStackObjCreation(cftGlobalStacks);
    } else {
      cftStackCreation(selectedStateSettings.cftStacks);
    }
  };

  const cftStackObjCreation = (globalStacks) => {
    const createObjStack = async (stack) => {
      const res = await dispatch(
        createCFTStack(
          "cloudFormation",
          stack.StackName,
          selectedTags,
          selectedStateSettings.awsIntegration,
          integrationExternalId,
          stack?.region,
          stack?.StackStatus
        )
      );
      if (!res?.req?.ok) {
        throw new Error("Error while creating new stack.");
      }
    };
    for (let stack of globalStacks) {
      createObjStack(stack);
    }
  };

  const cftStackCreation = (state) => {
    const createStack = async (stackName) => {
      const match = cftStacks?.find(
        (cftStack) => cftStack?.StackName === stackName
      );
      const res = await dispatch(
        createCFTStack(
          "cloudFormation",
          stackName,
          selectedTags,
          selectedStateSettings.awsIntegration,
          integrationExternalId,
          selectedStateSettings.region,
          match?.StackStatus
        )
      );
      if (!res?.req?.ok) {
        throw new Error("Error while creating new stack.");
      }
    };
    for (let stackName of state) {
      createStack(stackName, true);
    }
  };

  const handleS3Submit = async () => {
    if (_.isEmpty(stacksNames)) {
      if (selectedStateSettings?.discovery === "auto") {
        await handleCreateCrawler("s3", "s3Bucket", "awsIntegration");
      } else {
        await Promise.all(
          _.map(selectedStateSettings.terraformStates, async (state) => {
            await s3StackCreation(state, null);
          })
        );
      }
    }
    // else {
    //   await Promise.all(
    //     _.map(stacksNames, async (stackName, state) => {
    //       await s3StackCreation(state, stackName);
    //     })
    //   );
    // }
  };

  const s3StackCreation = async (state, stackName) => {
    // create the stack itself
    if (_.isEmpty(stackName)) {
      stackName = state;
    }
    const res = await dispatch(
      createS3Stack(
        "terraform",
        stackName,
        selectedTags,
        selectedStateSettings.awsIntegration,
        selectedStateSettings.s3Bucket,
        state
      )
    );

    if (!res?.ok) {
      throw new Error("Error while creating stack");
    }
  };

  const handleGcsSubmit = async () => {
    if (_.isEmpty(stacksNames)) {
      if (selectedStateSettings?.discovery === "auto") {
        await handleCreateCrawler("gcs", "gcsBucket", "gcpIntegration");
      } else {
        await Promise.all(
          _.map(selectedStateSettings.terraformStates, async (state) => {
            await gcsStackCreation(state, null);
          })
        );
      }
    }
  };

  const gcsStackCreation = async (state, stackName) => {
    // create the stack itself
    if (_.isEmpty(stackName)) {
      stackName = state;
    }
    const res = await dispatch(
      createGCSStack(
        "terraform",
        stackName,
        selectedTags,
        selectedStateSettings.gcpIntegration,
        selectedStateSettings.gcsBucket,
        state
      )
    );

    if (!res?.ok) {
      throw new Error("Error while creating stack");
    }
  };

  const handleTfcSubmit = async () => {
    if (_.isEmpty(stacksNames)) {
      await Promise.all(
        _.map(selectedStateSettings?.tfcOrganizationWorkspace, async (item) => {
          await tfcStackCreation(item, null);
        })
      );
    } else {
      _.forEach(stacksNames, async (stackName, state) => {
        await tfcStackCreation(state, stackName);
      });
    }
  };

  const tfcStackCreation = async (workspace, stackName) => {
    // create the stack itself

    let workspaceObj = "";
    if (!_.isEmpty(workspaces)) {
      workspaceObj = workspaces.find(
        (item) => item?.attributes?.name === workspace
      );
    }

    const res = await dispatch(
      createTFCStack(
        "terraform",
        workspace,
        selectedTags,
        selectedStateSettings.tfcIntegration,
        selectedStateSettings.tfcOrganization,
        workspaceObj?.id
      )
    );

    if (!res?.ok) {
      throw new Error("Error while creating stack");
    }
  };

  const handleConsulSubmit = async () => {
    if (_.isEmpty(stacksNames)) {
      if (selectedStateSettings?.discovery === "auto") {
        await handleCreateCrawler("consul", "dataCenter", "consulIntegration");
      } else {
        await Promise.all(
          _.map(selectedStateSettings?.terraformStates, async (item) => {
            await consulStackCreation(item, null);
          })
        );
      }
    } else {
      _.forEach(stacksNames, async (stackName, state) => {
        await consulStackCreation(state, stackName);
      });
    }
  };

  const consulStackCreation = async (state, stackName) => {
    // create the stack itself
    if (_.isEmpty(stackName)) {
      stackName = state;
    }

    const res = await dispatch(
      createConsulStack(
        "terraform",
        stackName,
        selectedTags,
        selectedStateSettings.consulIntegration,
        selectedStateSettings.dataCenter,
        state
      )
    );
    if (!res?.ok) {
      throw new Error("Error while creating new stack");
    }
  };

  const handleCreateCrawler = async (type, bucketKey, integrationKey) => {
    const prefix =
      selectedStateSettings?.terraformFolder === "Entire Bucket"
        ? ""
        : selectedStateSettings?.terraformFolder;

    const crawler = await dispatch(
      createCrawler(
        selectedStateSettings[integrationKey],
        selectedStateSettings[bucketKey],
        type,
        prefix,
        selectedTags
      )
    );

    if (_.isEmpty(crawler?.crawler)) {
      throw new Error("An error occured while updating state policy.");
    }
  };

  return (
    <div className="StackCreation">
      <AppWizard
        configWizard={configWizard}
        loadingSubmit={loadingSubmit}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        // if there are stacks or not in this environment
        handleClose={() => history.push("/iac")}
        handleSubmit={() => history.push("/iac")}
        iconSrc={cloudImg}
        iconStyle={{ width: "50px" }}
        customCurrent={customCurrent}
      />
    </div>
  );
};

export default StackCreation;
