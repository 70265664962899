import {
  GET_IAC_POLICIES,
  DELETE_IAC_POLICY,
  UPDATE_IAC_POLICY,
  CREATE_IAC_POLICY,
} from "../actions/iacPolicyActions";
import _ from "lodash";

const initialState = {
  iacPolicies: [],
};

// data
export default (state = initialState, { type, payload }) => {
  switch (type) {
    case GET_IAC_POLICIES:
      return { ...state, iacPolicies: payload.data };

    case DELETE_IAC_POLICY:
      return { ...state };

    case UPDATE_IAC_POLICY:
      return { ...state };

    case CREATE_IAC_POLICY:
      return { ...state };

    default:
      return state;
  }
};
