import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Form, Select } from "antd";
import { exportIacStacks } from "../../../redux/actions/iacStacksActions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload, faFileCsv } from "@fortawesome/free-solid-svg-icons";
import AppModal from "../../../shared/appModal/appModal";

import "./exportModal.scss";

const ExportModal = ({ visible, handleClose, tableSorting, selectedIacType }) => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const [loadingExport, setLoadingExport] = useState(false);
  const searchVal = useSelector((state) => state.globalAppReducer.searchVal);
  const screenFilters = useSelector(
    (state) => state.iacStacksReducer.iacStacksFilters
  );

  const handleSubmitExport = async (e) => {
    setLoadingExport(true);
    const fileFormat = form.getFieldValue("format");
    const res = await dispatch(
      exportIacStacks(
        fileFormat,
        screenFilters,
        searchVal,
        tableSorting,
        selectedIacType
      )
    );
    if (!res?.req?.ok) {
      return setLoadingExport(false);
    }

    if (fileFormat === "csv") {
      const fileName = "IacStacks";
      const downloadUrl = URL.createObjectURL(res?.result);
      const link = document.createElement("a");
      link.href = downloadUrl;
      link.setAttribute("download", fileName); //any other extension
      document.body.appendChild(link);
      link.click();
      link.remove();
    } else {
      e.preventDefault();
      const blob = new Blob([JSON.stringify(res?.result, null, 2)], {
        type: "text/json",
      });
      const a = document.createElement("a");
      a.download = "IacStacks.json";
      a.href = window.URL.createObjectURL(blob);
      const clickEvt = new MouseEvent("click", {
        view: window,
        bubbles: true,
        cancelable: true,
      });
      a.dispatchEvent(clickEvt);
      a.remove();
    }

    setLoadingExport(false);
    handleClose();
    form.resetFields();
  };

  return (
    <AppModal
      visible={visible}
      handleClose={handleClose}
      title="Iac Stacks Export"
      handleOnSubmit={handleSubmitExport}
      loadingSubmit={loadingExport}
      form="export-iac-form"
      submitBtnIcon={
        <FontAwesomeIcon icon={faDownload} style={{ marginRight: "10px" }} />
      }
      submitBtnText="Export"
      subtitle="The Iac Stacks export will include up to 10,000 stacks, according to the applied filters."
    >
      <Form
        name="export-iac-form"
        form={form}
        className="ExportModal"
        initialValues={{
          format: "csv",
        }}
      >
        <Form.Item
          label="File Type"
          name="format"
          rules={[
            {
              required: true,
              message: "File Format is required",
            },
          ]}
          style={{ marginBottom: "1.5rem", flexDirection: "column" }}
        >
          <Select>
            <Select.Option value={"csv"} key="csv">
              <span className="ExportModal-select row">
                <FontAwesomeIcon icon={faFileCsv} />
                CSV
              </span>
            </Select.Option>
            <Select.Option value={"json"} key="json">
              <span>{`{ } JSON`}</span>
            </Select.Option>
          </Select>
        </Form.Item>
      </Form>
    </AppModal>
  );
};

export default ExportModal;
