import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPlus } from "@fortawesome/free-solid-svg-icons";

import SingleNotification from "./singleNotification/singleNotification";
import AppBtn from "../../../shared/appBtn/appBtn";

import "./notificationList.scss";
import { useTranslation } from "react-i18next";
import { capitalize, filter, isUndefined, sortBy, union } from "lodash";
import AppPopoverSelect from "../../../shared/appPopoverSelect/appPopoverSelect";
import { Button } from "antd";
import { getAllProvidersArray } from "../../../utils/formatting";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import UserIcon from "../../../Images/general_icons/user_icon.svg";
import { integrationIcons } from "../../../utils/icons";
import SearchBox from "../../../shared/searchBox/searchBox";
import { PROVIDERS_TITLE_MAP } from "../../../consts/general";

const NotificationList = ({
  handleAddNotification,
  notificationTypesIndex,
  loadingNotificationsInteg,
  loadingProviders,
  loadingSlackChannels,
  loadingInsights,
  getUserNotifications,
  onEditNotificationData
}) => {
  const { t } = useTranslation('notifications')
  const notifications = useSelector(
    (state) => state.notificationsReducer.notifications
  );

  const [editModalOpen, setEditModalOpen] = useState(false);
  const [searchVal, setSearchVal] = useState("")
  const [allUsers, setAllUsers] = useState([])
  const [filteredUsers, setFilteredUsers] = useState([])
  const [allDataSources, setAllDataSources] = useState([])
  const [allDestinations, setAllDestinations] = useState([])
  const [filteredDestinations, setFilteredDestinations] = useState([])
  const [filteredDataSources, setFilteredDataSources] = useState([])
  const [allProviders, setAllProviders] = useState([])
  const isViewer = useSelector((state) => state.profilesReducer.isViewer);
  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  useEffect(() => {
    const tempAllUsers = [];
    const tempAllDestinations = [];
      if(!isEmpty(providerIntegrations)){
        setAllProviders(getAllProvidersArray(providerIntegrations))
        setAllDataSources(Object.keys(providerIntegrations))
      }
      if(!isEmpty(notifications)){
        notifications.forEach(notification => {
          if (!isEmpty(notification?.name)) {
            tempAllUsers.push(notification.name);
          }
          if (!isEmpty(notification?.notificationIntegrations)) {
            notification?.notificationIntegrations?.forEach(integration => tempAllDestinations.push(integration?.service))
          }
        });
        setAllUsers(union(tempAllUsers.flat(1)));
        setAllDestinations(filter(union(tempAllDestinations.flat(1)), value => !isUndefined(value)));
      }
  }, [providerIntegrations])
  const filteredNotifications = useMemo(() => {
    if (!isEmpty(notifications)) {
      let filteredNotifications = [...notifications];
      if (searchVal) {
        filteredNotifications = filter(filteredNotifications, (item) =>
          (item.displayName || "").toLowerCase().includes(searchVal.toLowerCase())
        );
      }

      if (!isEmpty(filteredDataSources)) {
        filteredNotifications = filteredNotifications.filter((item) => {
          if(isEmpty(item.scope.providers)){
            return false;
          }else{
            return item.scope?.providers?.some(providerId => {
              const matchedProvider = allProviders.find(provider => provider.id === providerId);
              return filteredDataSources.includes(matchedProvider?.provider);
            });
          }
        });
      }
      if (!isEmpty(filteredDestinations)) {
        filteredNotifications = filteredNotifications.filter((item) => {
          return  item.notificationIntegrations.some(integration =>
            filteredDestinations.includes(integration.service));
        });
      }
      if (!isEmpty(filteredUsers)) {
        filteredNotifications = filteredNotifications.filter((item) => {
          return filteredUsers.includes(item?.name);
        });
      }

      return filteredNotifications;
    }
    return []
  }, [searchVal,filteredUsers, filteredDataSources, filteredDestinations,loadingNotificationsInteg, notifications]);
  
  const clearAllFilters = () => {
    setFilteredUsers([]);
    setFilteredDataSources([]);
    setFilteredDestinations([]);
    setSearchVal("");
  }

  const renderList = () => {
    if (!isEmpty(filteredNotifications)) {
      return map(sortBy(filteredNotifications, 'createdAt').reverse() || [], (notification) => {
        return (
          <SingleNotification
            key={notification._id}
            data={notification}
            notificationTypesIndex={notificationTypesIndex}
            loadingNotificationsInteg={loadingNotificationsInteg}
            loadingProviders={loadingProviders}
            setEditNotificationData={onEditNotificationData}
            setEditModalOpen={setEditModalOpen}
          />
        );
      });
    }
  };

  return (
    <div className="NotificationList col">
      <div className="NotificationList__header row between">
        <span className="title">{t('title', { count: notifications?.length })} </span>
      </div>
      <div className="NotificationList__filter-wrapper">
        <span>Filter by:</span>
        <div className="NotificationList__filter-wrapper_dropdowns">
          <AppPopoverSelect
            label="Created by"
            options={allUsers.map(user => ({
              label: <span className="AppPopoverSelect_content-list-withIcon" style={{ color: "#f00000" }} key={user}><img src={UserIcon} alt="user icon" width={12} />
                {capitalize(user)}</span>, value: user
            }))}

            checkedOptions={filteredUsers}
            onSubmit={selectedUsers => {
              setFilteredUsers(selectedUsers);
            }}
          />
          <AppPopoverSelect
            label="Data Source"
            options={allDataSources.map(ds => ({ label: <span className="AppPopoverSelect_content-list-withIcon" style={{ color: "#f00000" }} key={ds}><ProviderIcon provider={ds} customStyle="notification" /> {PROVIDERS_TITLE_MAP[ds] || capitalize(ds)}</span>, value: ds }))}
            checkedOptions={filteredDataSources}
            onSubmit={selectedDataSources => setFilteredDataSources(selectedDataSources)}
          />
          <AppPopoverSelect
            label="Destination"
            options={allDestinations.map(destination => ({
              label: <span className="AppPopoverSelect_content-list-withIcon" style={{ color: "#f00000" }} key={destination}>
                <img
                  src={integrationIcons[destination]}
                  alt="integration icon"
                />
                {capitalize(destination)}</span>, value: destination
            }))}
            checkedOptions={filteredDestinations}
            onSubmit={selectedDestinations => setFilteredDestinations(selectedDestinations)}
          />

          <span className="divider"></span>
        </div>
        <Button type="link" className="clear-btn" onClick={clearAllFilters}>Clear all</Button>
      </div>
      <div className="NotificationList__search-wrapper row between">
        <SearchBox
          placeholder={t('search-placeholder')}
          value={(val) => setSearchVal(val)}
          resetSearch={(val) => setSearchVal(val)}
          darkMode
          width={320}
        />
        <AppBtn
          text={t('add-new-btn')}
          onClick={handleAddNotification}
          disabled={isViewer}
          icon={<FontAwesomeIcon icon={faPlus} />}
        />
      </div>
      <div className="NotificationList__list col">{renderList()}</div>
      {/* <CreateNotification
        visible={editModalOpen}
        handleClose={() => setEditModalOpen(false)}
        notificationTypesIndex={notificationTypesIndex}
        loadingNotificationsInteg={loadingNotificationsInteg}
        loadingProviders={loadingProviders}
        loadingSlackChannels={loadingSlackChannels}
        loadingInsights={loadingInsights}
        handleCloseRefresh={() => {
          setEditModalOpen(false);
          getUserNotifications();
        }}
        isEditMode
        rowData={editNotificationData}
      /> */}
    </div>
  );
};

export default NotificationList;
