import React, { useEffect, useState } from "react";
import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import _ from "lodash";

import { clouds } from "../../../../utils/icons";
import AppWizard from "../../../../shared/appWizard/appWizard";
import AwsOverview from "./awsOverview";
import AwsIntegrationConfiguration from "./awsIntegrationConfiguration";
import AwsFinalInstructions from "./awsFinalInstructions";
import {
  createKeyPair,
} from "../../../../redux/actions/keyPairsActions";

import "./awsIntegration.scss";

const AwsIntegration = () => {
  const history = useHistory();
  const dispatch = useDispatch();
  const [wizardState, setWizardState] = useState({});
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [previousDisabled, setPreviousDisabled] = useState(false);
  const [isProd, setIsProd] = useState(false);

  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  useEffect(() => {
    setPreviousDisabled(false);
  }, []);

  const createNewKeyPair = async () => {
    setWizardState(Object.assign(wizardState, await dispatch(createKeyPair())));
  };

  const configWizard = [
    {
      step_title: "Overview",
      step_description: "Information and requirements",
      content_title: "AWS Integration Overview (Pulumi Provider)",
      content: <AwsOverview setSubmitDisabled={setSubmitDisabled} />,
      valid: true,
    },
    {
      step_title: "Configuration",
      step_description: "Modify default configuration values",
      content_title: "Pulumi Provider's Configuration",
      content: (
        <AwsIntegrationConfiguration
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          isProd={isProd}
          handelSetIsProd={() => setIsProd(!isProd)}
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        setLoadingSubmit(true);
        setPreviousDisabled(true);

        if (wizardState?.accessKeyType === "new") {
          await createNewKeyPair();
          setLoadingSubmit(false);
          if (_.isEmpty(wizardState.accessKey)) {
            throw new Error(
              "Failed to create access key pair. Please try again"
            );
          }
        } else {
          // if user selected a prev acess key
          setLoadingSubmit(false);
          setWizardState({...wizardState, accessKey: wizardState?.selectedKey, secretKey: 'YOUR SECRET KEY'});
        }
      },
    },
    {
      step_title: "Completion",
      step_description: "Final Instructions",
      content_title: "Final Instructions",
      content: (
        <AwsFinalInstructions
          wizardState={wizardState}
          setSubmitDisabled={setSubmitDisabled}
          isProd={isProd}
        />
      ),
      valid: true,
    },
  ];

  return (
    <div className="AwsIntegration">
      <AppWizard
        iconSrc={clouds("aws", themeDark)}
        iconStyle={{ height: "35px", transform: "translateY(2px)" }}
        loadingSubmit={loadingSubmit}
        configWizard={configWizard}
        handleClose={() => history.goBack()}
        submitDisabled={submitDisabled}
        setSubmitDisabled={setSubmitDisabled}
        handleSubmit={() => history.push("/integrations")}
        prevDisabled={previousDisabled}
      />
    </div>
  );
};

export default AwsIntegration;
