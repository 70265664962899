import React from "react";
import { TreeSelect } from "antd";
import _ from "lodash";
import {
  providerNameFormatter,
  formatAwsStringWithUnderscore,
  providerAssetImagesFormatter
} from "../../../utils/formatting";
import ProviderIcon from "../../../shared/providerIcon/providerIcon";
import AssetTypeImage from "../../../shared/assetTypeImage/assetTypeImage";

const AssetTypeSelect = ({ selected, onChange, loading, types, readOnly,maxTagCount,showCheckedStrategy="SHOW_PARENT",getPopupContainer }) => {

  const formattedTreeData = () => {
    if (loading || _.isEmpty(types)) {
      return [];
    }

    const transformedTypes = [];
    for (const typeName in types) {
      const typesData = types[typeName];
      if (typesData.length > 0) {
        const cloud = {
          title: (
            <span className="row g10">
              <div style={{ width: "26px" }} className="center">
                <ProviderIcon provider={typeName} customStyle="inventory" />
              </div>
              {providerNameFormatter(typeName)}
            </span>
          ),
          value: `${typeName}objects`,
          key: `${typeName}objects`,
          children: [],
        };
        for (const data of typesData) {
          const child = {
            title: (
              <span className="row g10">
                <div
                  style={{ overflow: "hidden", borderRadius: "4px" }}
                  className="center"
                >
                  <AssetTypeImage
                    assetType={data}
                    provider={providerAssetImagesFormatter(typeName)}
                    customStyle={{ height: "20px" }}
                  />
                </div>
                {formatAwsStringWithUnderscore(data)}
              </span>
            ),
            value: data,
            key: data,
          };
          cloud.children.push(child);
        }
        transformedTypes.push(cloud);
      }
    }
    return transformedTypes;
  };

  return (
    <TreeSelect
      getPopupContainer={getPopupContainer}
      maxTagCount={maxTagCount}
      treeData={formattedTreeData()}
      value={selected}
      onChange={onChange}
      treeCheckable
      showCheckedStrategy={showCheckedStrategy}
      placeholder="Select asset types"
      showArrow
      style={{
        width: "100%",
      }}
      multiple
      filterTreeNode={(search, item) => {
        if (!_.isString(item?.title)) {
          return (
            item?.title?.props?.children[1]
              .toLowerCase()
              .indexOf(search.toLowerCase()) >= 0
          );
        }
        return item?.title.toLowerCase().indexOf(search.toLowerCase()) >= 0;
      }}
      loading={loading}
      disabled={readOnly}
    />
  );
};

export default AssetTypeSelect;
