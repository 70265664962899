import React from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { formatIacStatusTitle } from '../../../../../utils/formatting';
import moment from 'moment';


const colors = {
    unmanaged: "#D76089", //Unmanaged
    managed: "#67CEBC", //Codified
    ghost: "#F4B483", //Ghost
    modified: "#9872FE", //Drifted,
    deleted: "#a8a8a8a9"
};

const RevisionStateChange = ({ asset_compare, selectedRevision }) => {


    const renderStateIcon = (state) => {
        const iconStyle = { fontSize: 22 };
        switch (state) {
            case "unmanaged":
                return <FontAwesomeIcon icon="exclamation-circle" style={iconStyle} />;
            case "managed":
                return <FontAwesomeIcon icon="code" style={iconStyle} />;
            case "modified":
                return <FontAwesomeIcon icon="code-branch" style={iconStyle} />;
            case "ghost":
                return <FontAwesomeIcon icon="ghost" style={iconStyle} />;
            case "deleted":
                return <FontAwesomeIcon icon="trash" style={iconStyle} />;
            default:
                return <FontAwesomeIcon icon="question" style={iconStyle} />;
        }
    };

    const renderArrow = () => {
        return <svg
            width="390"
            height="12"
            viewBox="0 0 400 12"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
        >
            <line
                x1="0"
                y1="6"
                x2="390"
                y2="6"
                stroke={`url(#paint0_linear_6745_12866${0})`}
                strokeWidth="2"
                strokeDasharray="4.6 4.6"
            />
            <defs>
                <linearGradient
                    id={`paint0_linear_6745_12866${0}`}
                    x1="400"
                    y1="5"
                    x2="-9.5"
                    y2="5"
                    gradientUnits="userSpaceOnUse"
                >
                    <stop stopColor={colors[asset_compare.revision2]} />
                    <stop offset="1" stopColor={colors[asset_compare.revision1]} />
                </linearGradient>
            </defs>
            <path
                d="M400 6L390.803847 0.803847L390.803847 11.1962L400 6Z"
                fill={colors[asset_compare.revision2]}
            />
        </svg>


    }

    return (<div className="RevisionStateChange">
        <div className="RevisionStateChange__diff">
            <div className="RevisionStateChange__diff-title row">
                <div className="RevisionStateChange__diff-title-block center">
                    {` ${selectedRevision?.timeEpoch
                        ? moment(selectedRevision?.timeEpoch).format("MM.DD.YYYY hh:mm")
                        : ""
                        }`}
                </div>
                <div className="RevisionStateChange__diff-title-block center">
                    Current Configuration
                </div>
            </div>

        </div>
        <div className="RevisionStateChange__diff-state">
            <div>
                <div className="RevisionStateChange__diff-state-title">IaC status changed</div>
                <div className="RevisionStateChange__diff-state-change">
                    <div className={`RevisionStateChange__diff-state-change-flag ${asset_compare.revision1}`}>
                        <div>
                            {renderStateIcon(asset_compare.revision1)}
                        </div>
                        <span>{formatIacStatusTitle(asset_compare.revision1)}</span>
                    </div>
                    {renderArrow()}
                    <div className={`RevisionStateChange__diff-state-change-flag ${asset_compare.revision2}`}>
                        <div>
                            {renderStateIcon(asset_compare.revision2)}
                        </div>
                        <span>{formatIacStatusTitle(asset_compare.revision2)}</span>
                    </div>
                </div>
            </div>
        </div>

    </div>);
}

export default RevisionStateChange;