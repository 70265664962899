import React from "react";
import { useDispatch } from "react-redux";
import { useHistory } from "react-router-dom";
import isEmpty from "lodash/isEmpty";
import map from "lodash/map";
import size from "lodash/size";
import { Tooltip } from "antd";
import { emptyIacScreenFilters } from "../../../consts/iacStacks";
import { setFilters } from "../../../redux/actions/iacStacksActions";

const AssetStacksCell = ({ data }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const handleNavigate = (id, type) => {
    dispatch(
      setFilters({
        ...emptyIacScreenFilters,
        stackId: [id],
      })
    );
    history.push({
      pathname: `/iac`,
      search: `?view=${type}`,
    });
  };

  // if no stacks
  if (isEmpty(data?.stacks)) {
    return null;
  }

  // if only 1 stack
  if (size(data?.stacks) === 1) {
    return (
      <span
        className="underline"
        style={{ cursor: "pointer" }}
        onClick={() =>
          handleNavigate(data?.stacks[0]?.stackId, data?.stacks[0]?.stackType)
        }
      >
        {data?.stacks[0]?.stackName}
      </span>
    );
  }

  return (
    <Tooltip
      placement="top"
      overlayInnerStyle={{
        width: "220px",
      }}
      title={
        <div className="col g10">
          {map(data?.stacks || [], (s) => {
            return (
              <span
                className="underline"
                key={s?.stackId}
                style={{ cursor: "pointer" }}
                onClick={() => handleNavigate(s?.stackId, s?.stackType)}
              >
                {s?.stackName}
              </span>
            );
          })}
        </div>
      }
    >
      <span>{`${data?.stacks?.length} Stacks`}</span>
    </Tooltip>
  );
};

export default AssetStacksCell;
