import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import { clouds } from "../../utils/icons";

import "./providerIcon.scss";

const ProviderIcon = ({ provider, customStyle, dark, hover }) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );
  const getCustomStyle = () => {
    switch (customStyle) {
      case "integration":
        return {
          height: "100%",
          transform: provider === "aws" ? "translateY(4px)" : "translateY(2px)",
          opacity: hover ? ".6" : "1",
          transition: "all .3s ease"
        };

      case "tree":
        return {
          maxHeight:
            provider === "aws" ? "12px" : provider === "okta" ? "9px" : "15px",
          marginLeft: "2px",
          transform: provider === "aws" ? "translateY(1px)" : "translateY(0px)",
        };

      case "inventory":
        return {
          width: "auto",
          height: "16px",
          transition: "filter 0.2s ease",
          transform:
            provider === "aws" || provider === "okta"
              ? "translateY(-1px)"
              : "translateY(0px)",
          maxHeight:
            provider === "aws"
              ? "12px"
              : provider === "k8s"
              ? "17px"
              : provider === "okta"
              ? "9px"
              : "16px",
        };

      case "children":
        return {
          height:
            provider === "aws" ? "15px" : provider === "okta" ? "11px" : "19px",
          marginRight: "12px",
        };

      case "header":
        return {
          maxHeight:
            provider === "aws" ? "12px" : provider === "okta" ? "9px" : "15px",
          transform:
            provider === "aws" || provider === "okta"
              ? "translateY(-1px)"
              : "translateY(0px)",
        };

      case "insight":
        return {
          maxHeight:
            provider === "aws" ? "12px" : provider === "okta" ? "9px" : "15px",
          transform:
            provider === "aws" || provider === "okta"
              ? "translateY(1px)"
              : "translateY(0px)",
        };

      case "drift":
        return {
          width: provider === "aws" ? "20px" : "auto",
          height: provider === "aws" ? "auto" : "12px",
          transform:
            provider === "aws" || provider === "okta"
              ? "translateY(2px)"
              : "translateY(0px)",
        };

      case "excluded":
        return {
          width: "auto",
          height: provider === "okta" ? "11px" : "14px",
          transform: provider === "aws" ? "translateY(2px)" : "translateY(0px)",
        };

      case "notification":
        return {
          width: "auto",
          height: provider === "okta" ? "9px" : "14px",
        };

      case "drawer":
        return {
          width: "auto",
          height: "20px",
        };

      case "backends":
        return {
          width: "18px",
          height: "auto",
        };

      case "modal":
          return {
            width: "auto",
            height: "30px",
          };

      default:
        return {};
    }
  };
  return (
    <img
      alt="cloud"
      src={clouds(dark ? `${provider}Dark` : provider, themeDark)}
      style={getCustomStyle()}
      className={`ProviderIcon-${provider}`}
    />
  );
};

export default ProviderIcon;
