  import {
    GET_CLOUDFLARE_INTEGRATIONS
} from "../actions/cloudFlareIntegrationActions";

const initialState = {
  integrations: [],
};

export default function (state = initialState, { type, payload }) {
  switch (type) {
    case GET_CLOUDFLARE_INTEGRATIONS:
      return { ...state, integrations: payload };

    default:
      return state;
  }
}
