import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faCodeBranch,
  faGhost,
  faExclamationCircle
} from "@fortawesome/free-solid-svg-icons";
import insightPic from '../../../Images/general_icons/orange_insight.svg';

import "./driftTypeIcon.scss";

const DriftTypeIcon = ({ type, small }) => {
  const renderIconByType = () => {
    switch (type) {
      case "unmanaged":
        return <FontAwesomeIcon icon={faExclamationCircle} />;
      case "modified":
        return <FontAwesomeIcon icon={faCodeBranch} />;
      case "ghost":
        return <FontAwesomeIcon icon={faGhost} />;
      case "insight":
        return <img src={insightPic} alt="insightPic" />;
      default:
        break;
    }
  };

  return (
    <div className={`DriftTypeIcon center ${type} ${small ? 'small' : null}`}>{renderIconByType()}</div>
  );
};

export default DriftTypeIcon;
