import React from 'react';
import AppModal from '../../../../shared/appModal/appModal';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useTranslation } from 'react-i18next';
import { Select } from 'antd';
import map from 'lodash/map';
import { useDispatch, useSelector } from 'react-redux';
import { getAllSlackChannels, updateIntegrationsById } from '../../../../redux/actions/integrationsActions';
import { sendTestNotification } from '../../../../redux/actions/notificationsActions';
import { appToast } from '../../../../shared/appToast/appToast';
import { sendEvent } from '../../../../utils/amplitude';
import { integrationEvents } from '../../../../utils/amplitudeEvents';

const SlackChannelsPopup = ({ visible, selectedSlackApp = {}, setSelectedSlackApp = () => null, setShowSlackChannelsPopup = () => null, testNotificationLoading = {}, setTestNotificationLoading = () => null }) => {
    const { t } = useTranslation('integrations');
    const slackChannels = useSelector((state) => state.integrationsReducer.channels);
    const dispatch = useDispatch();

    const handleSendTestNotification = async(item = {}) => {
      const notificationIntegrationId = item._id;
      const payload = { notificationIntegrationId: notificationIntegrationId }
      setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: true });
      if (item.service === "slackApp") {
        if (item.slackChannel) {
          payload.slackChannel = item.slackChannel;
        } else {
          await dispatch(getAllSlackChannels(notificationIntegrationId));
          setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
          setSelectedSlackApp(item);
          return setShowSlackChannelsPopup(true);
        }
      }
      const response = await dispatch(sendTestNotification(payload));
      setTestNotificationLoading({ ...testNotificationLoading, [notificationIntegrationId]: false});
      
      const { status = { lastState: 'unhealthy'} } = response || {};
      dispatch(updateIntegrationsById(notificationIntegrationId, { status }));
      const lastState = status.lastState;
      const isSuccessfulTest = lastState === "healthy";
      sendEvent(integrationEvents.sendTestNotification, { integrationId: notificationIntegrationId, integrationName: item.name,  integrationType: item.service, success: isSuccessfulTest })
      if (isSuccessfulTest) {
        return appToast("success", "", t("notification-heartbeat.alerts.success"))
      }
      if (!isSuccessfulTest && status.error) {
        return appToast("error", "", `${t("notification-heartbeat.alerts.failure")} - ${status.error}`)
      }
    }

    const renderSlackChannels = () => (
        <div className="SingleIntegrationItem-slackChannels col g10">
          <div className="row g5">
            <FontAwesomeIcon className="SingleIntegrationItem-slackChannels-icon" icon="info-circle"/>
            <span className="SingleIntegrationItem-slackChannels-desc">{t("notification-heartbeat.slackAppDisclaimer")}</span>
          </div>
          <Select
            className="SingleIntegrationItem-slackChannels-select"
            placeholder="Select channel"
            showArrow
            value={selectedSlackApp?.slackChannel}
            showSearch
            onSelect={(value) => setSelectedSlackApp({...selectedSlackApp, slackChannel: value})}
            filterOption={(input, option) =>
              option?.children?.props?.children[1]
                .toLowerCase()
                .indexOf(input.toLowerCase()) >= 0
            }>
          {map(slackChannels || [], (channel) => (
              <Select.Option value={channel.id} key={channel.id}>
                <span>
                  {`# `}
                  {channel.name}
                </span>
              </Select.Option>))}
          </Select>
        </div>
    )
    
    return (
        <AppModal 
          visible={visible}
          title="Select a Slack channel"
          handleClose={() => {
            setShowSlackChannelsPopup(false);
            setSelectedSlackApp({});
          }}
          handleOnSubmit={() =>{
            handleSendTestNotification(selectedSlackApp);
            setShowSlackChannelsPopup(false);
          }}
          submitBtnDisabled={!selectedSlackApp?.slackChannel}
          submitBtnText="Send Notification">
            {renderSlackChannels({})}
        </AppModal>
    );
};

export default SlackChannelsPopup;