import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import { Form, Button } from "antd";
import map from "lodash/map";
import isEmpty from "lodash/isEmpty";
import isFunction from "lodash/isFunction";
import AppModalWizard from "../../../shared/appModalWizard/appModalWizard";
import { appToast } from "../../../shared/appToast/appToast";
import IssueConfiguration from "./issueConfiguration";
import RequiredFields from "./requiredFields";
import {
  getJiraDomainMetadata,
  createJiraTicket,
} from "../../../redux/actions/jiraIntegrationActions";
import { getAllClouds } from "../../../redux/actions/globalAppActions";
import { projects } from "../../../utils/icons";
import { emptyInventoryScreenFilters } from "../../../consts/inventory";
import { getAllProvidersArray } from "../../../utils/formatting";
import "./issueModal.scss";

const IssueModal = ({
  visible,
  handleClose,
  selectedResources,
  insights,
  insightsTotal,
  noJiraIntegrations,
  selectedInsight,
  driftDrawer,
  code,
  isPropertyExcluded,
}) => {
  const dispatch = useDispatch();
  const { user } = useAuth0();

  const [allProviderIntegrations, setAllProviderIntegrations] = useState([]);
  const [loadingIntegrations, setLoadingIntegrations] = useState(false);
  const [loadingMetadata, setLoadingMetadata] = useState(false);
  const [loadingMetadataForSearch, setLoadingMetadataForSearch] =
    useState(false);
  const [integrationMetadata, setIntegrationMetadata] = useState(null);
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [submitDisabled, setSubmitDisabled] = useState(false);
  const [wizardState, setWizardState] = useState({});
  const [extraFields, setExtraFields] = useState([]);
  const [requiredFields, setRequiredFields] = useState({});
  const [current, setCurrent] = useState(0);

  const providerIntegrations = useSelector(
    (state) => state.globalAppReducer.clouds
  );

  const jiraIntegrations = useSelector(
    (state) => state.jiraIntegrationReducer.integrations
  );

  const [form] = Form.useForm();

  useEffect(() => {
    if (visible) {
      setAllProviderIntegrations([]);
      setLoadingSubmit(false);
      setWizardState({});
      setExtraFields([]);
      setRequiredFields({});
      setCurrent(0);
      fetchIntegrations();
    }
  }, [visible]);

  const fetchIntegrations = async () => {
    setLoadingIntegrations(true);
    await Promise.all([dispatch(getAllClouds())]);
    getDriftTable();
    setAllProviderIntegrations(getAllProvidersArray(providerIntegrations));
    setLoadingIntegrations(false);
  };

  const fetchIntegrationMetadata = async (integration) => {
    setLoadingMetadata(true);
    const res = await dispatch(getJiraDomainMetadata(integration));
    if (!res?.req?.ok) {
      return setLoadingMetadata(false);
    }
    setIntegrationMetadata(res?.data);
    setLoadingMetadata(false);
  };

  const fetchIntegrationMetadataForSearch = async (integration, val) => {
    setLoadingMetadataForSearch(true);
    const res = await dispatch(getJiraDomainMetadata(integration, val));
    if (!res?.req?.ok) {
      return setLoadingMetadataForSearch(false);
    }
    setIntegrationMetadata(res?.data);
    setLoadingMetadataForSearch(false);
  };

  const formatTags = (mapStr) => {
    // remove map[...] from string
    let newString = mapStr.substring(4);
    newString = newString.substring(0, newString.length - 1);
    // remove spaces around commas
    newString = newString.replaceAll(" ,", ",");
    newString = newString.replaceAll(", ", ",");
    let stringArr = newString.split(" ");
    let returnString = "";
    map(stringArr, (pairStr, index) => {
      returnString += pairStr;
      if (index !== stringArr?.length - 1) {
        returnString += "\n";
      }
    });
    return returnString;
  };

  const getFilters = () => {
    let inventoryFilters = {
      ...emptyInventoryScreenFilters,
    };
    if (!insights && !isEmpty(selectedResources)) {
      inventoryFilters.arns = map(
        selectedResources,
        (resource) => resource?.arn
      );
    }
    return inventoryFilters;
  };

  const getDriftTable = () => {
    let tableContent = [];
    if (!isEmpty(code)) {
      map(code, (drift) => {
        if (!isPropertyExcluded(drift?.keyName)) {
          tableContent.push({
            Property: drift?.keyName,
            "Runtime Value": !drift?.inventory.toString().startsWith("map[")
              ? drift?.inventory
              : formatTags(drift?.inventory),
            "IaC Value": !drift?.state.toString().startsWith("map[")
              ? drift?.state
              : formatTags(drift?.state),
          });
        }
      });
    }
    return tableContent;
  };

  const handleSubmit = async () => {
    setLoadingSubmit(true);
    const jiraPost = {
      project: { id: wizardState?.project },
      issuetype: { id: wizardState?.issuetype },
      summary: wizardState?.summary,
      assignee: { id: wizardState?.assignee },
      description: wizardState?.description,
      link: insights ? "insights" : "inventory",
      selectedInsightId: insights ? selectedInsight?.id : "",
      inventoryFilters: getFilters(),
      table: driftDrawer ? getDriftTable() : [],
      userId: user?.sub,
    };

    map(Object.keys(wizardState), (key) => {
      if (
        !Object.keys(jiraPost)?.find((jiraKey) => jiraKey === key) &&
        key !== "integration"
      ) {
        if (!isEmpty(requiredFields[key])) {
          if(Array.isArray(wizardState[key])) {
            jiraPost[key] = map(wizardState[key], i => {return { id: i }})
          } else {
            jiraPost[key] = { id: wizardState[key] };
          }
        } else {
          jiraPost[key] = wizardState[key];
        }
      }
    });
    const res = await dispatch(
      createJiraTicket(wizardState?.integration, jiraPost)
    );
    setLoadingSubmit(false);
    if (!res?.req?.ok) {
      return;
    }
    handleClose();
    return appToast(
      "success",
      "New Issue created",
      `${res?.data?.key} ${wizardState?.summary}`
    );
  };

  const getFieldsInitialValues = () => {
    let requiredFields = {};
    let initialValues = {};
    map(extraFields, (field) => {
      requiredFields[field?.key] = field?.allowedValues || null;
      switch (field?.schema?.type) {
        case 'array':
          initialValues[field?.key] = [];
          break;
        case 'option':
        case 'priority':
          initialValues[field?.key] = null;
          break;
        case 'string':
          initialValues[field?.key] = '';
          break;
        default:
          initialValues[field?.key] = null;
          break;
      }
    });
    setRequiredFields(requiredFields);
    return setWizardState({ ...wizardState, ...initialValues });
  };

  const configWizard = [
    {
      step_title: "Details",
      step_description: "Issue description",
      content_title: "Create New Issue",
      content: (
        <IssueConfiguration
          form={form}
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          loadingIntegrations={loadingIntegrations}
          allProviderIntegrations={allProviderIntegrations || []}
          selectedResources={selectedResources}
          fetchIntegrationMetadata={(integration) =>
            fetchIntegrationMetadata(integration)
          }
          loadingMetadata={loadingMetadata}
          integrationMetadata={integrationMetadata}
          setExtraFields={setExtraFields}
          insightsTotal={insightsTotal}
          insights={insights}
          selectedInsight={selectedInsight}
          driftDrawer={driftDrawer}
          loadingMetadataForSearch={loadingMetadataForSearch}
          fetchIntegrationMetadataForSearch={(integration, val) =>
            fetchIntegrationMetadataForSearch(integration, val)
          }
        />
      ),
      valid: true,
      beforeNextStep: async () => {
        if (isEmpty(extraFields)) {
          handleSubmit();
        } else {
          getFieldsInitialValues();
        }
      },
    },
    {
      step_title: "Required Fields",
      content_title: "Required Fields",
      content: (
        <RequiredFields
          form={form}
          wizardState={wizardState}
          setWizardState={setWizardState}
          setSubmitDisabled={setSubmitDisabled}
          requiredFields={requiredFields}
          extraFields={extraFields}
        />
      ),
      valid: true,
    },
  ];

  const next = () => {
    setSubmitDisabled(true);
    setCurrent(current + 1);
  };

  const renderFooter = () => {
    return (
      <div className="AppModalWizard__footer row">
      
        {current < configWizard.length - 1 && (
          <span>
            {!isEmpty(extraFields) ? (
              <Button
                type="primary"
                onClick={async () => {
                  if (isFunction(configWizard[current].beforeNextStep)) {
                    try {
                      await configWizard[current].beforeNextStep();
                    } catch (err) {
                      return;
                    }
                  }
                  next();
                }}
                disabled={submitDisabled || noJiraIntegrations}
                loading={loadingSubmit}
              >
                Next
              </Button>
            ) : (
              <Button
                type="primary"
                htmlType="submit"
                loading={loadingSubmit}
                disabled={submitDisabled || noJiraIntegrations}
                onClick={handleSubmit}
              >
                {`Create & Link Issue`}
              </Button>
            )}
          </span>
        )}
        {current === configWizard.length - 1 && (
          <Button
            type="primary"
            htmlType="submit"
            loading={loadingSubmit}
            disabled={submitDisabled || noJiraIntegrations}
            onClick={handleSubmit}
          >
            {`Create & Link Issue`}
          </Button>
        )}
      </div>
    );
  };

  const renderEmptyIntegrations = () => {
    return (
      <div className="AppModalWizard__noIntegs col">
        <span>No Integrations</span>
        <Link to="/integrations/jira-integration">
          + Create a new Jira Integration
        </Link>
      </div>
    );
  };

  return (
    <AppModalWizard
      visible={visible}
      handleClose={handleClose}
      title="Create a new Jira Issue"
      hideCloseBtn
      iconSrc={projects["jira"]}
      hideSubmitBtn
      noPaddingBody
      className="AppModalWizard"
      customFooter={renderFooter()}
      zIndex={1001}
      content={
        noJiraIntegrations || isEmpty(jiraIntegrations)
          ? renderEmptyIntegrations()
          : configWizard[current].content
      }
      width="700px"
    />
  );
};

export default IssueModal;
