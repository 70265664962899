import React from "react";
import { useSelector } from "react-redux";
import { Tooltip } from "antd";
import { getIconMutedColor } from "../../utils/helpers";
import "./gitopsLabelIndicator.scss";

const GitopsLabelIndicator = ({
  gitopsDetected,
}) => {
  const themeDark = useSelector(
    (state) => state.userPreferencesReducer.themeDark
  );

  return (
    <Tooltip
      placement="top"
      title="GitOps"
      style={{ cursor: "pointer" }}
      overlayInnerStyle={{
        fontSize: "13px",
      }}
    >
      <button
        className="GitopsLabelIndicator center"
      >
        <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 20.73 9.59"
      width="13"
      height="13"
    >
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Q3V3RM.tif">
            <path
              d="M9.76,3.24,8.64,4.36c-.57-.58-1.13-1.18-1.72-1.75A3,3,0,1,0,2.8,7.09,3,3,0,0,0,7,7c1.86-1.83,3.68-3.68,5.53-5.51A4.82,4.82,0,0,1,18,.47a4.77,4.77,0,0,1,2.61,5.32,4.83,4.83,0,0,1-4.52,3.79,4.71,4.71,0,0,1-3.59-1.39L10.86,6.56l1.27-1.21c.51.52,1.07,1.09,1.65,1.65a3.09,3.09,0,0,0,5.06-1.25A3,3,0,0,0,14,2.45c-.4.33-.75.71-1.11,1.07C11.35,5.07,9.82,6.63,8.25,8.16a4.82,4.82,0,0,1-8-1.74A4.77,4.77,0,0,1,2,.92,4.71,4.71,0,0,1,7.7,1,23,23,0,0,1,9.76,3.24Z"
              fill={!gitopsDetected ? getIconMutedColor(themeDark) : "#9872FE"}
            />
          </g>
        </g>
      </g>
    </svg>
      </button>
    </Tooltip>
  );
};

export default GitopsLabelIndicator;
