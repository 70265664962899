import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_AKAMAI_INTEGRATIONS = "GET_AKAMAI_INTEGRATIONS";
export const CREATE_AKAMAI_INTEGRATION = "CREATE_AKAMAI_INTEGRATION";
export const DELETE_AKAMAI_INTEGRATION = "DELETE_AKAMAI_INTEGRATION";
export const EDIT_AKAMAI_INTEGRATION = "EDIT_AKAMAI_INTEGRATION";

const getAkamaiIntegrations = () => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const integrationsReq = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/akamai`,
        "GET"
    );

    const integrations = await integrationsReq.json();
    dispatch({
      type: GET_AKAMAI_INTEGRATIONS,
      payload: { integrations },
    });
    return integrationsReq.ok;
  });
};

  const createAkamaiIntegration = (name, host, clientSecret, accessToken, clientToken, fullScanEnabled=true) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/akamai/`,
        "POST",
        {
          name,
          host,
          clientSecret,
          accessToken,
          clientToken,
          fullScanEnabled
        }
    );

    dispatch({
      type: CREATE_AKAMAI_INTEGRATION,
      payload: {},
    });
    return res;
  });
};

const deleteAkamaiIntegration = (id) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    await requestWrapper.sendRequest(
        `${BACKEND_URL}/integrations/akamai/${id}`,
        "DELETE"
    );

    dispatch({
      type: DELETE_AKAMAI_INTEGRATION,
      payload: {},
    });
  });
};

const editAkamaiIntegration = (id, name, fetchable) => {
  return action(async (dispatch) => {
    const requestWrapper = new RequestWrapper();

    const res = await requestWrapper.sendRequest(
      `${BACKEND_URL}/integrations/akamai/${id}`,
      "PUT",
    {name, fetchable}
    );

    dispatch({
      type: EDIT_AKAMAI_INTEGRATION,
      payload: {},
    });
    return res.ok;
  });
};


export { getAkamaiIntegrations, createAkamaiIntegration, deleteAkamaiIntegration, editAkamaiIntegration };
