import React from "react";
import { useSelector } from "react-redux";
import _ from "lodash";
import AssetInfoCard from "../assetInfoCard";
import { iacProviders } from "../../../../../utils/icons";
import "../iacTab/iacTab.scss";

const ManagingResource = ({
    assetData,
    handleAssetModalFromChildren,
    controllerSources,
}) => {

  const themeDark = useSelector((state) => state.userPreferencesReducer.themeDark);

  const resourceClick = (children) => {
    handleAssetModalFromChildren(children, assetData);
  };


  const renderStacksList = () => {
    if (!_.isEmpty(controllerSources)) {
      return (
        <div className="IaCTab__list col">
          {_.map(controllerSources, (source) => (
            <div key={source?.frn} className="IaCTab__list-item row">
              <div className="IaCTab__list-item-type row">
                <img
                  src={iacProviders(source?.provider, themeDark)}
                  alt={source?.provider}
                />
                <span>{source?.assetType}</span>
              </div>
              <div
                className="underline"
                style={{ cursor: "pointer" }}
                onClick={(e) => {
                  e.stopPropagation();
                  resourceClick(source);
                }}
              >
                {source?.name}
              </div>
            </div>
          ))}
        </div>
      );
    }
  };

  return (
    <AssetInfoCard
      contentArr={[
        {
          label: "Sources",
          content: renderStacksList(),
          display: !_.isEmpty(controllerSources),
        },
      ]}
    />
  );
};

export default ManagingResource;
