import action from "../middleware";
import RequestWrapper from "../../utils/requestWrapper";
import { BACKEND_URL } from "../../consts/config";

export const GET_CONSUL_INTEGRATIONS = "GET_CONSUL_INTEGRATIONS";
export const CREATE_CONSUL_INTEGRATION = "CREATE_CONSUL_INTEGRATION";
export const DELETE_CONSUL_INTEGRATION = "DELETE_CONSUL_INTEGRATION";
export const GET_CONSUL_DATA_CENTERS = "GET_CONSUL_DATA_CENTERS";
export const EDIT_CONSUL_INTEGRATION = "EDIT_CONSUL_INTEGRATION";
export const GET_CONSUL_DATACENTERS = "GET_CONSUL_DATACENTERS";
export const GET_CONSUL_KEYS = "GET_CONSUL_KEYS";

const createConsulIntegration = (name, token, publicClusterAddress, datacenter) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul/`,
            "POST",
            {
                name,
                publicClusterAddress,
                token
            }
        );

        dispatch({
            type: CREATE_CONSUL_INTEGRATION,
            payload: { },
        });

        const result = await req.json();

        return result;
    });
};

const deleteConsulIntegration = (id) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul/${id}`,
            "DELETE"
        );

        dispatch({
            type: DELETE_CONSUL_INTEGRATION,
            payload: {},
        });

        return {};
    });
};

const getConsulIntegrations = () => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
    
        const integrationsReq = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul`,
            "GET"
        );

        const result = await integrationsReq.json();
    
        dispatch({
          type: GET_CONSUL_INTEGRATIONS,
          payload: { result },
        });
        return integrationsReq.ok;
      });
};

const editConsulIntegration = (integration, name) => {
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul/${integration}`,
            "PUT",
            {name}
        );

        dispatch({
            type: EDIT_CONSUL_INTEGRATION,
            payload: {},
        });

        return req.ok;
    });
};

const getConsulDatacenters = (integration) =>{
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();

        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul/${integration}/datacenters`,
            "GET"
        );
        dispatch({
          type: GET_CONSUL_DATACENTERS,
          payload: {},
        });
        const datacenters = await req.json();
        return datacenters;
      });
}

const getConsulKeys = (integration, datacenter) =>{
    return action(async (dispatch) => {
        const requestWrapper = new RequestWrapper();
        const req = await requestWrapper.sendRequest(
            `${BACKEND_URL}/integrations/consul/${integration}/${datacenter}/keys`,
            "GET"
        );
        dispatch({
          type: GET_CONSUL_KEYS,
          payload: {},
        });
        const keys = await req.json();
        return keys;
      });
}

export {
    createConsulIntegration,
    deleteConsulIntegration,
    editConsulIntegration,
    getConsulIntegrations,
    getConsulDatacenters,
    getConsulKeys,
};
