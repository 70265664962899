import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { generalEvents } from "../../../utils/amplitudeEvents";
import { sendEvent } from "../../../utils/amplitude";

import "./feedback.scss";

const Feedback = () => {
  return (
    <div
      id="beamerButton"
      onClick={() => {
        sendEvent(generalEvents.pageClick, { page: "Beamer" });
      }}
    >
      <div className="Feedback center">
        <FontAwesomeIcon icon="bell" />
      </div>
    </div>
  );
};

export default Feedback;
