import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import {
  getHistoryRevisionById,
  getHistoryRevisionsByFrn,
  compareHistory,
} from "../../../../redux/actions/historyActions";
import _ from "lodash";
import Loading from "../../../../shared/loading/loading";
import HistoryNav from "./historyNav/historyNav";
import AppEmpty from "../../../../shared/appEmpty/appEmpty";
import HistoryContent from "./historyContent/historyContent";
import "./assetHistory.scss";

const AssetHistory = ({ asset }) => {
  const dispatch = useDispatch();
  const revisions = useSelector((state) => state.historyReducer.revisions);

  const [selectedRevision, setSelectedRevision] = useState(null);
  const [loadingInit, setLoadingInit] = useState(true);
  const [loadingSingleRevision, setLoadingSingleRevision] = useState(false);

  useEffect(() => {
    handleFetchItialHistory();
  }, [asset]);

  // fetch revision on run ID selected
  useEffect(() => {
    if (!loadingInit) {
      handleFetchRevision();
    }
  }, [selectedRevision]);

  const handleFetchItialHistory = async () => {
    setLoadingInit(true)
    const { provider, integrationId, assetType, frn } = asset;
    const reqRevisions = await dispatch(
      getHistoryRevisionsByFrn(provider, integrationId, assetType, frn, 0, 50)
    );
    if (!_.isEmpty(reqRevisions)) {

      const revisionsWithoutCurrent = _.filter(reqRevisions || [], rev => !rev?.current);
      const currentRevisionRunId = _.find(reqRevisions || [], rev => rev?.current).runId;

      setSelectedRevision(revisionsWithoutCurrent?.[revisionsWithoutCurrent?.length - 1]);

      await Promise.all([
        dispatch(
          getHistoryRevisionById(
            provider,
            integrationId,
            assetType,
            frn,
            revisionsWithoutCurrent?.[revisionsWithoutCurrent?.length - 1]?.runId
          )
        ),
        dispatch(
          compareHistory(
            provider,
            integrationId,
            assetType,
            frn,
            revisionsWithoutCurrent?.[revisionsWithoutCurrent?.length - 1]?.runId,
            currentRevisionRunId
          )
        ),
      ]);
    }
    setLoadingInit(false);
  };

  const handleFetchRevision = async () => {
    if (!_.isEmpty(asset) && !_.isEmpty(selectedRevision)) {
      setLoadingSingleRevision(true);
      const { provider, integrationId, assetType, frn } = asset;
      const currentRevisionRunId = _.find(revisions || [], rev => rev?.current).runId;

      await Promise.all([
        dispatch(
          getHistoryRevisionById(
            provider,
            integrationId,
            assetType,
            frn,
            selectedRevision?.runId
          )
        ),
        dispatch(
          compareHistory(
            provider,
            integrationId,
            assetType,
            frn,
            selectedRevision?.runId,
            currentRevisionRunId
          )
        ),
      ]);
      return setLoadingSingleRevision(false);
    }
  };

  if (loadingInit) {
    return (
      <div className="tab-page center">
        <Loading />
      </div>
    );
  }

  if (!loadingInit && _.isEmpty(revisions)) {
    return (
      <div className="tab-page center">
        <AppEmpty customStyle="code" text="No History Revisions" />
      </div>
    );
  }

  return (
    <div className="AssetHistory col">
      <HistoryNav
        selectedRevision={selectedRevision}
        setSelectedRevision={setSelectedRevision}
      />
      <HistoryContent
        loadingSingleRevision={loadingSingleRevision}
        selectedRevision={selectedRevision}
      />
    </div>
  );
};

export default AssetHistory;
