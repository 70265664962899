import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import _ from "lodash";

// components
import Loading from "../../shared/loading/loading";
import NotificationList from "../../components/notifications/notificationList/notificationList";
import EmptyNotifications from "../../components/notifications/emptyNotifications/emptyNotifications";
import CreateNotification2 from "../../components/notifications/createNotification/createNotification2";


// redux actions
import {
  getTeamsIntegrations,
  getWebhookIntegrations,
  getSlackIntegrations,
  getAllSlackChannels,
  getOpsgenieIntegrations,
  getTorqIntegrations,
  getPagerdutyIntegrations,
  getSlackChannelsByIds,
} from "../../redux/actions/integrationsActions";
import { getAllNotifications } from "../../redux/actions/notificationsActions";
import { getClassifications } from "../../redux/actions/classificationsActions";
import { getAllClouds } from "../../redux/actions/globalAppActions";

import "./notifications.scss";
import { useTranslation } from "react-i18next";
import { ReactComponent as MatchingRules } from "../../Images/general_icons/classification.svg";


const Notifications = () => {
  const dispatch = useDispatch();
  const { t } = useTranslation('notifications')
  const [loading, setLoading] = useState(true);
  const [loadingIntegrations, setLoadingIntegrations] = useState(true);
  const [loadingProviders, setLoadingProviders] = useState(true);
  const [loadingSlackChannels, setLoadingSlackChannels] = useState(false);
  const [loadingInsights, setLoadingInsights] = useState(false);
  const [notificationModalVisible, setNotificationModalVisible] = useState(false);
  const [selectedNotification, setSelectedNotification] = useState();

  const notificationTypesIndex = [
    {
      id: 1,
      value: "Drift",
      name: t('types.drift.name'),
      displayName: t('types.drift.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "code-branch",
    },
    {
      id: 2,
      value: "UnmanagedResource",
      name: t('types.unmanaged-resource.name'),
      displayName: t('types.unmanaged-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "exclamation-circle",
    },
    {
      id: 3,
      value: "GhostResource",
      name: t('types.ghost-resource.name'),
      displayName: t('types.ghost-resource.display'),
      relatedScope: "provider",
      disabled: false,
      icon: "ghost",
    },
    {
      id: 4,
      value: "InsightDetected",
      name: t('types.insight-detected.name'),
      displayName: t('types.insight-detected.display'),
      relatedScope: "insight",
      disabled: false,
      icon: <MatchingRules/>,
    },
    {
      id: 5,
      value: "StateFileChange",
      name: t('types.state-file-change.name'),
      displayName: t('types.state-file-change.display'),
      relatedScope: "group",
      disabled: true,
    },
    {
      id: 6,
      value: "SystemActivity",
      name: t('types.system-activity.name'),
      displayName: t('types.system-activity.display'),
      relatedScope: null,
      disabled: true,
    },
  ];

  const allNotifications = useSelector(
    (state) => state.notificationsReducer.notifications
  );

  const slackIntegrations = useSelector(
    (state) => state.integrationsReducer.slack
  );

  useEffect(() => {
    getUserNotifications();
    getIntegrations();
    getProviders();
    getAllInisights();
  }, []);

  useEffect(() => {
    fetchSlackChannelNamesByIds();
  }, [slackIntegrations, allNotifications]);

  useEffect(() => {
    fetchSlackChannels();
  }, [slackIntegrations]);

  // get all stored notifications
  const getUserNotifications = async () => {
    setLoading(true);
    await dispatch(getAllNotifications());
    setLoading(false);
  };

  // get all notification integrations
  const getIntegrations = async () => {
    setLoadingIntegrations(true);
    await Promise.all([
      dispatch(getSlackIntegrations()),
      dispatch(getTeamsIntegrations()),
      dispatch(getWebhookIntegrations()),
      dispatch(getOpsgenieIntegrations()),
      dispatch(getTorqIntegrations()),
      dispatch(getPagerdutyIntegrations()),
    ]);
    setLoadingIntegrations(false);
  };

  // get all provider integrations
  const getProviders = async () => {
    setLoadingProviders(true);
    await Promise.all([dispatch(getAllClouds())]);
    setLoadingProviders(false);
  };

  const fetchSlackChannels = async () => {
    if (!_.isEmpty(slackIntegrations)) {
      const slackApp = slackIntegrations?.find(
        (item) => item?.service === "slackApp"
      );

      if (!_.isEmpty(slackApp)) {
        setLoadingSlackChannels(true);
        await dispatch(getAllSlackChannels(slackApp?.id));
        setLoadingSlackChannels(false);
      }
      return;
    }
  };

  const fetchSlackChannelNamesByIds = async () => {
    if (!_.isEmpty(slackIntegrations) && !_.isEmpty(allNotifications)) {
      const slackApp = slackIntegrations?.find(
        (item) => item?.service === "slackApp"
      );

      const allNotificationIntegs = _.flatten(
        _.map(
          allNotifications || [],
          (item) => item?.notificationIntegrations
        ) || []
      );
      const slackAppNotificationIntegs = _.filter(
        allNotificationIntegs || [],
        (item) => item?.service === "slackApp"
      );
      const channelIds = _.flatten(
        _.filter(
          _.map(slackAppNotificationIntegs || [], (item) => item?.channels) ||
          [],
          (x) => !_.isUndefined(x)
        )
      );

      if (slackApp && !_.isEmpty(channelIds)) {
        await dispatch(getSlackChannelsByIds(slackApp?.id, channelIds));
      }
      return;
    }
  };

  // get all insights
  const getAllInisights = async () => {
    setLoadingInsights(true);
    await Promise.all([dispatch(getClassifications())]);
    setLoadingInsights(false);
  };

  if (loading) {
    return (
      <div className="basic-page center">
        <Loading />
      </div>
    );
  }

  return (
    <div className="Notifications basic-page">
      {!loading && _.isEmpty(allNotifications) ? (
        <EmptyNotifications
          handleAddNotification={() => setNotificationModalVisible(true)}
        />
      ) : (
        <NotificationList
          handleAddNotification={() => setNotificationModalVisible(true)}
          notificationTypesIndex={notificationTypesIndex}
          loadingNotificationsInteg={loadingIntegrations}
          loadingProviders={loadingProviders}
          loadingSlackChannels={loadingSlackChannels}
          loadingInsights={loadingInsights}
          getUserNotifications={getUserNotifications}
          onEditNotificationData={(notification) =>{
            setSelectedNotification(notification);
            setNotificationModalVisible(true);
          }}
        />
      )}

      {/* <CreateNotification
        visible={createNotification}
        handleClose={() => setCreateNotification(false)}
        notificationTypesIndex={notificationTypesIndex}
        loadingNotificationsInteg={loadingIntegrations}
        loadingProviders={loadingProviders}
        loadingSlackChannels={loadingSlackChannels}
        loadingInsights={loadingInsights}
        handleCloseRefresh={() => {
          setCreateNotification(false);
          getUserNotifications();
        }}
      /> */}
      <CreateNotification2
        initialValues={selectedNotification}
        visible={notificationModalVisible}
        insightsLoading={loadingInsights}
        handleClose={() => {
          setSelectedNotification(null);
          setNotificationModalVisible(false)
        }}

      />
    </div>
  );
};

export default Notifications;
